import { inject, Injectable } from '@angular/core';
import { CanDeactivateFn, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { NotificationsService } from 'app/core/services';
import {
  JatakCreateComponent,
  JatakEditComponent,
  JatakTemplateComponent,
  JatakDefaultComponent
} from 'app/features/jatak';

// ***  It runs before navigating away from Jatak form ***
// Checks whether the formData has been altered and pressed back instead of saving
// @returns boolean =>  true:    Route is deactivated
//                      false:   Current route is not changed
@Injectable({
  providedIn: 'root'
})
export class JatakGuardService {
  constructor() {
  }

  jatakCanDeactivate(
    component:
      | JatakCreateComponent
      | JatakEditComponent
      | JatakTemplateComponent
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const dirty =
      component.jatakFormComponent.jatakForm.dirty &&
      !component.jatakFormComponent.formSaved;
    if (dirty) {
      const title =
        component.jatakFormComponent.jatakForm.get('title').value ||
        'Ja-tak';
      return NotificationsService.confirm(
        `Dine ændringer til ${title} bliver ikke gemt.`
      );
    }
    return true;
  }

  jatakDefaultCanDeactivate(
    component:
      | JatakDefaultComponent
  ): Observable<boolean> | boolean {
    const dirty = component.jatakDefaultForm.dirty && !component.formSaved;
    if (dirty) {
      return NotificationsService.confirm('Dine ændringer vil ikke blive gemt.');
    }
    return true;
  }

  jatakDuplicateCanDeactivate(
    component:
      | JatakCreateComponent
      | JatakEditComponent
      | JatakTemplateComponent
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const dirty =
      !component.jatakFormComponent.formSaved;
    if (dirty) {
      const title =
        component.jatakFormComponent.jatakForm.get('title').value ||
        'Ja-tak';
      return NotificationsService.confirm(
        `Dine ændringer til ${title} bliver ikke gemt.`
      );
    }
    return true;
  }
}

export const JatakGuard: CanDeactivateFn<JatakCreateComponent | JatakEditComponent | JatakTemplateComponent> = (component) => {
  return inject(JatakGuardService).jatakCanDeactivate(component);
};
export const JatakDefaultGuard: CanDeactivateFn<JatakDefaultComponent> = (component) => {
  return inject(JatakGuardService).jatakDefaultCanDeactivate(component);
};

export const JatakDuplicateGuard: CanDeactivateFn<JatakCreateComponent | JatakEditComponent | JatakTemplateComponent> = (component) => {
  return inject(JatakGuardService).jatakDuplicateCanDeactivate(component);
};


