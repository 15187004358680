import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router, UrlSegment } from '@angular/router';
import { combineLatest } from 'rxjs';
import { finalize, take } from 'rxjs/operators';

import { ErrorhandlerService } from 'app/core/services';
import { Jatak, JatakFormComponent } from 'app/features/jatak';
import {JatakDefault} from "../../../../shared/interface";

@Component({
  selector: 'coop-jatak-template',
  templateUrl: './jatak-template.component.html',
  styleUrls: ['./jatak-template.component.scss'],
})
// Creating Jatak from template
export class JatakTemplateComponent implements OnInit {
  // *** JatakFormComponent required in canDeactivate guard ***
  @ViewChild(JatakFormComponent, { static: true })
  jatakFormComponent: JatakFormComponent;
  formData: Jatak;
  jatakDefault :JatakDefault
  previousUrl: string;
  currentUrl: string;
  successUrl: string;
  offerId: string; // editId + templateId
  parentUrl: string;
  origin: string;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _errorHandlerService: ErrorhandlerService,
    private _router: Router
  ) {}
// *** It sets previousUrl and successUrl for coop-jatak-form ***
  ngOnInit() {
    this.formData = this._activatedRoute.snapshot.data['jatak'];
    this.jatakDefault = this._activatedRoute.snapshot.data["data"];
    this.origin = this._activatedRoute.snapshot.queryParams['origin'];
    this._setUrls();
  }
// *** Sets parentUrl for back and success navigation ***
  private _setUrls() {
    this.currentUrl = this._router.url;
    this.previousUrl = this.currentUrl.replace('ja-tak/skab', '');
    this.parentUrl =
      this._activatedRoute.snapshot.parent.parent.data['parentUrl'];
    this.successUrl = this.parentUrl + '/kalendar/ja-tak';
    combineLatest([
      this._activatedRoute.url,
      this._activatedRoute.parent.params,
    ])
      .pipe(
        take(1),
        finalize(() => {
          // need to change this if added for bestyrelse
          if (this._activatedRoute.children.length > 0) {
            this.currentUrl = '/butikker/ja-tak/redigere/' + this.offerId;
          }
          if (this.currentUrl.indexOf('sog') > 0) {
            this.previousUrl = this.currentUrl.replace(
              '/sog/' + this.offerId,
              ''
            );
          }
          if (this.currentUrl.indexOf('sog') === -1) {
            this.previousUrl = this.currentUrl.replace('/' + this.offerId, '');
          }
        })
      )
      .subscribe({
        next: (value: [UrlSegment[], Params]) => this._setOfferId(value[0]),
        error: (err) => this._errorHandlerService.handleError(err),
      });
  }

// *** It sets value of this.offerId from url. ***
// this.offerId = template_id // for new template form
  private _setOfferId(urlSegment) {
    if (urlSegment.length > 1) {
      this.offerId = urlSegment[1].path;
    } else {
      this.offerId = urlSegment[0].path;
    }
  }
}
