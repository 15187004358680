import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {FormGroup} from '@angular/forms';

import {DateFormatService} from 'app/core/services';
import * as dayjs from "dayjs";

@Injectable({
  providedIn: 'root'
})
export class EventDateTimeService {
  constructor() {
  }

// *** Sets default time on create form ***
  // @params [eventForm: FormGroup] => the form where default dates are to be patched
  // startDate has current time and endDate has that of 1 hour later.
  static setDefaultTimeOnCreate(eventForm: FormGroup): void {
    const startDate = dayjs().format('YYYY-MM-DD HH:mm');
    const endDate =dayjs().add(1,'hours').format('YYYY-MM-DD HH:mm');
    eventForm.patchValue({
      publishDate: startDate,
      expirationDate: endDate,
      startDate: startDate,
      endDate: endDate
    });
  }

// TODO used but not working
// *** Sets up dateTime for create mode ***
  // Used in mobiscroll option
  // @params [eventForm:FormGroup]=> The event form where default dates are to be patched
  // @params [inst: Scroller] => current date-time options and value
  static setUpDateTime(eventForm: FormGroup, id: string): void {
    // Set Start Date at Today time 8:00
    if (id === 'startDate' && !eventForm.value.startDate) {
      eventForm.patchValue({
        startDate: moment().hour(8).minute(0).toDate()
      });
    }

    // Set Publish Date at Today time 8:00
    if (id === 'publishDate' && !eventForm.value.publishDate) {
      eventForm.patchValue({
        publishDate: moment().hour(8).minute(0).toDate()
      });
    }

    // endDate picker clicked
    // If no startDate set it date to Today time 20:00
    if (
      id === 'expiry' &&
      !eventForm.value.endDate &&
      !eventForm.value.startDate
    ) {
      eventForm.patchValue({
        endDate: moment().hour(20).minute(0).toDate()
      });
    }

    // Expiration picker clicked
    // If no PublshDate set it date to Today time 20:00
    if (
      id === 'expirationDate' &&
      !eventForm.value.expirationDate &&
      !eventForm.value.publishDate
    ) {
      eventForm.patchValue({
        expirationDate: moment().hour(20).minute(0).toDate()
      });
    }
    // endDate picker clicked
    // If startDate set it to startDate and time at 20:00
    if (
      id === 'expiry' &&
      !eventForm.value.endDate &&
      eventForm.value.startDate
    ) {
      const startDate = moment(eventForm.value.startDate);
      eventForm.patchValue({
        endDate: startDate.hour(20).minute(0).toDate()
      });
    }
    // expirationDate picker clicked
    // If publishDate set it to startDate and time at 20:00
    if (
      id === 'expirationDate' &&
      !eventForm.value.expirationDate &&
      eventForm.value.publishDate
    ) {
      const startDate = moment(eventForm.value.publishDate);
      eventForm.patchValue({
        expirationDate: startDate.hour(20).minute(0).toDate()
      });
    }
  }

  static initialiseDurationPop(eventForm: FormGroup, id: string, editMode: boolean, onDurationPop: () => void): void {
    const elementID = id;
    if (elementID === 'startDate') {
      const startDate = eventForm.value.startDate;
      const endDate = moment(startDate).add(1, 'hour');
      eventForm.patchValue({
        endDate: endDate
      });
    }
    if (!editMode) {
      if (elementID === 'startDate' || elementID === 'expiry') {
        const startDate = moment(eventForm.value.startDate);
        const endDate = moment(eventForm.value.endDate);
        if (endDate.diff(startDate, 'minutes') > 120) {
          onDurationPop();
        }
      }
    }
  }
}
