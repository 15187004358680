import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import {
  AppEventsService,
  ErrorhandlerService,
} from 'app/core/services';
import {
  Template,
  TemplateFolderData,
  TemplateListData,
} from 'app/shared/interface';
import {
  FbInsights,
  JatakLiveComments,
  JatakLiveList,
  ProductReportData,
  JatakLiveService,
} from 'app/features/jatak-live';
// It resolves JatakLive for edit form
// @routeParam [id: number] => Jatak_live_id
// @returns [jatakLive: JatakLive] => Jatak-live details of particular id
@Injectable({
  providedIn: 'root',
})
export class JatakLiveSingleResolverService  {
  constructor(
    private _jatakLiveService: JatakLiveService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    const id = route.params['id'];
    return this._jatakLiveService
      .getSingleJatakLive(id)
      .pipe(
        catchError((err) => this._errorHandlerService.routeResolverError(err))
      );
  }
}
// Todo: no use
@Injectable({
  providedIn: 'root',
})
export class JatakLiveResolverService  {
  constructor(
    private _jatakLiveService: JatakLiveService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return this._jatakLiveService
      .getJatakLive()
      .pipe(
        catchError((err) => this._errorHandlerService.routeResolverError(err))
      );
  }
}
// It resolves productData for JatakLive reporting page
// @routeParam [id: string] => JatakLive id
// @returns [productData: ProductReportData] => info about product availability and quantity
@Injectable({
  providedIn: 'root',
})
export class JatakLiveProductDataResolverService  {
  constructor(
    private _jatakLiveService: JatakLiveService,
    private _appEvents: AppEventsService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<ProductReportData>
    | Promise<ProductReportData>
    | ProductReportData {
    this._appEvents.showLoader(true);
    const id = route.parent.params['id']
      ? route.parent.params['id']
      : route.params['id'];
    return this._jatakLiveService
      .getJatakProductReport(id)
      .pipe(
        catchError((err) => this._errorHandlerService.routeResolverError(err))
      );
  }
}
// It resolves latest 10 comments for JatakLive reporting page
// @routeParam [id: string] => JatakLive id
// @returns [commentData: JatakLiveComments] => comment head information and all the comment details
@Injectable({
  providedIn: 'root',
})
export class JatakLiveCommentResolverService  {
  constructor(
    private _jatakLiveService: JatakLiveService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<JatakLiveComments>
    | Promise<JatakLiveComments>
    | JatakLiveComments {
    const id = route.parent.params['id']
      ? route.parent.params['id']
      : route.params['id'];
    return this._jatakLiveService.getJatakComments(id).pipe(
      map((res: any) => {
        const replyStatusNull = [];
        let fetchCommentsForId = '';
        res.data.forEach((data) => {
          if (data.reply_comment && data.reply_status === null) {
            replyStatusNull.push(data.id);
            fetchCommentsForId = replyStatusNull.toString();
          }
        });
        return { ...res, fetchCommentsForId };
      }),
      catchError((err) => this._errorHandlerService.routeResolverError(err))
    );
  }
}
// *** Resolves jatak-live lists inside Indstillinger(settings) tab with startPage 1, pageLimit 10 and status as active ***
// @returns [Observable<JatakLive[]>] => Basic Jatak-live value of 10 active jatak-live items
@Injectable({
  providedIn: 'root',
})
export class JatakLiveListResolverService  {
  constructor(
    private _appEvents: AppEventsService,
    private _jatakLiveService: JatakLiveService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this._jatakLiveService
      .getJatakLiveListing(1, 10, '1')
      .pipe(
        catchError((err) => this._errorHandlerService.routeResolverError(err))
      );
  }
}
// *** It resolves total likes and views (insights) in JatakLive reporting page ***
// @routeParam [id: string] => JatakLive id
// @returns [fbInsights: FbInsights] => info about likes and views
@Injectable({
  providedIn: 'root',
})
export class JatakLiveInsightsResolverService  {
  constructor(
    private _jatakLiveService: JatakLiveService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<FbInsights> | Promise<FbInsights> | FbInsights {
    const id = route.parent.params['id']
      ? route.parent.params['id']
      : route.params['id'];
    return this._jatakLiveService
      .getFacebookInsights(id)
      .pipe(
        catchError((err) => this._errorHandlerService.routeResolverError(err))
      );
  }
}
// *** Resolves all the JatakLive template folders ***
// @returns [templateFolder: TemplateFolderData] => information about JatakLive template folders
@Injectable({
  providedIn: 'root',
})
export class JatakLiveTemplateFolderResolverService
  
{
  constructor(
    private _jatakLiveService: JatakLiveService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this._jatakLiveService
      .getJatakLiveTemplatesFolder()
      .pipe(
        catchError((err) => this._errorHandlerService.routeResolverError(err))
      );
  }
}
// *** gets all the TemplateListData from page 1 to 10 ***
// @routeParam [folderId: string] => 'own', 'store', any_folder_id
// @returns [template: Array<Template>] => Templates belonging to particular folder
@Injectable({
  providedIn: 'root',
})
export class JatakLiveTemplateListResolverService
  
{
  constructor(
    private _jatakLiveService: JatakLiveService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    // if (route.params['folderId'] === 'egne') {
    //   return this._jatakLiveService.getJatakLiveTemplates(TokenService.getUserCode(), 'false', '', 1, 10).pipe(
    //     catchError(err => this._errorHandlerService.routeResolverError(err))
    //   );
    // }
    // if (route.params['folderId'] === 'store') {
    //   return this._jatakLiveService.getJatakLiveTemplates('false', TokenService.getUserCode(), '', 1, 10).pipe(
    //     catchError(err => this._errorHandlerService.routeResolverError(err))
    //   );
    // }

    // Jatak-live does not have own template only admin so sending client ID as 0
    return this._jatakLiveService
      .getJatakLiveTemplates('0', 'false', route.params['folderId'], 1, 10)
      .pipe(
        catchError((err) => this._errorHandlerService.routeResolverError(err))
      );
  }
}
// *** Resolves single template ***
// @routeParam [templateId:number] => template_id
// @returns [jatakLive: Template] => Details of the template
@Injectable({
  providedIn: 'root',
})
export class JatakLiveTemplateResolverService  {
  constructor(
    private _jatakLiveService: JatakLiveService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this._jatakLiveService
      .getJatakLiveSingleTemplate(route.params['templateId'])
      .pipe(
        catchError((err) => this._errorHandlerService.routeResolverError(err))
      );
  }
}
