import {
  AfterViewInit,
  Component,
  ElementRef, Input,
  OnChanges,
  OnInit,
  SimpleChange,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Chart, registerables} from "chart.js";

Chart.register(...registerables);

@Component({
  selector: 'app-quicktogo-turnover-graphs',
  templateUrl: './quicktogo-turnover-graphs.component.html',
  styleUrls: ['./quicktogo-turnover-graphs.component.scss'],
})
export class QuicktogoTurnoverGraphsComponent implements OnInit, AfterViewInit, OnChanges {
  lineChart: any;
  months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
  yAxisLabel = '';
  xlabel = '';
  chartData: any;
  earlierData: any;
  presentLabel: any;
  earlierLabel: any;
  title: string;
  earlier: string;
  @ViewChild('lineCanvas') private lineCanvas: ElementRef;
  @Input() turnOverGraph: any

  constructor() {
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.lineCanvas.nativeElement.focus()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.turnOverGraph) {
      this.chartData = [];
      this.earlierData = [];
      this.presentLabel = [];
      this.earlierLabel = [];
      this.setupData()
      if (this.lineChart) {
        this.lineChart.destroy()
      }
      setTimeout(() => this.lineChartMethod())
    }

  }

  private setupData() {
    this.chartData = this.turnOverGraph
    this.months = Object.keys(this['turnOverGraph']);
  }

  lineChartMethod() {
    if (!this.turnOverGraph) {
      return;
    }
    this.lineChart = new Chart(this.lineCanvas.nativeElement, {
      type: 'line',
      data: {
        labels: this.months,
        datasets: [
          {
            label: '',
            fill: true,
            backgroundColor: '#00A869',
            borderColor: '#00A869',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#00A869',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 4,
            tension: 0.6,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: this.chartData,
            spanGaps: true,

          }
        ]
      },

      options: {
        plugins : {
          legend : {
            display : false
          }
        },
        scales: {
          y: {
            beginAtZero: true,
            grace: 90,
            ticks: {
              precision: 0,
            },
           title : {
              display: true,
              text : '(kr.)',
              align : 'end'
           }
          },

        },

        responsive: true,
        maintainAspectRatio: false
      }
    });
  }


}
