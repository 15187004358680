<div class="section-container">
  <div class="section links link-container">
    <ng-container *ngFor="let option of channelData">
      <a
        class="link-box"
        (click)="channelSelect(option.channel)"
        *ngIf="isAllowed(option.channel)"
        [class.disabled]="option.channel === requiredChannel"
      >
        <div class="link-icon">
          <ion-icon class = "channel-icon" name="{{ option.iconOutline }}"  *ngIf="!selectedOption[option.channel]"></ion-icon>
          <ion-icon class = "channel-icon" name="{{ option.iconFilled }}"  *ngIf="selectedOption[option.channel]"></ion-icon>
        </div>
        <div class="link-text">
          <h3 *ngIf="!selectedOption[option.channel]">{{ option.name }}</h3>
          <h5 *ngIf="selectedOption[option.channel]">{{ option.name }}</h5>
        </div>
        <div
          class="check icon-check-box"
          [ngClass]="selectedOption[option.channel] ? 'red' : ''"
        >
          <ion-icon name="square-outline" class="checkbox-icon" *ngIf="!selectedOption[option.channel]"></ion-icon>
          <ion-icon name="checkbox" class="checkbox-icon "*ngIf="selectedOption[option.channel]"></ion-icon>
<!--          <i class="icon icon-stop" *ngIf="!selectedOption[option.channel]"></i>-->
<!--          <i class="icon icon-tick-square" *ngIf="selectedOption[option.channel]"></i>-->
        </div>
      </a>
    </ng-container>
  </div>
  <div class="btn-container">
    <div
      class="login-btn btn-small btn-white-login "
      (click)="selectAllChannel()"
      [class.disabled-option]="disableButton"
    >
      <span>Vælg alle</span>
    </div>
    <div
      class="login-btn btn-small btn-black-login" style="margin-left: 10px"
      (click)="confirmChannelSelection($event)"
      [class.disabled-option]="disableButton"
    >
      <span>Gem</span>
    </div>
  </div>

</div>
