import { InjectionToken } from '@angular/core';

export const defaultErrors = {
  required: (error) => 'Udfyld venligst feltet',
  min: ({ min, actual }) => `Værdien skal være over ${min}`,
  maxlength: ({ requiredLength, actualLength }) =>
    `Tekst må højst være ${requiredLength} tegn`,
};
export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
  providedIn: 'root',
  factory: () => defaultErrors,
});
