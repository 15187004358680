import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import {
  fadeInOutAnimationMFL,
  mflPopoutAnimation,
  mflPopupAnimation,
} from 'app/shared/animations';

@Component({
  selector: 'coop-mfl-popup',
  templateUrl: './mfl-popup.component.html',
  styleUrls: ['./mfl-popup.component.scss'],
  animations: [mflPopoutAnimation, fadeInOutAnimationMFL, mflPopupAnimation],
})
export class MflPopupComponent implements OnInit, OnDestroy {
  pAnimate = 'inactive';
  @Output() showPopup: EventEmitter<boolean> = new EventEmitter<boolean>();
  //**Declares  a host property binding/
  //returns 'inactive'
  @HostBinding('@fadeInOutAnimationMFL') get fadeInOutAnimationMFL() {
    return 'inactive';
  }
//**listen to the event emitted by the host element/
  //set pAnimate to 'active'
  @HostListener('@fadeInOutAnimationMFL.done') onDone() {
    this.pAnimate = 'active';
  }
  constructor() {}

  ngOnInit() {}
//** destroys component*/
  // set pAnimate to 'inactive'
  ngOnDestroy() {
    this.pAnimate = 'inactive';
  }
//** exits Popup */
  //hidePopup() to hide Popup
  cancel() {
    this.hidePopup();
  }
  //** when animation completes*/
  //@params[e] => event object
  //if event.toState in which the animation completes is equal to 'inactive' and event.fromState from which the animation is triggered is not equal to void => set pAnimate to 'inactive'
  slideDone(e): void {
    if (e.toState === 'inactive' && e.fromState !== 'void') {
      this.pAnimate = 'inactive';
    }
  }
//** navigates */
  //hidePopup() => hides Popup
  navigateTo() {
    this.hidePopup();
  }
//** hides Popup */
  //set pAnimate to 'inactive'
//setTimeout() sets a timer which executes showPopup() once the timer expires i.e 250ms.
  hidePopup() {
    this.pAnimate = 'inactive';
    setTimeout(() => {
      this.showPopup.emit(false);
    }, 250);
  }
}
