import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import {
  AppEventsService,
  ChannelDataService,
  ErrorhandlerService,
  TokenService,
  UserService,
} from 'app/core/services';
import {
  SelectedChannels,
  Template,
  TemplateFolderData,
  TemplateListData,
  JatakDefault
} from 'app/shared/interface';
import { Jatak, JatakList, JatakService } from 'app/features/jatak';
import {Store} from "@ngxs/store";
import {SetOrderListCompState} from "../states";
// *** Resolves single Jatak post to edit page ***
// @routeParam [id: number] => Jatak_id
// @returns [jatak: Jatak] => Jatak detail of particular jatak id
@Injectable({
  providedIn: 'root',
})
export class JatakResolverService  {
  constructor(
    private _appEvents: AppEventsService,
    private _jatakService: JatakService,
    private _errorHandlerService: ErrorhandlerService,

  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    const id = route.params['id'];
    return this._jatakService
      .getSingleFbPost(id)
      .pipe(
        catchError((err) => this._errorHandlerService.routeResolverError(err))
      );
  }
}
// *** Resolves all active jatakLists with given page limit and current page ***
// @returns [jatakList: Array<JatakList>] => Basic Jatak value of all Active jatak items
@Injectable({
  providedIn: 'root',
})
export class JatakListResolverService  {
  constructor(
    private _appEvents: AppEventsService,
    private _jatakService: JatakService,
    private _errorHandlerService: ErrorhandlerService,
    private _store: Store

  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    let sortBy = TokenService.geTSortId()
    return this._jatakService
      .getJatakListing(1, 10, this._store.selectSnapshot(SetOrderListCompState.getData) ? this._store.selectSnapshot(SetOrderListCompState.getData).tab : '1' , sortBy ? sortBy : 1)
      .pipe(
        catchError((err) => this._errorHandlerService.routeResolverError(err))
      );
  }
}
// *** Resolves all the orderLists for a particular Jatak item ***
// @routeParam [id: string] => Jatak_id
// @returns [orderList: OrderData] => All the orderLists data
@Injectable({
  providedIn: 'root',
})
export class OrderListResolverService  {
  constructor(
    private _events: AppEventsService,
    private _jatakService: JatakService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    // @ts-ignore
    const id = route.params['id'];
    return this._jatakService
      .getOrderList(id)
      .pipe(
        catchError((err) => this._errorHandlerService.routeResolverError(err))
      );
  }
}
// *** Resolves all the Jatak template folders ***
// @returns [templateFolder: TemplateFolderData] => information about Jatak template folders
@Injectable({
  providedIn: 'root',
})
export class JatakTemplateFolderResolverService
  
{
  constructor(
    private _jatakService: JatakService,
    private _user: UserService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this._jatakService
      .getJatakTemplatesFolder()
      .pipe(
        catchError((err) => this._errorHandlerService.routeResolverError(err))
      );
  }
}
// *** gets all the TemplateListData from page 1 to 10 ***
// @routeParam [folderId: string] => 'own', 'store', any_folder_id
// @returns [template: Array<Template>] => Templates belonging to particular folder
@Injectable({
  providedIn: 'root',
})
export class JatakTemplateListResolverService
  
{
  constructor(
    private _jatakService: JatakService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    if (route.params['folderId'] === 'egne') {
      return this._jatakService
        .getJatakTemplates( 'own', '', 1, 10)
        .pipe(
          catchError((err) => this._errorHandlerService.routeResolverError(err))
        );
    } else if (route.params['folderId'] === 'store') {
      return this._jatakService
        .getJatakTemplates('store', '', 1, 10)
        .pipe(
          catchError((err) => this._errorHandlerService.routeResolverError(err))
        );
    } else {
      return this._jatakService
        .getJatakTemplates('folder',  route.params['folderId'], 1, 10 , '')
        .pipe(
          catchError((err) => this._errorHandlerService.routeResolverError(err))
        );
    }
  }
}
// *** Resolves single template ***
// @routeParam [templateId:number] => template_id
// @returns [jatak: Template] => Details of the template
@Injectable({
  providedIn: 'root',
})
export class JatakTemplateResolverService  {
  constructor(
    private _jatakService: JatakService,
    private _errorHandlerService: ErrorhandlerService
  ) {
  }

  resolve(route: ActivatedRouteSnapshot) {
    return this._jatakService
      .getJatakSingleTemplate(route.params['templateId'])
      .pipe(
        catchError((err) => this._errorHandlerService.routeResolverError(err))
      );
  }
}
// *** Resolves selected channels for current jatak template ***
@Injectable({
  providedIn: 'root',
})
export class JatakChannelResolver  {
  constructor(
    private _channelDataService: ChannelDataService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this._channelDataService.getChannel();
  }
}

// Resolves jatak default data

@Injectable({
  providedIn: 'root',
})

export  class JatakDefaultResolver {
  constructor(
    private _jatakService: JatakService,
    private _errorHandlerService: ErrorhandlerService
  ) {
  }

  resolve(route:ActivatedRouteSnapshot){
    return this._jatakService.getJatakDefaultText().pipe(
      catchError((err) => this._errorHandlerService.routeResolverError(err))
    )
  }
}
