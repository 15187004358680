export interface JatakLiveList {
  id: string;
  title: string;
  max_items: string;
  order: string;
  start_date: string;
  end_date: string;
  turnover: string;
}

export interface JatakLive {
  id: number | string;
  title: string;
  startDate: string;
  endDate: string;
  liveStartDate: string;
  liveEndDate: string;
  shouldLike: number | boolean;
  templateId?: any;
  products: JatakLiveProduct[];
  isLive: boolean;
}

export interface JatakLiveProduct {
  productId: number;
  templateProductId?: any;
  productTitle: string;
  price: string;
  quantity: number;
  keyword: string;
  private: boolean;
  info: string;
  maxOrder: number | boolean;
  maxOrderCount: number;
}

export interface ProductReportData {
  average_order: number;
  title: string;
  products: Product[];
  start_date: string;
  end_date: string;
  live_end_date: string;
  live_start_date: string;
  total_orders: number;
  total_extra_orders: number;
  total_quantity: number;
  total_sold: number;
  total_turnover: number;
}

export interface Product {
  id: number;
  quantity: number;
  sold: number;
  title: string;
  maxOrder: number;
  maxOrderCount: number;
  keyword: string;
  extra_orders: number;
  turnover: number;
  info_commands: number;
  showContent?: boolean;
  inSearch?: boolean;
}

export interface JatakLiveComments {
  last_id: number;
  last_head_id: number;
  IsLive: boolean;
  data: CommentDetail[];
  separator: JatakLiveCommentSeparator[];
  hasGoneLive: boolean;
}

export interface CommentDetail {
  reply_status: any;
  id: number;
  from_id: string;
  from_name: string;
  comment: string;
  reply_comment: string;
  feed_comment_reply: string;
}

export interface JatakLiveCommentSeparator {
  id: number;
  date: string;
}

export interface FbInsights {
  total_likes: number;
  total_views: number;
}

export interface JatakLiveExcel {
  productTitle: string;
  price: number;
  quantity: number;
  keyword: string;
  productInfo: string;
}

export interface FbTokenValidityCheck {
  error: boolean;
  message: string;
  valid: boolean;
}
