import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import {
  AdminReport, DashboardLatestYearMonth,
  GeneralReport,
  JatakReport,
  PromoReport
} from 'app/shared/interface';
import { ApiService } from 'app/core/services/api.service';
import { HttpParams } from '@angular/common/http';
// *** Contains methods to get Reports on: ***
// getGeneralReport() fetch the general report through the passed url
//getAdminReport() fetch the report for board of directors through passed url
// getJatakReport() fetch the jatak report through the passed url
//getPromoReport() fetch the promo report through the passed url
@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  constructor(private _apiService: ApiService) {
  }

  getGeneralReport(pyear: string | number, pmonth: string | number): Observable<GeneralReport> {
    const url = `dashboard/report/general?year=${pyear}&month=${pmonth}`;
    return this._apiService.get(url).pipe(map((res) => res['data']));
  }

  getAdminReport(pyear: string, pweek: string): Observable<AdminReport> {
    const url = `dashboard/report/admin?year=${pyear}&week=${pweek}`;
    return this._apiService.get(url).pipe(map((res) => res['data']));
  }

  getJatakReport(pyear: string, pweek: string): Observable<JatakReport> {
    const url = `dashboard/report/jatak?year=${pyear}&week=${pweek}`;
    return this._apiService.get(url).pipe(map((res) => res['data']));
  }

  getPromoReport(pyear: string, pweek: string): Observable<PromoReport> {
    const url = `dashboard/report/promo?year=${pyear}&week=${pweek}`;
    return this._apiService.get(url).pipe(map((res) => res['data']));
  }


  getYearMonth(): Observable<DashboardLatestYearMonth> {
    return this._apiService.get('dashboard/report/date').pipe(map((res) => res['data']));
  }

  // quicktogo statistics info

  getBagsReport(year, category): Observable<any> {
    let selectedCategory;
    if (category === 7) {
      selectedCategory = '';
    } else {
      selectedCategory = category;
    }
    const url = `bags/statistics?preset=${year}&category=${selectedCategory}`;
    return this._apiService.get(url).pipe(map((res) => res['data']));
  }

  getCategoryList() {
    let url = 'bags/categories';
    return this._apiService.get(url).pipe(map((res) => res['data']));
  }

  getTurnOverReport() {
    let url = 'bags/turnover';
    return this._apiService.get(url).pipe(map((res) => res['data']));
  }

  getLuckyBagReport(filters) {
    let queryParams = new HttpParams();
    filters.forEach(filter => {
      queryParams = queryParams.append('filters[]', filter);
    });
    let url = `bags/detail-graph`;
    return this._apiService.get(url, { params: queryParams }).pipe(map((res) => res['data']));
  }
}
