import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ErrorhandlerService, NotificationsService } from 'app/core/services';
import { ThankYouSms } from 'app/features/order-and-pickup/order/interface';
import { OrderService } from 'app/features/order-and-pickup/order/services';
import { SMS_VALIDATION_MESSAGES } from 'app/shared/data';
import { ValidationMessage } from 'app/shared/interface';
import { takeWhile } from 'rxjs/operators';
import { checkWhiteSpace } from 'app/core/validators';

@Component({
  selector: 'coop-thank-you-sms',
  templateUrl: './thank-you-sms.component.html',
  styleUrls: ['./thank-you-sms.component.scss'],
})
export class ThankYouSmsComponent implements OnInit, OnDestroy {
  smsMessageForm: FormGroup;
  message: ThankYouSms;
  isProcessing = false;
  formSaved = false;
  validationMessages: ValidationMessage = SMS_VALIDATION_MESSAGES;

  private _subscriptionState: boolean = true;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _router: Router,
    private _errorHandlerService: ErrorhandlerService,
    private _orderService: OrderService
  ) {}
//** initialize component
 //set up form and validates the input value
  //set message with the value fetched from resolver data 'thankYOuSms'
  //if message is present => patch the message to smsMessageForm
  ngOnInit() {
    this.smsMessageForm = this._formBuilder.group({
      sms: [
        '',
        [Validators.required, checkWhiteSpace, Validators.maxLength(300)],
      ],
    });
    this.message = this._activatedRoute.snapshot.data['thankYouSms'];
    if (this.message) {
      this.smsMessageForm.patchValue(this.message);
    }
  }
//** destroy component
  ngOnDestroy() {
    this._subscriptionState = false;
  }
//** send sms
  //if smsMessageForm status is equal to 'INVALID' => handleError()
  // if isProcessing is false => set is Processing to true
  //                            post smsMessageForm value and if success => _success() else _handleError()
  sendSms() {
    if (this.smsMessageForm.status === 'INVALID') {
      this._handleError('');
      return;
    }
    if (!this.isProcessing) {
      this.isProcessing = true;
      this._orderService
        .saveThankYouMessage(this.smsMessageForm.value)
        .pipe(takeWhile(() => this._subscriptionState)) //unsubscribe
        .subscribe({
          next: () => this._success(),
          error: (err) => this._handleError(err),
        });
    }
  }
// ** navigate back to previous page
  goBack() {
    this._router.navigate([`/butikker/indstillinger`]).then();
  }
//** sms sent is successful
  //notify 'Completed with success' and navigate back to previous page
  private _success() {
    this.isProcessing = false;
    this.formSaved = true;
    NotificationsService.notify('Afsluttet med success', 'success', 'top');
    this._router.navigate([`/butikker/indstillinger`]).then();
  }
//** handles error
  private _handleError(error: any) {
    this._errorHandlerService.handleError(
      error || { code: -400 },
      'orderThankYouSms'
    );
    this.isProcessing = false;
  }
}
