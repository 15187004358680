<div
  class="preview-overlay-container"
  (click)="onClickOutside()"
  [class.processing]="!previewLoaded && (tab === 'dit' || tab === 'screen')"
>
  <div
    class="processing-loader"
    *ngIf="!previewLoaded && (tab === 'dit' || tab === 'screen')"
  ></div>
  <div
    (click)="onHandleClick($event)"
    class="preview-modal"
    [ngClass]="tab"
    [@fade]="'in'"
    [class.text-green]="tab === 'screen' && isCoop365User"
  >
    <span class="close-preview" (click)="closePreview()"
    ><i class="icon icon-close-circle"></i
    ></span>
    <ng-container *ngIf="tab === 'coop'">
      <div class="preview-header-section" [class.newsHeader]="modes.newsMode">
        <h2 class="preview-time-section" [class.newsTime]="modes.newsMode">
          I DAG KL. {{ coopTimeFormat }}
        </h2>
        <h2 class="preview-title-section" [class.newsTitle]="modes.newsMode">
          <span *ngIf="!modes.newsMode"
          >{{ modes.goodPriceMode ? 'God pris' : 'Tilbud' }} -
            {{ shopName }}</span
          >
          <span *ngIf="modes.newsMode">Nyhed - {{ shopName }}</span>
        </h2>
      </div>
      <div class="preview-content-section">
        <div class="preview-information-section" [class.news]="modes.newsMode">
          <div class="preview-information" [class.newsInfo]="modes.newsMode">
            <div class="preview-info-details">
              <h5 *ngIf="!modes.newsMode">GÆLDER {{ previewDateText }}</h5>
              <h4>{{ previewValue.title }}</h4>
            </div>
            <div class="preview-info-bottom-section" *ngIf="!modes.newsMode">
              <div
                class="editor-content not-news"
                [innerHTML]="previewValue.longDescription"
              ></div>
              <ng-container *ngIf="!modes.goodPriceMode">
                <div class="preview-price-details">
                  <h2 class="preview-price">
                    {{ offerPrice[0] }} kr.
                    pr. {{ previewValue.inAppParameters.priceDescription }} {{previewValue.inAppParameters.uom }}
                  </h2>
                  <!--                  <h2 class="preview-price-desc">-->
                  <!--                    {{ previewValue.inAppParameters.priceDescription }} {{previewValue.inAppParameters.uom }}-->
                  <!--                  </h2>-->
                </div>
                <div class="preview-before-price-details">
                  <h2 class="preview-before-price">
                    Før pris: {{ price[0] }} kr.
                    pr. {{ previewValue.inAppParameters.priceDescription }} {{previewValue.inAppParameters.uom }}
                  </h2>
                </div>
              </ng-container>
              <div class="preview-price-details" *ngIf="modes.goodPriceMode && previewValue.inAppParameters.price !== ''">
                <h2 class="preview-price">
                  {{ price[0] }} kr.
                  pr. {{ previewValue.inAppParameters.priceDescription }} {{previewValue.inAppParameters.uom }}
                </h2>
                <!--                <h2 class="preview-price-desc">-->
                <!--                  {{ previewValue.inAppParameters.priceDescription }} {{previewValue.inAppParameters.uom }}-->
                <!--                </h2>-->
              </div>
            </div>
          </div>
          <div class="preview-image preview-image-container" [class.newsImage]="modes.newsMode"
               [class.has-video]="previewValue.hasVideo">
            <img [src]="previewValue.thumbnail" alt=""/>
          </div>
          <div class="preview-information newsInfo" *ngIf="modes.newsMode">
            <div class="preview-info-bottom-section">
              <div
                class="editor-content"
                [innerHTML]="previewValue.longDescription"
              ></div>
            </div>
          </div>
        </div>
        <div class="preview-button-section" [class.newsButton]="modes.newsMode">
          <div class="btn-div" [class.newsButtonDiv]="modes.newsMode">
            <span
            >Se dine lokale {{ modes.newsMode ? 'nyheder' : 'varer' }}</span
            >
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="tab === 'facebook'">
      <div class="fb-preview-header-section">
        <div class="fb-page-image-section">
          <img src="{{ facebookDetails.profileImage }}" alt=""/>
        </div>
        <div class="fb-header-information">
          <p class="fb-page-name">{{ facebookDetails.name }}</p>
          <p class="fb-publish-info">
            Published by <span>Quick Coop</span>[?]. July 9.
            <i class="icon-globe"></i>
          </p>
        </div>
      </div>
      <div class="fb-preview-description-section">
        <p>{{ previewValue.title }}</p>

        <p [innerHTML]="previewValue.longDescription"></p>


        <div style="margin: 5px 0">
          <p *ngIf="modes.goodPriceMode && previewValue.inAppParameters.price " style="margin-bottom: 5px">
            {{ previewValue.inAppParameters.price }} kr.
            pr. {{ previewValue.inAppParameters.priceDescription !== '1' ? previewValue.inAppParameters.priceDescription : '' }} {{ previewValue.inAppParameters.uom }}
          </p>
          <p *ngIf="!modes.newsMode && !modes.goodPriceMode">
            Tilbudspris: {{ previewValue.inAppParameters.offerPrice }} kr.
          </p>
          <p *ngIf="!modes.newsMode && !modes.goodPriceMode" style="margin-bottom: 5px">
            Før pris: {{ previewValue.inAppParameters.price }} kr.
            pr. {{  previewValue.inAppParameters.priceDescription !== '1' ? previewValue.inAppParameters.priceDescription : '' }} {{ previewValue.inAppParameters.uom }}
          </p>

        </div>


        <p *ngIf="!modes.newsMode && !modes.goodPriceMode">
          Tilbuddet gælder fra den {{ previewDateText }}
        </p>
      </div>
      <div class="preview-image-container fb-preview-image-section" [class.has-video]="previewValue.hasVideo">
        <img src="{{ previewValue.largeImage }}" alt=""/>
      </div>
      <div class="fb-option-section">
        <ul class="fb-option-list">
          <li>
            <i class="icon-like"></i>
            <span>Like</span>
          </li>
          <li>
            <i class="icon-sms"></i>
            <span>Comment</span>
          </li>
          <li>
            <i class="icon-share"></i>
            <span>Share</span>
          </li>
        </ul>
      </div>
    </ng-container>
    <ng-container *ngIf="tab === 'dit'">
      <div class="preview-border">
        <p>
          Lokal
          {{
          modes.newsMode
            ? 'nyhed'
            : modes.goodPriceMode
              ? 'god pris'
              : 'tilbud'
          }}
          fra {{ shopName }}
        </p>
      </div>
      <div class="preview-price-tag red-tag" *ngIf="!modes.newsMode">
        <div class="price-tag-img">
          <img
            src="assets/vendor/pintura-image-editor/presets/double_red_circle.svg"
            alt="price-tag"
            width="150"
            height="150"
            (load)="previewTagLoaded()"
          />
          <div class="price-tag-content">
            <p
              class="price-tag-weight"
              [class.weight-font-sm]="previewValue.weight.toString().length > 6"
            >
              {{ previewValue.weight }}
            </p>
            <p
              class="price-tag-price"
              [class.price-font-sm]="previewValue.price.toString().length > 3"
            >
              {{ previewValue.price }},-
            </p>
          </div>
        </div>
      </div>
      <div class="preview-location">
        <div class="location-container">
          <div class="location-icon">
            <img
              src="assets/icons/mapMarker.svg"
              alt=""
              width="20"
              height="30"
            />
          </div>
          <p>Find vej</p>
        </div>
      </div>
      <div class="preview-content-section">
        <div class="preview-information-section">
          <div class="preview-information">
            <div class="preview-image preview-image-container">
              <figure>
                <img
                  src="{{ previewValue.largeImage }}"
                  (load)="previewImageLoaded()"
                  alt="Product Image"
                />
              </figure>
            </div>
            <div
              class="preview-info-details"
              [class.news-info]="modes.newsMode"
            >
              <h4 class="preview-title">{{ previewValue.title }}</h4>
              <div class="preview-desc-container">
                <p class="preview-desc">{{ previewValue.longDescription }}</p>
                <p class="preview-duty-text" *ngIf="!modes.newsMode">
                  {{ previewValue.dutyText }}
                </p>
              </div>
              <p class="preview-date-range" *ngIf="!modes.newsMode">
                Gælder fra {{ previewValue.startDate | screenpreviewstart
                }}<br/>til og med {{ previewValue.endDate | screenpreviewend }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="tab === 'screen'">
      <div class="preview-border" [class.green]="isCoop365User"></div>
      <div
        class="preview-price-tag"
        [class.red-tag]="selectedPriceTag === 1"
        *ngIf="!modes.newsMode"
      >
        <div class="price-tag-img">
          <img
            src="assets/vendor/pintura-image-editor/presets/coop365_star.svg"
            alt="price-tag"
            width="150"
            height="150"
            *ngIf="selectedPriceTag === 0 && isCoop365User"
            (load)="previewTagLoaded()"
          />
          <img
            src="assets/vendor/pintura-image-editor/presets/yellow_star.svg"
            alt="price-tag"
            width="150"
            height="150"
            *ngIf="selectedPriceTag === 0 && !isCoop365User"
            (load)="previewTagLoaded()"
          />
          <img
            src="assets/vendor/pintura-image-editor/presets/double_red_circle.svg"
            alt="price-tag"
            width="150"
            height="150"
            *ngIf="selectedPriceTag === 1"
            (load)="previewTagLoaded()"
          />
          <div class="price-tag-content">
            <p
              class="price-tag-weight"
              [class.weight-font-sm]="previewValue.weight.toString().length > 6"
            >
              {{ previewValue.weight }}
            </p>
            <p
              class="price-tag-price"
              [class.price-font-sm]="previewValue.price.toString().length > 3"
            >
              {{ previewValue.price }},-
            </p>
          </div>
        </div>
      </div>
      <div class="preview-coop-logo" [class.coop365-logo]="isCoop365User">
        <img
          src="assets/icons/coop-logo-white.svg"
          alt="coop-logo"
          width="30"
          height="15"
          (load)="previewLogoLoaded()"
          *ngIf="!isCoop365User"
        />
        <img
          src="assets/icons/coop365-logo.svg"
          alt="coop-logo"
          width="80"
          height="50"
          (load)="previewLogoLoaded()"
          *ngIf="isCoop365User"
        />
      </div>
      <div class="preview-content-section">
        <div class="preview-information-section">
          <div class="preview-information">
            <div class="preview-image preview-image-container" [class.has-video]="previewValue.hasVideo">
              <figure>
                <img
                  src="{{ previewValue.largeImage }}"
                  (load)="previewImageLoaded()"
                  alt="Product Image"
                />
              </figure>
            </div>
            <div
              class="preview-info-details"
              [class.news-info]="modes.newsMode"
            >
              <h4 class="preview-title">{{ previewValue.title }}</h4>
              <p class="preview-desc">{{ previewValue.longDescription }}</p>
              <p class="preview-duty-text" *ngIf="!modes.newsMode">
                {{ previewValue.dutyText }}
              </p>
              <p class="preview-date-range" *ngIf="!modes.newsMode">
                Gælder fra<br/>
                {{ previewValue.startDate | screenpreviewstart }} til<br/>
                {{ previewValue.endDate | screenpreviewend }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
