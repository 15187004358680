<!-- sms box -->
<div class="sms-box-modal">
  <div class="sms-box-container">
    <div class="close" (click)="goBack()">
      <i class="icon-close"></i>
    </div>
    <div class="sms-box-content">
      <div class="sms-box-title">
        <span>Standard SMS besked</span>
      </div>
      <div class="sms-box-message">
        <form class="sms-box-input" [formGroup]="smsMessageForm" id="sms-form">
          <div class="row form-group" controlErrorContainer>
            <label for="sms-message"></label>
            <textarea
              name=""
              id="sms-message"
              formControlName="sms"
              placeholder="Opsæt en standard besked til kunden som tak for ordren."
              maxlength="300"
              [customErrors]="validationMessages['sms']"
            ></textarea>
          </div>
        </form>
      </div>
    </div>
    <div class="wrapper send-btn">
      <div class="row">
        <button
          class="login-btn btn-black-login"
          (click)="sendSms()"
          type="submit"
          form="sms-form"
        >
          Gem
        </button>
      </div>
    </div>
  </div>
</div>
