<section class="app-page list-component list-page-with-search product-list">
  <div class="fixed-header">
    <coop-pageheader
      [pageTitle]="pageTitle"
      [isChild]="true"
      [routeTo]="parentUrl"
    ></coop-pageheader>
    <div class="fixed-header-content">
      <div class="search-box-container">
        <div class="search-box">
          <input
            type="text"
            placeholder="Søg"
            #searchBox
            (keyup.enter)="search(searchBox.value)"
          />
          <i
            class="icon-search search-button right-search"
            (click)="search(searchBox.value)"
          ></i>
          <i
            class="icon icon-close-circle search-button left-close"
            (click)="closeSearch()"
            *ngIf="inputInSearch"
          ></i>
        </div>
      </div>
    </div>
  </div>
  <div class="page-container">
    <div class="item-list">
      <ng-container *ngFor="let product of productList">
        <a routerLink="{{ currentUrl }}/details/{{ product.id }}" class="item">
          <figure class="item-image">
            <img src="{{ product.largeImage }}" alt="" />
          </figure>
          <div class="item-detail">
            <h3 class="item-title">{{ product.title }}</h3>
            <div class="item-price d-flex">
              <span>Pris:</span>
              <span>{{ product.price }} kr</span>
            </div>
            <div class="item-order d-flex">
              <span>Total tilgængelig (ikke solgt)</span>
              <span *ngIf="!product.totalOfferUnlimited"
                >{{ product.totalOffer }} stk.</span
              >
              <span *ngIf="product.totalOfferUnlimited">Ubegrænset</span>
            </div>
            <div class="item-active d-flex">
              <span>Afhentningsperiode:</span>
              <span *ngIf="product.pickUpPeriod"
                >{{ product.pickUp.StartDate | dayandmonth }} -
                {{ product.pickUp.EndDate | dayandmonth }}</span
              >
              <span *ngIf="!product.pickUpPeriod">hele tiden</span>
            </div>
          </div>
        </a>
      </ng-container>
      <h5 class="empty-message" *ngIf="productList.length === 0">
        Beklager, ingen produkter tilgængelige
      </h5>
    </div>
    <a
      class="login-btn btn-white-login btn-medium"
      (click)="loadMore()"
      *ngIf="productListData.links.next"
      >Indlæs mere..</a
    >
  </div>
  <!-- for filter in product -->
  <a
    class="big-add-btn-left show-hide-btn"
    (click)="showHideFilterBtns()"
    *ngIf="showFilterAndAdd"
  >
    <i class="icon-filter"></i>
  </a>
  <div
    class="add-actions-container filter-products"
    [@slideUpDown]="showFilters"
  >
    <div class="close-btn" (click)="showHideFilterBtns()">
      <i class="icon icon-close-circle"></i>
    </div>
    <div class="wrapper">
      <h4>Filterindstilling</h4>
      <div class="sort-after">
        <h5>Sorter efter</h5>
        <ul>
          <li>
            <a
              class="btn"
              [class.active]="currentLatestFilter"
              (click)="
                loadProductListData(currentCategory, true, currentActiveFilter)
              "
              ><i class="ti-arrow-down"></i>Nyeste varer</a
            >
          </li>
          <li>
            <a
              class="btn"
              [class.active]="!currentLatestFilter"
              (click)="
                loadProductListData(currentCategory, false, currentActiveFilter)
              "
              >Ældste varer</a
            >
          </li>
        </ul>
      </div>
      <div class="sort-type">
        <h5>Type</h5>
        <ul>
          <li>
            <a
              class="btn"
              [class.active]="currentActiveFilter === 'Alle'"
              (click)="
                loadProductListData(
                  currentCategory,
                  currentLatestFilter,
                  'Alle'
                )
              "
              >Alle</a
            >
          </li>
          <li>
            <a
              class="btn"
              [class.active]="currentActiveFilter === 'active'"
              (click)="
                loadProductListData(
                  currentCategory,
                  currentLatestFilter,
                  'active'
                )
              "
              >Aktive varer</a
            >
          </li>
          <li>
            <a
              class="btn"
              [class.active]="currentActiveFilter === 'inactive'"
              (click)="
                loadProductListData(
                  currentCategory,
                  currentLatestFilter,
                  'inactive'
                )
              "
              >Inaktive varer</a
            >
          </li>
        </ul>
      </div>
      <div class="sort-category">
        <h5>Kategorier</h5>
        <ul>
          <li>
            <a
              class="btn"
              [class.active]="currentCategory === 'Alle'"
              (click)="
                loadProductListData(
                  'Alle',
                  currentLatestFilter,
                  currentActiveFilter
                )
              "
              >Alle</a
            >
          </li>
          <li>
            <a
              class="btn"
              [class.active]="currentCategory === 'Delikatessen'"
              (click)="
                loadProductListData(
                  'Delikatessen',
                  currentLatestFilter,
                  currentActiveFilter
                )
              "
              >Delikatessen</a
            >
          </li>
          <li>
            <a
              class="btn"
              [class.active]="currentCategory === 'Bageren'"
              (click)="
                loadProductListData(
                  'Bageren',
                  currentLatestFilter,
                  currentActiveFilter
                )
              "
              >Bageren</a
            >
          </li>
          <li>
            <a
              class="btn"
              [class.active]="currentCategory === 'Slagter'"
              (click)="
                loadProductListData(
                  'Slagter',
                  currentLatestFilter,
                  currentActiveFilter
                )
              "
              >Slagter</a
            >
          </li>
          <li>
            <a
              class="btn"
              [class.active]="currentCategory === 'Fisk'"
              (click)="
                loadProductListData(
                  'Fisk',
                  currentLatestFilter,
                  currentActiveFilter
                )
              "
              >Fisk</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
  <a
    routerLink="{{ currentUrl }}/skab"
    [queryParams]="{ origin: 'list' }"
    class="big-add-btn add-btn show-hide-btn flex-center"
    *ngIf="showFilterAndAdd"
  >
    <i class="icon icon-add"></i>
  </a>
</section>
<router-outlet></router-outlet>
