import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, CanActivateFn } from '@angular/router';
import { TokenService, UserService } from 'app/core/services';

@Injectable({
  providedIn: 'root'
})
export class UserGuard {
  constructor(private _router: Router,
              private _userService: UserService
  ) {
  }

  routeToUserHome() {
    if (TokenService.getUserType() === '1') {
      // route to shop
      this._router.navigate(['/butikker']).then();
      return true;
    }
    if (TokenService.getUserType() === '2') {
      // route to board of directors
      this._router.navigate(['/bestyrelse']).then();
      return true;
    }
    return false;
  }

  checkIfCorrectUserType(next: ActivatedRouteSnapshot) {
    const code = next.queryParams['code'];
    if (code) {
      return false;
    }
    if (
      TokenService.getUserType() === '1' ||
      TokenService.getUserType() === '2'
    ) {
      return true;
    }
    this._userService.removeUserDetails(false);// Clears LocalStorage and handles IndexedDB
    this._router.navigate(['/login']).then();
    return false;
  }

  checkIfIsShop() {
    if (TokenService.getUserType() === '1') {
      return true;
    }
    this._router.navigate(['/']).then();
    return false;
  }

  checkIfIsBoardmember() {
    if (TokenService.getUserType() === '2') {
      return true;
    }
    this._router.navigate(['/']).then();
    return false;
  }

}


export const RouteToUserType: CanActivateFn = () => {
  return inject(UserGuard).routeToUserHome();
};

export const CheckCorrectUserType: CanActivateFn = (next) => {
  return inject(UserGuard).checkIfCorrectUserType(next);
};

export const CheckIfIsShop: CanActivateFn = () => {
  return inject(UserGuard).checkIfIsShop();
};
export const CheckIfIsBoardMember: CanActivateFn = () => {
  return inject(UserGuard).checkIfIsBoardmember();
};


