import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener, Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import {
  fadeInOutAnimationMFL,
  mflPopoutAnimation,
  mflPopupAnimation,
} from 'app/shared/animations';

@Component({
  selector: 'coop-no-price-popup',
  templateUrl: './no-price-popup.component.html',
  styleUrls: ['./no-price-popup.component.scss'],
  animations: [mflPopoutAnimation, fadeInOutAnimationMFL, mflPopupAnimation],
})
export class NoPricePopupComponent implements OnInit, OnDestroy {
  @Input() type = ''
  pAnimate = 'inactive';
  @Output() showPopup: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() saveWithoutPrice: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @HostBinding('@fadeInOutAnimationMFL') get fadeInOutAnimationMFL() {
    return 'inactive';
  }

  @HostListener('@fadeInOutAnimationMFL.done') onDone() {
    this.pAnimate = 'active';
  }
  constructor() {}

  ngOnInit() {
  }

  ngOnDestroy() {
    this.pAnimate = 'inactive';
  }

  saveWithNoPrice() {
    setTimeout(() => {
      this.saveWithoutPrice.emit(true);
    }, 250);
  }

  cancel() {
    this.hidePopup();
  }

  slideDone(e): void {
    if (e.toState === 'inactive' && e.fromState !== 'void') {
      this.pAnimate = 'inactive';
    }
  }

  navigateTo() {
    this.hidePopup();
  }

  hidePopup() {
    this.pAnimate = 'inactive';
    setTimeout(() => {
      this.showPopup.emit(false);
    }, 250);
  }
}
