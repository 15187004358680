import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService, TokenService } from 'app/core/services';
import { TemplateFolderData, TemplateListData } from 'app/shared/interface';
import { PromoTemplate } from 'app/features/offer';

@Injectable({
  providedIn: 'root',
})
export class TemplateService {
  constructor(private _apiService: ApiService) {}

  getSingleTemplate(id: number) {
    const url = `template/single/${id}`;
    return this._apiService.get(url).pipe(map((res) => res['template']));
  }

  saveTemplate(params: PromoTemplate, edit: boolean): Observable<any> {
    if (edit) {
      return this._apiService.post(
        `template/own/${params.id}/update`,
        ApiService.CreateFormData(params)
      );
    }
    return this._apiService.post(
      'template/own',
      ApiService.CreateFormData(params)
    );
  }

  deleteTemplate(id: string): Observable<any> {
    return this._apiService.post(`template/${id}/delete`, {});
  }

  getTemplateFolder(
    newsMode: boolean,
    goodPriceMode: boolean
  ): Observable<TemplateFolderData> {
    const userType = TokenService.getUserType();
    let folderType = newsMode ? '2' : '1';
    folderType = goodPriceMode ? '3' : folderType;
    const board = userType === '1' ? '0' : '1';
    const url = `template/folders?folderType=${folderType}&board=${board}`;
    return this._apiService.get(url);
  }

  getTemplates(
    clientId: string,
    store: string,
    folderId: string,
    page: number,
    limit: number,
    newsMode?: boolean,
    goodPriceMode?: boolean,
    searchQuery?: string
  ): Observable<TemplateListData> {
    const userType = TokenService.getUserType();
    let templateType = newsMode ? '2' : '1';
    templateType = goodPriceMode ? '3' : templateType;
    const board = userType === '1' ? '0' : '1';
    let url = `template?kardex=${clientId}&store=${store}&folderId=${folderId}&type=${templateType}&board=${board}&page=${page}&limit=${limit}`;
    url = searchQuery ? `${url}&search=${searchQuery}` : url;
    return this._apiService.get(url);
  }

  searchTemplate(searchTerms: string, templateType: string, board: number) {
    const url = `template/search?searchTerms=${searchTerms}&templateType=${templateType}&board=${board}`;
    return this._apiService.get(url);
  }
}
