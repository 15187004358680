export const Product_Validation_Messages = {
  /* startDate: {
      invalid: 'Den seneste dato for afhentning er før produktionsdatoen - venligst vælg en ny dato'
  },
  dateMismatchStartEnd : {
      mismatch : 'Sluttid skal være senere end starttidspunktet'
  }, */
  title: {
    required: 'Udfyld venligst feltet',
  },
  description: {
    required: 'Udfyld venligst feltet',
  },
  largeImage: {
    required: 'Upload venligst billede',
  },
  minimumOrder: {
    required: 'Udfyld venligst feltet',
    minimumOrder:
      'Minimumskøb pr. Dag bør ikke være mere end Maks. antal ordrer pr. dag.',
  },
  price: {
    required: 'Udfyld venligst feltet',
    min: 'Prisen skal være over 0,00',
    max: 'Prisen må ikke overstige 9999,99.',
    invalid: 'Brug venligst det danske prisformat (f.eks. 6.789,45)',
  },
  totalOffer: {
    required: 'Udfyld venligst feltet',
    min: 'Total lagerbeholdning skal være større end 0.',
    offerPerDay:
      'Total lagerbeholdning skal være større end eller lig med maks. antal bestillinger per dag.',
  },
  offerPerDay: {
    required: 'Udfyld venligst feltet',
    min: 'Maks. antal bestillinger per dag skal være større end 0.',
    offerPerDay:
      'Total lagerbeholdning skal være større end eller lig med maks. antal bestillinger per dag.',
  },
  productionTime: {
    required: 'Udfyld venligst feltet',
    min: 'Produktionstiden skal være over 0 timer og 0 minutter',
  },
  startDate: {
    required: 'Udfyld venligst feltet',
    dateMismatch: 'Sluttidspunkt skal være senere end Starttidspunkt',
  },
  endDate: {
    required: 'Udfyld venligst feltet',
    dateMismatch: 'Sluttidspunkt skal være senere end Starttidspunkt',
  },
};
