import { inject, Injectable } from '@angular/core';
import { CanDeactivateFn, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import {
  OrderListComponent,
  DataService
} from 'app/features/order-and-pickup/order';

@Injectable({
  providedIn: 'root'
})
export class OrderGuardService {
  constructor(private _dataService: DataService) {
  }

  canDeactivate(
    nextState?: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (nextState.url.indexOf('/order/redigere') === -1) {
      this._dataService.changeTab(1);
      return true;
    }
    return true;
  }
}

export const OrderGuard: CanDeactivateFn<OrderListComponent> = (component, currentRoute, currentState, nextState) => {
  return inject(OrderGuardService).canDeactivate(nextState);
};
