import { mergeMap } from 'rxjs/operators';
import { take } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { takeWhile } from 'rxjs/operators';

import {
  ErrorhandlerService,
  NotificationsService,
  CreateOptionDataService,
  DateFormatService,
  FileReaderService
} from 'app/core/services';
import {
  checkIfEndDateIsLessThanStartDate,
  checkIfXinDescription, checkMaxLength,
  checkWhiteSpace
} from 'app/core/validators/custom.validator';
import { COMMON_DATETIMEPICKER_SETTINGS, COMMON_SELECT_SETTINGS } from 'app/shared/data';
import { ValidationMessage } from 'app/shared/interface';
import { DateToString } from 'app/shared/pipes';
import {
  SpecialEvent,
  SpecialPoll,
  PollService,
  Poll_Validation_Messages
} from 'app/features/poll';
import { environment } from 'environments/environment';
import { MbscDatepickerOptions } from '@mobiscroll/angular';
import { DatepickerBase } from '@mobiscroll/angular/dist/js/core/components/datepicker/datepicker';

@Component({
  selector: 'coop-special-poll-create-edit',
  templateUrl: './special-poll-create-edit.component.html',
  styleUrls: ['./special-poll-create-edit.component.scss'],
  providers: [DateToString]
})
export class SpecialPollCreateEditComponent implements OnInit, OnDestroy {
  pageTitle: string = 'Opret afstemning';
  previousUrl: string;
  specialPollForm: FormGroup;
  isProcessing: boolean = false;
  formSaved: boolean = false;
  currentUrl: string;
  parentUrl: string = '/bestyrelse';
  successUrl: string;
  routeId: string;
  imageUrl = {
    largeImage: 'assets/images/camera@3x.jpg',
    thumbnail: 'assets/images/camera@3x.jpg'
  };
  hasImage: boolean = false;
  editMode: boolean = false;
  specialEventId: string;
  showDeleteBox: boolean = false;
  showMsg: boolean = false;
  isActive: boolean = true;
  pollStarted: boolean = false;
  pollEnded: boolean = false;
  hasVoted: boolean = false;
  showResults: boolean = false;
  showXinDescError: boolean = false;
  showDurationPopup: boolean = false;
  answerCount: number = 0;
  candidateList: Array<string> = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  colors = ['shade1', 'shade2', 'shade3', 'shade4', 'shade5'];
  specialEventList: SpecialEvent[];
  specialPoll: SpecialPoll;
  eventRegisterEnd: string;
  downloadUrl: string;
  downloadFile = {
    name: '',
    file: null
  };
  origin: string = '';

  electionEntriesPickerSettings = {
    ...COMMON_SELECT_SETTINGS, ...{
      data: Array.apply(null, Array(11))
        .map((_: any, i: any) => i + ' kryds')
        .splice('1')
    }
  };
  datePickerSettings: MbscDatepickerOptions = {
    ...COMMON_DATETIMEPICKER_SETTINGS, ...{
      dateWheels: 'YYYY MMMM DD',
      dateFormat: 'YYYY-MM-DD',
      stepMinute: 15,
      onOpen: (_, inst) => this._setupDateTime(inst),
      onClose: (_, inst) => this._initDurationPopup(inst)
    }
  };
  validationMessages: ValidationMessage = Poll_Validation_Messages;
  visible: boolean = false;
  private _subscriptionState: boolean = true;

  // *** Check if Poll start date is after event registration end date *** //
  // @params [c : FormControl] => Start date
  // @return [Object or null] => If
  private _checkPollStart(
    c: AbstractControl
  ): { [key: string]: { [key: string]: boolean } } | null {
    const startDate = moment(c.get('startDate').value).toDate();
    const expiryDate = moment(this.eventRegisterEnd).toDate();
    if (expiryDate > startDate) {
      return {
        dateMismatchStartExpire: {
          mismatch: true
        }
      };
    }
    return null;
  }

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _dateToString: DateToString,
    private _pollService: PollService,
    private _createOptionService: CreateOptionDataService,
    private _router: Router,
    private _errorHandlerService: ErrorhandlerService
  ) {
  }

  ngOnInit() {
    this._activatedRoute.url
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe((urlSegment) => {
        if (urlSegment.length > 2) {
          this.routeId = urlSegment[2].path;
        } else {
          this.routeId = urlSegment[1].path;
        }
      });
    this.specialEventList = this._activatedRoute.snapshot.data['specialEvents'];
    this.eventRegisterEnd = this.specialEventList.length
      ? this.specialEventList[0].expirationDate
      : '';
    this.specialPoll = this._activatedRoute.snapshot.data['specialPoll'];
    this.origin = this._activatedRoute.snapshot.queryParams['origin'];
    this.currentUrl = this._router.url;
    this.previousUrl = this.parentUrl + '/skab';
    this.successUrl = this.parentUrl + '/kalendar/afstemninger';
    document.body.classList.add('overlay');
    this.specialPollForm = this._formBuilder.group(
      {
        id: [''],
        eventId: [''],
        title: ['', [Validators.required, checkWhiteSpace, checkMaxLength]],
        imageUrl: ['', Validators.required],
        startDate: ['', Validators.required],
        endDate: ['', Validators.required],
        description: [
          '',
          [Validators.required, checkWhiteSpace, checkIfXinDescription]
        ],
        answers: this._formBuilder.array([]),
        electionEntries: [1, Validators.required],
        memberForMonth: [1, Validators.required]
      },
      {
        validators: [
          checkIfEndDateIsLessThanStartDate,
          this._checkPollStart.bind(this)
        ]
      }
    );
    if (this.specialPoll) {
      this.editMode = true;
      this.isActive = this.specialPoll.active === 1;
      this.pollStarted = this.specialPoll.started === 1;
      this.pollEnded = this.specialPoll.ended === 1;
      this.hasVoted = this.specialPoll.totalVotes !== 0;
      if (this.pollEnded || !this.isActive) {
        this.pageTitle = 'Afstemning';
        this._buildSpecialPollResults();
        this.showResults = true;
      }
      this.pageTitle = 'Rediger afstemning';
      if (this.editMode) {
        if (this.origin === 'list') {
          this.previousUrl = '/bestyrelse/afstemninger/';
          this.successUrl = this.previousUrl;
        }
        if (this.origin !== 'list') {
          this.previousUrl =
            this.parentUrl + '/kalendar/special/' + this.routeId;
        }
      }
      this._buildEditForm();
    } else {
      this._setDefaultImgTitleDesc();
      for (let i = 0; i < 2; i++) {
        this.addAnswer('');
      }
      this._setDefaultTimeOnCreate();
      this._setElectionEntries();
      this._selectSpecialEvent();
    }
  }

  ngOnDestroy(): void {
    document.body.classList.remove('overlay');
    this._subscriptionState = false;
  }

  imageProcessing(e) {
    this.isProcessing = e;
  }

  onSelect() {
    this.visible = !this.visible;
  }

  getImagesUrl(images) {
    this.specialPollForm.patchValue({
      imageUrl: images.largeImage
    });
  }

  initAnswer(answer?: string): FormGroup {
    return this._formBuilder.group({
      answer: [answer, [Validators.required, checkWhiteSpace]]
    });
  }

  addAnswer(answer: string) {
    const answers = this.specialPollForm.controls['answers'] as FormArray;
    const aa = this.initAnswer(answer);
    answers.push(aa);
    this.answerCount += 1;
  }

  removeAnswer(i: number) {
    const answer = this.specialPollForm.controls['answers'] as FormArray;
    answer.removeAt(i);
    this.answerCount -= 1;
  }

  deleteSpecialPoll(id: string) {
    if (!this.editMode) {
      this.back();
    }
    if (this.editMode) {
      this._deleteSpecialPoll(id, 'delete')
        .pipe(takeWhile(() => this._subscriptionState))
        .subscribe({
          next: () => {
            NotificationsService.notify(
              'Afstemning blev slettet',
              'success',
              'top'
            );
            this._success();
          },
          error: (err) => this._handleError(err)
        });
    }
  }

  downloadVotesAndUserList(id: string) {
    this._pollService
      .getSpecialVotesOrUsersList(id)
      .pipe(
        take(1),
        mergeMap((file) => this.loadFile(file))
      )
      .subscribe({
        next: () => this.download(),
        error: (err) => this._handleError(err)
      });
  }

  private loadFile(file) {
    this.downloadFile.file = file;
    return FileReaderService.readFile(file, file['type']);
  }

  private download() {
    FileReaderService.downloadFile(
      this.downloadFile,
      'Poll_' + '_' + new Date().getTime()
    );
  }

  showOverlayMsg() {
    this.showMsg = !this.showMsg;
  }

  // go back button using setPreviousUrl
  back() {
    this._router.navigate([this.previousUrl]).then();
  }

  save() {
    if (!this._isValid()) {
      return;
    }

    if (!this.isProcessing) {
      this.isProcessing = true;
      this.specialPollForm.patchValue({
        startDate: this._dateToString.transform(
          this.specialPollForm.value.startDate
        ),
        endDate: this._dateToString.transform(
          this.specialPollForm.value.endDate
        )
      });
      this.specialPollForm.value.electionEntries =
        this.specialPollForm.value.electionEntries
          .toString()
          .replace(' poster', '');
      this._pollService
        .saveSpecialPoll(this.specialPollForm.value, this.editMode)
        .pipe(takeWhile(() => this._subscriptionState))
        .subscribe({
          next: () => {
            const notificationMessage = this.editMode
              ? 'Afstemning er opdateret'
              : 'Afstemningen er oprettet';
            NotificationsService.notify(notificationMessage, 'success', 'top');
            this._success();
          },
          error: (err) => {
            if (err.code === 600) {
              const errMsg =
                'Der er ingen årsmøde, så afstemning kan ikke oprettes.';
              NotificationsService.notify(errMsg, 'error', 'top');
              this.isProcessing = false;
            } else {
              this._handleError(err);
            }
          }
        });
    }
  }

  // ** Prepare Form Data and check Validation ** //
  // @return [boolean] => return false if form is invalid
  private _isValid() {
    if (this.specialPollForm.status === 'INVALID') {
      this._handleError('');
      return false;
    }
    return true;
  }

  private _buildEditForm() {
    const answers = this.specialPoll.answers;
    for (const answer of answers) {
      if (answer.id !== 0) {
        this.addAnswer(answer.answer);
      }
    }
    this.specialPollForm.patchValue(this.specialPoll);
    this.imageUrl = {
      largeImage: this.specialPoll.imageUrl,
      thumbnail: this.specialPoll.imageUrl
    };
    this.hasImage = true;
    if (this.pollStarted && this.hasVoted) {
      this.specialPollForm.disable();
    }
  }

  private _selectSpecialEvent() {
    this.specialEventId = this.specialEventList.length
      ? this.specialEventList[0].id
      : '';
    this.specialPollForm.patchValue({
      eventId: this.specialEventId
    });
  }

  private _deleteSpecialPoll(id: string, type: string) {
    return this._pollService.deleteSpecialPoll(id, type);
  }

  private _buildSpecialPollResults() {
    const answers = this.specialPoll.answers;
    for (let i = 0; i < answers.length; i++) {
      answers[i].percentage = parseFloat(
        (
          (parseInt(answers[i].votes, 10) / this.specialPoll.totalVotes) *
          100
        ).toString()
      ).toFixed(0);
      answers[i].shade = this.colors[i];
    }
  }

  private _setDefaultImgTitleDesc() {
    const imgUrl = `${environment.mainurl}/events/images/email.png`;
    const desc =
      'Hvis du er tilmeldt årsmødet, kan du nu være med til at vælge, hvem der skal sidde i bestyrelsen i de næste år. \n\n' +
      '[Skriv mere om valgmulighederne]';
    this.imageUrl = {
      largeImage: imgUrl,
      thumbnail: imgUrl
    };
    this.hasImage = true;
    this.specialPollForm.patchValue({
      imageUrl: imgUrl,
      title: 'Valg til bestyrelsen er åbent',
      description: desc
    });
  }

  private _setDefaultTimeOnCreate() {
    const startDate = DateFormatService.timeIntervalFormat(
      moment().add(3, 'hours')
    );
    const endDate = moment(startDate).add(1, 'day');
    const currForm = this.specialPollForm;
    currForm.patchValue({
      startDate: startDate,
      endDate: endDate
    });
  }

  private _setElectionEntries() {
    this.specialPollForm.patchValue({
      electionEntries: '1 poster'
    });
  }

  private _setupDateTime(inst: DatepickerBase) {
    if (!this.editMode) {
      // Set Start Date at Today time 8:00
      if (
        inst._el.id === 'startDate' &&
        !this.specialPollForm.value.startDate
      ) {
        this.specialPollForm.patchValue({
          startDate: moment().hour(8).minute(0).toDate()
        });
      }

      // EndDate picker clicked
      // If no Startdate set it date to Today time 20:00
      if (
        inst._el.id === 'expiry' &&
        !this.specialPollForm.value.endDate &&
        !this.specialPollForm.value.startDate
      ) {
        this.specialPollForm.patchValue({
          endDate: moment().hour(20).minute(0).toDate()
        });
      }

      // EndDate picker clicked
      // If Startdate set it to StartDate and time at 20:00
      if (
        inst._el.id === 'expiry' &&
        this.specialPollForm.value.startDate &&
        !this.specialPollForm.value.endDate
      ) {
        const startDate = moment(this.specialPollForm.value.startDate);
        this.specialPollForm.patchValue({
          endDate: startDate.hour(20).minute(0).toDate()
        });
      }
    }
  }

  private _initDurationPopup(inst: DatepickerBase) {
    if (!this.editMode) {
      const elementID = inst._el.id;
      if (elementID === 'startDate' || elementID === 'expiry') {
        const startDate = moment(this.specialPollForm.value.startDate);
        const endDate = moment(this.specialPollForm.value.endDate);
        if (endDate.diff(startDate, 'minutes') > 2880) {
          this.showDurationPopup = true;
        }
      }
    }
  }

  private _success(): void {
    this._createOptionService.clearCreateOption();
    this.formSaved = true;
    this.isProcessing = false;
    this._router.navigate([this.successUrl]).then();
  }

  private _handleError(error): void {
    this.isProcessing = false;
    this._errorHandlerService.handleError(error || { code: -400 }, 'poll');
  }
}
