import { ChannelList } from 'app/shared/interface';

export const ChannelData: ChannelList[] = [
  {
    channel: 'coop',
    icon: 'icon-coopapp',
    name: 'CoopApp',
  },
  {
    channel: 'facebook',
    icon: 'icon icon-facebook',
    name: 'Facebook',
  },
  {
    channel: 'sms',
    icon: 'icon-sms',
    name: 'SMS',
  },
  {
    channel: 'mail',
    icon: 'icon-email',
    name: 'Email',
  },
  {
    channel: 'web',
    icon: 'ti-world',
    name: 'Web',
  },
];
