import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  constructor() {}

  // Convert base64/URLEncoded data component to raw binary data held in a string
  // @params [dataURI : base64 data] => Base64/URLencoded data of the image
  // @return [Blob] => the blob of the bas64 data
  static base64toBlob(base64File): Blob {
    let byteString;
    if (base64File.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(base64File.split(',')[1]);
    } else {
      byteString = decodeURI(base64File.split(',')[1]);
    }

    // separate out the mime component
    const mimeString = base64File.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }
// *** It gets the extension of a file ***
  // @params [file:string] => full name of the file
  // @returns file format
  static getFileFormat(file) {
    return file.substring(file.lastIndexOf('.') + 1, file.length) || file;
  }

  static getFileName(file) {
    return file.substring(0, file.lastIndexOf('.')) || file;
  }

  static getBytestoMB(bytes: number) {
    // in MB
    if (bytes === 0) {
      return 0;
    }
    return parseFloat((bytes / Math.pow(1024, 2)).toFixed(2));
  }

  static getPatchableUrl(images): Array<string> {
    const patchableUrl = [];
    for (const img of images) {
      patchableUrl.push({ url: img, hasImage: true });
    }
    for (let i = 0; i < 5 - images.length; i++) {
      patchableUrl.push({
        url: '../../../assets/images/camera@3x.jpg',
        hasImage: false,
      });
    }
    return patchableUrl;
  }
}
