import { VideoUrls } from 'app/shared/interface';

export interface Promo {
  id: string;
  color: string;
  fromSystemId: number;
  coopOriginId: string;
  kardexId: string;
  publishDate: any;
  expirationDate: any;
  shared?: Shared;
  Shared?: Shared;
  isBoardNews?: string;
  offerGroup: string;
  coop: Coop;
  facebook: Facebook;
  sms: SMS;
  mail: Email;
  web?: Facebook;
  screen?: Screen;
  dit?: Dit;
  isTemp?: boolean;
  tempId?: string;
  ownTemplate: boolean;
  template: boolean;
  saved: string;
  createdAt: string;
  fbPostID?: string;
}

export interface RecurringPromo {
  id: string;
  color: string;
  fromSystemId: number;
  coopOriginId: string;
  kardexId: string;
  shared?: Shared;
  title: string;
  longDescription: string;
  startDate: string;
  endDate: string;
  largeImage: string;
  thumbnail: string;
  inAppParameters: InAppParameters;
  board: string;
  recurringData: RecurringDate;
  createdAt: string;
  status: number;
}

export interface RecurringDate {
  startDay: string;
  endDay: string;
}

export interface InAppParameters {
  price?: any;
  priceDescription?: string;
  offerPrice?: any;
  uom? : string
}

export interface Shared {
  facebook: boolean;
  coop: boolean;
  sms: boolean;
  mail: boolean;
  web: boolean;
  screen?: boolean;
  dit: boolean;
}

export interface PromoDetail {
  id: string;
  largeImage: string;
  title: string;
  longDescription: string;
  startDate?: string;
  publishDate: any;
  expirationDate: any;
  status?: string;
}

export interface PromoOptions {
  endDate: string;
  expirationDate: string;
  inAppParameters: InAppParameters;
  longDescription: string;
  publishDate: any;
  startDate: any;
  title: string;
  largeImage?: string;
  thumbnail?: string;
  priceTag?: string;
  video: VideoUrls;
  weight?: number;
  dutyText?: string;
  price?: string;
  hasVideo?:boolean;
}

export interface Coop extends PromoOptions {
  push: string;
  isSet?: boolean;
}

export interface Facebook {
  endDate: string;
  expirationDate: string;
  inAppParameters: InAppParameters;
  longDescription: string;
  publishDate: any;
  startDate: any;
  title: string;
  largeImage: Array<string>;
  video: VideoUrls;
  isSet?: boolean;
}

export interface Screen {
  endDate: string;
  expirationDate: string;
  price: string;
  offerPrice: string;
  dutyText: string;
  longDescription: string;
  publishDate: any;
  startDate: any;
  title: string;
  largeImage: Array<string>;
  video: VideoUrls;
  screens: string;
  priceTag: string;
  weight: number;
  coop365: boolean;
  screenLists?: ScreenList[];
  isSet?: boolean;
}

export interface Dit {
  endDate: string;
  expirationDate: string;
  price: string;
  offerPrice: string;
  dutyText: string;
  uom : string,
  longDescription: string;
  publishDate: any;
  startDate: any;
  title: string;
  largeImage: string;
  weight: number;
  isSet?: boolean;
}

export interface SMS {
  sendDate: any;
  // startDate: any;
  smsText: string;
  title: string;
  thumbnail: string;
  inAppParameters: InAppParameters;
  longDescription: string;
  solutions: string;
  video: VideoUrls;
  url?: string;
  isSet?: boolean;
  send_date?: any;
  sms_text?: any;
}

export interface Email extends PromoOptions {
  mailId: string;
  shortDescription: string;
  isSet?: boolean;
}

export interface EmailData {
  mailUsed: number;
  mails: EmailList[];
}

export interface EmailList {
  id: number;
  navn: string;
  week: string;
  branchId: number;
  campaignId: number;
  sendDate: string;
}

export interface Mail {
  used: boolean;
  id: string;
}

export interface PromoTemplate {
  id?: string;
  shared: Shared;
  coop: Coop;
  facebook: Facebook;
  sms: SMS;
  mail: Email;
  web: Facebook;
  screen: Screen;
  dit: Dit;
  kardex: string;
  active: string;
  board: string;
  activeCount: string;
  // templateType?:string,
  type: string;
}

export interface NewsList {
  id: string;
  thumbImageUrl: string;
  title: string;
  longDescription: string;
  publishDate: string;
  expirationDate: string;
  state?: string;
}

export interface AvailablePushCountData {
  availablePushCount: number;
  pushWeekData: PushWeekData[];
}

export interface PushWeekData {
  week: string;
  count: number;
}

export interface Modes {
  editMode: boolean;
  templateMode: boolean;
  newsMode: boolean;
  ownTemplate: boolean;
  goodPriceMode?: boolean;
}

export interface ChannelSelectionModes {
  edit: boolean;
  create: boolean;
  template: boolean;
}

export interface SmsData {
  title: string;
  longDescription: string;
  inAppParameters: InAppParameters;
  publishDate: any;
}

export interface ImageSliderUrl {
  coop: {
    imageUrl: {
      largeImage: string;
      thumbnail: string;
    };
    hasImage: boolean;
  };
  facebook: {
    imageUrl: {
      largeImage: string;
      thumbnail: string;
    };
    hasImage: boolean;
  };
  sms: {
    imageUrl: {
      largeImage: string;
      thumbnail: string;
    };
    hasImage: boolean;
  };
  mail: {
    imageUrl: {
      largeImage: string;
      thumbnail: string;
    };
    hasImage: boolean;
  };
}

export interface ImgInterface {
  imageUrl: {
    largeImage: string;
    thumbnail: string;
  };
  hasImage: boolean;
}

export interface ImageSlideArrayUrl {
  coop: ImgInterface;
  facebook: ImgData;
  sms: ImgInterface;
  mail: ImgInterface;
  web: ImgData;
  screen: ImgData;
  dit: ImgInterface;
}

export interface ImgData {
  imageUrl: Array<MultipleImage>;
  hasImage: boolean;
}

export interface MultipleImage {
  url: string;
  hasImage: boolean;
}

export interface OfferDates {
  start: any;
  end: any;
}

export interface ChannelList {
  channel: string;
  icon: string;
  name: string;
}

export interface DateDescription {
  startDateDescription: string;
  startDayDescription: string;
  validRangeDescription: string;
  endDateDescription: string;
}

export interface ScreenList {
  id: number;
  name: string;
}

export interface NyhedTilbud {
  id: string;
  shared: Shared;
  coop: Coop;
  facebook: Facebook;
  sms: SMS;
  mail: Email;
  web: Facebook;
  screen: Screen;
  dit: Dit;
  isSet?: boolean;
  offerGroup?: string;
  fbPostID?: string;
  ownTemplate?: boolean;
}
