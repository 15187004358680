import {Injectable} from "@angular/core";
import {NotificationsService} from "./notifications.service";
declare let ColorThief: any;
@Injectable({
    providedIn:'root'
})
export class ColorThiefService{
    constructor() {}
    // *** It takes the color from image and returns the color for the calendar item ***
    // @params [imageElement] => Image uploaded by user
  //if imageElement and imageElement width is greater than 0 =>
  //                   if imageElement complete is false => notify 'Processes image. Please try again in a moment.'
  // @returns r, g, b value
    static setColor(imageElement: HTMLImageElement){
        let color = '0,0,0';
        if (imageElement && imageElement.width > 0) {
            if (!imageElement.complete) {
                NotificationsService.notify(
                    'Behandler billede. Prøv igen om et øjeblik.',
                    'error',
                    'top'
                );
                return false;
            }
            const imageColor = new ColorThief().getColor(imageElement);// set imageColor with the imageElement color
            color = `${imageColor[0]},${imageColor[1]},${imageColor[2]}`;// r,g,b
        }
        return color;
    }

}
