<section class="app-page page-with-fixed-header">
  <div class="fixed-header">
    <coop-pageheader
      [pageTitle]="pageTitle"
      [isBackGroundWhite]="isBackGroundWhite"
      [isChild]="true"
      [routeTo]="parentUrl"
    ></coop-pageheader>
  </div>


  <div class="page-container">
    <ng-container *ngIf="type === 'video'; else showpdfguides">
      <div *ngFor="let item of QCGuiderList; let last=last" class="guide-container" [class.noborder]="last">
        <div class="title">
          {{ item.title }}
        </div>

        <div *ngFor="let list of item.guideList" (click)="showPreview(list.url)" class="guide-list">
          <div class="guide-list-title">
            <div>
              <ion-icon name="play-circle-outline"></ion-icon>
            </div>
            <div class="sub-title" style="margin-left: 10px">
              {{ list.title }}
            </div>
          </div>

          <div>
            <ion-icon name="chevron-forward-outline" class="font-20"></ion-icon>
          </div>
        </div>

      </div>
    </ng-container>


    <ng-template #showpdfguides>
      <div class="guide-container noborder" >
        <div *ngFor="let list of PDFGuidesList" (click)="downloadPDF(list)" class="guide-list">
          <div class="guide-list-title">
            <div>
              <ion-icon name="book-outline"></ion-icon>
            </div>
            <div class="sub-title" style="margin-left: 10px">
              {{ list.title }}
            </div>
          </div>

          <div>
            <ion-icon name="chevron-forward-outline" class="font-20"></ion-icon>
<!--            <ion-icon name="arrow-forward-outline"></ion-icon>-->
          </div>
        </div>
      </div>
    </ng-template>
  </div>


  <div class="video-box" *ngIf="showPreviewToggle">
    <div [ngClass]="firstLink ? 'first-preview' : 'video-preview' ">
      <div [ngClass]="firstLink ? 'first-link' : 'video-player' ">
        <video
          id="video"
          controls
          playsinline
          muted
          autoplay
          name="media"
          #video
        >
          <source src="{{videoLink}}">
        </video>
        <div (click)="onClose()" class="close-icon">
          <i class="icon icon-close-circle"></i>
        </div>
      </div>
    </div>
  </div>

</section>
