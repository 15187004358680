<section
  class="print-history-page app-page list-component"
  [class.z-index-fix]="zIndexFix"
>
  <div class="fixed-header">
    <coop-pageheader
      [pageTitle]="pageTitle"
      [isChild]="true"
      [routeTo]="'/'"
    ></coop-pageheader>
  </div>
  <div class="page-container">
    <div class="item-list">
      <ng-container *ngFor="let history of printHistory">
        <a class="item">
          <figure>
            <img
              src="assets/icons/outline/success_check.svg"
              *ngIf="history.status === 2"
              alt=""
            />
            <img
              src="assets/icons/outline/failed_alert.svg"
              *ngIf="history.status === 1 || history.status === 3"
              alt=""
            />
            <img
              src="assets/icons/outline/dots.svg"
              *ngIf="history.status === 0"
              alt=""
            />
          </figure>
          <div class="item-detail">
            <div class="item-order d-flex">
              <div>
                <h3 class="item-title">{{ history.orderId }}</h3>
                <span> {{ history.category }}</span>
              </div>
              <h3 class="item-title">
                <ng-container *ngIf="history.status === 0">
                  ...Printer
                </ng-container>
                <ng-container *ngIf="history.status === 1">
                  Ikke Printet
                </ng-container>
                <ng-container *ngIf="history.status === 2">
                  Printet
                </ng-container>
                <ng-container *ngIf="history.status === 3">
                  Mislykket
                </ng-container>
              </h3>
            </div>
            <div class="item-print-detail d-flex">
              <span [class.error]="history.status === 1"
                >Printer ID: {{ history.jobId }}</span
              >
              <span>{{ history.date }}</span>
            </div>
          </div>
        </a>
      </ng-container>
      <h5 class="empty-message" *ngIf="printHistory.length === 0">
        Beklager, ingen historik tilgængelig
      </h5>
    </div>
    <a
      class="login-btn btn-white-login btn-medium"
      (click)="loadMore()"
      *ngIf="!(printHistory.length < page * limit)"
      >Indlæs mere..</a
    >
  </div>
  <!-- filter acc to category -->
  <a class="big-add-btn-left position-fix" (click)="showFilterBtns()">
    <i class="icon-filter"></i>
  </a>
  <div class="add-actions-container" [@slideUpDown]="showFilters">
    <div class="close-btn" (click)="hideFilterBtns()">
      <i class="icon icon-close-circle"></i>
    </div>
    <div class="wrapper">
      <h4>Filterindstilling</h4>
      <ul>
        <li>
          <a
            class="btn"
            [class.active]="currentCategory === 'Alle'"
            (click)="loadPrintHistory('Alle')"
            >Alle</a
          >
        </li>
        <li>
          <a
            class="btn"
            [class.active]="currentCategory === 'Delikatessen'"
            (click)="loadPrintHistory('Delikatessen')"
            >Delikatessen</a
          >
        </li>
        <li>
          <a
            class="btn"
            [class.active]="currentCategory === 'Bageren'"
            (click)="loadPrintHistory('Bageren')"
            >Bageren</a
          >
        </li>
        <li>
          <a
            class="btn"
            [class.active]="currentCategory === 'Slagter'"
            (click)="loadPrintHistory('Slagter')"
            >Slagter</a
          >
        </li>
        <li>
          <a
            class="btn"
            [class.active]="currentCategory === 'Fisk'"
            (click)="loadPrintHistory('Fisk')"
            >Fisk</a
          >
        </li>
      </ul>
    </div>
  </div>
</section>
