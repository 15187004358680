import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {
  AppEventsService,
  ErrorhandlerService,
  ClientService,
} from 'app/core/services';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Client } from 'app/shared/interface';
import { SingleSms, SmsService } from 'app/features/sms';

@Injectable({
  providedIn: 'root',
})
export class SmsClubResolverService  {
  constructor(
    private _appEvents: AppEventsService,
    private _clientService: ClientService,
    private _errorHandlerService: ErrorhandlerService
  ) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Client[]> | Promise<Client[]> | Client[] {
    this._appEvents.showLoader(true);
    return this._clientService
      .getClients()
      .pipe(
        catchError((err) => this._errorHandlerService.routeResolverError(err))
      );
  }
}
@Injectable({
  providedIn: 'root',
})
export class SingleSmsResolverService  {
  constructor(
    private _appEvents: AppEventsService,
    private _smsService: SmsService,
    private _errorHandlerService: ErrorhandlerService
  ) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<SingleSms> | Promise<SingleSms> | SingleSms {
    const id = route.params['id'];
    return this._smsService
      .getSingleSms(id)
      .pipe(
        catchError((err) => this._errorHandlerService.routeResolverError(err))
      );
  }
}
