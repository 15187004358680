export const Jatak_Live_Validation_Messages = {
  title: {
    required: 'Udfyld venligst feltet',
  },
  dateMismatchStartEnd: {
    mismatch: 'Afslutningstidspunkt skal være senere end Udsendingstidspunkt',
  },
  productTitle: {
    required: 'Udfyld venligst feltet',
  },
  price: {
    min: 'Prisen skal være over 0,00',
    max: 'Prisen skal være mindre end 50000,00',
    invalid: 'Brug venligst det danske prisformat (f.eks. 6.789,45)',
    required: 'Udfyld venligst feltet',
  },
  productInfo: {
    required: 'Udfyld venligst feltet',
  },
  quantity: {
    min: 'Mængden skal være over 0',
    required: 'Udfyld venligst feltet',
  },
  maxOrderCount: {
    maxOrderLessThanTotal:
      'Produkt stk. antallet må ikke overstige den samlede mængde.',
  },
  maxOrderLessThanTotal: {
    invalid: 'Produkt stk. antallet må ikke overstige den samlede mængde.',
  },
  keyword: {
    unique: 'Nøgleordet skal være unikt',
  },
};
