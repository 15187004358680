import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { CreateOption } from 'app/shared/interface/createoption.interface';
//*** Service used to set and clear CreateOption ***
@Injectable({
  providedIn: 'root',
})
export class CreateOptionDataService {
  private settingSource = new BehaviorSubject(undefined);
  currentSetting = this.settingSource.asObservable();

  constructor() {}
// *** Sets the latest value of currentSetting  ***
// @params [options]=> documents type, mode and shared channels
  setCreateOption(options: CreateOption) {
    this.settingSource.next(options);
  }
// *** Sets the latest value of currentSetting to undefined  ***
  clearCreateOption() {
    this.settingSource.next(undefined);
  }
}
