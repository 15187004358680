import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { throwError } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

import { ErrorhandlerService, NotificationsService } from 'app/core/services';
import { animateParent, slideAnimation } from 'app/shared/animations';
import {
  OrderList,
  DataService,
  OrderService,
} from 'app/features/order-and-pickup/order';

@Component({
  selector: 'coop-product-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss'],
  animations: [animateParent, slideAnimation],
})
export class OrderListComponent implements OnInit, OnDestroy {
  orderList: OrderList[];
  allOrderList: OrderList[];
  showParent: string = 'visible';
  currentCategory: string = 'Alle';
  pageTitlePreText = 'Mad ud af huset';
  pageTitle: string = `${this.pageTitlePreText} - ${this.currentCategory}`;
  currentUrl: string;
  parentUrl: string;
  previousUrl: string;
  activeTab = 1;
  showFilters: string = 'inactive';
  origin: string;
  priorities: string[] = ['urgent', 'critical', 'important', 'normal'];
  showRangeSelect: boolean = false;
  page: number = 1;
  limit: number = 10;

  private _subscriptionState: boolean = true;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _errorHandlerService: ErrorhandlerService,
    private _orderService: OrderService,
    private _title: Title,
    private _dataService: DataService
  ) {}

  ngOnInit() {
    console.log('test')
    this._activatedRoute.parent.data
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe((url) => (this.parentUrl = url['parentUrl']));
    this._initComponent();

  }

  ngOnDestroy(): void {
    this._subscriptionState = false;
  }

  tab(value: number, event): void {
    if (event && this.activeTab !== value) {
      this.page = 1;
      this._getAll(true, value)
        .pipe(takeWhile(() => this._subscriptionState))
        .subscribe({
          next: (orders) => this._updateList(orders, value),
          error: (err) => this._errorHandlerService.handleError(err),
        });
    }
  }

  loadOrderListData(category: string) {
    this.showFilters = 'inactive';
    this._loadData(category);
  }

  loadMore() {
    this.page++;
    this._getAll(true, this.activeTab)
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (orders: OrderList[]) => {
          if (orders.length === 0) {
            NotificationsService.notify('Ikke flere ordre', 'error', 'top');
          } else {
            this.orderList = this.allOrderList.concat(orders);
            this.allOrderList = this.orderList;
          }
        },
        error: (err) => this._errorHandlerService.handleError(err),
      });
  }

  showPrintRangeSelect() {
    this.showRangeSelect = true;
  }

  hidePrintRangeSelect($event) {
    this.showRangeSelect = $event;
  }

  showHideFilterBtns(): void {
    this.showFilters = this.showFilters === 'inactive' ? 'active' : 'inactive';
  }

  private _initComponent() {
    this._title.setTitle('QuickCoop');
    this.currentUrl = this._router.url;
    this.previousUrl = this.parentUrl + '/indstillinger';
    this._dataService.currentTab.subscribe((tab) => (this.activeTab = tab));
    this.orderList = this.allOrderList =
      this._activatedRoute.snapshot.data['orderList'];
    this.origin = this._activatedRoute.snapshot.queryParams['origin'];
    if (this.origin === 'skab') {
      this.previousUrl = this.parentUrl + '/skab';
      this.currentUrl = this.parentUrl + '/order';
    }
    this._checkChildPage();
  }

  private _loadData(category: string) {
    this.page = 1;
    this._orderService
      .getOrderListing(this.page, this.activeTab, category)
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (orders: OrderList[]) => {
          this.orderList = this.allOrderList = orders;
          this.currentCategory = category;
          this._setPageTitle();
        },
        error: (err) => this._errorHandlerService.handleError(err),
      });
  }

  private _getAll(state, activeState) {
    if (state) {
      return this._orderService.getOrderListing(
        this.page,
        activeState,
        this.currentCategory
      );
    }
    return throwError(() => 'Error');
  }

  private _setPageTitle(): void {
    this.pageTitle = `${this.pageTitlePreText} - ${this.currentCategory}`;
  }

  private _updateList(orders, value) {
    this.orderList = this.allOrderList = orders;
    this.activeTab = value;
    this._dataService.changeTab(value);
  }

  private _checkChildPage(): void {
    this.showParent = this._activatedRoute.children.length
      ? 'invisible'
      : 'visible';
  }
}
