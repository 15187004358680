<section class="app-sub-page">
  <div class="modal" [@slideUpDown]="pAnimate" [@slideDownUp]="pAnimate">
    <div class="content-container">
      <div class="close" (click)="cancel()">
        <i class="icon icon-close-circle"></i>
      </div>
      <div class="content cancel-box-content">
        <div class="title">
          <h2><i class="ti-alert"></i></h2>
          <h2>Felter uden prisoplysninger</h2>
        </div>
        <div class="message">
          <div class="info">
            <p>
              Der er mindst ét felt til pris- eller prisbeskrivelse, der
              efterlades tom.
            </p>
            <p>Er du sikker på, at du vil gemme din God Pris på den måde?</p>
          </div>
        </div>
      </div>
      <div class="wrapper send-btn">
        <div class="row">
          <button class="login-btn btn-black-login" (click)="saveWithNoPrice()">
            Ja, gem God Pris
          </button>
        </div>
        <div class="row">
          <button class="login-btn btn-white-login" (click)="cancel()">
            Gå tilbage og rediger
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
