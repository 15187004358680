<div class="app-page website-create-edit page-with-fixed-header">
  <div class="fixed-header">
    <coop-pageheader
      [pageTitle]="pageTitle"
      [isChild]="true"
      [routeTo]="previousUrl"
      [isBackGroundWhite]="true"
    ></coop-pageheader>
  </div>
  <div class="page-container">
    <div class="form-container styled-two">
      <form [formGroup]="websiteForm" (submit)="save()">
        <div class="wrapper">
          <div class="form-group" controlErrorContainer>
            <coop-image-handler
              [imageUrl]="imageUrl"
              [hasImages]="hasImage"
              [singleCropper]="true"
              [isRectangle]="false"
              [multipleImage]="false"
              [isVideo]="false"
              (processing)="imageProcessing($event)"
              (image)="getImagesUrl($event)"
            ></coop-image-handler>
            <input
              formControlName="image"
              [customErrors]="validationMessage['image']"
              hidden
            />
          </div>
          <div class="row form-group" controlErrorContainer>
            <label for="website-title" class="margin-bottom-8"
              >Overskrift<span *ngIf="userType === '2'" class="board-title-desc"
                >Overskriften skal være en generel indledning, der beskriver
                teksten</span
              ></label
            >
            <input id="website-title" type="text" formControlName="title" [customErrors]="validationMessage['title']"/>
          </div>
          <div class="row form-group" controlErrorContainer>
            <label for="website-desc" class="margin-bottom-8">Tekst</label>
            <textarea
              name=""
              id="website-desc"
              formControlName="description"
              placeholder="Indsæt tekst der beskriver hvilken butik i er, hvad i står for, samt noget generelt om personalet."
              [customErrors]="validationMessage['description']"
            ></textarea>
          </div>
        </div>
        <div class="wrapper">
          <div class="row">
            <input type="submit" value="Gem" class="login-btn btn-black-login" />
          </div>
          <div class="row" *ngIf="editMode">
            <a
              (click)="remove(website.id)"
              class="login-btn btn-white-login "
              >Slet</a
            >
          </div>
<!--          <div class="row" *ngIf="editMode">-->
<!--            <a (click)="back()" class="btn btn-border-red btn-delete"-->
<!--              >Tilbage</a-->
<!--            >-->
<!--          </div>-->
        </div>
      </form>
    </div>
  </div>
</div>
