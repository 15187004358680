import { State, Selector, Action, StateContext } from "@ngxs/store";
import { CreateOption } from "app/shared/interface";
import { Injectable } from '@angular/core';

export interface CreateOptionsState {
  data: CreateOption;
}

export class SetCreateOptions {
  static readonly type = "[CreateOptionsCompState] Set Data";

  constructor(public payload: any) {
  }
}

export class ClearCreateOptions {
  static readonly type = "[CreateOptionsState] Clear Data";
}

@State<CreateOptionsState>({
  name: "home",
  defaults: {
    data: null
  }
})

@Injectable()
export class CreateOptionsCompState {
  @Selector()
  static getData(state: CreateOptionsState) {
    return state.data;
  }

  @Action(SetCreateOptions)
  setData(
    { patchState }: StateContext<CreateOptionsState>,
    { payload }: SetCreateOptions
  ) {
    patchState({
      data: payload
    });
  }

  @Action(ClearCreateOptions)
  clearData({ setState }: StateContext<CreateOptionsState>) {
    const defaults: CreateOptionsState = {
      data: null
    };
    setState(defaults);
  }
}


