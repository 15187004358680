import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ApiService, TokenService } from 'app/core/services';
import { TemplateFolderData, TemplateListData } from 'app/shared/interface';
import {
  FbInsights,
  JatakLive,
  JatakLiveList,
  ProductReportData,
} from 'app/features/jatak-live';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class JatakLiveService {
  BASE_URL = 'live_event';

  constructor(
    private _apiService: ApiService,
    private _httpService: HttpClient
  ) {}
  // *** Saving the JatakLive ***
  // @param [params: JatakLive] => JatakLive Item to be saved
  // @param [edit: boolean] => Is edit mode?
  //                           true: if editing active or future or archive jatakLive
  //                           false: if new jatakLive is to be created
  saveJatakLive(params: object, edit: boolean): Observable<any> {
    if (edit) {
      return this._apiService.putWebHook(
        `${this.BASE_URL}/${params['id']}`,
        ApiService.CreateFormData(params)
      );
    }
    return this._apiService.postWebHook(
      this.BASE_URL,
      ApiService.CreateFormData(params)
    );
  }
  // *** getting a particular JatakLive post ***
  // @param [id: number] => Jatak_live_id
  // @returns [jatakLive: JatakLive] => JatakLive details of particular id
  getSingleJatakLive(id: string): Observable<JatakLive> {
    return this._apiService
      .getWebHook(`${this.BASE_URL}/${id}`)
      .pipe(map((res) => res['fbLive']));
  }
  // *** Delete JatakLive ***
  // @param [id:string] => id of JatakLive to be deleted
  deleteJatakLive(id: number | string): Observable<any> {
    return this._apiService.delWebHook(`${this.BASE_URL}/${id}`);
  }
// *** Getting the jatak-live lists within a date range for calendar listing ***
  // @params [startDate: string]
  // @params [endDate: string]
  // @returns [Observable<JatakLive[]>] => Basic Jatak-live value of all jatak-live items
  getJatakLive(startDate?: any, endDate?: any): Observable<JatakLiveList[]> {
    let url = this.BASE_URL;
    if (startDate && endDate) {
      url = url + `?startDate=${startDate}&endDate=${endDate}`;
    }
    return this._apiService.getWebHook(url).pipe(map((res) => res['contents']));
  }
// *** Getting the jatak-live lists inside Indstillinger(settings) tab with startPage, pageLimit and status provided ***
  // @params [page] => Page number increases while Loading More
  // @params [limit: number] => total jatakLive lists to be loaded on particular page
  // @params [status: string] => '1' active
  //                             '2' Fremtidige (future)
  //                              '0' Archive
  // @returns [Observable<JatakLive[]>] => Basic Jatak-live value of all jatak-live items
  getJatakLiveListing(
    page: number,
    limit: number,
    status: string
  ): Observable<JatakLiveList[]> {
    const url = `live/listing?kardex=${TokenService.getUserCode()}&page=${page}&limit=${limit}&status=${status}`;
    return this._apiService.getWebHook(url).pipe(map((res) => res['offers']));
  }
// It gets productData for JatakLive reporting page
// @param [id: string] => JatakLive id
// @returns [productData: ProductReportData] => info about product availability and quantity
  getJatakProductReport(id: string): Observable<ProductReportData> {
    const url = `${this.BASE_URL}/${id}/live-report`;
    return this._apiService.getWebHook(url);
  }
// It gets 10 comments for JatakLive reporting page, from last_id or last_head_id
// @param [dataId: string] => JatakLive id
// @returns [commentData: JatakLiveComments] => comment head information and all the comment details
  getJatakComments(
    id: string,
    dataId?: number,
    head = false,
    fetchComments?: string
  ): Observable<any> {
    let url = `live_event_comments/${id}`;
    if (!isNaN(dataId)) {
      if (!head) {
        url += `?last_id=${dataId}`;
      } else {
        url += `?last_head_id=${dataId}`;
      }
      if (fetchComments) {
        url += `&fetch=${fetchComments}`;
      }
    }
    return this._apiService.getWebHook(url);
  }
// *** Gets the JatakLive report as pdf file
  // @param [id:string] => Jatak Live id
  getJatakReport(id: string): Observable<any> {
    const url = `${this.BASE_URL}/${id}/orderList`;
    return this._httpService
      .get(`${environment.webhookurl}${url}`, { responseType: 'blob' })
      .pipe(catchError(this._handlerError()));
  }
// *** It gets total likes and views (insights) in JatakLive reporting page ***
// @param [id: string] => JatakLive id
// @returns [fbInsights: FbInsights] => info about likes and views
  getFacebookInsights(id: string): Observable<FbInsights> {
    const url = `${this.BASE_URL}/${id}/insights`;
    return this._apiService.getWebHook(url);
  }
// *** It validates webhook server ***
  // @returns [status: boolean] => true: if token matched and is within 3 months
  //                               false: otherwise
  checkFbTokenValidity() {
    const kardex = TokenService.getUserCode();
    const url = `facebook/validate-token/${kardex}`;
    return this._apiService.get(url).pipe(map((res) => res['valid']));
  }

  // ** Template Services ** //

  // get all the jatak live template folders
  getJatakLiveTemplatesFolder(): Observable<TemplateFolderData> {
    const url = 'fb-live/template/folders';
    return this._apiService.get(url);
  }

// *** gets all the TemplateListData from current page to page limit usually set to 10 ***
// @params [clientId: number] => kardex_id for own templates
  //                             0 for store-template and folder-template
  // @params [store: number] => kardex_id for template created by store
  //                            0 for own templates and folder-template
  // @param [folderId: number] => folder_id for template created in folder
  //                              '' empty string for own templates and store-created
  // @param [page: number] => start page
  // @param [limit: number] => maximum templates to fetch at a time
  // @returns [template: Array<Template>] => Templates belonging to particular folder
  getJatakLiveTemplates(
    clientid: string,
    store: string,
    folderId: string,
    page: number,
    limit: number,
    searchQuery?: string
  ): Observable<TemplateListData> {
    const userType = TokenService.getUserType();
    let url = `fb-live/template/folders/list?kardex=${clientid}&store=${store}&folderId=${folderId}&page=${page}&limit=${limit}&user_type=${userType}`;
    url = searchQuery ? `${url}&search=${searchQuery}` : url;
    return this._apiService.get(url);
  }

  // *** Gets single template ***
  // @param [id:number] => template_id
  // @returns [template: Template] => Details of the template
  getJatakLiveSingleTemplate(id: number) {
    const url = `template/fb-live/${id}`;
    return this._apiService.get(url).pipe(map((res) => res['template']));
  }
// *** It takes the search string and searches using api ***
  // @param [searchTerms: string] => the searchTerms from templates-folder-list.component.ts
  // @returns [jatak: Array<JatakLive>] => Those templates as JatakLive found in the server
  searchJatakLiveTemplate(searchTerms: string) {
    const url = `template/fb-live/template/search?searchTerms=${searchTerms}`;
    return this._apiService.get(url);
  }

  private _handlerError() {
    return (error: HttpErrorResponse): Observable<any> => {
      return throwError(() => error);
    };
  }
}
