import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import {
  ErrorhandlerService,
  CreateOptionDataService,
  ChannelDataService,
} from 'app/core/services';
import { CreateOption, SelectedChannels } from 'app/shared/interface';
import {
  NewsList,
  Promo,
  RecurringPromo,
  ScreenList,
  OfferService,
  ScreenListDataService,
} from 'app/features/offer';

@Injectable({
  providedIn: 'root',
})
export class OfferResolverService  {
  constructor(
    private _offerService: OfferService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    const id = route.params['id'];
    return this._offerService
      .getPromo(id)
      .pipe(
        catchError((err) => this._errorHandlerService.routeResolverError(err))
      );
  }
}

@Injectable({
  providedIn: 'root',
})
export class NewsListResolverService  {
  constructor(
    private _offerService: OfferService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this._offerService
      .getNewsList('1', 1, 10)
      .pipe(
        catchError((err) => this._errorHandlerService.routeResolverError(err))
      );
  }
}

@Injectable({
  providedIn: 'root',
})
export class CreateOptionResolver  {
  constructor(
    private _createOption: CreateOptionDataService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this._getCreateOptionSettings().pipe(
      catchError((err) => this._errorHandlerService.routeResolverError(err))
    );
  }

  private _getCreateOptionSettings(): Observable<CreateOption> {
    return this._createOption.currentSetting.pipe(
      take(1),
      map((settings) => settings)
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class NewsResolverService  {
  constructor(
    private _offerService: OfferService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    const id = route.params['id'];
    return this._offerService
      .getPromo(id)
      .pipe(
        catchError((err) => this._errorHandlerService.routeResolverError(err))
      );
  }
}

@Injectable({
  providedIn: 'root',
})
export class ChannelResolver  {
  constructor(
    private _channelDataService: ChannelDataService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this._channelDataService.getChannel();
  }
}

@Injectable({
  providedIn: 'root',
})
export class RecurringOfferResolverService  {
  constructor(
    private _offerService: OfferService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    const id = route.params['id'];
    return this._offerService
      .getRecurringPromo(id)
      .pipe(
        catchError((err) => this._errorHandlerService.routeResolverError(err))
      );
  }
}

@Injectable({
  providedIn: 'root',
})
export class RecurringOfferListingResolverService

{
  constructor(
    private _offerService: OfferService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this._offerService
      .getRecurringPromos(1, 10, 1)
      .pipe(
        catchError((err) => this._errorHandlerService.routeResolverError(err))
      );
  }
}

@Injectable({
  providedIn: 'root',
})
export class ScreenListResolverService  {
  constructor(
    private _screenListDataService: ScreenListDataService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this._getScreenList().pipe(
      catchError((err) => this._errorHandlerService.routeResolverError(err))
    );
  }

  private _getScreenList(): Observable<ScreenList[]> {
    return this._screenListDataService.screens.pipe(
      take(1),
      map((screens) => screens)
    );
  }
}
