import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormControlName,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MbscDatepickerOptions } from '@mobiscroll/angular';
import { fromEvent, merge, Observable } from 'rxjs';
import { debounceTime, takeWhile } from 'rxjs/operators';
import * as _ from 'lodash';
import {
  FormFunctionsService,
  HelperService,
  ImageService,
  NotificationsService,
} from 'app/core/services';
import {
  checkIfEndDateIsLessThanStartDate,
  checkIfExpDateIsLessThanPublishDate,
  checkIfExpireEndMismatch,
  checkIfOfferPriceLessThanReal,
  checkMaxLength,
  checkPriceMinimum,
  checkPriceRegex,
  checkWhiteSpace,
  customMinValidator,
  GenericValidator,
} from 'app/core/validators';
import {
  COMMON_DATETIMEPICKER_SETTINGS,
  COMMON_SELECT_SETTINGS,
} from 'app/shared/data';
import {
  DatepickerData,
  DisplayMessage,
  Modes,
  MultipleImage,
  ValidationMessage,
  VideoUrls,
} from 'app/shared/interface';
import {
  INITIAL_FORM_DATA,
  NyhedTilbud,
  OfferDataService,
  OfferDateTimeService,
  OfferStateService,
} from 'app/features/offer';
import * as moment from 'moment/moment';
import { DatepickerBase } from '@mobiscroll/angular/dist/js/core/components/datepicker/datepicker';


@Component({
  selector: 'coop-fb-form',
  templateUrl: './fb-form.component.html',
  styleUrls: ['./fb-form.component.scss'],
})
export class FbFormComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  @ViewChildren(FormControlName, { read: ElementRef })
  formInputElements: ElementRef[];
  @Input() modes: Modes;
  @Input() tab: string = '';
  @Input() validationMessages: ValidationMessage;
  @Input() validateModes = [];
  @Input() submitForm = false;
  @Input() currentTab = 'coop';
  @Input() offerType: string = 'tilbud';
  @Output() formReady = new EventEmitter<FormGroup>();
  fbForm: FormGroup;
  formData?: NyhedTilbud = INITIAL_FORM_DATA;
  imageUrl;
  defaultImageData = [
    { url: 'assets/images/camera@3x.jpg', hasImage: false },
    { url: 'assets/images/camera@3x.jpg', hasImage: false },
    { url: 'assets/images/camera@3x.jpg', hasImage: false },
    { url: 'assets/images/camera@3x.jpg', hasImage: false },
  ];
  defaultVideoData = {
    url: '',
    thumbnail: 'assets/images/video.png',
  };
  videoUrl: VideoUrls = {
    url: '',
    thumbnail: 'assets/images/video.png',
  };
  hasImage = false;
  hasVideo = false;
  isGif = false;
  isVideo = false;
  isPostedToFb = false;
  isProcessing = false;
  currentDateTime: string;
  displayMessage: DisplayMessage = {};
  visible;
  numbers = [];
  priceDescSelectSettings = {
    ...COMMON_SELECT_SETTINGS,
    ...{
      data: [
        'stk',
        'kg',
        'g',
        'liter',
        'ml',
        'pakke',
        'pakker',
        'sæt',
        'kasse',
        'kasser',
        'ramme',
        'rammer',
      ],
    },
  };
  datePickerSettings: MbscDatepickerOptions = {
    ...COMMON_DATETIMEPICKER_SETTINGS,
    ...{
      dateWheels: 'YYYY MMMM DD',
      dateFormat: 'YYYY-MM-DD',
      stepMinute: 15,
      onOpen: (_, inst) => this._setUpDateTime(inst),
      onClose: (_, inst) => this._closeDatePicker(inst),
    },
  };

  titleCount: any = 0;
  private _validator: GenericValidator;
  private _subscriptionState = true;
  showPriceSection: boolean = false;
  startEndDatePicker: DatepickerData;
  publishStartEndPicker: DatepickerData;

  constructor(
    private _formBuilder: FormBuilder,
    private _formStateService: OfferStateService,
  ) {
    this.currentDateTime = moment().format('YYYY-MM-DD HH:mm');
  }

  ngOnInit() {
    this._validator = new GenericValidator(this.validationMessages);
    this.imageUrl = JSON.parse(JSON.stringify(this.defaultImageData));
    this.intializeForms();

    this.fbForm.get('title').valueChanges.subscribe((res) => {
      this.titleCount = this.calculateLength(res);
    });

    this.startEndDatePicker = {
      titles: {
        start: 'Gyldig fra - nu',
        end: 'Gyldig til',
      },
      startEndDateTime: {
        start: this.fbForm.get('startDate').value,
        end: this.fbForm.get('endDate').value,
      },
      disable: {
        end: false,
        start: false,
      },
      modes: {
        isTemplate: this.modes.templateMode,
        isEditMode: this.modes.editMode,
        isDuplicate: false,
      },
    };

    this.publishStartEndPicker = {
      titles: {
        start: 'Udsendingstidspunkt - nu',
        end: 'Afslutningstidspunkt',
      },
      startEndDateTime: {
        start: this.fbForm.get('publishDate').value,
        end: this.fbForm.get('expirationDate').value,
      },
      disable: {
        end: false,
        start: false,
      },
      modes: {
        isTemplate: this.modes.templateMode,
        isEditMode: this.modes.editMode,
        isDuplicate: false,
      },
    };
  }

  calculateLength(str: string) {
    return _.size(str);
  }

  ngAfterViewInit(): void {
    // Watch for the blur event from any input element on the form.
    const controlBlurs: Observable<any>[] = this.formInputElements.map(
      (formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur')
    );

    // Merge the blur event observable with the valueChanges observable
    merge(this.fbForm.valueChanges, ...controlBlurs)
      .pipe(
        debounceTime(800),
        takeWhile(() => this._subscriptionState)
      )
      .subscribe(() => {
        if (
          Object.keys(this.displayMessage).length &&
          this.displayMessage.constructor === Object
        ) {
          this.displayMessage = this._validator.processMessages(this.fbForm);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['submitForm']) {
      this.submitForm = changes['submitForm'].currentValue;
      this._isValid();
    }
    if (changes['offerType']) {
      this.offerType = changes['offerType'].currentValue;
    }
    if (changes['currentTab']) {
      if (changes['currentTab'].currentValue === 'facebook') {
        this.intializeForms();
      }
    }
  }

  intializeForms() {
    if (!this.fbForm) {
      this._setupFacebookForm();
    }
    if (!this.modes.newsMode && this.offerType === 'tilbud') {
      this._setPriceValidators();
    }
    this.formData = this._formStateService.formState.value;
    if (
      this.formData.facebook?.isSet ||
      this.modes.editMode ||
      this.modes.templateMode
    ) {
      this._editModeSetup(this.formData);
    }
    if (!this.modes.editMode && !this.modes.templateMode) {
      this.createFbForm();
    }
    if (!this.modes.newsMode && this.offerType === 'tilbud') {
      this.showPriceSection = true;
    }
    this.formReady.emit(this.fbForm);
  }

  ngOnDestroy(): void {
    this._subscriptionState = false;
  }

  public get getForm() {
    return this.fbForm;
  }

  checkInAppParametersValues(parentForm): boolean {
    if (parentForm) {
      const price = parentForm.inAppParameters.price;
      const priceDescription = parentForm.inAppParameters.priceDescription;
      const uom = parentForm.inAppParameters.uom;
      return price !== '' && priceDescription !== '' && uom !== '';
    }
    return false;
  }

  createFbForm() {
    const firstValidOption = this.validateModes[0];
    if (firstValidOption !== 'facebook') {
      // should not patch anything if its the only selected tab or its in nyhed mode
      const parentForm = this.formData[firstValidOption];

      if (FormFunctionsService.checkparentFormEmpty(parentForm)) {
        return;
      }
      console.log(this.fbForm.value, 'fb form');
      if (FormFunctionsService.checkFormEmpty(this.getForm.value)) {
        this.getForm.patchValue({
          title: parentForm.title,
          longDescription: OfferDataService.descriptionChanges(
            firstValidOption,
            this.modes.newsMode,
            parentForm.longDescription
          ),
          publishDate: parentForm.publishDate,
          expirationDate: parentForm.expirationDate,
          inAppParameters: {
            price: parentForm.inAppParameters.price,
            offerPrice: parentForm.inAppParameters.offerPrice,
            priceDescription: parentForm.inAppParameters.priceDescription,
            uom: parentForm.inAppParameters.uom,
          },
          startDate: parentForm.startDate,
          endDate: parentForm.endDate,
          video: {
            url: parentForm.video.url,
            thumbnail: parentForm.video.thumbnail,
          },
        });
        // patch Image from previous form into this new form
        if (!this.modes.newsMode && this.offerType !== 'tilbud') {
          (this.getForm.get('largeImage') as FormArray).push(
            new FormControl(parentForm.largeImage)
          );
          this._patchMultipleMedia();
        } else if (!this.modes.newsMode && this.offerType === 'tilbud') {
          (this.getForm.get('largeImage') as FormArray).push(
            new FormControl(parentForm.largeImage)
          );
          this._patchMultipleMedia();
        } else if (this.modes.newsMode && this.getForm.value.video.url) {
          this._patchMultipleMedia();
        } else {
          this.hasImage = false;
          return;
        }
      }

      if (!this.modes.newsMode && this.offerType === 'good-price') {
        this.showPriceSection = this.checkInAppParametersValues(parentForm);
        this.getForm.get('inAppParameters').patchValue({
          price: parentForm.inAppParameters.price,
          priceDescription: parentForm.inAppParameters.priceDescription,
          uom: parentForm.inAppParameters.uom,
        });
      }
    }

    // set default date to now on create
    if (FormFunctionsService.checkFormEmpty(this.getForm.value)) {
      OfferDateTimeService.setDefaultTimeOnCreate(this.fbForm);
    }
    if (this.isPostedToFb) {
      const offerType = this.modes.newsMode ? 'nyheder' : 'tilbud';
      NotificationsService.notify(
        `Medier kan ikke ændres, efterhånden som ${offerType} er blevet sendt til Facebook`,
        'info',
        'top'
      );
    }
  }

  getVideoUrl(videoUrl: any) {
    this.videoUrl = {
      thumbnail: videoUrl.thumbnail,
      url: videoUrl.url,
    };
    this.hasVideo = true;
    this.fbForm.patchValue({
      video: {
        url: videoUrl.url,
        thumbnail: videoUrl.thumbnail,
      },
    });
  }

  getImagesUrl(images: any) {
    Object.assign(this.imageUrl, images);
    this.hasImage = true;
    this._patchImageToMultipleImageSlider(images);
  }

  imageProcessing(e: boolean) {
    this.isProcessing = e;
  }

  uploadTypeSelect(type: boolean) {
    if (this.isPostedToFb) {
      const offerType = this.modes.newsMode ? 'nyheder' : 'tilbud';
      NotificationsService.notify(
        `Medier kan ikke ændres, efterhånden som ${offerType} er blevet sendt til Facebook`,
        'info',
        'top'
      );
      return;
    }
    this.clearingMediaOnTabChange(type);
  }

  clearingMediaOnTabChange(type: boolean) {
    let isPrompt;
    if (type) {
      isPrompt = this.hasImage;
    }
    if (!type) {
      isPrompt = this.hasVideo;
    }
    if (isPrompt) {
      this.clearMedia(type);
    }
    if (!isPrompt) {
      this.changeUploadType(type);
    }
  }

  clearMedia(isVideo: boolean) {
    let clearFunction;
    let notificationMsg;
    if (isVideo) {
      notificationMsg =
        'Alle uploadede billeder kasseres ved at skifte faner. Er du sikker?';
      clearFunction = this.clearImageUrl;
    }
    if (!isVideo) {
      notificationMsg =
        'Den uploadede video kasseres ved at skifte faner. Er du sikker?';
      clearFunction = this.clearVideo;
    }
    NotificationsService.confirmWithCallback(notificationMsg, (accept) => {
      if (accept) {
        clearFunction(this);
        this.changeUploadType(isVideo);
        return;
      }
      if (!accept) {
        return;
      }
    });
  }

  changeUploadType(isVideo: any) {
    this.isVideo = isVideo;
    this.changeMediaType(isVideo);
  }

  changeMediaType(isVideo: any) {
    if (isVideo && !!!this.getForm.value.video.url) {
      NotificationsService.notify(
        'Det er vigtigt, at videoen optages med mobiltelefonen holdt vertical/oprejst',
        'info',
        'top'
      );
    }
    this.changeMediaValidators(isVideo);
    this.setMediaToNull(isVideo);
  }

  changeMediaValidators(isVid: boolean) {
    let videoValidity;
    let largeImageValidity;
    if (isVid) {
      videoValidity = Validators.required;
      largeImageValidity = null;
    }
    if (!isVid) {
      videoValidity = null;
      largeImageValidity = Validators.required;
    }
    this._updateValidators('video.url', videoValidity);
    this._updateValidators('video.thumbnail', videoValidity);
    this._updateValidators('largeImage', largeImageValidity);
  }

  setMediaToNull(isVideo: boolean) {
    if (isVideo) {
      this.fbForm.patchValue({
        largeImage: [null],
      });
    }
    if (!isVideo) {
      this.fbForm.patchValue({
        video: {
          url: null,
          thumbnail: null,
        },
      });
    }
  }

  clearImageUrl(context: any) {
    context.imageUrl = JSON.parse(JSON.stringify(context.defaultImageData));
    context.hasImage = false;
    // clearing image
    const control = context.fbForm.get('largeImage') as FormArray;
    // clearing array;
    while (control.length !== 0) {
      control.removeAt(0);
    }
  }

  clearVideo(context: any) {
    context.videoUrl = JSON.parse(JSON.stringify(context.defaultVideoData));
    context.hasVideo = false;
    context.fbForm.patchValue({
      video: {
        url: '',
        thumbnail: '',
      },
    });
  }

  validateNumber(event) {
    HelperService.preventInvalidNumbers(event);
  }

  private _setupFacebookForm() {
    const validators = [checkIfExpDateIsLessThanPublishDate];
    if (!this.modes.newsMode) {
      validators.push(checkIfExpireEndMismatch);
      validators.push(checkIfEndDateIsLessThanStartDate);
    }
    this.fbForm = this._formBuilder.group(
      {
        title: ['', [Validators.required, checkWhiteSpace, checkMaxLength]],
        largeImage: this._formBuilder.array([], [Validators.required]),
        video: this._formBuilder.group({
          url: [''],
          thumbnail: [''],
        }),
        startNow: [],
        publishNow: [],
        publishDate: ['', Validators.required],
        expirationDate: ['', Validators.required],
        startDate: ['', Validators.required],
        endDate: ['', Validators.required],
        longDescription: ['', [Validators.required, checkWhiteSpace]],
        inAppParameters: this._formBuilder.group(
          {
            price: [''],
            offerPrice: [''],
            priceDescription: [''],
            uom: [''],
          },
          {
            validators: [checkIfOfferPriceLessThanReal],
          }
        ),
      },
      {
        validators: validators,
      }
    );
  }

  private updateValidation() {
    const validators = [checkPriceMinimum, checkPriceRegex];
    validators.push(Validators.required);
    this._updateValidators('inAppParameters.priceDescription', [
      Validators.required,
      checkWhiteSpace,
      checkPriceRegex,
    ]);
    this._updateValidators('inAppParameters.price', [
      Validators.required,
      checkWhiteSpace,
      checkPriceRegex,
    ]);
  }

  addPriceField() {
    this.showPriceSection = true;
    this.updateValidation();
    this.fbForm.get('inAppParameters').patchValue({
      uom: 'stk',
    });
  }

  removePrices() {
    this.showPriceSection = false;
    this.removePriceValidators();
  }

  removePriceValidators(): void {
    this._updateValidators('inAppParameters.priceDescription', null);
    this._updateValidators('inAppParameters.price', null);
    this.fbForm.get('inAppParameters').patchValue({
      price: '',
      priceDescription: '',
      uom: 'stk',
    });
  }

  private _updateValidators(formControl: string, validators = []): void {
    this.fbForm.get(formControl).setValidators(validators);
    this.fbForm.get(formControl).updateValueAndValidity();
  }

  private _setPriceValidators() {
    const validators = [checkPriceMinimum, checkPriceRegex];
    if (this.offerType === 'tilbud') {
      validators.push(Validators.required);
      this._updateValidators('inAppParameters.priceDescription', [
        customMinValidator(1),
        Validators.required,
        checkWhiteSpace,
        checkPriceRegex,
      ]);
      this._updateValidators('inAppParameters.price', [
        customMinValidator(1),
        Validators.required,
        checkWhiteSpace,
        checkPriceRegex,
      ]);
    }
    if (this.offerType === 'tilbud') {
      this._updateValidators('inAppParameters.offerPrice', validators);
    }
  }

  private _editModeSetup(promo: NyhedTilbud) {
    this.getForm.patchValue(promo.facebook);
    this.fbForm.get('inAppParameters').patchValue({
      uom: promo.facebook.inAppParameters.uom.toLowerCase(),
    });
    if (promo.facebook && promo.facebook.startDate) {
      if (this.currentDateTime > promo.facebook.startDate) {
        this.isPostedToFb = true;
      }
    }
    this._patchingMultipleImageToForm();
    this._patchMultipleMedia();
    if (
      !this.modes.newsMode &&
      this.offerType === 'good-price' &&
      promo.facebook.inAppParameters.price !== ''
    ) {
      this.showPriceSection = true;
      this.updateValidation();
    }
  }

  private _patchImageToMultipleImageSlider(images) {
    const urlArray = FbFormComponent._convertImageDataToURlArray(images);
    const control = this.fbForm.get('largeImage') as FormArray;
    // clearing array;
    while (control.length !== 0) {
      control.removeAt(0);
    }
    for (const img of urlArray) {
      control.push(new FormControl(img));
    }
  }

  private _patchMultipleMedia() {
    this.isVideo = !!this.getForm.value.video.url;
    let imgUrl;
    if (!this.isVideo) {
      this.imageUrl = ImageService.getPatchableUrl(
        this.getForm.value.largeImage
      );
      this.hasImage = true;
      imgUrl = this.imageUrl[0].url;

      const lastIndexOfDot = imgUrl.lastIndexOf('.');
      const fileFormat = imgUrl.slice(lastIndexOfDot);
      if (fileFormat === '.gif') {
        this.isGif = true;
      }
    }
    if (this.isVideo) {
      this.videoUrl = this.getForm.value.video;
      this.hasVideo = true;
      this.changeUploadType(this.isVideo);
      // we need to clear image in form because while patching , form array forms a formControl and hence largeImage is not empty and valid
      this.clearImageUrl(this);
    }
  }

  private _patchingMultipleImageToForm() {
    this.isVideo = !!this.getForm.value.video.url;
    if (!this.isVideo && !this.getForm.get('largeImage').value.length) {
      const controlLargeImage = this.getForm.get('largeImage') as FormArray;
      for (const url of this.formData.facebook?.largeImage) {
        controlLargeImage.push(new FormControl(url));
      }
    }
  }

  private static _convertImageDataToURlArray(
    imageSliderArray: MultipleImage[]
  ) {
    const urlArray = [];
    for (const img of imageSliderArray) {
      if (img.hasImage) {
        urlArray.push(img.url);
      }
    }
    return urlArray;
  }

  // ** Prepare Form Data and check Validation ** //
  // @return [boolean] => return false if form is invalid
  private _isValid() {
    this.submitForm = false;
    if (this.fbForm && this.fbForm.status === 'INVALID') {
      this.displayMessage = this._validator.processMessages(this.fbForm);
      return false;
    }
    return true;
  }

  // date time value set for mobiscroll date-time
  private _setUpDateTime(inst: DatepickerBase) {
    if (!this.formData.facebook) {
      OfferDateTimeService.setUpDateTime(this.fbForm, inst._el.id, 'fb');
    }
  }

  // on close of datetime
  private _closeDatePicker(inst: DatepickerBase) {
    const dateId = inst._el.id;
    if (this.modes.newsMode) {
      this._patchStartEndDatesForNewsMode(dateId);
    }
  }

  private _patchStartEndDatesForNewsMode(dateId) {
    const promoForm = this.getForm;
    if (dateId.indexOf('publishfb') === 0) {
      promoForm.patchValue({
        startDate: promoForm.value.publishDate,
      });
    }
    if (dateId.indexOf('expirefb') === 0) {
      promoForm.patchValue({
        endDate: promoForm.value.expirationDate,
      });
    }
  }

  onSelect() {
    this.visible = true;
  }

  ondeselect() {
    this.visible = false;
  }

  getStartDateTime(event: any) {
    this.fbForm.patchValue(
      {
        startDate: event.startDateTime,
        endDate: event.endDateTime,
        startNow: event.start_now,
      },
      {
        onlySelf: true,
      }
    );
  }

  getPublishExpirationDateTime(event: any) {
    this.fbForm.patchValue(
      {
        publishDate: event.startDateTime,
        expirationDate: event.endDateTime,
        publishNow: event.start_now,
      },
      {
        onlySelf: true,
      }
    );
  }
}
