import {Component, OnInit} from '@angular/core';
import {TokenService} from "../../../core/services";
import {ActivatedRoute} from "@angular/router";
import {saveAs} from "file-saver";

@Component({
  selector: 'app-guides',
  templateUrl: './guides.component.html',
  styleUrls: ['./guides.component.scss'],
})
export class GuidesComponent implements OnInit {

  pageTitle: string = 'Video guides';
  isBackGroundWhite: boolean = true
  showPreviewToggle: boolean = false;
  firstLink: boolean = false;
  videoLink = '';
  parentUrl = ''
  type: string = ''
  QCGuiderList = [
    {
      'title': 'Quick Coop (QC) Guider',
      'guideList': [
        {
          'title': 'Genslut Facebook til QC',
          'url': 'https://qcapi.quickinfo.dk/tutorial/gentilslut_facebook_til_quick_coop.mp4'
        },
        {
          'title': 'Sådan får du hjælp til QC',
          'url': 'https://qcapi.quickinfo.dk/tutorial/sedan_f%D0%B5r_du_help_til_quick_coop.mp4'
        }
      ]
    },
    {
      'title': 'JA TAK Opsætningsguider',
      'guideList': [
        {
          'title': 'Simpel JA TAK',
          'url': 'https://qcapi.quickinfo.dk/tutorial/opret_en_simpel_ja_tak.mp4'
        },
        {
          'title': 'JA TAK  med varianter',
          'url': 'https://qcapi.quickinfo.dk/tutorial/opret_en_ja_tak_med_varianter.mp4'
        },
        {
          'title': 'JA TAK Indstillinger ',
          'url': 'https://qcapi.quickinfo.dk/tutorial/ja_tak_indstillinger.mp4'
        },
        {
          'title': 'JA TAK - overisgten',
          'url': 'https://qcapi.quickinfo.dk/tutorial/ja_tak_oversigten.mp4'
        }
      ]
    },
    {
      'title': 'JA TAK Brugsvejledninger',
      'guideList': [
        {
          'title': 'Hvordan fungerer JA TAK i Coop App\'en',
          'url': 'https://qcapi.quickinfo.dk/tutorial/hvordan_fungerer_ja_tak_i_coop_app_en.mp4'
        },
        {
          'title': 'JA TAK-ordrelister og meddelelserne til butikken',
          'url': 'https://qcapi.quickinfo.dk/tutorial/ja_tak_ordrelisten_og_meddelelserne_til_butikken.mp4'
        }
      ]

    }
  ]

  PDFGuidesList = [
    {
      'title': 'JA TAK 2023',
      'url': 'https://qcapi.quickinfo.dk/pdf/JA_TAK_quickcoop_flere_varianter_Made by Coop-June 2023.pdf',
      'name': 'JA_TAK_quickcoop_flere_varianter_Made by Coop-June 2023.pdf'
    },
    {
      'title': 'Quick Coop vejledninger',
      'url': 'https://qcapi.quickinfo.dk/pdf/quickcoop_vejledning.pdf',
      'name': 'quickcoop_vejledning.pdf'
    },
    {
      'title': 'Quick Coop tips & tricks',
      'url': 'https://qcapi.quickinfo.dk/pdf/quickcooptipsogtricks.pdf',
      'name': 'quickcooptipsogtricks.pdf'
    },
    {
      'title': 'LiveShopping 2022',
      'url': 'https://qcapi.quickinfo.dk/pdf/Liveshopping guide - Facebook Live 081122.pdf',
      'name': 'Liveshopping guide - Facebook Live 081122.pdf'
    }
  ]

  constructor(
    private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.type = this.activatedRoute.snapshot.queryParams['type']
    if (this.type === 'video') {
      this.pageTitle = 'Video guides'
    } else {
      this.pageTitle = 'PDF guides'
    }
    this.setPreviousUrl()
  }

  setPreviousUrl() {
    const userType = TokenService.getUserType();
    if (userType === '1') {
      this.parentUrl = '/butikker/indstillinger';
    }
    if (userType === '2') {
      this.parentUrl = '/bestyrelse/indstillinger';
      this.parentUrl = '/bestyrelse/indstillinger';
    }
  }

  showPreview(link: string) {
    this.firstLink = link === 'https://qcapi.quickinfo.dk/tutorial/gentilslut_facebook_til_quick_coop.mp4';
    this.showPreviewToggle = true;
    this.videoLink = link;
    document.body.classList.add('overlay');

  }

  onClose() {
    this.showPreviewToggle = false;
    document.body.classList.remove('overlay');
  }


  downloadPDF(item) {
    saveAs(`${item.url}`, item.name);
  }


}
