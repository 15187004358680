import { Component, Input, OnInit } from '@angular/core';
import { CreateOption, Navitem } from 'app/shared/interface';
import { CreateOptionDataService } from 'app/core/services';

@Component({
  selector: 'coop-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() navItems: Navitem[];
  selectedOption: CreateOption;

  constructor(private _service: CreateOptionDataService) {

  }

  ngOnInit() {
  }

  onNavigate(item: any) {
    this.selectedOption = {
      mode: 'skab',
      type: 'tilbud',
      share: {
        coop: true,
        facebook: false,
        sms: false,
        mail: false,
        web: false,
        screen: false,
        dit: false
      },
      //if currentCalendarType is present set step to 3 else 1
      step: 1
    };
    if (item.name === 'Start') {
      this._service.setCreateOption(this.selectedOption);
    }
  }

}
