import {
  Component, ElementRef,
  EventEmitter,
  Input,
  OnChanges, OnInit,
  Output,
  SimpleChanges, ViewChild
} from '@angular/core';
import {
  Customer
} from 'app/features/jatak/interface';
import { COMMON_SELECT_SETTINGS } from 'app/shared/data';

@Component({
  selector: 'coop-order-list-accordion-facebook',
  templateUrl: './order-list-accordion-facebook.component.html',
  styleUrls: ['./order-list-accordion-facebook.component.scss']
})
export class OrderListAccordionFacebookComponent implements OnInit, OnChanges {
  @ViewChild('range') range: ElementRef;
  @Input() orderListing: any;
  @Input() customerList: Customer[];
  @Input() numbers: any;
  @Input() successReplyMessage;
  @Input() varaintList: any;
  @Output() approveOrder = new EventEmitter<any>();
  @Output() rejectOrder = new EventEmitter<number>();
  @Output() collectOrder = new EventEmitter<any>();
  @Output() cancelOrder = new EventEmitter<string>();
  @Output() orderQuantity = new EventEmitter<any>();
  @Output() retryOrder = new EventEmitter<number>();
  orderSelectSettings = {
    ...COMMON_SELECT_SETTINGS
  };

  showCustomerDetail: string = '';
  customerId: string = "1"

  constructor() {
  }

  ngOnInit(): void {
  }

// runs when any value of fields in @Input() decorator changes
  ngOnChanges(changes: SimpleChanges) {
    if (changes['customerList']) {
      this.customerList = changes['customerList'].currentValue;
    }
  }


  onCustomerSelect(id: string) {
    this.customerId = this.customerId === id ? "0" : id
  }

// *** It is evoked when all the orders by a customer is marked as picked-up ***
  // @param [userId: string] => id that uniquely identifies each customer in facebook
  onCollectOrder(userId): void {
    this.collectOrder.emit(userId);
  }

// *** It is evoked when all the orders by a customer is marked as delete ***
  // @param [orders: OrderListData] => Whole orders by a particular user in facebook
  onCancelOrder(userId): void {
    this.cancelOrder.emit(userId);
  }

// *** It is evoked when user manually approves an order ***
  // @param [order: OrderList] => The OrderList object to approve
  approve(order: any, quantity: any): void {
    this.approveOrder.emit(order);
    this.orderQuantity.emit(quantity);
  }


  // orderQuanitity():void {
  //   this.orderQuantity.emit(this.quantityForm.get('quantity').value)
  // }
// *** It is evoked when user manually rejects an order ***
  // @param [commentId: string] => id of the OrderList object to reject
  reject(commentId: number): void {
    this.rejectOrder.emit(commentId);
  }

// it is envoked when retry button is clicked
  onRetry(orderId: number) {
    this.retryOrder.emit(orderId);
  }
}
