<section
  class="app-page jatak-live-create-edit page-with-fixed-header-and-tab"
  [@parentAnimate]="showParent"
>
  <div
    class="fixed-header-bug-fix"
    id="fixed"
    [class.remove-fixed-header]="showParent === 'invisible'"
  >
    <coop-pageheader
      [pageTitle]="pageTitle"
      [isBackGroundWhite]="true"
      [isChild]="true"
      [routeTo]="previousUrl"
    ></coop-pageheader>
    <div class="fixed-header-content">
      <div class="tab-container">
        <div class="flex-display tab-container-wrap">
          <ul class="tab-list">
            <li class="tab-option active">
              Facebook
            </li>
          </ul>
          <div>
            Ingen andre kanaler
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="page-container">
    <div class="form-container styled-two" *ngIf="jatakLiveForm">
      <form [formGroup]="jatakLiveForm" (submit)="save()">
        <div class="wrapper">
          <div class="fb">
            <div style="margin-top: 10px" class="section-wrapper">
              <div class="title">
                Post tekst
              </div>
              <coop-mfl-text [offerType]="'jatak-live'"></coop-mfl-text>
              <div class="row form-group" controlErrorContainer>
                <label for="fb-title" class="margin-bottom-8">Overskrift</label>
                <input
                  id="fb-title"
                  type="text"
                  formControlName="title"
                  placeholder="Tilføj kampagnenavn"
                  [customErrors]="validationMessages['title']"
                />
              </div>
            </div>

            <div class="hr"></div>

            <div class="section-wrapper">
              <div class="flex-display">
                <div class="title">
                  Produkter
                </div>

                <div class="jatak-setting">
                  Import fra excel
                </div>
              </div>


              <div>
                <div formArrayName="products" class="product-list" [@list]>
                  <div
                    *ngFor="
                    let product of jatakLiveForm.controls['products'][
                      'controls'
                    ];
                    let i = index
                  "
                    class="product"
                    [@items]
                  >
                    <div class="flex-display product-title-wrapper">
                      <div class="product-title">
                        Product {{ i + 1 }}
                      </div>
                      <div
                        class="close"
                        (click)="removeProduct(i)"
                        *ngIf="numberOfProducts > 1"
                      >
                        <i class="icon icon-close-circle"></i>
                      </div>
                    </div>

                    <div class="product-wrapper">
                      <div
                        class="row form-group"
                        [formGroupName]="i"
                        controlErrorContainer
                      >
                        <label [for]="'product-title' + i" class="margin-bottom-8">Produkt overskrift</label>
                        <input
                          [id]="'product-title' + i"
                          type="text"
                          formControlName="productTitle"
                          placeholder="Tilføj kampagnenavn"
                          [customErrors]="validationMessages['productTitle']"
                        />
                      </div>

                      <div
                        class="row form-group"
                        [formGroupName]="i"
                        controlErrorContainer
                      >
                        <label [for]="'product-title' + i" class="margin-bottom-8">Unik variant søgeord</label>
                        <input
                          [id]="'product-title' + i"
                          type="text"
                          formControlName="productTitle"
                          placeholder="unikke nøgleord"
                          [customErrors]="validationMessages['productTitle']"
                        />
                      </div>
                    </div>


                    <!--                    <div class="row">-->
                    <!--                      <div-->
                    <!--                        class="col-two form-group"-->
                    <!--                        [formGroupName]="i"-->
                    <!--                        controlErrorContainer-->
                    <!--                      >-->
                    <!--                        <label [for]="'price' + i">Pris</label>-->
                    <!--                        <input-->
                    <!--                          [id]="'price' + i"-->
                    <!--                          type="text"-->
                    <!--                          formControlName="price"-->
                    <!--                          placeholder="Indtast pris"-->
                    <!--                          (keydown)="validateNumber($event)"-->
                    <!--                          [customErrors]="validationMessages['price']"-->
                    <!--                        />-->
                    <!--                      </div>-->
                    <!--                      <div-->
                    <!--                        class="col-two form-group"-->
                    <!--                        [formGroupName]="i"-->
                    <!--                        controlErrorContainer-->
                    <!--                      >-->
                    <!--                        <label [for]="'quantity' + i">Antal</label>-->
                    <!--                        <input-->
                    <!--                          [id]="'quantity' + i"-->
                    <!--                          type="number"-->
                    <!--                          formControlName="quantity"-->
                    <!--                          min="0"-->
                    <!--                          placeholder="Indtast lagerbeholdning, stk."-->
                    <!--                          [customErrors]="validationMessages['quantity']"-->
                    <!--                        />-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                    <!--                    <div class="push-container row">-->
                    <!--                      <div-->
                    <!--                        class="row form-group col-two switches"-->
                    <!--                        [formGroupName]="i"-->
                    <!--                      >-->
                    <!--                        <div>-->
                    <!--                          <h4 class="stk"></h4>-->
                    <!--                          <label class="switch">-->
                    <!--                            <input-->
                    <!--                              type="checkbox"-->
                    <!--                              formControlName="maxOrder"-->
                    <!--                              (click)="changeMaxOrderValidation(i)"-->
                    <!--                            />-->
                    <!--                            <span class="toggle round"></span>-->
                    <!--                          </label>-->
                    <!--                        </div>-->
                    <!--                        <label-->
                    <!--                        >Fast antal pr. ordre.<span-->
                    <!--                          style="font-weight: 400"-->
                    <!--                          *ngIf="product.get('maxOrder').value"-->
                    <!--                        >-->
                    <!--                          Mængden vil altid være:</span-->
                    <!--                        ></label-->
                    <!--                        >-->
                    <!--                      </div>-->
                    <!--                      <div-->
                    <!--                        class="col-two"-->
                    <!--                        [@fade]="'in'"-->
                    <!--                        *ngIf="product.get('maxOrder').value"-->
                    <!--                      >-->
                    <!--                        <div class="row form-group" [formGroupName]="i">-->
                    <!--                          <div class="max-order-form-group">-->
                    <!--                            <div class="max-order-input">-->
                    <!--                              <div class="max-order-picker">-->
                    <!--                                <a class="flex-item" (click)="subtractItems(i)">-->
                    <!--                                  <img src="assets/icons/minus-icon-green.png"-->
                    <!--                                       alt=""-->
                    <!--                                  />-->
                    <!--                                </a>-->
                    <!--                                <div class="max-order flex-item maxOrderCount">-->
                    <!--                                  <input-->
                    <!--                                    type="number"-->
                    <!--                                    id="min-order"-->
                    <!--                                    formControlName="maxOrderCount"-->
                    <!--                                    readonly-->
                    <!--                                    [customErrors]="-->
                    <!--                                    validationMessages['maxOrderCount']-->
                    <!--                                  "-->
                    <!--                                    [checkParent]="true"-->
                    <!--                                  />-->
                    <!--                                </div>-->
                    <!--                                <a class="flex-item" (click)="addItems(i)">-->
                    <!--                                  <img src="assets/icons/plus-icon-green.png"-->
                    <!--                                       alt=""-->
                    <!--                                  />-->
                    <!--                                </a>-->
                    <!--                              </div>-->
                    <!--                            </div>-->
                    <!--                          </div>-->
                    <!--                        </div>-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                    <!--                    <div-->
                    <!--                      class="row form-group"-->
                    <!--                      [formGroupName]="i"-->
                    <!--                      controlErrorContainer-->
                    <!--                    >-->
                    <!--                      <label [for]="'keyword' + i"-->
                    <!--                      >Nøgleord<br/><span style="font-weight: 400"-->
                    <!--                      >Kunden skal skrive-->
                    <!--                        <span style="font-weight: 700">ja tak [nøgleord]</span>-->
                    <!--                        i kommentarfeltet under livevideoen for at bestille-->
                    <!--                        varen fx-->
                    <!--                        <span style="font-weight: 700">ja tak 1A</span></span-->
                    <!--                      ></label-->
                    <!--                      >-->
                    <!--                      <coop-input-mask-->
                    <!--                        [id]="'keyword' + i"-->
                    <!--                        formControlName="keyword"-->
                    <!--                        [maxOrder]="product.get('maxOrder').value"-->
                    <!--                        [customErrors]="validationMessages['Keyword']"-->
                    <!--                      ></coop-input-mask>-->
                    <!--                    </div>-->
                    <!--                    <div-->
                    <!--                      class="row form-group"-->
                    <!--                      [formGroupName]="i"-->
                    <!--                      controlErrorContainer-->
                    <!--                    >-->
                    <!--                      <label [for]="'product-info' + i"-->
                    <!--                      >Produkt info (valgfrit)<br/><span-->
                    <!--                        style="font-weight: 400"-->
                    <!--                      >Kunden modtager denne info i Messenger, når kunden-->
                    <!--                        skriver-->
                    <!--                        <span style="font-weight: 700">info [nøgleord]</span> i-->
                    <!--                        kommentarfeltet under livevideoen.</span-->
                    <!--                      >-->
                    <!--                      </label>-->
                    <!--                      <textarea-->
                    <!--                        [id]="'product-info' + i"-->
                    <!--                        formControlName="productInfo"-->
                    <!--                        placeholder="Indtast produktinfo"-->
                    <!--                        [customErrors]="validationMessages['productInfo']"-->
                    <!--                      ></textarea>-->
                    <!--                    </div>-->
                  </div>
                  <!--                  <div class="row my-7" *ngIf="!isLive">-->
                  <!--                    <div class="col-two">-->
                  <!--                      <a-->
                  <!--                        (click)="addProduct(templateMode ? 1 : 0)"-->
                  <!--                        class="btn btn-red add-product-btn"-->
                  <!--                      >-->
                  <!--                        Tilføj produkt-->
                  <!--                      </a>-->
                  <!--                    </div>-->
                  <!--                    <div class="import col-two" *ngIf="!this.editMode">-->
                  <!--                      <input-->
                  <!--                        type="file"-->
                  <!--                        #excelInput-->
                  <!--                        (change)="onFileChange($event)"-->
                  <!--                        (click)="excelInput.value = null"-->
                  <!--                        hidden-->
                  <!--                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"-->
                  <!--                      />-->
                  <!--                      <div class="import-btn">-->
                  <!--                        <a-->
                  <!--                          (click)="excelInput.click()"-->
                  <!--                          class="btn btn-border-red"-->
                  <!--                        >-->
                  <!--                          <i class="ti-export" style="margin-right: 5px"></i>-->
                  <!--                          Importer fra Excel-->
                  <!--                        </a>-->
                  <!--                      </div>-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                </div>
              </div>


            </div>


            <!--            <div class="row">-->
            <!--              <h2 class="form-subtitle">Kampagneperiode</h2>-->
            <!--              <p class="live-date-info">-->
            <!--                Vælg <b>start-</b> og <b>sluttidspunkt</b> for din FB Live-->
            <!--                kampagne inkl. deadline for reservationer. Livevideoen på-->
            <!--                Facebook skal starte efter <b><i>Gyldig fra</i></b> og slutte-->
            <!--                før <b><i>Gyldig til.</i></b>-->
            <!--              </p>-->
            <!--              <p class="live-date-info">-->
            <!--                I den valgte periode vil ja-tak robotten være tilknyttet-->
            <!--                kampagnen og svare kunder, der skriver i livevideoens-->
            <!--                kommentarfelt på Facebook.-->
            <!--              </p>-->
            <!--              <p class="live-date-info">-->
            <!--                Efter <b><i>Gyldig til</i></b> svarer robotten ikke længere på-->
            <!--                kommentarer.-->
            <!--              </p>-->
            <!--              <div class="col-two form-group" controlErrorContainer>-->
            <!--                <label>Gyldig fra</label>-->
            <!--                <div class="date-time-picker">-->
            <!--                  <input-->
            <!--                    id="startscreen"-->
            <!--                    mbsc-datepicker-->
            <!--                    [mbscOptions]="datePickerSettings"-->
            <!--                    formControlName="startDate"-->
            <!--                    type="text"-->
            <!--                  />-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              <div class="col-two form-group" controlErrorContainer>-->
            <!--                <label>Gyldig til</label>-->
            <!--                <div class="date-time-picker">-->
            <!--                  <input-->
            <!--                    id="endscreen"-->
            <!--                    type="text"-->
            <!--                    mbsc-datepicker-->
            <!--                    [mbscOptions]="datePickerSettings"-->
            <!--                    formControlName="endDate"-->
            <!--                    [customErrors]="validationMessages['endDate']"-->
            <!--                  />-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--            <div class="push-container automatic-like-toggle">-->
            <!--              <div class="row form-group switches">-->
            <!--                <label><br/>'Like' automatisk kommentarer</label>-->
            <!--                <div>-->
            <!--                  <h4 class="stk mt-4"></h4>-->
            <!--                  <label class="switch">-->
            <!--                    <input-->
            <!--                      type="checkbox"-->
            <!--                      formControlName="shouldLike"-->
            <!--                      (click)="toggleShouldLike()"-->
            <!--                    />-->
            <!--                    <span class="toggle round"></span>-->
            <!--                  </label>-->
            <!--                </div>-->
            <!--                <p style="margin-bottom: 0">-->
            <!--                  SLÅ TIL for at robotten automatisk liker alle ja-tak-->
            <!--                  kommentarer i kampagneperioden, når ordren er registreret af-->
            <!--                  robotten-->
            <!--                </p>-->
            <!--              </div>-->
            <!--            </div>-->
            <div class="row form-submit-field">
              <input type="submit" value="Aktivér" class="login-btn btn-black-login"/>
            </div>
            <div class="row">
              <a (click)="exportExcelData()" class="login-btn btn-white-login">
                <i class="ti-download" style="margin-right: 5px"></i>
                Download Excel liste
              </a>
            </div>
            <div class="row">
              <a
                (click)="onOpenReporting()"
                class="login-btn btn-white-login "
                *ngIf="this.showReport"
              >
                Vis rapport</a
              >
            </div>
            <div class="row">
              <a
                (click)="onDelete()"
                class="login-btn btn-white-login btn-delete"
                *ngIf="this.editMode && !jatakLive.isLive"
              >
                Slet</a
              >
            </div>
            <!--            <div class="row">-->
            <!--              <a (click)="back()" class="btn btn-border-red btn-delete"-->
            <!--                >Tilbage</a-->
            <!--              >-->
            <!--            </div>-->
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
<coop-mfl-popup
  *ngIf="showMFLPopup && !editMode"
  (showPopup)="togglePopup($event)"
></coop-mfl-popup>
<router-outlet></router-outlet>
