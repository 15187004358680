import { Injectable } from '@angular/core';
import { NewOrder, OrderList } from '../interface';
import { ActivatedRouteSnapshot } from '@angular/router';
import { catchError } from 'rxjs/operators';

import {
  AppEventsService,
  ErrorhandlerService,
  TokenService,
} from 'app/core/services';
import { DataService, OrderService } from 'app/features/order-and-pickup/order';

@Injectable({
  providedIn: 'root',
})
export class OrderListResolverService  {
  currentTab: number;

  constructor(
    private _orderService: OrderService,
    private _errorHandlerService: ErrorhandlerService,
    private _dataService: DataService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    this._dataService.currentTab.subscribe((tab) => (this.currentTab = tab));
    return this._orderService
      .getOrderListing(1, this.currentTab)
      .pipe(
        catchError((err) => this._errorHandlerService.routeResolverError(err))
      );
  }
}

@Injectable({
  providedIn: 'root',
})
export class OrderResolverService  {
  constructor(
    private _orderService: OrderService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    const id = route.params['id'];
    return this._orderService
      .getSingleOrder(id)
      .pipe(
        catchError((err) => this._errorHandlerService.routeResolverError(err))
      );
  }
}

@Injectable({
  providedIn: 'root',
})
export class FinishedOrderResolverService  {
  constructor(
    private _orderService: OrderService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this._orderService
      .getFinishedOrders(1)
      .pipe(
        catchError((err) => this._errorHandlerService.routeResolverError(err))
      );
  }
}

@Injectable({
  providedIn: 'root',
})
export class NewOrderListResolverService  {
  constructor(
    private _orderService: OrderService,
    private _appEventsService: AppEventsService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    if (
      TokenService.getUserType() === '2' ||
      !TokenService.getUserDetail().scopes.orderAndPickup
    ) {
      return;
    }

    this._appEventsService.showLoader(true);
    return this._orderService
      .getNewOrders('Alle')
      .pipe(
        catchError((err) => this._errorHandlerService.routeResolverError(err))
      );
  }
}

@Injectable({
  providedIn: 'root',
})
export class PrintHistoryResolver  {
  constructor(
    private _orderService: OrderService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    if (TokenService.getUserType() === '2') {
      return;
    }
    return this._orderService
      .getPrintHistory(1)
      .pipe(
        catchError((err) => this._errorHandlerService.routeResolverError(err))
      );
  }
}
