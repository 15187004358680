import { inject, Injectable } from '@angular/core';
import { CanDeactivateFn, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { NotificationsService } from 'app/core/services';
import { WebsiteCreateEditComponent } from 'app/features/website';

@Injectable({
  providedIn: 'root'
})
export class WebsiteGuardService {
  constructor() {
  }

  canDeactivate(
    component: WebsiteCreateEditComponent
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (component.websiteForm.dirty && !component.formSaved) {
      const websiteTitle = component.websiteForm.value.title || 'Ny Website';
      return NotificationsService.confirm(
        `Dine ændringer til ${websiteTitle} bliver ikke gemt.`
      );
    }
    return true;
  }
}

export const WebsiteGuard: CanDeactivateFn<WebsiteCreateEditComponent> = (component) => {
  return inject(WebsiteGuardService).canDeactivate(component);
};
