import { Directive, ElementRef } from "@angular/core";
import { shareReplay, tap } from "rxjs/operators";
import { fromEvent } from "rxjs";
import { FormGroupDirective } from "@angular/forms";
import { environment } from "../../../environments/environment";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "form"
})
export class FormSubmitDirective {

  constructor(public host: ElementRef<HTMLFormElement>,
              private formGroup: FormGroupDirective
  ) {
  }

  submit$ = fromEvent(this.element, "submit").pipe(
    tap((_) => {
      if (!environment.production) {
        console.log('','FormGroup Data: visible only in development mode','\n',this.formGroup.form);
      }
      if (this.element.classList.contains("submitted") === false) {
        this.element.classList.add("submitted");
      }
    }),
    shareReplay(1)
  );

  get element() {
    return this.host.nativeElement;
  }
}
