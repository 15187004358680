<section class="loading" *ngIf="showLoader">
  <p class="loader-message" *ngIf="loaderMsg">{{ loaderMsg }}</p>
  <div class="loader-container">
    <div class="nt-loading" *ngIf="progress >= 0 && progress < 100">
      <p>Uploader video...</p>
      <div class="progress-bar">
        <div class="progress">
          <div class="bar" style="width:{{progress}}%"></div>
        </div>
        <p>
          (Det er vigtigt, at videoen optages med mobiltelefonen holdt
          vertical/oprejst)
        </p>
      </div>
    </div>
    <div class="uploaded-message" *ngIf="!uploadFailed && progress === 100">
      <p>Vi konverterer vidoen til kompatibelt format...</p>
      <br />
      <p>
        Dette kan tage lidt tid.<br />
        Hold dette vindue åbent.<br />
        Når din video er klar, vises den her.
      </p>
    </div>
    <div class="upload-failed" *ngIf="uploadFailed">
      <p>Videoupload mislykkedes</p>
    </div>
  </div>
</section>
