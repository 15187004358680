import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
import 'dayjs/locale/da';
import * as relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);
@Pipe({
  name: 'dateformat',
})
export class DateformatPipe implements PipeTransform {
//** transform date into locale
  //@params[inputdate, dateOnly] input value to be transformed and boolean value
  //if dateOnly is true => return inputdate in provided format
  //else return inputdate in provided format
  transform(inputdate: string, dateOnly?: boolean): string {
    dayjs.locale('da');
    if (dateOnly) {
      return dayjs(inputdate).format('LL');
    }
    return dayjs(inputdate).format('D MMMM  YYYY, HH:mm');
  }
}

@Pipe({
  name: 'datetostring',
})
export class DateToString implements PipeTransform {
  //**transform date into string
  //@params[date] => value to be transformed
  //returns  date in the provided format
  transform(date: Date): string {
    return dayjs(date).format('YYYY-MM-DD HH:mm');
  }
}

@Pipe({
  name: 'hoursdifference',
})
export class HoursDifference implements PipeTransform {
  //** returns time spend from the date passed in param
  //@params[date] value to be transformed
  transform(date: string): string {
    return dayjs(date).fromNow();
  }
}

@Pipe({
  name: 'dayandmonth',
})
export class DayAndMonth implements PipeTransform {
  //@params[date] value to be transformed
  // return given date in provided format
  transform(date: string): string {
    dayjs.locale('da');
    return dayjs(date).format('DD MMM');
  }
}
@Pipe({
  name: 'millisecondstominutes',
})
export class MillisToMinutes implements PipeTransform {
  //** transform milliseconds to minute
  //@params[time] millisecond value to be transformed into minutes
  transform(time: number): number {
    return time / 60000;
  }
}
@Pipe({
  name: 'dateformatddmmyy',
})
export class DateFormatDDMMYY implements PipeTransform {
  //**transforms given value to provided format
  //@params[date, dots, dateOnly] date => value to be transformed , dots and boolean are the boolean value
  //if dots is true => if dateOnly is true => transform given date in provided format
  //                              else => transform given date in provided format
  //esle return given date in provided format
  transform(date: string, dots?: boolean, dateOnly?: boolean): string {
    if (dots) {
      if (dateOnly) {
        return dayjs(date).format('DD.MM.YYYY');
      }
      return dayjs().format(' Do MMMM YYYY HH:mm');
    }
    return dayjs(date).format('DD-MM-YYYY HH:mm');
  }
}
@Pipe({
  name: 'datewithseconds',
})
export class DateWithSeconds implements PipeTransform {
  //** transform provided date in given format
  //@params[date] value to be transformed
  //return given date in provided format
  transform(date: Date): string {
    return dayjs(date).format('YYYY-MM-DD HH:mm:ss');
  }
}
@Pipe({
  name: 'timeOnly',
})
export class TimeOnly implements PipeTransform {
  //**transform provided value in given format
  //@params[date] value to be transformed
  transform(date: string): string {
    dayjs.locale('da');//set language to 'da'
    return dayjs(date).format('LT');
  }
}
@Pipe({
  name: 'screenpreviewstart',
})
export class ScreenPreviewStart implements PipeTransform {
  //** transforms provided value to the given format
  //@params[date] value to be transformed
  transform(date: string): string {
    dayjs.locale('da');
    return dayjs(date).format('dddd [d.] D MMMM');
  }
}
@Pipe({
  name: 'screenpreviewend',
})
export class ScreenPreviewEnd implements PipeTransform {
  //**transforms provided value to the given format
  //@params[date] value to be transformed
  transform(date: string): string {
    dayjs.locale('da');
    return dayjs(date).format('dddd [den] D MMMM');
  }
}
@Pipe({
  name: 'cooppreview',
})
export class CoopPreview implements PipeTransform {
  //**transforms provide value to given format
  //@params[date] value to be transformed
  transform(date: string): string {
    return dayjs(date).format('LT');
  }
}
@Pipe({
  name: 'orderlistDate',
})
export class OrderlistDateFormat implements PipeTransform {
  //**transforms provided value to the given format
  //@params[date] value to be transformed
  transform(date: string): string {
    dayjs.locale('da'); // set language to 'da'
    return dayjs(date).format('DD. MMM. YYYY, HH.mm');
  }
}
