import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import * as moment from 'moment';
import {Observable} from 'rxjs';
import {takeWhile} from 'rxjs/operators';
import {SMS_BESKED_VALIDATION_MESSAGES} from 'app/features/sms/data';
import {
  ErrorhandlerService,
  NotificationsService,
  CreateOptionDataService,
  TokenService,
  HelperService
} from 'app/core/services';
import {
  checkWhiteSpace, checkEmojiCharactersForSMSMessage
} from 'app/core/validators/custom.validator';
import {animateChild, fadeInOut, toggle} from 'app/shared/animations';
import {COMMON_DATEPICKER_SETTINGS} from 'app/shared/data';
import {Client, UserDetail, ValidationMessage} from 'app/shared/interface';
import {DateToString, NumberPad} from 'app/shared/pipes';
import {SingleSms, SmsService} from 'app/features/sms';
import {MbscDatepickerOptions} from '@mobiscroll/angular';
import {DatepickerBase} from '@mobiscroll/angular/dist/js/core/components/datepicker/datepicker';
import * as dayjs from "dayjs";

@Component({
  selector: 'coop-sms-message',
  templateUrl: './sms-message.component.html',
  styleUrls: ['./sms-message.component.scss'],
  animations: [animateChild, toggle, fadeInOut],
  host: {'[@childAnimate]': ''},
  providers: [NumberPad, DateToString]
})
export class SmsMessageComponent implements OnInit, OnDestroy {
  pageTitle: string = 'Opret nyt SMS';
  publishDate: string = '';
  isProcessing: boolean = false;
  textSmsForm: FormGroup;
  formSaved: boolean = false;
  titleDate: any;
  scheduled: boolean = false;
  client: Client;
  clients: Client[];
  userDetail: UserDetail;
  staticMessage: string = '';
  editMode: boolean = false;
  singleSms: SingleSms;
  dates = {
    startDate: null,
    endDate: null
  };
  shared = {
    coop: true,
    facebook: false,
    sms: false
  };
  currentUrl: string;
  successUrl: string;
  charCount: number;
  sms: {
    price: string;
    quantity: number;
  };
  smsCounter = 1;
  messageText: string;
  submitButton: string = 'Send';
  intervalTime;
  showMFLPopup = false;
  startDate = dayjs().format('YYYY-MM-DD')
  startTime = dayjs().format('HH:mm')
  datePickerSettings: MbscDatepickerOptions = {
    ...COMMON_DATEPICKER_SETTINGS, ...{
      dateWheels: 'YYYY MMMM DD',
      dateFormat: 'YYYY-MM-DD',
      onOpen: (_, inst) => this._setupDateTime(inst),
      onClose: () => this._setTitleDate()
    }
  };
  onStartNow: boolean = true
  validationMessages: ValidationMessage = SMS_BESKED_VALIDATION_MESSAGES;
  finalSmsMessage: string = '';
  private _subscriptionState: boolean = true;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _smsService: SmsService,
    private _dateToString: DateToString,
    private _numberPadPipe: NumberPad,
    private _createOptionService: CreateOptionDataService,
    private _router: Router,
    private _errorHandlerService: ErrorhandlerService
    // private  enco
  ) {
  }

  ngOnInit() {
    document.body.classList.add('overlay');
    this.editMode = this._activatedRoute.snapshot.data['editMode'];
    this.singleSms = this._activatedRoute.snapshot.data['sms'];
    this.clients = this._activatedRoute.snapshot.data['client'];
    this.userDetail = TokenService.getUserDetail();
    this.client = this.clients[0];
    this._configDatePicker();
    this.messageText = '';

    this.staticMessage = '\n';
    if (this.client.standardText) {
      this.staticMessage += this.client.standardText
    }
    if (this.client.afmeldingText) {
      this.staticMessage += this.client.afmeldingText;
    }
    this.successUrl = '/butikker/kalendar/sms';
    // *** Build Form Group *** //
    this.textSmsForm = this._formBuilder.group(
      {
        startNow: [true],
        ShareMessage: ['true', Validators.required],
        message: [this.messageText, [Validators.required, checkWhiteSpace, checkEmojiCharactersForSMSMessage]],
        PublishDate: ['', Validators.required]
      }
    );
    if (this.editMode) {
      // const processedMessage = this._processSms(this.singleSms.message);
      const {text: convertedProcessedMessage} = HelperService.smsParser(this.singleSms.message);
      this.textSmsForm.patchValue({
        ShareMessage: false,
        message: convertedProcessedMessage,
        PublishDate: moment(this.singleSms.send_time).toDate()
      });
      this.dates.startDate = this._dateToString.transform(
        moment(this.singleSms.send_time).toDate()
      );
      this.toggle('sched');
    }
    if (!this.editMode) {
      this.toggle('inst');
    }
    // this.getCurrentTime();
    this.smsCount();

  }

  smsConvert(ev) {
    const userInput = ev.target.value;
    let fullMessage;
    if (userInput) {
      fullMessage = `${userInput}${this.staticMessage}`;
    } else {
      fullMessage = `${this.staticMessage}`;
    }

    const {text: converted} = HelperService.smsParser(userInput);
    const {text, length, parts} = HelperService.smsParser(fullMessage);
    this.finalSmsMessage = text;
    this.textSmsForm.patchValue({
      message: converted
    });
    this.charCount = length;
    this.smsCounter = parts;
    this.getPriceandQuantity(parts)
    ;
  }

  getPriceandQuantity(part: number) {
    let price = part * this.userDetail.sms.cost * this.client.members;
    this.sms = {
      price: this._numberPadPipe.transform(price, 'da-DA'),
      quantity: part
    };
  }


  ngOnDestroy(): void {
    document.body.classList.remove('overlay');
    this._subscriptionState = false;
  }

  private _processSms(message: string): string {
    message = message.replace(this.client.standardText.replace('\n', ''), '');
    message = message.replace(this.client.afmeldingText.replace('\n', ''), '');
    return message;
  }

  setTimer(time?) {
    // title date to show on top in danish format 'LLL'
    clearInterval(this.intervalTime);
    if (!time) {
      this.intervalTime = setInterval(() => {
        this.titleDate = moment().format('LLL');
      }, 1);
    }
    if (time) {
      this.intervalTime = setInterval(() => {
        this.titleDate = moment(time).add(1, 'seconds').format('LLL');
      }, 1);
    }
  }

  // toggling publish date based on user choice
  toggle(option: string) {
    if (option === 'inst') {
      this.textSmsForm.get('PublishDate').setValidators(null);
      this.textSmsForm.get('PublishDate').updateValueAndValidity();
      //  setting title to current date after publish date removal
      this.titleDate = moment().format('LLL');
      this.textSmsForm.patchValue({
        ShareMessage: true
      });
      this.scheduled = false;
      this.submitButton = 'Send';
      this.setTimer();
    }
    if (option === 'sched') {
      this.scheduled = true;
      this.textSmsForm.patchValue({
        ShareMessage: false
      });
      this.textSmsForm.get('PublishDate').setValidators(Validators.required);
      this.textSmsForm.get('PublishDate').updateValueAndValidity();
      this.submitButton = 'Aktivér';
      this.setTimer(this.textSmsForm.value.PublishDate);
    }
  }

  toggleSMS(event: any) {
    this.scheduled = event.target.checked
    this.onStartNow = event.target.checked
  }

  togglePopup(event) {
    this.showMFLPopup = event;
  }

  private _setupDateTime(inst: DatepickerBase) {
    // *** Create Mode ***
    // Set Start Date at Today time 8:00 if empty
    if (!this.textSmsForm.value.StartDate) {
      if (inst._el.id === 'publishdate') {
        this.textSmsForm.patchValue({
          StartDate: moment().hour(8).minute(0).toDate()
        });
      }
    }
  }

  private _setTitleDate() {
    // setting title date as the form date in danish format
    this.setTimer(this.textSmsForm.value.PublishDate);
  }

  deleteSms() {
    if (!this.isProcessing) {
      this.isProcessing = true;
      NotificationsService.confirmWithCallback('Er du sikker?', (accept) => {
        if (accept) {
          this._smsService
            .deleteSms(this.singleSms.id)
            .pipe(takeWhile(() => this._subscriptionState))
            .subscribe(() => {
              this.isProcessing = false;
              NotificationsService.notify(
                'Meddelelsen blev slettet',
                'success',
                'top'
              );
              this._router.navigate([this.successUrl]).then();
            });
        }
      });
    }
  }

  save() {
    this.concatDateTime()
    if (!this._isValid()) {
      return;
    }
    if (this.textSmsForm.value.PublishDate) {
      this.textSmsForm.value.PublishDate = this._dateToString.transform(
        this.textSmsForm.value.PublishDate
      );
      this.publishDate = this.textSmsForm.value.PublishDate;
    } else {
      this.publishDate = '';
    }
    if (!this.isProcessing) {
      this.isProcessing = true;
      this.sendSingleSms(this.textSmsForm.value.startNow, this.publishDate)
        .pipe(takeWhile(() => this._subscriptionState))
        .subscribe({
          next: () => this._success(),
          error: (err) => this._handleError(err)
        });
    }
  }

  sendSingleSms(instance: boolean, publishDate: string): Observable<any> {

    const parm = {
      id: this.editMode ? this.singleSms.id : null,
      content: this.finalSmsMessage,
      solutionId: this.client.id,
      sendInstantly: instance,
      sendTime: publishDate
    };
    return this._smsService.sendSingleSms(parm, this.editMode);
  }


  smsCount() {
    const {parts, length} = HelperService.smsParser(this.staticMessage);
    this.smsCounter = parts;
    this.charCount = length;
    this.getPriceandQuantity(parts);
  }

  // ** Prepare Form Data and check Validation ** //
  // @return [boolean] => return false if form is invalid
  private _isValid() {
    if (this.textSmsForm.status === 'INVALID') {
      this._handleError('');
      return false;
    }
    return true;
  }

  private _configDatePicker() {
    let now = moment();
    let toAdd;
    if (now.minutes() % 15 !== 0) {
      const rem = now.minutes() % 15;
      if (rem < 15) {
        toAdd = 15 - rem;
      }
      if (rem > 15) {
        toAdd = rem - 15;
      }
      now = now.add(toAdd, 'minutes');
    }
    this.datePickerSettings.min = now.toDate();
  }

  formatDate(date: string) {
    dayjs.locale('da');
    return dayjs(date).format('D. MMMM');
  }

  onStartTimeChange(event: any) {
    this.startTime = event.valueText
    // this.concatDateTime()
  }

  concatDateTime() {
    let startDateTime = this.startDate + ' ' + this.startTime
    this.textSmsForm.patchValue({
      PublishDate: startDateTime,
    })
  }

  getDateTime(event: any) {
    if (this.editMode) {
      this.startDate = this.editMode ? this.startDate : dayjs().format('YYYY-MM-DD');
    } else {
      this.startDate = dayjs(event).format('YYYY-MM-DD')
    }
    // this.concatDateTime()
  }

  private _success(): void {
    this.formSaved = true;
    this.isProcessing = false;
    this._createOptionService.clearCreateOption();
    NotificationsService.notify('Besked sendt med succes', 'success', 'top');
    this._router.navigate(['/butikker/kalendar/sms']).then();
  }

  private _handleError(error): void {
    this.isProcessing = false;

    if (error.status === 422 && error.errors.standardText) {
      const errorMessage = 'Indsæt venligst ingen emoji-tegn (de ikke understøttes).';
      NotificationsService.notify(errorMessage, 'danger', 'top');
      return;
    }
    this._errorHandlerService.handleError(error || {code: -400}, 'sms');
  }
}
