<div class="order-list-header">
  <div class="order-title">
    {{ title }}
  </div>

  <div class="start-end-date">
    <div>
      <div>
        <ion-icon name="timer-outline"></ion-icon>
      </div>
      <div class="time-info">
        {{ startDate | dateformat : false}}
      </div>
    </div>

    <div>
      <div>
        <ion-icon name="timer"></ion-icon>
      </div>
      <div class="time-info">
        {{ endDate | dateformat : false}}
      </div>
    </div>
  </div>

</div>
