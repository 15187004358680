<div class="form-group" [formGroup]="smsForm">
  <div class="wrapper">
    <div class="upload-options"
         [ngClass]="{disabled : modes.editMode && isPostedToFb }">
      <div
        (click)="uploadTypeSelect(false)"
        [class.active]="!isVideo" class="options-list">
        Billeder
      </div>
      <div
        (click)="uploadTypeSelect(true)"
        [class.active]="isVideo" class="options-list">
        Video
      </div>
    </div>
    <div class="form-group" controlErrorContainer style="margin-bottom: 0">
      <coop-image-handler
        [imageUrl]="imageUrl"
        [hasImages]="hasImage"
        [singleCropper]="true"
        [disableUpload]="false"
        [isPostedToFb]="isPostedToFb"
        [isRectangle]="false"
        [videoUrl]="videoUrl"
        [editMode]="modes.editMode || modes.templateMode"
        [isVideo]="isVideo"
        [hasVideo]="hasVideo"
        [isGif]="isGif"
        [offerType]="modes.newsMode ? 'nyhed' : offerType"
        (panelType)="changeUploadType($event)"
        (video)="getVideoUrl($event)"
        (image)="getImagesUrl($event)"
        (processing)="imageProcessing($event)"
      ></coop-image-handler>
      <input
        formControlName="thumbnail"
        [customErrors]="validationMessages['thumbnail']"
        hidden
      />
    </div>
    <div class="form-group" formGroupName="video" controlErrorContainer>
      <input
        formControlName="url"
        [customErrors]="validationMessages['url']"
        hidden
      />
    </div>
    <div class="section-wrapper">

      <div class="title">
        Post tekst
      </div>

      <div class="form-group" controlErrorContainer>
        <label class="margin-bottom-8">Overskrift</label>
        <input
          type="text"
          (focusin)="onSelect()"
          (focusout)="ondeselect()"
          formControlName="title"
          [customErrors]="validationMessages['title']"
          [readOnly]="!showSmsTextSection"/>
        <div *ngIf="visible" class="remaining"> Brugte tegn: {{ smsForm.get("title").value.length }} / 99</div>
      </div>
      <div class="row form-group flex-column" controlErrorContainer>
        <label class="margin-bottom-8">Tekst</label>
        <coop-mfl-text
          [offerType]="modes.newsMode ? 'nyhed' : offerType"
        ></coop-mfl-text>
        <textarea name="" formControlName="longDescription" [customErrors]="validationMessages['longDescription']"
                  [readOnly]="!showSmsTextSection"></textarea>
      </div>
    </div>

    <div class="hr"></div>

    <div *ngIf="!modes.newsMode" class="section-wrapper">

      <div class="flex-display">
        <div class="title">
          Prisoplysninger
        </div>
        <div class="tooltip-icon">
                    <span
                      tooltip="Pris og mængde bliver indsat lige under overskriften."
                      placement="top"
                      delay="500"><i class="icon-info-circle-filled"></i></span>
        </div>
      </div>


      <div
        class="row form-group"
        formGroupName="inAppParameters"
        controlErrorContainer
        *ngIf="!modes.newsMode && offerType !== 'good-price'"
      >
        <div class="label-wrapper">
          <div>
            <label>Tilbudspris</label>
          </div>

<!--          <div class="tooltip-icon">-->
<!--                    <span-->
<!--                      tooltip="Pris og mængde bliver indsat lige under overskriften."-->
<!--                      placement="top"-->
<!--                      delay="500"><i class="icon-info-circle-filled"></i></span>-->
<!--          </div>-->
        </div>
        <input
          type="text"
          formControlName="offerPrice"
          placeholder="0.00"
          (keydown)="validateNumber($event)"
          [customErrors]="validationMessages['offerPrice']"
          [checkParent]="true"
        />
      </div>


      <div *ngIf="!modes.newsMode" class="form-group" controlErrorContainer>

        <div *ngIf="offerType !== 'good-price'" class="label-wrapper">
          <div>
            <label class="margin-bottom-8">
              Før pris <span>(vejl. udsalgspris)</span>
            </label>
          </div>
          <div class="tooltip-icon">
                    <span
                      tooltip="Pris og mængde bliver indsat lige under overskriften."
                      placement="top"
                      delay="500"><i class="icon-info-circle-filled"></i></span>
          </div>
        </div>
        <div *ngIf="showPriceSection" class="price-description-wrapper">
          <div class="price-container">
            <div formGroupName="inAppParameters">
              <input
                type="text"
                formControlName="price"
                [customErrors]="validationMessages['price']"
                min="0"
                placeholder="Pris"
                (keydown)="validateNumber($event)"
              />
            </div>
            <div style="margin:10px;min-width: fit-content">
              kr. pr.
            </div>
          </div>

          <div class="price-description-container">
            <div style="margin-right: 10px" class="price-description" formGroupName="inAppParameters">
              <input
                id="fb-price"
                type="text"
                formControlName="priceDescription"
                [customErrors]="validationMessages['priceDescription']"
                min="0"
                placeholder="vægt/mængde"
                (keydown)="validateNumber($event)"
              />
            </div>

            <div class="price-limit" formGroupName="inAppParameters">
              <select
                formControlName="uom"
                mbsc-select
                [mbscOptions]="priceDescSelectSettings"
                class="form-select">
              </select>
              <div class="dropdown-icon"><i class="icon-arrow-down"></i></div>
            </div>

            <div *ngIf=" offerType === 'good-price' && showPriceSection" style="cursor: pointer;margin-top: 8px"
                 (click)="removePrices()">
              <i class="icon icon-close-circle" style="color: #D10A11;font-size: 24px"></i>
            </div>
          </div>

        </div>


        <div *ngIf="!showPriceSection" class="row addvarient" (click)="addPriceField()">
          <a class="login-btn btn-white-login button-wrapper">
            <div style="margin-right: 10px"><i class="icon-add-circle"></i></div>
            <div>Tilføj pris</div>
          </a>
        </div>
      </div>

    </div>

    <div class="hr"></div>

    <coop-sendsms
      *ngIf="clients"
      [offerType]="offerType"
      [clients]="clients"
      [promo]="smsForm.value"
      [modes]="modes"
      [tempSms]="tempSms"
      [smsMsg]="smsText"
      [newsMode]="modes.newsMode"
      [readOnly]="!showSmsTextSection"
      [showErrorInput]="showEmojiError"
      [goodPriceMode]="offerType === 'good-price'"
      (patchEvent)="smsInit($event)"
      (valid)="checkSmsMessageValidity($event)"
    ></coop-sendsms>

    <span class="error-message" *ngIf="showEmojiError">
      Feltet kan ikke indeholde emoji-tegn
    </span>

    <div class="hr"></div>


    <div *ngIf="!modes.newsMode">
      <h2 class="form-subtitle">
        {{
        offerType === 'good-price'
          ? 'God Pris Gyldighedsperiode'
          : 'Tilbudsperiode'
        }}
      </h2>
      <div *ngIf="tab === 'sms'" class="form-group" controlErrorContainer>
        <div class="form-group">
          <app-start-end-date-time
            [startDateTimeS]="smsForm.get('startDate').value"
            [endDateTime]="smsForm.get('endDate').value"
            [validFrom]="validFrom"
            [validTill]="validTill"
            (startDateTime)="getStartDateTime($event)"
          ></app-start-end-date-time>
        </div>
      </div>

    </div>
    <div>
      <h2 class="form-subtitle">Hvornår meddelelsen skal sendes</h2>
      <div class="time-wrapper border-15">

        <div class="flex-display">
          <div class="flex-display">
            <div>
              <ion-icon name="timer-outline"></ion-icon>
            </div>
            <div class="dispatchtime">
              Udsendingstidspunkt
            </div>
          </div>
          <div>
            <label class="switch">
              <input type="checkbox">
              <span class="slider round"></span>
            </label>
          </div>
        </div>

        <div
          class="col-two form-group date-pick datepicker"
          controlErrorContainer
        >
          <mbsc-datepicker
            #myDatepicker
            (onChange)="getDateTime($event)"
            [controls]="['calendar']"
            [value]="startDate"
            [showRangeLabels]="false"
            display="inline"></mbsc-datepicker>
          <input
            mbsc-datepicker
            #starttime
            [controls]="['time']"
            display="bottom"
            [value]="startTime"
            (onChange)="onStartTimeChange($event)"
            [touchUi]="false" hidden/>
        </div>
        <div class="enddate">
          <div style="font-size: 14px">
            <div>Send Date</div>
          </div>
          <div class="timewrapper">
            <div> {{ formatDate(startDate)   }}</div>
            <div class="enddatetime" (click)="starttime.click()">
              <span> {{ startTime }} </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
