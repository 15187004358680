import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Location} from '@angular/common';
import {Router} from '@angular/router';

import {AppEventsService} from 'app/core/services';
import {Store} from "@ngxs/store";
import {ClearOrderListState} from 'app/features/jatak/states';

@Component({
  selector: 'coop-pageheader',
  templateUrl: './pageheader.component.html',
  styleUrls: ['./pageheader.component.scss'],
})
export class PageheaderComponent implements OnInit {
  @Input() isBackGroundWhite: boolean = false
  @Input() showPrinter : boolean = false
  @Input() isChild: boolean;
  @Input() pageTitle: string;
  @Input() pageSubTitle: string;
  @Input() routeTo: string;
  @Input() create: boolean;
  @Input() step: number;
  @Input() customBack: boolean;
  @Input() customFunction: () => void;
  @Input() calendarPreviousUrl: string;
  @Input() section: string
  @Input() isTemplate : boolean = false
  @Output() stepEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() closeComponent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() printOrder = new EventEmitter<null>();
  constructor(
    private _router: Router,
    private _appEvents: AppEventsService,
    private _location: Location,
    private _store: Store
  ) {
  }

  ngOnInit() {
  }

  onPrint() {
    this.printOrder.emit();
  }

//** navigate to the respective url */
  //if customBack is true => customFunction()
  //if routeTo is present => if routeTo is not equal to '/' then _location.back() else navigate to [this.routeTo]
  //else => showComponent() close component
  //        closeComponent.emit() to emit event
  close() {
    if (this.section === 'orderlist') {
      this._store.dispatch(new ClearOrderListState())
    }
    if (this.customBack) {
      this.customFunction();
      return;
    }
    if (this.routeTo) {
      if (this.routeTo === '/') {
        this._location.back();
      } else {
        this._router.navigate([this.routeTo]).then();
      }
    } else {
      this._appEvents.showComponent(false);
      this.closeComponent.emit(true);
    }
  }

//**//** navigate to the respective url */
  //if calendarPreviousUrl length is greater than 1 => navigate to [calendarPreviousUrl]
  //if step is greater than 1 => reduce the value of step by 1
  //                          => stepEvent.emit() to emit event
  back() {
    if(this.isTemplate) {
      this._appEvents.showComponent(false);
      this.closeComponent.emit(true);
    } else {
      this._location.back();
    }

    // if (this.calendarPreviousUrl.length > 1) {
    //   this._router.navigate([this.calendarPreviousUrl]).then();
    //   return;
    // }
    // this._router.navigate([this.routeTo]).then();
  }

}
