import { Injectable } from '@angular/core';
import { WindowService } from 'app/core/services/window.service';
import { Subject } from 'rxjs';
import { environment } from 'environments/environment';
// import { openDB, deleteDB } from 'idb';
import { IDBdatachange } from 'app/shared/interface';

@Injectable({
  providedIn: 'root',
})
export class IdbService {
  private iDatabase: IDBFactory;
  private _dbPromise: Promise<any>;
  readonly dbName: string = environment.database;
  private IDBStores: Array<string> = environment.stores;
  private _IDBDataChange = new Subject<IDBdatachange>();
  $IDBDataChange = this._IDBDataChange.asObservable();

  constructor(private _windowService: WindowService) {
    this.iDatabase = this._windowService.window.indexedDB;
    this.dbName = 'CoopDatabase';
  }

  // *** Connect to IndexedDB Database ***
  // Creates a new DB is there is none
  // Creates stores depending number of stores provided
  connectToIDB() {
    const that = this;
    // this._dbPromise = openDB(this.dbName, 1, {
    //   upgrade(db) {
    //     for (const i in that.IDBStores) {
    //       if (that.IDBStores.hasOwnProperty(i)) {
    //         const store = db.createObjectStore(that.IDBStores[i], {
    //           keyPath: 'id',
    //         });
    //         store.createIndex('id', 'id', { unique: true });
    //       }
    //     }
    //   },
    // });
  }

  // *** Add Items to IndexedDB store ***
  // @params [storeName] => name of the IndexedDB store where items will be added
  // @params [data] => Array of items to be added
  addItems(storeName: string, data: any): void {
    this._dbPromise.then((db) => {
      const tx: IDBTransaction = db.transaction(storeName, 'readwrite');
      const store: IDBObjectStore = tx.objectStore(storeName);
      for (const i in data) {
        if (data.hasOwnProperty(i)) {
          store.put(data[i]);
        }
      }
      this._IDBDataChanged({
        changed: true,
        store: storeName,
      });
      return tx.oncomplete;
    });
  }

  // *** Fetches all the items from IndexedDB store ***
  // @params [storeName] => name of the IndexedDB to be used
  // @returns [Promise] => all the items for the store
  getItems(storeName: string) {
    return this._dbPromise.then((db: any) => {
      const tx = db.transaction(storeName, 'readonly');
      const store = tx.objectStore(storeName);
      return store.getAll();
    });
  }

  // *** Fetches item using its id from IndexedDB store ***
  // @params [storeName] => name of the IndexedDB store to be used
  // @params [id] => Id of the item to fetched
  // @returns [Promise] => Item matching the id
  getItem(storeName: string, id: string) {
    return this._dbPromise.then((db) => {
      const tx: IDBTransaction = db.transaction(storeName, 'readonly');
      const store: IDBObjectStore = tx.objectStore(storeName);
      return store.get(id);
    });
  }

  // *** Clear all the items of an IndexedDB Store ***
  // @params [storeName] => name of the IndexedDB store to be used
  clearStore(storeName: string) {
    this._dbPromise.then((db) => {
      const tx = db.transaction(storeName, 'readwrite');
      const store = tx.objectStore(storeName);
      store.clear().then();
    });
  }

  // *** Delete an indexedDB Database ***
  deleteDatabase() {
    return this._dbPromise.then((db) => {
      db.close();
      // deleteDB(this.dbName).then();
    });
  }

  getDatabase(): Promise<any> {
    return this._dbPromise;
  }

  // *** Fire and event when ever datas on an indexedDB Store is changed ***
  private _IDBDataChanged(changeData: IDBdatachange) {
    this._IDBDataChange.next(changeData);
  }
}
