import { Shared } from 'app/shared/interface';

export interface JatakList {
  id: any
  title: string
  turnover: string
  customer: string
  sold: string
  stockLeft: string
  orders: string
  pickedUp: string
  actionRequired: string
  image: string
  isVariant: string
  variantCounts: string
  totalSoldVariant: string
  startDate: string
  endDate: string
  canSaveAsTemplate: boolean
  shared: Shared
}

export interface Jatak {
  kardex: string;
  title: string;
  description: string;
  endedTitle: string;
  endedDescription: string;
  images:any
  isVariant:boolean;
  imageUrl: ImageUrl;
  startDate: any;
  started:boolean
  endDate: any;
  ended?: boolean;
  facebook: number;
  order: number;
  coop: number;
  sms: number;
  originId: string;
  wantOrderId: string;
  replyText: string;
  replyTextEnd: string;
  replyTextMore: string;
  offers: number;
  id: string;
  fbPostId: string;
  phone: string;
  smsText?: string;
  web: number;
  own: boolean;
  video?: Video;
  shared: Shared;
  variants: []
  smsSent: boolean;
  prices: PriceInfo[];
  quantity?:any,
  canSaveAsTemplate : boolean,
  soldOutReply: any,
  soldOutTitle : any,
  alertQuantity  :any,
  hasCustomerLimit : boolean,
  customerLimit : any,
  hasAlertQuantity : boolean
  start_now : boolean

}

export interface PriceInfo {
  price: string;
  quantity: string;
  uom: string;
}

/* export interface OrderData {
  orders: OrderList[];
  total: number;
  request: number;
  title: string;
  fb_post_id: string;
} */

// export interface OrderList {
//   name: string;
//   id: string;
//   order: string;
//   collected: boolean;
//   cancelled: boolean;
//   to_approve: boolean;
//   orderId: number;
//   system: string;
//   message: string;
//   commentLink: string;
// }

export interface ImageUrl {
  largeImage: string;
  thumbnail: string;
}

export interface Video {
  url: string;
  thumbnail: string;
}

export interface OrderListData {
  FACEBOOK: Facebook
  COOP_APP: CoopApp
  SMS: Sms
  variants: any[]
  title: string
  startDate: string
  endDate: string
  shared: OrderListShared
}
export interface OrderListShared {
  FACEBOOK: boolean
  COOP_APP: boolean
  SMS: boolean
}
export interface Facebook {
  summary: Summary
  customers: Customer[]
}
export interface CoopApp {
  summary: Summary
  customers: Customer[]
}

export interface Sms {
  summary: Summary
  customers: Customer[]
}

export interface Summary {
  turnover: string
  customers: number
  sold: number
  pickedUp: number
  orderCount: number
  remainingStock: number
  facebookReach  :number
}

export interface Customer {
  name: string
  userId: string
  cancelled: boolean
  collected: boolean
  isActionable: boolean
  approvalRemaining: boolean
  orders: Order[]
}

export interface Order {
  id: number
  variantId: any
  variantName: string
  cancelled: boolean
  collected: boolean
  toApprove: boolean
  approved: boolean
  orderId: string
  phone: any
  message: string
  quantity: number
  showReplyError: boolean
  canResendReply: boolean
  replyErrorText: any
  commentLink: string
  orderedAt: string
}

// export interface OrderListTabData {
//   [key: string]: OrderList[];
// }
// export interface OrderListTabDataFb {
//   [key: string]: OrderListData;
// }
// export interface OrderListData {
//   id?: string;
//   orders: OrderList[];
//   approvalRemaining: boolean;
//   collected: boolean;
//   cancelled: boolean;
// }
// export interface OrderList {
//   name: string;
//   id: string;
//   quantity: number;
//   collected: boolean;
//   cancelled: boolean;
//   toApprove: boolean;
//   orderId: number;
//   system: string;
//   message: string;
//   commentLink: string;
//   phone: number;
//   orderedAt: string;
//   variantId?: number;
//   variantName?: string;
//   approved?: boolean;
//   rejected?: boolean;
// }
// export interface OrderData {
//   orders: CustomerGroupOrderData;
//   soldItems: TabData;
//   orderCount: TabData;
//   picked: TabData;
//   noOfCustomers: number;
//   noOfOrders: number;
//   total: number;
//   variantsTotal: any;
//   request: number;
//   title: string;
//   startDate: string;
//   endDate: string;
//   fbPostId: string;
// }
export interface TabData {
  coop: number;
  facebook: number;
  sms: number;
}
