import { CreateOption } from 'app/shared/interface';

export const DefaultSharedValue = {
  coop: false,
  facebook: false,
  sms: false,
  mail: false,
  web: false,
  screen: false,
  dit: false,
};
export const OrderTab = [
  'coop',
  'facebook',
  'sms',
  'web',
  'dit',
  'screen',
  'mail',
];
export const SharedNames = {
  coop: 'Coop App',
  facebook: 'Facebook',
  sms: 'SMS',
  mail: 'Mail',
  web: 'Web',
  screen: 'Butiksskærme',
  dit: 'Digital avis',
};
export const AllowedChannelValue = {
  coop: true,
  facebook: true,
  sms: true,
  mail: true,
  web: true,
  screen: true,
  dit: true,
};
export const DefaultCreateOptionValue: CreateOption = {
  mode: 'skab',
  type: 'tilbud',
  share: {
    coop: true,
    facebook: false,
    sms: false,
    mail: false,
    web: false,
    screen: false,
    dit: false,
  },
  step: 1,
  isSet: false,
};
