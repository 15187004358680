import { inject, Injectable } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { TokenService } from 'app/core/services';
import { environment } from 'environments/environment';

// ***  Only Authenticated user is allowed from this routeGuard ***
// Checks Token and its expiry and resolves respective routes if not expired.
// @returns boolean =>  true:    Route is success
//                      false:   Route is '/login'
@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(private _router: Router) {
  }

  checkIfAuthenticated() {
    if (TokenService.checkAuthenticatedState()) {
      return true;
    }
    this._router.navigate(['/login']).then();
    return false;
  }

  checkIfNotAuthenticated() {
    if (!TokenService.checkAuthenticatedState()) {
      return true;
    }
    this._router.navigate(['/']).then();
    return false;
  }

  checkIfEnabledInstaging() {
    if (environment.enableInStaging) {
      return true;
    }
    this._router.navigate(['/']).then();
    return false;
  }
}

export const IsAuthenticated: CanActivateFn = () => {
  return inject(AuthGuard).checkIfAuthenticated();
};

export const IsNotAuthenticated: CanActivateFn = () => {
  return inject(AuthGuard).checkIfNotAuthenticated();
};

export const IsEnabledInStaging: CanActivateFn = () => {
  return inject(AuthGuard).checkIfEnabledInstaging();
};

