import {
  animate,
  style,
  transition,
  trigger,
  state,
} from '@angular/animations';
// created animation varible 'toggle' with trigger 'toggle'
// transition in both direction (visible<=>invisible) takes  transition time 400ms with ease-in-out
export const toggle = [
  trigger('toggle', [
    state(
      'visible',
      style({
        maxHeight: '1000px',
        overflow: 'hidden',
        display: 'block',
      })
    ),
    state(
      'invisible',
      style({
        maxHeight: '0',
        overflow: 'hidden',
        display: 'block',
      })
    ),
    transition('visible <=> invisible', animate('400ms ease-in-out')),
  ]),
];
