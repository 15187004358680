import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Chart, registerables} from "chart.js";
import {DashboardService, ErrorhandlerService} from "../../../core/services";

Chart.register(...registerables);

@Component({
  selector: 'app-quicktogo-luckybag-graphs',
  templateUrl: './quicktogo-luckybag-graphs.component.html',
  styleUrls: ['./quicktogo-luckybag-graphs.component.scss'],
})
export class QuicktogoLuckybagGraphsComponent implements OnInit, OnChanges, AfterViewInit {
  lineChart: any;
  months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
  yAxisLabel = '';
  xlabel = '';
  chartData: any;
  earlierData: any;
  presentLabel: any;
  earlierLabel: any;
  title: string;
  earlier: string;
  pickedUpData: any
  totalOrdersData: any
  notPickedUpData: any
  @ViewChild('lineCanvas') private lineCanvas: ElementRef;
  @Input() turnOverGraph: any
  luckyBagGraph: any
  sortList = [
    {
      "id": 1,
      "name": "Bestilt",
      "value": "total_ordered"
    },
    {
      "id": 2,
      "name": "Afhentet",
      "value": "picked_up"
    },
    {
      "id": 3,
      "name": "Ikke afhentet",
      "value": "not_picked_up"
    }
  ]

  activeFilter = ['total_ordered', 'picked_up', 'not_picked_up']

  constructor(private _service: DashboardService,
              private _errorService: ErrorhandlerService
  ) {
  }

  ngOnInit() {
    this.getLuckyBagsReport()
  }

  getLuckyBagsReport() {
    this._service.getLuckyBagReport(this.activeFilter).subscribe({
      next: (res) => {
        this.totalOrdersData = res['total_ordered']
        this.pickedUpData = res['picked_up']
        this.notPickedUpData = res['not_picked_up']
        if (this.totalOrdersData) {
          this.months = Object.keys(this['totalOrdersData']);
        }
        if (this.lineChart) {
          this.lineChart.destroy()
        }
        setTimeout(() => this.lineChartMethod())
      },
      error: (err) => {
        this.handleError(err)
      }
    })
  }

  isIncludes(item) {
    return this.activeFilter.includes(item.value)
  }

  onFilter(item) {
    const index = this.activeFilter.indexOf(item.value);
    if (index !== -1) {
      this.activeFilter.splice(index, 1);
    } else {
      this.activeFilter.push(item.value);
    }
    this.getLuckyBagsReport()
  }

  ngOnChanges(changes: SimpleChanges) {

  }
  ngAfterViewInit() {
    this.lineCanvas.nativeElement.focus()
  }

  lineChartMethod() {
    // if (!this.notPickedUpData && !this.totalOrdersData && !this.pickedUpData) {
    //   return;
    // }
    this.lineChart = new Chart(this.lineCanvas.nativeElement, {
      type: 'line',
      data: {
        labels: this.months,
        datasets: [
          {
            label: '',
            fill: true,
            backgroundColor: '#2F80ED',
            borderColor: '#2F80ED',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#2F80ED',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 4,
            tension: 0.6,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#2F80ED',
            pointHoverBorderColor: '#2F80ED',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: this.totalOrdersData,
            spanGaps: true,

          },
          {
            label: '',
            fill: true,
            backgroundColor: '#27AE60',
            borderColor: '#27AE60',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#27AE60',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 4,
            tension: 0.6,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#27AE60',
            pointHoverBorderColor: '#27AE60',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: this.pickedUpData,
            spanGaps: true,
          },
          {
            label: '',
            fill: true,
            backgroundColor: '#C31414',
            borderColor: '#C31414',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#C31414',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 4,
            tension: 0.6,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#C31414',
            pointHoverBorderColor: '#C31414',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: this.notPickedUpData,
            spanGaps: true,
          }
        ]
      },

      options: {
        plugins : {
          legend : {
            display : false
          }
        },
        scales: {
          y: {
            beginAtZero: true,
            grace: 90,
            ticks: {
              precision: 0,
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false
      }
    });
  }

  handleError(err) {
    this._errorService.handleError(err, '')
  }
}
