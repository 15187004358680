import {Component, ElementRef, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {JatakDefault, ValidationMessage} from '../../../../shared/interface';
import {JatakService} from '../../services';
import {takeWhile} from 'rxjs/operators';
import {Jatak_Validation_Messages} from '../../data';
import {animateParent, slideAnimation} from '../../../../shared/animations';
import {ErrorhandlerService, HelperService, TokenService} from '../../../../core/services';
import {
  checkMaxLengthForSoldOutReply,
  checkMaxLengthForSoldOutTitle,
  checkPhoneNumber
} from '../../../../core/validators';
import {FormatNumberPipe} from '../../../../shared/pipes';
import {COMMON_SELECT_SETTINGS} from '../../../../shared/data';
import * as _ from 'lodash';
import {MbscSelectOptions} from '@mobiscroll/angular';

@Component({
  selector: 'app-jatak-default',
  templateUrl: './jatak-default.component.html',
  styleUrls: ['./jatak-default.component.scss'],
  animations: [animateParent, slideAnimation],
  providers: [FormatNumberPipe]
})
export class JatakDefaultComponent implements OnInit, OnDestroy {
  @ViewChild('emailInput') emailInput: ElementRef;
  @ViewChild('addButton', {static: false}) addButton: ElementRef;
  @ViewChild('mobilescreen') mobilescreen: ElementRef;
  @ViewChild('standardText') standardText: ElementRef;
  @ViewChild('replyMethods') replyMethods: ElementRef;

  jatakDefaultForm: FormGroup;
  formSaved: boolean = false;
  pageTitle: string;
  isBackGroundWhite: boolean = true
  showParent: string = 'visible';
  parentUrl: string;
  previousUrl: string;
  jatakDefault: JatakDefault;
  limit: number = 1;
  showMaxOrder: boolean = false
  showMaualApproval: boolean = false
  emailList: any = [];
  isProcessing: boolean = false;
  emptyEmail: boolean = false;
  invalidEmail: boolean;
  sameEmail: boolean = false;
  disabled: boolean = false;
  activeMethod: number;
  showReply: boolean = false;
  private _subscriptionState = true;
  numbers = [];
  stringNum: string = '';
  alertQuantitySelectSettings: MbscSelectOptions = {
    ...COMMON_SELECT_SETTINGS, ...{
      data: []
    }
  };
  validationMessage: ValidationMessage = Jatak_Validation_Messages;
  soldOutTitleLength: any = 0;
  soldOutReplyLength: any = 0;
  showSmsSection: boolean = false;
  showEmailSection: boolean = false;
  methodList = [
    {
      "id": 1,
      "name": "Svarmetode",
      "scrollToDiv": "#response"
    },
    {
      "id": 2,
      "name": "Standard tekster",
      "scrollToDiv": "#standard"
    },
    {
      "id": 3,
      "name": "Restriktioner",
      "scrollToDiv": "#restriction"
    },
    {
      "id": 4,
      "name": "Meddelelser",
      "scrollTo": "#notification"
    },
  ]

  constructor(private _activatedRoute: ActivatedRoute,
              private _formBuilder: FormBuilder,
              private _jatakservice: JatakService,
              private _errorHandlerService: ErrorhandlerService,
              private _numberFormatPipe: FormatNumberPipe,
              private _router: Router) {
  }

  ngOnInit() {
    this.pageTitle = 'JA TAK indstillinger';
    this._checkChildPage();
    this._initComp();
    while (this.limit <= 99) {
      this.alertQuantitySelectSettings.data.push(this.limit);
      this.limit++;
    }
    this.setPreviousUrl();
    this._setupJatakDefaultForm();

    // initialize email list
    this.emailList = this.jatakDefaultForm.get('emails').value as String[];
    if (this.jatakDefault) {
      this.jatakDefaultForm.patchValue({
        soldOutTitle: this.jatakDefault.soldOutTitle,
        soldOutReply: this.jatakDefault.soldOutReply,
        emails: [],
        hasAlertQuantity: this.jatakDefault.hasAlertQuantity,
        alertQuantity: this.jatakDefault.alertQuantity,
        phone: this._numberFormatPipe.transform(this.jatakDefault.phone),
        hasCustomerLimit: this.jatakDefault.hasCustomerLimit,
        customerLimit: this.jatakDefault.customerLimit,
        notificationPhone: this._numberFormatPipe.transform(this.jatakDefault.notificationPhone),
        emailNotification: this.jatakDefault.emailNotification,
        smsNotification: this.jatakDefault.smsNotification
      });
      this.emailList = this.jatakDefault.emails;

      if (this.jatakDefault.smsNotification) {
        this.showSmsSection = true;
        this.jatakDefaultForm.get('notificationPhone').setValidators([Validators.required, checkPhoneNumber]);
        this.jatakDefaultForm.get('notificationPhone').updateValueAndValidity();
      }
      if (this.jatakDefault.emailNotification) {
        this.showEmailSection = true;
      }

      if (this.jatakDefault.hasCustomerLimit) {
        this.showMaxOrder = true
      }

      if (this.jatakDefault.hasAlertQuantity) {
        this.showMaualApproval = true
      }
    }
    this.disabled = this.emailList.length > 2;

    this.jatakDefaultForm.get('soldOutTitle').valueChanges.pipe(
      takeWhile(() => this._subscriptionState)
    ).subscribe((res) => {
      this.soldOutTitleLength = this.calculateLength(res);
    });

    this.jatakDefaultForm.get('soldOutReply').valueChanges.pipe(
      takeWhile(() => this._subscriptionState)
    ).subscribe((res) => {
      this.soldOutReplyLength = this.calculateLength(res);
    });
  }

  calculateLength(str: string) {
    return _.size(str);
  }

  ngOnDestroy(): void {
    document.body.classList.remove('overlay');
    this._subscriptionState = false;
  }


  onSmsToggle(event) {
    this.showSmsSection = event.target.checked;
    if (this.showSmsSection) {
      this.jatakDefaultForm.get('notificationPhone').setValidators([Validators.required, checkPhoneNumber]);
      this.jatakDefaultForm.get('notificationPhone').updateValueAndValidity();
    } else {
      this.jatakDefaultForm.get('notificationPhone').clearValidators();
      this.jatakDefaultForm.get('notificationPhone').updateValueAndValidity();
    }
  }

  onEmailToggle(event) {
    this.showEmailSection = event.target.checked;
  }


  private _checkChildPage(): void {
    document.body.classList.add('overlay');
  }

  setPreviousUrl() {
    const userType = TokenService.getUserType();
    if (userType === '1') {
      this.parentUrl = '/butikker/indstillinger';
      this.previousUrl = '/butikker/indstillinger';
    }
    if (userType === '2') {
      this.parentUrl = '/bestyrelse/indstillinger';
      this.parentUrl = '/bestyrelse/indstillinger';
    }
  }

  private _setupJatakDefaultForm(): void {
    this.jatakDefaultForm = this._formBuilder.group({
      soldOutTitle: ['', [Validators.required, checkMaxLengthForSoldOutTitle]],
      soldOutReply: ['', [Validators.required, checkMaxLengthForSoldOutReply]],
      hasCustomerLimit: [false],
      customerLimit: ['', Validators.required],
      hasAlertQuantity: [false],
      alertQuantity: ['', [Validators.required]],
      phone: [''],
      emails: [],
      smsNotification: [false],
      emailNotification: [false],
      notificationPhone: []
    });
  }

  private _isValid() {
    return this.jatakDefaultForm.status !== 'INVALID';

  }

  onSetMaxOrderToggle(event: any) {
    this.showMaxOrder = event.target.checked
    if (event.target.checked) {
      this.jatakDefaultForm.patchValue({
        customerLimit: 1
      })
    }
  }

  onManualApproval(event: any) {
    this.showMaualApproval = event.target.checked
    if (this.showMaualApproval) {
      this.jatakDefaultForm.patchValue({
        alertQuantity: 1
      })

      this.jatakDefaultForm.get('phone')?.setValidators([checkPhoneNumber])
      this.jatakDefaultForm.get('phone')?.updateValueAndValidity()
    } else {
      this.jatakDefaultForm.get('phone')?.clearValidators()
      this.jatakDefaultForm.get('phone')?.updateValueAndValidity()
    }
  }

  //To add multiple email address
  addEmail() {
    if (this.emailList.length !== 0) {
      this.emptyEmail = false;
    }
    let email = this.emailInput.nativeElement.value;
    if (!email) {
      return;
    }
    if (!this._checkSameEmailAddress(email)) {
      return;
    }
    if (!this._checkInvalidEmail(email)) {
      return;
    }

    this.emailInput.nativeElement.value = '';
    this.disabled = this.emailList.length > 2;

  }

  deleteEmail(i: number) {
    this.emailList.splice(i, 1);
    this.disabled = this.emailList.length > 2;

  }

  private _initComp() {
    this.jatakDefault = this._activatedRoute.snapshot.data['data'];
  }

  private _checkInvalidEmail(email) {
    let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!email.match(regex)) {
      this.invalidEmail = true;
      this.emptyEmail = false;
      return false;
    } else {
      this.emptyEmail = false;
      this.invalidEmail = false;
      this.emailList.push(email);
      return true;
    }
  }

  private _checkSameEmailAddress(email) {
    if (!this.emailList.includes(email)) {
      this.sameEmail = false;
      return true;
    } else {
      this.sameEmail = true;
      return false;
    }
  }

  save() {
    // let email = this.emailInput.nativeElement.value;
    this._prepareFormValue();
    if (!this._isValid()) {
      return;
    }
    if (this.showEmailSection && this.emailList.length === 0) {
      this.emptyEmail = true;
      return;
    }
    let data = {
      soldOutTitle: this.jatakDefaultForm.get('soldOutTitle').value,
      soldOutReply: this.jatakDefaultForm.get('soldOutReply').value,
      phone: this.jatakDefaultForm.value.phone,
      emails: this.emailList,
      hasCustomerLimit: this.jatakDefaultForm.get('hasCustomerLimit').value,
      customerLimit: this.jatakDefaultForm.get('customerLimit').value,
      hasAlertQuantity: this.jatakDefaultForm.get('hasAlertQuantity').value,
      alertQuantity: this.jatakDefaultForm.get('alertQuantity').value,
      doPrivateReply: this.jatakDefault.doPrivateReply,
      smsNotification: this.jatakDefaultForm.get('smsNotification').value,
      notificationPhone: this.jatakDefaultForm.value.notificationPhone,
      emailNotification: this.jatakDefaultForm.get('emailNotification').value
    };
    this._jatakservice.sendJatakDefault(data).pipe(
      takeWhile(() => this._subscriptionState)
    ).subscribe(({
      next: () => this._success(),
      error: (err) => this._handleError(err)
    }));
  }

  // prepare forms value before submitting to backend

  _prepareFormValue() {
    // const regex = /(?!\d) +(?=\d)/g;
    // const subst = ``;
    this.jatakDefaultForm.value.phone = this.jatakDefaultForm.value.phone ? this.jatakDefaultForm.value.phone.replace(/ /g, '') : '';
    this.jatakDefaultForm.value.notificationPhone = this.jatakDefaultForm.value.notificationPhone ? this.jatakDefaultForm.value.notificationPhone.replace(/ /g, '') : '';
  }

  validateNumber(event: any) {
    HelperService.preventInvalidNumbers(event);
  }

  formatPhoneNumber(ev) {
    if (ev.key === 'Backspace') {
      this.stringNum = ev.target.value;
    } else {
      this.stringNum = ev.target.value.replace(/[^\d]/g, '').replace(/(.{2})/g, '$1 ');
    }
    this.jatakDefaultForm.patchValue({
      phone: this.stringNum
    });
  }

  formatSMSPhoneNumber(ev) {
    if (ev.key === 'Backspace') {
      this.stringNum = ev.target.value;
    } else {
      this.stringNum = ev.target.value.replace(/[^\d]/g, '').replace(/(.{2})/g, '$1 ');
    }
    this.jatakDefaultForm.patchValue({
      notificationPhone: this.stringNum
    });
  }


// activate selected method and route to selected method div using view child
  onMethodClick(id: number) {
    this.activeMethod = id;
    if (id === 1) {
      const headerOffset = 45;
      let element = document.getElementById('response')
      element?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      })
    }

    if (id === 2) {
      let element = document.getElementById('standard')
      element?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      })
    }

    if (id === 3) {
      let element = document.getElementById('restriction')
      element?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      })
    }

    if (id === 4) {
      let element = document.getElementById('notification')
      element?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      })
    }
  }

  onPublicPrivateReply() {
    this.jatakDefault.doPrivateReply = true;
  }

  onPublicReplyOnly() {
    this.jatakDefault.doPrivateReply = false;
  }

  toggleReply() {
    this.showReply = !this.showReply;
  }

  private _success(): void {
    this.formSaved = true;
    this._router.navigate([this.previousUrl]).then();
  }

  private _handleError(error) {
    this._errorHandlerService.handleError(error || {code: -400}, 'ja-tak-default');
    this.isProcessing = false;
  }
}
