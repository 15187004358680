export const Website_Validation_Messages = {
  image: {
    required: 'Upload venligst billede',
  },
  title: {
    required: 'Udfyld venligst feltet',
  },
  descriptions: {
    required: 'Udfyld venligst feltet',
  }
};
