export const Order_Validation_Messages = {
  comment: {
    maxlength: 'Tekst må højst være 300 tegn',
  },
  sms: {
    maxlength: 'Tekst må højst være 300 tegn',
  },
  notification: {
    maxlength: 'Tekst må højst være 300 tegn',
  },
};
