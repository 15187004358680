<div class="form-group" [formGroup]="coopForm">
  <div class="wrapper">

    <div class="upload-options"
         [ngClass]="{disabled : modes.editMode && isPostedToFb }">
      <div
        (click)="uploadTypeSelect(false)"
        [class.active]="!isVideo" class="options-list">
        Billeder
      </div>
      <div
        (click)="uploadTypeSelect(true)"
        [class.active]="isVideo" class="options-list">
        Video
      </div>
    </div>


    <div class="form-group" controlErrorContainer style="margin-bottom: 0">
      <coop-image-handler
        [imageUrl]="imageUrl"
        [hasImages]="hasImage"
        [singleCropper]="true"
        [disableUpload]="false"
        [isPostedToFb]="isPostedToFb"
        [isRectangle]="modes.newsMode"
        [videoUrl]="videoUrl"
        [editMode]="modes.editMode || modes.templateMode"
        [isVideo]="isVideo"
        [hasVideo]="hasVideo"
        [isGif]="isGif"
        [offerType]="modes.newsMode ? 'nyhed' : offerType"
        (panelType)="changeUploadType($event)"
        (video)="getVideoUrl($event)"
        (image)="getImagesUrl($event)"
        (processing)="imageProcessing($event)"
      ></coop-image-handler>
      <input
        formControlName="largeImage"
        [customErrors]="validationMessages['largeImage']"
        hidden
      />
    </div>
    <div class="form-group" formGroupName="video" controlErrorContainer>
      <input
        formControlName="url"
        [customErrors]="validationMessages['url']"
        hidden
      />
    </div>

    <div class="section-wrapper">
      <div class="title">
        Post tekst
      </div>


      <div class=" form-group" controlErrorContainer>
        <label class="margin-bottom-8">Overskrift*</label>
        <input
          type="text"
          (focusin)="onSelect()"
          placeholder="Indsæt overskrift"
          (focusout)="ondeselect()"
          formControlName="title"
          [customErrors]="validationMessages['title']" />
        <div *ngIf="visible" class="remaining"> Brugte tegn: {{ coopForm.get("title").value.length }} / 99</div>
      </div>
      <div
        class="row form-group flex-column"
        controlErrorContainer
        *ngIf="!modes.newsMode"
      >
        <label class="margin-bottom-8">Tekst*</label>
        <coop-mfl-text [offerType]="'tilbud'"></coop-mfl-text>
        <textarea
          name=""
          placeholder="Indsæt tekst"
          formControlName="longDescription"
          [value]="coopForm.get('longDescription').value | formatLongDescription"
          [customErrors]="validationMessages['longDescription']"></textarea>
      </div>
      <div
        class="row form-group flex-column"
        controlErrorContainer
        *ngIf="modes.newsMode"
      >
        <label class="margin-bottom-8">Tekst*</label>
        <!--      <coop-mfl-text [offerType]="'nyhed'"></coop-mfl-text>-->
        <coop-text-editor
          name=""
          formControlName="longDescription"
        ></coop-text-editor>
        <div></div>
      </div>
    </div>

    <div class="divider"></div>

    <div *ngIf="!modes.newsMode" class="section-wrapper">

      <div class="flex-display">
        <div class="title">
          Prisoplysninger
        </div>

        <div class="tooltip-icon">
                    <span
                      tooltip="Pris og mængde bliver indsat lige under overskriften."
                      placement="top"
                      delay="500">
                       <ion-icon name="information-circle" class="font-22" style="color: #C5C5C7"> </ion-icon>
                    </span>
        </div>


      </div>


      <div
        class="row form-group"
        formGroupName="inAppParameters"
        controlErrorContainer
        *ngIf="!modes.newsMode && offerType !== 'good-price'"
      >

        <div class="label-wrapper">
          <div>
            <label class="margin-bottom-8">Tilbudspris</label>
          </div>

          <!--          <div class="tooltip-icon">-->
          <!--                    <span-->
          <!--                      tooltip="Pris og mængde bliver indsat lige under overskriften."-->
          <!--                      placement="top"-->
          <!--                      delay="500"><i class="icon-info-circle-filled"></i></span>-->
          <!--          </div>-->
        </div>
        <input
          type="text"
          formControlName="offerPrice"
          placeholder="0,00"
          (keydown)="validateNumber($event)"
          [customErrors]="validationMessages['offerPrice']"
          [checkParent]="true"
        />
      </div>


      <div *ngIf="!modes.newsMode" class="form-group" controlErrorContainer>

        <div *ngIf="offerType !== 'good-price'" class="label-wrapper">
          <div>
            <label class="margin-bottom-8">
              Før pris <span>(vejl. udsalgspris)</span>
            </label>
          </div>
          <!--          <div class="tooltip-icon">-->
          <!--                    <span-->
          <!--                      tooltip="Pris og mængde bliver indsat lige under overskriften."-->
          <!--                      placement="top"-->
          <!--                      delay="500"><i class="icon-info-circle-filled"></i></span>-->
          <!--          </div>-->
        </div>
        <div *ngIf="showPriceSection" class="price-description-wrapper">
          <div class="price-container" formGroupName="inAppParameters">
            <div>
              <input
                type="text"
                formControlName="price"
                min="0"
                placeholder="Pris"
                (keydown)="validateNumber($event)"
                [customErrors]="validationMessages['price']"
              />
            </div>
            <div style="margin:10px;min-width: fit-content">
              kr. pr.
            </div>
          </div>

          <div class="price-description-container">
            <div style="margin-right: 10px" class="price-description" formGroupName="inAppParameters">
              <input
                id="fb-price"
                type="text"
                formControlName="priceDescription"
                min="0"
                placeholder="vægt/mængde"
                (keydown)="validateNumber($event)"
                [customErrors]="validationMessages['priceDescription']"
              />
            </div>

            <div #input class="price-limit" formGroupName="inAppParameters">
              <select
                formControlName="uom"
                mbsc-select
                [mbscOptions]="priceDescSelectSettings"
                class="form-select">
              </select>
              <div class="dropdown-icon">
                <ion-icon name="chevron-down" class="font-13"></ion-icon>
              </div>
            </div>
            <div *ngIf=" offerType === 'good-price' && showPriceSection" style="cursor: pointer;margin-top: 8px"
                 (click)="removePrices()">
              <ion-icon name="close-circle" style="color: #C5C5C7;font-size: 24px"></ion-icon>
            </div>
          </div>

        </div>

        <div *ngIf="!showPriceSection" class="row addvarient" (click)="addPriceField()">
          <a class="login-btn btn-white-login button-wrapper " style="margin-bottom: 0"
          >
            <div style="margin-right: 10px">
              <ion-icon name="add-outline" class="font-18"></ion-icon>
            </div>
            <div>Tilføj pris</div>
          </a>
        </div>
      </div>

    </div>

    <div class="divider" *ngIf="!modes.newsMode"></div>

    <div class="section-wrapper">
      <div class="title">
        Planlægning
      </div>


      <div *ngIf="!modes.newsMode">
        <h2 class="form-subtitle">
          {{
            offerType === 'good-price'
              ? 'God Pris Gyldighedsperiode'
              : 'Tilbudsperiode'
          }}
        </h2>
        <div *ngIf="tab === 'coop'" class="form-group">
          <coop-date-time-range-picker
            [datePickerData]="startEndPickerData"
            (onDateTimeChange)="getStartDateTime($event)"
          ></coop-date-time-range-picker>
        </div>

      </div>
      <div>
        <h2 class="form-subtitle">Hvornår skal beskeden vises</h2>
        <div *ngIf="tab === 'coop'" class="form-group" controlErrorContainer>
          <coop-date-time-range-picker
            [datePickerData]="publishStartEndPickerData"
            (onDateTimeChange)="getPublishExpirationDateTime($event)"
          ></coop-date-time-range-picker>
        </div>
      </div>

    </div>


  </div>
</div>
