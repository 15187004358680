import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from 'app/core/services';
import { Client, EmailData } from 'app/shared/interface';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  constructor(private _apiService: ApiService) {}

// *** Gets the store's own setting for greeting text and total members ***
// @returns [Observable of Client info ]
  getClients(): Observable<Client[]> {
    return this._apiService
      .get(`get-clients`)
      .pipe(map((res) => res['clients']));
  }

//** gets mail list
  //@params[params] object containing endDate, offerId and userType
  getMailList(params: any): Observable<EmailData> {
    return this._apiService.post(
      'get-member-mails-user',
      ApiService.CreateFormData(params)
    );
  }
}
