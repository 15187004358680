export const ErrorMessages = {
  system: {
    '-404': 'Noget gik galt. Prøv igen',
    '-83': 'Sessionen er udløbet. Log venligst ind igen.',
  },
  promo: {
    '-10': 'Ugyldig pris',
    '-75': 'Ugyldigt Kardex ID',
    '-400': 'Venligst tjek / udfyld alle felter',
  },
  poll: {
    '-119': 'Afstemning kan ikke redigeres',
    '-400': 'Venligst tjek / udfyld alle felter',
    '-121': 'Det valgte tidsinterval er allerede brugt.',
    600: 'Der kan kun være en aktiv afstemning ad gangen.',
    620: 'Der er ingen vælgere.',
  },
  sms: {
    '-1': "Telefonnummeret 12345678 er allerede medlem af din klub.",
    '1': 'Medlemmet har endnu ikke bekræftet deres medlemskab sendt via SMS.',
    '-400': 'Venligst tjek / udfyld alle felter',
  },
  'ja-tak': {
    '-1': "Noget gik galt. Prøv igen",
    '-400': 'Venligst tjek / udfyld alle felter',
  },
  'ja-tak-live': {
    '-400': 'Venligst tjek / udfyld alle felter',
    '-422': 'Det samme nøgleord bruges til flere produkter',
    '-801': 'Jatak live eksisterer allerede for den givne dato',
    '-802': 'Kan ikke slette igangværende Ja-tak live',
  },
  feedback: {
    '-400': 'Venligst tjek / udfyld alle felter',
  },
  user: {
    '-74': 'Invalid KardexId',
  },
  products: {
    '-100': 'Empty product Kardex ID',
    '-101': 'Empty product image Url',
    '-102': '',
    '-103': 'Empty product thumbnail Url',
    '-104': 'Empty product title',
    '-105': 'Empty product description',
    '-106': 'Produktpris påkrævet',
    '-107': 'Invalid product price',
    '-108': 'Empty product duty text',
    '-109': 'Empty product turnaround time text',
    '-110': 'Wrong product id',
    '-111': 'Empty Product id',
    '-112': 'Empty Kardex Id',
    '-113': 'Ugyldigt Kardex ID',
    '-114': 'Ugyldigt ordre-id',
    '-115': 'Produktet bruges i ordrer og kan ikke slettes',
    '-400': 'Venligst tjek / udfyld alle felter',
  },
  orders: {},
  events: {
    '-400': 'Venligst tjek / udfyld alle felter',
    '-121': 'Sædeantalet skal være et helt tal større end nul',
    '-122':
      'Antallet af pladser, der kan købes, skal være et heltal større end nul og mindre end det samlede antal pladser',
    '-123': 'Ugyldig pris',
    '-127': 'Der er ingen reservation til dette arrangement.',
    600: 'Speciel begivenhed allerede oprettet. Slet tidligere begivenheder.',
    602: 'Der er ingen reservation til dette årsmøde.',
    702: 'Der er ingen reservation til dette generalforsamling.',
  },
  website: {
    '-400': 'Venligst tjek / udfyld alle felter',
  },
  produkt: {
    '-400': 'Venligst tjek / udfyld alle felter',
    '-8': 'Kategori ikke fundet',
    '-9': 'Butikken blev ikke fundet',
    '-12': 'Produkt ikke fundet',
    '-13': 'Skabelon ikke fundet',
    '-16': 'Produkt forbeholdt vogn. Kan ikke slette produkt.',
    '-17': 'Produktet er en del af en ordre. Kan ikke slette produkt.',
    '-18': 'Produktet er aktivt. Kan ikke slette produkt.',
    '-19': 'Dato for afhentningsperiode er ugyldig',
    '-20':
      'Total lagerbeholdning kan ikke være mindre end den reserverede mængde.',
  },
  order: {
    '-400': 'Venligst tjek / udfyld alle felter',
    '-9': 'Butikken blev ikke fundet',
    '-10': 'Ordre ikke fundet',
    80: 'Invalid Authorization token',
    81: 'Der opstod et problem med udskrivningsservice.',
    82: 'Printeren er ikke konfigureret, ellers var den ikke konfigureret korrekt.',
    83: 'Shop Kardex ikke fundet i udskrivningstjenestens API.',
    381: 'Basket error - Noget gik galt. Prøv igen',
  },
  orderSms: {
    '-400': 'Venligst tjek / udfyld alle felter',
    '-11': 'Ordrestatus ikke fundet',
    '-14': 'Beskeden kan ikke gemmes for denne ordrestatus',
  },
  orderThankYouSms: {
    '-400': 'Venligst tjek / udfyld alle felter',
  },
  okscreen: {
    '-400': 'Venligst tjek / udfyld alle felter',
    '-908': 'Flere pladsindgange til samme dato',
    '-907': 'Tidsrum er allerede taget',
    '-906': 'Afspilningsliste ikke fundet',
    '-905': 'Tilføjelse af afspilningsliste kunne ikke tilføjes',
    '-904': 'Den aktuelle afspilningsliste kunne ikke hentes',
    '-911': 'Denne bruger må ikke oprette okskærmen',
  },
  fbcomment: {
    300: 'Vi har allerede svaret p\u00e5 det med succes! Intet behov for yderligere handling.'
  }
};
