export interface SingleOrder {
  data: Order;
  default_message: KlarSms;
}

export interface Order {
  id: number;
  orderNumber: number;
  category: string;
  bookingDate: string;
  pickupDate: string;
  approvedDate: string;
  phoneNumber: number;
  orders: ProductList[];
  orderComment: string;
  orderMessage: string;
  sentComment: Message[];
  orderStatus: number;
  deliveryStatus: number;
  notificationMessage: Notification[];
  customerName: string;
  totalPrice: number;
  printError: boolean;
}

export interface OrderList {
  id: number;
  orderNumber: number;
  category: string;
  bookingDate: string;
  pickupDate: string;
  totalPrice: number;
  totalQuantity: number;
  orderStatus: number;
  deliveryStatus: number;
  priority: number;
}

export interface ProductList {
  productTitle: string;
  quantity: number;
  price: number;
  message: string;
}

export interface NewOrder {
  count: number;
  data: NewOrderList[];
}

export interface NewOrderList {
  id: number;
  orderNumber: number;
  category: string;
  bookingDate: string;
}

export interface Message {
  comment: string;
  sent_date: string;
}

export interface Notification {
  notification: string;
  sent_date: string;
}

export interface PrintOrderList {
  customerName: string;
  bookingDate: string;
  category: string;
  orders: ProductList[];
  phoneNumber: number;
  pickupDate: string;
  totalPrice: number;
}

export interface ThankYouSms {
  comment: string;
}

export interface KlarSms {
  comment: string;
  notPickedUpMessage: string;
  pickedUpMessage: string;
  notification: KlarNotification[];
}

export interface KlarNotification {
  comment: string;
  hoursAfter: string;
}

export interface PrepListData {
  [key: string]: OrderList[];
}
export interface PrintHistory {
  category: string;
  date: string;
  status: number;
  jobId: number;
  orderId: string;
}
