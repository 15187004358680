import {
  trigger,
  state,
  animate,
  transition,
  style,
} from '@angular/animations';

//created animation variable 'animateParent'
//state defines the states to call at the end of each transition
//transition on both direction (visible<=>invisible) takes 600ms  and same custom defined easing curve changing the element's style to respective state's.
export const animateParent = [
  trigger('parentAnimate', [
    state(
      'visible',
      style({
        position: 'relative',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      })
    ),
    state(
      'invisible',
      style({
        position: 'absolute',
        top: '-150vh',
        left: 0,
        right: 0,
        bottom: 0,
        height: 0,
        overflow: 'hidden',
      })
    ),
    transition(
      'visible <=> invisible',
      animate('600ms cubic-bezier(.35,0,.25,1)')
    ),
  ]),
];
//created animation 'animateChild' 
//style of default state(*) is defined
// :enter => transition when element enter in the DOM with style of top: 100vh then animates it to change that top to 0 as the element is inserted into the view over 500ms transition time and custom defined easing curve.
export const animateChild = [
  trigger('childAnimate', [
    state(
      '*',
      style({
        position: 'relative',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 50,
        overflowY: 'scroll',
      })
    ),
    transition(':enter', [
      style({
        top: '100vh',
      }),
      animate(
        '500ms cubic-bezier(.35,0,.25,1)',
        style({
          top: 0,
        })
      ),
    ]),
    transition(':leave', [
      animate(
        '500ms cubic-bezier(.35,0,.25,1)',
        style({
          top: '100vh',
        })
      ),
    ]),
  ]),
];
