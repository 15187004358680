// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  version: 13,
  production: false,
  pdfurl: 'https://qcmobapi.quickinfo.dk/assets/files/',
  mainurl: 'https://qcmobapi.quickinfo.dk',
  apiurl: 'https://qcmobapi.quickinfo.dk/api/',
  imageUrl: 'https://qcmobapi.quickinfo.dk/api/quickcoop/',
  webhookurl: 'https://webhook.quickinfo.dk/api/',
  oauthurl: 'https://qcmobapi.quickinfo.dk/api/oauth/',
  fburl: 'https://qcmobapi.quickinfo.dk/api/facebook/',
  adfsurl: 'https://adfsprod.quickinfo.dk?s=prod&dev=true',
  adfslogout:
    'https://adfsprod.quickinfo.dk/module.php/core/authenticate.php?as=default-sp&logout&dev=true',
  adobeAnalyticsUrl:
    '//assets.adobedtm.com/251e2d2244aa/418af0b958e7/launch-7dfaa975187c-development.min.js',
  appversion: require('../../package.json').version + '-dev',
  database: 'CoopDatabase',
  stores: ['promos', 'shop'],
  enableInStaging: true
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
