import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

import { ErrorhandlerService, NotificationsService } from 'app/core/services';
import { OrderList, OrderService } from 'app/features/order-and-pickup/order';
import { animateParent } from 'app/shared/animations';

@Component({
  selector: 'coop-finished-order-list',
  templateUrl: './finished-order-list.component.html',
  styleUrls: ['./finished-order-list.component.scss'],
  animations: [animateParent],
})
export class FinishedOrderListComponent implements OnInit, OnDestroy {
  finishedOrderList: OrderList[];
  allFinishedOrderList: OrderList[];
  showParent: string = 'visible';
  pageTitle: string = 'Afsluttet';
  currentUrl: string;
  parentUrl: string;
  previousUrl: string;
  page: number = 1;
  limit: number = 10;
  deliveryStatus: string[] = ['Afvist', 'Ikke afhentet', 'Afhentet'];

  private _subscriptionState: boolean = true;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _errorHandlerService: ErrorhandlerService,
    private _orderService: OrderService
  ) {}

  ngOnInit() {
    this._activatedRoute.parent.data
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe((url) => (this.parentUrl = url['parentUrl']));
    this._initComponent();
  }

  ngOnDestroy(): void {
    this._subscriptionState = false;
  }

  loadMore() {
    this.page++;
    this._getAllOrders()
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (orders) => {
          if (orders.length === 0) {
            NotificationsService.notify('Ingen flere ordren', 'error', 'top');
          } else {
            this.finishedOrderList = this.allFinishedOrderList.concat(orders);
            this.allFinishedOrderList = this.finishedOrderList;
          }
        },
        error: (err) => this._errorHandlerService.handleError(err),
      });
  }

  private _getAllOrders(): Observable<OrderList[]> {
    return this._orderService.getFinishedOrders(this.page);
  }

  private _initComponent() {
    this.currentUrl = this.parentUrl + '/order';
    this.previousUrl = this.parentUrl + '/indstillinger';
    this.finishedOrderList = this.allFinishedOrderList =
      this._activatedRoute.snapshot.data['finishedOrderList'];
    this._checkChildPage();
  }

  private _checkChildPage(): void {
    this.showParent = this._activatedRoute.children.length
      ? 'invisible'
      : 'visible';
  }
}
