import { Injectable } from '@angular/core';
import { ScriptInjectorService } from 'app/core/services/script-injector.service';
import { environment } from 'environments/environment';

declare const window: Window;
declare const _satellite: any;
@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(private _scriptInjectorService: ScriptInjectorService) {}
  initAdobeLaunchDataLayer() {
    (window as Window)[`digitalData`] = {
      pageInstanceID: 'Coop Test',
      page: {
        pageInfo: {
          pageID: '777',
          pageName: 'COOP',
        },
        attributes: {
          country: 'DA',
          language: 'da',
        },
      },
    };
  }
  async injectAdobeLaunchScript() {
    try {
      await this._scriptInjectorService.load(
        'launch',
        environment.adobeAnalyticsUrl
      );
      _satellite.pageBottom();
    } catch (e) {
      console.log('Error while loading Adobe Launch Script', e);
    }
  }
  setPageId(pageId: string) {
    (window as Window)[`digitalData`].page.pageInfo.pageID = pageId;
  }
  pushEvent(analyticsEvent: string) {
    (window as Window)[`digitalData`].events.push(analyticsEvent);
  }
}
