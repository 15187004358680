import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from 'app/core/services';
import {
  NewOrder,
  OrderList,
  PrepListData,
  SingleOrder,
  ThankYouSms,
} from 'app/features/order-and-pickup/order';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(private _apiService: ApiService) {}

  getOrderListing(
    page: number,
    status: number,
    category?: string
  ): Observable<OrderList[] | NewOrder[] | PrepListData> {
    let url = `orders?orderStatus=${status}`;
    if (category && category !== 'Alle') {
      url += `&category=${category}`;
    }
    url += `&page=${page}`;
    return this._apiService.get(url).pipe(map((res) => res['data']));
  }

  getSingleOrder(id: number): Observable<SingleOrder> {
    const url = `order?id=${id}`;
    return this._apiService.get(url);
  }
  getPrintHistory(page: number, category?: string): Observable<any> {
    let url = `print-history?page=${page}`;
    if (category) {
      url += `&category=${category}`;
    }
    return this._apiService.get(url);
  }

  saveOrderMessages(
    params,
    id: number,
    cancelOrder?: boolean
  ): Observable<object> {
    let url = `order?id=${id}`;
    if (cancelOrder) {
      url = `order/cancel?id=${id}`;
      return this._apiService.post(url, ApiService.CreateFormData(params));
    }
    return this._apiService.put(url, ApiService.CreateFormData(params));
  }

  changeDeliveryStatus(params, id: number) {
    const url = `order/finished?id=${id}`;
    return this._apiService.put(url, ApiService.CreateFormData(params));
  }

  getFinishedOrders(page: number): Observable<OrderList[]> {
    const url = `orders/finished?page=${page}`;
    return this._apiService.get(url).pipe(map((res) => res['data']));
  }

  saveThankYouMessage(params): Observable<object> {
    return this._apiService.post(
      `default/thankyousms`,
      ApiService.CreateFormData(params)
    );
  }

  getThankYouMessage(): Observable<ThankYouSms> {
    const url = `default/thankyousms`;
    return this._apiService.get(url).pipe(map((res) => res['data']));
  }

  getNewOrders(category: string, lastId?: number): Observable<NewOrder> {
    let url = `orders/newCount`;
    if (category !== 'Alle') {
      url += `?category=${category}`;
      if (lastId) {
        url += `&lastId=${lastId}`;
      }
    }
    if (category === 'Alle') {
      if (lastId) {
        url += `?lastId=${lastId}`;
      }
    }
    return this._apiService.get(url);
  }

  printOrders(startDate: string, endDate: string) {
    const url = `printOrder?startDate=${startDate}&endDate=${endDate}`;
    return this._apiService.get(url).pipe(map((res) => res['data']));
  }

  printOrder(orderId: number) {
    const url = `printOrder/${orderId}`;
    return this._apiService.get(url).pipe(map((res) => res['data']));
  }
}
