import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import {
  ErrorhandlerService,
  SearchService,
  TokenService,
} from 'app/core/services';
import { WebsiteList, WebsiteService } from 'app/features/website';

@Component({
  selector: 'coop-website-list',
  templateUrl: './website-list.component.html',
  styleUrls: ['./website-list.component.scss'],
})
export class WebsiteListComponent implements OnInit, OnDestroy {
  @ViewChild('searchBox') searchBox;
  showParent: string = 'visible';
  pageTitle: string = 'Website';
  activeTab: string = '1';

  websiteList: WebsiteList[];
  allWebsites: WebsiteList[];

  currentUrl: string;
  parentUrl: string;
  inputInSearch: boolean = false;
  private _subscriptionState = true;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _errorHandlerService: ErrorhandlerService,
    private _title: Title,
    private _websiteService: WebsiteService,
    private _searchService: SearchService
  ) {}

  ngOnInit() {
    this._initComp();
    this.setPreviousUrl();
  }

  ngOnDestroy() {
    this._subscriptionState = false;
  }

  setPreviousUrl() {
    const userType = TokenService.getUserType();
    if (userType === '1') {
      this.parentUrl = '/butikker/indstillinger';
    }
    if (userType === '2') {
      this.parentUrl = '/bestyrelse/indstillinger';
    }
  }

  search(value: string) {
    if (value !== '' && value.trim().length !== 0) {
      this.websiteList = this._searchService.searchByTitle(
        value,
        this.allWebsites,
        'title'
      );
      this.inputInSearch = true;
    }
  }

  closeSearch() {
    this.websiteList = this.allWebsites;
    this.searchBox.nativeElement.value = null;
    this.inputInSearch = false;
  }

  preparePreviewData(desc: string) {
    if (desc.length > 100) {
      return desc.substring(0, 100) + '...';
    }
    return desc;
  }

  private _initComp() {
    this._title.setTitle('Website - QuickCoop');
    this.websiteList = this.allWebsites =
      this._activatedRoute.snapshot.data['websiteList'];
    this._checkChildPage();
    this.currentUrl = this._router.url;
  }

  // Check if the route is child route or parent route
  // if child route set isParentPage as false
  private _checkChildPage(): void {
    this.showParent = this._activatedRoute.children.length
      ? 'invisible'
      : 'visible';
  }
}
