export const Jatak_Validation_Messages = {
  title: {
    required: 'Udfyld venligst feltet',
  },
  varaintTitle: {
    required: 'Udfyld venligst feltet',
    maxlength: 'Tekst må højst være 47 tegn'
  },
  images: {
    required: 'Upload venligst billede',
  },
  url: {
    required: 'Upload venligst video',
  },
  startDate: {
    required: 'Udfyld venligst feltet',
    dateMismatch: 'Sluttidspunkt skal være senere end Starttidspunkt',
    endmisMatch: 'Afslutningstidspunktet kan ikke ligge i fortiden.'
  },
  endDate: {
    required: 'Udfyld venligst feltet',
    dateMismatch: 'Sluttidspunkt skal være senere end Starttidspunkt',
    endmisMatch: 'Afslutningstidspunktet kan ikke ligge i fortiden.'
  },
  description: {
    required: 'Udfyld venligst feltet',
  },
  endedDescription: {
    required: 'Udfyld venligst feltet',
  },
  soldOutTitle: {
    max: 'maximun length excced',
    required: 'Udfyld venligst feltet',
    maxlength: 'Tekst må højst være 47 tegn'
  },
  replyText: {
    max: 'maximun length excced',
    required: 'Dette felt er obligatorisk, fordi robotten har brug for en unik tekst for at svare på hvert JA TAK-indlæg.'
  },
  offers: {
    required: 'Udfyld venligst feltet',
    min: 'Antallet af varer kan ikke være negativt',
  },
  replyTextMore: {
    required: 'Udfyld venligst feltet',
  },
  soldOutReply: {
    required: 'Udfyld venligst feltet',
    maxlength: 'Tekst må højst være 47 tegn'
  },
  phone: {
    required: 'Udfyld venligst feltet',
    invalid: 'Brug venligst et gyldigt telefonnummer.',
  },
  notification: {
    required: 'Udfyld venligst feltet',
    invalid: 'Brug venligst et gyldigt telefonnummer.',
  },
  price: {
    required: 'Udfyld venligst feltet',
    min: 'Prisen skal være over 0,00',
    invalid: 'Brug venligst det danske prisformat (f.eks. 6.789,45)',
  },
  quantity: {
    required: 'Feltet Prisbeskrivelse er påkrævet',
    min: 'Mængden skal være over 0,00',
    invalid: 'Brug venligst det danske format (f.eks. 6.789,45)',
    duplicate: "Dette beløb er allerede brugt til en anden pris."
  },
  priceDescription: {
    required: 'Feltet Prisbeskrivelse er påkrævet',
    min: 'Mængden skal være over 0,00',
    invalid: 'Brug venligst det danske format (f.eks. 6.789,45)',
    duplicate: "Dette beløb er allerede brugt til en anden pris."
  },
  keyword: {
    required: 'Udfyld venligst feltet',
    duplicate: 'Dette nøgleord er allerede brugt i denne JA TAK.',
    whitespace: 'Det unikke nøgleord skal være et enkelt ord. Mellemrum er ikke tilladt.'
  },
  endedReply: {
    required: 'Udfyld venligst feltet'
  },
  alertQuantity: {
    required: 'Udfyld venligst feltet'
  },
  emails: {
    required: 'Udfyld venligst feltet'
  }
};
