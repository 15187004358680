import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'coop-input-mask',
  templateUrl: './input-mask.component.html',
  styleUrls: ['./input-mask.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputMaskComponent),
      multi: true,
    },
  ],
})
export class InputMaskComponent
  implements ControlValueAccessor, OnInit, OnChanges
{
  @ViewChild('custom_input', { static: true }) customInput: ElementRef;
  @Input() maxOrder = false;
  disabled = false;
  value: string;
  CHAR_PIXEL_SIZE = 8;
  showStk = true;
  onChange: any = (event) => {
  };
  onTouched: any = (event) => {
  };

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    this.showStk = !changes['maxOrder'].currentValue;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
    this.value = value ? value : '';
    if (this.value.length !== 0) {
      // for create case
      this.setInputSize(this.value.length);
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  inputEvents(event) {
    this.value = event.target.value;
    const size = this.value.length;
    if (size > 0) {
      this.setInputSize(size);
    } else {
      this.setInputSize(8);
    }
    this.onChange(this.value);
  }

  setInputSize(size: number): void {
    this.customInput.nativeElement.setAttribute('size', size);
    this.customInput.nativeElement.style.width =
      size * this.CHAR_PIXEL_SIZE + 'px';
  }
}
