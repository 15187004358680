import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { catchError } from 'rxjs/operators';

import { Folders } from 'app/shared/interface';
import { ErrorhandlerService } from 'app/core/services';
import {
  Product,
  ProductListData,
  ProductTemplate,
  ShopHours,
  ProductService,
} from 'app/features/order-and-pickup/product';

@Injectable({
  providedIn: 'root',
})
export class ProductListResolverService  {
  constructor(
    private _productService: ProductService,
    private _errorHandlerService: ErrorhandlerService
  ) {}
  resolve(route: ActivatedRouteSnapshot) {
    return this._productService
      .getProductListing(1, 'Alle', true, 'Alle', '')
      .pipe(
        catchError((err) => this._errorHandlerService.routeResolverError(err))
      );
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProductResolverService  {
  constructor(
    private _productService: ProductService,
    private _errorHandlerService: ErrorhandlerService
  ) {}
  resolve(route: ActivatedRouteSnapshot) {
    const id = route.params['id'];
    return this._productService
      .getSingleProduct(id)
      .pipe(
        catchError((err) => this._errorHandlerService.routeResolverError(err))
      );
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProductDetailsResolverService  {
  constructor(
    private _productService: ProductService,
    private _errorHandlerService: ErrorhandlerService
  ) {}
  resolve(route: ActivatedRouteSnapshot) {
    const id = route.params['id'];
    return this._productService
      .getProductDetails(id)
      .pipe(
        catchError((err) => this._errorHandlerService.routeResolverError(err))
      );
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProductTemplateFolderResolverService
  
{
  constructor(
    private _productService: ProductService,
    private _errorHandlerService: ErrorhandlerService
  ) {}
  resolve(route: ActivatedRouteSnapshot) {
    return this._productService
      .getProductTemplatesFolder()
      .pipe(
        catchError((err) => this._errorHandlerService.routeResolverError(err))
      );
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProductTemplateListResolverService
  
{
  constructor(
    private _productService: ProductService,
    private _errorHandlerService: ErrorhandlerService
  ) {}
  resolve(route: ActivatedRouteSnapshot) {
    const folderId = route.params['folderId'];
    return this._productService
      .getProductTemplates(folderId, 1)
      .pipe(
        catchError((err) => this._errorHandlerService.routeResolverError(err))
      );
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProductTemplateResolverService  {
  constructor(
    private _productService: ProductService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    const id = route.params['templateId'];
    return this._productService
      .getProductSingleTemplate(id)
      .pipe(
        catchError((err) => this._errorHandlerService.routeResolverError(err))
      );
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProductTemplateSearchResolverService  {
  constructor(
    private _productService: ProductService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    const searchquery = route.params['searchparams'];
    let folderId = ''
    return this._productService.searchProductTemplate(searchquery,folderId)
      .pipe(
        catchError((err) => this._errorHandlerService.routeResolverError(err))
      );
  }
}

@Injectable({
  providedIn: 'root',
})
export class ShopHoursResolverService  {
  constructor(
    private _productService: ProductService,
    private _errorHandlerService: ErrorhandlerService
  ) {}
  resolve(route: ActivatedRouteSnapshot) {
    return this._productService
      .getShopHours()
      .pipe(
        catchError((err) => this._errorHandlerService.routeResolverError(err))
      );
  }
}
