import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { INITIAL_FORM_DATA, NyhedTilbud } from 'app/features/offer';

@Injectable({
  providedIn: 'root',
})
export class OfferStateService {
  formState = new BehaviorSubject<NyhedTilbud>(INITIAL_FORM_DATA);
  constructor() {}

  setFormState(state: NyhedTilbud) {
    this.formState.next(state);
  }
}
