import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService, TokenService } from 'app/core/services';
import {
  FacebookLoginUrl,
  FaceBookPage,
  QuickCoopFacebookData,
} from '../interface/facebook.interface';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FacebookService {
  constructor(private _apiService: ApiService) {}

  getLoginUrl(): Observable<string> {
    return this._apiService
      .getFacebookLoginUrl()
      .pipe(map((res: FacebookLoginUrl) => res.fb_login_url));
  }

  getPageList(token: string): Observable<FaceBookPage[]> {
    return this._apiService.getFacebookPageList(token);
  }

  getPagination(url: string): Observable<FaceBookPage[]> {
    return this._apiService.getFacebookPagesPaginationList(url);
  }

  extendToken(token: string): Observable<string> {
    return this._apiService
      .postFacebook(`token/extend?short_token=${token}`, {})
      .pipe(map((res) => res['access_token']));
  }

  updateFbData(FacebookPage: QuickCoopFacebookData): Observable<any> {
    return this._apiService.postFacebook(
      'update/detail',
      ApiService.CreateFormData(FacebookPage)
    );
  }
}
