<div class="sms-container">
  <div class="sms-title">SMS besked</div>
  <div class="sms-sub-title" >
    Emoji-tegn vil automatisk blive fjernet fra SMS'en,fordi de ikke understøttes.
  </div>
  <div *ngIf="promo">
    <div class="form-group">
      <label
      >{{ sms.quantity }} SMS<span *ngIf="sms.quantity > 1"><b>er</b></span>
         <span>({{ msgLength }} karakterer)</span></label
      >
      <div class="message-edit-area" [class.error]="showErrorInput">
        <textarea
          [(ngModel)]="smsMessage"
          #messagebox
          (ngModelChange)="processSmsForPromo(messagebox.value)"
          *ngIf="!tempSms"
          [readOnly]="readOnly"
        ></textarea>
        <textarea
          [(ngModel)]="smsMessage"
          #messagebox
          (ngModelChange)="processSms(messagebox.value)"
          *ngIf="tempSms"
          [readOnly]="readOnly"
          [class.error]="showErrorInput"
        ></textarea>
        <div class="sms-date link" *ngIf="!newsMode && !goodPriceMode ">
          Gælder fra {{ promoData.startDate }} til {{ promoData.endDate }}
        </div>
        <div style="margin-bottom: 10px"
             class="static-message">{{ promoMessage }}</div>
        <div class="link">#LINK#</div>
<!--        <div style="margin-bottom: 10px"-->
<!--             class="static-message">{{ jatakSMSInfo }}</div>-->
        <span class="static-message">{{ staticMessage }}</span>
      </div>
      <p>
        Prisen for <strong>{{ sms.quantity }}</strong> SMS<span
        *ngIf="sms.quantity > 1"
      >er</span
      >
        til {{ client.members }} tilmeldte modtagere:
        <strong>{{ sms.price }} kr.</strong>
      </p>
    </div>
  </div>
  <div *ngIf="jatak">
    <div class="form-group">
      <label
      >{{ sms.quantity }} SMS<span *ngIf=" sms && sms.quantity > 1"><b>er</b></span>
        <span>({{ msgLength }} karakterer)</span></label
      >
      <div class="message-edit-area" [class.error]="showErrorInput">
        <textarea
          [(ngModel)]="smsMessage"
          #messagebox
          (ngModelChange)="processSms(messagebox.value)"
          [class.error]="showErrorInput"
        ></textarea>
        <div style="margin-bottom: 10px"
             class="static-message">{{ jatakMessage }}</div>
        <div class="link" *ngIf="!tempSms">#LINK#</div>
<!--        <div style="margin-bottom: 10px"-->
<!--             class="static-message">{{ jatakSMSInfo }}-->
<!--        </div>-->
        <span class="static-message">{{ staticMessage }}</span>
      </div>
      <p>
        Prisen for <strong>{{ sms.quantity }}</strong> SMS<span
        *ngIf="sms.quantity > 1"
      >er</span
      >
        til {{ client.members }} tilmeldte modtagere:
        <strong>{{ sms.price }} kr.</strong>
      </p>
    </div>
  </div>
</div>
