export const ClubMessages = {
  title: {
    required: 'Navn på afsender er påkrævet',
    maxlength: 'Navn på afsender er mere end 50 karakterer',
  },
  message: {
    required: 'Besked er påkrævet',
    maxlength: 'Besked er mere end 50 karakterer',
  },
};
export const SMS_BESKED_VALIDATION_MESSAGES = {
  message: {
    emoji: 'Feltet kan ikke indeholde emoji-tegn',
  },
};
export const MOBILE_MEDLEM_VALIDATION_MESSAGES = {
  number: {
    required: 'Indtast venligst et gyldigt 8-cifret nummer',
    invalid: 'Indtast venligst et gyldigt 8-cifret nummer',
  },
  numberSearch: {
    required: 'Indtast venligst et gyldigt 8-cifret nummer',
  },
  message: {
    required: 'Udfyld venligst feltet',
    emoji: 'Feltet kan ikke indeholde emoji-tegn',
    maxlength: 'Tekst må højst være 100 tegn'
  },
  emoji: {
    invalid: 'Feltet kan ikke indeholde emoji-tegn',
  },
};
export const SettingsMessages = {
  form: {
    samenumber: 'Mobilnumre kan ikke være ens',
  },
  SMS1: {
    required: 'Navn på afsender er påkrævet',
    maxlength: 'Det indtastede mobilnummer er ikke validt (8 cifre)',
    minlength: 'Det indtastede mobilnummer er ikke validt (8 cifre)',
    notvalid:
      'Det indtastede mobilnummer er ikke validt (8 cifre eller 10 cifre)',
    samenumber: 'Mobilnumre kan ikke være ens',
  },
  SMS2: {
    required: 'Navn på afsender er påkrævet',
    maxlength: 'Det indtastede mobilnummer er ikke validt (8 cifre)',
    minlength: 'Det indtastede mobilnummer er ikke validt (8 cifre)',
    notvalid:
      'Det indtastede mobilnummer er ikke validt (8 cifre eller 10 cifre)',
    samenumber: 'Mobilnumre kan ikke være ens',
  },
  SMS3: {
    required: 'Navn på afsender er påkrævet',
    maxlength: 'Det indtastede mobilnummer er ikke validt (8 cifre)',
    minlength: 'Det indtastede mobilnummer er ikke validt (8 cifre)',
    notvalid:
      'Det indtastede mobilnummer er ikke validt (8 cifre eller 10 cifre)',
    samenumber: 'Mobilnumre kan ikke være ens',
  },
  message: {
    maxlength: 'Besked er mere end 50 karakterer',
  },
};
