<div class="quicktogo-calendar">
  <div>
    <div class="calendar-tabs">
      <div [class.active]="activeTab === 'active'" (click)="onTabSelect('active')">
        Aktive
      </div>

      <div [class.active]="activeTab === 'inactive'" (click)="onTabSelect('inactive')">
        Inaktive
      </div>
    </div>

    <div *ngIf="!isMobileView()" class="day-selector">

      <div *ngFor="let day of weekDays; let lastItem = last" class=" box" [ngClass]="{'last-item': lastItem}">

        <ng-container *ngIf="isToday(day)">
          <strong>I Dag</strong>
        </ng-container>
        <ng-container *ngIf="!isToday(day)">
          {{ day.format('ddd, DD') }}
        </ng-container>

      </div>

      <div class="arrow-left" (click)="previousWeek()">
        <i class="icon icon-arrow-left"></i>
      </div>

      <div class="arrow-right" (click)="nextWeek()">
        <i class="icon icon-arrow-right"></i>
      </div>
    </div>

    <div *ngIf="isMobileView()" class="day-selector">
      <div *ngFor="let day of mobileDays;let lastItem = last" class=" box" [ngClass]="{'last-item': lastItem}">
        <ng-container *ngIf="isToday(day)">
          <strong>I Dag</strong>
        </ng-container>
        <ng-container *ngIf="!isToday(day)">
          {{ day.format('ddd, DD') }}
        </ng-container>
      </div>
      <div class="arrow-left" (click)="previousWeek()">
        <i class="icon icon-arrow-left"></i>
      </div>

      <div class="arrow-right" (click)="nextWeek()">
        <i class="icon icon-arrow-right"></i>
      </div>
    </div>


    <div *ngFor="let data of organizedData" class="item-list">
      <div class="title">
        {{ data.category }}
      </div>
      <div class="sub-title">
        <div>
          {{ data.title.length > 20 ? data.title.substring(0, 20) + '...' : data.title }}
        </div>
        <div (click)="onEdit(data.id)" style="margin-left: 10px; cursor: pointer">
          <img src="assets/quicktogo-icon/edit.svg" />
        </div>
      </div>
      <div class="datetime">
        <div class="release-time">
          <div style="margin-right: 10px">
            <img src="assets/quicktogo-icon/eye.svg" />
          </div>
          <div>
            Udgivet: kl. {{ data.publishAt }}
          </div>
        </div>

        <div class="pickup-time">
          <div style="margin-right: 10px">
            <img src="assets/quicktogo-icon/bag-tick.svg" />
          </div>
          <div>
            Afhentning: kl. {{ data.pickupStart }} - kl. {{ data.pickupEnd }}
          </div>
        </div>

      </div>

      <div class="item-info " [class.disabled]="activeTab === 'inactive'">
        <div *ngFor="let item  of data.bagsByWeek; let lastItem = last" class="info-box border"
             [ngClass]="{'last-item': lastItem}">
          <ng-container *ngIf=" item && !item.editable">
            <div *ngIf="item" class="sold">
              {{ item?.sold }} <span *ngIf="!item.unlimitedStock">/ {{ item?.stocks }} </span>
              <div>
                solgte
              </div>
            </div>
            <div *ngIf="item" class="pickedup">
              {{ item?.pickedUp }} / {{ item?.sold }}
              <div (click)="onNavigatetoOrderList(item.id)">
                Afhentet
              </div>
            </div>
          </ng-container>


          <ng-container *ngIf=" item && item.editable">
            <div #input class="limit">
              <select [(ngModel)]="item.stocks" (ngModelChange)="onStockChange($event, item, data.id)"
                      mbsc-select
                      [mbscOptions]="alertQuantitySelectSettings"
                      class="form-select">

              </select>
              <div class="dropdown-icon"><i class="icon-arrow-down"></i></div>
            </div>
            <div class="ready">
              <div>
                er klar
              </div>
              <div>
                <label class="switch">
                  <input (click)="onChange($event, item, data.id)" [checked]="item.publish" type="checkbox">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
