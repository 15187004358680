import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { TemplateListData } from 'app/shared/interface';
import { OkScreen, OkScreenService } from 'app/features/ok-screen';
// *** It resolves particular okScreen template with given id ***
// @routeParam [templateId: string] => okScreen_template_id
// @returns [okScreen: Observable<OkScreen>]=> OkScreen details
@Injectable({
  providedIn: 'root',
})
export class OkScreenSingleTemplateResolver  {
  constructor(private _okScreenService: OkScreenService) {}
  resolve(route: ActivatedRouteSnapshot): Observable<OkScreen> {
    const id = route['params']['templateId'];
    return this._okScreenService.getSingleOkScreenTemplate(id);
  }
}
// *** It resolves all the created okScreen templates ***
// @returns [okScreen: OkScreenTemplateList] => list of templates
@Injectable({
  providedIn: 'root',
})
export class OkScreenTemplateListResolver  {
  constructor(private _okScreenService: OkScreenService) {}
  resolve(route: ActivatedRouteSnapshot): Observable<TemplateListData> {
    return this._okScreenService.getOkScreenTemplates();
  }
}
// *** resolves a single Ok-Screen data ***
// @routeParam [id: number] => OkScreen_id
// @returns [okScreen: OkScreen] => OkScreen details
@Injectable({
  providedIn: 'root',
})
export class OkScreenSingleResolver  {
  constructor(private _okScreenService: OkScreenService) {}
  resolve(route: ActivatedRouteSnapshot): Observable<OkScreen> {
    const id = route['params']['id'];
    return this._okScreenService.getSingleOkScreen(id);
  }
}
