<section class="app-page page-with-fixed-header app-sms-klub" *ngIf="client">
  <div class="fixed-header">
    <coop-pageheader
      [isChild]="isChild"
      [isBackGroundWhite]="true"
      [pageTitle]="pageTitle"
      [routeTo]="parentUrl"
    ></coop-pageheader>
  </div>
  <div class="page-container">
    <div style="margin-top:34px">
      <div class="sms-club">
        <div>
          <ion-icon name="people" style="padding-top:2px"></ion-icon>
        </div>
        <div style="margin-left: 12px" class="bold">
          Jeres klub har {{ client.members }} medlemmer.
        </div>
      </div>

      <div class="sms-description">
        <div style="margin-bottom: 16px">
          <div class="bold" style="margin-bottom: 5px">For at tilmelde sig SMS klubben</div>
          <div>Kunden skal sende en SMS med <strong>{{ formatText(client.name, 1) }} {{ userDetail.kardex }}</strong>
            til <strong>{{ client.shortcode}}</strong>.
          </div>
        </div>

        <div >
          <div class="bold" style="margin-bottom: 5px">For at afmelde sig SMS klubben</div>
          <div>Kunden skal sende en SMS med <strong>{{ formatText(client.name, 2) }}</strong> til
            <strong>{{ client.shortcode}}</strong>.
          </div>
        </div>
      </div>

      <div class="wrapper" *ngIf="mobileTable">
        <div class="mobile-container">
          <table class="member-table">
            <tr>
              <th>Telefonnr</th>
              <th>Slet</th>
            </tr>
            <tr *ngFor="let temp of tempValHolder; let i = index">
              <td>{{ temp.phone }}</td>
              <td>
                <i
                  style="cursor: pointer"
                  class="icon icon-close-circle"
                  (click)="onMemberMobileDelete(temp.id, i)"
                ></i>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div class="hr"></div>


      <div class="form-container">
        <form [formGroup]="isSearchMode ? mobileSearchForm : mobileAddForm">
          <div class="row form-group phone-field" controlErrorContainer>
            <label for="message"
            >Find eller tilføj medlem
            </label>
            <input
              [type]="'text'"
              [(ngModel)]="number"
              placeholder="Indsæt mobilnummer"
              id="numberSearch"
              (input)="onInputChange($event)"
              [formControlName]="isSearchMode ? 'numberSearch' : 'number'"
              [customErrors]="isSearchMode ? validationMessages['numberSearch'] : validationMessages['number']"/>
            <span class="phone-prefix">+45</span>
          </div>
          <div
            class="error-message"
            *ngIf="mobileAddForm?.get('number').invalid &&(mobileAddForm?.get('number').dirty || mobileAddForm?.get('number').touched || formSubmitted)">
            <div *ngIf="mobileAddForm.get('number').errors['required']">
              <span>Udfyld venligst feltet</span>
            </div>
            <div *ngIf="mobileAddForm.get('number').errors['invalid']">
              <span>Indtast venligst et gyldigt 8-cifret nummer</span>
            </div>
          </div>
          <div class="flex-display">
            <div (click)="onSearchNumber()" class="search-btn">
              <div>
                <ion-icon name="search" class="font-18"></ion-icon>
              </div>
              <div style="margin-left: 10px">
                Søg medlemmer
              </div>
            </div>

            <div (click)="onAddNumber()" class="add-btn">
              <div>
                <ion-icon name="person-add" ></ion-icon>
              </div>
              <div style="margin-left: 10px">
                Tilføj
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="hr"></div>

      <div class="form-container">
        <form [formGroup]="smsForm" (submit)="send()">

          <div class="personal-greeting">
              <div class="flex-display" >
                <div class="greeting-title">
                  Personlig hilsen
                </div>
                <div>
                  {{ charCount }} / 100 tegn
                </div>
              </div>

            <div class="greeting-subtitle" >
              Følgende hilsen tilføjes i slutningen af hver SMS - besked
            </div>

          </div>

          <div class="form-group" controlErrorContainer>
            <div class="message-edit-area">
              <textarea
                id="message"
                formControlName="message"
                placeholder="Skriv din butikssignatur her."
                #msgtext
                (keyup)="smsConvert($event)"
                [customErrors]="validationMessages['message']"
              ></textarea>
              <span class="static-message">{{ staticMessage }}</span>
            </div>
            <div *ngIf="isMaxlength" class="error-message">
              <span>Tekst må højst være 100 tegn</span>
            </div>
          </div>

          <div
            class="row form-submit-field"
            style="padding-bottom: 50px" >
            <input type="submit" value="Gem" class="login-btn btn-white-login"/>
          </div>
        </form>
      </div>

    </div>
  </div>

  <div class="add-new-sms" >
    <div>
      <ion-icon name="add-outline" style="padding-top: 3px"></ion-icon>
    </div>
    <div style="margin-left: 5px" >
      Send SMS
    </div>
  </div>


</section>
