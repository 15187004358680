import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from 'app/core/services';
import { TemplateListData } from 'app/shared/interface';
import { OkScreen, OkScreenCalendar } from 'app/features/ok-screen';

@Injectable({
  providedIn: 'root',
})
export class OkScreenService {
  constructor(private _apiService: ApiService) {}
// *** It gets all the created okScreen templates ***
  // @optionalParam [searchQuery?:string] => searchText in title
  // @returns [okScreen: OkScreenTemplateList] => list of templates
  getOkScreenTemplates(searchQuery?: string): Observable<TemplateListData> {
    let url = 'ok-screen/templates';
    url = searchQuery ? `${url}?search=${searchQuery}` : url;
    return this._apiService.get(url);
  }
// *** It gets the particular okScreen template with given id ***
  // @param [id: string] => okScreen_template_id
  // @returns [okScreen: Observable<OkScreen>]=> OkScreen details
  getSingleOkScreenTemplate(templateId: string): Observable<OkScreen> {
    const url = `ok-screen/templates/${templateId}`;
    return this._apiService.get(url).pipe(map((res) => res['data']));
  }
// *** gets a single Ok-Screen data ***
  // @param [id: number] => OkScreen_id
  // @returns [okScreen: OkScreen] => OkScreen details
  getSingleOkScreen(id: string): Observable<OkScreen> {
    const url = `ok-screen/${id}`;
    return this._apiService.get(url).pipe(map((res) => res[`data`]));
  }
  // *** Gets the okScreens within a date range ***
  // @params [startDate: string]
  // @params [endDate: string]
  // @returns [Observable<OkScreenCalendar[]>] => Basic okScreen values of all OkScreen items
  getOkScreens(startDate?: any, endDate?: any): Observable<any> {
    let url = 'ok-screen';
    if (startDate) {
      url = url + `?startDate=${startDate}&endDate=${endDate}`;
    }
    return this._apiService.get(url).pipe(map((res) => res['data']));
    // return this._apiService.get('ok-screen/get-calendar.php');
  }
// *** It deletes okScreen of particular id ***
  // @param [id: string] => ok-screen id
  deleteOkScreen(id: string): Observable<any> {
    const url = `ok-screen/${id}`;
    return this._apiService.del(url);
  }
// *** It saves or updates the ok-screen form ***
  // @param [templateMode: boolean] => true: save the form
  //                                    false: update the form
  // @param [params: any] => new or updated FormData
  saveOkScreen(templateMode:boolean, params: any): Observable<any> {
    if (templateMode) {
      const url = 'ok-screen';
      return this._apiService.post(url, ApiService.CreateFormData(params));
    } else {
      const url = `ok-screen/${params.id}`;
      return this._apiService.put(url, ApiService.CreateFormData(params));
    }
  }
}
