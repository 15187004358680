export const Offer_Validation_Messages = {
  title: {
    required: 'Udfyld venligst feltet',
    maxlength: 'Overskrift må højst være 99 tegn',
    emoji: "Emoji understøttes ikke i SMS."
  },
  longDescription: {
    required: 'Udfyld venligst feltet',
  },
  shortDescription: {
    required: 'Udfyld venligst feltet',
    maxlength: 'Tekst må højst være 250 tegn',
  },
  largeImage: {
    required: 'Upload venligst billede',
  },
  url: {
    required: 'Upload venligst video',
  },
  thumbnail: {
    required: 'Upload venligst billede',
  },
  price: {
    required: 'Udfyld venligst feltet',
    min: 'Prisen skal være over 0,00',
    offerPriceInvalid: ' ', // truthy value to show error border with empty message
    invalid: 'Brug venligst det danske prisformat (f.eks. 6.789,45)',
  },
  offerPrice: {
    required: 'Udfyld venligst feltet',
    min: 'Prisen skal være over 0,00',
    offerPriceInvalid: 'Tilbudsprisen skal være mindre end før-prisen.',
    invalid: 'Brug venligst det danske prisformat (f.eks. 6.789,45)',
  },
  priceDescription: {
    required: 'Udfyld venligst feltet',
    min: 'Mængden skal være over 0,00',
    maxlength: 'Du nåede max. antal tegn: 50',
    invalid: 'Brug venligst det danske format (f.eks. 6.789,45)',
  },
  publishDate: {
    required: 'Udfyld venligst feltet',
    dateMismatchPubExp:
      'Afslutningstidspunkt skal være senere end Udsendingstidspunkt',
  },
  startDate: {
    dateMismatchStartEnd:
      'Afslutningstidspunkt skal være senere end Udsendingstidspunkt',
    dateMismatchStartEndWeek:
      'Sluttid skal være senere end en uge efter udsendelsestid',
    dateMismatchStartEndTime: 'Sluttid skal være efter starttid',
  },
  endDate: {
    dateMismatchStartEnd:
      'Afslutningstidspunkt skal være senere end Udsendingstidspunkt',
    dateMismatchExpireEnd: 'Udløbsdato kan ikke komme efter slutdatoen',
    dateMismatchStartEndWeek:
      'Sluttid skal være senere end en uge efter udsendelsestid',
    dateMismatchStartEndTime: 'Sluttid skal være efter starttid',
  },
  expirationDate: {
    dateMismatchExpireEnd: 'Udløbsdato kan ikke komme efter slutdatoen',
    dateMismatchPubExp:
      'Afslutningstidspunkt skal være senere end Udsendingstidspunkt',
  },
  sendDate: {
    smsDateNotInRange: 'Sms-sendedato skal være mellem de gyldige datoer',
  },
  priceMin: {
    min: 'Prisen skal være over 0,00',
  },
  smsDateNotInRange: {
    invalid: 'Sms-sendedato skal være mellem de gyldige datoer',
  },
  dateMismatchStartEndWeek: {
    mismatch: 'Sluttid skal være senere end en uge efter udsendelsestid',
  },
  dateMismatchStartEndTime: {
    mismatch: 'Sluttid skal være efter starttid',
  },
  weekly_invalid: {
    mismatch: 'true',
  },
  weight: {
    invalid: 'Maks. 8 karakterer. Benyt i stedet teksfeltet',
  },
  dutyText: {
    required: 'Udfyld venligst feltet',
    maxlength: 'Du nåede max. antal tegn: 50',
  },
  offerPriceInvalid: {
    invalid: 'Tilbudsprisen skal være mindre end før-prisen.',
  },
  mailId:{
    required: 'Vælg venligst en mailkanal',
  }
};
