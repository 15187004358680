import { inject, Injectable } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { Observable } from 'rxjs';

import { NotificationsService } from 'app/core/services';
import { JatakLiveCreateEditComponent } from 'app/features/jatak-live';

// ***  It runs before navigating away from JatakLiveCreateEditComponent  ***
// Checks whether the formData has been altered and pressed back instead of saving
// @returns boolean =>  true:    Route is deactivated
//                      false:   Current route is not changed
@Injectable({
  providedIn: 'root'
})
export class JatakLiveDeactivateGuardService {
  canDeactivate(
    component: JatakLiveCreateEditComponent
  ): Observable<boolean> | boolean {
    const dirty = component.jatakLiveForm.dirty;
    const formSaved = component.formSaved;
    if (dirty && !formSaved) {
      return NotificationsService.confirm(
        `Dine ændringer vil ikke blive gemt.`
      );
    }
    return true;
  }
}

export const JatakLiveDeactivateGuard: CanDeactivateFn<JatakLiveCreateEditComponent> = (component) => {
  return inject(JatakLiveDeactivateGuardService).canDeactivate(component);
};
