import { inject, Injectable } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { TokenService } from '../services';
// ***  Only user with orderAndPickup scope set to true is allowed from this routeGuard ***
//if orderAndPickup is false  Route is '/' and return false
// else return true
@Injectable({
  providedIn: 'root'
})
export class OrderPickupGuardService {
  constructor(private _router: Router) {
  }

  canLoad() {
    if (!TokenService.getUserDetail().scopes.orderAndPickup) {
      this._router.navigate(['/']).then();
      return false;
    }
    return true;
  }

}

export const OrderPickupGuard: CanActivateFn = () => {
  return inject(OrderPickupGuardService).canLoad();
};

// ***  Only user with okScreen scope set to true is allowed from this routeGuard ***
//if okScreen is false =>   Route is '/' and return false
// else return true:    Route is success
@Injectable({
  providedIn: 'root'
})
export class OkScreenGuardService {
  constructor(private _router: Router) {
  }

  canLoad() {
    if (!TokenService.getUserDetail().scopes.okScreen) {
      this._router.navigate(['/']).then();
      return false;
    }
    return true;
  }
}

export const OkScreenGuard: CanActivateFn = () => {
  return inject(OkScreenGuardService).canLoad();
};

@Injectable({
  providedIn: 'root'
})
export class RedmadScreenGuardService {
  constructor(private _router: Router) {
  }

  canLoad() {
    if (!TokenService.getUserDetail().scopes.quick2Go) {
      this._router.navigate(['/']).then();
      return false;
    }
    return true;
  }
}

export const RedmadScreenGuard: CanActivateFn = () => {
  return inject(RedmadScreenGuardService).canLoad();
};


