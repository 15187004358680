import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { NyhedTilbud, GoodPriceFormComponent } from 'app/features/offer';

@Component({
  selector: 'app-good-price-edit',
  templateUrl: './good-price-edit.component.html',
  styleUrls: ['./good-price-edit.component.scss'],
})
export class GoodPriceEditComponent implements OnInit {
  @ViewChild(GoodPriceFormComponent, { static: true })
  offerFormComp: GoodPriceFormComponent;
  formData?: NyhedTilbud;
  constructor(private _activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.formData = this._activatedRoute.snapshot.data['offer'];
  }
}
