
<div *ngFor="let customer of customerList; let i = index" class="customer">
  <div (click)="onCustomerSelect(customer.userId)" class="customer-info">
    <div class="customer-name">
      <span>{{ i + 1 }}.</span>
      <div>{{customer.name}}</div>
    </div>

    <div style="display: flex;justify-content: center;align-items: center">
      <div *ngIf="customer?.approvalRemaining" class="status">
        <ion-icon
          [class.to_approval]="customer?.approvalRemaining"
          name="alert-circle-outline"></ion-icon>
      </div>

      <div *ngIf="customer?.collected" class="status">
        <ion-icon
          [class.collected]="customer?.collected"
          name="checkmark-circle"></ion-icon>
      </div>

      <div *ngIf="customer?.cancelled" class="status">
        <ion-icon
          [class.rejected]="customer?.cancelled"
          name="close-circle"></ion-icon>
      </div>

      <div class="dropdown">
        <ion-icon *ngIf="customerId !== customer.userId" name="chevron-down-outline"></ion-icon>
        <ion-icon *ngIf="customerId === customer.userId" name="chevron-up-outline"></ion-icon>
      </div>
    </div>
  </div>

  <div *ngIf="customerId === customer.userId" class="seperator">
  </div>

  <ng-container *ngIf="customerId === customer.userId">
    <div *ngFor="let order of customer.orders" class="order-list">

      <div class="order-info">
        <div class="orderid">
          <p
            [class.toapprove]="order.toApprove"
            [class.rejected]="order.cancelled"
            [class.approved]=" !order.cancelled &&  order.approved"
          >#{{order.orderId}}</p>

<!--          <div *ngIf="order.toApprove" style="margin-left: 15px">-->
<!--            <p class="approval-required">Approval required</p>-->
<!--          </div>-->

          <div style="margin-left: 10px">
            <p
              [class.toapprove]="order.toApprove"
              [class.rejected]="order.cancelled"
              [class.approved]=" !order.cancelled &&  order.approved"
              *ngIf="order.variantName">{{ order.variantName }}</p>
            <p *ngIf="order.toApprove" class="required-text">Godkendelse nødvendig <br/> for valgt mængde</p>
            <p *ngIf="order.showReplyError" class="reply-error"> {{ order.replyErrorText }} </p>
            <p class="success-reply" *ngIf="successReplyMessage[order.id]">{{ successReplyMessage[order.id] }}</p>
          </div>
        </div>




        <div>
          <a href="{{order.commentLink}}" target="_blank">
            <ion-icon name="chatbox-ellipses-outline"></ion-icon>
          </a>
        </div>
      </div>

      <!--    order quantity -->
      <div class="quantity list">
        <div class="order-info-title">
          Quantity
        </div>


        <div *ngIf="order.toApprove" class="quantity-wrapper">
          <div class="approve-option-icon">

            <!--            approve order-->

            <ion-icon (click)="approve(order.id,order.quantity)" name="checkmark-circle-outline"
            >Approved
            </ion-icon>


            <!--            reject order-->
            <ion-icon (click)="reject(order.id)" name="close-circle-outline"

            >Reject
            </ion-icon>
            <div class="border-line"></div>
          </div>
          <div class="limit">
            <mbsc-select display="center" [data]="numbers" [(ngModel)]="order.quantity">
            </mbsc-select>
          </div>
        </div>


        <div *ngIf="!order.toApprove" class="amount">
          {{ order.quantity }}
        </div>

      </div>

      <!--    order status-->

      <div class="order-status list">
        <div class="order-info-title">
          Ordre status
        </div>
        <div class="status">
          <ng-container *ngIf="order.toApprove ; else rejected ">
            <span>Afventer</span>
          </ng-container>
          <ng-template #rejected>
            <ng-container *ngIf="order.cancelled ;else approved">
              <span>Afvist</span>
            </ng-container>
          </ng-template>
          <ng-template #approved>
            <span>Godkendt</span>
          </ng-template>
        </div>

      </div>


      <!--    order time-->

      <div class="order-time list">
        <div class="order-info-title">
          Bestilt den
        </div>
        <div class="order-date">
          <span>{{ order.orderedAt | dateformat : false }}</span>
        </div>

      </div>
    </div>


    <div class="action-buttons">
      <ng-container *ngIf="customer.isActionable">

        <div
          style="cursor: pointer"
          (click)="onCancelOrder(customer.userId)"
          class="delete-btn"
        >
          <ion-icon name="close-outline"></ion-icon>
          <div>Slet</div>
        </div>
        <div
          style="cursor: pointer"
          class="pickedup-btn"
          (click)="onCollectOrder(customer)"
        >
          <ion-icon name="checkmark-outline"></ion-icon>
          <div>Afhentet</div>
        </div>
      </ng-container>

      <ng-container *ngIf="customer?.collected">
        <div [class.picked]="customer?.collected" class="actionstatus ">
          <ion-icon name="checkmark-circle"></ion-icon>
          <p>
            Ordren afhentet
          </p>
        </div>
      </ng-container>

      <ng-container *ngIf="customer?.cancelled">
        <div [class.deleted]="customer?.cancelled" class="actionstatus ">
          <ion-icon name="close-circle"></ion-icon>
          <p>
            Ordren slettet
          </p>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>


