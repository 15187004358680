<section
  class="app-page list-component page-with-fixed-header template-list"
  [@parentAnimate]="showParent"
>
  <div class="fixed-header">
    <coop-pageheader
      [pageTitle]="pageTitle"
      [isBackGroundWhite]="true"
      [isChild]="true"
      [routeTo]="previousUrl"
    ></coop-pageheader>
  </div>
  <div class="channel-selection-overlay-container" *ngIf="showChannelSelection">
    <div class="channel-selection-box fixed-header">
      <coop-pageheader
        [pageTitle]="'Skabelon - Vælg kanaler'"
        [isChild]="true"
        [routeTo]="''"
        (closeComponent)="closeChannelSelection()"
      ></coop-pageheader>
      <div class="fixed-header-content">
        <coop-channel-select
          [selectedChannel]="sharedChannels"
          [allowedChannels]="sharedChannels"
          [requiredChannel]="requiredChannel"
          [mode]="{ create: false, edit: false, template: true }"
          (channelConfirmed)="channelConfirmed($event)"
        ></coop-channel-select>
      </div>
    </div>
  </div>
  <div class="page-container">
    <div>
      <div class="searchsection">
        <div class="searchinput">
          <input type="search"
                 placeholder="Søg efter skabelon eller mappe"
                 #searchBox
                 (keyup.enter)="search(searchBox.value)"
                 class="search-input"
          />
          <!--          <div *ngIf="searchString" (click)="closeSearch()" class="close-search">-->
          <!--            <ion-icon name="close-circle-outline"></ion-icon>-->
          <!--          </div>-->
        </div>
        <div (click)="search(searchBox.value)" class="searchicon">
          <ion-icon name="search-outline"></ion-icon>
        </div>
      </div>
    </div>

    <div class="folder-container">
      <ng-container *ngIf="!templates && offerType !== 'produkt'">
        <div
          [routerLink]="'egne'"
          *ngIf="offerType !== 'ja-tak-live'"
          class="folder-content">
          <ion-icon name="folder" class="folder-icon"></ion-icon>
          <div class="content-title">Egne skabeloner</div>
          <div class="content-subtitle">Dine personlige skabeloner</div>
        </div>

        <div
          [routerLink]="'store'"
          *ngIf="templateFolderList.store[0]"
          class="folder-content">
          <ion-icon name="folder" class="folder-icon"></ion-icon>
          <div class="content-title">For din butik</div>
          <div class="content-subtitle">Skabeloner fra hovedkontoret</div>
        </div>
      </ng-container>


      <div *ngFor="let item of itemList" class="folder-content" (click)="templateSelected(item.id)">
        <div *ngIf="itemList.length !== 0">
          <div>
            <img
              [src]=" item.image"
              class="result-img"
            />
            <div class="content-title">{{item.title}}</div>
            <div class="content-subtitle">{{item.desc}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="item-list">
      <ng-container *ngIf="!templates && offerType === 'produkt'">
        <h5 class="empty-message" *ngIf="templateFolder.length === 0">
          Beklager, ingen skabeloner tilgængelige
        </h5>
      </ng-container>
      <ng-container *ngIf="templates">
        <h5 class="empty-message" *ngIf="templates.length === 0">
          Beklager, ingen skabeloner tilgængelige
        </h5>
      </ng-container>
      <ng-container
        *ngIf="templateFolder.length === 0 && offerType === 'ja-tak-live'"
      >
        <h5 class="empty-message">Beklager, ingen skabeloner tilgængelige</h5>
      </ng-container>
    </div>
  </div>
</section>
<router-outlet></router-outlet>
