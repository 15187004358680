import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, NgZone } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScriptInjectorService {
  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _zone: NgZone
  ) {}
  load(id: string, src: string) {
    const scriptElemet: HTMLScriptElement =
      this._document.createElement('script');
    scriptElemet.id = id;
    scriptElemet.src = src;
    scriptElemet.async = true;
    const promise = new Promise<void>((resolve, reject) => {
      scriptElemet.addEventListener('load', () => {
        setTimeout(resolve, 10);
      });
      scriptElemet.addEventListener('error', (err) => {
        reject(err);
      });
    });
    this._zone.runOutsideAngular(() => {
      this._document.head.appendChild(scriptElemet);
    });
    return promise;
  }
}
