<div class="app-page product-details page-with-fixed-header">
  <div class="fixed-header">
    <coop-pageheader
      [pageTitle]="pageTitle"
      [isChild]="true"
      [routeTo]="previousUrl"
    ></coop-pageheader>
  </div>
  <div class="page-container">
    <div class="wrapper">
      <div class="product-image" [class.processing]="!imgLoaded">
        <img
          src="{{ product.largeImage }}"
          alt="product-image"
          (load)="imageLoaded()"
        />
        <div class="active-status">
          <button class="btn btn-green" *ngIf="product.productActive">
            Aktiv
          </button>
          <button class="login-btn btn-black-login" *ngIf="!product.productActive">
            Inaktiv
          </button>
        </div>
      </div>
      <h3>Produktoplysninger</h3>
      <div class="form-group">
        <label>Kategori</label>
        <p>{{ product.category }}</p>
      </div>
      <div class="form-group">
        <label>Overksrift</label>
        <p>{{ product.title }}</p>
      </div>
      <div class="form-group">
        <label>Tekst</label>
        <p class="product-desc">{{ product.description }}</p>
      </div>
      <div class="form-group">
        <label>Pris per vare/måltid</label>
        <p>{{ product.price }}</p>
      </div>
      <div class="form-group">
        <label>Afhentningsperiode</label>
        <p *ngIf="product.pickUpPeriod">
          {{ product.pickUp.StartDate | dateformatddmmyy }} til
          {{ product.pickUp.EndDate | dateformatddmmyy }}
        </p>
        <p *ngIf="!product.pickUpPeriod">hele tiden</p>
      </div>
      <div class="form-group">
        <label>Kommentarer til bestilling af produktet</label>
        <p>{{ product.allowComments ? 'Tilladt' : 'Ikke tilladt' }}</p>
      </div>
      <h3>Lageroplysninger</h3>
      <div class="form-group">
        <label>Total lagerbeholdning</label>
        <p *ngIf="product.totalOfferUnlimited === 1">Ubegrænset</p>
        <p *ngIf="product.totalOfferUnlimited === 0">
          {{ product.totalOffer }} stk
        </p>
      </div>
      <div class="form-group">
        <label>Maks. antal bestillinger per dag</label>
        <p *ngIf="product.offerPerDayUnlimited === 1">Ubegrænset</p>
        <p *ngIf="product.offerPerDayUnlimited === 0">
          {{ product.offerPerDay }} stk
        </p>
      </div>
      <div class="form-group">
        <label>Samlet solgt</label>
        <p>{{ product.totalSold }} stk.</p>
      </div>
      <div class="form-group">
        <label>Samlet resterende på lager</label>
        <p *ngIf="product.totalOfferUnlimited === 1">Ubegrænset</p>
        <p *ngIf="product.totalOfferUnlimited === 0">
          {{ product.totalRemaining }} stk
        </p>
      </div>
      <div class="form-group open-basket-info">
        <label>Antal stk. inkluderet i de åbne kurve</label>
        <p>
          Dette antal viser hvor mange stk. kunder har tilføjet til åbne kurve,
          men endnu ikke bestilt.
        </p>
        <p>
          En inaktivering af dette produkt fjerner det ikke fra de åbne kurve -
          det fjernet det blot fra listen af tilgængelige produkter.
        </p>
        <p class="text-bold">{{ product.reservedQuantity }} stk.</p>
      </div>
      <div class="row">
        <button
          class="login-btn btn-white-login "
          (click)="downloadOrders(product.id)"
        >
          Få ordrelisten med dette produkt
        </button>
      </div>
      <div class="row">
        <a
          routerLink="/butikker/produkt/redigere/{{ product.id }}"
          class="login-btn btn-black-login"
          >Rediger produkt</a
        >
      </div>
    </div>
  </div>
</div>
