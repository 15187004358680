<div class="redmad-statistics">
  <div class="title">
    Oversigt
  </div>
  <div class="bag-category">
    <div class="category-title">
      Posekategori
    </div>

    <div (click)="toggleList()" class="selected-value">
      <div>
        {{ title }}
      </div>
      <div>
        <i class="icon icon-arrow-down"></i>
      </div>
    </div>

    <div *ngIf="showList" style="border: 1px solid #A8A8A8;border-radius: 4px;margin-bottom: 20px">
      <div *ngFor="let list  of list" (click)="onCategorySelect(list)" class="category-list">
        <div>
          {{ list.name }}
        </div>
        <div>
          <ion-icon *ngIf="selectedCategoryId !== list.id" name="radio-button-off-outline"></ion-icon>
          <ion-icon *ngIf="selectedCategoryId === list.id" name="radio-button-on-outline"></ion-icon>
        </div>
      </div>
    </div>


    <div class="food-overview">
      <div class="food-overview-title">
        Quick to go development over time
      </div>

      <div (click)="toggleFoodOverview()" class="selected-value">
        <div>
          {{ foodCategoryTitle }}
        </div>
        <div>
          <i class="icon icon-arrow-down"></i>
        </div>
      </div>

      <div *ngIf="showFoodOverviewList" style="border: 1px solid #A8A8A8;border-radius: 4px;margin-bottom: 20px">
        <div *ngFor="let list  of foodOverviewList" (click)="onFoodCategorySelect(list)" class="category-list">
          <div>
            {{ list.name }}
          </div>

          <div>
            <ion-icon *ngIf="selectedFoodCategoryId !== list.id" name="radio-button-off-outline"></ion-icon>
            <ion-icon *ngIf="selectedFoodCategoryId === list.id" name="radio-button-on-outline"></ion-icon>
          </div>

        </div>
      </div>
    </div>

    <div class="data">
      <div>
        <div class="data-title">
          Total
        </div>
        <div class="sub-title">
          (Oprettet)
        </div>
        <div class="increment-number">
          {{ bagsReport?.total.difference }}
        </div>
        <div class="number">
          {{ bagsReport?.total.current }}
        </div>

      </div>
      <div>
        <div class="data-title">
          Solgt
        </div>
        <div class="sub-title">
          (poser)
        </div>
        <div class="increment-number">
          {{ bagsReport?.sold.difference }}
        </div>
        <div class="number">
          {{ bagsReport?.sold.current }}
        </div>

      </div>
      <div>
        <div class="data-title">
          Afhentet
        </div>
        <div class="sub-title">
          (poser)
        </div>
        <div class="increment-number">
          {{ bagsReport?.picked_up.difference }}

        </div>
        <div class="number">
          {{ bagsReport?.picked_up.current }}
        </div>

      </div>
      <div>
        <div class="data-title">
          Omsætning
        </div>
        <div class="sub-title">
          (kr.)
        </div>
        <div class="increment-number">
          {{ bagsReport?.turnover.difference }}
        </div>
        <div class="number">
          {{ bagsReport?.turnover.current }}
        </div>
      </div>
    </div>
  </div>


  <div class="graph-section">

    <div class="title">
      Præstation over tid
    </div>


    <div>
      <app-quicktogo-luckybag-graphs
        [turnOverGraph]="turnOverGraph"
      >
      </app-quicktogo-luckybag-graphs>
    </div>


    <app-quicktogo-turnover-graphs
      [turnOverGraph]="turnOverGraph"
    >

    </app-quicktogo-turnover-graphs>
  </div>


</div>

