import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'coop-order-list-filter',
  templateUrl: './order-list-filter.component.html',
  styleUrls: ['./order-list-filter.component.scss'],
})
export class OrderListFilterComponent implements OnInit {
  // @Input() order = 0;
  // @Input() sold = 0;
  // @Input() collected = 0;
  // @Input() stockLeft = 0;
  // @Input() tab = 0
  @Output() search = new EventEmitter<string>();
  showSearchIcon:boolean= true
  searchValue = ''
  @ViewChild('searchBox') searchBox;
  constructor() {}

  ngOnInit() {}
// *** It resets search input field ***
  // used in new tab change
  resetSearch() {
    const inputField = document.getElementById(
      'searchInput'
    ) as HTMLInputElement;
    inputField.value = '';
  }
// the search event is triggered that emits the entered text to parent component
  // @param [searchValue: string] => input element value
  onSearch(searchValue: string) {
      this.search.emit(searchValue);
      this.showSearchIcon = searchValue === ''
  }

  onClearSearch(){
    this.searchBox.nativeElement.value = null
    this.search.emit('')
    this.showSearchIcon = true
  }
}
