import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ErrorhandlerService } from 'app/core/services';
import { TemplateListData } from 'app/shared/interface';
import {
  Poll,
  PollList,
  SpecialEvent,
  SpecialPoll,
  Template,
  PollService,
} from 'app/features/poll';

@Injectable({
  providedIn: 'root',
})
export class PollListResolver  {
  constructor(
    private _pollService: PollService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<PollList[]> | Promise<PollList[]> | PollList[] {
    return this._pollService.getPolls(1, 1).pipe(
      catchError((err) => {
        return this._errorHandlerService.routeResolverError(err);
      })
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class PollTemplateListResolver  {
  constructor(
    private _pollService: PollService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<TemplateListData> {
    return this._pollService.getPollTemplates(1).pipe(
      catchError((err) => {
        return this._errorHandlerService.routeResolverError(err);
      })
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class PollResolver  {
  constructor(
    private _pollService: PollService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Poll> | Promise<Poll> | Poll {
    return this._pollService.getPoll(route.params['id']).pipe(
      catchError((err) => {
        return this._errorHandlerService.routeResolverError(err);
      })
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class PollSingleTemplateResolver  {
  constructor(
    private _pollService: PollService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this._pollService.getTemplate(route.params['id']).pipe(
      catchError((err) => {
        return this._errorHandlerService.routeResolverError(err);
      })
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class SpecialEventsListResolver  {
  constructor(
    private _pollService: PollService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<SpecialEvent[]> {
    return this._pollService.getSpecialEvents().pipe(
      catchError((err) => {
        return this._errorHandlerService.routeResolverError(err);
      })
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class SpecialPollResolver  {
  constructor(
    private _pollService: PollService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<SpecialPoll> {
    return this._pollService.getSpecialPoll(route.params['id']).pipe(
      catchError((err) => {
        return this._errorHandlerService.routeResolverError(err);
      })
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class AssemblyEventsListResolver  {
  constructor(
    private _pollService: PollService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<SpecialEvent[]> {
    return this._pollService.getAssemblyEvents().pipe(
      catchError((err) => {
        return this._errorHandlerService.routeResolverError(err);
      })
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class AssemblyPollResolver  {
  constructor(
    private _pollService: PollService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<SpecialPoll> {
    return this._pollService.getAssemblyPoll(route.params['id']).pipe(
      catchError((err) => {
        return this._errorHandlerService.routeResolverError(err);
      })
    );
  }
}
