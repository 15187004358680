<footer class="app-footer not-print ">

  <ul class="footer-links wrapper">
    <ng-container *ngFor="let navitem of navItems">
      <li routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          #link="routerLinkActive">
        <a
          routerLink="{{ navitem.url }}"
          (click)="onNavigate(navitem)"
        >
          <div>
            <ion-icon name="{{ navitem.icon }}" *ngIf="!link.isActive"></ion-icon>
            <ion-icon name="{{ navitem.filled }}" *ngIf="link.isActive"></ion-icon>
          </div>

          {{ navitem.name }}
        </a>
      </li>
    </ng-container>

  </ul>

</footer>
