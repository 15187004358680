import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';

@Injectable({
  providedIn: 'root',
})
export class SortService {
  constructor() {}

  // ** Sort Items By Now ** //
  // Sort Array Items into Active, Future, Expired
  // item with enddate less than now is Expired
  // item with startdate greater than now is Future
  // rest of the item is active
  // @params [itemArray: Array] => Array of Objects
  // @params [startDatekey: string] => Keyname of the Startdate in the object
  // @params [endDatekey: string] => Keyname of the Enddate in the object
  // @return [itemArray] => sorted inputArray
  sortItemByNow(
    itemArray: Array<any>,
    startDatekey: string,
    endDatekey: string
  ): Array<any> {
    for (const item in itemArray) {
      if (itemArray.hasOwnProperty(item)) {
        const obj = itemArray[item];
        const now = dayjs();
        const startDate = dayjs(obj[startDatekey]);
        const endDate = dayjs(obj[endDatekey]);
        if (endDate.isBefore(now)) {
          obj.state = 'past';
          continue;
        }
        if (startDate.isAfter(now)) {
          obj.state = 'future';
          continue;
        }
        obj.state = 'present';
      }
    }
    return itemArray;
  }

  // ** Sort By Date ** //
  // Sorts an array but date
  // @params [itemArray: Array] => Array of Objects
  // @params [keyname: string] => Name of the Key containing the date of the object
  // @params [direction: string] => Sort Direction {newestFirst, oldestFirst}
  // @return [itemArray: Array] => Sorted Array of objects
  sortItemByDate(itemArray: any[], keyname: string, direction: string): Array<any> {
    if (direction === 'oldestFirst') {
      return itemArray.sort((a: { [x: string]: any; }, b: { [x: string]: any; }) =>
        this._compareDate(a[keyname], b[keyname])
      );
    }
    if (direction === 'newestFirst') {
      return itemArray.sort((a, b) =>
        this._compareDate(b[keyname], a[keyname])
      );
    }
  }

  // Compare Dates
  // Compares two dates from sort method. Uses Moment js
  // @params [(a,b)] => items from the sort array
  // @return [number] => return -1 if condition match or return 1
  private _compareDate(a: string | number | dayjs.Dayjs | Date, b: string | number | dayjs.Dayjs | Date): number {
    if (dayjs(a) < dayjs(b)) {
      return -1;
    }
    return 1;
  }
}
