export const Assembly_Poll_Validation_Messages = {
  title:{
    required: 'Udfyld venligst feltet',
  },
  dateMismatchStartEnd: {
    mismatch: 'Afslutningstidspunkt skal være senere end Udsendingstidspunkt',
  },
  imageUrl: {
    required: 'Udfyld venligst feltet',
  },
  description: {
    required: 'Udfyld venligst feltet',
    invalid: '[ ] ikke tilladt her.',
  },
  dateMismatchStartExpire: {
    mismatch:
      'Tilmeldingen til generalforsamlingen skal være afsluttet inden afstemningens starttidspunkt.',
  },
  startDate: {
    dateMismatchStartEnd:
      'Afslutningstidspunkt skal være senere end Udsendingstidspunkt',
    dateMismatchStartExpire:
      'Tilmeldingen til generalforsamlingen skal være afsluttet inden afstemningens starttidspunkt.',
  },
  endDate: {
    dateMismatchStartEnd:
      'Afslutningstidspunkt skal være senere end Udsendingstidspunkt',
  },
  expirationDate: {
    dateMismatchStartExpire:
      'Tilmeldingen til generalforsamlingen skal være afsluttet inden afstemningens starttidspunkt.',
  },
  answer:{
    required: 'Udfyld venligst feltet',
  },
  requirements:{
    required: 'Udfyld venligst feltet',
  }
};
