import { ChannelList } from '../interface';

export const ChannelData: ChannelList[] = [
  {
    channel: 'coop',
    iconOutline: 'phone-portrait-outline',
    iconFilled:'phone-portrait',
    name: 'CoopApp',
  },
  {
    channel: 'facebook',
    iconOutline: 'logo-facebook',
    iconFilled: 'logo-facebook',
    name: 'Facebook',
  },
  {
    channel: 'sms',
    iconOutline: 'chatbubble-ellipses-outline',
    iconFilled: 'chatbubble-ellipses',
    name: 'SMS',
  },
  {
    channel: 'web',
    iconOutline: 'globe-outline',
    iconFilled: 'globe',
    name: 'Web',
  },
  {
    channel: 'dit',
    iconOutline: 'location-outline',
    iconFilled: 'location',
    name: 'Digital avis',
  },
  {
    channel: 'screen',
    iconOutline: 'location-outline',
    iconFilled: 'location',
    name: 'Butiksskærme',
  },
  {
    channel: 'mail',
    iconOutline: 'mail-outline',
    iconFilled: 'mail',
    name: 'Email',
  },
];
