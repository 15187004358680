<div class="filter-wrapper">
  <div class="search-title">
    Ordreliste
  </div>
  <div class="flex-display">
    <div class="search-section" >
      <input
        type="text"
        id="searchInput"
        placeholder="Søg "
        #searchBox
        (input)="onSearch(searchBox.value)"
      />
    </div>
    <div class="searchicon" >
      <ion-icon name="search-outline"></ion-icon>
    </div>
  </div>
</div>
