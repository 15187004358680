<div class="report-wrapper">
  <div class="report-overview">
    <div class="live-title">
      <p>{{ data.title }}</p>
    </div>
    <div class="live-date">
      <p class="item-date">
        {{ data.start_date | dateformatddmmyy : true }}
        <span style="font-weight: 700"><-></span>
        {{ data.end_date | dateformatddmmyy : true }}
      </p>
    </div>
    <div class="live-overall-stats">
      <div class="total-order">
        <p class="order-amount">{{ reportData.total_orders }}</p>
        <p>antal kunder</p>
      </div>
      <div class="order-stats">
        <p>Omsætning: {{ reportData.total_turnover }} kr.</p>
        <p>Gns. kurvstørrelse: {{ reportData.average_order }} kr.</p>
        <p>Salgspotentiale, stk. : {{ reportData.total_extra_orders }}</p>
        <div class="fb-stats">
          <button type="button" class="btn btn-blue">
            Facebook video statistik
          </button>
          <p>Antal 3-sek. seere: {{ fbInsights.total_views }}</p>
          <p>
            Personer der har emoji-interageret: {{ fbInsights.total_likes }}
          </p>
        </div>
      </div>
    </div>
    <div class="order-report-bar">
      <h2 class="title">Produkter (stk.)</h2>
      <div class="report-details">
        <div class="detail-item">
          <p>Bestilt / Max</p>
          <div class="report-bar-ctn">
            <div class="report-bar" id="soldBar-total"></div>
          </div>
          <span class="report-data" id="soldData-total"></span>
          <span class="report-data"
            >/ {{ data.total_quantity | kformatter }}</span
          >
        </div>
        <div class="detail-item">
          <p>Lagerbeholdning</p>
          <div class="report-bar-ctn">
            <div class="report-bar" id="remBar-total"></div>
          </div>
          <span class="report-data" id="remData-total"></span>
          <span class="report-data"
            >/ {{ data.total_quantity | kformatter }}</span
          >
        </div>
      </div>
    </div>
  </div>
  <div class="product-search">
    <div class="title">
      <p>Produkter</p>
    </div>
    <div class="search-box-container">
      <div class="search-box">
        <input
          type="text"
          placeholder="Indtast produkttitel"
          #searchBox
          (input)="search(searchBox.value)"
        />
        <i
          class="icon-search search-button left-search"
          (click)="search(searchBox.value)"
        ></i>
      </div>
    </div>
  </div>
  <div class="report-accordion-container">
    <div
      class="product-report"
      *ngFor="let prod of data.products; let i = index"
      (click)="hidingTest(prod)"
      [hidden]="hideForSearch(prod.title)"
    >
      <div class="product-initial-data">
        <div class="product-info">
          <p class="product-title">{{ prod.title }}</p>
          <p>
            <strong style="font-weight: 700">{{ prod.keyword }}</strong> - Fast
            antal pr. ordre: {{ prod.maxOrder ? prod.maxOrderCount : 'OFF' }}
          </p>
          <p *ngIf="!prod.showContent">
            Antal bestilte produkter: {{ reportData.products[i].sold }} /
            {{ prod.quantity }}
          </p>
        </div>
        <div class="accordion-toggle">
          <i
            class="{{
              prod.showContent ? 'ti-angle-double-up' : 'ti-angle-double-down'
            }}"
          ></i>
        </div>
      </div>
      <div class="report-details" [hidden]="!prod.showContent" [@fade]="'in'">
        <div class="detail-item">
          <p>Bestilt / Max</p>
          <div class="report-bar-ctn">
            <div class="report-bar" [id]="'soldBar-' + i"></div>
          </div>
          <span class="report-data" [id]="'soldData-' + i"></span>
          <span class="report-data">/ {{ prod.quantity | kformatter }}</span>
        </div>
        <div class="detail-item">
          <p>Resterende</p>
          <div class="report-bar-ctn">
            <div class="report-bar" [id]="'remBar-' + i"></div>
          </div>
          <span class="report-data" [id]="'remData-' + i"></span>
          <span class="report-data">/ {{ prod.quantity | kformatter }}</span>
        </div>
        <div class="detail-item-extra">
          <p>Salgspotentiale, stk. :</p>
          <p>{{ reportData.products[i].extra_orders }}</p>
        </div>
        <div class="detail-item-extra">
          <p>Antal 'Info' afsendt:</p>
          <p>{{ reportData.products[i].info_commands }}</p>
        </div>
        <div class="detail-item-extra">
          <p>Omsætning:</p>
          <p>{{ reportData.products[i].turnover }} kr.</p>
        </div>
      </div>
    </div>
  </div>
</div>
