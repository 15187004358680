import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'coop-nopage',
  templateUrl: './nopage.component.html',
  styleUrls: ['./nopage.component.scss'],
})
export class NopageComponent implements OnInit, OnDestroy {
  message: string = 'Noget gik galt, prøv igen.';
  private _subscriptionState: boolean = true;

  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {
    this._subscriptionState = false;
  }
}
