<div class="pseudo-input">
  ja tak &lt;<input
    #custom_input
    type="text"
    size="8"
    placeholder="nøgleord"
    (blur)="onTouched()"
    [value]="value"
    (keyup)="inputEvents($event)"
  />&gt;<span *ngIf="showStk">&lt;stk&gt;</span>
</div>
