import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'coop-imageloader',
  templateUrl: './imageloader.component.html',
  styleUrls: ['./imageloader.component.scss'],
})
export class ImageLoaderComponent implements OnInit {
  @Input() showLoader: boolean = false;
  @Input() progress: number = 0;
  @Input() loaderMsg = '';
  @Input() uploadFailed: boolean = true;

  constructor() {}

  ngOnInit() {}
}
