import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

import { DateFormatService } from 'app/core/services';
import { NyhedTilbud, OfferFormComponent } from 'app/features/offer';

@Component({
  selector: 'coop-offer-template',
  templateUrl: './offer-template.component.html',
  styleUrls: ['./offer-template.component.scss'],
})
export class OfferTemplateComponent implements OnInit {
  @ViewChild(OfferFormComponent, { static: true })
  offerFormComp: OfferFormComponent;
  formData?: NyhedTilbud;
  newsMode = false;
  constructor(private _activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.formData = this._activatedRoute.snapshot.data['offer'];
    this.newsMode = this._activatedRoute.snapshot.data['newsMode'];
    this._patchDefaultTimeInTemplateMode();
  }

  private _patchDefaultTimeInTemplateMode() {
    for (const option in this.formData.shared) {
      if (this.formData.shared[option]) {
        this._computeTemplateDefaultTime(option);
      }
    }
  }

  private _computeTemplateDefaultTime(option) {
    const currForm = this.formData[option];
    const isExpBefore = moment(currForm.expirationDate).isBefore(moment.now());
    if (option !== 'sms') {
      currForm.publishDate = DateFormatService.timeIntervalFormat(
        moment().toDate()
      );
      if (isExpBefore) {
        currForm.expirationDate = DateFormatService.timeIntervalFormat(
          moment().add(7, 'days')
        );
        currForm.endDate = currForm.expirationDate;
      }
    }
    const isEndBefore = moment(currForm.endDate).isBefore(moment.now());
    currForm.startDate = DateFormatService.timeIntervalFormat(
      moment().toDate()
    );
    if (isEndBefore) {
      currForm.endDate = DateFormatService.timeIntervalFormat(
        moment().add(7, 'days')
      );
    }
    if (option === 'sms') {
      if (isExpBefore) {
        currForm.endDate = DateFormatService.timeIntervalFormat(
          moment().add(7, 'days')
        );
      }
      currForm.sendDate = DateFormatService.timeIntervalFormat(
        moment().toDate()
      );
    }
  }
}
