import {Component, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { EMPTY } from 'rxjs';
import { mergeMap, takeWhile } from 'rxjs/operators';

import { OrderTab } from 'app/core/data/defaultValues';
import {
  ChannelDataService,
  ErrorhandlerService,
  NotificationsService,
  TokenService,
} from 'app/core/services';
import { FacebookEventsService } from 'app/features/facebookconnect/services';
import { JatakService } from 'app/features/jatak/services';
import { JatakLiveService } from 'app/features/jatak-live/services';
import { OfferDataService, TemplateService } from 'app/features/offer/services';
import { ProductService } from 'app/features/order-and-pickup/product/services';
import {
  animateChild,
  animateParent,
  slideAnimation,
} from 'app/shared/animations';
import {
  Folders,
  ListItem,
  Modes,
  Shared,
  Template,
  TemplateFolderData,
} from 'app/shared/interface';

@Component({
  selector: 'coop-templates-folder-list',
  templateUrl: './templates-folder-list.component.html',
  styleUrls: ['./templates-folder-list.component.scss'],
  animations: [animateChild, animateParent, slideAnimation],
})
export class TemplatesFolderListComponent implements OnInit {
  @ViewChild('searchBox') searchBox;
  offerType: string;
  pageTitle: string = 'Vælg skabelon';
  templateFolderList: TemplateFolderData;
  templateFolder: Folders[];
  allTemplateFolder: Folders[];
  templates: Template[];
  itemList: ListItem[];
  showParent: string = 'visible';
  templateUrl: string;
  currentUrl: string;
  previousUrl: string;
  templateType: string = 'folder';
  sharedChannels;
  showChannelSelection;
  currentSelectedTemplate;
  modes: Modes;
  templateFrom;
  board: number;
  orderShared = OrderTab;
  requiredChannel = "coop";
  private _subscriptionState: boolean = true;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _jatakService: JatakService,
    private _jatakLiveService: JatakLiveService,
    private _productService: ProductService,
    private _templateService: TemplateService,
    private _facebookEvents: FacebookEventsService,
    private _router: Router,
    private _offerDataService: OfferDataService,
    private _channelDataService: ChannelDataService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  ngOnInit() {
    this._initTempData();
    this._checkChildPage();
    this._router.events
      .pipe(
        takeWhile(() => this._subscriptionState),
        mergeMap((routerEvent) => {
          // check whether it is a parent page or not
          this._checkChildPage();
          if (
            routerEvent instanceof NavigationEnd &&
            this.showParent === 'visible'
          ) {
            // re calculate url
            this.currentUrl = this._router.url;
            this._getPreviousRoute();
            // reload data when the page returns to folder page for latest updates
            return this._reloadData();
          } else {
            return EMPTY;
          }
        })
      )
      .subscribe({
        next: (templateData) => {
          this._resetValues(templateData);
        },
        error: (err) => this._handleError(err),
      });

    this.requiredChannel = !this.modes.newsMode && (this.offerType !== "ja-tak") ? "coop" : "";
  }

  ngOnDestroy(): void {
    this._subscriptionState = false;
  }

  search(value: string) {
    if(value === '' ){
      this.templates = null;
      this._setUpListData(this.templateFolder, 'folder');
      return ;
    }
    if(this.offerType === "produkt"){
      this._router.navigate([this.currentUrl + '/sog/' + value])
    }
    this._searchFromAPI(value)
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (templateData) => {
          this.templates = templateData['data'];
          this._setUpListData(this.templates, 'search');
        },
        error: (err) => this._handleError(err),
      });
  }

  // to close the search div
  closeSearch() {
    this.templates = null;
    this.searchBox.nativeElement.value = null;
    this._setUpListData(this.templateFolder, 'folder');
  }

  templateSelected(id: number) {
    this.currentSelectedTemplate = id;
    if (this.templateType === 'template') {
      if (
        this.offerType === 'tilbud' ||
        this.offerType === 'nyheder' ||
        this.offerType === 'good-price'
      ) {
        this.sharedChannels = this.getSharedData(id);
        this.showChannelSelection = true;
        return;
      }
      if (this.offerType === 'ja-tak-live') {
        this._fbSelect(id);
        return;
      }

      if(this.offerType === 'ja-tak'){
        this.sharedChannels = this.getSharedData(id);
        this.showChannelSelection = true;
        return;
      }
      this._router.navigate([this.currentUrl + '/sog/' + id]).then(() => {
        this.closeSearch();
      });
      return;
    }
    this._router.navigate([this.currentUrl + '/' + id]).then(() => {
      this.closeSearch();
    });
  }

  getSharedData(id: number): Shared {
    for (const template of this.templates) {
      if (template.id === id) {
        return template['shared'];
      }
    }
  }

  channelConfirmed(channels: Shared) {
    this._offerDataService.setChannel(channels);
    this._channelDataService.setChannel({ selected: channels, isSet: true });
    this._router
      .navigate([this.currentUrl + '/sog/' + this.currentSelectedTemplate])
      .then(() => {
        this.closeChannelSelection();
        this.closeSearch();
      });
  }

  closeChannelSelection() {
    this.showChannelSelection = false;
  }

  private _fbSelect(id: number) {
    const facebookConnected = TokenService.getUserDetail().facebook.connected;
    if (!facebookConnected) {
      NotificationsService.notify(
        'Opret forbindelse til facebook',
        'error',
        'top'
      );
      this._facebookEvents.connectFacebook();
      return;
    }
    this._jatakService
      .getFbTokenStatus()
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (res) => {
          if (res) {
            this._router.navigate([this.currentUrl + '/sog/' + id]).then(() => {
              this.closeSearch();
            });
          } else {
            NotificationsService.notify(
              'Ugyldigt Facebook Token.<br>Forbind siden igen',
              'error',
              'top'
            );
            this._facebookEvents.connectFacebook();
          }
        },
        error: (err) => this._errorHandlerService.handleError(err),
      });
  }

  private _setUpListData(listData, type) {
    this.itemList = [];
    const that = this;
    // need to change interface coming from backend for 'folders'
    if (type === 'folder') {

      listData.forEach((item) => {
        const listItem: ListItem = {
          id: item.id,
          image: item.image ? item.image : 'assets/images/folder.png',
          title: item.name,
          desc: item.desc,
          newTemplateCount: item.newTemplateCount !== null ? item.newTemplateCount : null,
          new : item.new ? item.new : null
        };
        that.itemList = [...that.itemList, listItem];
      });
      this.templateType = 'folder';
    }
    if (type === 'search') {
      if (
        this.offerType === 'tilbud' ||
        this.offerType === 'nyheder' ||
        this.offerType === 'good-price'
      ) {
        this._setUpSearchListData();
      } else {
        listData.forEach((item) => {
          const listItem: ListItem = {
            id: item.id,
            image: item.image ? item.image : '',
            title: item.title,
            desc: item.desc ? item.desc : '',
            new: item.isNew,
            totalProducts: item.totalProducts ? item.totalProducts : null,
          };
          that.itemList = [...that.itemList, listItem];
        });
      }
      this.templateType = 'template';
    }
  }

  private _setUpSearchListData() {
    this.itemList = [];
    const that = this;
    let firstShared;
    let sudoItem;
    let shortDescription;
    let shortTitle;
    this.templates.forEach((item) => {
      firstShared = this._getFirstSharedValues(item);
      sudoItem = item[firstShared];
      // shortTitle = TemplatesFolderListComponent._getShortString(
      //   sudoItem.title,
      //   'title'
      // );
      // shortDescription = TemplatesFolderListComponent._getShortString(
      //   sudoItem.longDescription,
      //   'description'
      // );
      const listItem: ListItem = {
        id: item.id,
        image : item.video.thumbnail ? item.video.thumbnail : (item.image ? item.image : 'assets/images/folder.png'),
        // image: item.image ? item.image : (item.video.thumbnail ? item.video.thumbnail : ''),
        title: item.title,
        desc: item.desc,
        shared: item['shared'],

        new: item.isNew
      };
      that.itemList = [...that.itemList, listItem];
    });
  }

  private _getFirstSharedValues(item): string {
    return this.orderShared.filter((channel) => item.shared[channel])[0];
  }

  private static _getShortString(longString: string, type: string): string {
    if (type === 'title') {
      if (longString.length > 50) {
        return longString.substring(0, 40) + '...';
      }
    }
    if (type === 'description') {
      if (longString.length > 80) {
        return longString.substring(0, 80) + '...';
      }
    }
    return longString;
  }

  private _initTempData() {
    this.offerType = this._activatedRoute.snapshot.data['offerType'];
    if (this.offerType !== 'produkt') {
      this.templateFolderList =
        this._activatedRoute.snapshot.data['templateFolder'];
      this.templateFolder = this.templateFolderList['folders'];
      this.allTemplateFolder = this.templateFolder;
    }
    if (this.offerType === 'produkt') {
      this.templateFolder = this.allTemplateFolder =
        this._activatedRoute.snapshot.data['templateFolder'];
    }
    if (
      this.offerType === 'nyheder' ||
      this.offerType === 'tilbud' ||
      this.offerType === 'good-price'
    ) {
      this.modes = this._activatedRoute.snapshot.data['modes'];
      this.templateFrom = this.modes.newsMode ? '2' : '1';
      this.templateFrom = this.modes.goodPriceMode ? '3' : this.templateFrom;
    }
    this.modes = this._activatedRoute.snapshot.data["modes"] ? this._activatedRoute.snapshot.data["modes"] : {};
    this.board = TokenService.getUserType() === '2' ? 1 : 0;
    this.currentUrl = this._router.url;
    this._getPreviousRoute();
    this._setUpListData(this.templateFolder, 'folder');
  }

  private _reloadData() {
    if (this.offerType === 'ja-tak') {
      return this._jatakService.getJatakTemplatesFolder();
    }
    if (this.offerType === 'ja-tak-live') {
      return this._jatakLiveService.getJatakLiveTemplatesFolder();
    }
    if (this.offerType === 'produkt') {
      return this._productService.getProductTemplatesFolder();
    }
    if (
      this.offerType === 'nyheder' ||
      this.offerType === 'tilbud' ||
      this.offerType === 'good-price'
    ) {
      if (this.modes.newsMode) {
        return this._templateService.getTemplateFolder(true, false);
      }
      return this._templateService.getTemplateFolder(
        false,
        this.modes.goodPriceMode
      );
    }
  }

  private _resetValues(templateData) {
    const templateFolderList = templateData;
    const templateFolder = templateFolderList.folders
      ? templateFolderList.folders
      : templateFolderList;
    if (
      this.allTemplateFolder !== templateFolder ||
      this.templateFolderList !== templateFolderList
    ) {
      this.templateFolder = templateFolder;
      this.templateFolderList = templateFolderList;
      this._setUpListData(this.templateFolder, 'folder');
    }
  }

  private _searchFromAPI(searchTerm: string) {
    if (this.offerType === 'ja-tak') {
      return this._jatakService.searchJatakTemplate(searchTerm);
    }
    if (this.offerType === 'ja-tak-live') {
      return this._jatakLiveService.searchJatakLiveTemplate(searchTerm);
    }
    if (this.offerType === 'produkt') {
      return this._productService.searchProductTemplate(searchTerm , '' );
    }
    if (
      this.offerType === 'nyheder' ||
      this.offerType === 'tilbud' ||
      this.offerType === 'good-price'
    ) {
      return this._templateService.searchTemplate(
        searchTerm,
        this.templateFrom,
        this.board
      );
    }
  }

  private _getPreviousRoute() {
    if (
      this.offerType === 'nyheder' ||
      this.offerType === 'tilbud' ||
      this.offerType === 'good-price'
    ) {
      if (TokenService.getUserType() === '1') {
        if (this.modes.newsMode) {
          this.previousUrl = this.currentUrl.replace(
            '/indlaeg/nyheder/skabeloner',
            ''
          );
          return;
        }
        if (this.modes.goodPriceMode) {
          this.previousUrl = this.currentUrl.replace(
            '/indlaeg/god-pris/skabeloner',
            ''
          );
          return;
        }
        this.previousUrl = this.currentUrl.replace(
          '/indlaeg/tilbud/skabeloner',
          ''
        );
      }
      if (TokenService.getUserType() === '2') {
        this.previousUrl = this.currentUrl.replace(
          'indlaeg/nyheder/skabeloner',
          'skab'
        );
      }
      return;
    }
    this.previousUrl = this.currentUrl.replace(
      `${this.offerType}/skabeloner`,
      ''
    );
  }

  // Check if the route is child route or parent route
  // if child route set isParentPage as false
  private _checkChildPage(): void {
    this.showParent = this._activatedRoute.children.length
      ? 'invisible'
      : 'visible';
  }

  private _handleError(error) {
    let errorFrom = this.offerType;
    if (
      this.offerType === 'nyheder' ||
      this.offerType === 'tilbud' ||
      this.offerType === 'good-price'
    ) {
      errorFrom = 'promo';
    }
    this._errorHandlerService.handleError(error || { code: -400 }, errorFrom);
  }
}
