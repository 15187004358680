import {
  trigger,
  state,
  animate,
  transition,
  style,
} from '@angular/animations';

//*** slide animation for elements ***//
// created animation variable 'slideAnimation' with two triggers 'slideUpDown' and 'slideLeftRight'
//Each trigger have two states and transition => inactive and active state that defines the states to call at the end of each transition
//in trigger slideUpDown transition direction flows in both direction inactive and active state with 300ms transition time with 'ease-i-out'.
//in trigger slideLeftRight transition on both direction takes 400ms with 'ease-in-out'

export const slideAnimation = [
  trigger('slideUpDown', [
    state(
      'inactive',
      style({
        bottom: '-100vh',
        opacity: 0,
      })
    ),
    state(
      'active',
      style({
        bottom: '0',
        opacity: 1,
      })
    ),
    transition('inactive <=> active', animate('300ms ease-in-out')),
  ]),
  trigger('slideLeftRight', [
    state(
      'inactive',
      style({
        left: '-50vh',
      })
    ),
    state(
      'active',
      style({
        left: '0px',
      })
    ),
    transition('inactive <=> active', animate('400ms ease-in-out')),
  ]),
];
