import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { mergeMap } from 'rxjs/internal/operators/mergeMap';
import { distinctUntilChanged, EMPTY, Subject } from 'rxjs';
import { debounceTime, finalize, takeWhile } from 'rxjs/operators';

import { DefaultSharedValue, OrderTab } from 'app/core/data/defaultValues';
import {
  ChannelDataService,
  ErrorhandlerService,
  FormFunctionsService,
  NotificationsService,
  SearchService,
  TokenService
} from 'app/core/services';
import { FacebookEventsService } from 'app/features/facebookconnect/services';
import { JatakService } from 'app/features/jatak/services';
import { JatakLiveService } from 'app/features/jatak-live/services';
import {
  OfferService,
  ScreenListDataService,
  TemplateService
} from 'app/features/offer/services';
import { OkScreenService } from 'app/features/ok-screen/services';
import { PollService } from 'app/features/poll/services';
import {
  animateChild,
  animateParent,
  slideAnimation
} from 'app/shared/animations';
import {
  ListItem,
  Template,
  TemplateListData,
  Shared,
  Modes
} from 'app/shared/interface';
import { ProductService } from '../../../features/order-and-pickup/product';

@Component({
  selector: 'coop-templates-list',
  templateUrl: './templates-list.component.html',
  styleUrls: ['./templates-list.component.scss'],
  animations: [animateChild, animateParent, slideAnimation]
})
export class TemplatesListComponent implements OnInit, OnDestroy {
  @ViewChild('searchBox') searchBox;
  offerType: string;
  pageTitle: string = 'Vælg skabelon';
  pageNumber: number = 1;
  limit: number = 10;
  showParent: string = 'visible';
  currentUrl: string;
  templateData: TemplateListData;
  allTemplates: Template[];
  templates: Template[];
  itemList: ListItem[];
  orderShared = OrderTab;
  previousUrl: string;
  folderId: string;
  clientId: string;
  store: string;
  folId: string;
  showLoadMore = true;
  templateType: string = 'template';
  showChannelSelection = false;
  currentSelectedTemplate: number;
  sharedChannels: Shared;
  inputInSearch = false;
  modes: Modes;
  searchParams = new Subject<string>();
  requiredChannel = 'coop';
  searchQuery: any;
  isSearchResultPage: boolean = false;
  private _subscriptionState = true;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _templateService: TemplateService,
    private _jatakService: JatakService,
    private _jatakLiveService: JatakLiveService,
    private _facebookEvents: FacebookEventsService,
    private _offerService: OfferService,
    private _okScreenService: OkScreenService,
    private _pollService: PollService,
    private _router: Router,
    private _errorHandlerService: ErrorhandlerService,
    private _searchService: SearchService,
    private _channelDataService: ChannelDataService,
    private _screenListDataService: ScreenListDataService,
    private _productService: ProductService
  ) {
  }

  ngOnInit() {
    this.isSearchResultPage = this._activatedRoute.snapshot.url[0].path === 'sog';
    this._initTempData();
    this._router.events
      .pipe(
        takeWhile(() => this._subscriptionState),
        mergeMap((routerEvent) => {
          // check whether it is a parent page or not
          this._checkChildPage();
          if (
            routerEvent instanceof NavigationEnd &&
            this.showParent === 'visible'
          ) {
            this.currentUrl = this._router.url;
            this._getPreviousUrl();
            // reload data when the page returns to template page for latest
            this.pageNumber = 1;
            return this._reloadTemp();
          } else {
            return EMPTY;
          }
        })
      )
      .subscribe({
        next: (templateData) => this._resetValues(templateData),
        error: (err) => this._handleError(err)
      });
    this.search();

    // set requireChannel as Coop if not in news mode. News mode needs all channels to be optional
    this.requiredChannel = !this.modes.newsMode && (this.offerType !== 'ja-tak') ? 'coop' : '';
    // clearing out offer data if any
    this._channelDataService.clearChannel();
  }

  ngOnDestroy(): void {
    this._subscriptionState = false;
  }

  search() {
    // this.templates = []
    this.searchParams
      .pipe(
        debounceTime(800),
        distinctUntilChanged(),
        takeWhile(() => this._subscriptionState)
      )
      .subscribe({
        next: (searchQuery) => this.fetchNewData(searchQuery)
      });
    // this.showLoadMore = value.length === 0;
    // if (value !== '' && value.trim().length !== 0) {
    //   this.templates = this._searchService.searchByTitle(value, this.allTemplates, 'title', false);
    //   this.inputInSearch = true;
    //   this._setUpListData();
    // }
  }

  closeSearch() {
    this.templates = this.allTemplates;
    this._setUpListData();
    this.searchBox.nativeElement.value = null;
    this.inputInSearch = false;
  }

  loadMore(searchQuery?: string) {
    this.pageNumber += 1;
    this._reloadTemp(searchQuery)
      .pipe(
        takeWhile(() => this._subscriptionState),
        finalize(() => this._setUpListData())
      )
      .subscribe((templateData) => {
        this.templateData = FormFunctionsService.lowerCaseKeys(templateData);

        this.templates = this.allTemplates = this.allTemplates.concat(
          this.templateData.data
        );
      });
  }

  fetchNewData(searchQuery?: string) {
    this.pageNumber = 1;
    // this.templates = []
    this._reloadTemp(searchQuery)
      .pipe(
        takeWhile(() => this._subscriptionState),
        finalize(() => this._setUpListData())
      )
      .subscribe({
        next: (templateData) => {

          this.templateData = templateData;
          this.templates = this.allTemplates = this.templateData.data;
        },
        error: (err) => this._errorHandlerService.handleError(err)
      });
  }

  templateSelected(id: string) {
    this._router.navigate([this.currentUrl + '/' + id]).then();
  }

  getSelectedTemplateId(id: number) {
    if (this.offerType === 'ja-tak-live') {
      this._fbSelect(id);
      return;
    }
    this.currentSelectedTemplate = id;
    if (
      this.offerType === 'produkt' ||
      this.offerType === 'ok-screen' ||
      this.offerType === 'poll'
    ) {
      this._router
        .navigate([this.currentUrl + '/' + this.currentSelectedTemplate])
        .then();
      return;
    }

    this.sharedChannels = this.getSharedData(id);
    if (
      this.offerType === 'tilbud' ||
      this.offerType === 'nyheder' ||
      this.offerType === 'good-price'
    ) {
      if (this.sharedChannels.screen) {
        this._offerService
          .getScreenList()
          .pipe(takeWhile(() => this._subscriptionState))
          .subscribe((screenList) => {
            this._screenListDataService.setScreens(screenList);
            if (screenList.length === 0) {
              this.sharedChannels = { ...this.sharedChannels, screen: false };
            }
            this.showChannelSelection = true;
          });
      }
      if (!this.sharedChannels.screen) {
        this.showChannelSelection = true;
      }
      return;
    }
    this.showChannelSelection = true;
  }

  getSharedData(id: number): Shared {
    for (const template of this.templates) {
      if (template.id === id) {
        if (this.offerType === 'ja-tak') {
          return {
            coop: this.getToggleBoolean(+template.shared.coop),
            facebook: this.getToggleBoolean(+template.shared.facebook),
            mail: false,
            sms: this.getToggleBoolean(+template.shared.sms),
            web: this.getToggleBoolean(+template.shared.web),
            dit: false
          };
        }
        if (
          this.offerType === 'tilbud' ||
          this.offerType === 'nyheder' ||
          this.offerType === 'good-price'
        ) {
          return template['shared'];
        }
      }
    }
  }

  getToggleBoolean(counterValue: number): boolean {
    return counterValue === 1;
  }

  channelConfirmed(channels: Shared) {
    if (channels.web && !channels.facebook && !channels.coop && !this.modes.templateMode) {
      NotificationsService.notify(
        'Coop eller Facebook skal vælges',
        'info',
        'top'
      );
      return;
    }
    this._channelDataService.setChannel({ selected: channels, isSet: true });
    this._router
      .navigate([this.currentUrl + '/' + this.currentSelectedTemplate])
      .then();
    this.showChannelSelection = false;
  }

  closeChannelSelection() {
    this.showChannelSelection = false;
  }

  private _fbSelect(id: number) {
    const facebookConnected = TokenService.getUserDetail().facebook.connected;
    if (!facebookConnected) {
      NotificationsService.notify(
        'Opret forbindelse til facebook',
        'error',
        'top'
      );
      this._facebookEvents.connectFacebook();
      return;
    }
    this._jatakService
      .getFbTokenStatus()
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (res) => {
          if (res) {
            this._router.navigate([this.currentUrl + '/' + id]).then();
          } else {
            NotificationsService.notify(
              'Ugyldigt Facebook Token.<br>Forbind siden igen',
              'error',
              'top'
            );
            this._facebookEvents.connectFacebook();
          }
        },
        error: (err) => this._errorHandlerService.handleError(err)
      });
  }

  private _setUpListData() {
    this.itemList = [];
    const that = this;
    if (
      this.offerType === 'tilbud' ||
      this.offerType === 'nyheder' ||
      this.offerType === 'good-price'
    ) {
      this._setUpPromoListData();
    } else if (this.offerType === 'ok-screen') {
      this.templates.forEach((item) => {
        const listItem: ListItem = {
          id: item.id,
          image: this._setTempImg(item),
          title: item.title,
          desc: item.desc
            ? TemplatesListComponent._preparePreviewData(item.desc)
            : '',
          new: item.isNew
        };
        that.itemList = [...that.itemList, listItem];
      });
    } else {
      this.templates.forEach((item) => {
        const listItem: ListItem = {
          id: item.id,
          image: this._setTempImg(item),
          title: item.title,
          desc: item.desc
            ? TemplatesListComponent._preparePreviewData(item.desc)
            : '',
          new: item.isNew,
          shared: this._setSharedStatus(item),
          totalProducts: item.totalProducts ? item.totalProducts : null
        };
        that.itemList = [...that.itemList, listItem];
      });
    }
    let last_page = this.templateData.last_page;
    if (last_page > this.pageNumber) {
      this.showLoadMore = true;
    } else {
      this.showLoadMore = false;
    }

    // this.showLoadMore = !(this.itemList.length < 10);
  }

  private _setUpPromoListData() {
    this.itemList = [];
    const that = this;
    this.templates.forEach((item) => {
      const listItem: ListItem = {
        id: item.id,
        image: this._setTempImg(item),
        title: item.title,
        shared: item.shared,
        desc: item.desc,
        new: item.isNew
      };
      that.itemList = [...that.itemList, listItem];
    });
  }

  private _setTempImg(item: Template): string {
    return item.image ? item.image : item.video.thumbnail;
  }

  private static _preparePreviewData(desc: string) {
    if (desc.length > 100) {
      return desc.substring(0, 100) + '...';
    }
    return desc;
  }

  private _setSharedStatus(item: Template): Shared {
    if (this.offerType === 'ja-tak') {
      return item.shared;
    }
    if (this.offerType === 'ja-tak-live') {
      return DefaultSharedValue;
    }
  }

  private _reloadTemp(searchQuery?: string) {
    if (this.offerType === 'ja-tak') {
      return this._jatakService.getJatakTemplates(
        this.store,
        this.folId,
        this.pageNumber,
        this.limit,
        searchQuery
      );
    }
    if (this.offerType === 'ja-tak-live') {
      return this._jatakLiveService.getJatakLiveTemplates(
        this.clientId,
        this.store,
        this.folId,
        this.pageNumber,
        this.limit,
        searchQuery
      );
    }
    if (this.offerType === 'tilbud' ||
      this.offerType === 'nyheder' ||
      this.offerType === 'good-price'
    ) {
      if (this.folderId === 'egne') {
        return this._templateService.getTemplates(
          this.clientId,
          this.store,
          this.folId,
          this.pageNumber,
          this.limit,
          this.modes.newsMode,
          this.modes.goodPriceMode,
          searchQuery
        );
      }
      return this._templateService.getTemplates(
        this.clientId,
        this.store,
        this.folId,
        this.pageNumber,
        this.limit,
        this.modes.newsMode,
        this.modes.goodPriceMode,
        searchQuery
      );
    }
    if (this.offerType === 'ok-screen') {
      return this._okScreenService.getOkScreenTemplates(searchQuery);
    }
    if (this.offerType === 'poll') {
      return this._pollService.getPollTemplates(this.pageNumber, searchQuery);
    }
    if (this.offerType === 'produkt') {
      if (this.isSearchResultPage && searchQuery) {
        return this._productService.searchProductTemplate(this.searchQuery, this.folderId, this.pageNumber);
      }
      if (!searchQuery) {
        return this._productService.getProductTemplates(this.folderId, this.pageNumber);
      }

      return this._productService.searchProductTemplate(searchQuery, this.folderId, this.pageNumber);
    }
  }

  private _resetValues(templateData) {
    this.templateData = FormFunctionsService.lowerCaseKeys(templateData);
    this.templates = this.allTemplates = this.templateData.data;
    this._setUpListData();
  }

  private _sortTempId() {
    if (this.folderId === 'egne') {
      this.clientId = '1';
      this.store = 'own';
      this.folId = '0';
    } else if (this.folderId === 'store') {
      this.clientId = '0';
      this.store = 'store';
      this.folId = '0';
    } else {
      this.clientId = '0';
      this.store = 'folder';
      this.folId = this.folderId;
    }
  }

  private _initTempData() {
    this.offerType = this._activatedRoute.snapshot.parent.data['offerType']
      ? this._activatedRoute.snapshot.parent.data['offerType']
      : this._activatedRoute.snapshot.data['offerType'];
    this.folderId = this._activatedRoute.snapshot.params['folderId'];
    this.modes = this._activatedRoute.snapshot.data['modes'] ? this._activatedRoute.snapshot.data['modes'] : {};
    this.templateData = this._activatedRoute.snapshot.data['templates'];
    this.templates = this.allTemplates = this.templateData['data'];
    this.searchQuery = this._activatedRoute.snapshot.params['searchparams'];
    if (this.offerType === 'ok-screen') {
      this.pageTitle = 'Vælg OK-skabelon';
    }
    this.currentUrl = this._router.url;
    this._getPreviousUrl();
    this._setUpListData();
    this._sortTempId();
    this._checkChildPage();
  }

  private _getShortString(longString: string, type: string): string {
    if (type === 'title') {
      if (longString.length > 50) {
        return longString.substring(0, 40) + '...';
      }
    }
    if (type === 'description') {
      if (longString.length > 80) {
        return longString.substring(0, 80) + '...';
      }
    }
    return longString;
  }

  private static _getImageUrl(shared: string, item): string {
    if (shared !== 'dit') {
      if (
        shared === 'facebook' ||
        shared === 'web' ||
        shared === 'coop' ||
        shared === 'sms' ||
        shared === 'screen'
      ) {
        if (shared !== 'coop' && shared !== 'sms') {
          if (item.largeImage) {
            return item.largeImage[0];
          } else {
            return item.video.thumbnail;
          }
        }
        if (shared === 'coop' || shared === 'sms') {
          if (item.thumbnail) {
            return item.thumbnail;
          } else {
            return item.video.thumbnail;
          }
        }
      } else {
        return item.thumbnail;
      }
    }
    if (shared === 'dit') {
      return item.largeImage;
    }
  }

  private _getFirstSharedValues(item): string {
    return this.orderShared.filter((channel) => item.shared[channel])[0];
  }

  // get previousUrl from navigationExtras
  private _getPreviousUrl() {
    if (this.offerType === 'ok-screen') {
      this.previousUrl = this.currentUrl.replace(
        'ok-screen/skabeloner',
        'skab'
      );
      return;
    }
    if (this.offerType === 'poll') {
      this.previousUrl = this.currentUrl.replace(
        'afstemninger/skabeloner',
        'skab'
      );
      return;
    }
    if (this.offerType === 'produkt' && this.isSearchResultPage) {
      this.previousUrl = '/butikker/produkt/skabeloner/';
      return;
    }
    this.previousUrl = this.currentUrl.replace(this.folderId, '');
  }

  // Check if the route is child route or parent route
  // if child route set isParentPage as false
  private _checkChildPage(): void {
    this.showParent = this._activatedRoute.children.length
      ? 'invisible'
      : 'visible';
  }

  private _handleError(error) {
    this._errorHandlerService.handleError(
      error || { code: -400 },
      this.offerType
    );
  }
}
