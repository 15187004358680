import { State, Selector, Action, StateContext } from "@ngxs/store";
import { Injectable } from '@angular/core';

export interface RouteStates {
  routeUrl:any
}
export interface JatakRouteState {
  data : RouteStates
}


export class SetJatakRouteStates {
  static readonly type = "[SetJatakRouteCompState] Set Data"

  constructor(public payload: any) {
  }
}

export class ClearRouteState{
  static readonly type = "[SetJatakRouteState] Clear Data"
}

@State<any>({
  name: 'jatakroute',
  defaults : {
    data:null
  }
})

@Injectable()
export class SetJatakRouteState {
  @Selector()
  static getData(state) {
    return state.data
  }

  @Action(SetJatakRouteStates)
    setData(
      { patchState } : StateContext<JatakRouteState>,
      { payload } : SetJatakRouteStates
    ){
      patchState({
        data:payload
      })
    }
}
