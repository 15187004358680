<section class="app-sub-page">
  <div class="modal" [@slideUpDown]="pAnimate" [@slideDownUp]="pAnimate">
    <div class="content-container">
      <div class="close" (click)="cancel()">
        <i class="icon icon-close-circle"></i>
      </div>
      <div class="content cancel-box-content">
        <div class="title">
          <h2><i class="ti-alert"></i>Husk</h2>
        </div>
        <div class="message">
          <div class="info">
            <p>Der er kommet nye regler for prismarkedsføring.</p>
          </div>
        </div>
      </div>
      <div class="wrapper send-btn">
        <div class="row">
          <a
            class="login-btn btn-white-login"
            href="https://coopnetdk.intra.coop/Nyheder/Sider/Prismarkedsf%C3%B8ring---servicekontor.aspx"
            target="_blank"
            (click)="navigateTo()"
            >Læs mere</a
          >
        </div>
        <div class="row">
          <button class="login-btn btn-black-login" (click)="cancel()">
            Jeg har læst og forstået
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
