import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService, TokenService } from 'app/core/services';
import { Website, WebsiteList } from 'app/features/website';

@Injectable({
  providedIn: 'root',
})
export class WebsiteService {
  constructor(private _apiService: ApiService) {}

  saveWebsite(params: Website, edit: boolean): Observable<object> {
    if (edit) {
      return this._apiService.put(
        `about-store/${params.id}`,
        ApiService.CreateFormData(params)
      );
    }
    return this._apiService.post(
      `about-store`,
      ApiService.CreateFormData(params)
    );
  }

  getWebsiteListing(): Observable<WebsiteList[]> {
    const userType = TokenService.getUserType() === '2' ? 1 : 0;
    return this._apiService.get(`about-store `).pipe(map((res) => res['data']));
  }

  getSingleWebsite(id: number) {
    const url = `about-store/${id}`;
    return this._apiService.get(url).pipe(map((res) => res['data']));
  }

  deleteWebsite(id: string): Observable<any> {
    return this._apiService.del(`about-store/${id}`);
  }
}
