import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {ApiService, TokenService} from 'app/core/services';
import {
  Events,
  SingleEvent,
  SpecialEvent,
} from 'app/features/events/interface';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  constructor(private _apiService: ApiService) {
  }

  // *** Gets events lists within provided startDate and endDate ***
  // Used to populate events lists in calendar component
  // @params [startDate] => startDate displayed in current calendar
  // @params [endDate] => endDate displayed in current calendar
  // @returns [Observable<Events[]>] => Events whose activation period fall partially or completely in provided date range
  getEvents(startDate?: any, endDate?: any): Observable<Events[]> {
    let url = `events?userType=${TokenService.getUserType()}`;
    if (startDate) {
      url = url + `&startDate=${startDate}&endDate=${endDate}`;
    }
    return this._apiService.get(url).pipe(map((res) => res['events']));
  }

  // *** Gets events lists according to status ***
  // @params [status: number] => Tab number values: active(1), future(2), archived(0) or cancelled(-1)
  // @params [page: number] => Page number whose events are to be loaded.
  // @returns [Array<Events>] => belonging to current status and particular page number with limit of 10
  getEventsList(status, page): Observable<Events[]> {
    const url = `events?userType=${TokenService.getUserType()}&status=${status}&page=${page}&limit=10`;
    return this._apiService.get(url).pipe(map((res) => res['events']));
  }

  // *** Gets details of particular event ***
  // @params [id:string] => Event id
  // @returns Event Details of selected id
  getEvent(id: number) {
    return this._apiService
      .get(`events/${id}`)
      .pipe(map((res) => res['event']));
  }

  // *** Gets bookingList as a file from server and downloads it ***
  // @params [id:string] => Event id
  getBookingList(id: string): Observable<Blob> {
    return this._apiService.get(`exports/single-event/${id}`, {
      responseType: 'blob',
    });
  }

  // *** Provides POST api for creating a new event or editing event ***
  // @params [params: SingleEvent] => Event form value
  // @params [edit: boolean] => It represents whether current event is edit mode or create mode
  saveEvent(params: SingleEvent, edit: boolean): Observable<object> {
    params.userType = TokenService.getUserType();
    if (edit) {
      return this._apiService.post(
        `events/${params.id}/update`,
        ApiService.CreateFormData(params)
      );
    }
    return this._apiService.post(`events`, ApiService.CreateFormData(params));
  }

  // *** It provides API for deleting or cancelling the event ***
  // @params [id: string] => Event id
  // @params [type: string] => 'delete' or 'cancel'
  deleteEvent(id: string, type: string): Observable<object> {
    if (type === 'delete') {
      return this._apiService.post(`events/${id}/destroy`, {});
    }
    return this._apiService.post(`events/${id}/cancel`, {});
  }

  // *** Provides POST api for creating a new special event or editing special event ***
  // @params [params: SpecialEvent] => Special event form value
  // @params [edit: boolean] => It represents whether current event is edit mode or create mode
  saveSpecialEvent(params: SpecialEvent, edit: boolean): Observable<object> {
    if (edit) {
      return this._apiService.post(
        `special-events/${params.id}/update`,
        ApiService.CreateFormData(params)
      );
    }
    return this._apiService.post(
      'special-events',
      ApiService.CreateFormData(params)
    );
  }

  // *** It uses GET api to check whether current user is allowed to create special event or not ***
  // @returns [Observable<object>] => Success message if current user is allowed to create special event
  checkCreateSpecialEvent(): Observable<object> {
    return this._apiService.get('is-allowed/special-event');
  }

  // *** It gets prefilled JSON data for creating new Special Event ***
  // @returns [specialEventPrefill] => Special Event Templates data for create form
  getPrefilledSpecialEvent() {
    return this._apiService
      .get(`special-event-templates`)
      .pipe(map((res) => res['template']));
  }

  // *** Gets special events lists within provided startDate and endDate ***
  // Used to populate special events lists in calendar component
  // @params [startDate] => startDate displayed in current calendar
  // @params [endDate] => endDate displayed in current calendar
  // @returns [Observable<SpecialEvent[]>] => Special Events whose activation period fall partially or completely in provided date range
  getSpecialEvents(startDate?: any, endDate?: any): Observable<SpecialEvent[]> {
    let url = `special-events`;
    if (startDate) {
      url = url + `?fromDate=${startDate}&toDate=${endDate}`;
    }
    return this._apiService.get(url).pipe(map((res) => res['events']));
  }

  // *** Gets details of particular special event ***
  // @params [id:string] => Special Event id
  // @returns Special Event Details of selected id
  getSpecialEvent(id: number) {
    return this._apiService
      .get(`special-events/${id}`)
      .pipe(map((res) => res['event']));
  }

  // *** It provides API for deleting the special event ***
  // @params [id: string] => Special Event id
  deleteSpecialEvent(id: string): Observable<object> {
    return this._apiService.post(`special-events/${id}/destroy`, {});
  }

  // *** Gets bookingList as a file from server and downloads it ***
  // @params [id:string] => Special Event id
  getSpecialBookingList(id: string): Observable<string> {
    return this._apiService.get(`exports/special-event/${id}`, {
      responseType: 'blob',
    });
  }

  // *** It gets zoom link for the special event that is online ***
  // @params [params: object]=>Object of email, title and startTime of the event
  // @returns zoom link info in an interface of SpecialMeetingLink
  getMeetingLinks(params: any): Observable<object> {
    return this._apiService.post(
      'zoom-link',
      ApiService.CreateFormData(params)
    );
  }

  // *** It is used to cancel the Zoom meeting for special event ***
  // It is used after deleting the meeting or after navigating back in create mode after getting meeting link
  // @params [meetingId: string] => meetingId of interface SpecialMeetingLink
  cancelMeeting(meetingId: string): Observable<object> {
    const url = `zoom-link/cancel?meetingId=${meetingId}`;
    return this._apiService.post(url, {});
  }

  // *** It gets prefilled JSON data for creating new Assembly Event ***
  // @returns [specialEventPrefill] => Assembly Event Templates data for create form
  getPrefilledAssembly() {
    return this._apiService
      .get('assembly-event-templates')
      .pipe(map((res) => res['template']));
  }

  // *** It uses GET api to check whether current user is allowed to create assembly event or not ***
  // @returns [Observable<object>] => Success message if current user is allowed to create assembly event
  checkCreateAssembly(): Observable<object> {
    return this._apiService.get('is-allowed/assembly-event');
  }

  // *** It gets zoom link for the assembly event that is online ***
  // @params [params: object]=>Object of email, title and startTime of the event
  // @returns zoom link info in an interface of SpecialMeetingLink
  getMeetingLinksAssembly(params: any): Observable<object> {
    return this._apiService.post(
      'assembly-zoom-link',
      ApiService.CreateFormData(params)
    );
  }

  // *** It is used to cancel the Zoom meeting for Assembly event ***
  // It is used after deleting the meeting or after navigating back in create mode after getting meeting link
  // @params [meetingId: string] => meetingId of interface SpecialMeetingLink
  cancelMeetingAssembly(meetingId: string): Observable<object> {
    const url = `assembly-zoom-link/cancel?meetingId=${meetingId}`;
    return this._apiService.post(url, {});
  }

  // *** Provides POST api for creating a new assembly event or editing assembly event ***
  // @params [params: SpecialEvent] => Assembly event form value
  // @params [edit: boolean] => It represents whether current event is edit mode or create mode
  saveAssembly(params: SpecialEvent, edit: boolean): Observable<object> {
    params.memberId = parseInt(TokenService.getMemberId());
    if (edit) {
      return this._apiService.post(
        `assembly-events/${params.id}/update`,
        ApiService.CreateFormData(params)
      );
    }
    return this._apiService.post(
      `assembly-events`,
      ApiService.CreateFormData(params)
    );
  }

  // *** Gets assembly events lists within provided startDate and endDate ***
  // Used to populate assembly events lists in calendar component
  // @params [startDate] => startDate displayed in current calendar
  // @params [endDate] => endDate displayed in current calendar
  // @returns [Observable<SpecialEvent[]>] => Assembly Events whose activation period fall partially or completely in provided date range
  getAssemblyEvents(
    startDate?: any,
    endDate?: any
  ): Observable<SpecialEvent[]> {
    let url = 'assembly-events';
    if (startDate) {
      url = url + `?fromDate=${startDate}&toDate=${endDate}`;
    }
    return this._apiService.get(url).pipe(map((res) => res['events']));
  }

  // *** Gets details of particular assembly event ***
  // @params [id:string] => Assembly Event id
  // @returns Assembly Event Details of selected id
  getAssemblyEvent(id: number) {
    return this._apiService
      .get(`assembly-events/${id}?memberId=${TokenService.getMemberId()}`)
      .pipe(map((res) => res['event']));
  }

  // *** Gets bookingList as a file from server and downloads it ***
  // @params [id:string] => Assembly Event id
  getAssemblyBookingList(id: string): Observable<string> {
    return this._apiService.get(
      `exports/assembly-event/${id}?memberId=${TokenService.getMemberId()}`,
      {responseType: 'blob'}
    );
  }

  // *** It provides API for deleting the assembly event ***
  // @params [id: string] => Assembly Event id
  deleteAssembly(id: string): Observable<object> {
    return this._apiService.post(`assembly-events/${id}/destroy`, {});
  }

  // *** Gets originOwn and originParent kardex lists for parent ***
  // @returns Observable<KardexData>
  getAssemblyKardexList(): Observable<string[]> {
    let url = `assembly-events-child-kardex`;
    if (TokenService.getMemberId()) {
      url += `?memberId=${TokenService.getMemberId()}`;
    }
    return this._apiService.get(url).pipe(map((res) => res['data']));
  }


  getEmailPreviewData(event: string, type: string) {
    let url = `event/email-template/${event}/${type}`
    return this._apiService.get(url)
  }
}
