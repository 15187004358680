export const RECURRING_DATE_LIST = [
  {
    dayShort: 'Mon',
    dayName: 'Mandag',
    uniName: 'Monday',
    inRange: false,
  },
  {
    dayShort: 'Tir',
    dayName: 'Tirsdag',
    uniName: 'Tuesday',
    inRange: false,
  },
  {
    dayShort: 'Ons',
    dayName: 'Onsdag',
    uniName: 'Wednesday',
    inRange: false,
  },
  {
    dayShort: 'Tor',
    dayName: 'Torsdag',
    uniName: 'Thursday',
    inRange: false,
  },
  {
    dayShort: 'Fre',
    dayName: 'Fredag',
    uniName: 'Friday',
    inRange: false,
  },
  {
    dayShort: 'Lør',
    dayName: 'Lørdag',
    uniName: 'Saturday',
    inRange: false,
  },
  {
    dayShort: 'Søn',
    dayName: 'Søndag',
    uniName: 'Sunday',
    inRange: false,
  },
];
