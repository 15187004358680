<section class="app-page jatak-live-reporting">
  <div class="fixed-header-bug-fix" id="fixed">
    <coop-pageheader
      [pageTitle]="pageTitle"
      [isChild]="true"
      [routeTo]="previousUrl"
    ></coop-pageheader>
  </div>
  <div class="page-container">
    <coop-product-report-card
      [data]="productData"
      [isLive]="isLive"
      [routeId]="routeId"
      [fbInsights]="fbInsights"
    ></coop-product-report-card>
    <div class="live-comment-container">
      <div class="comment-ctn-title">Kommentarer</div>
      <div class="comments-ctn" *ngIf="hasGoneLive">
        <div class="facebook-live" [ngClass]="isLive ? 'blink_me' : 'not_live'">
          Facebook Live
        </div>
        <div class="comment-list">
          <div class="no-comments" *ngIf="commentList.length === 0">
            <p>Ingen kommentarer at vise</p>
          </div>
          <coop-live-infinite-scroll
            id="infinite"
            direction="up"
            [ready]="initInfiniteScroll"
            [isLoading]="isProcessing"
            (scrollTop)="onScrollTop()"
            (scrollBottom)="onScrollBottom($event)"
            style="height: 400px; overflow-y: auto"
          >
            <ng-container *ngIf="commentList.length > 0">
              <div
                class="comment"
                *ngFor="let commentDetail of commentList; let i = index"
                [id]="i"
              >
                <div class="comment-ctn">
                  <div class="comment-text">
                    <p>{{ commentDetail.from_name }}</p>
                    <p>{{ commentDetail.comment }}</p>
                  </div>
                </div>
                <div
                  class="reply-ctn"
                  *ngIf="
                    commentDetail.reply_status === 'success' &&
                    commentDetail.reply_comment !== null
                  "
                >
                  <div class="reply-text">
                    <p>Svar</p>
                    <p style="white-space: pre-line">
                      {{ commentDetail.reply_comment }}
                    </p>
                  </div>
                </div>
                <div
                  class="reply-ctn"
                  *ngIf="
                    commentDetail.reply_status === 'failed' &&
                    commentDetail.feed_comment_reply !== null
                  "
                >
                  <div class="reply-text">
                    <p>Svar</p>
                    <p style="white-space: pre-line">
                      {{ commentDetail.feed_comment_reply }}
                    </p>
                  </div>
                </div>
                <div
                  class="live-finish-separator"
                  *ngIf="separatorComment.hasOwnProperty(commentDetail.id)"
                >
                  <div class="live-end-line"></div>
                  <span class="live-end-date">
                    Live session sluttedde kl.
                    {{ separatorComment[commentDetail.id] | timeOnly }}
                  </span>
                  <span class="live-end-date" style="display: inline-block">
                    Robotten er aktiv til
                    {{ productData.end_date | dateformatddmmyy }}.
                  </span>
                </div>
              </div>
            </ng-container>
          </coop-live-infinite-scroll>
        </div>
      </div>
      <div class="comments-ctn" *ngIf="!hasGoneLive">
        <div class="no-comments not-live">
          <p>Live-videoen på Facebook skal starte</p>
          <p style="font-weight: 700">
            efter {{ productData.start_date | dateformatddmmyy }},
          </p>
          <p>og det skal slutte</p>
          <p style="font-weight: 700">
            før {{ productData.live_end_date | dateformatddmmyy }}.
          </p>
        </div>
      </div>
      <p class="comment-number" *ngIf="hasGoneLive">
        Antal kommentarer: {{ this.commentList.length }}
      </p>
    </div>
    <div class="lin-break"></div>
    <div class="wrapper">
      <button class="login-btn btn-black-login download" (click)="onDownload()">
        <i class="ti-download" style="margin-right: 5px"></i>
        Download ordreliste
      </button>
    </div>
  </div>
</section>
