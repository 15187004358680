import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { ErrorhandlerService, SearchService } from 'app/core/services';
import { fadeIn } from 'app/shared/animations';
import {
  FbInsights,
  Product,
  ProductReportData,
  JatakLiveService,
} from 'app/features/jatak-live';

@Component({
  selector: 'coop-product-report-card',
  templateUrl: './product-report-card.component.html',
  styleUrls: ['./product-report-card.component.scss'],
  animations: [fadeIn],
})
export class ProductReportCardComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  @Input() data: ProductReportData;
  @Input() fbInsights: FbInsights;
  @Input() isLive;
  @Input() routeId;
  init = false;
  combinedIntervalId;
  isProcessing = false;
  itemList: Product[];
  allItemList: Product[];
  reportData: ProductReportData;

  constructor(
    private _jatakLiveService: JatakLiveService,
    private _searchService: SearchService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  ngOnInit() {
    this.reportData = this.data;
    this.itemList = this.allItemList = this.data.products;
  }

  ngOnChanges(changes: SimpleChanges) {}

  ngAfterViewInit() {
    this._startProductDataReport(this.data, true);
    if (this.isLive) {
      this.combinedIntervalId = setInterval(
        () => this._getCombinedReports(),
        5000
      );
    }
    this.init = true;
  }

  ngOnDestroy() {
    clearInterval(this.combinedIntervalId);
  }

  hideForSearch(value: string) {
    return !this.itemList.some((item) => item.title === value);
  }

  search(value: string) {
    this.itemList = this._searchService.searchByTitle(
      value,
      this.allItemList,
      'title',
      false
    );
  }

  hidingTest(product: Product) {
    product['showContent'] = !product['showContent'];
  }

  private _getCombinedReports() {
    if (!this.isLive) {
      clearInterval(this.combinedIntervalId);
      return;
    }
    if (!this.isProcessing) {
      this.isProcessing = true;
      combineLatest([this._getProductReport(), this._getFbInsights()])
        .pipe(take(1))
        .subscribe({
          next: ([productReport, fbInsights]) => {
            this.reportData = productReport;
            this.fbInsights = fbInsights;
            this._startProductDataReport(productReport);
            this.isProcessing = false;
          },
          error: (err) => this._handleError(err),
        });
    }
  }

  private _getProductReport(): Observable<ProductReportData> {
    return this._jatakLiveService.getJatakProductReport(this.routeId);
  }

  private _getFbInsights(): Observable<FbInsights> {
    return this._jatakLiveService.getFacebookInsights(this.routeId);
  }

  private _startProductDataReport(
    reportData: ProductReportData,
    start = false
  ) {
    const totalRemaining = reportData.total_quantity - reportData.total_sold;
    this._createProgressAnimation(
      document.getElementById(`soldBar-total`),
      document.getElementById(`soldData-total`),
      reportData.total_sold,
      reportData.total_quantity,
      start
    );
    this._createRegressionAnimation(
      document.getElementById(`remBar-total`),
      document.getElementById(`remData-total`),
      totalRemaining,
      reportData.total_quantity,
      start
    );
    reportData.products.forEach((data: Product, index: number) => {
      const remaining = data.quantity - data.sold;
      this._createProgressAnimation(
        document.getElementById(`soldBar-${index}`),
        document.getElementById(`soldData-${index}`),
        data.sold,
        data.quantity,
        start
      );
      this._createRegressionAnimation(
        document.getElementById(`remBar-${index}`),
        document.getElementById(`remData-${index}`),
        remaining,
        data.quantity,
        start
      );
    });
  }

  private _createProgressAnimation(
    progressBar: HTMLElement,
    dataBox: HTMLElement,
    primaryData: number,
    total: number,
    start: boolean
  ): void {
    let width = 0;
    if (!start) {
      width = parseInt(dataBox.innerHTML, 10);
    } else {
      progressBar.style.width = 0 + '%';
      dataBox.innerHTML = '0';
    }
    const id = setInterval(frame, 10);

    function frame() {
      if (width >= primaryData) {
        clearInterval(id);
      } else {
        if (progressBar) {
          width++;
          progressBar.style.width = (width / total) * 100 + '%';
          dataBox.innerHTML = width.toString();
        } else {
          clearInterval(id);
        }
      }
    }
  }

  private _createRegressionAnimation(
    progressBar: HTMLElement,
    dataBox: HTMLElement,
    primaryData: number,
    total: number,
    start = false
  ): void {
    let width = total;
    if (!start) {
      width = parseInt(dataBox.innerHTML, 10);
    } else {
      progressBar.style.width = '100%';
      dataBox.innerHTML = total.toString();
    }
    const id = setInterval(frame, 10);

    function frame() {
      if (width <= primaryData) {
        clearInterval(id);
      } else {
        if (progressBar) {
          width--;
          progressBar.style.width = (width / total) * 100 + '%';
          dataBox.innerHTML = width.toString();
        } else {
          clearInterval(id);
        }
      }
    }
  }

  private _handleError(error) {
    this._errorHandlerService.handleError(error || { code: -400 }, 'jatakLive');
    this.isProcessing = false;
  }
}
