import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { DownLoadFile } from '../../shared/interface';

@Injectable({
  providedIn: 'root',
})
export class FileReaderService {
  // *** Reads file ***
  // in buffer for fileType: 'application/pdf
  // asDataURL for other fileTypes
  static readFile(file, type: string) {
    let readerObj;
    return new Observable((observer: Observer<object>) => {
      if (type === 'unsupported') {
        observer.error(file);
        observer.complete();
      } else {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          readerObj = {
            filesource: e.target.result,
            ready: true,
          };
          observer.next(readerObj);
          observer.complete();
        };
        if (type === 'application/pdf') {
          reader.readAsArrayBuffer(file);
        } else {
          reader.readAsDataURL(file);
        }
      }
    });
  }

  static dataUrlToFile(dataUrl, fileName) {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], fileName, { type: mime });
  }

  // *** Downloads the file ***
  // @params [downloadFile, filename]=> downloadFile.file contains the file
  static downloadFile(downloadFile: DownLoadFile, filename: string) {
    const url = window.URL.createObjectURL(downloadFile.file);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      link.remove();
    }, 100);
  }
}
