<section
  class="app-page list-component poll-list"
  [@parentAnimate]="showParent"
>
  <div class="fixed-header">
    <coop-pageheader
      [pageTitle]="pageTitle"
      [isChild]="true"
      [routeTo]="parentUrl"
    ></coop-pageheader>
    <div class="fixed-header-content">
      <ul class="tabs three-col">
        <li
          class="tab-first"
          (click)="tab($event, '1')"
          [class.active]="activeTab == '1'"
        >
          Aktiv
        </li>
        <li
          class="tab-second"
          (click)="tab($event, '2')"
          [class.active]="activeTab == '2'"
        >
          Fremtidige
        </li>
        <li
          class="tab-third"
          (click)="tab($event, '0')"
          [class.active]="activeTab == '0'"
        >
          Arkiv
        </li>
        <hr />
      </ul>
    </div>
  </div>
  <div class="page-container">
    <div class="item-list">
      <ng-container *ngFor="let poll of pollList">
        <a
          routerLink="{{ setPollEditUrl(poll.type, poll.id) }}"
          [queryParams]="{ origin: 'list' }"
          class="item"
        >
          <figure class="item-image">
            <img src="{{ poll.imageUrl }}" alt="" />
          </figure>
          <div class="item-detail">
            <p class="item-date">
              {{ poll.startDate | dateformat : true }} -
              {{ poll.endDate | dateformat : true }}
            </p>
            <h3 class="item-title">{{ poll.title }}</h3>
            <p>{{ poll.totalVoteCount }} besvarelser</p>
          </div>
          <div class="link-arrow">
            <i class="icon icon-arrow-right"></i>
          </div>
        </a>
      </ng-container>
      <h5 class="empty-message" *ngIf="pollList.length === 0">
        Beklager, ingen afstemninger tilgængelige
      </h5>
    </div>
    <a
      class="login-btn btn-white-login btn-medium"
      (click)="loadMore()"
      *ngIf="!(pollList.length < page * limit)"
      >Indlæs mere..</a
    >
  </div>
</section>
<router-outlet></router-outlet>
