import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  AppEventsService,
  AutologinService,
  ErrorhandlerService,
   NotificationsService, TokenService,
  UserService
} from "app/core/services";
import { switchMap, takeWhile } from "rxjs/operators";
import { EMPTY } from "rxjs";

@Component({
  selector: "coop-auto-login",
  templateUrl: "./auto-login.component.html",
  styleUrls: ["./auto-login.component.scss"]
})
export class AutoLoginComponent implements OnInit, OnDestroy {
  code: string;
  for: string;
  private _subscriptionState: boolean = true;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _autoLoginService: AutologinService,
    private _appEventsService: AppEventsService,
    private _errorHandlerService: ErrorhandlerService,
    private _userService: UserService
  ) {
  }

//*** initialize component */
//removeUserDetails() remove all the existing tokens in LocalStorage
//variable code is assigned with the the value of 'code' retireved through loginURL from admin dashboard
//variable for is assigned with the the value of 'for' retrieved through loginUrl from admin dashboard
//showLoader() to set the loader icon
//setLoaderMesg() to set loader message `Verificerende...`
//@params[code, for] => passed to verify code and for that returns token after being verified
//if userDetail is present => set it to localStorage
// else notify 'No userDetai is found','error', 'top' and redirect back to login
  ngOnInit() {
    this._userService.removeUserDetails(false);
    this.code = this._route.snapshot.queryParamMap.get("code");
    this.for = this._route.snapshot.queryParamMap.get("for");
    this._appEventsService.showLoader(true);
    this._appEventsService.setLoaderMsg(`Verificerende...`);
    this._autoLoginService
      .verifyToken(this.code, this.for)
      .pipe(
        takeWhile(() => this._subscriptionState),
        switchMap((res) => this._receiveLoginToken(res))
      )
      .subscribe({
        next: (userDetail) => {
          if (userDetail) {
            TokenService.setUserDetail(userDetail);
            this._router.navigate(["/"]).then();
          } else {
            NotificationsService.notify(
              "Ingen brugerdetaljer fundet",
              "error",
              "top"
            );
            this._router.navigate(["/login"]).then();
          }
        },
        error: (error) => this._handleError(error)
      });
  }
//** called to clean the component just before the instance of the component is destroyed */
//_subscriptionState is set to false to unsubscribe observable
// showLoader function set to false to avoid loading icon
//setLoaderMsg() is set to blank to clear the message
  ngOnDestroy() {
    this._subscriptionState = false;
    this._appEventsService.showLoader(false);
    this._appEventsService.setLoaderMsg("");
  }

  // *** Receives Login Tokens from ADFS or OAUTH urls and redirects to respective pages according to user types
  //@params[e] => is the tokenDetail received after verification from backend
  //if tokenDetail code is present => set message 'Something went wrong. Try again' and display

  //                    => if tokenDetail code === -99 then set message'Not a board member.Try again'
  //ErrorhandlerService sets message and redirect to login
  //return Empty
  //set tokenDetail and tokenDetail kardexId to localStorage
  // set true to session storage with keyword fetchUser
  //return userDetail
  private _receiveLoginToken(e) {

    if (e.code) {
      let message = "Noget gik galt. Prøv igen";
      if (e.code === -99) {
        message = "Ikke et bestyrelsesmedlem.\nPrøv igen";
      }
      ErrorhandlerService.error(message);
      this._router.navigate(["/login"]).then();// navigate to login page
      return EMPTY;
    }
    TokenService.setlocalstorage(e, e["kardexId"]);
    if (e['memberId']) {
      TokenService.setMemberId(e['memberId']);
    }
    sessionStorage.setItem("fetchUser", "true");
    return this._userService.getUserDetail();
  }

//*** handle errors */
//@params[error]=> error object
//navigate to login page
  private _handleError(error) {
    this._errorHandlerService.handleError(error);
    this._router.navigate(["/login"]).then();
  }

}
