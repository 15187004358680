<section class="app-page list-component list-page-with-search website-list">
  <div class="fixed-header">
    <coop-pageheader
      [pageTitle]="pageTitle"
      [isChild]="true"
      [routeTo]="parentUrl"
    ></coop-pageheader>
    <div class="fixed-header-content">
      <div class="search-box-container">
        <div class="search-box">
          <input
            type="text"
            placeholder="Søg"
            #searchBox
            (keyup.enter)="search(searchBox.value)"
          />
          <i
            class="icon-search search-button right-search"
            (click)="search(searchBox.value)"
          ></i>
          <i
            class="icon icon-close-circle search-button left-close"
            (click)="closeSearch()"
            *ngIf="inputInSearch"
          ></i>
        </div>
      </div>
    </div>
  </div>
  <div class="page-container">
    <div class="item-list">
      <ng-container *ngFor="let website of websiteList">
        <a routerLink="{{ currentUrl }}/redigere/{{ website.id }}" class="item">
          <figure class="item-image">
            <img src="{{ website.image }}" alt="" />
          </figure>
          <div class="item-detail">
            <h3 class="item-title">{{ website.title }}</h3>
            <p class="item-desc">
              {{ preparePreviewData(website.description) }}
            </p>
          </div>
          <div class="link-arrow">
            <i class="icon icon-arrow-right"></i>
          </div>
        </a>
      </ng-container>
      <h5 class="empty-message" *ngIf="websiteList.length == 0">
        Beklager, ingen website tilgængelige
      </h5>
    </div>
  </div>
  <a
    routerLink="{{ currentUrl }}/skab"
    class="big-add-btn add-btn flex-center"
    *ngIf="websiteList.length == 0"
  >
    <i class="icon icon-add"></i>
  </a>
</section>
<router-outlet></router-outlet>
