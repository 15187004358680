import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenService } from 'app/core/services';
// ***  Only Calendar Items are allowed from this routeGuard ***
// if type is any of these 'tilbud','nyheder', 'god-pris','arrangement','afstemninger','ja-tak','ja-tak-live','ok-screen','sms' and userType is 1 return true
//if type is any of these 'nyheder','arrangement','afstemninger','arsmode','special','forsamling-event','forsamling-poll' and userType is 2 i.e board return true
// navigate to the provided url
// Else false:   route url is `kalendar/${defaultType}`
@Injectable({
  providedIn: 'root'
})
export class KalendarGuard {
  constructor(private _router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const userType = TokenService.getUserType(); // to determine if its board or store
    const type = next.params['type'];
    const defaultType = userType === '1' ? 'ja-tak' : 'nyheder'; // set defaultType with 'tilbud' if userType is 1 else 'nyheder'
    const parentUrl = next.data['parentUrl'];


    if (type === 'redmad' && !TokenService.getUserDetail().scopes.quick2Go) {
      this._router.navigate(['/']).then();
      return false;
    }

    if (
      (type === 'tilbud' ||
        type === 'nyheder' ||
        type === 'god-pris' ||
        type === 'arrangement' ||
        type === 'afstemninger' ||
        type === 'ja-tak' ||
        type === 'ja-tak-live' ||
        type === 'ok-screen' ||
        type === 'redmad' ||
        type === 'sms') &&
      userType === '1'
    ) {
      return true;
    }
    if (
      (type === 'nyheder' ||
        type === 'arrangement' ||
        type === 'afstemninger' ||
        type === 'arsmode' ||
        type === 'special' ||
        type === 'forsamling-event' ||
        type === 'forsamling-poll') &&
      userType === '2'
    ) {
      return true;
    }

    this._router.navigate([`/${parentUrl}/kalendar/${defaultType}`]).then();
    return false;
  }

}

export const CheckIfKalendarAvailable: CanActivateFn = (next) => {
  return inject(KalendarGuard).canActivate(next);
};
