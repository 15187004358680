<div class="app-page okscreen-create-edit page-with-fixed-header-and-tab">
  <div class="fixed-header-bug-fix" id="fixed">
    <coop-pageheader [pageTitle]="pageTitle" [isChild]="true" [routeTo]="previousUrl"></coop-pageheader>
    <div class="fixed-header-content">
      <div class="tab-container">
        <div class="wrapper">
          <ul class="tab-list">
            <li class="tab-option active">OK skærme</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="page-container">
    <div class="form-container styled-two">
      <form [formGroup]="okscreenForm" (submit)="save()">
        <div class="wrapper">
          <coop-image-handler [imageUrl]="imageUrl" [canEdit]="false" [hasImages]="true" [singleCropper]="true"
                              [isRectangle]="true" [disableUpload]="true" (processing)="imageProcessing($event)"></coop-image-handler>
        </div>
        <div class="wrapper">
          <div class="row">
            <h2 class="form-subtitle">Skabelonen er tilgængelig <br> i det følgende tidsinterval</h2>
            <div class="col-two form-group">
              <label>Start dato</label>
              <div class="date-time-picker">
                <input id="publishcoop" formControlName="startDate" type="text" />
              </div>
            </div>
            <div class="col-two form-group">
              <label>Slut dato</label>
              <div class="date-time-picker">
                <input id="expirecoop" type="text" formControlName="endDate" />
              </div>
            </div>
          </div>
          <div class="row">
            <h2 class="form-subtitle">Planlæg køretiden <br> for denne skabelon</h2>
            <div formArrayName="displaySlots" class="display-slot-lists" [@list]>
              <div *ngFor="
                                  let slot of okscreenForm.controls[
                                      'displaySlots'
                                  ]['controls'];
                                  let i = index
                              " class="slot" [@items]>
                <div class="row form-group" [formGroupName]="i">
                  <label>Tidspinkt {{ i + 1 }}</label>
                  <div class="picker-flex" [class.picker-flex-two]="
                                          numberOfSlots === 1
                                      ">
                    <div class="date-time-picker slot-item" controlErrorContainer>
                      <label>Fra</label>
                      <input [min]="minDate" [max]="maxDate"

                             [id]="'date-' + i"
                             mbsc-datepicker
                             [mbscOptions]="datePickerSettings"
                             formControlName="startDate" type="text" [checkParent]="true" [customErrors]="validationMessages['startDate']"/>
                    </div>
                    <div class="date-time-picker slot-item" controlErrorContainer>
                      <label>Til</label>
                      <input [max]="maxDate" [min]="minDate" type="text" [id]="i"
                             mbsc-datepicker
                             [mbscOptions]="datePickerSettings"
                             formControlName="endDate" [checkParent]="true" [customErrors]="validationMessages['endDate']"/>
                    </div>
                    <div class="remove-slot slot-item" *ngIf="numberOfSlots > 1">
                      <label>Slet</label>
                      <span (click)="removeSlot(i)">
                        <i class="ti-trash"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <span (click)="onAddSlot()" class="add-slot-btn"><i class="icon-plus"></i>
                <span>Tilføj nyt slot</span></span>
            </div>
          </div>

          <div class="row form-submit-field">
            <input type="submit" value="Aktivér" class="login-btn btn-black-login" />
          </div>
          <div class="row" *ngIf="!templateMode">
            <a (click)="onDelete()" class="login-btn btn-white-login ">Slet</a>
          </div>
          <div class="row">
            <a (click)="back()" class="login-btn btn-white-login ">Tilbage</a>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
