import { ImageUrl } from 'app/shared/interface';

export interface PollList {
  id: string;
  title: string;
  imageUrl: string;
  startDate: string;
  endDate: string;
  totalVoteCount: string;
  state?: string;
  type: string;
}

export interface PollTemplates {
  Id: string;
  ImageUrl: string;
  Title: string;
  Description: string;
}

export interface Poll {
  id: string;
  kardexId: string;
  imageUrl: ImageUrl;
  title: string;
  description: string;
  startDate: any;
  endDate: any;
  isMultiple: boolean;
  isEditable: boolean;
  fromBoard: string;
  parameters?: any;
  answers: Answer[];
  totalVoteCount: number;
  web: number | boolean;
  userType?: string;
}

export interface Answer {
  id: number;
  answer: string;
  count: string;
  percentage: string;
  shade: string;
  votes: string;
}

export interface Template {
  Id: string;
  ImageUrl: string;
  Title: string;
  Description: string;
  StartDate: string;
  EndDate: string;
  IsMultiple: boolean;
  IsEditable: boolean;
  Answers: Answer[];
}

export interface SpecialEvent {
  id: string;
  title: string;
  expirationDate: string;
}

export interface SpecialPoll {
  id: string;
  kardexId: string;
  imageUrl: string;
  title: string;
  description: string;
  startDate: any;
  endDate: any;
  answers: Answer[];
  electionEntries: string;
  eventId: string;
  memberForMonth: string;
  active: number;
  possibleVotes: number;
  totalVotes: number;
  started: number;
  ended: number;
  requirements: string;
  memberId?: string;
  parentId?: string;
  isParent : boolean
}
