import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { NyhedTilbud, OfferFormComponent } from 'app/features/offer';

@Component({
  selector: 'coop-offer-edit',
  templateUrl: './offer-edit.component.html',
  styleUrls: ['./offer-edit.component.scss'],
})
export class OfferEditComponent implements OnInit {
  @ViewChild(OfferFormComponent, { static: true })
  offerFormComp: OfferFormComponent;
  formData?: NyhedTilbud;
  constructor(private _activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.formData = this._activatedRoute.snapshot.data['offer'];
  }
}
