import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router, UrlSegment } from '@angular/router';
import { combineLatest } from 'rxjs';
import { finalize, take } from 'rxjs/operators';

import { ErrorhandlerService } from 'app/core/services';
import { Jatak, JatakFormComponent } from 'app/features/jatak';
import {JatakDefault} from "../../../../shared/interface";

@Component({
  selector: 'coop-jatak-edit',
  templateUrl: './jatak-edit.component.html',
  styleUrls: ['./jatak-edit.component.scss'],
})
export class JatakEditComponent implements OnInit {
  // *** JatakFormComponent required in canDeactivate guard ***
  @ViewChild(JatakFormComponent, { static: true })
  jatakFormComponent: JatakFormComponent;
  formData: Jatak;
  jatakDefault :JatakDefault
  previousUrl: string;
  currentUrl: string;
  successUrl: string;
  offerId: string; // editId + templateId
  parentUrl: string;
  origin: string;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _errorHandlerService: ErrorhandlerService,
    private _router: Router
  ) {}
// *** It gets the formData from the resolver to pass to child
  ngOnInit() {
    this.formData = this._activatedRoute.snapshot.data['jatak'];
    this.jatakDefault = this._activatedRoute.snapshot.data["data"];
    this.origin = this._activatedRoute.snapshot.queryParams['origin'];
    this._setUrls();
  }
// *** It sets previousUrl and successUrl for coop-jatak-form ***
  private _setUrls() {
    this.currentUrl = this._router.url;
    this.previousUrl = this.currentUrl.replace('ja-tak/skab', '');
    this.parentUrl = this._activatedRoute.snapshot.parent.data['parentUrl'];
    this.successUrl = this.parentUrl + '/kalendar/ja-tak';
    combineLatest([
      this._activatedRoute.url,
      this._activatedRoute.parent.params,
    ])
      .pipe(
        take(1),
        finalize(() => {
          // need to change this if added for bestyrelse
          if (this._activatedRoute.children.length > 0) {
            this.currentUrl = '/butikker/ja-tak/redigere/' + this.offerId;
          }
          // setting previousUrl based on the mode
          if (this.origin === 'list') {
            this.successUrl = this.currentUrl.replace(
              'redigere/' + this.offerId + '?origin=list',
              ''
            );
            this.previousUrl = this.successUrl;
          }
          // if(this.origin === 'duplicate' ){
          //   this.successUrl = this.currentUrl.replace(
          //     'create/' + this.offerId + '?origin=duplicate',''
          //   )
          //   this.previousUrl= this.successUrl
          // }
          if (this.origin !== 'list') {
            this.previousUrl = '/';
          }
        })
      )
      .subscribe({
        next: (value: [UrlSegment[], Params]) => this._setOfferId(value[0]),
        error: (err) => this._errorHandlerService.handleError(err),
      });
  }
// *** It sets this.offerId using urlsegments for coop-jatak-form ***
  // @params [urlSegment: UrlSegment] => array of urlsegments
  // Either this.offerId = 'skabeloner' // for new jatak form
  // Or     this.offerId = offer_id     // for edit form
  private _setOfferId(urlSegment) {
    // setting temp id
    if (urlSegment.length > 1) {
      this.offerId = urlSegment[1].path;
    } else {
      this.offerId = urlSegment[0].path;
    }
  }
}
