<div class="graphs ">

  <div class="graph-title">
    Lykkeposer
  </div>

  <div class="list">

    <div *ngFor="let item of sortList" (click)="onFilter(item)" class="list-item">
      <div >
        <i *ngIf="!isIncludes(item)"
           [class.total]="item.id === 1"
           [class.pickedup]="item.id === 2"
           [class.notpickedup]="item.id === 3"
           class="icon icon-stop"></i>
        <i *ngIf="isIncludes(item)"
           [class.total]="item.id === 1"
           [class.pickedup]="item.id === 2"
           [class.notpickedup]="item.id === 3"
           class="icon icon-tick-square-filled"></i>
      </div>
      <div [class.total]="item.id === 1" [class.pickedup]="item.id === 2" [class.notpickedup]="item.id === 3">
        {{ item.name }}
      </div>
    </div>

  </div>

  <div class="graph-container">
    <canvas #lineCanvas style="position: relative; height:50vh; width:40vw"></canvas>
  </div>
</div>

