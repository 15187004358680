<section class="app-page print-range-select not-print">
  <div class="fixed-header">
    <div class="app-header">
      <div class="wrapper">
        <h1 class="page-title">Print</h1>
        <a class="back-btn not-print" (click)="hideRangeSelect()">
          <i class="icon icon-arrow-left"></i>
        </a>
      </div>
    </div>
  </div>
  <div class="page-container">
    <div class="form-container">
      <form [formGroup]="dateRangeSelectForm">
        <div class="row form-group">
          <p class="date-picker-title">Fra</p>
          <div class="date-picker">
            <mbsc-datepicker
              [options]="datePickerSettings"
              id="start-date"
              formControlName="startDate"
            >
              Fra
            </mbsc-datepicker>

          </div>
        </div>
        <div class="row form-group">
          <p class="date-picker-title">Til</p>
          <div class="date-picker">
            <mbsc-datepicker
              [options]="datePickerSettings"
              id="end-date"
              formControlName="endDate"
            >
              Til
            </mbsc-datepicker>
          </div>
        </div>
        <div class="wrapper">
          <div class="row">
            <button class="login-btn btn-black-login" (click)="print()">
              <i class="ti-printer"></i>Print ordre
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
