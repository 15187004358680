<ion-app>
  <ion-content>
    <router-outlet></router-outlet>

    <ng-container *ngIf="showPopOver">
      <div class="overlay" id="overlay"></div>
      <div class="popover" id="popover">
        <i class="icon icon-info-circle"></i>
        <h2>Tilslut din Facebook-side igen</h2>
        <p>
          For at forhindre problemer med JA TAK på Facebook skal du:
        </p>
        <div>
          <ul>
            <li>Gå til “Indstillinger”</li>
            <li>Afbryd forbindelsen til din Facebook-side.</li>
            <li> Tilslut din Facebook-side igen</li>
          </ul>
        </div>

        <p style="margin-top: 10px">
          Når du har gjort dette, vil denne påmindelse komme op igen efter 3 måneder.
        </p>

        <div class="confirmsection" (click)="onNavigate()">
          OK, tilslut min Facebook-side igen
        </div>

      </div>
    </ng-container>

    <coop-loading></coop-loading>

  </ion-content>
</ion-app>
