import { inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { NotificationsService } from 'app/core/services';
import { OkScreenCreateEditComponent } from 'app/features/ok-screen';
import { CanDeactivateFn } from '@angular/router';

// *** It reminds user of form not being saved if pressed back ***
@Injectable({
  providedIn: 'root'
})
export class OkscreenGuardService {
  canDeactivate(
    component: OkScreenCreateEditComponent
  ): Observable<boolean> | boolean {
    const displaySlots =
      component.okscreenForm.controls['displaySlots'].value.length;
    const formSaved = component.formSaved;
    if (displaySlots > 0 && !formSaved) {
      return NotificationsService.confirm(
        `Dine ændringer vil ikke blive gemt.`
      );
    }
    return true;
  }
}

export const OkscreenGuard: CanDeactivateFn<OkScreenCreateEditComponent> = (component) => {
  return inject(OkscreenGuardService).canDeactivate(component);
};
