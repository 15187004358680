<section
  class="fb-container"
  *ngIf="showLogin"
  [@fadeInOutAnimation]="showLogin"
>
  <div class="step fb-confirmation" *ngIf="!pages.length">
    <figure>
      <img
        src="assets/images/facebook-fan-page@2x.jpg"
        alt="Facebook Fan Page"
      />
    </figure>
    <div class="message" *ngIf="pageResponse">
      <h2>Hej</h2>
      <p>
        Venligst tilslut din Facebook for at kunne dele dine promoveringer på
        din Facebookside.
      </p>
    </div>
    <div class="message" *ngIf="!pageResponse">
      <h2>Fejl</h2>
      <p>{{ title }}</p>
    </div>
    <a (click)="pageLogin()" class="btn btn-blue text-wrap" [class.loading]="!fbloginurl">
      <i class="icon icon-facebook"></i>
      <p>Forbinde</p>
    </a>
    <a (click)="closeFbLogin()" class="close"><i class="icon icon-close-circle"></i></a>
  </div>
  <div class="step fbprofile-list" *ngIf="pages.length">
    <h4><i class="icon icon-facebook"></i>&nbsp;{{ title }}</h4>
    <div
      class="fb-profile"
      *ngFor="let page of pages"
      (click)="selectPage(page)"
    >
      <div class="profile-image">
        <img
          src="{{ page.picture.data.url }}"
          alt="{{ page.name }}-page-image"
        />
      </div>
      <div class="profile-detail">
        <h3>{{ page.name }}</h3>
      </div>
    </div>
    <div class="fb-list-nav clearfix">
      <div *ngIf="prevList" (click)="loadMorePages('prev')" class="prev">
        <i class="icon icon-arrow-left"></i>&nbsp;Arkiv
      </div>
      <div *ngIf="nextList" (click)="loadMorePages('next')" class="next">
        Næste&nbsp;<i class="icon icon-arrow-right"></i>
      </div>
    </div>
    <a (click)="closeFbLogin()" class="close"><i class="icon icon-close-circle"></i></a>
  </div>
</section>
