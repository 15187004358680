import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberpad',
})
export class NumberPad implements PipeTransform {
  //** transforms value to have two digits at the end
  //@params[value, language] value to be transformed and language standard to be followed
  transform(value: number, language?: string): string {
    return value.toLocaleString(language, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
}

@Pipe({
  name: 'leftpad',
})
export class LeftPad implements PipeTransform {
  //** transform the value to have '0' in left position
  //@params[str , max] value to be transformed and value to be compared against
  //if str length is less than max return str with 0 at the front else return str
  transform(str: string, max): string {
    str = str.toString(); //convert to string
    return str.length < max ? this.transform('0' + str, max) : str;
  }
}
@Pipe({
  name: 'kformatter',
})
export class KFormatter implements PipeTransform {
  //**transform provided value to given format
  //@params[num] value to be transformed
  //return the transformed value according to the matched condition
  transform(num: number): any {
    return num > 999 ? (num / 1000).toFixed(1) + 'k' : num;
  }
}
@Pipe({
  name: 'phoneNumber',
})
export class PhoneNumber implements PipeTransform {
  //** transform provided value to the given format
  //@params[value] value to be transformed
  //return the transformed value
  transform(value: number): any {
    return value
      .toString()//method returns a string representing the specified Number object
      .trim() //removes whitespace from both ends of a string.
      .match(/.{1,2}/g) //retrieves the result of matching a string against a regular expression
      .join(' '); //creates and returns a new string by concatenating all of the elements in an array (or an array-like object ), separated by commas or a specified separator string.
  }
}


@Pipe({
  name:'formatNumber'
})
export class FormatNumberPipe implements PipeTransform {

   transform(value: any): any {
      if(value){
        if (typeof value !== 'string') {
          value = value.toString();
        }
        // delete existing spaces
        while ((value as string).indexOf(' ') !== -1) {
          value = (value as string).replace(' ', '');
        }
        let integerPart: string = value;
        // if (value.indexOf('.') !== -1) {
        //   integerPart = value.slice(0, value.indexOf('.'));
        // }
        // if (value.indexOf(',') !== -1) {
        //   integerPart = value.slice(0, value.indexOf(','));
        // }
        let firstSlice = true;
        const arrayResults: Array<string> = [];
        let finalResult = '';

        const divisor = 2;
        const dividend: number = integerPart.length;
        let remainder = dividend % divisor;
        let quotient = (dividend + remainder) / divisor;
       if(dividend >= 2){
         do {
           if (firstSlice && remainder > 0) {
             // Manage numbers with remainders
             firstSlice = false;
             arrayResults.push(integerPart.slice(0, remainder));
           } else {
             // Slice each part of the number to an array
             firstSlice = false;
             arrayResults.push(integerPart.slice(remainder, remainder + divisor));
             remainder = remainder + divisor;
             quotient--;
           }
           // Continue dividing the number while there are values
         } while (quotient >= 1);

         arrayResults.forEach(part => {
           finalResult += `${part} `;
         });
         // Delete any trailing whitespace
         finalResult = finalResult.trim();
         return finalResult;
       } else {
         return value
       }
      }
      return value
   }

}
