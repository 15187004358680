import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivateFn, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { NotificationsService } from 'app/core/services';
import {
  GoodPriceCreateComponent,
  GoodPriceEditComponent,
  OfferCreateComponent,
  OfferEditComponent,
  OfferTemplateComponent,
  RecurringOfferComponent
} from 'app/features/offer';
// ***  It runs before navigating away from offer(create/edit) or goodPrice(create/edit) or templates  ***
// Checks whether the formData has been altered and pressed back instead of saving
// @returns boolean =>  true:    Route is deactivated
//                      false:   Current route is not changed
@Injectable({
  providedIn: 'root'
})
export class OfferGuardService {
  constructor() {
  }

  canDeactivate(
    component:
      | OfferCreateComponent
      | OfferEditComponent
      | GoodPriceCreateComponent
      | GoodPriceEditComponent
      | OfferTemplateComponent
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const validTab = component.offerFormComp.validateModes[0];
    const firstValidForm = component.offerFormComp.promoForm.controls[validTab];
    const dirty = firstValidForm.dirty;
    if (dirty && !component.offerFormComp.formSaved) {
      return NotificationsService.confirm(
        `Dine ændringer vil ikke blive gemt.`
      );
    }
    return true;
  }
}

export const OfferGuard: CanDeactivateFn<OfferCreateComponent | OfferEditComponent | GoodPriceCreateComponent | GoodPriceEditComponent | OfferTemplateComponent> = (component) => {
  return inject(OfferGuardService).canDeactivate(component);
};


// ***  It runs before navigating away from RecurringOfferComponent  ***
// Checks whether the formData has been altered and pressed back instead of saving
// @returns boolean =>  true:    Route is deactivated
//                      false:   Current route is not changed
@Injectable({
  providedIn: 'root'
})
export class RecurringOfferGuardService {
  constructor() {
  }

  canDeactivate(
    component: RecurringOfferComponent
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const form = component.promoForm;
    const dirty = form.dirty;
    if (dirty && !component.formSaved) {
      return NotificationsService.confirm(
        `Dine ændringer vil ikke blive gemt.`
      );
    }
    return true;
  }
}

export const RecurringOfferGuard: CanDeactivateFn<RecurringOfferComponent> = (component) => {
  return inject(RecurringOfferGuardService).canDeactivate(component);
};
