export interface Website {
  id: string;
  title: string;
  description: string;
  image: string;
  board: number;
}

export interface WebsiteList {
  board: boolean;
  description: string;
  id: string;
  image: string;
  kardex: string;
  title: string;
}
