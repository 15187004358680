import { inject, Injectable } from '@angular/core';
import { CanActivateFn, CanDeactivateFn, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { NotificationsService, TokenService } from 'app/core/services';
import { AssemblyPollCreateEditComponent } from 'app/features/poll';

@Injectable({
  providedIn: 'root'
})
export class AssemblyPollGuard {
  constructor(private _router: Router) {
  }

  canDeactivate(
    component: AssemblyPollCreateEditComponent
  ): Observable<boolean> | boolean {
    if (component.assemblyPollForm.dirty && !component.formSaved) {
      const pollTitle =
        component.assemblyPollForm.get('title').value || 'Ny afstemning';
      return NotificationsService.confirm(
        `Dine ændringer til ${pollTitle} bliver ikke gemt.`
      );
    }
    return true;
  }

  canActivate(): boolean {
    if (TokenService.getUserType() === '1') {
      this._router.navigate(['']).then();
      return false;
    }
    return true;
  }
}

export const AssemnlyPollCanActivate: CanActivateFn = () => {
  return inject(AssemblyPollGuard).canActivate();
};

export const AssemblyPollCanDeactivate: CanDeactivateFn<AssemblyPollCreateEditComponent> = (component) => {
  return inject(AssemblyPollGuard).canDeactivate(component);
};
