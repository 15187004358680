import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { catchError } from 'rxjs/operators';

import {
  ApiService,
  ErrorhandlerService,
  TokenService,
  UserService,
} from 'app/core/services';
import { Promo } from 'app/features/offer/interface';
import { TemplateService } from 'app/features/offer/services';
import { TemplateFolderData, TemplateListData } from 'app/shared/interface';

@Injectable({
  providedIn: 'root',
})
export class OfferTemplateResolver  {
  constructor(
    private _apiService: ApiService,
    private _templateService: TemplateService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this._templateService
      .getSingleTemplate(route.params['templateId'])
      .pipe(
        catchError((err) => this._errorHandlerService.routeResolverError(err))
      );
  }
}

@Injectable({
  providedIn: 'root',
})
export class OfferTemplateFolderResolver
  
{
  constructor(
    private _apiService: ApiService,
    private _templateService: TemplateService,
    private _userService: UserService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    const newsMode = route.data['modes']['newsMode'];
    const goodPriceMode = route.data['modes']['goodPriceMode'];
    return this._templateService
      .getTemplateFolder(newsMode, goodPriceMode)
      .pipe(
        catchError((err) => this._errorHandlerService.routeResolverError(err))
      );
  }
}

@Injectable({
  providedIn: 'root',
})
export class OfferTemplateListResolver  {
  constructor(
    private _apiService: ApiService,
    private _templateService: TemplateService,
    private _userService: UserService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    const newsMode = route.data['modes']['newsMode'];
    const goodPriceMode = route.data['modes']['goodPriceMode'];
    if (route.params['folderId'] === 'egne') {
      return this._templateService.getTemplates(
        'true',
        'false',
        '0',
        1,
        10,
        newsMode,
        goodPriceMode
      );
    } else if (route.params['folderId'] === 'store') {
      return this._templateService.getTemplates(
        'false',
        '1',
        '',
        1,
        10,
        newsMode,
        goodPriceMode
      );
    } else {
      return this._templateService.getTemplates(
        'false',
        'false',
        route.params['folderId'],
        1,
        10,
        newsMode,
        goodPriceMode
      );
    }
  }
}
