<div
  #anchor_head
  *ngIf="direction === 'up'"
  class="scroll-trigger-head"
  [class.not-in-viewport]="!ready"
>
  <div class="processing" *ngIf="isLoading"></div>
</div>
<ng-content> </ng-content>
<div #anchor_tail class="scroll-trigger-tail"></div>
