import {
  animate,
  style,
  transition,
  trigger,
  state,
} from '@angular/animations';

//*** fadeInOut transition Animation ***//
// created animation variable 'fadInOut' with trigger 'fadeInOut'
// trigger have two state => visible and invisible defining the state to call at the end of each transition
// visible state is set with the style position:fixed and opacity 1 along with z-index:1000(ideally avoid conflict set to 1000)
//invisible state is set with the style position:absolute and opacity 0(transparent)
//transition defines the direction of state flow both ways(visible <=> invisible) &
//animate() => transition delay to 400ms and ease of transition to ease-in-out
export const fadeInOut = [
  trigger('fadeInOut', [
    state(
      'visible',
      style({
        position: 'fixed',
        opacity: 1,
        zIndex: 1000,
      })
    ),
    state(
      'invisible',
      style({
        opacity: 0,
        position: 'absolute',
      })
    ),
    transition('visible <=> invisible', animate('400ms ease-in-out')),
  ]),
];

//created animation variable 'notification' with trigger 'slideUpDown'
// trigger have two state => visible and invisible defining the state to call at the end of each transition
//visible state is styled with opacity 1 . 
//invisible state is styled with opacity 0.
// transition flows both ways visible <=> invisible wtih 400ms transition time and ease of transition to ease-in-out
export const notification = [
  trigger('slideUpDown', [
    state(
      'visible',
      style({
        opacity: 1,
      })
    ),
    state(
      'invisible',
      style({
        opacity: 0,
      })
    ),
    transition('visible <=> invisible', animate('400ms ease-in-out')),
  ]),
];

//*** Animation for fadeIn ***//
// created animation variable 'fadeIn' with trigger 'fade'
// 'in' state is defined with style opacity:1
// :enter transition when element enter in the DOM => set style to opacity 0 and set transition time of 200ms 
// :leave transition when element leave from the DOM => set style to opacity 0 and set transition time of 400ms
export const fadeIn = trigger('fade', [
  state('in', style({ opacity: 1 })),
  transition(':enter', [style({ opacity: 0 }), animate(200)]),
  transition(':leave', animate(400, style({ opacity: 0 }))),
]);
