<section class="page-not-found">
  <div class="wrapper">
    <div class="message">
      <p>Advarsel !!!</p>
      <h1>404</h1>
      <p>{{ message }}</p>
      <a routerLink="/" class="login-btn btn-black-login">Gå hjem</a>
    </div>
  </div>
</section>
