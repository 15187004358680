import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {
  AppEventApiService,
  AppEventsService,
  CreateOptionDataService,
  ErrorhandlerService, TokenService
} from 'app/core/services';
import {takeWhile} from "rxjs/operators";
import {CreateOption, Notice} from "../../interface";
import {JatakList, JatakService} from 'app/features/jatak';

@Component({
  selector: 'app-home-dashboard',
  templateUrl: './home-dashboard.component.html',
  styleUrls: ['./home-dashboard.component.scss'],
})
export class HomeDashboardComponent implements OnInit {
  alert: Notice;
  pageTitle: string = 'QuickCoop';
  imageLoaded = false;
  jatakList: JatakList[]
  selectedOption: CreateOption;
  userType: string;
  totalActiveJatak :number;
  private _subscriptionState: boolean = true;
  alertUpdateDate:string;
  constructor(
    private _router: Router,
    private _appApiService: AppEventApiService,
    private _jatakService: JatakService,
    private _createOptionService: CreateOptionDataService,
    private _errorHandlerService: ErrorhandlerService,
  ) {
  }

  ngOnInit() {
    this.userType = TokenService.getUserType();
    this.alertUpdateDate = localStorage.getItem('alert')
    this._appApiService.getNotice()
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (data) => {
          this.alert = data;
          if(this.alertUpdateDate && this.alertUpdateDate === this.alert.updated_at){
            this.alert = null;
          }
        }
      });
    this.selectedOption = {
      mode: 'skab',
      type: 'ja-tak',
      share: {
        coop: true,
        facebook: true,
        sms: false,
        mail: false,
        web: false,
        screen: false,
        dit: false
      },
      //if currentCalendarType is present set step to 3 else 1
      step: 1
    };
    if (this.userType === '1') {
      this.getActiveJatak()
    }
  }

  closeAlert(){
    localStorage.setItem('alert', this.alert.updated_at);
    this.alert = null;
  }
  private getActiveJatak() {
    this._jatakService.getJatakListing(1, 4, '1', 1).subscribe({
      next: (res) => {
        this.totalActiveJatak = res['meta']['total'];
        this.jatakList = res['data']
      },
      error: (err) => {
        this._errorHandlerService.handleError(err)

      }
    })
  }


  createJatak() {
    this.selectedOption.isSet = true;
    this._createOptionService.setCreateOption(this.selectedOption);
    this._router.navigate(['/butikker/ja-tak/skab']).then()
  }
  viewAllActiveJatak(){
    this._router.navigate(['/butikker/campaign'],{queryParams: {type: 'jatak'}}).then()
  }
  viewJatakDetail(id:number){
    this._router.navigate([`butikker/ja-tak/redigere/${id}`], {queryParams: {origin: 'list'}}).then()
  }

  onImageLoad(event: Event) {
    this.imageLoaded = true;
  }

  navigate(type: string) {
    if (this.userType === '1') {
      this._router.navigate(['/butikker/skab'], {queryParams: {type: `${type}`}}).then()
    } else {
      this._router.navigate(['/bestyrelse/skab'], {queryParams: {type: `${type}`}}).then()
    }

  }

}
