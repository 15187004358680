import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { EMPTY, throwError } from 'rxjs';
import { mergeMap, takeWhile } from 'rxjs/operators';

import {
  ErrorhandlerService,
  NotificationsService,
  SortService,
  TokenService,
} from 'app/core/services';
import { FacebookEventsService } from 'app/features/facebookconnect/services';
import { JatakService } from 'app/features/jatak/services';
import { animateParent, slideAnimation } from 'app/shared/animations';
import { JatakLiveList, JatakLiveService } from 'app/features/jatak-live';

@Component({
  selector: 'app-jatak-live-list',
  templateUrl: './jatak-live-list.component.html',
  styleUrls: ['./jatak-live-list.component.scss'],
  animations: [animateParent, slideAnimation],
})
export class JatakLiveListComponent implements OnInit, OnDestroy {
  showParent: string = 'visible';
  pageTitle: string = 'Ja Tak Live';
  activeTab: string = '1';

  page: number = 1;
  limit: number = 10;

  jatakLiveList: JatakLiveList[];
  allJatakLive: JatakLiveList[];

  currentUrl: string;
  parentUrl: string;
  private _subscriptionState = true;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _title: Title,
    private _sortService: SortService,
    private _router: Router,
    private _errorHandlerService: ErrorhandlerService,
    private _jatakLiveService: JatakLiveService,
    private _facebookEvents: FacebookEventsService,
    private _jatakService: JatakService
  ) {}

  ngOnInit() {
    this._initComp();
    this.setPreviousUrl();
    this._router.events
      .pipe(
        takeWhile(() => this._subscriptionState),
        mergeMap((routerEvent) => {
          if (routerEvent instanceof NavigationEnd) {
            return this._getAll(true, this.activeTab);
          } else {
            return EMPTY;
          }
        })
      )
      .subscribe({
        next: (posts) => this.updateList(posts, this.activeTab),
        error: (err) => this._errorHandlerService.handleError(err),
      });
  }

  ngOnDestroy(): void {
    this._subscriptionState = false;
  }

  // get more post and concat it with the previous posts
  loadMore() {
    this.page++;
    this._getAll(true, this.activeTab)
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (posts) => {
          if (posts.length === 0) {
            NotificationsService.notify('Ingen flere tilbud', 'error', 'top');
          } else {
            this.jatakLiveList = this.allJatakLive.concat(posts);
            this.allJatakLive = this.jatakLiveList;
          }
        },
        error: (err) => this._errorHandlerService.handleError(err),
      });
  }

  navigateToCreate() {
    this.fbSelect();
  }

  fbSelect() {
    const facebookConnected = TokenService.getUserDetail().facebook.connected;
    if (!facebookConnected) {
      NotificationsService.notify(
        'Opret forbindelse til facebook',
        'error',
        'top'
      );
      this._facebookEvents.connectFacebook();
      return;
    }
    this._jatakService
      .getFbTokenStatus()
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (res) => {
          if (res) {
            this._router
              .navigate([`/${this.currentUrl}/skab`], {
                queryParams: { origin: 'list' },
              })
              .then();
          } else {
            NotificationsService.notify(
              'Ugyldigt Facebook Token.<br>Forbind siden igen',
              'error',
              'top'
            );
            this._facebookEvents.connectFacebook();
          }
        },
        error: (err) => this._errorHandlerService.handleError(err),
      });
  }

  private _initComp() {
    this._title.setTitle('Ja Tak Live - QuickCoop');
    this.jatakLiveList = this.allJatakLive =
      this._activatedRoute.snapshot.data['jatakLiveList'];
    this._checkChildPage();
    this.currentUrl = this._router.url;
  }

  // changing the tab 'active 1', 'future 2', 'past 0'
  tab(value: string, event): void {
    if (event && this.activeTab !== value) {
      // re setting the page from 1 for other tabs
      this.page = 1;
      this._getAll(true, value)
        .pipe(takeWhile(() => this._subscriptionState))
        .subscribe({
          next: (posts) => this.updateList(posts, value),
          error: (err) => this._errorHandlerService.handleError(err),
        });
    }
  }

  // updating the fbcomment list and tab after new posts arrive
  updateList(posts, value) {
    this.jatakLiveList = this.allJatakLive = posts;
    this.activeTab = value;
  }

  setPreviousUrl() {
    const userType = TokenService.getUserType();
    if (userType === '1') {
      this.parentUrl = '/butikker/indstillinger';
    }
    if (userType === '2') {
      this.parentUrl = '/bestyrelse/indstillinger';
    }
  }

  navigateTo(type: string, id: string, event) {
    event.stopPropagation();
    this._router
      .navigate([`${this.currentUrl}/${type}/${id}`], {
        queryParams: { origin: 'list' },
      })
      .then();
  }

  onDelete(id: string, event): void {
    event.stopPropagation();
    NotificationsService.confirmWithCallback('Slette kampagne?', (accept) => {
      if (accept) {
        this._jatakLiveService
          .deleteJatakLive(id)
          .pipe(
            takeWhile(() => this._subscriptionState),
            mergeMap(() => this._getAll(true, this.activeTab))
          )
          .subscribe({
            next: (posts) => this.updateList(posts, this.activeTab),
            error: (err) =>
              this._errorHandlerService.handleError(
                err || { code: -400 },
                'jatakLive'
              ),
          });
      }
      if (!accept) {
        return;
      }
    });
  }

  private _getAll(state: boolean, value) {
    if (state) {
      return this._jatakLiveService.getJatakLiveListing(this.page, 10, value);
    }
    return throwError(() => 'Error');
  }

  // Check if the route is child route or parent route
  // if child route set isParentPage as false
  private _checkChildPage(): void {
    this.showParent = this._activatedRoute.children.length
      ? 'invisible'
      : 'visible';
  }
}
