import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {EMPTY, fromEvent, Subscription, throwError} from 'rxjs';
import {debounceTime, mergeMap, takeWhile} from 'rxjs/operators';

import {
  CreateOptionDataService,
  ErrorhandlerService,
  NotificationsService,
  SortService,
  TokenService
} from 'app/core/services';
import {animateParent, slideAnimation} from 'app/shared/animations';
import {JatakService} from 'app/features/jatak';
import {CreateOption} from '../../../../shared/interface';
import {Store} from '@ngxs/store';
import {SetJatakRouteStates, SetOrderListCompState, SetOrderListState} from '../../states';

@Component({
  selector: 'coop-jatak-list',
  templateUrl: './jatak-list.component.html',
  styleUrls: ['./jatak-list.component.scss'],
  animations: [animateParent, slideAnimation]
})
export class JatakListComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  @ViewChild('searchBox') searchBox;
  pAnimate: string = 'inactive';
  showParent: string = 'visible';
  pageTitle: string = 'Ja Tak';
  activeTab: string = '1';

  page: number = 1;
  limit: number = 10;
  last_page: number;

  jatak: any;
  jatakList: any;
  allJataks: any;

  inputInSearch: boolean = false;
  searchString = '';
  currentSearchString: string = '';

  editUrl: string;
  section: string = 'orderlist';
  currentUrl: string;
  parentUrl: string;
  showSortOptions: boolean = false;
  showButtonOptionsById: number = 0;
  sortBy = 1;
  showRecent: boolean = true;
  showTurnOver: boolean = false;
  showMostSold: boolean = false;
  scroller: Subscription;
  showFilterAndAdd: boolean = true;
  selectedOption: CreateOption;
  config = {
    init: true,
    slidesPerView: 3,
    slidesPerGroup: 3,
    keyboard: true,
    mousewheel: true,
    pagination: false,
    navigation: false,
    loop: true
  };

  private _subscriptionState = true;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _title: Title,
    private _sortService: SortService,
    private _router: Router,
    private _errorHandlerService: ErrorhandlerService,
    private _jatakService: JatakService,
    private _createOptionService: CreateOptionDataService,
    private _store: Store
  ) {
  }

  ngOnInit() {
    this._initComp();
    this.setPreviousUrl();
    this._createOptionService.currentSetting
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (setting: any) => {
          if (setting) {
            this.selectedOption = setting;
          }
        },
        error: (err: any) => this._errorHandlerService.handleError(err)
      });
    this._router.events
      .pipe(
        takeWhile(() => this._subscriptionState),
        mergeMap((routerEvent) => {
          if (routerEvent instanceof NavigationEnd) {
            return this._getAll(true, this.activeTab, this.sortBy, this.searchString);
          } else {
            return EMPTY;
          }
        })
      )
      .subscribe({
        next: (posts) => {
          this.updateList(posts, this.activeTab);
        },
        error: (err) => this._errorHandlerService.handleError(err)
      });
    ;
  }

  ngAfterViewInit() {
    this.initSwipers();

  }

  ngAfterViewChecked() {

  }

  private initSwipers() {
    const swiperContainers = document.querySelectorAll('swiper-container');
    if (swiperContainers.length) {
      swiperContainers.forEach(container => {
        Object.assign(container, this.config);
        container.initialize();
      });
    }
  }


  handleNextClick(sliderRef: any) {
    sliderRef.swiper.slideNext();
  }

  ngOnDestroy(): void {
    // this._store.dispatch(new ClearOrderListState())
    this._subscriptionState = false;
  }

  toggleSortOptions() {
    this.showSortOptions = !this.showSortOptions;
    this.sortBy = TokenService.geTSortId();
    if (this.sortBy === null) {
      this.sortBy = 1;
    }
  }

  toggleButtonOptions(id: number) {
    this.showButtonOptionsById = this.showButtonOptionsById === id ? 0 : id;

  }

  onSortClicked(id: number) {
    this.sortBy = id;
    TokenService.setSortId(this.sortBy);
    this.showSortOptions = true;
    this.page = 1;
    if (id === 1) {
      this.showRecent = true;
      this.showTurnOver = false;
      this.showMostSold = false;
    }
    if (id === 2) {
      this.showRecent = false;
      this.showTurnOver = true;
      this.showMostSold = false;
    }
    if (id === 3) {
      this.showRecent = false;
      this.showTurnOver = false;
      this.showMostSold = true;
    }
    this._getAll(true, this.activeTab, this.sortBy, this.searchString)
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (posts: any) => this.updateList(posts, this.activeTab),
        error: (err) => this._errorHandlerService.handleError(err)
      });
    // this.showSortOptions = true
  }

  search(value: string) {
    this.searchString = value;
    this.page = 1;
    if (value !== '' && value.trim().length !== 0) {

      this._getAll(true, this.activeTab, this.sortBy, this.searchString)
        .pipe(takeWhile(() => this._subscriptionState))
        .subscribe({
          next: (posts: any) => this.updateList(posts, this.activeTab),
          error: (err) => this._errorHandlerService.handleError(err)
        });
    }
    this.currentSearchString = value;

  }

  closeSearch() {
    this.jatakList = this.allJataks;
    this.searchBox.nativeElement.value = null;
    this.currentSearchString = '';
    this.searchString = '';
    this.inputInSearch = false;
    this.page = 1;
    this._getAll(true, this.activeTab, this.sortBy, this.currentSearchString)
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (posts: any) => this.updateList(posts, this.activeTab),
        error: (err) => this._errorHandlerService.handleError(err)
      });
  }

  // get more post and concat it with the previous posts
  loadMore() {
    this.page++;
    this._getAll(true, this.activeTab, this.sortBy, this.searchString)
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (posts: any) => {
          if (posts.length === 0) {
            NotificationsService.notify('Ingen flere varer', 'error', 'top');
          } else {
            this.jatakList = this.allJataks.concat(posts.data);

            this.allJataks = this.jatakList;
          }
        },
        error: (err) => this._errorHandlerService.handleError(err)
      });
  }

// *** Runs on component initialization ***
  // Sets title and jatak list fields of this component from resolver
  private _initComp() {
    this.selectedOption = {
      mode: 'skab',
      type: 'ja-tak',
      share: {
        coop: true,
        facebook: true,
        sms: false,
        mail: false,
        web: false,
        screen: false,
        dit: false
      },
      //if currentCalendarType is present set step to 3 else 1
      step: 1
    };
    this._title.setTitle('Ja Tak - QuickCoop');
    this.last_page = this._activatedRoute.snapshot.data['jatakList'].meta.last_page;
    this.jatakList = this.allJataks =
      this._activatedRoute.snapshot.data['jatakList'].data;
    this._checkChildPage();
    this.currentUrl = this._router.url;
    this.scroller = fromEvent(window, 'scroll')
      .pipe(
        takeWhile(() => this._subscriptionState),
        debounceTime(200)
      )
      .subscribe(() => this.dealWithScroll(window.scrollY));

    if (this._store.selectSnapshot(SetOrderListCompState.getData)) {
      this.activeTab = this._store.selectSnapshot(SetOrderListCompState.getData).tab;
    } else {
      this.activeTab = '1';
    }

  }

  onOutsideClick() {
    this.showSortOptions = false;
  }


  dealWithScroll(y: number) {
    if (y === 0) {
      this.showFilterAndAdd = true;
    }
    if (y !== 0) {
      this.showFilterAndAdd = false;
    }
  }

  // *** Changing the tab on click ***
  // @params [value: string] => '1' active
  //                             '2' Fremtidige (future)
  //                              '0' Archive
  // @params [event: Event] => click event from html
  tab(value: string, event): void {
    this.currentSearchString = '';
    this.searchBox.nativeElement.value = null;
    this.showSortOptions = false;
    this.searchString = '';
    if (event && this.activeTab !== value) {
      // re setting the page from 1 for other tabs
      this.page = 1;
      this._getAll(true, value, this.sortBy, this.currentSearchString)
        .pipe(takeWhile(() => this._subscriptionState))
        .subscribe({
          next: (posts: any) => {
            this.updateList(posts, value);
          },
          error: (err) => this._errorHandlerService.handleError(err)
        });
    }
    this.inputInSearch = false;
  }

  // *** Updates jatak lists after new tab is clicked ***
  // @params [value: string] => '1' active
  //                             '2' Fremtidige (future)
  //                              '0' Archive
  // @params [posts: Array<Jatak>] => Jatak post related to the value
  updateList(posts, value) {
    this.inputInSearch = true;
    this.jatakList = this.allJataks = posts.data;
    this.last_page = posts.meta.last_page;
    this.activeTab = value;
    window.scroll(0, 0);
    this._saveOrderListState();
  }

  // sets parentUrl during ngOnInt() for back navigation
  setPreviousUrl() {
    const userType = TokenService.getUserType();
    if (userType === '1') {
      this.parentUrl = '/butikker/indstillinger';
    }
    if (userType === '2') {
      this.parentUrl = '/bestyrelse/indstillinger';
    }
  }

// gets all Jatak lists from server with page limit of 10 and this.page as current page
  // @params [state] => Always true
  // @params [value: string] => '1' active
  //                             '2' Fremtidige (future)
  //                              '0' Archive
  // @returns [jatakList: Array<JatakList>] => Basic Jatak value of all jatak items
  private _getAll(state: boolean, value, sortBy, searchString) {
    if (state) {
      return this._jatakService.getJatakListing(this.page, 10, value, sortBy, searchString);
    }
    return throwError(() => 'Error');
  }

  // Check if the route is child route or parent route
  // if child route set isParentPage as false
  private _checkChildPage(): void {
    this.showParent = this._activatedRoute.children.length
      ? 'invisible'
      : 'visible';
  }

  // save jatak as a template
  saveJatakTemplate(id: number) {
    this._jatakService.getSingleFbPost(id).pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (details: any) => this.saveJatakDataTemplate(details),
        error: (err) => this._errorHandlerService.handleError(err)
      });
  }

  saveJatakDataTemplate(JatakData) {
    this._jatakService.saveJatakTemplate(JatakData, false)
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: () =>
          NotificationsService.notify(
            'Skabelon er blevet oprettet',
            'success',
            'top'
          ),
        error: (err) => this._errorHandlerService.handleError(err)
      });
  }

  orderList(id: number) {
    let routeUrl = `${this.currentUrl}/orderlist/`;
    this._router.navigate([routeUrl + id]);
    // this._saveOrderListState()
    // this._saveRouteState()
  }

  // create jatak
  createJatak() {
    let routeToUrl = `${this.currentUrl}/skab`;
    this.selectedOption.isSet = true;
    this._createOptionService.setCreateOption(this.selectedOption);
    this._router.navigate([routeToUrl], {queryParams: {origin: 'create'}});
    // this._saveRouteState()
    // this._saveOrderListRouteState()
  }

  // edit jatak
  edit(id: number) {
    this.editUrl = `${this.currentUrl}/redigere/`;
    this._router.navigate([this.editUrl + id], {queryParams: {origin: 'list'}})
      .then(() => (this.pAnimate = 'inactive'));
    this._saveOrderListState();
    // this._saveRouteState()
  }

  // duplicate jatak
  duplicate(id: number) {

    NotificationsService.confirmWithCallback(
      'Dette vil kopiere teksten og billedet fra dette indlæg til en ny JA TAK. Denne JA TAK vil ikke blive påvirket. Vil du fortsætte?',
      (accept) => {
        if (accept) {
          let routeToUrl = `${this.currentUrl}/create/`;
          this._router.navigate([routeToUrl + id], {queryParams: {origin: 'duplicate'}})
            .then(() => (this.pAnimate = 'inactive'));
        }
      }
    );
    // this._saveRouteState()
    // this._saveOrderListRouteState()
  }

  private _saveOrderListState() {
    this._store.dispatch(new SetOrderListState({
      tab: this.activeTab
    }));
  }

  // private _saveOrderListRouteState(){
  //   this._store.dispatch(new SetOrderListState({
  //     tab : this.activeTab,
  //      route : this._router.url
  //   }))
  // }

  private _saveRouteState() {
    this._store.dispatch(new SetJatakRouteStates({
      routeUrl: this._router.url
    }));
  }

}
