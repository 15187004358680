<div class="datetime-wrapper">
  <div class="startsfrom flex-display">
    <div class="flex-display">
      <div>
        <ion-icon name="timer-outline"></ion-icon>
      </div>
      <div class="picker-title">
        {{ this.dispatchTime }}
      </div>
    </div>
    <div class="height-27">
      <label class="switch">
        <input [checked]="onStartNow" (click)="onStartNowToggle($event)" type="checkbox">
        <span class="slider round"></span>
      </label>
    </div>
  </div>

  <div *ngIf="!onStartNow" class="start-datetime">

    <div class="datetime-title">
      Gyldighedstarttidspunkt
    </div>

    <div class="flex-display">
      <div (click)="toggleCalendar()" class="date padding flex-display">
        <div>
          {{  formatDate(startDate) }}
        </div>
        <div style="height: 13px">
          <ion-icon name="chevron-down-outline"></ion-icon>
        </div>
      </div>
      <div (click)="starttime.click()" class="time">

        {{ startTime }}
        <input
          (onChange)="onStartTimeChange($event)"
          #starttime
          mbsc-datepicker
          [controls]="['time']"
          readonly
          display="center"
          timeFormat="HH:mm"
          hidden
          [touchUi]="false" dateFormat="YYYY-MM-DD"
          class="date-input"/>
      </div>


    </div>
  </div>

  <div class="hr"></div>

  <div class="flex-display start-datetime" style="flex-wrap:wrap">
    <div class="flex-display">
      <div>
        <ion-icon name="timer"></ion-icon>
      </div>
      <div class="picker-title">
        {{ this.closingTime }}
      </div>
    </div>

    <div class="flex-display date-wrapper">
      <div (click)="toggleCalendar()" class="padding flex date-container" style="margin-right: 5px">
        <span>{{  formatDate(endDate) }}</span>
        <ion-icon name="chevron-down-outline" class="calendar-expand-icon" *ngIf="!showCalendar"></ion-icon>
        <ion-icon name="chevron-up-outline" class="calendar-expand-icon" *ngIf="showCalendar"></ion-icon>
      </div>
      <div (click)="endtime.click()" class="time">
         {{ endTime }}
      </div>

      <input
        (onChange)="onEndTimeChange($event)"
        #endtime
        mbsc-datepicker
        [controls]="['time']"
        readonly
        display="center"
        timeFormat="HH:mm"
        hidden
        [touchUi]="false" dateFormat="YYYY-MM-DD"
        class="date-input"/>
    </div>

  </div>


  <div *ngIf="showCalendar" class="calendar-wrapper">
    <mbsc-datepicker
      #myDatepicker
      [(ngModel)]="dateRange"
      (onChange)="getDateTime($event)"
      [controls]="['calendar']"
      select="range"
      [showRangeLabels]="false"
      [min]="onStartNow || editMode ? startDate : defaultStartDate"
      display="inline"></mbsc-datepicker>
  </div>
</div>
