import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'coop-mfl-text',
  templateUrl: './mfl-text.component.html',
  styleUrls: ['./mfl-text.component.scss'],
})
export class MflTextComponent implements OnInit {
  @Input() offerType = 'tilbud';
  @Input() isAccordion = false
  showNotification:boolean = false
  showNyhedNotification:boolean = false
  constructor() {}

  ngOnInit() {

  }

  onToggle(){
    this.showNotification = !this.showNotification

  }

  onNyhedToggle(){
    this.showNyhedNotification = !this.showNyhedNotification
  }
}
