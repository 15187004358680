import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { TokenService } from 'app/core/services';
import { Navitem } from 'app/shared/interface';

@Component({
  selector: 'coop-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  navItems: Navitem[];
  usertype: string;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router
  ) {}
//** initialized component
  //set navItems with the value fetched from resolver data 'nav'
  //set usertype with the value from getUserType()
  ngOnInit() {
    this.navItems = this._activatedRoute.snapshot.data['nav'];
    this.usertype = TokenService.getUserType();
  }
}
