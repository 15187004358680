import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AccordionActions {
  constructor() {}
// *** Toggles Accordion for each user in jatak order lists***
// @params [id] => userId
// @params [accordionGroup: HTMLDivElement] => for manipulating <div> element
  accordionClick(id: number | string, accordionGroup: HTMLDivElement) {
    const selectedAccordion = document.getElementById(id.toString());
    selectedAccordion.classList.toggle('expanded');
    const accordionContent = selectedAccordion.getElementsByClassName(
      'accordion-content'
    )[0] as HTMLElement;
    if (selectedAccordion.classList.contains('expanded')) {
      const paddingFix = 45;
      const orderListHeight = selectedAccordion.getElementsByClassName(
        'order-list'
      )
        ? selectedAccordion.getElementsByClassName('order-list')[0].clientHeight
        : 0;
      const orderStatusHeight = selectedAccordion.getElementsByClassName(
        'order-status'
      )
        ? selectedAccordion.getElementsByClassName('order-status')[0]
            .clientHeight
        : 0;
      accordionContent.style.height =
        orderStatusHeight + orderListHeight + paddingFix + 'px';
    } else {
      accordionContent.style.height = 0 + 'px';
    }
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < accordionGroup.children.length; i++) {
      const childAccord = accordionGroup.children[i];
      if (childAccord.id !== id.toString()) {
        const childrenAccordContent = childAccord.getElementsByClassName(
          'accordion-content'
        )[0] as HTMLElement;
        childrenAccordContent.style.height = '0px';
        childAccord.classList.remove('expanded');
      }
    }
  }
}
