import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  resultData: Array<any>;
  param: string;

  constructor() {}

  // ** Search Array By Title ** //
  // Searchs an array with same title as the input string
  // @params [value: string] => string from searchinput
  // @params [searchArray : Array] => Array of item to be searched
  // @return [resultData : Array] => Search result
  searchByTitle(
    value: string,
    searchArray: any,
    searchBy: string,
    folderMode?: boolean
  ): Array<any> {
    folderMode ? (this.param = 'name') : (this.param = searchBy);
    if (!value) {
      this.resultData = searchArray;
    }
    this.resultData = Object.assign([], searchArray).filter(
      (item) => item[this.param].toLowerCase().indexOf(value.toLowerCase()) > -1
    );
    return this.resultData;
  }
}
