<section
  class="app-page list-component order-list"
  id="orderList"
  *ngIf="!showRangeSelect"
>
  <div class="fixed-header">
    <coop-pageheader
      [pageTitle]="pageTitle"
      [isChild]="true"
      [routeTo]="previousUrl"
    ></coop-pageheader>
    <div class="fixed-header-content">
      <ul class="tabs four-col">
        <li
          class="tab-first"
          (click)="tab(1, $event)"
          [class.active]="activeTab === 1"
        >
          Nye
        </li>
        <li
          class="tab-second"
          (click)="tab(2, $event)"
          [class.active]="activeTab === 2"
        >
          Prep
        </li>
        <li
          class="tab-third"
          (click)="tab(0, $event)"
          [class.active]="activeTab === 0"
        >
          Prod.
        </li>
        <li
          class="tab-fourth"
          (click)="tab(-1, $event)"
          [class.active]="activeTab === -1"
        >
          Klar
        </li>
        <hr />
      </ul>
    </div>
  </div>
  <div class="page-container">
    <div class="item-list">
      <div class="item-group" *ngIf="activeTab === 2">
        <div class="item-group-title">
          <a class="print-btn" (click)="showPrintRangeSelect()">
            <figure>
              <img src="assets/icons/outline/printer.svg" alt="" />
            </figure>
            Print
          </a>
          <a class="print-btn" routerLink="/butikker/order/print-history">
            Se print historik
          </a>
        </div>
      </div>
      <ng-container *ngFor="let order of orderList">
        <a class="item" routerLink="{{ currentUrl }}/redigere/{{ order.id }}">
          <span
            [className]="'dot' + ' ' + priorities[order.priority - 1]"
            *ngIf="order.priority !== 1"
          ></span>
          <img
            src="assets/images/exclamation_circle.svg"
            alt=""
            width="24"
            height="24"
            *ngIf="order.priority === 1"
          />
          <div class="item-detail">
            <div class="item-order d-flex">
              <div>
                <h3 class="item-title">#{{ order.orderNumber }}</h3>
                <span> {{ order.category }}</span>
              </div>
              <h3 class="item-title">
                {{ order.totalPrice | numberpad : 'da-DA' }}
              </h3>
            </div>
            <div class="item-date d-flex">
              <span>{{ order.bookingDate | dateformatddmmyy }}</span>
              <span *ngIf="order.orderStatus === 1"
                >{{ order.totalQuantity }}
                {{ order.totalQuantity > 1 ? 'varer' : 'vare' }}</span
              >
              <span *ngIf="order.orderStatus === 2 || order.orderStatus === 0"
                >Afhentes {{ order.pickupDate | hoursdifference }}</span
              >
              <span *ngIf="order.orderStatus === -1">Klar til afhentning</span>
            </div>
          </div>
        </a>
      </ng-container>
      <h5 class="empty-message" *ngIf="orderList.length === 0">
        Beklager, ingen ordrer tilgængelige
      </h5>
    </div>
    <a
      class="login-btn btn-white-login btn-medium"
      (click)="loadMore()"
      *ngIf="activeTab !== 2 && !(orderList.length < page * limit)"
      >Indlæs mere..</a
    >
  </div>
  <!-- for filter in order -->
  <a class="big-add-btn-left z-index-fix" (click)="showHideFilterBtns()">
    <i class="icon-filter"></i>
  </a>
  <div class="add-actions-container" [@slideUpDown]="showFilters">
    <div class="close-btn" (click)="showHideFilterBtns()">
      <i class="icon icon-close-circle"></i>
    </div>
    <div class="wrapper">
      <h4>Filterindstilling</h4>
      <ul>
        <li>
          <a
            class="btn"
            [class.active]="currentCategory === 'Alle'"
            (click)="loadOrderListData('Alle')"
            >Alle</a
          >
        </li>
        <li>
          <a
            class="btn"
            [class.active]="currentCategory === 'Delikatessen'"
            (click)="loadOrderListData('Delikatessen')"
            >Delikatessen</a
          >
        </li>
        <li>
          <a
            class="btn"
            [class.active]="currentCategory === 'Bageren'"
            (click)="loadOrderListData('Bageren')"
            >Bageren</a
          >
        </li>
        <li>
          <a
            class="btn"
            [class.active]="currentCategory === 'Slagter'"
            (click)="loadOrderListData('Slagter')"
            >Slagter</a
          >
        </li>
        <li>
          <a
            class="btn"
            [class.active]="currentCategory === 'Fisk'"
            (click)="loadOrderListData('Fisk')"
            >Fisk</a
          >
        </li>
      </ul>
    </div>
  </div>
</section>
<ng-container *ngIf="showRangeSelect">
  <coop-print-range-select
    (rangeSelectEvent)="hidePrintRangeSelect($event)"
  ></coop-print-range-select>
</ng-container>
<router-outlet></router-outlet>
