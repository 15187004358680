import {Injectable} from '@angular/core';
import { HttpHeaders, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {TokenService} from 'app/core/services/token.service';
import {catchError, map} from 'rxjs/operators';
import {environment} from 'environments/environment';
import {AppEventsService} from 'app/core/services/appevents.service';
import {UserService} from 'app/core/services/userdetail.service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RequestInterceptorService implements HttpInterceptor {
  private _backgroundApis: string[] = [
    'coop-auth.php',
    'newCount',
    'upload_video.php',
    'live_event_comments',
    'live-report',
    'insights',
  ];

  constructor(
    private _appEvents: AppEventsService,
    private _userService: UserService,
    private _router: Router
  ) {
  }

//*** Intercepts any http request, sets header object and disables busy loader if response ***
// @params [request] => HTTP request
// @params [next] => Passes to next interceptor in chain if any
// @returns [httpEvent] => an observable of the event stream
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    //
    // if ( req.method === 'POST' && this.shouldSkipInterceptor(req)) {
    //   // If it matches, pass the request without intercepting
    //   return next.handle(req);
    // }
    return next.handle(this._setRequestHeaders(req)).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this._appEvents.showLoader(false);
        }
        return event;
      }),
      catchError((HttpError: HttpErrorResponse) => {
        const isTokenInvalid =
          HttpError.error.code === -83 ||
          HttpError.error.code === -84 ||
          HttpError.error.code === -85;
        if (HttpError.status === 401 && isTokenInvalid) {
          this._userService.removeUserDetails(true);
          this._appEvents.showLoader(false);
          this._router.navigate(['/']).then();
          return throwError(HttpError.error);
        }
        if (!environment.production) {
          console.log(HttpError);
        }
        this._appEvents.showLoader(false);
        return throwError(HttpError.error);
      })
    );
  }

// *** Sets request headers ***
// @params [request] => http request
// It sets headers in cloned request
  private _setRequestHeaders(request: HttpRequest<any>) {
    const headerObject = {};
    if (request.method === 'POST' && !(request.body instanceof FormData)) {

      if (request.url.includes('api/bags')) {
        headerObject['Content-type'] = 'application/json';
      } else {
        headerObject['Content-type'] = 'application/x-www-form-urlencoded';
      }

    }
    if (request.method === 'GET') {
      headerObject['Content-type'] = 'application/json';
    }

    // check if requesting to coop api calls
    if (
      request.url.indexOf(environment.apiurl) !== -1 ||
      request.url.indexOf(environment.webhookurl) !== -1 ||
      request.url.indexOf(environment.imageUrl) !== -1
    ) {
      // *** If request are made after authentication add Token and KardexId on respective headers *** //
      if (TokenService.checkAuthenticatedState()) {
        headerObject['X-User-Id'] = TokenService.getUserCode();
        headerObject['Authorization'] =
          request.url.indexOf(environment.apiurl) !== -1
            ? `Bearer ${TokenService.getToken()}`
            : `Bearer ${TokenService.getImageToken()}`;
        headerObject['WebHook'] = `${TokenService.getWebHookToken()}`;
      }
      // *** Apis that run in background ***

      const containsUrl = this._backgroundApis.some(
        (url: string) => request.url.indexOf(url) !== -1
      );
      if (!containsUrl) {
        this._appEvents.showLoader(true);
      }
    }
    const headers = new HttpHeaders(headerObject);
    return request.clone({headers});
  }


  // Function to determine if a request should skip interceptor
  private shouldSkipInterceptor(req: HttpRequest<any>): boolean {
    // Add the URLs you want to skip interception here
    const skipUrls: string[] = [
      '/api/bags',
    ];
    // Check if the request URL matches any of the skip URLs
    return skipUrls.some(url => req.url.includes(url));
  }
}
