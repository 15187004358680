import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import * as dayjs from 'dayjs';
import { Store } from '@ngxs/store';
import { Jatak } from 'app/features/jatak';
import { localeDa, MbscDatepicker, MbscLocale } from '@mobiscroll/angular';

@Component({
  selector: 'app-start-end-date-time',
  templateUrl: './start-end-date-time.component.html',
  styleUrls: ['./start-end-date-time.component.scss']
})
export class StartEndDateTimeComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('myDatepicker', { static: false }) myDatepicker: MbscDatepicker;
  @Input() formData: any;
  @Input() jatakData: Jatak;
  @Input() jatak: boolean = false;
  @Input() editMode: boolean = false;
  @Input() duplicateMode: boolean = false;
  @Input() templateMode: boolean = false;
  @Input() startDateTimeS = dayjs().format('YYYY-MM-DD HH:mm');
  @Input() endDateTime = dayjs().add(6, 'days').format('YYYY-MM-DD HH:mm');
  @Input() validFrom: string;
  @Input() validTill: string;


  defaultStartDate = dayjs().format('YYYY-MM-DD');
  startDate = dayjs().format('YYYY-MM-DD');
  startTime = dayjs().format('HH:mm');
  endDate = dayjs().add(6, 'days').format('YYYY-MM-DD');
  endTime = dayjs().format('HH:mm');
  dateRange = [this.startDate, this.endDate];
  onStartNow: boolean = true;
  @Output() startDateTime = new EventEmitter<{ startDateTime: string, endDateTime: string, start_now: boolean }>();
  showCalendar: boolean = false;
  locale: MbscLocale;


  constructor(
    private _store: Store
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['startDateTimeS'] || changes['endDateTime']) {
      this.setupCalendar();
    }
    if (changes['jatakData']) {
      this.jatakData = changes['jatakData'].currentValue;
    }
    if (changes['validFrom']) {
      this.validFrom = changes['validFrom'].currentValue;
    }
    if (changes['validTill']) {
      this.validTill = changes['validTill'].currentValue;
    }

  }

  ngOnDestroy() {
    if (this.myDatepicker) {
      this.myDatepicker.destroy();
    }
  }

  ngOnInit() {
    this.locale = localeDa;
    if (this.jatak && this.jatakData) {
      if (this.editMode) {
        this.onStartNow = false;
        this.setupCalendar();
      } else if (this.duplicateMode && this.jatakData.ended) {
        this.setDefaultDateTime();
      } else if (this.jatakData.own) {
        this.handleDateTimeForJatakTemplate();
      }
    }
  }

  private setDefaultDateTime() {
    this.startDate = dayjs().format('YYYY-MM-DD');
    this.startTime = dayjs().format('HH:mm');
    this.endDate = dayjs().add(6, 'days').format('YYYY-MM-DD');
    this.endTime = dayjs().format('HH:mm');
    this.dateRange = [this.startDate, this.endDate];
    this.onStartNow = true;
  }

  private setupCalendar() {
    this.startDate = dayjs(this.startDateTimeS).format('YYYY-MM-DD');
    this.startTime = dayjs(this.startDateTimeS).format('HH:mm');
    this.endDate = dayjs(this.endDateTime).format('YYYY-MM-DD');
    this.endTime = dayjs(this.endDateTime).format('HH:mm');
    this.dateRange = [dayjs(this.startDateTimeS).format('YYYY-MM-DD'), dayjs(this.endDateTime).format('YYYY-MM-DD')];
    // this.onStartNow = !this.editMode;
  }


  private handleDateTimeForJatakTemplate() {
    const currentDate = dayjs();
    if (currentDate.isAfter(this.jatakData.endDate)) {
      this.setDefaultDateTime();
    } else if (currentDate.isAfter(this.jatakData.startDate)) {
      this.startDate = dayjs().format('YYYY-MM-DD');
      this.startTime = dayjs().format('HH:mm');
      this.endDate = dayjs(this.jatakData.endDate).format('YYYY-MM-DD');
      this.endTime = dayjs(this.jatakData.endDate).format('HH:mm');
      this.dateRange = [this.startDate, this.endDate];
    } else {
      this.setupCalendar();
    }
  }

  onStartTimeChange(event: any) {
    this.startTime = event.valueText;
    this.concatDateTime();
  }

  onEndTimeChange(event: any) {
    this.endTime = event.valueText;
    this.concatDateTime();
  }

  getDateTime(event: any) {
    if (this.onStartNow || this.editMode) {
      this.startDate = this.editMode ? this.startDate : dayjs().format('YYYY-MM-DD');
      this.endDate = dayjs(event.value[0]).format('YYYY-MM-DD');
      if (event.value[1]) {
        this.endDate = dayjs(event.value[1]).format('YYYY-MM-DD');
      }
    } else {
      this.startDate = dayjs(event.value[0]).format('YYYY-MM-DD');
      if (event.value[1]) {
        this.endDate = dayjs(event.value[1]).format('YYYY-MM-DD');
      }
    }
    this.dateRange = [this.startDate, this.endDate];
    this.concatDateTime();
  }

  onStartNowToggle(event: any) {
    this.onStartNow = event.target.checked;
    if (!this.onStartNow) {
      this.showCalendar = true;
    }
    this.startDate = dayjs().format('YYYY-MM-DD');
    this.startTime = dayjs().format('HH:mm');
    this.dateRange[0] = this.startDate;
    this.concatDateTime();
  }

  formatDate(date: string) {
    dayjs.locale('da');
    return dayjs(date).format('D. MMMM');
  }


  concatDateTime() {
    let startDateTime = this.startDate + ' ' + this.startTime;
    let endDateTime = this.endDate + ' ' + this.endTime;
    let start_now = this.onStartNow;
    this.startDateTime.emit({ startDateTime, endDateTime, start_now });
  }

  toggleCalendar() {
    this.showCalendar = !this.showCalendar;

  }
}
