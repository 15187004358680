import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router, UrlSegment } from '@angular/router';
import * as moment from 'moment';
import { EMPTY, combineLatest } from 'rxjs';
import { finalize, mergeMap, take, takeWhile } from 'rxjs/operators';

import {
  DateFormatService,
  ErrorhandlerService,
  HelperService,
  NotificationsService
} from 'app/core/services';
import {
  checkIfTotalOfferLessThanPerDay,
  minOrderPerDay,
  checkWhiteSpace,
  checkPriceMinimum,
  checkMaximumPriceProduct,
  checkPriceRegex,
  checkStartEndDateForOrderPickup
} from 'app/core/validators/custom.validator';
import { ValidationMessage } from 'app/shared/interface';
import { DateToString, MillisToMinutes } from 'app/shared/pipes';
import {
  Product_Validation_Messages,
  FormattedShopHour,
  Product,
  ShopHours,
  ProductService
} from 'app/features/order-and-pickup/product';
import { MbscDatepickerOptions } from '@mobiscroll/angular';
import { COMMON_DATETIMEPICKER_SETTINGS, COMMON_TIMEPICKER_SETTINGS } from 'app/shared/data';

interface MinMaxPickUpDate {
  min: moment.Moment;
  max: moment.Moment;
}

@Component({
  selector: 'coop-product-create-edit',
  templateUrl: './product-create-edit.component.html',
  styleUrls: ['./product-create-edit.component.scss'],
  providers: [DateToString, MillisToMinutes]
})
export class ProductCreateEditComponent implements OnInit, OnDestroy {
  pageTitle: string = 'Opret nyt produkt';
  previousUrl: string;
  parentUrl: string;
  productForm: FormGroup;
  imageUrl = {
    largeImage: 'assets/images/camera@3x.jpg',
    thumbnail: ''
  };
  hasImage: boolean = false;
  isProcessing: boolean = false;
  formSaved: boolean = false;

  datePickerSettings: MbscDatepickerOptions = {
    ...COMMON_DATETIMEPICKER_SETTINGS, ...{
      dateWheels: 'YYYY MMMM DD',
      dateFormat: 'YYYY-MM-DD',
      stepMinute: 15
    }
  };
  //TODO: fix time picker
  timePickerSettings: MbscDatepickerOptions = {
    ...COMMON_TIMEPICKER_SETTINGS, ...{
      timeWheels: '|DD HH MM SS|',
      returnFormat: 'locale',
      stepHour: 1,
      stepMinute: 1,
      stepSecond: 15
    }
  };


  categoryList = ['Delikatessen', 'Bageren', 'Slagter', 'Fisk'];
  showSelectBox: boolean = false;
  tempSelectedCategory: number;
  selectedCategory: number = 0;
  product: Product;
  productFormValue: Product;
  editMode: boolean = false;
  origin: string;
  templateMode: boolean = false;
  productId: string;
  currentUrl: string;
  successUrl: string;
  shopHours: ShopHours[];
  formattedShopHours: FormattedShopHour[] = [];
  validShopHours: FormattedShopHour[] = [];
  minPickupDate: moment.Moment;
  maxPickupDate: moment.Moment;
  showDeactivate: boolean = false;
  validationMessages: ValidationMessage = Product_Validation_Messages;

  private _subscriptionState: boolean = true;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _errorHandlerService: ErrorhandlerService,
    private _productService: ProductService,
    private _router: Router,
    private _dateToString: DateToString,
    private _millisToMinutes: MillisToMinutes
  ) {
  }

  ngOnInit() {
    this._activatedRoute.parent.data
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe((url) => (this.parentUrl = url['parentUrl']));
    document.body.classList.add('overlay');
    this.previousUrl = this.parentUrl + '/skab';
    this.productForm = this._formBuilder.group(
      {
        id: null,
        template_id: null,
        title: ['', [Validators.required, checkWhiteSpace]],
        description: ['', [Validators.required, checkWhiteSpace]],
        category: ['', Validators.required],
        largeImage: ['', Validators.required],
        price: [
          '',
          [
            Validators.required,
            checkPriceMinimum,
            checkMaximumPriceProduct,
            checkPriceRegex
          ]
        ],
        totalOfferUnlimited: [false],
        totalOffer: ['', [Validators.required, Validators.min(1)]],
        offerPerDayUnlimited: [false],
        offerPerDay: ['', [Validators.required, Validators.min(1)]],
        productionTime: [null, [Validators.required, Validators.min(1)]],
        minimumOrder: [1, Validators.required],
        productActive: [false],
        allowComments: [false],
        pickUpPeriod: [false]
      },
      {
        validators: [checkIfTotalOfferLessThanPerDay, minOrderPerDay]
      }
    );
    this._initComponent();
    if (this.product) {
      this.editMode = true;
      this.editMode = this.templateMode ? false : this.editMode;
      this.pageTitle = this.templateMode
        ? 'Rediger Skabelon'
        : 'Rediger Produkt';
      if (this.templateMode || this.editMode) {
        // setting productId and folder id and previousUrl values
        combineLatest([
          this._activatedRoute.url,
          this._activatedRoute.parent.params
        ])
          .pipe(
            take(1),
            finalize(() => {
              this._computePreviousUrl();
            })
          )
          .subscribe({
            next: (value: [UrlSegment[], Params]) => {
              this._computeRouteAndParentId(value);
            },
            error: (err) => this._errorHandlerService.handleError(err)
          });
      }
      this._patchFormValues(this.product);
    }
    if (this.shopHours.length) {
      this._setMinMaxInvalidDate();
      this.validShopHours = this._setValidDate();
    }
    this._initDateSetting();
    // set  default time on create
    if (!this.product) {
      this._setDefaultTimeOnCreate();
    }
  }

  ngOnDestroy(): void {
    document.body.classList.remove('overlay');
    this._subscriptionState = false;
  }

  imageProcessing(e) {
    this.isProcessing = e;
  }

  // get the uploaded image from image slider
  getImagesUrl(images) {
    this.productForm.patchValue({
      largeImage: images.largeImage
    });
    this.imageUrl = {
      largeImage: images.largeImage,
      thumbnail: ''
    };
    this.hasImage = true;
  }

  save() {
    if (this.productForm.status === 'INVALID') {
      this._handleError('');
      return;
    }
    if (!this.isProcessing) {
      this.isProcessing = true;
      if (this.templateMode) {
        this.productForm.patchValue({
          template_id: this.productId
        });
      }
      // changing the date format
      if (this.productForm.value.pickUpPeriod) {
        this.productForm.patchValue({
          pickUp: {
            StartDate: this._dateToString.transform(
              this.productForm.getRawValue().pickUp.StartDate
            ),
            EndDate: this._dateToString.transform(
              this.productForm.getRawValue().pickUp.EndDate
            )
          }
        });
      }
      if (this.productForm.value.totalOfferUnlimited) {
        this.productForm.patchValue({
          totalOffer: null
        });
      }
      if (this.productForm.value.offerPerDayUnlimited) {
        this.productForm.patchValue({
          offerPerDay: null
        });
      }
      this.productFormValue = this.productForm.getRawValue();
      // to make sure production time is not converted to minutes again if error occurs on save
      this.productFormValue.productionTime = this._millisToMinutes.transform(
        this.productForm.value.productionTime
      );
      this._productService
        .saveProduct(this.productFormValue, this.editMode)
        .pipe(takeWhile(() => this._subscriptionState))
        .subscribe({
          next: () => this._success(),
          error: (err) => this._handleError(err)
        });
    }
  }

  selectBox() {
    this.showSelectBox = true;
    this.tempSelectedCategory = this.selectedCategory;
  }

  categorySelection(category) {
    this.selectedCategory = category;
    this.productForm.patchValue({
      category: this.categoryList[category]
    });
    this.productForm.controls['category'].markAsDirty();
    this.showSelectBox = false;
  }

  addItems() {
    const currentValue = this.productForm.value.minimumOrder;
    if (currentValue >= this.productForm.value.offerPerDay) return;
    this.productForm.patchValue({
      minimumOrder: currentValue + 1
    });
  }

  subtractItems() {
    const currentValue = this.productForm.value.minimumOrder;
    if (currentValue !== 1) {
      this.productForm.patchValue({
        minimumOrder: currentValue - 1
      });
    }
  }

  delete() {
    NotificationsService.confirm(`Er du sikker?`)
      .pipe(
        takeWhile(() => this._subscriptionState),
        mergeMap((state) => this._deleteProduct(state))
      )
      .subscribe({
        next: () => this._success(),
        error: (err) => this._handleError(err)
      });
  }

  addDeleteStartEnd(e) {
    if (this.product) {
      if (this.product.activeCart) {
        e.preventDefault();
        NotificationsService.notify('Produktet er i kurven', 'info', 'top');
        return;
      }
    }
    if (this.productForm.value.pickUpPeriod) {
      this.productForm.removeControl('pickUp');
      this.productForm.updateValueAndValidity();
    }
    if (!this.productForm.value.pickUpPeriod) {
      this.productForm.addControl('pickUp', this.initStartEnd());
      this.productForm.updateValueAndValidity();
      if (this.product) {
        if (this.product.pickUpPeriod) {
          this.productForm.patchValue({
            pickUp: {
              StartDate: this.product.pickUp.StartDate,
              EndDate: this.product.pickUp.EndDate
            }
          });
        }
      }
    }
  }

  initStartEnd(): FormGroup {
    return this._formBuilder.group(
      {
        StartDate: ['', Validators.required],
        EndDate: ['', Validators.required]
      },
      {
        validators: [checkStartEndDateForOrderPickup]
      }
    );
  }

  updateOfferValidation() {
    const currForm = this.productForm;
    currForm.value.totalOfferUnlimited
      ? currForm.controls['totalOffer'].enable()
      : currForm.controls['totalOffer'].disable();
    if (currForm.value.totalOfferUnlimited) {
      currForm.patchValue({
        offerPerDayUnlimited: false
      });
      currForm.controls['offerPerDay'].enable();
    }
  }

  updatePerDayValidation(e) {
    if (!this.productForm.value.totalOfferUnlimited) {
      e.preventDefault();
      return;
    }
    const currForm = this.productForm;
    currForm.value.offerPerDayUnlimited
      ? currForm.controls['offerPerDay'].enable()
      : currForm.controls['offerPerDay'].disable();
  }

  changeStatus(e) {
    if (
      this.editMode &&
      this.productForm.value.productActive &&
      this.product.reservedQuantity > 0
    ) {
      this.showDeactivate = true;
      e.preventDefault();
    }
  }

  deactivateProduct() {
    this.productForm.patchValue({
      productActive: false
    });
    this.showDeactivate = false;
  }

  validateNumber(event) {
    HelperService.preventInvalidNumbers(event);
  }

  private _deleteProduct(state: boolean) {
    if (state) {
      return this._productService.deleteProduct(this.product.id);
    }
    return EMPTY;
  }

  private _initComponent() {
    this.origin = this._activatedRoute.snapshot.queryParams['origin'];
    this.product = this._activatedRoute.snapshot.data['product'];
    this.shopHours = this._activatedRoute.snapshot.data['shopHours'];
    this.templateMode = this._activatedRoute.snapshot.data['template'];
    this.currentUrl = this._router.url;
    this.previousUrl = this.currentUrl.replace('produkt/skab', '');
    if (this.origin === 'list') {
      this.previousUrl = this.currentUrl.replace('skab?origin=list', '');
    }
    if (this.templateMode) {
      this.parentUrl =
        this._activatedRoute.snapshot.parent.parent.data['parentUrl'];
    } else {
      this.parentUrl = this._activatedRoute.snapshot.parent.data['parentUrl'];
    }
    this.successUrl = this.parentUrl + '/produkt';
  }

  private _computePreviousUrl() {
    if (this.editMode) {
      this.successUrl = this.currentUrl.replace(
        'redigere/' + this.productId,
        ''
      );
      this.previousUrl = this.currentUrl.replace('redigere', 'details');
    }
    if (this.templateMode) {
      this.previousUrl = this.currentUrl.replace('/' + this.productId, '');
    }
  }

  private _computeRouteAndParentId(value: [UrlSegment[], Params]) {
    const urlSegment = value[0];
    if (this.templateMode || this.editMode) {
      // setting temp id
      if (urlSegment.length > 1) {
        this.productId = urlSegment[1].path;
      } else {
        this.productId = urlSegment[0].path;
      }
    }
  }

  private _patchFormValues(product: Product) {
    if (this.product.pickUpPeriod) {
      this.productForm.addControl('pickUp', this.initStartEnd());
      this.productForm.updateValueAndValidity();
    }
    this.productForm.patchValue(product);
    this.selectedCategory = this.categoryList.indexOf(
      this.productForm.value.category
    );
    if (!this.templateMode) {
      this.productForm.controls['category'].disable();
    }
    if (this.product.activeCart) {
      this.productForm.controls['price'].disable();
      if (this.product.pickUpPeriod) {
        this.productForm.get('pickUp.StartDate').disable();
        this.productForm.get('pickUp.EndDate').disable();
      }
    }
    if (this.product.totalOfferUnlimited) {
      this.productForm.controls['totalOffer'].disable();
    }
    if (this.product.offerPerDayUnlimited) {
      this.productForm.controls['offerPerDay'].disable();
    }
    this.hasImage = true;
    this.imageUrl = {
      largeImage: product.largeImage,
      thumbnail: ''
    };
  }

  private _setDefaultTimeOnCreate() {
    if (this.shopHours.length) {
      this.productForm.patchValue({
        pickUp: {
          StartDate: this.minPickupDate,
          EndDate: this.maxPickupDate
        }
      });
    }
    if (!this.shopHours.length) {
      const StartDate = DateFormatService.timeIntervalFormat(moment());
      const EndDate = DateFormatService.timeIntervalFormat(
        moment().add(7, 'days')
      );
      this.productForm.patchValue({
        pickUp: {
          StartDate: StartDate,
          EndDate: EndDate
        }
      });
    }
  }

  private _setMinMaxInvalidDate() {
    const pickUpDates = ProductCreateEditComponent._getMinMaxPickupDate(
      this.shopHours
    );
    this.minPickupDate = pickUpDates.min;
    this.maxPickupDate = pickUpDates.max;
    if (this.editMode) {
      if (
        ProductCreateEditComponent._checkDateBeforeToday(
          this.product.pickUp.StartDate
        )
      ) {
        // setting start date as min
        this.minPickupDate = moment(this.product.pickUp.StartDate);
      }
    }
    this.formattedShopHours = this._getInvalidShopHours();
  }

  private static _getMinMaxPickupDate(pickUpDates): MinMaxPickUpDate {
    const pickupDate = ProductCreateEditComponent._concatTime(
      pickUpDates[0].date,
      pickUpDates[0].from
    );
    const shopHoursLength = pickUpDates.length;
    const maxPickUpDate = ProductCreateEditComponent._concatTime(
      pickUpDates[shopHoursLength - 1].date,
      pickUpDates[shopHoursLength - 1].to
    );
    return {
      min: DateFormatService.timeIntervalFormat(pickupDate),
      max: DateFormatService.timeIntervalFormat(maxPickUpDate)
    };
  }

  private _getInvalidShopHours(): FormattedShopHour[] {
    const pickUpDates = this.shopHours;
    let shopHours = [];
    pickUpDates.forEach((selectedDate) => {
      let startingShopHour: FormattedShopHour;
      if (!selectedDate.holiday) {
        if (pickUpDates.indexOf(selectedDate) === 0) {
          startingShopHour = {
            d: moment(selectedDate.date).toDate(),
            start: '00:00',
            end: moment().format('HH:mm')
          };
        }
        if (pickUpDates.indexOf(selectedDate) !== 0) {
          startingShopHour = {
            d: moment(selectedDate.date).toDate(),
            start: '00:00',
            end: ProductCreateEditComponent._calculateMinMaxDate(
              'min',
              selectedDate.date,
              selectedDate.from
            )
          };
        }
        const endingShopHour: FormattedShopHour = {
          d: moment(selectedDate.date).toDate(),
          start: ProductCreateEditComponent._calculateMinMaxDate(
            'max',
            selectedDate.date,
            selectedDate.to
          ),
          end: '23:59'
        };
        shopHours = [...shopHours, startingShopHour, endingShopHour];
      }
      if (selectedDate.holiday) {
        const holidayHour = {
          start: moment(selectedDate.date).toDate(),
          end: moment(selectedDate.date).toDate()
        };
        shopHours = [...shopHours, holidayHour];
      }
    });
    if (this.editMode) {
      const StartDate = this.product.pickUp.StartDate;
      const EndDate = this.product.pickUp.EndDate;
      const current = moment().toDate();
      if (
        ProductCreateEditComponent._checkDateAfterToday(StartDate) &&
        ProductCreateEditComponent._checkDateAfterToday(EndDate)
      ) {
        // case no handle
        return shopHours;
      }
      if (
        ProductCreateEditComponent._checkDateBeforeToday(StartDate) &&
        ProductCreateEditComponent._checkDateBeforeToday(EndDate)
      ) {
        // Case 1
        shopHours = [
          ...shopHours,
          {
            d: moment(StartDate).toDate(),
            start: '00:00',
            end: '23:59'
          },
          {
            d: moment(EndDate).toDate(),
            start: '00:00',
            end: '23:59'
          },
          {
            start: moment(StartDate).add(1, 'day').toDate(),
            end: moment(EndDate).subtract(1, 'day').toDate()
          },
          {
            start: moment(EndDate).add(1, 'day').toDate(),
            end: moment(current).subtract(1, 'day').toDate()
          }
        ];
        return shopHours;
      }
      if (
        ProductCreateEditComponent._checkDateBeforeToday(StartDate) &&
        ProductCreateEditComponent._checkDateAfterToday(EndDate)
      ) {
        // case 2
        shopHours = [
          ...shopHours,
          {
            d: moment(StartDate).toDate(),
            start: '00:00',
            end: '23:59'
          },
          {
            start: moment(StartDate).add(1, 'day').toDate(),
            end: moment(current).subtract(1, 'day').toDate()
          }
        ];
        return shopHours;
      }
      if (
        ProductCreateEditComponent._checkDateBeforeToday(StartDate) &&
        ProductCreateEditComponent._checkDateOnSameDayAsToday(EndDate)
      ) {
        // case 3
        shopHours = [
          ...shopHours,
          {
            d: moment(StartDate).toDate(),
            start: '00:00',
            end: '23:59'
          },
          {
            start: moment(this.product.pickUp.StartDate).add(1, 'day').toDate(),
            end: moment(current).subtract(1, 'day').toDate()
          }
        ];
        return shopHours;
      }
      if (
        ProductCreateEditComponent._checkDateOnSameDayAsToday(StartDate) &&
        ProductCreateEditComponent._checkDateOnSameDayAsToday(EndDate)
      ) {
        // nothing to do, just keeping the if check for reference
        return shopHours;
      }
    }
    return shopHours;
  }

  private static _concatTime(date: string, time: string): moment.Moment {
    return moment(date + ' ' + time);
  }

  private static _calculateMinMaxDate(timeStatus: string, date, time): string {
    let newDate = ProductCreateEditComponent._concatTime(date, time);
    if (timeStatus === 'min') {
      newDate = newDate.subtract(15, 'minutes');
    }
    if (timeStatus === 'max') {
      newDate = newDate.add(15, 'minutes');
    }
    return newDate.format('HH:mm');
  }

  private static _checkDateBeforeToday(date): boolean {
    const currentTime = DateFormatService.timeIntervalFormat(moment());
    const inputDate = moment(date);
    return inputDate.isBefore(currentTime, 'day');
  }

  private static _checkDateAfterToday(date): boolean {
    const currentTime = DateFormatService.timeIntervalFormat(moment());
    const inputDate = moment(date);
    return inputDate.isAfter(currentTime, 'day');
  }

  private static _checkDateOnSameDayAsToday(date): boolean {
    const currentTime = DateFormatService.timeIntervalFormat(moment());
    const inputDate = moment(date);
    return inputDate.isSame(currentTime, 'day');
  }

  private _setValidDate() {
    let validTime = [];
    if (this.editMode) {
      const StartDate = moment(this.product.pickUp.StartDate);
      const EndDate = moment(this.product.pickUp.EndDate);
      validTime = [
        {
          d: StartDate.toDate(),
          start: StartDate.format('HH:mm'),
          end: StartDate.add(1, 'minute').format('HH:mm')
        },
        {
          d: EndDate.toDate(),
          start: EndDate.format('HH:mm'),
          end: EndDate.add(1, 'minute').format('HH:mm')
        }
      ];
    }
    return validTime;
  }

  private _initDateSetting() {
  }

  private _success(): void {
    this.isProcessing = false;
    this.formSaved = true;
    NotificationsService.notify('Afsluttet med success', 'success', 'top');
    this._router.navigate([this.successUrl]).then();
  }

  private _handleError(error) {
    this._errorHandlerService.handleError(error || { code: -400 }, 'produkt');
    this.isProcessing = false;
  }
}
