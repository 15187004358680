import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { takeWhile } from 'rxjs/operators';

import {
  DateFormatService,
  ErrorhandlerService,
  NotificationsService
} from 'app/core/services';
import { listAnimate, listItemFade } from 'app/shared/animations';
import { ValidationMessage } from 'app/shared/interface';
import { DateToString } from 'app/shared/pipes';
import {
  Order_Validation_Messages,
  SingleOrder,
  ThankYouSms,
  DataService,
  OrderService
} from 'app/features/order-and-pickup/order';
import { checkWhiteSpace } from 'app/core/validators';
import { MbscDatepickerOptions } from '@mobiscroll/angular';
import { COMMON_DATETIMEPICKER_SETTINGS } from 'app/shared/data';
import { DatepickerBase } from '@mobiscroll/angular/dist/js/core/components/datepicker/datepicker';

@Component({
  selector: 'coop-order-edit',
  templateUrl: './order-edit.component.html',
  styleUrls: ['./order-edit.component.scss'],
  providers: [DateToString],
  animations: [listItemFade, listAnimate]
})
export class OrderEditComponent implements OnInit, OnDestroy {
  pageTitle: string;
  previousUrl: string;
  parentUrl: string;
  order: SingleOrder;
  orderMessageForm: FormGroup;
  cancelOrderForm: FormGroup;
  smsMessageForm: FormGroup;
  notificationMessageForm: FormGroup;
  isProcessing: boolean = false;
  formSaved: boolean = false;
  activeTab: number;
  cancelOrder: boolean = false;
  showSmsBox: boolean = false;
  pickedUp: boolean;
  showSendNotification: boolean = false;
  notificationSent: boolean = false;
  orderFinished: boolean = false;
  showNotifyPicker: boolean = false;
  selectedNotify: number;
  tempSelectedNotify: number;
  notificationCount: number = 0;
  showPrintModal: boolean = false;
  datePickerSettings: MbscDatepickerOptions = {
    ...COMMON_DATETIMEPICKER_SETTINGS, ...{
      dateWheels: 'YYYY MMMM DD',
      dateFormat: 'YYYY-MM-DD',
      stepMinute: 15
    },
    onChange: (_, inst) => this._deleteNotification(inst)
  };
  deliveryStatus: string[] = ['Afvist', 'Ikke afhentet', 'Afhentet'];
  message: ThankYouSms;
  validationMessages: ValidationMessage = Order_Validation_Messages;

  private _subscriptionState: boolean = true;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _router: Router,
    private _errorHandlerService: ErrorhandlerService,
    private _dateToString: DateToString,
    private _orderService: OrderService,
    private _dataService: DataService
  ) {
  }

  ngOnInit() {
    this._setParentUrl();
    this._dataService.currentTab.subscribe((tab) => (this.activeTab = tab));
    document.body.classList.add('overlay');
    this.order = this._activatedRoute.snapshot.data['order'];
    this._setPageTitle();
    this.previousUrl = this.parentUrl + '/order';
    this._setupOrderForm();
    this._initComponent();
  }

  ngOnDestroy() {
    document.body.classList.remove('overlay');
    this._subscriptionState = false;
  }

  initNotification(): FormGroup {
    return this._formBuilder.group({
      notification: [
        '',
        [Validators.required, checkWhiteSpace, Validators.maxLength(300)]
      ],
      sent_date: [''],
      hoursAfter: ['']
    });
  }

  addNotification() {
    const notifications = this.notificationMessageForm.controls[
      'notificationMessage'
      ] as FormArray;
    const notification = this.initNotification();
    notifications.push(notification);
    this.notificationCount++;
  }

  removeNotification(i: number) {
    const notifications = this.notificationMessageForm.controls[
      'notificationMessage'
      ] as FormArray;
    notifications.removeAt(i);
    this.notificationCount--;
    if (this.notificationCount === 0) {
      this.showSendNotification = false;
    }
  }

  addNumber() {
    if (this.tempSelectedNotify !== 2) {
      this.tempSelectedNotify++;
    }
  }

  subtractNumber() {
    if (this.tempSelectedNotify !== 1) {
      this.tempSelectedNotify--;
    }
  }

  setNotification() {
    this.showNotifyPicker = true;
    this.selectedNotify = 1;
    this.tempSelectedNotify = this.selectedNotify;
  }

  notificationSelect(options: boolean) {
    this.selectedNotify = this.tempSelectedNotify;
    this.showNotifyPicker = false;
    if (options) {
      for (let i = 0; i < this.selectedNotify; i++) {
        this.addNotification();
        const notification = this.order.default_message.notification;
        if (notification) {
          this.notificationMessageForm.controls['notificationMessage'][
            'controls'
            ][i].patchValue({
            notification: notification[i].comment,
            hoursAfter: notification[i].hoursAfter
          });
        }
      }
    }
    if (!this.showSendNotification && !this.showSmsBox && options) {
      this.showSendNotification = true;
      this._setDefaultTimeOnCreate();
    }
  }

  save() {
    if (this.order.data.orderStatus !== 2) {
      if (!this._isValid(this.orderMessageForm)) {
        return;
      }
    }
    if (!this.isProcessing) {
      this.isProcessing = true;
      const sentData = this._prepareMessageData();
      this._orderService
        .saveOrderMessages(sentData, this.order.data.id, this.cancelOrder)
        .pipe(takeWhile(() => this._subscriptionState))
        .subscribe({
          next: (res: SingleOrder) => this._success(res),
          error: (err) => this._handleError(err)
        });
    }
  }

  cancel() {
    this.cancelOrder = !this.cancelOrder;
    this.showSendNotification = false;
    this._setPageTitle();
    if (this.cancelOrder) {
      this.pageTitle =
        'Annuller ordre' + ' ' + '#' + this.order.data.orderNumber;
    }
  }

  togglePrintPopup() {
    this.showPrintModal = false;
    NotificationsService.notify('Afsluttet med success', 'success', 'top');
    this._router.navigate([`/${this.parentUrl}/order`]).then();
  }

  smsBox(pickedUp: boolean) {
    this.pickedUp = pickedUp;
    const sms = pickedUp
      ? this.order.default_message.pickedUpMessage
      : this.order.default_message.notPickedUpMessage;
    this.smsMessageForm.patchValue({
      sms
    });
    this.showSmsBox = true;
  }

  sendSms() {
    if (!this._isValid(this.smsMessageForm)) {
      return;
    }
    if (!this.isProcessing) {
      this.isProcessing = true;
      const sentData = this._prepareFinishedOrderData();
      this._orderService
        .saveOrderMessages(sentData, this.order.data.id)
        .pipe(takeWhile(() => this._subscriptionState))
        .subscribe({
          next: (res: SingleOrder) => this._success(res),
          error: (err) => this._handleError(err)
        });
    }
  }

  sendNotification() {
    if (!this._isValid(this.notificationMessageForm)) {
      return;
    }
    if (!this.isProcessing) {
      this.isProcessing = true;
      // changing the date format
      for (let i = 0; i < this.selectedNotify; i++) {
        const date =
          this.notificationMessageForm.controls['notificationMessage'][
            'controls'
            ][i].value.sent_date;
        this.notificationMessageForm.controls['notificationMessage'][
          'controls'
          ][i].value.sent_date = this._dateToString.transform(date);
      }
      this._orderService
        .saveOrderMessages(
          this.notificationMessageForm.value,
          this.order.data.id
        )
        .pipe(takeWhile(() => this._subscriptionState))
        .subscribe({
          next: (res: SingleOrder) => this._success(res),
          error: (err) => this._handleError(err)
        });
    }
  }

  saveCanceledOrder() {
    if (!this._isValid(this.cancelOrderForm)) {
      return;
    }
    if (!this.isProcessing) {
      this.isProcessing = true;
      // changing the date format
      this.cancelOrderForm.value.sent_date = this._dateToString.transform(
        moment().toDate()
      );
      let sentData;
      sentData = {
        sentComment: this.cancelOrderForm.value,
        deliveryStatus: '0'
      };
      this._orderService
        .saveOrderMessages(sentData, this.order.data.id, this.cancelOrder)
        .pipe(takeWhile(() => this._subscriptionState))
        .subscribe({
          next: (res: SingleOrder) => this._success(res),
          error: (err) => this._handleError(err)
        });
    }
  }

  clearOrder() {
    if (!this.isProcessing) {
      this.isProcessing = true;
      const sentData = {
        deliveryStatus: '0'
      };
      this._orderService
        .changeDeliveryStatus(sentData, this.order.data.id)
        .pipe(takeWhile(() => this._subscriptionState))
        .subscribe({
          next: (res: SingleOrder) => this._success(res),
          error: (err) => this._handleError(err)
        });
    }
  }

  print(id: number) {
    if (!this.isProcessing) {
      this.isProcessing = true;
      this._orderService
        .printOrder(id)
        .pipe(takeWhile(() => this._subscriptionState))
        .subscribe({
          next: () => this._printSuccess(),
          error: (err) => this._handleError(err)
        });
    }
  }

  private _setParentUrl() {
    this._activatedRoute.parent.data
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe((url) => (this.parentUrl = url['parentUrl']));
  }

  private _setupOrderForm() {
    this.orderMessageForm = this._formBuilder.group({
      comment: [
        '',
        [Validators.required, checkWhiteSpace, Validators.maxLength(300)]
      ],
      sent_date: ['']
    });
    this.cancelOrderForm = this._formBuilder.group({
      comment: [
        '',
        [Validators.required, checkWhiteSpace, Validators.maxLength(300)]
      ],
      sent_date: ['']
    });
    this.smsMessageForm = this._formBuilder.group({
      sms: [
        '',
        [Validators.required, checkWhiteSpace, Validators.maxLength(300)]
      ],
      sent_date: ['']
    });
    this.notificationMessageForm = this._formBuilder.group({
      notificationMessage: this._formBuilder.array([])
    });
  }

  private _initComponent() {
    if (this.order.data.notificationMessage.length) {
      this.notificationSent = true;
    }
    if (this.order.data.deliveryStatus !== null) {
      this.orderFinished = true;
      this.previousUrl = this.parentUrl + '/order/afsluttet';
    }
    if (
      this.order.data.orderStatus !== -1 &&
      this.order.data.deliveryStatus === null
    ) {
      if (this.order.default_message) {
        this.orderMessageForm.patchValue(this.order.default_message);
      }
    }
    if (
      this.order.data.orderStatus === -1 &&
      this.order.data.deliveryStatus === null
    ) {
      if (this.order.default_message) {
        if (this.order.default_message.comment) {
          this.orderMessageForm.patchValue({
            comment: this.order.default_message.comment
          });
        }
      }
    }
  }

  private _setPageTitle() {
    const orderNumber = this.order.data.orderNumber;
    const orderStatus = this.order.data.orderStatus;
    if (orderStatus === 1) {
      this.pageTitle = 'Ny bestilling' + ' ' + '#' + orderNumber;
    }
    if (orderStatus === 2) {
      this.pageTitle = 'Klargør til produktion' + ' ' + '#' + orderNumber;
    }
    if (orderStatus === 0) {
      this.pageTitle = 'Bestilling' + ' ' + '#' + orderNumber;
    }
    if (orderStatus === -1) {
      this.pageTitle = 'Klar til afhentning' + ' ' + '#' + orderNumber;
    }
    if (this.order.data.deliveryStatus !== null) {
      this.pageTitle = 'Afsluttet bestilling' + ' ' + '#' + orderNumber;
    }
  }

  private _setDefaultTimeOnCreate() {
    const sendDateOne = DateFormatService.timeIntervalFormat(
      moment().add(
        this.order.default_message.notification[0].hoursAfter,
        'hours'
      )
    );
    this.notificationMessageForm.controls['notificationMessage'][
      'controls'
      ][0].patchValue({
      sent_date: sendDateOne
    });
    if (this.selectedNotify === 2) {
      const sendDateTwo = DateFormatService.timeIntervalFormat(
        moment().add(
          this.order.default_message.notification[1].hoursAfter,
          'hours'
        )
      );
      this.notificationMessageForm.controls['notificationMessage'][
        'controls'
        ][1].patchValue({
        sent_date: sendDateTwo
      });
    }
  }

  private _deleteNotification(inst: DatepickerBase) {
    if (inst._el.id === 'select-notification-1') {
      this.removeNotification(0);
    }
    if (inst._el.id === 'select-notification-2') {
      this.removeNotification(1);
    }
  }

  private _getData() {
    this._orderService
      .getSingleOrder(this.order.data.id)
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe((order) => (this.order = order));
  }

  private _prepareMessageData() {
    // changing the date format
    const currentDate = this._dateToString.transform(moment().toDate());
    this.orderMessageForm.value.sent_date = currentDate;
    let sentData;
    let status;
    const orderStatus = this.order.data.orderStatus;
    if (orderStatus === 1) {
      status = 2;
      sentData = {
        approvedDate: currentDate
      };
    }
    if (orderStatus === 2) {
      status = 0;
    }
    if (orderStatus === 0) {
      status = -1;
    }
    sentData = {
      ...sentData,
      sentComment: orderStatus !== 2 ? this.orderMessageForm.value : null,
      orderStatus: status
    };
    return sentData;
  }

  private _prepareFinishedOrderData() {
    // changing the date format
    this.smsMessageForm.value.sent_date = this._dateToString.transform(
      moment().toDate()
    );
    let sentData;
    if (this.pickedUp) {
      sentData = {
        pickedUpComment: this.smsMessageForm.value,
        deliveryStatus: '2'
      };
    }
    if (!this.pickedUp) {
      sentData = {
        notPickedUpComment: this.smsMessageForm.value,
        deliveryStatus: '1'
      };
    }
    return sentData;
  }

  private _printSuccess(): void {
    this.isProcessing = false;
    NotificationsService.notify('Afsluttet med success', 'success', 'top');
  }

  private _success(res: SingleOrder): void {
    this.isProcessing = false;
    this.formSaved = true;
    if (!this.cancelOrder) {
      if (this.orderFinished) {
        this._router.navigate([`/${this.parentUrl}/order/afsluttet`]).then();
        return;
      }
      const orderStatus = this.order.data.orderStatus;
      if (orderStatus === 1) {
        this._dataService.changeTab(2);
      }
      if (orderStatus === 2) {
        this._dataService.changeTab(0);
      }
      if (orderStatus === 0) {
        this._dataService.changeTab(-1);
        this._getData();
        this.pageTitle =
          'Klar til afhentning' + ' ' + '#' + this.order.data.orderNumber;
        this.setNotification();
        return;
      }
      if (orderStatus === -1) {
        if (this.showSendNotification && !this.showSmsBox) {
          this.showSendNotification = false;
          this.notificationSent = true;
          this._getData();
          return;
        }
        if (this.showSmsBox) {
          this.showSmsBox = false;
        }
      }
    }
    if (res.data.printError) {
      this.showPrintModal = true;
    }
    if (!res.data.printError) {
      NotificationsService.notify('Afsluttet med success', 'success', 'top');
      this._router.navigate([`/${this.parentUrl}/order`]).then();
    }
  }

  private _isValid(form: FormGroup) {
    if (form.status === 'INVALID') {
      this._handleError('');
      return false;
    }
    return true;
  }

  private _handleError(error) {
    this._errorHandlerService.handleError(error || { code: -400 }, 'order');
    this.isProcessing = false;
  }
}
