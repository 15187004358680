import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { catchError } from 'rxjs/operators';

import { ErrorhandlerService } from 'app/core/services';
import { WebsiteList, WebsiteService } from 'app/features/website';

@Injectable({
  providedIn: 'root',
})
export class WebsiteResolverService  {
  constructor(
    private _websiteService: WebsiteService,
    private _errorHandlerService: ErrorhandlerService
  ) {}
  resolve(route: ActivatedRouteSnapshot) {
    const id = route.params['id'];
    return this._websiteService
      .getSingleWebsite(id)
      .pipe(
        catchError((err) => this._errorHandlerService.routeResolverError(err))
      );
  }
}
@Injectable({
  providedIn: 'root',
})
export class WebsiteListResolverService  {
  constructor(
    private _websiteService: WebsiteService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this._websiteService
      .getWebsiteListing()
      .pipe(
        catchError((err) => this._errorHandlerService.routeResolverError(err))
      );
  }
}
