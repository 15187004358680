import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ApiService } from 'app/core/services';
import { Folders } from 'app/shared/interface';
import {
  Product,
  ProductListData,
  ProductTemplate,
  ShopHours,
} from '../interface';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(
    private _apiService: ApiService,
    private _httpService: HttpClient
  ) {}

  getShopHours(): Observable<ShopHours[]> {
    const url = `product/shop`;
    return this._apiService.get(url).pipe(map((res) => res['data']));
  }

  saveProduct(params: Product, editMode: boolean): Observable<object> {
    if (editMode) {
      return this._apiService.put(
        `update-product`,
        ApiService.CreateFormData(params)
      );
    }
    return this._apiService.post(`product`, ApiService.CreateFormData(params));
  }

  getProductListing(
    page: number,
    category: string,
    latest: boolean,
    active: string,
    searchString: string
  ): Observable<ProductListData> {
    let url = `products`;
    const isActive = active === 'active';
    if (category && category !== 'Alle') {
      url += `?category=${category}`;
    }
    if (active !== 'Alle' && category !== 'Alle') {
      url += `&active=${isActive}&latest=${latest}`;
    }
    if (active !== 'Alle' && category === 'Alle') {
      url += `?active=${isActive}&latest=${latest}`;
    }
    if (active === 'Alle' && category !== 'Alle') {
      url += `&latest=${latest}`;
    }
    if (active === 'Alle' && category === 'Alle') {
      url += `?latest=${latest}`;
    }
    if (searchString) {
      url += `&searchString=${searchString}`;
    }
    url += `&page=${page}`;
    return this._apiService.get(url);
  }

  getSingleProduct(id: number): Observable<Product> {
    const url = `product?id=${id}`;
    return this._apiService.get(url).pipe(map((res) => res['data']));
  }

  getProductDetails(id: number): Observable<Product> {
    const url = `product/${id}/stock`;
    return this._apiService.get(url).pipe(map((res) => res['data']));
  }

  getProductTemplatesFolder(): Observable<Folders[]> {
    const url = 'templates/products/folders';
    return this._apiService.get(url).pipe(map((res) => res['data']));
  }

  getProductTemplates(
    folderId: string,
    page: number
  ): Observable<ProductTemplate[]> {
    const url = `templates/products/${folderId}/list?page=${page}`;
    return this._apiService.get(url);
  }

  getProductSingleTemplate(id: number): Observable<Product> {
    const url = `templates/products/${id}`;
    return this._apiService.get(url).pipe(map((res) => res['data']));
  }

  searchProductTemplate(searchTerms: string , folderId?:string ,page?:number ){


    if(searchTerms){
      searchTerms=`/${searchTerms}`
    }
    if(page){
      searchTerms+=`?page=${page}`
    }
    let url = `templates/products/search${searchTerms}`;
    if(folderId){
      let seperator = page ?  '&' : '?'
      url += `${seperator}category=${folderId}`;
    }
    return this._apiService.get(url);
  }

  deleteProduct(id: number) {
    const url = `product/${id}`;
    return this._apiService.del(url);
  }

  getOrderList(id: number): Observable<string> {
    const url = `product/${id}/download`;
    return this._httpService
      .get(`${environment.apiurl}${url}`, { responseType: 'blob' })
      .pipe(catchError(this._handlerError()));
  }

  private _handlerError() {
    return (error: HttpErrorResponse): Observable<any> => {
      return throwError(() => error);
    };
  }
}
