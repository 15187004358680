import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
  ActivatedRoute,
  NavigationEnd,
  Params,
  Router,
  UrlSegment
} from '@angular/router';
import * as moment from 'moment';
import {combineLatest} from 'rxjs';
import {take, takeWhile} from 'rxjs/operators';

import {SharedNames} from 'app/core/data/defaultValues';
import {
  ClientService,
  CreateOptionDataService,
  DateFormatService,
  ErrorhandlerService,
  FormFunctionsService,
  HelperService,
  NotificationsService,
  TokenService
} from 'app/core/services';
import {
  checkIfEndDateIsLessThanWeekBeforeStartDate,
  checkPriceMinimum,
  checkPriceRegex,
  checkStartEndTime,
  checkWhiteSpace, customMinValidator
} from 'app/core/validators';
import {FacebookEventsService} from 'app/features/facebookconnect/services';
import {animateParent, slideAnimation} from 'app/shared/animations';
import {
  COMMON_DATETIMEPICKER_SETTINGS,
  COMMON_SELECT_SETTINGS,
  RECURRING_DATE_LIST
} from 'app/shared/data';
import {Client, CreateOption, ValidationMessage} from 'app/shared/interface';
import {DateToString, LeftPad, NumberPad} from 'app/shared/pipes';
import {
  Offer_Validation_Messages,
  ChannelSelectionModes,
  DateDescription,
  RecurringPromo,
  Shared,
  OfferDataService,
  OfferService
} from 'app/features/offer';
import {ColorThiefService} from 'app/core/services';
import {MbscDatepickerOptions} from '@mobiscroll/angular';
import {DatepickerBase} from '@mobiscroll/angular/dist/js/core/components/datepicker/datepicker';
import * as dayjs from "dayjs";

@Component({
  selector: 'coop-recurring-offer',
  templateUrl: './recurring-offer.component.html',
  styleUrls: ['./recurring-offer.component.scss'],
  animations: [animateParent, slideAnimation],
  providers: [LeftPad, NumberPad, DateToString]
})
export class RecurringOfferComponent implements OnInit, OnDestroy {
  showLengthCounter: boolean = false;
  tabs = SharedNames;
  tabName: string;
  allowedChannels = {
    coop: true,
    facebook: true,
    sms: false,
    mail: false,
    web: false,
    dit: false
  };
  showSelectBox: boolean = false;
  showSelectBoxEnddate:boolean = false;
  showSelectBoxstart = false;
  showSelectBoxend = false;
  selectBoxType: string;
  tempSelectedDay: number;
  showChannelSelection: boolean = false;
  hasImage: boolean = false;
  showParent: string = 'visible';
  tab: string = 'coop';
  orderTab = ['coop', 'facebook', 'sms', 'mail'];
  pageTitle: string = 'Opret Ugentlig god pris';
  promo: RecurringPromo;
  editMode = false;
  imageUrl = {
    largeImage: 'assets/images/camera@3x.jpg',
    thumbnail: ''
  };
  formSaved: boolean = false;
  isProcessing: boolean = false;
  clients: Client[];
  routeId: string;
  promoForm: FormGroup;
  availablePushCount: number;
  guid: string = '';
  userType: string;
  shared = {
    coop: false,
    facebook: false,
    sms: false,
    mail: false,
    dit: false,
    screen: false,
    web: false
  };
  createOption: Shared;
  createSettings: CreateOption;
  numbers = [];
  priceDescSelectSettings = {
    ...COMMON_SELECT_SETTINGS, ...{
      data: [
        'stk',
        'kg',
        'g',
        'liter',
        'ml',
        'pakke',
        'pakker',
        'sæt',
        'kasse',
        'kasser',
        'ramme',
        'rammer'
      ]
    }
  };
  datePickerSettings: MbscDatepickerOptions = {
    ...COMMON_DATETIMEPICKER_SETTINGS, ...{
      dateWheels: 'YYYY MMMM DD',
      dateFormat: 'YYYY-MM-DD',
      stepMinute: 15,
      onOpen: (_, inst) => this._showDatePicker(inst),
      onChange: (_, inst) => this._setDate(inst)
    }
  };
  previousUrl: string;
  currentUrl: string;
  parentUrl: string;
  successUrl: string;
  shopName: string;
  price = [];
  offerGroup: string;
  tempShared: Shared;
  tempValidateModes = [];
  validationMessages: ValidationMessage = Offer_Validation_Messages;
  origin: string;
  channelSelectionModes: ChannelSelectionModes;
  selectedChannels: Shared;
  tempTitle: string;
  offerStartDay;
  recurringDateList = RECURRING_DATE_LIST;
  weekDays = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
  ];
  selectedRecurringDates = {
    start: 0,
    end: 0
  };
  recurringRange = [];
  recurringDateDescription: DateDescription = {
    startDateDescription: '',
    endDateDescription: '',
    validRangeDescription: '',
    startDayDescription: ''
  };
  archiveTab: boolean = false;
  showPricePopup = false;
  saveWithNoPrice = false;
  private _subscriptionState: boolean = true;
  showPriceSection: boolean = false;
  defaultStartDate = dayjs().format('YYYY-MM-DD')
  startDate = dayjs().format('YYYY-MM-DD')
  startTime = dayjs().format('HH:mm')
  endDate = dayjs().add(6, 'days').format('YYYY-MM-DD')
  endTime = dayjs().format('HH:mm')
  dateRange = [this.startDate, this.endDate]
  showCalendar: boolean = false
  selectedStartDay:string;
  selectedEndDay:string;
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _dateToString: DateToString,
    private _offerService: OfferService,
    private _facebookEvents: FacebookEventsService,
    private _router: Router,
    private _clientService: ClientService,
    private _leftPad: LeftPad,
    private _createOptionService: CreateOptionDataService,
    private _errorHandlerService: ErrorhandlerService,
    private _numberPad: NumberPad,
    private _offerDataService: OfferDataService
  ) {
  }

  ngOnInit() {
    document.body.classList.add('overlay');
    // to hide when order list is showing
    this._setupRouteData();
    this.createDefaultCalendar()
    this._buildForm();
    this._initComponent();
    this.currentUrl = this._router.url;
    // setting default previousUrl
    this._setPreviousUrl();
    this._setUrlData();

  }

  private createDefaultCalendar() {
    this.startDate = dayjs().format('YYYY-MM-DD')
    this.startTime = dayjs().format('HH:mm')
    this.endDate = dayjs().add(6, 'days').format('YYYY-MM-DD')
    this.endTime = dayjs().format('HH:mm')
    this.dateRange = [this.startDate, this.endDate]
  }

  private setupCalendarForEditMode() {
    this.showCalendar = true
    this.startDate = dayjs(this.promo.startDate).format('YYYY-MM-DD')
    this.startTime = dayjs(this.promo.startDate).format('HH:mm')
    this.endDate = dayjs(this.promo.endDate).add(6, 'days').format('YYYY-MM-DD')
    this.endTime = dayjs(this.promo.endDate).format('HH:mm')
    this.dateRange = [this.startDate, this.endDate]
    this.promoForm.patchValue({
      dateRange: this.dateRange
    })
  }

  toggleCalendar() {
    this.showCalendar = !this.showCalendar
  }


  formatDate(date: string) {
    dayjs.locale('da');
    return dayjs(date).format('D. MMMM');
  }

  onStartTimeChange(event: any) {
    this.startTime = event.valueText
    this.concatDateTime()
  }

  onEndTimeChange(event: any) {
    this.endTime = event.valueText
    this.concatDateTime()
  }

  getDateTime(event: any) {
    if (this.editMode) {
      this.startDate = this.editMode ? this.startDate : dayjs().format('YYYY-MM-DD');
      this.endDate = dayjs(event[0]).format('YYYY-MM-DD')
      if (event[1]) {
        this.endDate = dayjs(event[1]).format('YYYY-MM-DD')
      }
    } else {
      this.startDate = dayjs(event[0]).format('YYYY-MM-DD')
      if (event[1]) {
        this.endDate = dayjs(event[1]).format('YYYY-MM-DD')
      }
    }
    this.concatDateTime()
  }


  concatDateTime() {
    let startDateTime = this.startDate + ' ' + this.startTime
    let endDateTime = this.endDate + ' ' + this.endTime
    this.promoForm.patchValue({
      startDate: startDateTime,
      endDate: endDateTime
    })
  }

  showCounter() {
    this.showLengthCounter = !this.showLengthCounter;
  }

  ngOnDestroy(): void {
    document.body.classList.remove('overlay');
    this._subscriptionState = false;
  }

  confirmedShareOptions(confirmShares: Shared) {
    if (this.shared !== confirmShares) {
      this.shared = JSON.parse(JSON.stringify(confirmShares));
      this.showChannelSelection = false;
      this.pageTitle = this.tempTitle;
      this._setShareTabName();
    }
  }

  imageProcessing(e) {
    this.isProcessing = e;
  }

  // get the uploaded image from image slider
  getImagesUrl(images) {
    this.imageUrl.largeImage = images.largeImage;
    this.imageUrl.thumbnail = images.thumbnail;
    this.promoForm.patchValue({
      largeImage: images.largeImage,
      thumbnail: images.thumbnail
    });
  }

  toggleChannelSelection() {
    if (!this.showChannelSelection) {
      this.tempValidateModes = this._setUpValidModes(this.shared);
      this.tempTitle = this.pageTitle;
      this.pageTitle = 'Ugentlig God Pris - Vælg kanaler';
    }
    if (this.showChannelSelection) {
      this.pageTitle = this.tempTitle;
    }
    this.showChannelSelection = !this.showChannelSelection;
  }

  updateChannelState(channelState: Shared): void {
    this.tempShared = channelState;
    this.tempValidateModes = this._setUpValidModes(channelState);
  }

  recurringDateSelection(dayNum) {
    // cancelling user input while still in archive mode
    if (this.archiveTab) {
      return;
    }
    if (this.selectBoxType === 'start') {
      this._setRecurringStartDate(this._getDay(dayNum, true));
    }
    if (this.selectBoxType === 'end') {
      this._setRecurringEndDate(this._getDay(dayNum, true));
    }
    this._setActiveRecurringRange();
    this._setRecurringDateDescription();
    this.showSelectBox = false;
  }

  // setup validateModes
  private _setUpValidModes(shared): Array<string> {
    const validModes = [];
    for (const key in this.orderTab) {
      if (shared[this.orderTab[key]]) {
        validModes.push(this.orderTab[key]);
      }
    }
    return validModes;
  }

  // save the form
  save() {
    if (!this._isFullFormValid()) {
      return;
    }
    if (!this._getForm().value.inAppParameters.price && !this.saveWithNoPrice) {
      this.showPricePopup = true;
      return;
    }

    this.saveLogic();
  }

  saveLogic() {
    const promoForm = this._prepareFormValues(this._getForm());
    if (this.userType === '1') {
      this._savePromo(promoForm);
    }
  }

  togglePricePopup(event) {
    this.showPricePopup = event;
    this.saveWithNoPrice = false;
  }


  saveWithoutPrice(event) {
    this.saveWithNoPrice = event;
    this.save();
  }


  selectBox(event) {
    if (this.archiveTab) {
      return;
    }
    this.selectBoxType = event;
    if (this.selectBoxType === 'start') {
      this.showSelectBox = !this.showSelectBox;
      this.showSelectBoxEnddate = false;
      // this.showSelectBoxstart = !this.showSelectBoxstart;
      this.tempSelectedDay = this.selectedRecurringDates.start;
      if(this.selectedStartDay === undefined){
        this.selectedStartDay = this.recurringDateList[this.selectedRecurringDates.start]?.dayName;
      }
    }
    if (this.selectBoxType === 'end') {
      this.showSelectBox = false;
      this.showSelectBoxEnddate = !this.showSelectBoxEnddate;
      // this.showSelectBoxend = !this.showSelectBoxend;
      this.tempSelectedDay = this.selectedRecurringDates.end;

      if(this.selectedEndDay === undefined){
        this.selectedEndDay = this.recurringDateList[this.selectedRecurringDates.end]?.dayName;
      }

    }
  }

  handleClickoutside(){
    this.showSelectBoxEnddate =  this.showSelectBox = false;
  }
  onSelect(day:string, type:string){

    if(type === 'start'){
      this.showSelectBox = false;
      this.selectedStartDay = day;
      // this.showSelectBoxstart = false;

    }
    else{
      this.showSelectBoxEnddate = false;
      this.selectedEndDay = day;
      // this.showSelectBoxend = false;
    }

  }

  closeSelectBox() {
    this.showSelectBox = false;
  }

  private _savePromo(promoForm) {
    const toUpdate = this._checkToUpdateOrCreate();
    this._offerService
      .saveRecurringPromo(promoForm, toUpdate)
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: () => this._success(),
        error: (err) => this._handleError(err)
      });
  }

  private _checkToUpdateOrCreate(): boolean {
    let toUpdate = false;
    if (this.editMode) {
      toUpdate = !(this.promo.status === 0 && !this.archiveTab);
    }
    return toUpdate;
  }

  private _checkIfArchive() {
    if (this.promo.status === 0) {
      this.archiveTab = true;
    }
    if (this.promo.status !== 0) {
      this.archiveTab = false;
    }
    // disable the form
    this._changeFormStatus(this.archiveTab);
  }

  private _changeFormStatus(archive: boolean) {
    if (archive) {
      this.promoForm.disable();
    }
    if (!archive) {
      this.promoForm.enable();
    }
  }

  useAsTemplate() {
    const newStartDate = moment().toDate();
    const newEndDate = moment(newStartDate).add(7, 'days').toDate();
    this.promoForm.patchValue({
      startDate: newStartDate,
      endDate: newEndDate
    });
    this.archiveTab = false;
    this._setRecurringDateEnvironment(newStartDate, newEndDate, false);
    this._changeFormStatus(this.archiveTab);
  }

  deletePromo() {
    const notification = 'Er du sikker?';
    if (!this.isProcessing) {
      this.isProcessing = true;
      NotificationsService.confirmWithCallback(notification, (accept) => {
        if (accept) {
          this._offerService
            .deleteRecurringPromo(this.promo.id)
            .pipe(takeWhile(() => this._subscriptionState))
            .subscribe({
              next: () => this._success(),
              error: (err) => this._handleError(err)
            });
        } else {
          this.isProcessing = false;
        }
      });
    }
  }

  validateNumber(event) {
    HelperService.preventInvalidNumbers(event);
  }

  private _setDefaultTimeOnCreate() {
    if (this.createSettings) {
      const startDate = DateFormatService.timeIntervalFormat(moment());
      const endDate = DateFormatService.timeIntervalFormat(
        moment().add(7, 'days')
      );
      const currForm = this._getForm();
      currForm.patchValue({
        startDate: startDate,
        endDate: endDate
      });
      this._setRecurringDateEnvironment(startDate, endDate, this.editMode);
    }
  }

  _setEditModeForm() {
    this._patchFormValues(FormFunctionsService.lowerCaseKeys(this.promo));
  }

  private _setRecurringDateEnvironment(startDate, endDate, editMode) {
    this._setRecurringOfferStartDay(startDate, editMode);
    this._setRecurringDate(startDate, endDate, editMode);
    this._getFormattedStartDate();
    this._getFormattedEndDate();
    this._setRecurringDateDescription();
  }

  private _setRecurringOfferStartDay(startDate, editMode) {
    if (editMode) {
      this.offerStartDay = this._getDay(this.promo.startDate, false);
      return;
    }
    this.offerStartDay = this._getDay(startDate, false);
  }

  private _setRecurringDate(startDate, endDate, editMode) {
    if (editMode) {
      this._setRecurringStartDate(startDate);
      this._setRecurringEndDate(endDate);
    }
    if (!editMode) {
      // default start day should be one day after the offer start day , so we add 1 day to start day
      this._setRecurringStartDate(
        this._getDay(moment(startDate).add(1, 'days'), false)
      );
      // default end day should be two day after the offer start day , so we add 2 day to start day
      this._setRecurringEndDate(
        this._getDay(moment(endDate).add(2, 'days'), false)
      );
    }
    this._setActiveRecurringRange();
  }

  private _setRecurringStartDate(day) {
    this.selectedRecurringDates.start = !isNaN(day)
      ? day
      : this.weekDays.indexOf(day);
    this._setStartDay(this.weekDays[this.selectedRecurringDates.start]);
  }

  private _setRecurringEndDate(day) {
    this.selectedRecurringDates.end = !isNaN(day)
      ? day
      : this.weekDays.indexOf(day);
    this._setEndDay(this.weekDays[this.selectedRecurringDates.end]);
  }

  private _setStartDay(day: string) {
    this.promoForm.patchValue({
      recurringData: {
        startDay: day
      }
    });
  }

  private _setEndDay(day: string) {
    this.promoForm.patchValue({
      recurringData: {
        endDay: day
      }
    });
  }

  // go back button using setPreviousUrl
  back() {
    this._router.navigate([this.previousUrl]).then();
  }

  // initialise the component values
  private _initComponent() {
    this.promo = FormFunctionsService.lowerCaseKeys(
      this._activatedRoute.snapshot.data['offer']
    );
    if (this.promo) {
      this.editMode = true;
    }
    this.channelSelectionModes = {
      create: !this.editMode,
      edit: this.editMode,
      template: false
    };
    this.createSettings = this._activatedRoute.snapshot.data['createOption'];
    this.selectedChannels = this._activatedRoute.snapshot.data['channels'];
    if (this.createSettings) {
      this.createOption = this.createSettings.share;
    }
    this.availablePushCount = TokenService.getUserDetail().availablePushCount;
    this.guid = RecurringOfferComponent._getGuid();
    this.userType = TokenService.getUserType();
    // set shared and image url value to default
    this._resetImageUrlAndShared();
    // to see if user can give out push notification
    this.shopName = TokenService.getUserDetail().name;
    this.successUrl = '/butikker/indlaeg/tilbagevendende-tilbud/list';
    this.parentUrl = this._activatedRoute.snapshot.parent.data['parentUrl'];
    if (this.editMode) {
      this._setSharedValue(this.promo.shared);
      this._setShareTabName();
      this._setEditModeForm();
      this._setRecurringDateEnvironment(
        this.promo.recurringData.startDay,
        this.promo.recurringData.endDay,
        true
      );
      this._checkIfArchive();
      if (this.promo.inAppParameters.price !== '') {
        this.updateValidation()
        this.showPriceSection = true;
      }
      this.setupCalendarForEditMode()
    }
    if (!this.editMode) {
      this._setSharedValue(this.createOption);
      this._setShareTabName();
      this._setDefaultTimeOnCreate();
    }
  }

  private _setSharedValue(options: Shared) {
    Object.assign(this.shared, options);
  }

  private static _getGuid(): string {
    return (
      RecurringOfferComponent.s4() +
      RecurringOfferComponent.s4() +
      '-' +
      RecurringOfferComponent.s4() +
      '-4' +
      RecurringOfferComponent.s4().substring(0, 3) +
      '-' +
      RecurringOfferComponent.s4() +
      '-' +
      RecurringOfferComponent.s4() +
      RecurringOfferComponent.s4() +
      RecurringOfferComponent.s4()
    ).toLowerCase();
  }

  private _computeRouteAndParentId(value: [UrlSegment[], Params]) {
    const urlSegment = value[0];
    if (this.editMode) {
      // setting temp id
      if (urlSegment.length > 1) {
        this.routeId = urlSegment[1].path;
      } else {
        this.routeId = urlSegment[0].path;
      }
    }
  }

  private _setShareTabName() {
    let validTab: any[];
    // setting tab name
    validTab = this.orderTab
      .filter((tab) => this.shared[tab])
      .map((name) => this.tabs[name]);
    this.tabName = validTab[0];
    for (let i = 1; i < validTab.length; i++) {
      this.tabName += ' / ' + validTab[i];
    }
  }

  private _setupRouteData(): void {
    this._router.events
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe((routerEvent) => {
        if (routerEvent instanceof NavigationEnd) {
          this._checkChildPage();
        }
      });
  }

  private _buildForm() {
    this.promoForm = this._formBuilder.group(
      {
        id: [''],
        coopOriginId: [''],
        shared: this._formBuilder.group({
          facebook: [false],
          coop: [false],
          sms: [false],
          mail: [false],
          web: [false],
          screen: [false],
          dit: [false]
        }),
        recurringData: this._formBuilder.group({
          startDay: [''],
          endDay: ['']
        }),
        color: [''],
        fromSystemId: [''],
        title: ['', [Validators.required, checkWhiteSpace]],
        largeImage: ['', Validators.required],
        thumbnail: [''],
        startDate: ['', Validators.required],
        endDate: ['', Validators.required],
        dateRange: [this.dateRange],
        longDescription: ['', [Validators.required, checkWhiteSpace]],
        inAppParameters: this._formBuilder.group({
          price: [''],
          priceDescription: [''],
          uom: ['']
        }),
        firstOffer: []
      },
      {
        validators: [
          checkIfEndDateIsLessThanWeekBeforeStartDate,
          checkStartEndTime
        ]
      }
    );
  }

  addPriceField() {
    this.showPriceSection = true
    this.updateValidation()
    this.promoForm.get('inAppParameters').patchValue({
      uom: 'stk'
    })
  }

  private updateValidation() {
    const validators = [checkPriceMinimum, checkPriceRegex];
    validators.push(Validators.required);
    this._updateValidators("inAppParameters.priceDescription", [
      Validators.required,
      checkWhiteSpace,
      checkPriceRegex,
      customMinValidator(1)
    ]);
    this._updateValidators("inAppParameters.price", [
      Validators.required,
      checkWhiteSpace,
      checkPriceRegex,
      customMinValidator(1)
    ]);
  }

  removePrices() {
    this.showPriceSection = false
    this.removePriceValidators()
  }

  removePriceValidators(): void {
    this._updateValidators("inAppParameters.priceDescription", null);
    this._updateValidators("inAppParameters.price", null);
    this.promoForm.get('inAppParameters').patchValue({
      price: '',
      priceDescription: '',
      uom: ''
    })
  }

  private _updateValidators(formControl: string, validators = []): void {
    this.promoForm.get(formControl).setValidators(validators);
    this.promoForm.get(formControl).updateValueAndValidity();
  }

  // ** patch form values based on the component
  private _patchFormValues(promoForm) {
    Object.assign(this.shared, promoForm.shared);
    this.offerGroup = promoForm.offer_group;
    this.promoForm.patchValue(promoForm);
    // setting image in imageSlider
    this._patchFormImageToImageUrl();
    this._setTitleInEditMode();
  }

  private _setTitleInEditMode() {
    if (this.editMode) {
      this.pageTitle = 'Rediger Ugentlig God Pris';
    }
  }

  private _patchFormImageToImageUrl() {
    const currForm = this._getForm().value;
    this.imageUrl.largeImage = currForm.largeImage;
    this.imageUrl.thumbnail = currForm.thumbnail;
    this.hasImage = true;
  }

  // setting previous Url
  private _setPreviousUrl() {
    if (this.editMode) {
      this.previousUrl = '/butikker/indlaeg/tilbagevendende-tilbud/list';
      return;
    }
    this.previousUrl = '/butikker/skab';
  }

  private _setUrlData() {
    // setting offerId and folder id and previousUrl values
    combineLatest([
      this._activatedRoute.url,
      this._activatedRoute.parent.params
    ])
      .pipe(take(1))
      .subscribe({
        next: (value: [UrlSegment[], Params]) => {
          this._computeRouteAndParentId(value);
        },
        error: (err) => this._errorHandlerService.handleError(err)
      });
  }

  // get the instance of current form
  private _getForm() {
    return this.promoForm;
  }

  // get the default imageurls for imageSliders
  private static _getDefaultImageUrl() {
    return {
      imageUrl: {
        largeImage: '../../../assets/images/camera@3x.jpg',
        thumbnail: ''
      },
      hasImage: false
    };
  }

  // date time value set for mobiscroll date-time
  private _showDatePicker(inst: DatepickerBase) {
    const dateId = inst._el.id;
    if (!this.promo) {
      // set start date at today's time 8:00
      if (dateId === 'start') {
        if (!this.promoForm.value.startDate) {
          this.promoForm.patchValue({
            startDate: moment().hour(8).minute(0).toDate()
          });
        }
      }
      // end date picker clicked
      // if start date is empty set time to today at 20:00
      if (dateId === 'end' && !this.promoForm.value.startDate) {
        this.promoForm.patchValue({
          endDate: moment().hour(20).minute(0).toDate()
        });
      }
      if (
        dateId === 'end' &&
        this.promoForm.value.startDate &&
        !this.promoForm.value.endDate
      ) {
        const startDate = moment(this.promoForm.value.startDate);
        this.promoForm.patchValue({
          endDate: startDate.hour(20).minute(0).toDate()
        });
      }
    }
  }

  private _setDate(inst: DatepickerBase) {
    const dateId = inst._el.id;
    if (dateId === 'start') {
      this._setRecurringOfferStartDay(this.promoForm.value.startDate, false);
    }
    this._getFormattedStartDate();
    this._setRecurringDateDescription();
  }

  private static _calculateRecurringRange(start: number, end: number) {
    const range = [];
    if (end === start) {
      range.push(start);
      return range;
    }
    if (end > start) {
      for (let i = start; i <= end; i++) {
        range.push(i);
      }
      return range;
    }
    if (end <= start) {
      for (let i = 0; i <= end; i++) {
        range.push(i);
      }
      for (let j = start; j <= 6; j++) {
        range.push(j);
      }
      return range;
    }
  }

  private _getDay(date, isDay: boolean) {
    if (isDay) {
      if (this.weekDays.indexOf(date) !== -1) {
        return this.weekDays.indexOf(date);
      }
      return parseInt(date, 10);
    }
    return moment(date).weekday();
  }

  private _setActiveRecurringRange() {
    this.recurringRange = RecurringOfferComponent._calculateRecurringRange(
      this.selectedRecurringDates.start,
      this.selectedRecurringDates.end
    );
    for (let i = 0; i < this.recurringDateList.length; i++) {
      if (this.recurringRange.indexOf(i) >= 0) {
        this.recurringDateList[i].inRange = true;
      }
      if (this.recurringRange.indexOf(i) < 0) {
        this.recurringDateList[i].inRange = false;
      }
    }
  }

  private _getFormattedStartDate() {
    const date = this.promoForm.value.startDate;
    const momentDate = moment(date).format('DD. MMM YYYY [kl.] HH:mm').split('d.');
    return momentDate[0];
  }

  private _getFormattedEndDate() {
    const date = this.promoForm.value.endDate;
    return moment(date).format('ll');
  }

  private _getRecurringDayRange(): string {
    const startDayName =
      this.recurringDateList[this.selectedRecurringDates.start].dayName;
    const endDayName =
      this.recurringDateList[this.selectedRecurringDates.end].dayName;
    if (startDayName === endDayName) {
      return startDayName;
    }
    return startDayName + '-' + endDayName;
  }

  private _getDayName(dayNumber): string {
    return this.recurringDateList[dayNumber].dayName;
  }

  private _setRecurringDateDescription() {
    this.recurringDateDescription = this._getRecurringDateDescription();
  }

  private _getRecurringDateDescription(): DateDescription {
    const startDesc = this._getFormattedStartDate();

    const dayValidityDesc = this._getDayName(
      this._getDay(this.promoForm.value.startDate, false)
    );
    const dayRangeDesc = this._getRecurringDayRange();
    const endDesc = this._getFormattedEndDate();
    return {
      startDateDescription: startDesc,
      startDayDescription: dayValidityDesc,
      validRangeDescription: dayRangeDesc,
      endDateDescription: endDesc
    };
  }

  // ** prepare form values for saving
  private _prepareFormValues(promoForm: FormGroup): object {
    for (const platform in this.shared) {
      if (this.shared.hasOwnProperty(platform)) {
        promoForm.patchValue({
          shared: {
            [platform]: this.shared[platform]
          }
        });
      }
    }
    // change the date format
    promoForm.value.startDate = this._dateToString.transform(
      promoForm.value.startDate
    );
    promoForm.value.endDate = this._dateToString.transform(
      promoForm.value.endDate
    );
    const str = promoForm.value.longDescription;
    promoForm.value.longDescription = str.split('+').join('%2B');
    // Add a GUID to CoopOriginID if not already present
    if (!promoForm.value.coopOriginId) {
      promoForm.value.coopOriginId = this.guid;
    }
    promoForm.value.fromSystemId = 4;
    promoForm.value.board = '0';
    return promoForm.value;
  }

  private _isFullFormValid(): boolean {
    let valid = true;
    if (this.promoForm.status === 'INVALID') {
      valid = false;
      this._handleError('');
      NotificationsService.notify('Noget gik galt. Prøv igen', 'error', 'top');
    }
    // TODO: Handle video color
    const image = document.getElementById('large-image') as HTMLImageElement;
    const color = ColorThiefService.setColor(image);
    this._getForm().patchValue({
      color: `rgba(${color},0.4)`
    });
    return valid;
  }

  // perform after successul operation
  private _success() {
    this._createOptionService.clearCreateOption();
    this.formSaved = true;
    NotificationsService.notify('Gemt med succes', 'success', 'top');
    this.isProcessing = false;
    this._offerDataService.clearChannel();
    this._router.navigate([this.successUrl]).then();
  }

  // resetting the images of sliders
  private _resetImageUrlAndShared() {
    const image = RecurringOfferComponent._getDefaultImageUrl();
    this.imageUrl = image.imageUrl;
    this.hasImage = image.hasImage;
  }

  // Check if the route is child route or parent route
  // if child route set isParentPage as false
  private _checkChildPage(): void {
    this.showParent = this._activatedRoute.children.length
      ? 'invisible'
      : 'visible';
  }


  private _handleError(error): void {
    this._errorHandlerService.handleError(error || {code: -400}, 'promo');
    this.isProcessing = false;
  }

  private static s4(): string {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
}
