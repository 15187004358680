<section
  class="app-page list-component recurring-offer-list"
  [@parentAnimate]="showParent"
>
  <div class="fixed-header">
    <coop-pageheader
      [pageTitle]="pageTitle"
      [isChild]="true"
      [routeTo]="parentUrl"
    ></coop-pageheader>
    <div class="fixed-header-content">
      <ul class="tabs three-col">
        <li
          class="tab-first"
          (click)="tab('1', $event)"
          [class.active]="activeTab == '1'"
        >
          Aktiv
        </li>
        <li
          class="tab-second"
          (click)="tab('2', $event)"
          [class.active]="activeTab == '2'"
        >
          Fremtidige
        </li>
        <li
          class="tab-third"
          (click)="tab('0', $event)"
          [class.active]="activeTab == '0'"
        >
          Arkiv
        </li>
        <hr />
      </ul>
    </div>
  </div>
  <div class="page-container">
    <div class="item-list">
      <ng-container *ngFor="let rOffer of recurringOfferList">
        <a
          class="item"
          routerLink="/butikker/indlaeg/tilbagevendende-tilbud/redigere/{{
            rOffer.id
          }}"
        >
          <figure class="item-image">
            <img src="{{ rOffer.largeImage }}" alt="" />
          </figure>
          <div class="item-detail">
            <p class="item-date">
              {{ rOffer.startDate | dateformat : true }} -
              {{ rOffer.endDate | dateformat : true }}
            </p>
            <h3 class="item-title">{{ rOffer.title }}</h3>
            <p class="item-desc">{{ rOffer.longDescription }}</p>
          </div>
          <div class="link-arrow">
            <i class="icon icon-arrow-right"></i>
          </div>
        </a>
      </ng-container>
      <h5
        class="empty-message"
        *ngIf="page == 1 && recurringOfferList.length === 0"
      >
        Beklager, ingen ugentlig god pris tilgængelige
      </h5>
    </div>
    <a
      class="login-btn btn-white-login btn-medium"
      (click)="loadMore()"
      *ngIf="!(recurringOfferList.length < page * limit)"
      >Indlæs mere..</a
    >
  </div>
</section>
<router-outlet></router-outlet>
