import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { of, throwError } from 'rxjs';
import { mergeMap, takeWhile } from 'rxjs/operators';

import {
  AppEventsService,
  ErrorhandlerService,
  FormFunctionsService,
  NotificationsService,
  SortService,
} from 'app/core/services';
import { RecurringPromo } from 'app/features/offer/interface';
import { OfferService } from 'app/features/offer/services';
import { animateParent, slideAnimation } from 'app/shared/animations';

@Component({
  selector: 'coop-recurring-offer-list',
  templateUrl: './recurring-offer-list.component.html',
  styleUrls: ['./recurring-offer-list.component.scss'],
  animations: [animateParent, slideAnimation],
})
export class RecurringOfferListComponent implements OnInit, OnDestroy {
  recurringOfferList: RecurringPromo[];
  allRecurringOfferList: RecurringPromo[];
  showParent: string = 'visible';
  pageTitle: string = 'Ugentligt god pris';
  currentUrl: string;
  parentUrl: string = '/butikker/indstillinger';
  page: number = 1;
  limit: number = 10;
  activeTab = '1';
  private _subscriptionState: boolean = true;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _errorHandlerService: ErrorhandlerService,
    private _offerService: OfferService,
    private _sortService: SortService,
    private _title: Title,
    private _appEvents: AppEventsService
  ) {}

  ngOnInit() {
    this._initComponent();
    this._router.events
      .pipe(
        takeWhile(() => this._subscriptionState),
        mergeMap((routerEvent) => {
          if (
            routerEvent instanceof NavigationEnd &&
            routerEvent.url === this.currentUrl
          ) {
            return this._getAllRecurringOffers();
          }
          return of([]);
        })
      )
      .subscribe({
        next: (res) => {
          if (res.length !== 0) {
            this._setRecurringOfferList(res);
          }
        },
        error: (err) => this._errorHandlerService.handleError(err),
      });
    this._appEvents.showLoader(false);
  }

  ngOnDestroy(): void {
    this._subscriptionState = false;
  }

  loadMore() {
    this.page++;
    this._getAllRecurringOffers()
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (posts) => {
          if (posts.length === 0) {
            NotificationsService.notify(
              'Ingen flere ugentlig god pris',
              'error',
              'top'
            );
          } else {
            this.recurringOfferList = this.allRecurringOfferList.concat(posts);
            this.allRecurringOfferList = this.recurringOfferList;
          }
        },
        error: (err) => this._errorHandlerService.handleError(err),
      });
  }

  private _initComponent() {
    this._title.setTitle('QuickCoop');
    this.currentUrl = this._router.url;
    this._setRecurringOfferList(
      this._activatedRoute.snapshot.data['recurringOffers']
    );
    this._checkChildPage();
  }

  tab(value: string, event): void {
    if (event && this.activeTab !== value) {
      this.page = 1;
      this._getAll(true, value)
        .pipe(takeWhile(() => this._subscriptionState))
        .subscribe({
          next: (offers) => this._updateList(offers, value),
          error: (err) => this._errorHandlerService.handleError(err),
        });
    }
  }

  private _updateList(offers, value) {
    this.recurringOfferList = this.allRecurringOfferList =
      FormFunctionsService.lowerCaseKeys(offers);
    this.activeTab = value;
  }

  private _setRecurringOfferList(data) {
    this.recurringOfferList = this.allRecurringOfferList =
      FormFunctionsService.lowerCaseKeys(data);
  }

  private _getAll(state, activeState) {
    if (state) {
      return this._offerService.getRecurringPromos(
        this.page,
        this.limit,
        activeState
      );
    }
    return throwError(() => 'Error');
  }

  private _getAllRecurringOffers() {
    return this._offerService.getRecurringPromos(this.page, this.limit, 1);
  }

  private _checkChildPage(): void {
    this.showParent = this._activatedRoute.children.length
      ? 'invisible'
      : 'visible';
  }
}
