import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
// import { OrderListData, OrderListTabData } from 'app/features/jatak/interface';
import { AccordionActions } from '../../../../core/services';
import {Customer} from "../../interface";
import {FormatNumberPipe} from "../../../../shared/pipes";

@Component({
  selector: 'coop-order-list-accordion',
  templateUrl: './order-list-accordion.component.html',
  styleUrls: ['./order-list-accordion.component.scss'],
  providers:[FormatNumberPipe]
})
export class OrderListAccordionComponent implements OnChanges {
  @Input() customerList: Customer[];
  @Output() collectOrder = new EventEmitter<string>();
  @Output() cancelOrder = new EventEmitter<any>();
  objectKeys = Object.keys;
  showCustomerDetail:string = '' ;
  customerId: string = "1"
  constructor(private _accordionActions: AccordionActions , private  _numberFormat:FormatNumberPipe) {}
// runs when any value of fields in @Input() decorator changes
  ngOnChanges(changes: SimpleChanges) {
    if(changes['customerList']){
      this.customerList = changes['customerList'].currentValue
    }
  }
// *** This method is  passed along as a callback for 'trackBy' to each customer ***
  // @param [index: number] => integer value starting from 0, unique to each user
  // @param [item: any] => id that uniquely identifies each customer in CoopApp or SMS
  // returns item => meaning the customers in orderLists is sorted by it
  trackByFnObj(index: number, item: any): number {
    return item;
  }
  // *** This method is  passed along as a callback for 'trackBy' to each order ***
  // @param [index: number] => integer value starting from 0 to all users, unique to each order
  // @param [item: any] => Each order by a user in CoopApp or SMS
  // returns [item.id: number] => meaning each accordion of orders in orderLists is arranged by the order id
  trackByFn(index: number, item: any): number {
    return item.id;
  }
// *** Toggles Accordion for each user in jatak order lists ***
// @params [id] => userId
// @params [accordionGroup: HTMLDivElement] => for manipulating <div> element
  onCustomerSelect(id: string) {
    this.customerId = this.customerId === id ? "0" : id
  }
  onAccordion(userId:string){
    this.showCustomerDetail  = this.showCustomerDetail === userId ? '': userId
  }

// *** It is evoked when all the orders by a customer is marked as picked-up ***
  // @param [userId: string] => id that uniquely identifies each customer in CoopApp or SMS
  onCollectOrder(order): void {
    this.collectOrder.emit(order);
  }
// *** It is evoked when all the orders by a customer is marked as delete ***
  // @param [orders: OrderListData] => Whole orders by a particular user in CoopApp or SMS
  onCancelOrder(order): void {
    this.cancelOrder.emit(order);
  }
}
