import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { debounceTime, takeWhile } from 'rxjs/operators';

import {
  ErrorhandlerService,
  NotificationsService,
  SearchService,
} from 'app/core/services';
import { slideAnimation } from 'app/shared/animations';
import {
  ProductList,
  ProductListData,
  ProductService,
} from 'app/features/order-and-pickup/product';

@Component({
  selector: 'coop-product-list',
  templateUrl: './product-list.component.html',
  animations: [slideAnimation],
  styleUrls: ['./product-list.component.scss'],
})
export class ProductListComponent implements OnInit, OnDestroy {
  @ViewChild('searchBox') searchBox;
  showParent: string = 'visible';
  pageTitle: string = 'Alle';

  productListData: ProductListData;
  productList: ProductList[];
  allProducts: ProductList[];

  currentUrl: string;
  parentUrl: string;
  inputInSearch: boolean = false;
  showFilters: string = 'inactive';
  currentCategory: string = 'Alle';
  currentLatestFilter: boolean = true;
  currentActiveFilter: string = 'Alle';
  currentSearchString: string = '';
  page: number = 1;
  scroller: Subscription;
  showFilterAndAdd: boolean = true;
  private _subscriptionState = true;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _title: Title,
    private _productService: ProductService,
    private _errorHandlerService: ErrorhandlerService,
    private _searchService: SearchService
  ) {}

  ngOnInit() {
    this._initComp();
    this.parentUrl = '/butikker/indstillinger';
  }

  ngOnDestroy() {
    this._subscriptionState = false;
  }

  loadProductListData(category: string, latest: boolean, active: string) {
    this.showFilters = 'inactive';
    this._loadData(category, latest, active, this.currentSearchString);
  }

  search(value: string) {
    if (value !== '' && value.trim().length !== 0) {
      this._loadData(
        this.currentCategory,
        this.currentLatestFilter,
        this.currentActiveFilter,
        value
      );
      this.currentSearchString = value;
      this.inputInSearch = true;
    }
  }

  closeSearch() {
    this.productList = this.allProducts;
    this.searchBox.nativeElement.value = null;
    this.inputInSearch = false;
    this.currentCategory = 'Alle';
    this.currentLatestFilter = true;
    this.currentActiveFilter = 'Alle';
    this.currentSearchString = '';
    this._loadData(
      this.currentCategory,
      this.currentLatestFilter,
      this.currentActiveFilter,
      this.currentSearchString
    );
  }

  showHideFilterBtns(): void {
    this.showFilters = this.showFilters === 'inactive' ? 'active' : 'inactive';
  }

  loadMore() {
    this.page++;
    this._getAllProducts()
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (products) => {
          if (products.data.length === 0) {
            NotificationsService.notify(
              'Ingen flere produkter',
              'error',
              'top'
            );
          } else {
            this.productListData = products;
            this.productList = this.allProducts.concat(products.data);
            this.allProducts = this.productList;
          }
        },
        error: (err) => this._errorHandlerService.handleError(err),
      });
  }

  dealWithScroll(y: number) {
    if (y === 0) {
      this.showFilterAndAdd = true;
    }
    if (y !== 0) {
      this.showFilterAndAdd = false;
    }
  }

  private _initComp() {
    this._title.setTitle('Produkt - QuickCoop');
    this.productListData = this._activatedRoute.snapshot.data['productList'];
    this.productList = this.allProducts = this.productListData.data;
    this.currentUrl = this._router.url;
    this.scroller = fromEvent(window, 'scroll')
      .pipe(
        takeWhile(() => this._subscriptionState),
        debounceTime(200)
      )
      .subscribe(() => this.dealWithScroll(window.scrollY));
  }

  private _loadData(
    category: string,
    latest: boolean,
    active: string,
    searchString: string
  ) {
    this.page = 1;
    this._productService
      .getProductListing(this.page, category, latest, active, searchString)
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (products: ProductListData) => {
          this.productListData = products;
          this.productList = this.allProducts = products.data;
          this.currentCategory = category;
          this.currentLatestFilter = latest;
          this.currentActiveFilter = active;
          this.pageTitle = category;
        },
        error: (err) => this._errorHandlerService.handleError(err),
      });
  }

  private _getAllProducts(): Observable<ProductListData> {
    return this._productService.getProductListing(
      this.page,
      this.currentCategory,
      this.currentLatestFilter,
      this.currentActiveFilter,
      this.currentSearchString
    );
  }
}
