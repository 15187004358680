export interface OkScreen {
  active?: string;
  description: string;
  longDescription?: string;
  endDate: string;
  id?: string;
  templateId?: string
  image: string;
  own: boolean;
  price: number;
  priceDescription: string;
  startDate: string;
  title: string;
  type?: string;
  displaySlots: DisplaySlot[];
  usedTimeSlot: TimeSlot[];
}
export interface OkScreenTemplateList {
  templates: OkScreen[];
  hasNextPage: boolean;
}
export interface OkScreenCalendar {
  endDate: string;
  id?: string;
  startDate: string;
  title: string;
  image?: string;
  publishDate : string ;
  expirationDate : string
}
export interface DisplaySlot {
  slotId?: string;
  endDate: string;
  startDate: string;
}
export interface TimeSlot {
  startDate: string;
  endDate: string;
}
export interface OKImageUrl {
  largeImage: string;
  thumbnail: string;
}
