import { Component, HostBinding, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { takeWhile } from "rxjs/operators";

import {
  AppEventsService,
  ErrorhandlerService,
  FormFunctionsService,
  NotificationsService
} from "app/core/services";
import {
  SingleEvent,
  SpecialEvent
} from "app/features/events/interface/event.interface";
import { Jatak } from "app/features/jatak/interface";
import { JatakLive } from "app/features/jatak-live/interface";
import { JatakLiveService } from "app/features/jatak-live/services";
import { OfferService } from "app/features/offer/services";
import { OkScreen } from "app/features/ok-screen/interface";
import { Poll, SpecialPoll } from "app/features/poll/interface/poll.interface";
import { SingleSms } from "app/features/sms/interface";
import { SmsService } from "app/features/sms/service";
import {
  detailOverlayAnimation,
  fadeInOutAnimation
} from "app/shared/animations";
import { OfferDates } from "app/shared/interface";
import {OkScreenService} from "../../../features/ok-screen";
import {Store} from "@ngxs/store";
import {SetJatakRouteStates} from "../../../features/jatak/states";
import * as dayjs from 'dayjs';

@Component({
  selector: "coop-offerpreview",
  templateUrl: "./offerpreview.component.html",
  styleUrls: ["./offerpreview.component.scss"],
  animations: [fadeInOutAnimation, detailOverlayAnimation]
})
export class OfferpreviewComponent implements OnInit {
  pAnimate: string = "inactive";
  offerType: string = "promo";
  promoOffer: any;
  promo: any;
  editUrl: string;
  reportUrl: string;
  orderListUrl: string;
  imageLoaded: boolean = false;
  channel: string;
  orderChannel = ["coop", "facebook", "sms", "mail", "web", "screen", "dit"];
  isProcessing: boolean = false;
  parentUrl: string;
  formattedDate: string = "";

  private _subscriptionState: boolean = true;

//**Declares  a host property binding/
  //return 'inactive'
  @HostBinding("@fadeInOutAnimation") get fadeInOutAnimation() {
    return "inactive";
  }
//**listen to the event emitted by the host element/
  //set pAnimate to active
  @HostListener("@fadeInOutAnimation.done") onDone() {
    this.pAnimate = "active";
  }

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _appEventsService: AppEventsService,
    private _errorHandlerService: ErrorhandlerService,
    private _offerService: OfferService,
    private _smsService: SmsService,
    private _jatakLiveService: JatakLiveService,
    private _okscreenService: OkScreenService,
    private _router: Router,
    private _store: Store
  ) {
  }
//** initialize the component */
  //promoOffer is set with the value retrieved from .data["posts"] of Routes
  //offerType is set with the value retrieved from .parent.params["type"] of Routes
  //parentUrl is set with the value retrieved from .parent.data["parentUrl"]of Routes
  //if offerType is equal to any of these 'tilbud', 'god-pris', 'nyheder' =>
  //              loop through orderChannel if promoOffer shared value of (orderChannel) is true then assign orderChannel value to           variable channel and break the loop
  //              setUpPromoDetail() =>sets up promoDetail
  //              if promoOffer fromSystemId is equal to 4 i.e god-pris => set editUrl with `/${this                                                   .parentUrl}/indlaeg/god-pris/redigere/`;
  //              if promoOffer fromSystemId is equal to 3 i.e tilbud => set editUrl with `/${this.parentUrl}/indlaeg/tilbud/redigere/`
  //              if promoOffer fromSystemId is equal to 2 i.e News => set editUrl with `/${this.parentUrl}/indlaeg/nyheder/redigere/`
 //if offerType is equal to 'arrangement' => setUpEventDetail()
  //                                      set ediUrl with `/${this.parentUrl}/arrangement/redigere/`
  //if offerType is equal to 'afstemninger' => setUpPollDetail()
  //                                           set editUrl with `/${this.parentUrl}/afstemninger/redigere/`
  //if offerType is equal to 'ja-tak' => setUpJatakDetail()
  //                                     set editUrl with `/${this.parentUrl}/ja-tak/redigere/`
  //                                     set orderListUrl with `${this.editUrl}`
  //if offerType is equal to 'ja-tak-live' => setUpJatakLiveDetail()
  //                                          set editUrl with `/${this.parentUrl}/ja-tak-live/redigere/`
  //                                          set reportUrl with `/${this.parentUrl}/ja-tak-live/reporting/`
  //if offerType is equal to sms => setUpDetail()
  //                                set editUrl with `/${this.parentUrl}/sms/sms-message/redigere/`
  //if offerType is equal to arsmode => setUpSpecialEventDetail()
  //                                    set editUrl with `/${this.parentUrl}/arrangement/arsmode/redigere/`
  //if offerType is equal to 'forsamling-event' => setUpSpecialEventDetail()
  //                                               set editUrl with `/${this.parentUrl}/arrangement/forsamling-event/redigere/`
  //if offerType is equal to 'special' => setUpSpecialPollDetail()
  //                                      set editUrl with `/${this.parentUrl}/afstemninger/special/redigere/`
  //if offerType is equal to 'forsamling-poll' => setUpSpecialPillDetail()
  //                                              set editUrl with `/${this.parentUrl}/afstemninger/forsamling-poll/redigere/`
  //if offerType is equal to 'ok-screen' => setUpOkScreenDetail()
  //                                        set editUrl with `/${this.parentUrl}/ok-screen/redigere/`
  //_setUpPreviewDate() => set up preview date
  ngOnInit() {
    this.promoOffer = FormFunctionsService.lowerCaseKeys(
      this._activatedRoute.snapshot.data["posts"]
    );
    this.offerType = this._activatedRoute.snapshot.parent.params["type"];
    this.parentUrl = this._activatedRoute.snapshot.parent.data["parentUrl"];
    if (
      this.offerType === "tilbud" ||
      this.offerType === "god-pris" ||
      this.offerType === "nyheder"
    ) {
      for (const key in this.orderChannel) {
        if (this.promoOffer.shared[this.orderChannel[key]]) {
          this.channel = this.orderChannel[key];
          break;
        }
      }
      this.setUpPromoDetail(this.promoOffer[this.channel], this.promoOffer.id);
      if (this.promoOffer.fromSystemId === 4) {
        this.editUrl = `/${this.parentUrl}/indlaeg/god-pris/redigere/`;
      }
      if (this.promoOffer.fromSystemId === 3) {
        this.editUrl = `/${this.parentUrl}/indlaeg/tilbud/redigere/`;
      }
      if (this.promoOffer.fromSystemId === 2) {
        this.editUrl = `/${this.parentUrl}/indlaeg/nyheder/redigere/`;
      }
    }
    if (this.offerType === "arrangement") {
      this.setUpEventDetail(this.promoOffer);
      this.editUrl = `/${this.parentUrl}/arrangement/redigere/`;
    }
    if (this.offerType === "afstemninger") {
      this.setUpPollDetail(this.promoOffer);
      this.editUrl = `/${this.parentUrl}/afstemninger/redigere/`;
    }
    if (this.offerType === "ja-tak") {
      this.setUpJatakDetail(this.promoOffer);
      this.editUrl = `/${this.parentUrl}/ja-tak/redigere/`;
      this.orderListUrl = `${this.editUrl}`;
    }
    if (this.offerType === "ja-tak-live") {
      this.setUpJatakLiveDetail(this.promoOffer);
      this.editUrl = `/${this.parentUrl}/ja-tak-live/redigere/`;
      this.reportUrl = `/${this.parentUrl}/ja-tak-live/reporting/`;
    }
    if (this.offerType === "sms") {
      this.setUpSmsDetail(this.promoOffer);
      this.editUrl = `/${this.parentUrl}/sms/sms-message/redigere/`;
    }
    if (this.offerType === "arsmode") {
      this.setUpSpecialEventDetail(this.promoOffer);
      this.editUrl = `/${this.parentUrl}/arrangement/arsmode/redigere/`;
    }
    if (this.offerType === "forsamling-event") {
      this.setUpSpecialEventDetail(this.promoOffer);
      this.editUrl = `/${this.parentUrl}/arrangement/forsamling-event/redigere/`;
    }
    if (this.offerType === "special") {
      this.setUpSpecialPollDetail(this.promoOffer);
      this.editUrl = `/${this.parentUrl}/afstemninger/special/redigere/`;
    }
    if (this.offerType === "forsamling-poll") {
      this.setUpSpecialPollDetail(this.promoOffer);
      this.editUrl = `/${this.parentUrl}/afstemninger/forsamling-poll/redigere/`;
    }
    if (this.offerType === "ok-screen") {
      this.setUpOkScreenDetail(this.promoOffer);
      this.editUrl = `/${this.parentUrl}/ok-screen/redigere/`;
    }
    this._setUpPreviewDate();
  }
//** set up preview date */
  //isOffer is set true if offerType is equal to 'tilbud' or 'nyheder'
  //if isOffer is true => const getMinMax is set with the value returned by _getMinMaxDates()
  //                      formattedDate is set with the value returned by _previewDatFormatting()
  //if isOffer is false and offerType is not equal to sms => set formatteDate with value returned by _previewDateFormatting()
  private _setUpPreviewDate() {
    const isOffer = this.offerType === "tilbud" || this.offerType === "nyheder";
    if (isOffer) {
      const getMinMax: OfferDates = this._getMinMaxDates(this.promoOffer);
      this.formattedDate = this._previewDateFormatting(
        getMinMax.start,
        getMinMax.end
      );
    }

    if (!isOffer && this.offerType !== "sms") {
      this.formattedDate = this._previewDateFormatting(
        this.promo.publishDate,
        this.promo.expirationDate
      );
    }
  }
//** get MinMax dates*/
  //@params[offer] => promoOffer data
  //const startArr and endArr are initialized to an empty array
  //loop through the orderChannel => const channel is set with the orderChannel value
  //                              => if offer shared channel is present => if channel is not equal to 'sms'=>
  //                                                                   push offer channel publishDate to startARR and push offer channel                                                                    expirationDate to endArr
  //                                                                   => if channel is equal to 'sms'=> push offer channel sendDate to                                                                     startArr and offer channel sendDate to endArr
  //set const minStart with the value of min value of startArr
  //set const maxEnd with the value of max value of endArr
  //return start and end
  private _getMinMaxDates(offer): OfferDates {
    const startArr = [];
    const endArr = [];
    for (const key in this.orderChannel) {
      const channel = this.orderChannel[key];
      if (offer.shared[channel]) {
        if (channel !== "sms") {
          startArr.push(moment(offer[channel].publishDate));
          endArr.push(moment(offer[channel].expirationDate));
        }
        if (channel === "sms") {
          startArr.push(moment(offer[channel].sendDate));
          endArr.push(moment(offer[channel].sendDate));
        }
      }
    }
    const minStart = moment.min(startArr).toDate();
    const maxEnd = moment.max(endArr).toDate();
    return { start: minStart, end: maxEnd };
  }
//** set up Promo detail */
  //@params[promo, promoId] => promo contains the promoOffer data of the selected channel and promoId is the id of promoOffer
  //if channel is not equal to 'sms' => set the values of promo object with promoOffer data of the selected channel
  //if channel is equal to 'sms' => set the properties of promo object with promoOffer data of the channel
  setUpPromoDetail(promo, promoId) {
    if (this.channel !== "sms") {
      this.promo = {
        id: promoId,
        title: promo.title,
        publishDate: promo.publishDate,
        expirationDate: promo.expirationDate,
        longDescription: promo.longDescription ? promo.longDescription.replace(/\n/g, "<br>") : promo.shortDescription,
        largeImage: this._setUpImageForPromo(promo),
        isVideo: this._checkIsVideo(promo)
      };
    }
    if (this.channel === "sms") {
      this.promo = {
        id: promoId,
        title: promo.title,
        publishDate: promo.sendDate,
        expirationDate: promo.sendDate,
        longDescription: promo.longDescription.replace(/\n/g, "<br>"),
        //if promo thumbnail is present then promo.thumbnail else video.thumbnail
        largeImage: promo.thumbnail ? promo.thumbnail : promo.video.thumbnail
      };
    }
  }
//** set up event detail */
  //@params[event] => promoOffer data
  //object promo properties are set with promoOffer data values
  setUpEventDetail(event: SingleEvent) {
    this.promo = {
      id: event.id,
      title: event.title,
      publishDate: event.publishDate,
      expirationDate: event.expirationDate,
      longDescription: event.description.replace(/\n/g, "<br>"),
      largeImage: event.imageUrl.largeImage // if event imageUrl largerImage is present set largeImage to with event.imageUrl largeImage                                                    else event.video.thumbnail
        ? event.imageUrl.largeImage
        : event.video.thumbnail,
      status: event.active ? "Aktiv" : "Aflyst" // if event.active is true set status to 'active' else 'cancelled'
    };
  }
//** set up Poll Detail */
  //@params[poll] => promoOffer data
  // promo object properites are set with the promoOffer data values
  setUpPollDetail(poll: Poll) {
    this.promo = {
      id: poll.id,
      title: poll.title,
      publishDate: poll.startDate,
      expirationDate: poll.endDate,
      longDescription: "",
      largeImage: poll.imageUrl.largeImage
    };
  }
//* set up Jatak Detail */
  //@params[jatak] => promoOffer data
  // promo object  properties are set with the promoOffer data values
  setUpJatakDetail(jatak: any) {
    this.promo = {
      id: jatak.id,
      title: jatak.title,
      publishDate: jatak.startDate,
      expirationDate: jatak.endDate,
      longDescription: jatak.description,
      largeImage: jatak.video
        ? jatak.video.thumbnail
        : jatak.images[0]
      // largeImage: jatak.images
      //   ? jatak.images[0]
      //   : jatak.video.thumbnail
    };
  }
//** set up Jatak Live Detail */
  //@params[jatakLive] => promoOffer data
  // promo object properties are set with the promoOffer data values
  setUpJatakLiveDetail(jatakLive: JatakLive) {
    this.promo = {
      id: jatakLive.id,
      title: jatakLive.title,
      publishDate: jatakLive.startDate,
      expirationDate: jatakLive.endDate,
      longDescription: "",
      largeImage: ""
    };
  }
//** set up Sms Detail */
  //@params[singleSms] => promoOffer data
  // promo object  properties are set with the promoOffer data values
  setUpSmsDetail(singleSms: SingleSms) {
    this.promo = {
      id: singleSms.id,
      title: "",
      publishDate: singleSms.created_time,
      expirationDate: singleSms.send_time,
      longDescription: OfferpreviewComponent._addBreakLine(singleSms.message),
      largeImage: ""
    };
  }
//** set up special Event Detail */
  //@params[event] => promoOffer data
  // promo object  properties are set with the promoOffer data values
  setUpSpecialEventDetail(event: SpecialEvent) {
    this.promo = {
      id: event.id,
      title: event.title,
      startDate: event.startDate,
      publishDate: event.publishDate,
      expirationDate: event.expirationDate,
      longDescription: this._previewDescFormatting(event.description),
      largeImage: event.imageUrl
    };
  }
//** set up special Poll Detail */
  //@params[poll] => promoOffer data
 // promo object properties are set with the promoOffer data values
  setUpSpecialPollDetail(poll: SpecialPoll) {
    this.promo = {
      id: poll.id,
      title: poll.title,
      publishDate: poll.startDate,
      expirationDate: poll.endDate,
      longDescription: this._previewDescFormatting(poll.description),
      largeImage: poll.imageUrl
    };
  }
// ** set up OkScreen Detail */
  //@params[okScreen] => promoOffer data
  // promo object properties are set with the promoOffer data values

  setUpOkScreenDetail(okScreen: OkScreen) {
    this.promo = {
      id: okScreen.id,
      title: okScreen.title,
      publishDate: okScreen.startDate,
      expirationDate: okScreen.endDate,
      longDescription: okScreen.description,
      largeImage: okScreen.image
    };
  }
//** load Image */
// imageLoaded is set to display loaded image
  loadImage(): void {
    this.imageLoaded = true;
  }
//** in case of error in loading image*/
  //promo largeImage is set with no-image.jpg
  // imageLoaded is set to display no-image.jpg
  imgError(): void {
    this.promo.largeImage = "assets/images/no-image.jpg";
    this.imageLoaded = true;
  }
//** close the offer preview tab*/
  //set pAnimate to inactive
  close(): void {
    this.pAnimate = "inactive";
  }

  handleClickOutside(){
    this.pAnimate = "inactive";
  }
//** edit the offerPreview data */
  //@params[id] => is the promoOffer id
  //navigate to the respective editUrl along with id
  //set pAnimate to 'inactive'
  edit(id: string) {
    this._router
      .navigate([this.editUrl + id])
      .then(() => (this.pAnimate = "inactive"));
  }
//** go to report */
  //@params[id] => is the promoOffer id
  //navigate to respective reportUrl along with id
  //set pAnimate to 'inactive'
  goToReport(id: string) {
    this._router
      .navigate([this.reportUrl + id])
      .then(() => (this.pAnimate = "inactive"));
  }
// ** go to Order List */
  //@params[id] => is the promoOffer id
  //navigate to the respective url along with id
  //set pAnimate to 'inactive'
  goToOrderList(id: string) {
    this.orderListUrl = `/${this.parentUrl}/ja-tak/orderlist/`;
    this._router
      .navigate([`${this.orderListUrl}/${id}`])
      .then(() => {
        (this.pAnimate = "inactive")
          // this._store.dispatch(new SetJatakRouteStates({
          //   routeUrl : `/${this.parentUrl}/kalendar/ja-tak`
          // }))
      }
      );
  }
  //** when animation completes*/
  //@params[e] => event object
  //if event.toState in which the animation completes is equal to 'inactive' and event.fromState from which the animation is triggered is not equal to void then =>
  //              if offerType is not equal to 'arsmode' and offertype is not equal to 'special' then =>  navigate to the respective                    given url
  //              if offerType is equal to 'arsmode' => navigate to respective given url
  //              if offerType is equal to 'special' => navigate to respecitve given url

  slideDone(e): void {
    if (e.toState === "inactive" && e.fromState !== "void") {
      if (this.offerType !== "arsmode" && this.offerType !== "special") {
        this._router
          .navigate([`/${this.parentUrl}/kalendar/` + this.offerType])
          .then();
      }
      if (this.offerType === "arsmode") {
        this._router
          .navigate([`/${this.parentUrl}/kalendar/arrangement`])
          .then();
      }
      if (this.offerType === "special") {
        this._router
          .navigate([`/${this.parentUrl}/kalendar/afstemninger`])
          .then();
      }
    }
  }
//** delete promo */
  //if promoOffer id is equal to promoOffer offerGroup then =>
  //      set local variable notification with message 'If you delete this, the sms tab in other linked offers / news, if any, will also be deleted. Are you sure?'
  //if promoOffer id is not equal to promoOffer offerGroup then =>
  //      set notification with message 'Are you sure?'
  // if isProcessing is true then => set isProcessing to true
  //                                confirmWithCallback => if callbackFn returns true then =>
  //                                                        delete the promo with provided id
  //                                                        subscribe => set isProcessing to false
  //                                                              .notify( notificationMessage:'All channels were deleted successfully',                                                                  type:'success' , position:'top')
  //                                                               set pAnimate to 'inactive'
  //                                                               realoadCalender() to reload the calender

  //                                                         else set isProcessing to false
  deletePromo() {
    let notification;
    if (this.promoOffer.id === this.promoOffer.offerGroup) {
      notification =
        "Hvis du sletter dette, bliver sms-fanen i andre linkede tilbud / nyheder, hvis der findes, også slettet.Er du sikker?";
    }
    if (this.promoOffer.id !== this.promoOffer.offerGroup) {
      notification = "Er du sikker?";
    }
    if (!this.isProcessing) {
      this.isProcessing = true;
      NotificationsService.confirmWithCallback(notification, (accept) => {
        if (accept) {
          this._offerService
            .deletePromo(this.promoOffer.id)
            .pipe(takeWhile(() => this._subscriptionState))
            .subscribe({
              next: () => {
                this.isProcessing = false;
                NotificationsService.notify(
                  "Alle kanaler blev slettet med succes",
                  "success",
                  "top"
                );
                this.pAnimate = "inactive";
                this._appEventsService.reloadCalendar(true);
              },
              error: (err) => this._handleError(err)
            });
        } else {
          this.isProcessing = false;
        }
      });
    }
  }
// ** delete sms */
  // if isProcessing is true then => set isProcessing to true
  //                                confirmWithCallback(confirmationMessage: 'Are you sure?') => if callbackFn returns true then =>
  //                                                        delete the sms with provided promoOffer id
  //                                                        subscribe => set isProcessing to false
  //                                                              .notify( notificationMessage:'The message was deleted',                                                                                       type:'success' , position:'top')
  //                                                               set pAnimate to 'inactive'
  //                                                               realoadCalender() to reload the calender

  deleteOkScreen(){
    if(!this.isProcessing) {
      this.isProcessing = true;
      NotificationsService.confirmWithCallback("Er du sikker?", (accept) => {
        if (accept) {
          this._okscreenService
            .deleteOkScreen(this.promoOffer.id)
            .pipe(takeWhile(() => this._subscriptionState))
            .subscribe({
              next: () => {
                this.isProcessing = false;
                NotificationsService.notify(
                  "Alle kanaler blev slettet med succes",
                  "success",
                  "top"
                );
                this.pAnimate = "inactive";
                this._appEventsService.reloadCalendar(true);
              },
              error: (err) => this._handleError(err)
            });
        } else {
          this.isProcessing = false;
        }
      });
    }
  }


  deleteSms() {
    if (!this.isProcessing) {
      this.isProcessing = true;
      NotificationsService.confirmWithCallback("Er du sikker?", (accept) => {
        if (accept) {
          this._smsService
            .deleteSms(this.promoOffer.id)
            .pipe(takeWhile(() => this._subscriptionState))
            .subscribe({
              next: () => {
                this.isProcessing = false;
                NotificationsService.notify(
                  "Meddelelsen blev slettet",
                  "success",
                  "top"
                );
                this.pAnimate = "inactive";
                this._appEventsService.reloadCalendar(true);
              },
              error: (err) => this._handleError(err)
            });
        } else {
          this.isProcessing = false;
        }
      });
    }
  }
//** delete Jatak */
  //if isProcessing is true => set isProcessing to true
  //                           confirmWithCallback(confirmationMessage: 'Are you sure?') => if callbackFn returns true then =>
  //                                                        delete the jatakLive with provided promo id
  //                                                        subscribe => set isProcessing to false
  //                                                        .notify( notificationMessage:'The message was deleted',                                                                                       type:'success' , position:'top')
  //                                                         set pAnimate to 'inactive'
  //                                                         realoadCalender() to reload the calender
  //                                                         else set isProcessing to false
  onDeleteJatak() {
    if (!this.isProcessing) {
      this.isProcessing = true;
      NotificationsService.confirmWithCallback("Er du sikker?", (accept) => {
        if (accept) {
          this._jatakLiveService
            .deleteJatakLive(this.promo.id)
            .pipe(takeWhile(() => this._subscriptionState))
            .subscribe({
              next: () => {
                this.isProcessing = false;
                NotificationsService.notify(
                  "Meddelelsen blev slettet",
                  "success",
                  "top"
                );
                this.pAnimate = "inactive";
                this._appEventsService.reloadCalendar(true);
              },
              error: (err) => this._handleError(err)
            });
        } else {
          this.isProcessing = false;
        }
      });
    }
  }
//** add BreakLine in text */
  // replaces <br/> to \n to start new line
  private static _addBreakLine(message: string): string {
    return message.replace(new RegExp("\n", "g"), "<br/>");
  }
//** preview description formatting*/
  //@params[desc] => is the promoOffer data description
  //if descrition length is greater than 100 return substring of desc bteween (0,100) + "..."
  //else return desc
  _previewDescFormatting(desc: string) {
    if (desc.length > 100) {
      return desc.substring(0, 100) + "...";
    }
    return desc;
  }
// ** check if Video is present */
  //@params[promo] =>  promo contains the promoOffer data of the selected channel
  //if channel is equal to 'coop' => if promo video thumbnail is present return true else return false
  private _checkIsVideo(promo): boolean {
    if (this.channel === "coop") {
      if (promo.video.thumbnail) {
        return true;
      }
      return false;
    }
  }
//* set up Image for Promo */
  //@params[promo] => contains the promoOffer data of selected channel
  // if channel is equal to 'mail' return promo thumbnail
  //if channel is equal to 'coop' => if promo largeImage is present return promo largeImage
  //                                 if promo video thumbnail is present return promo video thumbnail
  //if channel is equal to 'dit' return promo largeImage
  //if channel is not equal to 'mail' and channel is not equal to 'coop' => if promo largeImage[0](no image is uploaded) is null return promo video thumbnail
  //                                                                        if promo largeImage[0] is not null then return promo largeImage first image

  private _setUpImageForPromo(promo): string {
    if (this.channel === "mail") {
      return promo.thumbnail;
    }
    if (this.channel === "coop") {
      if (promo.largeImage) {
        return promo.largeImage;
      }
      if (promo.video.thumbnail) {
        return promo.video.thumbnail;
      }
    }
    if (this.channel === "dit") {
      return promo.largeImage;
    }
    if (this.channel !== "mail" && this.channel !== "coop") {
      if (promo.largeImage[0] === null) {
        return promo.video.thumbnail;
      }
      if (promo.largeImage[0] !== null) {
        return promo.largeImage[0];
      }
    }
  }
//** date formatting */
  //@params[startDate , endDate] => startDate and endDate of offer
  //const isSameDay is set true if startDate and endDate is same
  //if  isSameDay is true then => if offerType is not equal to 'arsmode' => dateText is set with startDate with format "LL"
  //                           => if offerType is equal to 'arsmode' => dateText is set with startDate with format "D[.] MMM[.]"
  //if isSameDay is false then => if offerType is not equal to 'arsmode' => set dateText with startDate with format 'LL' + '-' +endDate                                with format 'LL'
  //                           => if offerType is equal to 'arsmode' => set dateText with startDate with fromat 'D[.] MMM[.]' + '-' +                                   endDate with format'D[.] MMM[.]'
  // return dateText
  private _previewDateFormatting(startDate, endDate): string {
    let dateText;
    dayjs.locale('da')

    const isSameDay = dayjs(startDate).isSame(endDate, "day");
    if (isSameDay) {
      if (this.offerType !== "arsmode") {
        dateText = dayjs(startDate).format("LL");
      }
      if (this.offerType === "arsmode") {
        dateText = dayjs(startDate).format('DD. MMM YYYY, HH:mm');
      }
    }
    if (!isSameDay) {
      if (this.offerType !== "arsmode") {
        dateText =
          dayjs(startDate).format('DD. MMM YYYY, HH:mm');

      }
      if (this.offerType === "arsmode") {
        dateText =
          dayjs(startDate).format('DD. MMM YYYY, HH:mm')
        ;
      }
    }
    return dateText;
  }
//**handle error*/
  //@params[error] => error object
  //handleError pass either error object or code:-400
  //set isProcessing to false
  private _handleError(error): void {
    this._errorHandlerService.handleError(error || { code: -400 }, "promo");
    this.isProcessing = false;
  }
}
