import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { mergeMap } from 'rxjs/internal/operators/mergeMap';
import { takeWhile } from 'rxjs/operators';

import {
  ErrorhandlerService,
  WindowService,
  FileReaderService,
} from 'app/core/services';
import { Product, ProductService } from 'app/features/order-and-pickup/product';

@Component({
  selector: 'coop-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
})
export class ProductDetailsComponent implements OnInit, OnDestroy {
  pageTitle: string = 'Produkt beskrivelse';
  previousUrl: string;
  product: Product;
  imgLoaded: boolean = false;
  downloadFile = {
    name: '',
    file: '',
  };
  currentFile: {
    type: string;
    file: any;
  };

  private _subscriptionState: boolean = true;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _productService: ProductService,
    private _errorHandlerService: ErrorhandlerService,
    private _windowService: WindowService
  ) {}

  ngOnInit() {
    document.body.classList.add('overlay');
    this.previousUrl = '/butikker/produkt';
    this.product = this._activatedRoute.snapshot.data['product'];
  }

  ngOnDestroy() {
    document.body.classList.remove('overlay');
    this._subscriptionState = false;
  }

  imageLoaded() {
    this.imgLoaded = true;
  }

  downloadOrders(id: number) {
    this.currentFile = {
      type: '',
      file: '',
    };
    this._productService
      .getOrderList(id)
      .pipe(
        takeWhile(() => this._subscriptionState),
        mergeMap((file) => this._loadFile(file))
      )
      .subscribe({
        next: (res) => {
          this._loadPreview(res);
          this._download();
        },
        error: (err) => this._errorHandlerService.handleError(err),
      });
  }

  private _loadFile(file) {
    this.downloadFile.file = file;
    this.currentFile.type = file['type'];
    return FileReaderService.readFile(file, this.currentFile.type);
  }

  private _loadPreview(file) {
    this.currentFile.file = file['filesource'];
  }

  private _download() {
    FileReaderService.downloadFile(this.downloadFile, this.product.title);
  }
}
