import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatLongDescription' })
export class FormatLongDescriptionPipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      return value.replace(/\\n/g, '\n');
    }
    return value;
  }
}
