import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AngularPinturaModule } from '@pqina/angular-pintura';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FullCalendarModule } from '@fullcalendar/angular';

import {
  CalendarComponent,
  ChannelSelectComponent,
  CoopTextEditorComponent,
  DashboardComponent,
  HomeComponent,
  FooterComponent,
  InfiniteScrollComponent,
  ItemlistComponent,
  LoadingComponent,
  NopageComponent,
  OfferpreviewComponent,
  PageheaderComponent,
  ThankYouSmsComponent,
  ImageHandlerComponent,
  ImageEditorComponent,
  ImageLoaderComponent,
  ControlErrorComponent,
  TemplatesFolderListComponent,
  TemplatesListComponent,
  MflTextComponent,
  MflPopupComponent,
  AutoLoginComponent,
  CreateOptionChannelSelectComponent,
  HomeDashboardComponent,
  GuidesComponent,
  StartEndDateTimeComponent,
  PublishExpirationDateTimeComponent,
  CreatePostPageComponent
} from './components/';
import {
  CharacterCount,
  CoopPreview,
  DateFormatDDMMYY,
  DateformatPipe,
  DateToString,
  DateWithSeconds,
  DayAndMonth,
  HoursDifference,
  KFormatter,
  LeftPad,
  MillisToMinutes,
  NumberPad,
  OrderlistDateFormat,
  PhoneNumber,
  SafeHTMLPipe,
  SafeurlPipe,
  ScreenPreviewEnd,
  ScreenPreviewStart,
  TimeOnly,
  FormatNumberPipe,
  FormatLongDescriptionPipe
} from './pipes';
import { FacebookConnectModule } from '../features/facebookconnect/facebookconnect.module';
import {
  ControlErrorContainerDirective,
  ControlErrorDirective,
  FormSubmitDirective,
  PhoneNumberDirective,
  TooltipDirective,
  ClickOutsideDirective
} from './directives';
import { QuicktogoOrderComponent } from './components';
import { QuicktogoCalendarComponent } from './components/quicktogo-calendar/quicktogo-calendar.component';
import {
  QuicktogoTurnoverGraphsComponent
} from './components/quicktogo-turnover-graphs/quicktogo-turnover-graphs.component';
import {
  QuicktogoLuckybagGraphsComponent
} from './components/quicktogo-luckybag-graphs/quicktogo-luckybag-graphs.component';
import { MbscDatepickerModule, MbscSelectModule } from '@mobiscroll/angular';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [
    HomeComponent,
    FooterComponent,
    LoadingComponent,
    NopageComponent,
    PageheaderComponent,
    DateformatPipe,
    FormatNumberPipe,
    DateToString,
    HoursDifference,
    CharacterCount,
    TimeOnly,
    CoopPreview,
    ScreenPreviewStart,
    ScreenPreviewEnd,
    NumberPad,
    LeftPad,
    KFormatter,
    SafeurlPipe,
    ItemlistComponent,
    DashboardComponent,
    CalendarComponent,
    OfferpreviewComponent,
    ChannelSelectComponent,
    DayAndMonth,
    ThankYouSmsComponent,
    MillisToMinutes,
    DateFormatDDMMYY,
    DateWithSeconds,
    CoopTextEditorComponent,
    InfiniteScrollComponent,
    ImageHandlerComponent,
    ImageEditorComponent,
    ImageLoaderComponent,
    ControlErrorComponent,
    ControlErrorDirective,
    PhoneNumberDirective,
    TooltipDirective,
    ControlErrorContainerDirective,
    FormSubmitDirective,
    ClickOutsideDirective,
    TemplatesFolderListComponent,
    TemplatesListComponent,
    MflTextComponent,
    MflPopupComponent,
    PhoneNumber,
    OrderlistDateFormat,
    AutoLoginComponent,
    CreateOptionChannelSelectComponent,
    SafeHTMLPipe,
    FormatLongDescriptionPipe,
    QuicktogoOrderComponent,
    QuicktogoCalendarComponent,
    OfferpreviewComponent,
    QuicktogoTurnoverGraphsComponent,
    QuicktogoLuckybagGraphsComponent,
    ControlErrorComponent,
    HomeDashboardComponent,
    GuidesComponent,
    StartEndDateTimeComponent,
    PublishExpirationDateTimeComponent,
    CreatePostPageComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FacebookConnectModule,
    FullCalendarModule,
    CKEditorModule,
    AngularPinturaModule,
    MbscDatepickerModule,
    MbscSelectModule,
    IonicModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HomeComponent,
    LoadingComponent,
    NopageComponent,
    PageheaderComponent,
    CalendarComponent,
    OfferpreviewComponent,
    DateformatPipe,
    FormatNumberPipe,
    DateToString,
    HoursDifference,
    CharacterCount,
    TimeOnly,
    CoopPreview,
    ScreenPreviewStart,
    ScreenPreviewEnd,
    NumberPad,
    LeftPad,
    KFormatter,
    SafeurlPipe,
    ItemlistComponent,
    FullCalendarModule,
    ChannelSelectComponent,
    DayAndMonth,
    ThankYouSmsComponent,
    MillisToMinutes,
    DateFormatDDMMYY,
    DateWithSeconds,
    CoopTextEditorComponent,
    InfiniteScrollComponent,
    ImageHandlerComponent,
    ControlErrorComponent,
    ControlErrorDirective,
    PhoneNumberDirective,
    ControlErrorContainerDirective,
    FormSubmitDirective,
    TooltipDirective,
    ClickOutsideDirective,
    TemplatesFolderListComponent,
    TemplatesListComponent,
    MflTextComponent,
    MflPopupComponent,
    PhoneNumber,
    OrderlistDateFormat,
    AutoLoginComponent,
    CreateOptionChannelSelectComponent,
    SafeHTMLPipe,
    QuicktogoOrderComponent,
    FormatLongDescriptionPipe,
    HomeDashboardComponent,
    IonicModule,
    GuidesComponent,
    StartEndDateTimeComponent,
    PublishExpirationDateTimeComponent,
    CreatePostPageComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {
}
