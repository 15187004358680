import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ScreenList } from 'app/features/offer';

@Injectable({
  providedIn: 'root',
})
export class ScreenListDataService {
  private screenList = new BehaviorSubject([]);
  screens = this.screenList.asObservable();

  setScreens(screens: ScreenList[]) {
    this.screenList.next(screens);
  }
  clearScreens() {
    this.screenList.next([]);
  }
}
