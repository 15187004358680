import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safeurl',
})
export class SafeurlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(url): any {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url); //Bypass security and trust the given value to be a safe resource URL
  }
}
