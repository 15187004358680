import { Injectable } from '@angular/core';
import * as moment from 'moment';
import * as dayjs from 'dayjs';

@Injectable({
  providedIn: 'root',
})
export class DateFormatService {
  //** set time interval 15 min
  //@params[date] date
  static timeIntervalFormat(date) {
    let newDate;
    const min = moment(date).minutes();
    if (min > 0) {
      const newMin = (Math.ceil(min / 15) * 15) % 60; // rounded up value
      const toBeAddedMin = newMin - min;
      if (newMin === 0) {
        newDate = moment(date).add(1, 'hours').minute(0).second(0);
      }
      if (newMin !== 0) {
        newDate = moment(date).add(toBeAddedMin, 'minutes').second(0);
      }
    } else {
      newDate = moment(date);
    }
    return newDate;
  }

  static timeIntervalFormatter(date: any) {
    let newDate: any;
    const min = dayjs(date).minute(); // Get the minutes using Day.js

    if (min > 0) {
      const newMin = (Math.ceil(min / 15) * 15) % 60; // Rounded up value
      const toBeAddedMin = newMin - min;

      if (newMin === 0) {
        newDate = dayjs(date).add(1, 'hour').minute(0).second(0); // Add 1 hour and set minutes to 0
      } else {
        newDate = dayjs(date).add(toBeAddedMin, 'minute').second(0); // Add the calculated minutes
      }
    } else {
      newDate = dayjs(date); // No changes if minutes are 0
    }

    return newDate;
  }

  //@params[startDate, endDate , tab] start date ,end date and type of offer
  //if isSameDay is true => if tab is 'coop' =>set dateText and return it
  //                                    else => set dateText with the given format
  //if isSameDay is false => if tab is 'coop' => set dateText and return it
  //                                    else => set dateText with given format
  static previewDateFormatting(startDate, endDate, tab): string {
    let dateText;
    const isSameDay = moment(startDate).isSame(endDate, 'day'); // comparing if startDate and endDate are same

    if (isSameDay) {
      if (tab === 'coop') {
        dateText = DateFormatService.noYearDate(startDate);
        return dateText;
      }
      dateText = moment(startDate).format('DD.MM.YYYY');
    }
    if (!isSameDay) {
      if (tab === 'coop') {
        dateText =
          DateFormatService.noYearDate(startDate) +
          ' - ' +
          DateFormatService.noYearDate(endDate);
        return dateText;
      }
      dateText =
        moment(startDate).format('DD.MM.YYYY') +
        ' - ' +
        moment(endDate).format('DD.MM.YYYY');
    }
    return dateText;
  }

  //** provides dates with date and month only
  //@params[start] date
  //return date with index 0,1
  static noYearDate(start) {
    const space = ' ';
    const date = moment(start).format('ll').split(space);
    return date[0] + date[1];
  }

  //** compare if send date is before current dat
  //@params[date] date
  // returns boolean: true if sendDate is before currentDate
  static dateHasPassed(date): boolean {
    const currentDate = moment().toDate();
    const sendDate = moment(date).toDate();
    return moment(sendDate).isBefore(currentDate);
  }

  static getWeeksInaYearinArray(year: number) {
    const weeksInTheyear = DateFormatService.getWeeksInYear(year);
    return Array.from({ length: weeksInTheyear }, (_, i) => ({
      text: `${i + 1}`,
      value: i + 1,
    }));
  }

  static getWeeksInYear(year: number) {
    // Create date objects for December 31st and the last Thursday of the given year
    let lastDayOfYear = new Date(year, 11, 31);
    let dayOfWeek = lastDayOfYear.getDay();

    // If the last day of the year is not a Thursday, adjust to the previous Thursday
    if (dayOfWeek !== 4) {
      lastDayOfYear.setDate(lastDayOfYear.getDate() - ((dayOfWeek + 3) % 7));
    }

    // Get the week number of the last Thursday of the year

    return DateFormatService.getWeekNumber(lastDayOfYear);
  }

  static getWeekNumber(date: Date) {
    // Copy date to avoid modifying original
    let currentDate = new Date(date.getTime());

    // Set the start of the year
    let startOfYear = new Date(currentDate.getFullYear(), 0, 1);

    // Calculate the number of days between the current date and the start of the year
    let daysSinceStartOfYear = Math.floor(
      (currentDate.getTime() - startOfYear.getTime()) / (24 * 60 * 60 * 1000)
    );
    return Math.ceil(
      (daysSinceStartOfYear + 1 + ((startOfYear.getDay() + 6) % 7)) / 7
    );
  }
}
