<section class="app-page page-with-fixed-header below-footer">
  <div class="fixed-header" id="stat-header">
    <coop-pageheader [pageTitle]="pageTitle"></coop-pageheader>
  </div>


  <div class="page-container">

    <div (click)="showItemsList()" class="select-tab">
      <div class="title">
        {{ selectedItemName }}
      </div>
      <div>
        <ion-icon name="chevron-down-outline"></ion-icon>
      </div>
    </div>


    <div *ngIf="showList" class="list">
      <div *ngFor="let data of itemList;let last=last" class="item" (click)="selectItem(data)" [class.noborder]="last">
        <div class="title">
          {{ data.name }}
        </div>
        <div>
          <ion-icon *ngIf="selectedItemId !== data.id" name="radio-button-off-outline"></ion-icon>
          <ion-icon *ngIf="selectedItemId == data.id" name="radio-button-on-outline"></ion-icon>
        </div>
      </div>
    </div>


    <ng-container *ngIf="selectedItemId == 1">
      <div class="category">
        <div (click)="selectCategory(1)" class="title margin-right-24" [class.active]="selectedCategoryId == 1">
          Medlemsstatistik
        </div>
        <div (click)="selectCategory(2)" class="title" [class.active]="selectedCategoryId == 2">
          Opslag fra QuickCoop
        </div>
      </div>

      <ng-container *ngIf="selectedCategoryId == 1">
        <div class="select-month-week">
          <div class="title">
            Vælg måned
          </div>

          <div class="select">
            <div>
              <input
                mbsc-datepicker
                [mbscOptions]="memberStatsDatePicker.month"
                id="member-stats-month"
              />
            </div>
            <div style="margin-left: 10px">
              <input
                mbsc-datepicker
                [mbscOptions]="memberStatsDatePicker.year"
                id="member-stats-year"
              />
            </div>
          </div>
        </div>

        <div style="margin: 15px 0 ">
          <div class="stats">
            <div class="title">
              Medlemmer
            </div>
            <div class="number">
              {{ generalReport.members.count }}
            </div>
          </div>
          <div class="stats">
            <div class="title">
              COOP App samtykker
            </div>
            <div class="number">
              {{ generalReport.app.count }}
            </div>
          </div>

          <div class="stats">
            <div class="title">
              SMS-Medlemmer
            </div>
            <div class="number">
              {{ generalReport.sms.count }}
            </div>
          </div>

          <div class="stats">
            <div class="title">
              Andel medlems-omsætning
            </div>
            <div class="number">
              {{ generalReport.sales.count }}
            </div>
          </div>
        </div>


        <div class="age-distribution-container">
          <div class="wrapper flex-center">
            <div
              class="age-distribution"
              *ngIf="generalReport.ageDistribution.length"
            >
              <p class="result-title text-align-center">I procent og (antal)</p>
              <ul>
                <li *ngFor="let distribution of generalReport.ageDistribution">
                  <div class="ageGroupItem">
                              <span class="ageGroup {{ distribution.Shade }}">{{
                                distribution.title
                                }}</span>
                    <div class="report-ctn">
                                <span
                                  class="bg {{ distribution.Shade }}"
                                  [style.width]="distribution.Percentage * 1.2 + 'px'"
                                ></span>
                      <span class="memberPercent"
                      >{{ distribution.Percentage }}%
                                  <span class="memberCount"
                                  >({{ distribution.members }})</span
                                  ></span
                      >
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <h5
              class="empty-message"
              *ngIf="generalReport.ageDistribution.length === 0"
            >
              Beklager, ingen tilgængelige data
            </h5>
          </div>
        </div>


      </ng-container>


      <ng-container *ngIf="selectedCategoryId == 2">
        <div class="select-month-week">
          <div class="title">
            Vælg uge
          </div>

          <div class="select">
            <div>
              <input
                value="{{lookupStatsDatePicker.week.defaultValue}}"
                mbsc-select
                [mbscOptions]=" lookupStatsDatePicker.week"
                id="lookup-stats-week"
              />
            </div>
            <div style="margin-left: 10px">
              <input
                mbsc-datepicker
                [mbscOptions]="lookupStatsDatePicker.year"
                id="lookup-stats-year"
              />
            </div>
          </div>
        </div>

        <div class="table-data">
          <div class="title">
            Antal opslag per kanal for valgt periode
          </div>

          <div *ngIf="userType === '1'" class="table-container">
            <table>
              <thead>
              <tr>
                <th scope="col">kanal</th>
                <th scope="col">Butik (Antal)</th>
                <th scope="col">Kæde (Gns.)</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <th scope="row">COOP App</th>
                <td>{{ promoReport.offer.coop }}</td>
                <td>{{ promoReport.kaede.coop }}</td>
              </tr>
              <tr>
                <th scope="row">Facebook</th>
                <td>{{ promoReport.offer.facebook }}</td>
                <td>{{ promoReport.kaede.facebook }}</td>
              </tr>
              <tr>
                <th scope="row">SMS</th>
                <td>{{ promoReport.offer.sms }}</td>
                <td>{{ promoReport.kaede.sms }}</td>
              </tr>
              <tr>
                <th scope="row">Mail</th>
                <td>{{ promoReport.offer.mail }}</td>
                <td>{{ promoReport.kaede.mail }}</td>
              </tr>
              </tbody>
            </table>
          </div>

          <div *ngIf="userType === '2'" class="table-container">
            <table>
              <thead>
              <tr>
                <th scope="col">kanal</th>
                <th scope="col">Butik (Antal)</th>
                <th scope="col">Kæde (Gns.)</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <th scope="row">Nyheder</th>
                <td>{{ adminReport.news.store }}</td>
                <td>{{  adminReport.news.kaede }}</td>
              </tr>
              <tr>
                <th scope="row">Arrangement</th>
                <td>{{ adminReport.events.store }}</td>
                <td>{{  adminReport.events.kaede }}</td>
              </tr>
              <tr>
                <th scope="row">Afstemninger</th>
                <td>{{ adminReport.polls.store }}</td>
                <td>{{  adminReport.polls.kaede }}</td>
              </tr>
              </tbody>
            </table>
          </div>


        </div>


        <div *ngIf="userType === '1'" class="table-data">
          <div class="title">
            JA TAK
          </div>

          <div class="table-container">
            <table>
              <thead>
              <tr>
                <th scope="col">kanal</th>
                <th scope="col">Butik (Antal)</th>
                <th scope="col">Kæde (Gns.)</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <th scope="row">COOP App</th>
                <td>{{ jatakReport.offer.coop }}</td>
                <td>{{ jatakReport.kaede.coop }}</td>
              </tr>
              <tr>
                <th scope="row">Facebook</th>
                <td>{{ jatakReport.offer.facebook }}</td>
                <td>{{ jatakReport.kaede.facebook }}</td>
              </tr>
              <tr>
                <th scope="row">SMS</th>
                <td>{{ jatakReport.offer.sms }}</td>
                <td>{{ jatakReport.kaede.sms }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

      </ng-container>
    </ng-container>

    <ng-container *ngIf="selectedItemId == 2">
      <app-quicktogo-order></app-quicktogo-order>
    </ng-container>

  </div>
</section>
