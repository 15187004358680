import { animate, style, transition, state } from '@angular/animations';

// created animtation variable 'childShowHide' with two transition
// :enter=>trigger transition when element enter in the DOM 
// :enter transition sets an initial top to 100% and then animates it to change that top to 0 as the element is inserted into the view over 500ms transition time with custom defined easing curve. 
//:leave => trigger transition when element leave the DOM 
//:leave trasition  animates top:0 to 100% as the element is removed from the view over transition time 600ms with custom defined easing curve

export const childShowHide = [
  transition(':enter', [
    style({ top: '100%' }),
    animate('500ms cubic-bezier(.35,0,.25,1)', style({ top: '0' })),
  ]),
  transition(':leave', [
    style({ top: '0' }),
    animate('600ms cubic-bezier(.35,0,.25,1)', style({ top: '100%' })),
  ]),
];


//created animation variable 'parentShowHide'
//state 'inactive' is styled with position:absolute and top:-100vh
//state'active' is styled with position: relative and top:0
//transition flows from inactive to active state with transition delay of 500ms with custom defined easing curve.
//transition flows from active to inactive state with transition delay of 600ms with custom defined easing curve.
export const parentShowHide = [
  state(
    'inactive',
    style({
      position: 'absolute',
      top: '-100vh',
    })
  ),
  state(
    'active',
    style({
      position: 'relative',
      top: '0px',
    })
  ),
  transition('inactive => active', animate('500ms cubic-bezier(.35,0,.25,1)')),
  transition('active => inactive', animate('600ms cubic-bezier(.35,0,.25,1)')),
];
