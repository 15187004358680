import {
  Component,
  EventEmitter,
  Input, OnChanges,
  OnDestroy,
  OnInit,
  Output, SimpleChanges
} from "@angular/core";
import {
  ErrorhandlerService,
  NotificationsService,
  TokenService
} from "app/core/services";
import {takeWhile} from "rxjs/operators";
import {FacebookEventsService} from "app/features/facebookconnect/services";
import {JatakService} from "app/features/jatak";
import {
  ChannelList,
  ChannelSelectionModes,
  Shared, UserDetail
} from "app/shared/interface";
import {ChannelData} from "app/shared/data";

@Component({
  selector: "coop-channel-select",
  templateUrl: "./channel-select.component.html",
  styleUrls: ["./channel-select.component.scss"]
})
export class ChannelSelectComponent implements OnInit, OnDestroy, OnChanges {
  @Input() selectedChannel: Shared;
  @Input() mode: ChannelSelectionModes;
  @Input() selectedId: string;
  @Input() offerType: string;
  @Input() allowedChannels: Shared;
  @Input() requiredChannel: string = "coop";
  @Output() channelState: EventEmitter<Shared> = new EventEmitter<Shared>();
  @Output() channelConfirmed: EventEmitter<Shared> = new EventEmitter<Shared>();
  tempSelected: Shared;
  selectedOption: Shared = {
    coop: false,
    facebook: false,
    sms: false,
    mail: false,
    web: false,
    screen: false,
    dit: false
  };
  disableButton: boolean = false;
  userType: string;
  userDetail: UserDetail;
  readonly channelData: ChannelList[] = ChannelData;
  private _subscriptionState: boolean = true;
  isBtnSwitchColor = false;
  type: string;
  constructor(
    private _facebookEvents: FacebookEventsService,
    private _jatakService: JatakService,
    private _errorHandlerServices: ErrorhandlerService
  ) {
  }

//**initialize component */
//variable tempSelected is set with selectedChannel
//tempSelected is set to variable selectedOption
//variable userType is assigned with the type of user from localstorage (board or regular user)
// emitState() to emit events
  ngOnInit() {
    this.tempSelected = JSON.parse(JSON.stringify(this.selectedChannel));
    this.selectedOption = this.tempSelected;
    this.userType = TokenService.getUserType();
    if (this.mode.template && this.selectedOption.facebook) {
      this.fbSelect()
    }
    this.emitState();
  }

  //** handle changes on component */
  //@params[changes] => object containing changed properties
  //if changes requiredChannel is present => set requiredChannel to the current value of changes requiredChannel
  //if changes selectedChannel is present => set selectedChannel to the current value of changes selectedChannel
  //if changes allowedChannels is present => set allowedChannel to the current value of changes allowedChannels
  // if changes offerType is present => set offerType to the current value of changes offerType
  ngOnChanges(changes: SimpleChanges) {

    if (changes["requiredChannel"]) {
      this.requiredChannel = changes["requiredChannel"].currentValue;
    }
    if (changes["selectedChannel"]) {
      this.selectedChannel = changes["selectedChannel"].currentValue;
    }
    if (changes["allowedChannels"]) {
      this.allowedChannels = changes["allowedChannels"].currentValue;
      this.userDetail = TokenService.getUserDetail();
      // disable screen if user scope doesnot have screen
      if (!this.userDetail.scopes.screen) {
        this.allowedChannels = { ...this.allowedChannels, screen: false };
      }
    }
    if (changes["offerType"]) {
      this.offerType = changes["offerType"].currentValue;
    }

  }

//** destroy component */
//set _subscriptionState to unsubscribe observable
  ngOnDestroy(): void {
    this._subscriptionState = false;
  }

//** checks if the channel is allowed */
// @params[channel] => is the channel trigger by click event
//if allowedChannels channel is true => if channel is equal to 'dit' and offerType is equal to 'tilbud' then return false( discard DIT chanel in tilbud offerType )
//return false if channel is equal to 'sms' or 'tilbud' & userType = 2 i.e board (sms and tilbud are deiscared for board user)
  isAllowed(channel): boolean {
    if (this.allowedChannels[channel]) {

      if (channel === 'screen') {
        return false;
      }
      if (channel === "dit" && this.offerType === "tilbud") {
        return false;
      }
      return !(
        (channel === "sms" || channel === "dit") &&
        this.userType === "2"
      );
    }
    return false;
  }

  //** selects the channel */
  //@params[channelType] => is the channel trigger by click event
  //if mode of creation is template & allowedChannels channelType is false then return
  //if channelType is 'facebook' => fbSelect()
  //toggle the value of selectedOption channelType
  //if offerType is equal to 'jatak' => validateWebSelection()
  //emitState()=> emit events
  //_validateSelection() => validate selection of channel
  channelSelect(channelType: string) {
    if (channelType === this.requiredChannel) {
      return;
    }
    if (this.mode.template && !this.allowedChannels[channelType]) {
      return;
    }
    if (channelType === "facebook") {
      if (this.selectedOption.facebook) {
        this.selectedOption.facebook = false
        return;
      }
      if (!this.selectedOption.facebook) {
        this.fbSelect();
        return;
      }

    }

    this.selectedOption[channelType] = !this.selectedOption[channelType];
    if (this.offerType === "jatak") {
      this.validateWebSelection();
    }
    this.emitState();
    this._validateSelection();
  }

  //** validates web selection*/
  //if selectedOption 'coop' and selectedOption 'facebook' are false then set selectedOption 'web' to false(coop and facebook must be selected for web to get selected)
  validateWebSelection() {
    if (!this.selectedOption["coop"] && !this.selectedOption["facebook"]) {
      this.selectedOption["web"] = false;
    }
  }

  //** checks if facebook is connected */
  //set variable facebookConnected to facebook connected value from local storage
  //if facebookConnected is false => notify with notificationMessage:'connect to facebook' , type: 'error', position:'top' and
  // connectFacebook() => Show facebook Login Popup to connect
  //subscribe to getFbTokenStatus() which returns token
  //if token is present then => toggle the value of selectedOption facebook(select to unselect and vice-versa)
  //                         => if offerType is equal to 'jatak' then => validateWebSelection()
  //                         => emitState() to emit events
// else notify with notificationMessage 'Invalid Facebook Token.Reconnect the page' , type:'error', position:'top' & connectFacebook() => Show facebook Login Popup to connect
  //validateSelection()=> validate selection of selected channel
  fbSelect() {
    const facebookConnected = TokenService.getUserDetail().facebook.connected;
    if (!facebookConnected) {
      NotificationsService.notify(
        "Opret forbindelse til facebook",
        "error",
        "top"
      );
      this._facebookEvents.connectFacebook();
      return;
    }
    this._jatakService
      .getFbTokenStatus()
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (res) => {
          if (res) {
            // if( this.mode.template && this.selectedOption.facebook){
            //    this.selectedOption.facebook = res
            // }
            this.selectedOption.facebook = res;
            if (this.offerType === "jatak") {
              this.validateWebSelection();
            }
            this.emitState();
          } else {
            NotificationsService.notify(
              "Ugyldigt Facebook Token.<br>Forbind siden igen",
              "error",
              "top"
            );
            this._facebookEvents.connectFacebook();
          }
          this._validateSelection();
        },
        error: (err) => this._errorHandlerServices.handleError(err)
      });
  }

//emits event for selectedOption value
  emitState() {
    this.channelState.emit(this.selectedOption);
  }

  //** confirm channel selection */
  //@params[e] => event variable containing information of DOM event
  //if _checkIfAllUnselected is true =>  prevent the browser from executing the default action of the selected element
  //else emit event with selected Option
  confirmChannelSelection(e: any) {
    if (this._checkIfAllUnSelected()) {
      e.preventDefault();
      return;
    }

    if (this.selectedOption.facebook) {
      const facebookConnected = TokenService.getUserDetail().facebook.connected;
      if (!facebookConnected) {
        NotificationsService.notify(
          "Opret forbindelse til facebook",
          "error",
          "top"
        );
        this._facebookEvents.connectFacebook();
        return;
      }
    }

    this.channelConfirmed.emit(this.selectedOption);
  }

//** validates selection of selected channel*/
  //if _checkIfAllUnSelected() is true => set disableButton true
  // else set disableButton false
  private _validateSelection() {
    if (this._checkIfAllUnSelected()) {
      this.disableButton = true;
      return;
    }
    if (!this._checkIfAllUnSelected()) {
      this.disableButton = false;
    }
  }

//** check if all unselected //
  // for loop=> loop through all selectedOption
  //          if selectedOption is true return false
  //          else return true
  private _checkIfAllUnSelected(): boolean {
    for (const opt in this.selectedOption) {
      if (this.selectedOption[opt]) {
        return false;
      }
    }
    return true;
  }

  selectAllChannel() {
    this.isBtnSwitchColor = !this.isBtnSwitchColor;
    if (this.isBtnSwitchColor  && this.userType == '2') {
      const facebookConnected = TokenService.getUserDetail().facebook.connected;
      this.fbSelect()
      this.selectedOption.coop = true;
      this.selectedOption.facebook = facebookConnected;
      this.selectedOption.web = true;
      this.selectedOption.mail = true;
      this.selectedOption.sms = false;
      this.selectedOption.screen = false
      return;
    }

    if (!this.isBtnSwitchColor  && this.userType == '2') {
      console.log('all not selected')
      this.selectedOption.facebook = false;
      this.selectedOption.web = false;
      this.selectedOption.mail = false;
      this.selectedOption.sms = false;
      this.selectedOption.screen = false
      return;
    }

    if (this.isBtnSwitchColor) {
      const facebookConnected = TokenService.getUserDetail().facebook.connected;
      this.fbSelect()
      this.selectedOption.coop = true;
      this.selectedOption.facebook = facebookConnected;
      this.selectedOption.web = true;
      this.selectedOption.mail = true;
      this.selectedOption.sms = true;
      this.selectedOption.dit = true
      this.selectedOption.screen = false;
    }
    if (!this.isBtnSwitchColor) {
      this.selectedOption.facebook = false;
      this.selectedOption.web = false;
      this.selectedOption.mail = false;
      this.selectedOption.sms = false;
      this.selectedOption.dit = false;
      this.selectedOption.screen = false;
    }
  }
}
