import {Directive, Output, EventEmitter, HostListener} from '@angular/core';

@Directive({
  selector: '[scrollBottom]'
})
export class ScrollBottomDirective {
  @Output() scrolledBottom = new EventEmitter<boolean>();

  constructor() {
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
    const element = event.target;
    // Detect if the scrollbar reached the bottom
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      // Emit event only when scrolling downwards
      if (event.deltaY > 0) {
        this.scrolledBottom.emit(true);
      }
    }
  }
}
