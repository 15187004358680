import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { SelectedChannels } from 'app/shared/interface';

// *** Has Getter, Setter and clearer for defaultChannels selected in templates ***
//getChannel() gets the selected channel initially default channel
//setChannel() emits the selected channel
//@params[channels] selected channel
//clearChannel() emits undefined channel
@Injectable({
  providedIn: 'root',
})
export class ChannelDataService {
  readonly defaultChannels: SelectedChannels = {
    selected: {
      coop: true,
      facebook: false,
      sms: false,
      mail: false,
      web: false,
      screen: false,
      dit: false,
    },
    isSet: false,
  };
  private channelSelection = new BehaviorSubject(this.defaultChannels);
  selectedChannel = this.channelSelection.asObservable();

  getChannel() {
    return this.selectedChannel;
  }
  setChannel(channels: SelectedChannels) {
    this.channelSelection.next(channels);
  }

  clearChannel() {
    this.channelSelection.next(undefined);
  }
}
