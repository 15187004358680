import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService, TokenService } from 'app/core/services';
import {
  AvailablePushCountData,
  NyhedTilbud,
  Promo,
  RecurringPromo,
} from 'app/features/offer';

@Injectable({
  providedIn: 'root',
})
export class OfferService {
  constructor(private _apiService: ApiService) {}

  // **get push week data **
  getWeekPushData(): Observable<AvailablePushCountData> {
    return this._apiService.get(`get-unavailable-weeks`);
  }

  // *** Get All Offers/Promos ***
  // @params [pagenumber] => get data from certain page
  // @params [limit] => Limit number of data to be received
  // @return => returns list of Promos/Offers according to params
  getPromos(
    startDate?: any,
    endDate?: any,
    fromSystemId?: number,
    pagenumber?: number
  ): Observable<Promo[]> {
    const board = TokenService.getUserType() === '1' ? 0 : 1;
    pagenumber = !pagenumber ? 1 : pagenumber;
    let url = `offer?filter=DESC`;
    if (startDate) {
      url =
        url +
        `&startDate=${startDate}&endDate=${endDate}&fromSystemId=${fromSystemId}`;
    } else {
      url = url + `&page=${pagenumber}&limit=10`;
    }
    url += `&board=${board}`;
    return this._apiService.get(url).pipe(map((res) => res['promos']));
  }
//**get recurring promos
 //@params[page,limit,status] page number , limit of data to be displayed on single page and status of created offer
  //set board 0 if getUserType is 1 else set 1
  getRecurringPromos(
    page: number,
    limit: number,
    status: number | string
  ): Observable<RecurringPromo[]> {
    const board = TokenService.getUserType() === '1' ? 0 : 1;
    const url = `recurring-promos?page=${page}&limit=${limit}&board=${board}&status=${status}`;
    return this._apiService.get(url).pipe(map((res) => res['recurringPromos']));
  }

  // ** get a single promo **
  // @params [promoID] => get the promo of that id
  getPromo(promoID: string): Observable<NyhedTilbud> {
    return this._apiService
      .get(`offer/${promoID}`)
      .pipe(map((res) => res['promo']));
  }
//**get recurring promo
  //@params[promoID] promo id
  getRecurringPromo(promoID: string): Observable<RecurringPromo> {
    return this._apiService
      .get(`recurring-promos/${promoID}`)
      .pipe(map((res) => res['recurringPromo']));
  }

  // ** save offer **
  // @params [params] => form data,
  // @params [edit] => edit the offer
  savePromo(params, edit: boolean): Observable<any> {
    if (edit) {
      return this._apiService.put(
        `offer/${params.id}`,
        ApiService.CreateFormData(params)
      );
    }
    return this._apiService.post('offer', ApiService.CreateFormData(params));
  }
//save recurring promo
  //@params[params,edit] form data, edit offer boolean value
  saveRecurringPromo(params, edit: boolean): Observable<any> {
    if (edit) {
      return this._apiService.post(
        `recurring-promos/${params.id}/update`,
        ApiService.CreateFormData(params)
      );
    }
    return this._apiService.post(
      'recurring-promos',
      ApiService.CreateFormData(params)
    );
  }

  // ** to delete the promo
  // @params [id] => to delete the offer with that id
  deletePromo(id: string): Observable<any> {
    return this._apiService.del(`offer/${id}`);
  }
//** delete recurring promo
  //@param[id] promo id
  deleteRecurringPromo(id: string): Observable<any> {
    return this._apiService.post(`recurring-promos/${id}/destroy`, {});
  }

  // ** to delete the promo channel
  // @params [tab,id] =>channel , to delete the offer's channel with that id
  deleteChannel(tab: string, promoId): Observable<any> {
    return this._apiService.get(
      `delete-channel?promoId=${promoId}&channel=${tab}`
    );
  }

  // ** get all the news for nyheder **
  //@params[active,page,limit]status of created offer,  page number and limit of data to be displayed on single page
  getNewsList(
    active: string,
    page: number,
    limit: number
  ): Observable<Promo[]> {
    return this._apiService
      .get(`get-news?active=${active}&page=${page}&limit=${limit}`)
      .pipe(map((res) => res['promos']));
  }
//** get screen list
  getScreenList(): Observable<any> {
    return this._apiService
      .get(`get-screen-devices`)
      .pipe(map((res) => res['contents']));
  }
}
