<div>
  <div style="height: 100vh">
    <pintura-editor-modal
      *ngIf="isVisible"
      [src]="src"
      [options]="options"
      (process)="handleModalProcess($event)"
      (load)="handleModalLoad()"
      (close)="onCloseEditor()"
    ></pintura-editor-modal>
  </div>
</div>
