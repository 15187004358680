export const annotateOptions = [
  [
    'text',
    (locale: any) => locale.shapeLabelToolText,
    {
      icon: (locale: any) => locale.shapeIconToolText,
    },
  ],
  [
    'rectangle',
    (locale: any) => locale.shapeLabelToolRectangle,
    {
      icon: (locale: any) => locale.shapeIconToolRectangle,
    },
  ],
];
export const textShapeOptions = {
  text: [
    {
      x: 0,
      y: 0,
      color: '#d10a11',
      fontSize: '10%',
      text: 'Text',
    },
    {
      position: 'relative',
    },
  ],
  rectangle: [],
};
export const DEFAULT_DOKA_OPTIONS = {
  decorate: true,
  sticker: true,
  text: true,
  rectangle: false,
  priceTag: true,
  frame: true,
  emoji: true,
};
