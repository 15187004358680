import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import { OrderTab, SharedNames } from 'app/core/data/defaultValues';
import { ListItem } from 'app/shared/interface';

@Component({
  selector: 'coop-itemlist',
  templateUrl: './itemlist.component.html',
  styleUrls: ['./itemlist.component.scss'],
})
export class ItemlistComponent implements OnInit, OnChanges {
  @Input() itemList: ListItem[];
  @Input() url: string;
  @Input() template: string;
  @Input() jatakLive = false;
  @Output() selectedTemplate: EventEmitter<number> = new EventEmitter<number>();
  orderedShared = OrderTab;
  sharedNames = SharedNames;

  constructor() {}
//**handles the changes
  //@params[changes] object containing changed properties
  //if changes 'itemList' is present =>set itemList with the value of changes itemList currentValue
  //if changes 'url' is present => set url with the value of changes url currentValue
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['itemList']) {
      this.itemList = changes['itemList'].currentValue;
    }
    if (changes['url']) {
      this.url = changes['url'].currentValue;
    }
  }

  ngOnInit() {

  }
//** selected template
  //@params[id] item id
  templateSelected(id: number) {
    this.selectedTemplate.emit(id); // emit id for parent component
  }
}
