import { State, Selector, Action, StateContext } from "@ngxs/store";
import { Injectable } from '@angular/core';

export  interface OrderLists {
  tab: any,
}


export interface OrderListState {
  data : OrderLists,
}


export class SetOrderListState {
  static readonly type = "[SetOrderListCompState] Set Data"

  constructor(public payload: any) {
  }
}


export class ClearOrderListState{
   static readonly type = "[SetOrderListState] Clear Data"
}

@State<any>({
  name :'orderlist',
  defaults : {
    data:null
  }
})

@Injectable()
export class SetOrderListCompState {
  @Selector()
  static getData(state){
    return state.data
  }

  @Action(SetOrderListState)
  setData(
    { patchState } : StateContext<OrderListState>,
    { payload } : SetOrderListState
  ){
    patchState({
      data : payload
    })
  }

  @Action(ClearOrderListState)
  clearData({ setState } :StateContext<OrderListState> ){
    const defaults: any = {
      data:null
    };
    setState(defaults)
  }
}
