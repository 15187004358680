<!--<div class="item-list">-->
<!--  <ng-container *ngFor="let item of itemList">-->
<!--    <a-->
<!--      class="item"-->
<!--      (click)="templateSelected(item.id)"-->
<!--      [class.jatak-live-list]="jatakLive"-->
<!--    >-->
<!--      <figure class="item-image" *ngIf="!jatakLive">-->
<!--        <img src="{{ item.image }}" alt=""/>-->
<!--      </figure>-->
<!--      <div class="item-detail" [class.no-img]="jatakLive">-->
<!--        <div class="title-container">-->
<!--          <h3-->
<!--            class="item-title"-->
<!--            [ngStyle]="jatakLive && { '-webkit-box-orient': 'vertical' }"-->
<!--          >-->
<!--            {{ item.title }}-->
<!--          </h3>-->
<!--          <span *ngIf="template === 'folder'">-->
<!--            <span class="temp-notification" *ngIf="item.newTemplateCount !== 0"-->
<!--            >Ny ({{ item.newTemplateCount }})</span-->
<!--            >-->
<!--          </span>-->
<!--          <span *ngIf="template === 'template'">-->
<!--            <span class="temp-notification" *ngIf="item.new">Ny</span>-->
<!--          </span>-->
<!--        </div>-->
<!--        <span *ngIf="jatakLive">-->
<!--          Antal produkter: {{ item.totalProducts }}-->
<!--        </span>-->
<!--        <div class="item-shared-option" *ngIf="item.shared">-->
<!--          <ng-container *ngFor="let opt of orderedShared">-->
<!--            <span *ngIf="item.shared[opt]" class="item-shared-option-name">{{-->
<!--              sharedNames[opt]-->
<!--              }}</span>-->
<!--          </ng-container>-->
<!--        </div>-->
<!--        <p class="item-desc">{{ item.desc }}</p>-->
<!--      </div>-->
<!--      <div class="link-arrow">-->
<!--        <i class="icon icon-arrow-right"></i>-->
<!--      </div>-->
<!--    </a>-->
<!--  </ng-container>-->
<!--</div>-->

<div class="template-title">Alle skabeloner</div>
<div *ngFor="let item of itemList" (click)="templateSelected(item.id)" >
  <div class="result-list">
    <div style="display: flex">
      <img
        *ngIf="!jatakLive"
        [src]="item.image"
        class="result-img"
      />
      <div class="result-content" [class.no-img]="jatakLive">

        <div *ngIf="item.shared">
          <ng-container *ngFor="let opt of orderedShared">
            <span *ngIf="item.shared[opt]" class="result-title">{{
              sharedNames[opt]
              }}</span>
          </ng-container>
        </div>

        <div class="result-subtitle">{{item.title}}</div>
      </div>
    </div>
    <div style="width: 24px">
      <ion-icon name="chevron-forward-outline" class="result-right-icon"></ion-icon>
    </div>
  </div>
</div>
