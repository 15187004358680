import { Component, OnInit } from '@angular/core';
import { AppEventsService } from 'app/core/services';

@Component({
  selector: 'coop-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit {
  showLoader: boolean = false;
  loaderMsg = '';

  constructor(private _appEvents: AppEventsService) {}
//** initialize component
  ngOnInit() {
    this._appEvents.$showLoader.subscribe(
      (showLoader) => (this.showLoader = showLoader) // show hide loader
    );
    this._appEvents.$loaderMsg.subscribe(
      (loaderMsg) => (this.loaderMsg = loaderMsg) // show loader msg
    );
  }
}
