import { Injectable } from '@angular/core';
import { Error } from 'app/shared/interface';
import { environment } from 'environments/environment';
import { ErrorMessages } from 'app/core/data';
import { Router } from '@angular/router';
import { TokenService } from 'app/core/services/token.service';
import { WindowService } from 'app/core/services/window.service';
import { Observable, of } from 'rxjs';
import { UserService } from 'app/core/services/userdetail.service';
import { NotificationsService } from './notifications.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorhandlerService {
  constructor(
    private _router: Router,
    private _windowService: WindowService,
    private _userService: UserService
  ) {
  }

  // *** Show General Error Messages ***
  // @params [errorMessage] => Message to be displayed
  // show error messages that are not from api
  static error(errorMessage: string, error?: any) {
    if (!environment.production && error) {
      console.log(error);
    }
    NotificationsService.notify(errorMessage, 'danger', 'top');

  }

  // *** Handle Errors from api ***
  // @params [error] => Error object generate by the api
  // @params @optional [errorof] => Error of which module eg. Offer, Polls etc
  // Check which module the error belongs to
  // Find the relate error message using the error code
  // Show the error message
  // If the error is due to Invalid user, usertype Logout
  handleError(error: Error, errorOf?: string): void {
    errorOf = errorOf || 'system';
    if (!environment.production) {
      console.log(error, errorOf);
    }
    let errorMessage = 'Noget gik galt. Prøv igen';

    if (error.description) {
      errorMessage = error.description;
    }
    errorMessage =
      error.code && ErrorMessages[errorOf][error.code.toString()]
        ? ErrorMessages[errorOf][error.code.toString()]
        : errorMessage;

    if (error.conflicts) {
      errorMessage = 'Den samme dato findes allerede i brugt Ok-skærm.';
    }

    NotificationsService.notify(errorMessage, 'danger', 'top');

    if (error.code === -83) {
      if (TokenService.getUserType() === '-1') {
        this._windowService.window.nativeWindow.open(
          environment.adfslogout,
          'ADFS Logout',
          'width=600, height=420'
        );
        this._userService.removeUserDetails(true);
      }
    }
  }

  // *** Route to 404 page if any error occurs on route resolve ***
  // @params [error] => Error created by resolver
  // route to 404 page
  // @returns [Observable] => error as an observable that route resolver can handle
  routeResolverError(error: any): Observable<any> {
    this.handleError(error);
    this._router.navigate(['/404']).then();
    return of(error);
  }
}
