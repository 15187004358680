export const Poll_Validation_Messages = {
  largeImage: {
    required: 'Udfyld venligst feltet',
  },
  imageUrl: {
    required: 'Udfyld venligst feltet',
  },
  title: {
    required: 'Udfyld venligst feltet',
    maxlength: ' Tekst må højst være 99 tegn'
  },
  startDate: {
    dateMismatchStartEnd:
      'Afslutningstidspunkt skal være senere end Udsendingstidspunkt',
    dateMismatchStartExpire:
      'Tilmeldingen til årsmødet skal være afsluttet inden afstemningens starttidspunkt.',
  },
  endDate: {
    dateMismatchStartEnd:
      'Afslutningstidspunkt skal være senere end Udsendingstidspunkt',
  },
  expirationDate: {
    dateMismatchStartExpire:
      'Tilmeldingen til årsmødet skal være afsluttet inden afstemningens starttidspunkt.',
  },
  dateMismatchStartEnd: {
    mismatch: 'Afslutningstidspunkt skal være senere end Udsendingstidspunkt',
  },
  description: {
    invalid: '[ ] ikke tilladt her.',
  },
  dateMismatchStartExpire: {
    mismatch:
      'Tilmeldingen til årsmødet skal være afsluttet inden afstemningens starttidspunkt.',
  },
};
