import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChildren
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControlName,
  FormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Ok_Screen_Messages } from '../../data';
import * as moment from 'moment';
import { takeWhile, take, debounceTime } from 'rxjs/operators';

import {
  CreateOptionDataService,
  ErrorhandlerService,
  NotificationsService
} from 'app/core/services';
import {
  okScreenDisplaySlots,
  okScreenUsedSlots
} from 'app/core/validators/custom.validator';
import {
  animateParent,
  fadeIn,
  listAnimate,
  listItemFade,
  slideAnimation
} from 'app/shared/animations';
import {
  DisplayMessage,
  ImageUrl,
  ValidationMessage
} from 'app/shared/interface';
import { DateToString } from 'app/shared/pipes';
import { DisplaySlot, OkScreen, OkScreenService, TimeSlot } from 'app/features/ok-screen';
import { GenericValidator } from 'app/core/validators';
import { fromEvent, merge, Observable } from 'rxjs';
import { MbscDatepickerOptions } from '@mobiscroll/angular';
import { COMMON_DATETIMEPICKER_SETTINGS } from 'app/shared/data';

@Component({
  selector: 'coop-okscreen-create-edit',
  templateUrl: './okscreen-create-edit.component.html',
  styleUrls: ['./okscreen-create-edit.component.scss'],
  providers: [DateToString],
  animations: [
    animateParent,
    slideAnimation,
    listAnimate,
    listItemFade,
    fadeIn
  ]
})
export class OkScreenCreateEditComponent
  implements OnInit, OnDestroy, AfterViewInit {
  // Tracks the FormControl instance
  @ViewChildren(FormControlName, { read: ElementRef })
  formInputElements: ElementRef[];
  pageTitle = 'Opret skærme tilbud';
  isProcessing = false;
  okscreenData: OkScreen;
  okscreenForm: FormGroup;
  formSaved = false;
  displayMessage: DisplayMessage = {};
  templateMode = false;
  previousUrl: string;
  successUrl: string;
  parentUrl: string;
  currentUrl: string;
  routeId: string;

  datePickerSettings: MbscDatepickerOptions = {
    ...COMMON_DATETIMEPICKER_SETTINGS, ...{
      dateWheels: 'YYYY MMMM DD',
      dateFormat: 'YYYY-MM-DD'
    }
  };
  numberOfSlots = 0;
  imageUrl: ImageUrl;
  minDate: any;
  maxDate: any;
  readonly validationMessages: ValidationMessage = Ok_Screen_Messages;
  private _validator: GenericValidator;
  private _subscriptionState: boolean = true;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _router: Router,
    private _errorHandlerService: ErrorhandlerService,
    private _okscreenService: OkScreenService,
    private _createOptionService: CreateOptionDataService
  ) {
  }

  ngOnInit() {
    this._addOverlayContainer();
    this._routeDataHandling();
    this._buildForm();
    this._setUpComponentType();
  }

  ngAfterViewInit(): void {
    // Watch for the blur event from any input element on the form.
    const controlBlurs: Observable<any>[] = this.formInputElements.map(
      (formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur')
    );

    // Merge the blur event observable with the valueChanges observable
    merge(this.okscreenForm.valueChanges, ...controlBlurs)
      .pipe(
        debounceTime(800),
        takeWhile(() => this._subscriptionState)
      )
      .subscribe((res) => {
        if (
          Object.keys(this.displayMessage).length &&
          this.displayMessage.constructor === Object
        ) {
          this.displayMessage = this._validator.processArrayMessages(
            this.okscreenForm
          );
        }
      });
  }

  ngOnDestroy(): void {
    this._subscriptionState = false;
    document.body.classList.remove('overlay');
  }

  // *** Slot Handling ***
  // @param [data: DisplaySlot]
  initAnswer(data: DisplaySlot): FormGroup {
    return this._formBuilder.group({
      slotId: [data.slotId],
      endDate: [data.endDate, [Validators.required, okScreenUsedSlots(this._formatUsedSlotTimeData())]],
      startDate: [data.startDate, [Validators.required, okScreenUsedSlots(this._formatUsedSlotTimeData())]]
    });
  }

  addSlot(slotData: DisplaySlot) {
    const displaySlots = this.okscreenForm.controls[
      'displaySlots'
      ] as FormArray;
    displaySlots.push(this.initAnswer(slotData));
    this.numberOfSlots++;
  }

  onAddSlot(): void {
    this.addSlot({ slotId: null, startDate: '', endDate: '' });
  }

  removeSlot(i: number) {
    const displaySlots = this.okscreenForm.controls[
      'displaySlots'
      ] as FormArray;
    displaySlots.removeAt(i);
    this.numberOfSlots--;
  }

  // ************

  checkUsedTimeSlot() {

  }


  // **** Saving...
  save() {
    if (!this._isValid()) {
      return;
    }
    this._okscreenService
      .saveOkScreen(this.templateMode, this._saveData)
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe(
        () => this._onSuccess(),
        (err) => {
          /*if ((err.code === '-908')) {
            for (const index of err.DuplicateKeys) {
              this.okscreenForm
                .get('displaySlots')
                ['controls'][index]['controls'][
                'slotDate'
                ].setErrors({ duplicate: true });
            }
          }*/
          this._handleError(err);
        }
      );
    if (!this.isProcessing) {
      this.isProcessing = true;
    }
  }

  private get _saveData(): object {
    const saveData = { ...this.okscreenForm.getRawValue() };
    if (this.templateMode) {
      return { ...saveData, templateId: this.okscreenData.id };
    } else {
      return { ...saveData, id: this.okscreenData.id };
    }
  }

  private _isValid() {
    if (this.okscreenForm.status === 'INVALID') {
      this._handleError('');
      return false;
    }
    return true;
  }

  private _onSuccess(): void {
    this._createOptionService.clearCreateOption();
    this.formSaved = true;
    this.isProcessing = false;
    this._router.navigate([this.successUrl]).then();
  }

  // *******
  // *** time slots and date settings
  private _formatUsedSlotTimeData(): DisplaySlot[] {
    return this.okscreenData.usedTimeSlot;
  }

  private _setDateOnlySettings(): void {
    this._formatUsedSlotTimeData();
  }

  // *********

  // **** setting up ...
  private _setUpComponentType(): void {
    this.okscreenForm.patchValue(this.okscreenData);
    this._setDateOnlySettings();
    this.minDate = moment(this.okscreenForm.controls['startDate'].value).format('YYYY[-]MM[-]DD[T]HH:mm'); // moment().subtract(5,'days').toDate()// this.okscreenForm.controls['startDate'].value;
    this.maxDate = moment(this.okscreenForm.controls['endDate'].value).format('YYYY[-]MM[-]DD[T]HH:mm'); // moment().add(5, 'days').toDate()// this.okscreenForm.controls['endDate'].value;

    if (this.templateMode) {
      this.previousUrl = this.parentUrl + '/ok-screen/skabeloner/';
      this.addSlot({ slotId: '', startDate: '', endDate: '' });
      return;
    }
    this.previousUrl = this.successUrl + '/' + this.routeId;
    const displaySlots = this.okscreenData.displaySlots;
    for (const slot of displaySlots) {
      this.addSlot(slot);
    }
  }

  private _buildForm(): void {
    this.okscreenForm = this._formBuilder.group({
      startDate: [{ value: '', disabled: true }, [Validators.required]],
      endDate: [{ value: '', disabled: true }, Validators.required],
      displaySlots: this._formBuilder.array([])
    }, { validator: okScreenDisplaySlots });
  }

  private _addOverlayContainer(): void {
    document.body.classList.add('overlay');
  }

  private _routeDataHandling(): void {
    this._activatedRoute.parent.data
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe((url) => (this.parentUrl = url['parentUrl']));
    this._activatedRoute.url
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe((urlSegment) => {
        if (urlSegment.length > 1) {
          this.routeId = urlSegment[1].path;
        } else {
          this.routeId = urlSegment[0].path;
        }
      });
    this.okscreenData = this._activatedRoute.snapshot.data['okscreen'];
    this.templateMode = this._activatedRoute.snapshot.data['template'];
    this.imageUrl = {
      largeImage: this.okscreenData.image,
      thumbnail: ''
    };
    this.currentUrl = this._router.url;
    this.successUrl = this.parentUrl + '/kalendar/ok-screen';
  }

  // ****
  // **** utils
  imageProcessing(e) {
    this.isProcessing = e;
  }

  onDelete(): void {
    NotificationsService.confirmWithCallback('Er du sikker?', (res) => {
      if (res) {
        this.isProcessing = true;
        this._okscreenService
          .deleteOkScreen(this.okscreenData.id)
          .pipe(take(1))
          .subscribe(
            (_) => {
              this._onSuccess();
            },
            (err) => this._handleError(err)
          );
      }
    });
  }

  back() {
    this._router.navigate([this.previousUrl]).then();
  }

  private _handleError(error): void {
    this.isProcessing = false;
    this._errorHandlerService.handleError(error || { code: -400 }, 'okscreen');
  }

  // *****
}
