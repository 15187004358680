import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from 'app/app.component';
import {
  IsAuthenticated,
  RouteToUserType

} from 'app/core/guards';
import {
  AutoLoginComponent,
  NopageComponent
} from 'app/shared/components';

const routes: Routes = [
  {
    path: '',
    component: AppComponent,
    canActivate: [IsAuthenticated, RouteToUserType]
  },
  {
    path: 'verify',
    component: AutoLoginComponent
  },
  {
    path: 'login',
    loadChildren: () =>
      import('app/users/authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      )
  },
  {
    path: 'butikker',
    loadChildren: () =>
      import('app/users/butikker/butikker.module').then(
        (m) => m.ButikkerModule
      )
  },
  {
    path: 'bestyrelse',
    loadChildren: () =>
      import('app/users/bestyrelse/bestyrelse.module').then(
        (m) => m.BestyrelseModule
      )
  },
  {
    path: '**',
    redirectTo: '/404',
    pathMatch: 'full'
  },
  {
    path: '404',
    component: NopageComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
