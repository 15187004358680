import { Component, ViewChild } from '@angular/core';

import {
  INITIAL_FORM_DATA,
  NyhedTilbud,
  OfferFormComponent,
} from 'app/features/offer';

@Component({
  selector: 'coop-offer-create',
  templateUrl: './offer-create.component.html',
  styleUrls: ['./offer-create.component.scss'],
})
export class OfferCreateComponent {
  @ViewChild(OfferFormComponent, { static: true })
  offerFormComp: OfferFormComponent;
  formData?: NyhedTilbud = INITIAL_FORM_DATA;
  constructor() {}
}
