<section class="app-page page-with-fixed-header">
  <div class="fixed-header">
    <coop-pageheader
      [pageTitle]="pageTitle"
      [isChild]="true"
      [isBackGroundWhite]="true"
      [create]="false"
      [step]="selectedOption.step"
      [calendarPreviousUrl]="calendarPreviousUrl"
      [routeTo]="parentUrl"
      (stepEvent)="updateStep($event)"
    ></coop-pageheader>
  </div>

  <div class="page-container">
    <div class="create-new-section">
      <div class="title ">
        Vælg type vare
      </div>

      <ng-container *ngFor="let item of menuItems">
        <div class="list" (click)="selectChannel(item)">
          <div class="sub-list">
            <ion-icon name="{{ item.icon }}"></ion-icon>
            <div class="sub-title">
              <div>
                {{ item.title }}
              </div>
              <span>
              {{ item.subTitle }}
            </span>
            </div>
          </div>
          <ion-icon name="chevron-forward-outline"
                    [class.selected]="selectedChannel === item.type"
          ></ion-icon>

        </div>
        <div class="selected-channels"
             [class.visible]="selectedChannel === item.type"
             *ngIf="item.hasSubMenu && postEventType !== 'template'"
        >
          <create-option-channel-select
            [type]="item.type"
            [userType]="userType"
            [selectedOption]="selectedOption"
            [parentUrl]="parentUrl"
          >
          </create-option-channel-select>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="popup-window" *ngIf="showDuplicateError">
    <div class="select-box-container">
      <div class="select-box-content">
        <div class="select-box-title">
          <h4>{{ popupText.title }}</h4>
        </div>
        <div class="select-box-info">
          <p>{{ popupText.info }}</p>
        </div>
      </div>
      <div class="select-btn">
        <div class="row">
          <button class="btn btn-red" (click)="showDuplicateError = false">
            Ok
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
