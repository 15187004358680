export interface Product {
  id: number;
  largeImage: string;
  category: string;
  title: string;
  description: string;
  price: number;
  totalOffer: number;
  offerPerDay: number;
  ended_description: string;
  productionTime: number;
  productActive: boolean;
  allowComments: boolean;
  pickUpPeriod: boolean;
  alcoholic: boolean;
  pickUp: PickupPeriod;
  activeCart: boolean;
  ordered: boolean;
  minimumOrder: number;
  totalRemaining: number;
  totalSold: number;
  reservedQuantity: number;
  totalOfferUnlimited: number;
  offerPerDayUnlimited: number;
}

export interface ProductListData {
  data: ProductList[];
  links: Links;
}

export interface ProductList {
  id: number;
  largeImage: string;
  category: string;
  title: string;
  totalOffer: number;
  pickUpPeriod: boolean;
  pickUp: PickupPeriod;
  price: string;
  totalRemaining: number;
  totalOfferUnlimited: number;
}

export interface PickupPeriod {
  StartDate: string;
  EndDate: string;
}

export interface ShopHours {
  date: string;
  from: string;
  to: string;
  workingHours: number;
  holiday: boolean;
}

export interface FormattedShopHour {
  d: Date;
  end: string;
  start: string;
}

export interface Links {
  first: string;
  last: string;
  next: string;
  prev: string;
}

export interface ProductTemplate {
  LongDescription: string;
  Thumbnail: string;
  Title: string;
  id: string;
  new: boolean;
}
