import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { catchError } from 'rxjs/operators';

import { ErrorhandlerService, TokenService } from 'app/core/services';
import {
  Events,
  SingleEvent,
  SpecialEventPrefill,
} from 'app/features/events/interface';

import { EventsService } from './events.service';
// *** Active events Resolver ***
// It runs before viewing active/archive/future/cancelled event list page by admin
// @returns [ event: SingleEvent[] ] => All the active events
@Injectable({
  providedIn: 'root',
})
export class EventsListResolver  {
  constructor(
    private _eventsService: EventsService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this._eventsService.getEventsList(1, 1).pipe(
      catchError((err) => {
        return this._errorHandlerService.routeResolverError(err);
      })
    );
  }
}
// *** Regular Event Redigere Resolver ***
// It runs before editing any Regular events
// @returns [ event: SingleEvent ] => Particular Regular Event for edit form
@Injectable({
  providedIn: 'root',
})
export class EventsResolver  {
  constructor(
    private _eventsService: EventsService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this._eventsService.getEvent(route.params['id']).pipe(
      catchError((err) => {
        return this._errorHandlerService.routeResolverError(err);
      })
    );
  }
}
// *** Special Event Skabeloner Resolver ***
// It runs before creating any special events
// @returns [specialEventPrefill] => Special Event Templates data for create form
@Injectable({
  providedIn: 'root',
})
export class SpecialEventPrefilledResolver
  
{
  constructor(
    private _eventsService: EventsService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this._eventsService.getPrefilledSpecialEvent().pipe(
      catchError((err) => {
        return this._errorHandlerService.routeResolverError(err);
      })
    );
  }
}
// *** Special Event Redigere Resolver ***
// It runs before editing any special events
// @returns [ event: SingleEvent ] => Particular Special Event for edit form
@Injectable({
  providedIn: 'root',
})
export class SpecialEventResolver  {
  constructor(
    private _eventsService: EventsService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this._eventsService.getSpecialEvent(route.params['id']).pipe(
      catchError((err) => {
        return this._errorHandlerService.routeResolverError(err);
      })
    );
  }
}
// *** Assembly Event Skabeloner Resolver ***
// It runs before creating any assembly events
// @returns [specialEventPrefill] => Assembly event Templates data for create form
@Injectable({
  providedIn: 'root',
})
export class AssemblyPrefilledResolver  {
  constructor(
    private _eventsService: EventsService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this._eventsService.getPrefilledAssembly().pipe(
      catchError((err) => {
        return this._errorHandlerService.routeResolverError(err);
      })
    );
  }
}
// *** Assembly Event Redigere Resolver ***
// It runs before editing any assembly events
// @returns [ event: SingleEvent ] => Particular Assembly Event for edit form
@Injectable({
  providedIn: 'root',
})
export class AssemblyEventResolver  {
  constructor(
    private _eventsService: EventsService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this._eventsService.getAssemblyEvent(route.params['id']).pipe(
      catchError((err) => {
        return this._errorHandlerService.routeResolverError(err);
      })
    );
  }
}
// *** Assembly Event Child Kardex Resolver For Parent ***
// It runs before creating or editing any assembly events
// @returns Observable<KardexData>
@Injectable({
  providedIn: 'root',
})
export class AssemblyEventKardexResolver  {
  constructor(
    private _eventsService: EventsService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this._eventsService.getAssemblyKardexList().pipe(
      catchError((err) => {
        return this._errorHandlerService.routeResolverError(err);
      })
    );
  }
}
