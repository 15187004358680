<div class="oversight-section">
  <div (click)="toggleOversight()" class="flex-display over-sight-title">
    <div>
      Facebook oversigt
    </div>
    <div >
      <ion-icon *ngIf="!showOversight" name="chevron-down-outline"></ion-icon>
      <ion-icon *ngIf="showOversight" name="chevron-up-outline"></ion-icon>
    </div>
  </div>
  <div *ngIf="showOversight" class="separator"></div>
  <div *ngIf="showOversight" class="oversight-info">
    <div class="list flex-display">
      <div class="flex-display">
        <ion-icon name="cash-outline"></ion-icon>
        <div style="margin-left: 5px">
          Omsætning
        </div>
      </div>
      <div>
        {{summaryList.turnover}}
      </div>
    </div>
    <div class="list flex-display">
      <div class="flex-display">
        <ion-icon name="card-outline"></ion-icon>
        <div style="margin-left: 5px">
          Solgte produkter
        </div>
      </div>
      <div>
        {{summaryList.sold}}
      </div>
    </div>

    <div class="list flex-display">
      <div class="flex-display">
        <ion-icon name="radio-outline"></ion-icon>
        <div style="margin-left: 5px">
          FB reach
        </div>
      </div>
      <div>
        {{summaryList.facebookReach}}
      </div>
    </div>
    <div class="list flex-display">
      <div class="flex-display">
        <ion-icon name="people-outline"></ion-icon>
        <div style="margin-left: 5px">
          Kunder
        </div>
      </div>
      <div>
        {{summaryList.customers}}
      </div>
    </div>
    <div class="list flex-display">
      <div class="flex-display">
        <ion-icon name="bag-outline"></ion-icon>
        <div style="margin-left: 5px">
          Ordre
        </div>
      </div>
      <div>
        {{summaryList.orderCount}}
      </div>
    </div>
    <div class="list flex-display">
      <div class="flex-display">
        <ion-icon name="bag-check-outline"></ion-icon>
        <div style="margin-left: 5px">
          Afhentet
        </div>
      </div>
      <div>
        {{summaryList.pickedUp}}
      </div>
    </div>
    <div class="list flex-display">
      <div class="flex-display">
        <ion-icon name="pie-chart-outline"></ion-icon>
        <div style="margin-left: 5px">
          Tilbage
        </div>
      </div>
      <div>
        {{summaryList.remainingStock}}
      </div>
    </div>
  </div>

</div>
