import { Injectable } from '@angular/core';
import { NotificationsService } from './notifications.service';
import {SegmentedMessage} from "sms-segments-calculator";
@Injectable({
  providedIn: 'root',
})
export class HelperService {
  constructor() {}

  // *** Copies HTML Input element value to system clipboard ***
  // Used in admin event creation page to copy zoom meeting link
  // @params [el:HtmlInputElement] => The element whose value is to be copied
  static copyToClipboard(el: HTMLInputElement): void {
    if (el.value === '' || el.value === '0') {
      return;
    }
    el.select();
    document.execCommand('copy');
    el.setSelectionRange(0, 0);
    NotificationsService.notify('Kopieret til udklipsholderen', 'info', 'top');
  }

  // *** prevents invalid characters for phone and price ***
  // @params [event : KeyboardEvent]=> keyDown event
  static preventInvalidNumbers(event: any): void {
    const keyPressed = event.key;
    const keyCode = keyPressed.charCodeAt(0);
    if (keyCode === 66 || keyCode === 65 || keyCode === 84) {
      return;
    }
    const regex = /^[0-9.+,]+$/;
    if(keyCode === 118 ){
      return;
    }
    if (!regex.test(keyPressed)) {
      event.preventDefault();
    }
  }




  static preventSpecialCharacterForJatakKeyword(event:any){
    const keyPressed = event.key;
    const keyCode = keyPressed.charCodeAt(0);
    const regex = /[^A-Za-z0-9æåøÆÅØ]/;
    if (regex.test(keyPressed)) {
      event.preventDefault();
    }
  }

// *** Used to check if error-message exists on this.errorMessage field ***
  // params [object: object] => JS keyed collections
  // returns [boolean] => true if some value exists
  //                      false if empty
  static isNotEmpty(object: Object): boolean {
    return Object.values(object).some(
      (x) => x !== undefined && x !== null && x !== ''
    );
  }
// *** It is used to provide random originId to Jatak post ***
  // @returns [guid:string] => A random guid string. example: 99fb4920-30d3-4290-a784-b5e51214c58a
  static guid() {
    return (
      this.s4() +
      this.s4() +
      '-' +
      this.s4() +
      '-4' +
      this.s4().substring(0, 3) +
      '-' +
      this.s4() +
      '-' +
      this.s4() +
      this.s4() +
      this.s4()
    ).toLowerCase();
  }
// *** It is used to get 4 characters of hexadecimal value ***
  // @returns [guid:string] => A random 4-digits hexadecimal, example: 99fb
  private static s4(): string {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }

  static unicodeToRendered(text) {
    return text.replace(/\\u(\w{4})/g, (match, hex) => String.fromCharCode(parseInt(hex, 16)));
  }


  static renderedToUnicode(text) :any {
    // @ts-ignore
    return Array.from(text).map(char => "\\u" + char.charCodeAt(0).toString(16).padStart(4, "0")).join("");
  }


  static smsParser(message:string){
    message = this.renderedToUnicode(message);

    // Perform any operations on the rendered text if needed
    const modifiedText = message.replace(/\\u203c\\ufe0f/g, '');

    message = this.unicodeToRendered(modifiedText);

    let segmentedMessage = new SegmentedMessage(message, 'auto', true);
    let converted = segmentedMessage.graphemes.join("");
    let nonGsm = segmentedMessage.getNonGsmCharacters();



    if (nonGsm.length > 0) {
      nonGsm.forEach((i) => {
        // @ts-ignore
        converted = converted.replace(`${i}`, '')
        // converted = converted.replace('\n', '');
      })
      segmentedMessage = new SegmentedMessage(converted, 'GSM-7', true);
    }
    return {
      'text': converted,
      'length': segmentedMessage.numberOfCharacters,
      'parts': segmentedMessage.segments.length
    }
  }



}
