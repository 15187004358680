import { inject, Injectable } from '@angular/core';
import { CanDeactivateFn, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { NotificationsService } from 'app/core/services';
import { PollCreateEditComponent } from 'app/features/poll';

@Injectable({
  providedIn: 'root'
})
export class PollGuardService {
  constructor() {
  }

  canDeactivate(
    component: PollCreateEditComponent
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (component.pollForm.dirty && !component.formSaved) {
      const pollTitle =
        component.pollForm.get('title').value || 'Ny Afstemning';
      return NotificationsService.confirm(
        `Dine ændringer til ${pollTitle} bliver ikke gemt.`
      );
    }
    return true;
  }
}

export const PollGuard: CanDeactivateFn<PollCreateEditComponent> = (component) => {
  return inject(PollGuardService).canDeactivate(component);
};
