import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormControlName,
  FormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { fromEvent, merge, Observable } from 'rxjs';
import { debounceTime, takeWhile } from 'rxjs/operators';

import {
  FormFunctionsService,
  HelperService,
  ImageService,
  NotificationsService
} from 'app/core/services';
import {
  checkIfEndDateIsLessThanStartDate,
  checkIfExpDateIsLessThanPublishDate,
  checkIfExpireEndMismatch,
  checkIfOfferPriceLessThanRealScreen, checkMaxLength,
  checkPriceMinimum,
  checkPriceRegex,
  checkWhiteSpace,
  GenericValidator,
  maxLengthOfEight
} from 'app/core/validators';
import { fadeIn } from 'app/shared/animations';
import { COMMON_DATETIMEPICKER_SETTINGS } from 'app/shared/data';
import {
  DisplayMessage,
  EditorOptions,
  Modes,
  MultipleImage,
  ValidationMessage,
  VideoUrls
} from 'app/shared/interface';
import {
  INITIAL_FORM_DATA,
  NyhedTilbud,
  ScreenList,
  OfferDateTimeService,
  OfferStateService, OfferDataService
} from 'app/features/offer';
import { MbscDatepickerOptions } from '@mobiscroll/angular';
import { DatepickerBase } from '@mobiscroll/angular/dist/js/core/components/datepicker/datepicker';

@Component({
  selector: 'coop-screen-form',
  templateUrl: './screen-form.component.html',
  styleUrls: ['./screen-form.component.scss'],
  animations: [fadeIn]
})
export class ScreenFormComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChildren(FormControlName, { read: ElementRef })
  formInputElements: ElementRef[];
  @Input() modes: Modes;
  @Input() validationMessages: ValidationMessage;
  @Input() validateModes = [];
  @Input() submitForm = false;
  @Input() currentTab = 'coop';
  @Input() offerType: string = 'tilbud';
  @Output() formReady = new EventEmitter<FormGroup>();
  formData?: NyhedTilbud = INITIAL_FORM_DATA;
  screenForm: FormGroup;
  imageUrl;
  defaultImageData = [
    { url: 'assets/images/camera@3x.jpg', hasImage: false },
    { url: 'assets/images/camera@3x.jpg', hasImage: false },
    { url: 'assets/images/camera@3x.jpg', hasImage: false },
    { url: 'assets/images/camera@3x.jpg', hasImage: false }
  ];
  defaultVideoData = {
    url: '',
    thumbnail: 'assets/images/video.png'
  };
  videoUrl: VideoUrls = {
    url: '',
    thumbnail: 'assets/images/video.png',
    orientation: ''
  };
  hasImage = false;
  hasVideo = false;
  isGif = false;
  isVideo = false;
  isProcessing = false;
  displayMessage: DisplayMessage = {};
  screenEditorOptions: EditorOptions = { frame: false, priceTag: false };
  screenCount: number = 2;
  initScreenList: ScreenList[] = [];
  notSelectedScreenList: ScreenList[] = [];
  tempSelectedScreenList: ScreenList[] = [];
  screenList: ScreenList[] = [];
  showScreenDropdown = false;
  priceTagList = ['Gul stjerne', 'Rød boble'];
  tempSelectedPriceTag: number;
  selectedPriceTag: number = 1;
  isCoop365User = false;
  visible: boolean = false;
  datePickerSettings: MbscDatepickerOptions = {
    ...COMMON_DATETIMEPICKER_SETTINGS, ...{
      dateWheels: 'YYYY MMMM DD',
      dateFormat: 'YYYY-MM-DD',
      stepMinute: 15,
      onOpen: (_, inst) => this._setUpDateTime(inst),
      onClose: (_, inst) => this._closeDatePicker(inst)
    }
  };
  private _validator: GenericValidator;
  private _subscriptionState = true;

  constructor(
    private _formBuilder: FormBuilder,
    private _formStateService: OfferStateService,
    private _activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this._validator = new GenericValidator(this.validationMessages);
    this.imageUrl = JSON.parse(JSON.stringify(this.defaultImageData));
    this.initializeForms();
  }

  ngAfterViewInit(): void {
    // Watch for the blur event from any input element on the form.
    const controlBlurs: Observable<any>[] = this.formInputElements.map(
      (formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur')
    );

    // Merge the blur event observable with the valueChanges observable
    merge(this.screenForm.valueChanges, ...controlBlurs)
      .pipe(
        debounceTime(800),
        takeWhile(() => this._subscriptionState)
      )
      .subscribe(() => {
        if (
          Object.keys(this.displayMessage).length &&
          this.displayMessage.constructor === Object
        ) {
          this.displayMessage = this._validator.processMessages(
            this.screenForm
          );
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['offerType']) {
      this.offerType = changes['offerType'].currentValue;
    }
    if (changes['modes']) {
      this.modes = changes['modes'].currentValue;
    }
    if (changes['submitForm']) {
      this.submitForm = changes['submitForm'].currentValue;
      this._isValid();
    }
    if (changes['currentTab']) {
      if (changes['currentTab'].currentValue === 'screen') {
        if (
          !(this.modes.editMode || this.modes.templateMode) &&
          this.validateModes[0] !== 'screen'
        ) {
          this.initializeForms();
        }
      }
    }
  }

  ngOnDestroy(): void {
    this._subscriptionState = false;
  }

  initializeForms() {
    if (!this.screenForm) {
      this._setupScreenForm();
    }
    if (!this.modes.newsMode && this.offerType === 'tilbud') {
      this._setPriceValidators();
    }
    this.formData = this._formStateService.formState.value;
    this.initScreenList = this._activatedRoute.snapshot.data['screenList']
      ? this._activatedRoute.snapshot.data['screenList']
      : [];
    if (
      this.formData.screen?.isSet ||
      this.modes.editMode ||
      this.modes.templateMode
    ) {
      this._editModeSetup(this.formData);
    }
    if (!this.modes.editMode && !this.modes.templateMode) {
      this.createScreenForm();
    }
    this.formReady.emit(this.screenForm);
  }

  public get getForm() {
    return this.screenForm;
  }

  createScreenForm() {
    const screenForm = this.getForm;
    const screenImageUrl = this.imageUrl;
    const firstValidOption = this.validateModes[0];
    const parentForm = this.formData[firstValidOption];

    const ditForm = this.formData['dit'];
    const patchFromFb = this.formData.facebook?.largeImage.length > 0;
    if (firstValidOption !== 'screen') {
      if (FormFunctionsService.checkparentFormEmpty(parentForm)) {
        return;
      }
      const patchableUrl = this.getPatchableUrl(patchFromFb);
      if (FormFunctionsService.checkFormEmpty(screenForm.value)) {
        screenForm.patchValue({
          title: parentForm.title,
          longDescription: OfferDataService.descriptionChanges(firstValidOption, this.modes.newsMode, parentForm.longDescription),
          dutyText: parentForm.inAppParameters
            ? parentForm.inAppParameters.priceDescription
            : ditForm.dutyText,
          weight: ditForm?.weight,
          publishDate: parentForm.publishDate,
          expirationDate: parentForm.expirationDate,
          price: parentForm.inAppParameters
            ? parentForm.inAppParameters.price
            : ditForm.price,
          offerPrice: parentForm.inAppParameters
            ? parentForm.inAppParameters.offerPrice
            : ditForm.offerPrice,
          startDate: parentForm.startDate,
          endDate: parentForm.endDate
        });
        if (firstValidOption === 'sms') {
          // sms doesnt have other dates, so we set to default
          OfferDateTimeService.setDefaultTimeOnCreate(screenForm);
        }
        (this.getForm.get('largeImage') as FormArray).push((() => {
            return parentForm.largeImage ? new FormControl(parentForm.largeImage) : new FormControl(parentForm.thumbnail);
          })()
        );
        patchFromFb ? this._patchImageToMultipleImageSlider(patchableUrl) : this._patchMultipleMedia();
      }
      if (!screenImageUrl.hasImage && patchFromFb) {
        Object.assign(screenImageUrl, patchableUrl);
        this.hasImage = true;
      }
    }
    // set default date to now on create
    if (FormFunctionsService.checkFormEmpty(screenForm.value)) {
      OfferDateTimeService.setDefaultTimeOnCreate(screenForm);
    }
    if (!(this.modes.editMode || this.modes.templateMode)) {
      this._createModeSetup();
    }
  }

  getPatchableUrl(patchFromFb: boolean) {
    if (patchFromFb) {
      return ImageService.getPatchableUrl(this.formData.facebook.largeImage);
    }
    return [{ url: '', hasImage: false }];
  }

  priceTagSelection(priceTag) {
    this.selectedPriceTag = priceTag;
  }

  setScreenListOnEdit(promo: NyhedTilbud): void {
    this.initScreenList = promo.screen.screenLists;
    const selectedScreens = promo.screen.screens.split(',');
    this.screenList = this.initScreenList.filter(
      (screen) => selectedScreens.indexOf(screen.id.toString()) !== -1
    );
    this.screenCount = this.screenList.length;
  }

  initScreen(): void {
    let screenData;
    if (this.screenCount === 1) {
      screenData = this.screenList[0].id;
    }
    if (this.screenCount > 1) {
      screenData = [];
      this.screenList.forEach((screen) => {
        screenData.push(screen.id);
      });
      screenData = screenData.join();
    }
    this.notSelectedScreenList = this.initScreenList.filter(
      ({ id: id1 }) => !this.screenList.some(({ id: id2 }) => id2 === id1)
    );
    this._patchScreenData(screenData);
  }

  openScreenDropDown() {
    this.showScreenDropdown = !this.showScreenDropdown;
    this.tempSelectedScreenList = [];
  }

  addScreenTemp(screen: ScreenList) {
    const index = this.tempSelectedScreenList.indexOf(screen);
    if (index === -1) {
      this.tempSelectedScreenList.push(screen);
    }
    if (index > -1) {
      this.tempSelectedScreenList.splice(index, 1);
    }
  }

  addScreen() {
    this.screenList.push(...this.tempSelectedScreenList);
    this.screenCount += this.tempSelectedScreenList.length;
    this.tempSelectedScreenList = [];
    this.showScreenDropdown = false;
    this.initScreen();
  }

  removeScreen(i: number) {
    this.screenList.splice(i, 1);
    this.screenCount -= 1;
    this.initScreen();
  }

  getVideoUrl(videoUrl: any) {
    this.videoUrl = {
      thumbnail: videoUrl.thumbnail,
      url: videoUrl.url,
      orientation: videoUrl.orientation
    };
    this.hasVideo = true;
    this.screenForm.patchValue({
      video: {
        url: videoUrl.url,
        thumbnail: videoUrl.thumbnail,
        orientation: videoUrl.orientation
      }
    });
  }

  getImagesUrl(images: any) {
    Object.assign(this.imageUrl, images);
    this.hasImage = true;
    this._patchImageToMultipleImageSlider(images);
  }

  imageProcessing(e: boolean) {
    this.isProcessing = e;
  }

  uploadTypeSelect(type: boolean) {
    this.clearingMediaOnTabChange(type);
  }

  clearingMediaOnTabChange(type: boolean) {
    let isPrompt;
    if (type) {
      isPrompt = this.hasImage;
    }
    if (!type) {
      isPrompt = this.hasVideo;
    }
    if (isPrompt) {
      this.clearMedia(type);
    }
    if (!isPrompt) {
      this.changeUploadType(type);
    }
  }

  clearMedia(isVideo: boolean) {
    let clearFunction;
    let notificationMsg;
    if (isVideo) {
      notificationMsg =
        'Alle uploadede billeder kasseres ved at skifte faner. Er du sikker?';
      clearFunction = this.clearImageUrl;
    }
    if (!isVideo) {
      notificationMsg =
        'Den uploadede video kasseres ved at skifte faner. Er du sikker?';
      clearFunction = this.clearVideo;
    }
    NotificationsService.confirmWithCallback(notificationMsg, (accept) => {
      if (accept) {
        clearFunction(this);
        this.changeUploadType(isVideo);
        return;
      }
      if (!accept) {
        return;
      }
    });
  }

  changeUploadType(isVideo: any) {
    this.isVideo = isVideo;
    this.changeMediaType(isVideo);
  }

  // allow screen to upload vertical video as well so no notification message
  changeMediaType(isVideo: any) {
    this.changeMediaValidators(isVideo);
    this.setMediaToNull(isVideo);
  }

  changeMediaValidators(isVid: boolean) {
    const promoForm = this.screenForm;
    let videoValidity;
    let largeImageValidity;
    if (isVid) {
      videoValidity = Validators.required;
      largeImageValidity = null;
    }
    if (!isVid) {
      videoValidity = null;
      largeImageValidity = Validators.required;
    }
    this._updateValidators('video.url', videoValidity);
    this._updateValidators('video.thumbnail', videoValidity);
    this._updateValidators('largeImage', largeImageValidity);
  }

  setMediaToNull(isVideo: boolean) {
    if (isVideo) {
      this.screenForm.patchValue({
        largeImage: [null]
      });
    }
    if (!isVideo) {
      this.screenForm.patchValue({
        video: {
          url: null,
          thumbnail: null
        }
      });
    }
  }

  clearImageUrl(context: any) {
    context.imageUrl = JSON.parse(JSON.stringify(context.defaultImageData));
    context.hasImage = false;
    // clearing image
    const control = context.screenForm.get('largeImage') as FormArray;
    // clearing array;
    while (control.length !== 0) {
      control.removeAt(0);
    }
  }

  clearVideo(context: any) {
    context.videoUrl = JSON.parse(JSON.stringify(context.defaultVideoData));
    context.hasVideo = false;
    context.screenForm.patchValue({
      video: {
        url: '',
        thumbnail: ''
      }
    });
  }

  validateNumber(event) {
    HelperService.preventInvalidNumbers(event);
  }

  private _setupScreenForm() {
    const validators = [checkIfExpDateIsLessThanPublishDate];
    if (!this.modes.newsMode) {
      validators.push(checkIfExpireEndMismatch);
      validators.push(checkIfEndDateIsLessThanStartDate);
      validators.push(checkIfOfferPriceLessThanRealScreen);
    }
    this.screenForm = this._formBuilder.group(
      {
        title: ['', [Validators.required, checkWhiteSpace, checkMaxLength]],
        longDescription: ['', [Validators.required, checkWhiteSpace]],
        largeImage: this._formBuilder.array([], [Validators.required]),
        publishDate: ['', Validators.required],
        expirationDate: ['', Validators.required],
        startDate: ['', Validators.required],
        endDate: ['', Validators.required],
        priceTag: [''],
        dutyText: ['', Validators.maxLength(50)],
        coop365: [null],
        screens: ['', Validators.required],
        price: ['', [checkPriceRegex]],
        offerPrice: ['', [checkPriceRegex]],
        weight: [''],
        video: this._formBuilder.group({
          url: [''],
          thumbnail: [''],
          orientation: ['']
        })
      },
      {
        validators: validators
      }
    );
  }

  private _patchScreenData(screenData: string) {
    this.screenForm.patchValue({
      screens: screenData
    });
  }

  private _updateValidators(formControl: string, validators = []): void {
    this.screenForm.get(formControl).setValidators(validators);
    this.screenForm.get(formControl).updateValueAndValidity();
  }

  private _setPriceValidators() {
    const priceValidators = [checkPriceMinimum, checkPriceRegex];
    const descValidators = [Validators.required, checkWhiteSpace];
    const weightValidators = [maxLengthOfEight];
    if (this.offerType === 'tilbud') {
      priceValidators.push(Validators.required);
      weightValidators.push(Validators.required);
      this._updateValidators('dutyText', descValidators);
      this._updateValidators('offerPrice', priceValidators);
    }
    this._updateValidators('price', priceValidators);
    this._updateValidators('priceTag', descValidators);
    this._updateValidators('weight', weightValidators);
  }

  private _createModeSetup() {
    if (this.initScreenList.length > 0) {
      if (!this.modes.newsMode) {
        this.tempSelectedPriceTag = this.selectedPriceTag;
        this._setPriceTagList();
      }
      this.screenList =
        this.initScreenList.length > 2
          ? JSON.parse(JSON.stringify(this.initScreenList)).splice(0, 2)
          : JSON.parse(JSON.stringify(this.initScreenList));
      this.initScreen();
    }
  }

  private _editModeSetup(promo: NyhedTilbud) {
    this.getForm.patchValue(promo.screen);
    this._patchingMultipleImageToForm();
    this._patchMultipleMedia();
    if (!this.modes.templateMode) {
      this.setScreenListOnEdit(this.formData);
      this.selectedPriceTag = this.priceTagList.indexOf(
        this.formData.screen.priceTag
      );
      this.initScreen();
    }
    if (this.modes.templateMode) {
      this.initScreenList = this.formData.screen
        ? this.formData.screen.screenLists
        : [];
      this.screenList =
        this.initScreenList.length > 2
          ? JSON.parse(JSON.stringify(this.initScreenList)).splice(0, 2)
          : JSON.parse(JSON.stringify(this.initScreenList));
      this.initScreen();
    }
    if (!this.modes.newsMode) {
      this.tempSelectedPriceTag = this.selectedPriceTag;
      this._setPriceTagList();
    }
  }

  onSelect() {
    this.visible = true;
  }

  ondeselect() {
    this.visible = false;
  }


  private _setPriceTagList() {
    if (this.isCoop365User) {
      this.priceTagList = ['COOP365 stjerne'];
      this.getForm.patchValue({
        priceTag: 'COOP365 stjerne'
      });
    } else {
      this.getForm.patchValue({
        priceTag: this.priceTagList[this.selectedPriceTag]
      });
    }
  }

  private _patchImageToMultipleImageSlider(images) {
    const urlArray = ScreenFormComponent._convertImageDataToURlArray(images);
    const control = this.screenForm.get('largeImage') as FormArray;
    // clearing array;
    while (control.length !== 0) {
      control.removeAt(0);
    }
    for (const img of urlArray) {
      control.push(new FormControl(img));
    }
  }

  private _patchMultipleMedia() {
    this.isVideo = this.formData.screen?.largeImage[0] === null;
    let imgUrl;
    if (!this.isVideo) {
      this.imageUrl = ImageService.getPatchableUrl(
        this.getForm.value.largeImage
      );
      this.hasImage = true;
      imgUrl = this.imageUrl[0].url;

      const lastIndexOfDot = imgUrl.lastIndexOf('.');
      const fileFormat = imgUrl.slice(lastIndexOfDot);
      if (fileFormat === '.gif') {
        this.isGif = true;
      }
    }
    if (this.isVideo) {
      this.videoUrl = this.getForm.value.video;
      this.hasVideo = true;
      this.changeUploadType(this.isVideo);
      // we need to clear image in form because while patching , form array forms a formControl and hence largeImage is not empty and valid
      this.clearImageUrl(this);
    }
  }

  private _patchingMultipleImageToForm() {
    const controlLargeImage = this.getForm.get('largeImage') as FormArray;
    for (const url of this.formData.screen?.largeImage) {
      controlLargeImage.push(new FormControl(url));
    }
  }

  private static _convertImageDataToURlArray(
    imageSliderArray: MultipleImage[]
  ) {
    const urlArray = [];
    for (const img of imageSliderArray) {
      if (img.hasImage) {
        urlArray.push(img.url);
      }
    }
    return urlArray;
  }

  // ** Prepare Form Data and check Validation ** //
  // @return [boolean] => return false if form is invalid
  private _isValid() {
    this.submitForm = false;
    if (this.screenForm && this.screenForm.status === 'INVALID') {
      this.displayMessage = this._validator.processMessages(this.screenForm);
      return false;
    }
    return true;
  }

  // date time value set for mobiscroll date-time
  private _setUpDateTime(inst: DatepickerBase) {
    if (!this.modes.editMode) {
      OfferDateTimeService.setUpDateTime(this.screenForm, inst._el.id, 'screen');
    }
  }

  // on close of datetime
  private _closeDatePicker(inst: DatepickerBase) {
    const dateId = inst._el.id;
    if (this.modes.newsMode) {
      this._patchStartEndDatesForNewsMode(dateId);
    }
  }

  private _patchStartEndDatesForNewsMode(dateId) {
    const promoForm = this.getForm;
    if (dateId.indexOf('publishscreen') === 0) {
      promoForm.patchValue({
        startDate: promoForm.value.publishDate
      });
    }
    if (dateId.indexOf('expirescreen') === 0) {
      promoForm.patchValue({
        endDate: promoForm.value.expirationDate
      });
    }
  }
}
