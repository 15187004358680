import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';

import { FaceBookPage } from '../../interface/facebook.interface';
import {
  AppEventsService,
  ErrorhandlerService,
  NotificationsService,
  TokenService,
  UserService,
  WindowService,
} from '../../../../core/services';
import { Router } from '@angular/router';
import { mergeMap, takeWhile } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';

import { FacebookService, FacebookEventsService } from '../../services';
import { fadeInOutAnimation } from '../../../../shared/animations';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'coop-connect',
  templateUrl: './connect.component.html',
  styleUrls: ['./connect.component.scss'],
  animations: [fadeInOutAnimation],
})
export class ConnectComponent implements OnInit, OnDestroy {
  title: string = 'Klik på siden for at oprette forbindelse';
  isConnected: boolean = false;
  showLogin: boolean = false;
  pages: FaceBookPage[] = [];
  pageResponse: boolean = true;
  nextList: string;
  prevList: string;
  fbloginurl: string = '';
  child: Window;
  private _subscriptionState: boolean = true;

  constructor(
    private _windowService: WindowService,
    private _appEvents: AppEventsService,
    private _router: Router,
    private _fbService: FacebookService,
    private _errorHandlerService: ErrorhandlerService,
    private _renderer: Renderer2,
    private _userService: UserService,
    private _facebookEvents: FacebookEventsService
  ) {}

  ngOnInit() {
    this.child = this._windowService.window;
    this._receiveToken();
    this._closeModalOnRouteChange();
    this._connectToFacebook();
    this._disconnectFromFacebook();
  }

  ngOnDestroy(): void {
    this._subscriptionState = false;
  }

  pageLogin() {
    // IF Facebook Dialog Url is fetched open it in a child poppup
    if (this.fbloginurl) {
      this.child.open(
        this.fbloginurl,
        'Facebook Login',
        'width=600, height=420'
      );
    }
  }

  closeFbLogin() {
    this.showLogin = false;
  }

  selectPage(page: FaceBookPage) {
    this.closeFbLogin();
    this._fbService
      .extendToken(page.access_token)
      .pipe(
        takeWhile(() => this._subscriptionState),
        mergeMap((token) => this._saveFBDetails(page, token)),
        mergeMap(() => this._userService.getUserDetail())
      )
      .subscribe({
        next: (userDetail) => {
          TokenService.setUserDetail(userDetail);
          this._facebookEvents.facebookConnected(true);
          NotificationsService.notify(
            'Tilsluttet Facebook!!! Nu kan du dele på facebook',
            'success',
            'top'
          );
        },
        error: (error) => this._errorHandlerService.handleError(error),
      });
  }

  // Facebook returns 10 items per request
  // If there is more than 10 we use this to move through the pagination
  loadMorePages(type: string): void {
    const url = type === 'prev' ? this.prevList : this.nextList;
    this._fbService
      .getPagination(url)
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (fbResponse) => this._loadPageList(fbResponse),
        error: (err) => this._errorHandlerService.handleError(err),
      });
  }

  // Listen to PostMessage from child Popup
  // Should receive data from Facebook after proper permissions
  private _receiveToken(): void {
    this._renderer.listen('window', 'message', (event) => {
      this._receiveFacebookToken(event);
    });
  }

  // Close FB Login on any route changes
  private _closeModalOnRouteChange(): void {
    this._router.events
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe(() => this.closeFbLogin());
  }

  // Listen to any event asking to connect FB
  // Fetch FB login Url
  private _connectToFacebook(): void {
    this._facebookEvents.$connectFacebook
      .pipe(
        takeWhile(() => this._subscriptionState),
        mergeMap((state) => this._setfbUrl(state))
      )
      .subscribe({
        next: (loginUrl) => (this.fbloginurl = loginUrl),
        error: (error) => this._errorHandlerService.handleError(error),
      });
  }

  private _disconnectFromFacebook(): void {
    // Listen to any event asking to disconnect FB
    this._facebookEvents.$disconnectFacebook
      .pipe(
        takeWhile(() => this._subscriptionState),
        mergeMap(() =>
          NotificationsService.confirm(
            'Er du sikker på, at du vil afbryde forbindelse til Facebook?'
          )
        ),
        mergeMap((state) => this._disconnect(state)),
        mergeMap(() => this._userService.getUserDetail())
      )
      .subscribe({
        next: (userDetail) => {
          TokenService.setUserDetail(userDetail);
          this._facebookEvents.facebookConnected(true);
          this.pages = [];
          NotificationsService.notify('Facebook frakoblet', 'info', 'top');
        },
        error: (error) => this._errorHandlerService.handleError(error),
      });
  }

  private _setfbUrl(state: boolean): Observable<string | any> {
    // Check for FB Connection Status
    this.isConnected = TokenService.getUserDetail().facebook.connected;
    this.showLogin = state;
    return this._fbService.getLoginUrl();
  }

  // Receives the Data from the child popup once the Facebook dialog confirmation is successful
  private _receiveFacebookToken(e: MessageEvent) {
    if (e.origin !== environment.mainurl || e.data.source !== 'FB_LOGIN') {
      return;
    }
    this._fbService
      .getPageList(e.data.token)
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (fbResponse: FaceBookPage[]) => {
          // if there is no pages
          // @ts-ignore
          if (fbResponse['data'].length === 0) {
            this.pageResponse = false;
            this.title =
              'Ingen side tilknyttet, vær venlig at oprette en fan side';
          } else {
            this.title = 'Klik på siden for at oprette forbindelse';
            this.pageResponse = true;
            this._loadPageList(fbResponse);
          }
        },
        error: (error) => this._errorHandlerService.handleError(error),
      });
  }

  // Load all the fetched facebook pages
  private _loadPageList(fbResponse: any): void {
    this.pages = fbResponse['data'];
    this.nextList = fbResponse['paging']['next'];
    this.prevList = fbResponse['paging']['previous'];
  }

  // Connect Selected Facebook Page
  private _saveFBDetails(page: FaceBookPage, token: string) {
    return this._fbService.updateFbData({
      connected: 1,
      id: page.id,
      name: page.name,
      profileImage: page.picture.data.url,
      accessToken: token,
    });
  }

  // Disconnect if confirmation is true
  private _disconnect(state: any) {
    if (state) {
      return this._fbService.updateFbData({
        connected: 0,
        id: '',
        name: '',
        profileImage: '',
        accessToken: '',
      });
    }
    return EMPTY;
  }
}
