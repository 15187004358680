import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, switchMap } from 'rxjs';
import { DashboardService, ErrorhandlerService } from 'app/core/services';
import { catchError } from 'rxjs/operators';
import {
  DashBoardDate,
  GeneralReport,
  JatakReport,
  PromoReport
} from 'app/shared/interface';
import * as dayjs from 'dayjs';
import * as weekOfYear from 'dayjs/plugin/weekOfYear';
dayjs.extend(weekOfYear);
@Injectable({
  providedIn: 'root'
})

export class DashboardGeneralLatestReportDate  {
  constructor(
    private _dashboardSevice: DashboardService,
    private _errorHandlerService: ErrorhandlerService
  ) {
  }

  resolve() {
    return this._dashboardSevice.getYearMonth().pipe(
      catchError((err) => this._errorHandlerService.routeResolverError(err))
    );
  }
}

// ***  Resolves General Report data for statistik page ***
@Injectable({
  providedIn: 'root'
})
export class DashboardGeneralResolver  {
  constructor(
    private _dashboardSevice: DashboardService,
    private _errorHandlerService: ErrorhandlerService
  ) {
  }

  resolve(): Observable<GeneralReport> | Promise<any> {
    return this._dashboardSevice.getYearMonth().pipe(
      switchMap((res) => {
        return this._dashboardSevice.getGeneralReport(res.year, res.month).pipe(
          catchError((err) => this._errorHandlerService.routeResolverError(err))
        );
      })
    );
  }
}

// ***  Resolves jatakReport for statistik page ***
@Injectable({
  providedIn: 'root'
})
export class DashboardJatakResolver  {
  constructor(
    private _dashboardSevice: DashboardService,
    private _errorHandlerService: ErrorhandlerService
  ) {
  }

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<JatakReport> | Promise<any> {
    const date = getYearMonthWeek() as DashBoardDate;
    return this._dashboardSevice
      .getJatakReport(date.year, date.week)
      .pipe(
        catchError((err) => this._errorHandlerService.routeResolverError(err))
      );
  }
}

// *** Resolves promoReport for Statistik page ***
@Injectable({
  providedIn: 'root'
})
export class DashboardPromoResolver  {
  constructor(
    private _dashboardSevice: DashboardService,
    private _errorHandlerService: ErrorhandlerService
  ) {
  }

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<PromoReport> | Promise<any> {
    const date = getYearMonthWeek() as DashBoardDate;
    return this._dashboardSevice
      .getPromoReport(date.year, date.week)
      .pipe(
        catchError((err) => this._errorHandlerService.routeResolverError(err))
      );
  }
}

// *** Resolves adminReport for admin's Settings page ***
@Injectable({
  providedIn: 'root'
})
export class DashboardAdminResolver  {
  constructor(
    private _dashboardSevice: DashboardService,
    private _errorHandlerService: ErrorhandlerService
  ) {
  }

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<JatakReport> | Promise<any> {
    const date = getYearMonthWeek() as DashBoardDate;
    return this._dashboardSevice
      .getAdminReport(date.year, date.week)
      .pipe(
        catchError((err) => this._errorHandlerService.routeResolverError(err))
      );
  }
}


// *** Returns year, week, month in provided format ***
export const getYearMonthWeek = (): DashBoardDate => {
  return { year: dayjs().format('YYYY'), week: dayjs().week().toString(), month: dayjs().format('M') };
};

//** checks if its a leap year **//
//returns boolean : true is its leap year
export function isLeapYear(year): boolean {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}


