import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  ImageBlob,
  ImageUrl,
  VideoData,
  VideoUrls,
  MultipleImage,
  EditorOptions
} from 'app/shared/interface';
import { fadeInOut } from 'app/shared/animations';

import {
  ApiService,
  AppEventsService,
  ErrorhandlerService,
  ImageService,
  NotificationsService, TokenService
} from 'app/core/services';
import { takeWhile } from 'rxjs/operators';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { SwiperContainer } from 'swiper/swiper-element';


@Component({
  selector: 'coop-image-handler',
  templateUrl: './image-handler.component.html',
  styleUrls: ['./image-handler.component.scss'],
  animations: [fadeInOut]
})
export class ImageHandlerComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() redMad: boolean = false;
  @Input() imageUrl: ImageUrl;
  @Input() imageArray: MultipleImage[];
  @Input() canEdit: boolean = true;
  @Input() videoUrl: VideoUrls;
  @Input() hasImages: boolean;
  @Input() hasVideo: boolean;
  @Input() isEmail: boolean;
  @Input() isGif: boolean = false;
  @Input() singleCropper: boolean = true;
  @Input() disableUpload: boolean = false;
  @Input() isRectangle: boolean = false;
  @Input() editMode: boolean = false;
  @Input() multipleImage: boolean = false;
  @Input() isVideo: boolean = false;
  @Input() isPostedToFb: boolean = false;
  @Input() forShop: boolean = false;
  @Input() screen: boolean = false;
  @Input() editorOptions: EditorOptions;
  @Input() noBorder: boolean = false;
  @Input() offerType = 'tilbud';
  @Input() source: string = 'coop';
  @Output() image: EventEmitter<ImageUrl> = new EventEmitter<ImageUrl>();
  @Output() imageArr: EventEmitter<MultipleImage[]> = new EventEmitter<
    MultipleImage[]
  >();
  @Output() video: EventEmitter<VideoUrls> = new EventEmitter<VideoUrls>();
  @Output() processing: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() panelType: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() saveOnAllChannel: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @ViewChild('fileinput') fileInput: ElementRef;
  @ViewChild('galleryimageuploadbutton') galleryUpload : ElementRef
  @ViewChild('video') videoContainer: ElementRef;
  imageChangedEvent: any = '';
  croppedImage: ImageBlob;
  cropperStatus: string = 'invisible';
  showCropper: boolean = false;
  showProgressBar: boolean = false;
  progress = 0;
  aspectRatio: number;
  fileFormat: string;
  imageUploaded = false;
  videoUploaded = false;
  imgLoaded = false;
  videoLoaded = false;
  imgSliderArray: MultipleImage[] = [];
  videoSubscription: Subscription;
  showSaveOptions = false;
  showImageLoader = false;
  uploadFailed = false;
  showNotificationMessage: boolean = true;
  showMessage: boolean = true;
  activeSliderImageIndex = 0;
  swiperContainer?: SwiperContainer | null;
  swiperConfig = {
    loop: false,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  };
  showUploadOption: boolean = false

  private _subscriptionState = true;

  constructor(
    private _apiService: ApiService,
    private _appEvents: AppEventsService,
    private _errorHandlerService: ErrorhandlerService
  ) {
  }

//** called when any data-bound property of a directive changes
  //@params[changes] object containing changed properties
  //if changes imageUrl is present => set imageUrl with the current value of changes imageUrl
  //if changes hasImages is true => set hasImages with the current value of changes hasImages
  //                                   set imageUploaded with the current value of changes hasImages
  //if changes hasVideo is true => set videoUploaded with the current value of changes hasVideo
  //if changes isGif is true => set isGif with the current value of changes isGif
  //if changes videoUrl is true => set videoUrl with the current value of changes videoUrl
  //if changes source is present =>  set source with the current value of changes source
  // if changes isVideo is true => set isVideo with the current value of changes isVideo
  //                                 if is isVideo is true => if videoSubscription is true => set showImageLoader false & unsubscribe videoSubscription
  //if changes isVideo is false => resetProgessBar()
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['imageUrl']) {
      this.imageUrl = changes['imageUrl'].currentValue;
    }
    if (changes['hasImages']) {
      this.hasImages = changes['hasImages'].currentValue;
      this.imageUploaded = changes['hasImages'].currentValue;
    }
    if (changes['hasVideo']) {
      this.videoUploaded = changes['hasVideo'].currentValue;
    }
    if (changes['isGif']) {
      this.isGif = changes['isGif'].currentValue;
    }
    if (changes['videoUrl']) {
      this.videoUrl = changes['videoUrl'].currentValue;
    }
    if (changes['source']) {
      this.source = changes['source'].currentValue;
    }
    if (changes['isVideo']) {
      this.isVideo = changes['isVideo'].currentValue;

      if (this.isVideo) {
        this.imgSliderArray = [];
        if (this.videoSubscription) {
          this.showImageLoader = false;
          this.videoSubscription.unsubscribe(); //Disposes the resources held by the subscription
        }
      }
    }
    if (!changes['isVideo']) {
      this._resetProgressBar();
    }
  }

//** destroy component
  ngOnDestroy(): void {
    this._subscriptionState = false;
    this.showImageLoader = false; //represent no images/video are uploaded
    if (this.videoSubscription) {
      this.videoSubscription.unsubscribe(); ////Disposes the resources held by the subscription
    }
  }

//****called after Angular has fully initialized a component's view
  ngAfterViewInit(): void {
    this.sliderSetup();
    this.imgSliderArray.forEach((image: any) => {
      this.addSlide(image);
    });

  }

//** initialize component
  ngOnInit() {
    this._initComp();
  }

  sliderSetup() {
    this.swiperContainer = document.querySelector('swiper-container');
    if (this.swiperContainer) {
      Object.assign(this.swiperContainer, this.swiperConfig);
      this.swiperContainer.addEventListener('swiperslidechange', this.onSlideChange.bind(this));
      this.swiperContainer?.initialize();
      this.swiperContainer?.swiper.removeAllSlides();
    }
  }

  addSlide(image: MultipleImage) {
    // reinitialize if the swiper has been destroyed
    // the swiper enters the state of destroyed if all the slides have been removed (this is the default behaviour of swiper.js)
    if(this.swiperContainer.swiper.destroyed){
      this.sliderSetup()
    }
    this.swiperContainer?.swiper.appendSlide(
      `<swiper-slide class="slide-item"><img class="images" src="${image.url}" alt="" (load)="imageLoaded()"/></swiper-slide>`
    );
    this.swiperContainer?.swiper.updateSlides();
  }

  // to get activesliderimageindex on slider change
  onSlideChange(event: CustomEvent) {
    this.activeSliderImageIndex = event.detail[0].realIndex;
  }


  openFileBrowser(event: Event) {
    this.showUploadOption= false
    if (!this.canEdit) {
      event.stopPropagation();
      return;
    }

    if (this.isPostedToFb && this.editMode) {

      event.stopPropagation();
      return;
    }
    if (this.multipleImage && this.imgSliderArray.length === 5) {
      event.stopPropagation();
      return;
    }
    this.galleryUpload.nativeElement.click();
  }
  openUploadOptions() {
    this.showUploadOption = !this.showUploadOption
  }
  disableImageUpload(e: Event) {
    e.stopPropagation();
  }

  // to show notification
  showNotification(event: any) {
    event.stopPropagation();
    this.showNotificationMessage = !this.showNotificationMessage;
    this.showMessage = !this.showMessage;
    TokenService.setNotificationStatus(this.showMessage);
  }

  showMessageSection(event: any) {
    event.stopPropagation();
    this.showMessage = !this.showMessage;
    this.showNotificationMessage = !this.showNotificationMessage;
    TokenService.setNotificationStatus(this.showMessage);
  }

//**when an alteration to the element's value is committed by the user.
  //@params[event] event object
  //if file type is 'image/jpg' or 'image/png' or 'image/jpeg' or 'image/jpeg' => set showLoader to true & handleImageUpload()
  //if file type is equal to any of these 'video/mp4','video/webm','video/ogg','video/mov','video/m4v' ,'video/avi',
  // 'video/mpg','video/quicktime' => set videoUrl
  //                                  emit video data for parent component

  fileChangeEvent(event: any, fileInput: HTMLInputElement): void {
    const file = event.target.files[0];
    fileInput.value = null;
    event = null;

    if (this.redMad && !this.isVideo && (file.type === 'image/gif')) {
      NotificationsService.notify('Ugyldigt format', 'error', 'top');
      return;
    }

    if (
      !this.isVideo && (
        file.type === 'image/jpg' ||
        file.type === 'image/png' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/gif')
    ) {
      this._appEvents.showLoader(true); //Show Loaded
      this.handleImageUpload(file);
      return;
    }


    if (
      this.isVideo && (
        file.type === 'video/mp4' ||
        file.type === 'video/webm' ||
        file.type === 'video/ogg' ||
        file.type === 'video/mov' ||
        file.type === 'video/m4v' ||
        file.type === 'video/avi' ||
        file.type === 'video/mpg' ||
        file.type === 'video/quicktime'
      )
    ) {
      this.videoUrl = {
        url: '',
        thumbnail: 'assets/images/video.png'
      };
      this.video.emit(this.videoUrl);
      this.showImageLoader = true; // show loaded
      this.handleVideoUpload(file);
      return;
    }
    this.handleInvalidImageandVideoFormat();
  }

  handleInvalidImageandVideoFormat() {
    NotificationsService.notify('Ugyldigt format', 'error', 'top');
    return;
  }

//** handle uploaded image
  //@params[file] => uploaded media file (image)
  //if isVideo true => detectPanelChange()
  //if file type is equal to 'image/gif' => handleGifUpload()
  handleImageUpload(file: any) {
    if (this.isVideo) {
      this.detectPanelChange();
    }
    if (file.type === 'image/gif') {
      this.handleGifUpload(file);
      return;
    }
    this.gifMode(false);// represent gif file isnt getting uploaded
    this.processing.emit(true); // emits data for parent component
    // compress
    document.body.classList.add('cropper-open'); // add class 'cropper-open' to body
    this.imageChangedEvent = file;
    this.showCropper = true; // show cropper
  }

//** handles uploaded video
  //@params[file] => uploaded file (video)
  //if isVideo false => detectPanelChange()
  //set gbSize with the calculated value and if gbSize is greater than 1 => notify 'A maximum of 1 GB of video can be uploaded. Upload video in size less than or equal to 1 GB'
  handleVideoUpload(file: File) {
    if (!this.isVideo) {
      this.detectPanelChange();
    }
    const fileSize = file.size;
    const gbSize = parseFloat((fileSize / Math.pow(1024, 3)).toFixed(2));//control video size i.e video cannot be greater than 1gb
    if (gbSize > 1) {
      this.showImageLoader = false;
      NotificationsService.notify(
        'Maksimalt 1 GB video kan uploades. Upload video i størrelse mindre end eller lig med 1 GB',
        'error',
        'top'
      );
      this.fileInput.nativeElement.value = null; //set input field blank
      this.processing.emit(false);
      return;
    }
    this.fileFormat = ImageService.getFileFormat(file.name);
    this.videoSubscription = this.uploadVideo(file);
  }

// ** handles gif upload
  //@params[file]=> uploaded file (gif)
  //if multipleImage is false => gifUploadLogic()
  //if multipleImage is true, imageArray of 0 index hasImage is true and isGif is false =>
  //  Show Confirmation Message 'Gifs cannot be uploaded next to images. If you decide to upload gif, all the uploaded images
  //  will be deleted. Only 1 gif per Offers are allowed. "Want to upload gif?'
  //if accept is true => removeAllSliderImages() , gifUploadLogic()
  //if accept is false => set showLoader false , set fileInput nativeElement value to null
  handleGifUpload(file: any) {
    if (!this.multipleImage) {
      this.gifUploadLogic(file);
      return;
    }
    if (this.multipleImage && this.imageArray[0].hasImage && !this.isGif) {
      NotificationsService.confirmWithCallback(
        'Gif kan ikke uploades ved siden af billeder. Hvis du beslutter at uploade gif, vil alle de uploadede billeder blive slettet. Kun 1 gif pr. Tilbud er tilladt.\n' +
        'Vil du uploade gif?',
        (accept: any) => {
          if (accept) {
            this.removeAllSliderImages();
            this.gifUploadLogic(file);
          }
          if (!accept) {
            this._appEvents.showLoader(false);
            this.showUploadOption = false;
            this.fileInput.nativeElement.value = null;
            return;
          }
        }
      );
      return;
    }
    this.gifUploadLogic(file);
  }

//** logic for the uploaded gif
  //@params[file] uploaded file
  //gifMode() reflects gif file is being uplaoded
  //set singleCropper to true as gif is regarded as video so only single gif can be uploaded
  gifUploadLogic(file: any) {
    this.gifMode(true);
    this.singleCropper = true;
    this.croppedImage = {
      rectangle: file,
      square: file
    };
    this.fileFormat = ImageService.getFileFormat(file.name);
    this.uploadImage();
  }

//** refers to gif Mode
  //@params[status] boolean value to determine if gif is being uploaded
  gifMode(status: boolean) {
    this.isGif = status;
  }

//**detects panel change
  detectPanelChange() {
    this.panelType.emit(!this.isVideo);// emit data for parent component
  }

// ** detects Slider changes
  detectSliderChanges() {
    // let swiper = this.swiper.swiperRef
    // swiper.loopDestroy();
    // swiper.loopCreate();
  }

//** process image
  //@params[event] event object containing file data
  //if aspectRatio is 1 => croppedImage square is set with event
  //if aspectRatio is 2 => croppedImage rectangle is set with event
  imageProcessed(event: any) {
    this.fileFormat = event.type.split('/')[1];//extracting file format
    if (this.aspectRatio === 1) {
      this.croppedImage.square = event;
    }
    if (this.aspectRatio === 2) {
      this.croppedImage.rectangle = event;
    }
    // if ((this.singleCropper || this.aspectRatio === 2) && this.editMode && !this.multipleImage) {
    //   this.showSaveOptions = true;
    //   return;
    // }
    this.uploadImage();
  }

//**provides image loaded status
  imageLoaded() {
    this.imgLoaded = true; // image is loaded
  }

//** provides video loaded status
  vidLoaded() {
    this.videoLoaded = true;//video is loaded
  }

//** set cropper status
  cropperLoaded() {
    this.cropperStatus = 'visible';
    // set the cropper for rectangle after cropper is loaded
  }

//** close editor
  closeEditor() {
    this._reset(false);
  }

//** uploads image
  //if forShop is true => uploadImageShop() => if ImageUrl is true => onUploadSuccess()
  //subscribe to uploadImage => _onUploadSuccess()
  uploadImage() {
    if (this.forShop) {
      return this._apiService
        .uploadImageShop(
          this.croppedImage.square,
          this.croppedImage.rectangle,
          this.fileFormat
        )
        .pipe(takeWhile(() => this._subscriptionState))
        .subscribe({
          next: (urls: ImageUrl) => this._onUploadSuccess(urls),
          error: (err) =>
            ErrorhandlerService.error('Noget gik galt. Prøv igen', err)
        });
    }
    return this._apiService
      .uploadImage(
        true,
        this.croppedImage.square,
        this.croppedImage.rectangle,
        this.fileFormat
      )
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (urls: ImageUrl) => this._onUploadSuccess(urls),
        error: (err) => this._errorHandlerService.handleError(err)
      });
  }

//** upload video
  //@params[file] uploaded file content
  //subscribe to uploadVideo => use switch for different cases
  uploadVideo(file: any) {
    this.showProgressBar = true; // shows video uploaded percentage
    return this._apiService
      .uploadVideo(file, this.fileFormat)
      .pipe(takeWhile(() => this._subscriptionState))//unsubscribe
      .subscribe({
        next: (res: HttpEvent<any>) => {
          switch (res.type) {
            case HttpEventType.Sent:
              break;
            case HttpEventType.UploadProgress:
              // @ts-ignore
              this.progress = Math.round((res.loaded / res.total) * 100); // calculates progess bar
              break;
            case HttpEventType.Response:
              this._onUploadSuccess(res.body); // successfully uploaded
              break;
          }
        },
        error: (err) => {
          this.hasVideo = this.showImageLoader = this.videoUploaded = false;
          this.uploadFailed = true; // represent upload has been failed
          this._errorHandlerService.handleError(err);
        }
      });
  }

//** upload Image for all channel
  uploadImageForAllChannels() {
    this.saveOnAllChannel.emit(true); // emits value for parent component
    this.uploadImage(); //uploads image
  }


//** remove slider images
  //set imageArray with updated hasImage value
  removeAllSliderImages() {
    this.imageArray = [
      { url: 'assets/images/camera@3x.jpg', hasImage: false },
      { url: 'assets/images/camera@3x.jpg', hasImage: false },
      { url: 'assets/images/camera@3x.jpg', hasImage: false },
      { url: 'assets/images/camera@3x.jpg', hasImage: false }
    ];
    this.imgSliderArray = [];
    this.hasImages = false; // represent image is not present
    this.imageUploaded = false;
    this.imageArr.emit(this.imageArray);//emits data for parent component
    this.detectSliderChanges();

  }

//** logic for removing image
  //@params[event , index] event object and index of image
  //notify with confirmation message 'Are you sure you want to delete this image?'
  //if state is true => deletes image
  imageRemoveLogic(event: any) {
    event.stopPropagation(); //stoping propagation from the image editor
    NotificationsService.confirmWithCallback(
      'Er du sikker på at du ønsker at slette dette billede?',
      (state: any) => {
        if (state) {
          if(this.multipleImage) {
            this.imageArray.splice(this.activeSliderImageIndex, 1);
            this.imageArray.push({
              url: 'assets/images/camera@3x.jpg',
              hasImage: false
            });

            this.imgSliderArray.splice(this.activeSliderImageIndex, 1);
            this.swiperContainer.swiper.removeSlide(this.activeSliderImageIndex);


            this.imageArr.emit(this.imageArray); //emits data for parent component
            if (this.imgSliderArray.length === 0) {
              this.hasImages = false;
              this.imageUploaded = false;
            }
          } else {
            this.imageUrl.largeImage = null
            this.hasImages = this.imageUploaded = false
            this.image.emit(this.imageUrl);
          }

        }
      }
    );
  }


//** prevents further propagation of the current event
  stopPropagation($event: any): void {
    // stoping propagation from the image editor
    if ($event.target.type === 'submit') $event.preventDefault();
  }

//** set values
  //if isRectangle or isEmail is present => set aspectRatio 2
  //if multipleImages and imageArray[0] hasImage are true => setupForMultipleImages()
  private _initComp() {
    this.aspectRatio = 1;
    if (this.isRectangle || this.isEmail) {
      this.aspectRatio = 2;
    }
    this.croppedImage = {
      square: null,
      rectangle: null
    };
    if (this.multipleImage && this.imageArray[0].hasImage) {
      this._setupForMultipleImage();
    }
    this.showMessage = TokenService.getNotificationStatus() === null ? true : TokenService.getNotificationStatus();
  }

//** set up for multiple image upload
  //loop through imageArray => if img hasImage is true then push img to imgSliderArray
  private _setupForMultipleImage() {
    for (const img of this.imageArray) {
      if (img.hasImage) {
        this.imgSliderArray.push(img);
      }
    }
  }

//**on video upload success
  //@params[vidUrl] => contains data on successfully uploaded video file
  //set videoUrl
  //notify with message 'Video has been uploaded'
  onVideoUploadSuccess(vidUrl: VideoData) {
    this.videoUploaded = true;
    this.hasVideo = true;
    this.showImageLoader = false;
    this.videoUrl = {
      thumbnail: vidUrl.thumbnail,
      url: vidUrl.video_url,
      orientation: vidUrl.orientation
    };
    this.setUpVideoFile({ url: vidUrl.video_url, thumbnail: vidUrl.thumbnail });
    NotificationsService.notify('Video er uploadet', 'success', 'top');
    this._resetVideo();
  }

//** set up video file
  //@params[vidUrl] contains uploaded video data
  //setTimeout() sets a timer  which executes attachSourceToVideo() once the timer expires at 1000ms.
  setUpVideoFile(vidUrl: VideoUrls) {
    setTimeout(() => {
      ImageHandlerComponent._attachSourceToVideo(vidUrl.url, this.videoContainer);
    }, 1000);
  }

  //attach source to video
  //@params[url, videoElem] => uploaded video url , videoElem html data
  //if video is present and video has child nodes => set video innerHtml to null
  //create element 'source' with two attributes and append it to video
  private static _attachSourceToVideo(url: string, videoElem: ElementRef): void {
    const video: HTMLVideoElement = videoElem.nativeElement;
    if (video && video.hasChildNodes()) {
      video.innerHTML = '';
    }
    const source = document.createElement('source');
    source.setAttribute('src', url);
    source.setAttribute('type', 'video/mp4');
    video.appendChild(source);
    video.load();
  }

//** on image upload success
  //@params[url] contains successful data on image upload
  //if isRectangle is true => set imageUrl
  //else => if multipleImage is true =>_patchForMultipleImage()
  //        if multipleImage is false => set imageUrl with provided values
  //notify with  message 'Image has been uploaded'
  //if multipleImage is true =>setTimeout() sets a timer  which executes showLoader() once the timer expires at 2000ms
  onImageUploadSuccess(url: ImageUrl) {

    this.showCropper = false;
    this.imageUploaded = true;

    if (this.isRectangle) {
      this.imageUrl = {
        largeImage: url.largeImage,
        thumbnail: url.largeImage
      };
    } else {
      if (this.multipleImage) {
        this._patchForMultipleImage(url);
      }
      if (!this.multipleImage) {
        this.imageUrl = {
          largeImage: url.largeImage,
          thumbnail: url.thumbnail
        };
      }
    }
    this.croppedImage = {
      rectangle: null,
      square: null
    };

    NotificationsService.notify('Billede er uploadet', 'success', 'top');
    this._reset(true);
    if (this.multipleImage) {
      this._appEvents.showLoader(true);
      setTimeout(() => {
        this.showUploadOption = false;
        this._appEvents.showLoader(false);
      }, 2000);
    }
  }

//** on upload success
  //@params[url] contains data on successful upload
  // if isVideo is true => onVideoUploadSuccess()
  //if isVideo is false => onImageUploadSuccess()
  private _onUploadSuccess(url: any) {
    if (this.isVideo) {
      this.onVideoUploadSuccess(url);
    }
    if (!this.isVideo) {
      this.onImageUploadSuccess(url);
      this.showUploadOption = false;
    }
  }

//**patch for multiple images
  //@params[url] contains data on successful image upload
  //loop through imageArray => if img hasImage is true => continue
  //                            else set values for img url ,img hasImage to true and push img to imgSliderArray
  private _patchForMultipleImage(url: ImageUrl) {
    for (const img of this.imageArray) {
      if (img.hasImage) {
        continue;
      }
      img.url = url.largeImage;
      img.hasImage = true;
      this.imgSliderArray.push(img);
      // Wait swiper container to be available
      setTimeout(() => {
        if (!this.swiperContainer) {
          this.sliderSetup();
        }
        this.addSlide(img);
        this.swiperContainer?.swiper?.slideTo(this.imgSliderArray.length - 1);
      }, 10);
      break;
    }

  }

//** reset values
  //@params[state] boolean value
  //if state is true => if multipleImage is true => emit imageArray for parent component
  //                     else emit imageUrl for parent component
  private _reset(state: boolean) {
    // this.fileInput.nativeElement.value = null;
    this.cropperStatus = 'invisible'; //set the cropper for rectangle after cropper is loaded
    this.showCropper = false;//hide cropper option
    document.body.classList.remove('cropper-open');
    // this.aspectRatio = 1;
    this.fileFormat = '';
    this.processing.emit(false);
    if (state) {
      if (this.multipleImage) {
        this.imageArr.emit(this.imageArray);
      }
      if (!this.multipleImage) {
        this.image.emit(this.imageUrl);
      }
    }
  }

//** reset upload for video
  private _resetVideo() {
    this.fileInput.nativeElement.value = null;
    this.fileFormat = '';
    this._resetProgressBar(); //reset progress bar
    this.processing.emit(false);
    this.video.emit(this.videoUrl);
  }

//reset progess bar
  private _resetProgressBar(): void {
    this.progress = 0; //set progress to initial state
  }
}
