import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'charCount',
})
//** provide text length
export class CharacterCount implements PipeTransform {
  transform(text: string): number {
    return text.length;
  }
}
