<section
  class="app-page list-component news-list"
  [@parentAnimate]="showParent"
>
  <div class="fixed-header">
    <coop-pageheader
      [pageTitle]="pageTitle"
      [isChild]="true"
      [routeTo]="parentUrl"
    ></coop-pageheader>
    <div class="fixed-header-content">
      <ul class="tabs three-col">
        <li
          class="tab-first"
          (click)="tab('1', $event)"
          [class.active]="activeTab == '1'"
        >
          Aktive
        </li>
        <li
          class="tab-second"
          (click)="tab('2', $event)"
          [class.active]="activeTab == '2'"
        >
          Fremtidige
        </li>
        <li
          class="tab-third"
          (click)="tab('0', $event)"
          [class.active]="activeTab == '0'"
        >
          Arkiv
        </li>
        <hr />
      </ul>
    </div>
  </div>
  <div class="page-container">
    <div class="item-list">
      <ng-container *ngFor="let news of newsList">
        <a
          class="item"
          routerLink="/bestyrelse/indlaeg/nyheder/redigere/{{ news.id }}"
          [queryParams]="{ origin: 'list' }"
        >
          <figure class="item-image">
            <img src="{{ news.thumbImageUrl }}" alt="" />
          </figure>
          <div class="item-detail">
            <p class="item-date">
              {{ news.publishDate | dateformat : true }} -
              {{ news.expirationDate | dateformat : true }}
            </p>
            <h3 class="item-title">
              {{
                news.title.length > 50
                  ? (news.title | slice : 0 : 50) + '...'
                  : news.title
              }}
            </h3>
            <p class="item-desc">
              {{
                news.longDescription.length > 150
                  ? (news.longDescription | slice : 0 : 150) + '...'
                  : news.longDescription
              }}
            </p>
          </div>
          <div class="link-arrow">
            <i class="icon icon-arrow-right"></i>
          </div>
        </a>
      </ng-container>
      <h5 class="empty-message" *ngIf="newsList.length === 0">
        Beklager, ingen nyheder tilgængelige
      </h5>
    </div>
    <a
      class="login-btn btn-white-login btn-medium"
      (click)="loadMore()"
      *ngIf="!(newsList.length < page * limit)"
      >Indlæs mere..</a
    >
  </div>
</section>
<router-outlet></router-outlet>
