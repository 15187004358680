<div
  class="app-page assembly-poll-create-edit special-poll-create-edit page-with-fixed-header"
>
  <div class="fixed-header">
    <coop-pageheader
      [pageTitle]="pageTitle"
      [isChild]="true"
      [routeTo]="previousUrl"
    ></coop-pageheader>
  </div>
  <div class="page-container">
    <div class="form-container styled-two">
      <form [formGroup]="assemblyPollForm" (submit)="save()">
        <div class="wrapper">
          <div class="form-group" controlErrorContainer>
            <coop-image-handler
              [imageUrl]="imageUrl"
              [hasImages]="hasImage"
              [singleCropper]="true"
              [isRectangle]="true"
              [disableUpload]="pollStarted && hasVoted"
              (processing)="imageProcessing($event)"
              (image)="getImagesUrl($event)"
            ></coop-image-handler>
            <input
              formControlName="imageUrl"
              [customErrors]="validationMessages['imageUrl']"
              hidden
            />
          </div>
          <div class="form-group" controlErrorContainer>
            <label for="poll-title">Overskrift</label>
            <input
              id="poll-title"
              type="text"
              (focusin)="onSelect()"
              (focusout)="onSelect()"
              formControlName="title" [customErrors]="validationMessages['title']" />
            <div *ngIf="visible" class="remaining"> Brugte
              tegn: {{ assemblyPollForm.get('title').value.length }} / 99
            </div>
          </div>
          <div class="row form-group" controlErrorContainer>
            <label for="poll-text">Indhold</label>
            <textarea
              name=""
              id="poll-text"
              formControlName="description"
              [customErrors]="validationMessages['description']"
            ></textarea>
          </div>
          <div formArrayName="answers" class="answers-list">
            <h2 class="form-subtitle">Valgmulighed</h2>
            <div
              *ngFor="
                let answer of assemblyPollForm.controls['answers']['controls'];
                let i = index
              "
              class="answer"
            >
              <div class="form-group" [formGroupName]="i" controlErrorContainer>
                <i
                  class="icon icon-clos-circle remove-answer"
                  *ngIf="
                    assemblyPollForm.controls['answers']['controls'].length >
                      2 && !hasVoted
                  "
                  (click)="removeAnswer(i)"
                ></i>
                <label for="answer">Valgmulighed {{ candidateList[i] }}</label>
                <input
                  type="text"
                  id="answer"
                  formControlName="answer"
                  [class.w-100]="hasVoted"
                  [customErrors]="validationMessages['answer']"
                />
              </div>
            </div>
            <button
              *ngIf="answerCount <= 10"
              (click)="addAnswer('')"
              class="login-btn btn-black-login"
              type="button"
              [disabled]="hasVoted"
            >
              Tilføj flere valgmuligheder
            </button>
          </div>
          <div class="row entries">
            <h2 class="form-subtitle">Antal krydser pr. stemmeberettiget</h2>
            <div class="form-group" controlErrorContainer>
              <label for="election-entries"></label>
              <input
                type="text"
                mbsc-select
                [mbscOptions]="electionEntriesPickerSettings"
                formControlName="electionEntries"
                id="election-entries"
              />
            </div>
          </div>
          <div class="row special-poll-date-time">
            <h2 class="form-subtitle">Afstemningens varighed</h2>
            <div class="form-control-desc">
              <p class="text-align-center">
                Vi anbefaler at medlemmerne har mulighed for at stemme i minimum
                24 timer.
              </p>
            </div>
            <div class="col-two form-group" controlErrorContainer>
              <div class="date-time-picker" >
                <label for="startDate">Starttidspunkt</label>
                <input
                  id="startDate"
                  mbsc-datepicker
                  [mbscOptions]="datePickerSettings"
                  formControlName="startDate"

                  type="text"
                  [customErrors]="validationMessages['startDate']"
                  [checkParent]="true"
                />
              </div>
            </div>
            <div class="col-two form-group" controlErrorContainer>
              <div class="date-time-picker">
                <label for="expiry">Udløbstidspunkt</label>
                <input
                  id="expiry"

                  formControlName="endDate"
                  mbsc-datepicker
                  [mbscOptions]="datePickerSettings"
                  type="text"
                  [customErrors]="validationMessages['endDate']"
                  [checkParent]="true"
                />
              </div>
            </div>
            <div class="form-control-desc">
              <p class="text-align-center">
                Afstemningen vil være synlig i Coop app'en i ovenstående
                periode. Placeret under "Din butik" og i nyhedsfeedet. Det er
                kun muligt at stemme, hvis man er tilmeldt generalforsamlingen.
              </p>
            </div>
          </div>
          <div class="row form-group">
            <h2 class="form-subtitle">Stemmeberettigede</h2>
            <div class="form-control-desc">
              <p class="text-align-center mb-10">
                Kun medlemmer der har tilmeldt sig den digitale
                generalforsamling kan stemme i medlems appen.
              </p>
            </div>
          </div>
          <div class="row form-group" controlErrorContainer>
            <h2 class="form-subtitle">Krav til stemmeret</h2>
            <div class="form-control-desc">
              <p class="text-align-center">
                Beskriv herunder de krav, som medlemmet skal bekræfte for at
                have stemmeret og kunne afgive stemme i Coop appen
              </p>
            </div>
            <label for="poll-requirements"></label>
            <textarea
              name=""
              id="poll-requirements"
              formControlName="requirements"
              [customErrors]="validationMessages['requirements']"
            ></textarea>
          </div>
          <div class="row form-group special-poll-votes" *ngIf="pollStarted">
            <h2 class="form-subtitle">Stemmer</h2>
            <p class="download-info">
              Resultatet vil kunne downloades efter endt afstemning
            </p>
            <div class="poll-options">
              <div class="poll-option votes-info">
                <div class="poll-option-text votes-number-text">
                  <h3>Mulige stemmer</h3>
                </div>
                <div class="poll-option-number">
                  <p>{{ assemblyPoll.possibleVotes }}</p>
                </div>
              </div>
              <div class="poll-option votes-info">
                <div class="poll-option-text votes-number-text">
                  <h3>Antal stemmer afgivet</h3>
                </div>
                <div class="poll-option-number">
                  <p>{{ assemblyPoll.totalVotes }}</p>
                </div>
              </div>
            </div>
            <button
              class="login-btn btn-black-login download-votes-btn"
              type="button"
              (click)="downloadVotesAndUserList()"

              [disabled]=" !this.canEdit || !showResults"
            >
              {{
                isActive
                  ? 'Download detaljeret deltagerliste'
                  : 'Download digitalt afstemningsresultat'
              }}
            </button>
          </div>
          <div class="special-poll-preview" *ngIf="showResults">
            <div class="special-poll-preview-detail">
              <div class="wrapper">
                <h2 class="special-poll-preview-title text-align-center">
                  Digitalt resultat
                </h2>
                <p class="special-poll-preview-desc text-align-center">
                  <strong>HUSK</strong> at nedenstående resultat er uden
                  eventuelle manuelle stemmer.
                </p>
              </div>
              <div class="special-poll-results">
                <ul>
                  <li *ngFor="let answer of assemblyPoll.answers">
                    <span
                      class="bg {{ answer.shade }}"
                      [style.width]="answer.percentage + '%'"
                    ></span>
                    <div class="row">
                      <span class="answer">{{ answer.answer }}</span>
                      <span class="vote-count">{{ answer.votes }}</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div id="overlay" class="overlay-msg" *ngIf="showMsg">
            <div class="msg-content">
              <h4 class="msg-title">!!!VÆR OPMÆRKSOM!!!</h4>
              <div class="msg-text">
                <p class="msg-par-first">
                  Du er ved at hente en liste med persondata. Du skal derfor
                  være opmærksom på:
                </p>
                <ul>
                  <li>
                    Del kun oplysningerne med relevante personer fx bestyrelsen
                    og uddeler/VHC.
                  </li>
                  <li>Slet filen efter brug.</li>
                  <li>
                    Undgå at printe oplysningerne. Hvis du gør det, så skal de
                    makuleres (smides sikkert ud) efter mødet.
                  </li>
                </ul>
                <p class="msg-par-last">
                  Data fra afsluttede arrangementer slettes automatisk i
                  QuickCoop efter 1 måned. Bekræft, at du er indforstået med
                  dette, ved at trykke " Forstået". Derefter kan du downloade
                  filen.
                </p>
              </div>

              <div class="row">
                <a
                  href="{{ downloadUrl }}"
                  class="login-btn btn-black-login"
                  download
                  (click)="showOverlayMsg()"
                >Forstået</a
                >
              </div>
              <div class="row">
                <button class="login-btn btn-white-login" (click)="showOverlayMsg()">
                  Annuller
                </button>
              </div>
            </div>
          </div>
          <div class="row form-submit-field" *ngIf="!editMode">
            <button type="submit" class="login-btn btn-black-login">Opret afstemning</button>
          </div>
          <div
            class="row form-submit-field"
            *ngIf="editMode && isActive && !hasVoted"
          >
            <button
              type="submit"
              class="login-btn btn-black-login"
              [disabled]="memberIdNotMatched"
            >
              Gem ændringer
            </button>
          </div>
          <div class="row" *ngIf="!editMode">
            <a
              (click)="showDeleteBox = true"
              class="login-btn btn-white-login "
            >Annuller</a
            >
          </div>
          <div class="row" *ngIf="editMode">
            <button
              type="button"
              *ngIf="
                !pollStarted ||
                pollEnded ||
                !isActive ||
                (pollStarted && !hasVoted)
              "
              (click)="showDeleteBox = true"
              class="login-btn btn-white-login "
              [disabled]="memberIdNotMatched"
            >
              Slet
            </button>
            <ng-container
              *ngIf="pollStarted && isActive && !pollEnded && hasVoted"
            >
              <p class="delete-info">
                Det er kun muligt at redigere en afstemning, indtil første stemme er afgivet. Sker der er fejl kan
                afstemningen dog slettes. Opret derefter en ny afstemning.
              </p>
              <button
                type="button"
                (click)="showDeleteBox = true"
                class="login-btn btn-white-login "
                [disabled]="memberIdNotMatched"
              >
                Slet afstemning
              </button>
            </ng-container>
          </div>
          <!--          <div class="row" *ngIf="editMode && hasVoted">-->
          <!--            <a (click)="back()" class="btn btn-border-red btn-delete"-->
          <!--              >Tilbage</a-->
          <!--            >-->
          <!--          </div>-->
        </div>
      </form>
    </div>
    <!-- popup window -->
    <div class="popup-window" *ngIf="showDeleteBox">
      <div class="select-box-container">
        <div class="select-box-content">
          <div class="select-box-title">
            <h4>Slet afstemning</h4>
          </div>
          <div class="select-box-info">
            <p>
              Du er i gang med at slette en afstemning. Ønsker du ikke at gøre
              det, skal du trykke på "Annuller".
            </p>
          </div>
        </div>
        <div class="select-btn">
          <div class="row">
            <button
              class="login-btn btn-black-login"
              (click)="deleteAssemblyPoll(assemblyPollForm.value.id)"
            >
              Slet
            </button>
          </div>
          <div class="row">
            <button class="login-btn btn-white-login" (click)="showDeleteBox = false">
              Annuller
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- popup window -->
    <div class="popup-window" *ngIf="showXinDescError">
      <div class="select-box-container">
        <div class="select-box-content">
          <div class="select-box-title">
            <h4>Du mangler at forholde dig til feltet ”Indhold”</h4>
          </div>
          <div class="select-box-info">
            <p>
              Du mangler at tilrette følgende felt [ ] under ”Indhold”. Husk at
              læse hele teksten igennem, så du er sikker på den er tilrettet.
            </p>
          </div>
        </div>
        <div class="select-btn">
          <div class="row">
            <button class="login-btn btn-black-login" (click)="showXinDescError = false">
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- popup window -->
    <div class="popup-window" *ngIf="showDurationPopup">
      <div class="select-box-container">
        <div class="select-box-content">
          <div class="select-box-title">
            <h4>Notifikation på varighed:</h4>
          </div>
          <div class="select-box-info">
            <p>Afstemningen er planlagt til mere 48 timer.</p>
            <p>Er du sikker? Ellers tilret tiden inden oprettelse.</p>
          </div>
        </div>
        <div class="select-btn">
          <div class="row">
            <button class="login-btn btn-black-login" (click)="showDurationPopup = false">
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
