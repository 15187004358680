import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { catchError, map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { CreateOption } from '../../shared/interface';
import { CreateOptionDataService } from './createoptiondata.service';
import { ErrorhandlerService } from './errorhandler.service';

// ***  Type, Channels and Modes are returned from this Resolver ***
// It runs after channels selection in CoopCreateOptions
// Route is success only after passing current selection options
@Injectable({
  providedIn: 'root',
})
export class CreateOptionResolver  {
  constructor(
    private _createOption: CreateOptionDataService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this._getCreateOptionSettings().pipe(
      catchError((err) => this._errorHandlerService.routeResolverError(err))
    );
  }
  private _getCreateOptionSettings(): Observable<any> {
    return this._createOption.currentSetting.pipe(
      take(1),
      map((settings) => settings)
    );
  }
}
