import {Injectable} from "@angular/core";
import {ApiService, TokenService} from 'app/core/services';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class QuicktogoService {
  constructor(private _apiService: ApiService) {
  }


  createOrder(params) {
    let url = 'bags'
    return this._apiService.quicktogopost(url, params)
  }


  updateOrder(params, id) {
    let url = `bags/${id}`
    return this._apiService.put(url, params)
  }

  getOfferById(id) {
    let url = `bags/${id}`
    return this._apiService.get(url).pipe(map((res) => res['data']))
  }

  getCategoryList() {
    let url = 'bags/categories'
    return this._apiService.get(url).pipe(map((res) => res['data']))
  }

  deleteOrder(id) {
    let url = `bags/${id}`
    return this._apiService.del(url)
  }


  getOfferList(startDate?: any, endDate?: any, tab?: any,page? : number) {
    let status;
    if (tab === 'active') {
      status = 1
    } else {
      status = 0
    }
    let url = `bags?start_date=${startDate}&end_date=${endDate}&status=${status}&page=${page}`
    return this._apiService.get(url)
  }


  updateBag(bagId: any, params: object) {
    let url = `bags/${bagId}/publish/update`

    return this._apiService.post(url, params)
  }

  getBagsOrder(id) {
    let url = `bags/publish/${id}/orders`

    return this._apiService.get(url).pipe(map((res) => res['data']))
  }

  deleteBagsOrder(publishId: any, orderId: any) {
    let url = `bags/publish/${publishId}/orders/${orderId}/reject`
    return this._apiService.post(url,{}).pipe(map((res) => res['data']))
  }


}
