import { Injectable } from "@angular/core";
import { HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ApiService, TokenService } from "app/core/services";
import { TemplateListData } from "app/shared/interface";
import { Polls } from "app/shared/interface/calendar.interface";
import {
  Poll,
  PollList,
  SpecialEvent,
  SpecialPoll,
  Template
} from "app/features/poll";

@Injectable({
  providedIn: "root"
})
export class PollService {
  constructor(private _apiService: ApiService) {
  }

  getPollList(
    startDate?: any,
    endDate?: any,
    pagenumber?: number
  ): Observable<PollList[]> {
    pagenumber = !pagenumber ? 1 : pagenumber;
    let url = `polls?userType=${TokenService.getUserType()}&filter=DESC`;
    if (startDate) {
      url = url + `&StartDate=${startDate}&EndDate=${endDate}`;
    } else {
      url = url + `&page=${pagenumber}&limit=10`;
    }
    return this._apiService.get(url).pipe(map((res) => res["polls"]));
  }

  getPolls(status, page) {
    const url = `polls?userType=${TokenService.getUserType()}&filter=DESC&status=${status}&page=${page}&limit=10`;
    return this._apiService.get(url).pipe(map((res) => res["polls"]));
  }

  getPoll(id: number): Observable<Poll> {
    return this._apiService.get(`polls/${id}`).pipe(map((res) => res["poll"]));
  }

  deletePoll(id: string) {
    return this._apiService.post(`polls/${id}/destroy`, {});
  }

  getPollTemplates(page: number,searchQuery?: string): Observable<TemplateListData> {
    console.log(searchQuery)
    let url = `poll-templates?page=${page}`;
    url = searchQuery ? `${url}&search=${searchQuery}` : url;
    return this._apiService
      .get(url);
  }

  getTemplate(id: string): Observable<Template> {
    return this._apiService
      .get(`poll-templates/${id}`)
      .pipe(map((res) => res["template"]));
  }

  savePoll(params: Poll, edit: boolean, noVotes: boolean): Observable<any> {
    params.userType = TokenService.getUserType();
    if (!noVotes) {
      return this._apiService.post(
        `polls/${params.id}/date`,
        PollService._createFormData(params)
      );
    }
    if (edit) {
      return this._apiService.post(
        `polls/${params.id}/update`,
        PollService._createFormData(params)
      );
    }
    return this._apiService.post(`polls`, PollService._createFormData(params));
  }

  getSpecialEvents(): Observable<SpecialEvent[]> {
    const url = `special-events/all`;
    return this._apiService.get(url).pipe(map((res) => res["events"]));
  }

  getSpecialPolls(startDate?: any, endDate?: any): Observable<Polls[]> {
    let url = "special-polls";
    if (startDate) {
      url = url + `?startDate=${startDate}&endDate=${endDate}`;
    }
    return this._apiService.get(url).pipe(map((res) => res["polls"]));
  }

  getSpecialPoll(id: number): Observable<SpecialPoll> {
    return this._apiService
      .get(`special-polls/${id}`)
      .pipe(map((res) => res["poll"]));
  }

  saveSpecialPoll(params: SpecialPoll, edit: boolean): Observable<object> {
    if (edit) {
      return this._apiService.post(
        `special-polls/${params.id}/update`,
        PollService._createFormData(params)
      );
    }
    return this._apiService.post(
      "special-polls",
      PollService._createFormData(params)
    );
  }

  checkCreateSpecialPoll(): Observable<object> {
    return this._apiService.get("is-allowed/special-poll");
  }

  deleteSpecialPoll(id: string, type: string) {
    if (type === "delete") {
      return this._apiService.post(`special-polls/${id}/destroy`, {});
    }
    return this._apiService.post(`special-polls/${id}/stop`, {});
  }

  getSpecialVotesOrUsersList(id: string): Observable<string> {
    return this._apiService.get(`special-polls/${id}/download`, {
      responseType: "blob"
    });
  }

  getAssemblyEvents(): Observable<SpecialEvent[]> {
    const url = "assembly-events/all";
    return this._apiService.get(url).pipe(map((res) => res["events"]));
  }

  getAssemblyPolls(startDate?: any, endDate?: any): Observable<Polls[]> {
    let url = "assembly-polls";
    if (startDate) {
      url = url + `?startDate=${startDate}&endDate=${endDate}`;
    }
    return this._apiService.get(url).pipe(map((res) => res["polls"]));
  }

  getAssemblyPoll(id: number): Observable<SpecialPoll> {
    return this._apiService
      .get(`assembly-polls/${id}`)
      .pipe(map((res) => res["poll"]));
  }

  saveAssemblyPoll(params: SpecialPoll, edit: boolean): Observable<object> {
    params.memberId = TokenService.getMemberId();
    if (edit) {
      return this._apiService.post(
        `assembly-polls/${params.id}/update`,
        PollService._createFormData(params)
      );
    }
    return this._apiService.post(
      "assembly-polls",
      PollService._createFormData(params)
    );
  }

  checkCreateAssemblyPoll(): Observable<object> {
    let url = "is-allowed/assembly-poll";
    if (TokenService.getMemberId()) {
      url += `?memberId=${TokenService.getMemberId()}`;
    }
    return this._apiService.get(url);
  }

  deleteAssemblyPoll(id: string, type: string) {
    if (type === "delete") {
      return this._apiService.post(`assembly-polls/${id}/destroy`, {});
    }
    return this._apiService.post(`assembly-polls/${id}/stop`, {});
  }

  getAssemblyVotesOrUsersList(id: string): Observable<string> {
    return this._apiService.get(`assembly-polls/${id}/download`, {
      responseType: "blob"
    });
  }

  private static _createFormData(params: Poll | SpecialPoll): HttpParams {
    // Create object keys from Answers array and add them to params
    // remove Answers array
    for (const key in params) {
      if (params[key] instanceof Array) {
        for (let i = 0; i < params[key].length; i++) {
          params[`${key}[${i}]`] = params[key][i]["answer"];
        }
      }
    }
    delete params["answers"];
    return ApiService.CreateFormData(params);
  }
}
