import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Observable, throwError } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

import {
  ErrorhandlerService,
  NotificationsService,
  SortService,
  TokenService,
} from 'app/core/services';
import { animateParent, slideAnimation } from 'app/shared/animations';
import { PollList, PollService } from 'app/features/poll';

@Component({
  selector: 'coop-polllist',
  templateUrl: './polllist.component.html',
  styleUrls: ['./polllist.component.scss'],
  animations: [animateParent, slideAnimation],
})
export class PolllistComponent implements OnInit, OnDestroy {
  pollList: PollList[];
  allPolls: PollList[];
  pageTitle: string = 'Afstemninger';
  activeTab: string = '1';
  showParent: string = 'visible';
  currentUrl: string;
  parentUrl: string;
  page: number = 1;
  limit: number = 10;
  private _subscriptionState: boolean = true;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _title: Title,
    private _sortService: SortService,
    private _pollService: PollService,
    private _router: Router,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  ngOnInit() {
    this._initComp();
    this.setPreviousUrl();
  }

  ngOnDestroy(): void {
    this._subscriptionState = false;
  }

  setPreviousUrl() {
    const userType = TokenService.getUserType();
    if (userType === '1') {
      this.parentUrl = '/butikker/indstillinger';
    }
    if (userType === '2') {
      this.parentUrl = '/bestyrelse/indstillinger';
    }
  }

  setPollEditUrl(pollType: string, pollId: string) {
    if (pollType === 'normal') {
      return this.currentUrl + '/' + 'redigere' + '/' + pollId;
    }
    if (pollType === 'special') {
      return (
        this.currentUrl + '/' + 'special' + '/' + 'redigere' + '/' + pollId
      );
    }
    if (pollType === 'assembly') {
      return (
        this.currentUrl +
        '/' +
        'forsamling-poll' +
        '/' +
        'redigere' +
        '/' +
        pollId
      );
    }
  }

  tab(event: MouseEvent, value: string) {
    if (event && this.activeTab !== value) {
      this.page = 1;
      this._getall(true, value)
        .pipe(takeWhile(() => this._subscriptionState))
        .subscribe({
          next: (pollList) => this._updateList(pollList, value),
          error: (err) => this._errorHandlerService.handleError(err),
        });
    }
  }

  loadMore() {
    this.page++;
    this._getall(true, this.activeTab)
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (posts) => {
          if (posts.length === 0) {
            NotificationsService.notify(
              'Ingen flere afstemninger',
              'error',
              'top'
            );
          } else {
            this.pollList = this.allPolls.concat(posts);
            this.allPolls = this.pollList;
          }
        },
        error: (err) => this._errorHandlerService.handleError(err),
      });
  }

  private _initComp() {
    this._title.setTitle('Afstemninger - QuickCoop');
    this.pollList = this.allPolls = this._activatedRoute.snapshot.data['polls'];
    this._checkCheckChildPage();
    this.currentUrl = this._router.url;
  }

  private _updateList(polls, activeTab) {
    this.pollList = this.allPolls = polls;
    this.activeTab = activeTab;
  }

  private _getall(state, activeTab): Observable<any> {
    if (state) {
      return this._pollService.getPolls(activeTab, this.page);
    }
    return throwError(() => 'Error');
  }

  // Check if the route is child route or parent route
  // if child route set isParentPage as false
  private _checkCheckChildPage(): void {
    this.showParent = this._activatedRoute.children.length
      ? 'invisible'
      : 'visible';
  }
}
