<div class="graphs " >

  <div class="graph-title" >
    Omsætning
  </div>

  <div class="graph-container">
    <canvas #lineCanvas style="position: relative; height:50vh; width:40vw"></canvas>
  </div>
</div>

