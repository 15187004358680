import {Component, OnInit} from '@angular/core';
import {DashboardService, ErrorhandlerService} from "../../../core/services";
import * as moment from "moment/moment";
import {BagsReport} from "../../interface";

@Component({
  selector: 'app-quicktogo-order',
  templateUrl: './quicktogo-order.component.html',
  styleUrls: ['./quicktogo-order.component.scss'],
})
export class QuicktogoOrderComponent implements OnInit {
  showList: boolean = false
  showFoodOverviewList: boolean = false
  selectedCategoryId: any = 1
  selectedFoodCategoryId = 1
  title: string = ''
  foodCategoryTitle: string = ''
  currentYear: number
  currentType: string;
  bagsReport: BagsReport
  turnOverGraph: any
  allCategory = {
    id: 7,
    name: "Se alt",
    image: "https://example.com/image.jpg",
    title: "New Category Title",
    description: "Description of the new category",
    placeholder: null
  };
  list = [
  ]

  foodOverviewList = [
    {
      "id": 1,
      "name": "Udvikling om dagen",
      "value": 'day'
    },
    {
      "id": 2,
      "name": "Udvikling om ugen",
      "value": "week"
    },
    {
      "id": 3,
      "name": "Udvikling om måneden",
      "value": "month"
    },
    {
      "id": 4,
      "name": "Udvikling om året",
      "value": "year"
    }
  ]

  constructor(
    private _service: DashboardService,
    private _errorService: ErrorhandlerService
  ) {
  }


  ngOnInit() {
    this.currentYear = moment().year()
    this.setupCategoryDetails()
    this.getBagsReport()
    this.getTurnOverReport()
  }

  setupCategoryDetails() {
    this._service.getCategoryList().subscribe({
      next: (res) => {
        this.list = res
        this.list.push(this.allCategory)
        this.list.forEach((item) => {
          if (item.id == this.selectedCategoryId) {
            this.title = item.name
          }
        })
      }
    })

    this.foodOverviewList.forEach((item) => {
      if (item.id == this.selectedFoodCategoryId) {
        this.currentType = item.value
        this.foodCategoryTitle = item.name
      }
    })
  }

  getBagsReport() {
    this._service.getBagsReport(this.currentType, this.selectedCategoryId).subscribe({
      next: (res) => {
        this.bagsReport = res
      },
      error: (err) => {
        this.handleError(err)
      }
    })
  }

  getTurnOverReport() {
    this._service.getTurnOverReport().subscribe({
      next: (res) => {
        this.turnOverGraph = res
      },
      error: (err) => {
        this.handleError(err)
      }
    })
  }


  toggleList() {
    this.showList = !this.showList
  }

  toggleFoodOverview() {
    this.showFoodOverviewList = !this.showFoodOverviewList
  }

  onCategorySelect(list) {
    // if(list.name === 'Se alt'){
    //   this.selectedCategoryId = ''
    // } else {
    //
    // }
    this.selectedCategoryId = list.id
    this.showList = false
    this.getBagsReport()
    this.list.forEach((item) => {
      if (item.id == this.selectedCategoryId) {
        this.title = item.name
      }
    })
  }

  onFoodCategorySelect(list) {
    this.selectedFoodCategoryId = list.id
    this.showFoodOverviewList = false
    this.currentType = list.value
    this.getBagsReport()
    this.foodOverviewList.forEach((item) => {
      if (item.id == this.selectedFoodCategoryId) {
        this.foodCategoryTitle = item.name
      }
    })
  }

  handleError(err) {
    this._errorService.handleError(err, '')
  }

}
