import {Component, OnInit, ViewChild} from '@angular/core';
import { CreateOptionDataService, FormFunctionsService, SearchService, TokenService } from '../../../core/services';
import {NewsList, OfferService, Promo} from "../../../features/offer";
import * as dayjs from "dayjs";
import {Events} from "../../../features/events/interface";
import {EventsService} from "../../../features/events/services";
import {WebsiteList, WebsiteService} from "../../../features/website";
import { ActivatedRoute, Router } from '@angular/router';
import {PollList, PollService} from "../../../features/poll";
import { CreateOption } from '../../interface';

@Component({
  selector: 'app-boardcampaigns',
  templateUrl: './boardcampaigns.component.html',
  styleUrls: ['./boardcampaigns.component.scss'],
})
export class BoardcampaignsComponent implements OnInit {
  @ViewChild('searchBox') searchBox;
  type: string = ''
  orderTab = ['coop', 'facebook', 'sms', 'mail'];
  pageTitle = 'Kampagneoversigt'
  parentUrl: string;
  section: string = 'orderlist';
  searchString = '';
  itemList = [
    {
      "id": 1,
      "name": 'Nyheder'
    },
    {
      "id": 2,
      "name": 'Arrangement '
    },
    {
      "id": 3,
      "name": 'Website - Bestyrelse'
    },
    {
      "id": 4,
      "name": 'Afstemninger'
    }
  ]
  page: number = 1;
  limit: number = 10;
  last_page: number;
  showOfferOption = false;
  selectedId = 1;
  offerTitle = 'Nyheder';
  currentTab = 'active';
  activeTab: string = '1';
  currentSearchString: string = '';
  createNew = 'Opret Nyhed'


  newsData: Promo[];
  newsList: NewsList[];
  allNews: NewsList[] = [];

  eventsList: Events[];
  allEvents: Events[];

  websiteList: WebsiteList[];
  allWebsites: WebsiteList[];

  pollList: PollList[];
  allPolls: PollList[];

  selectedOption: CreateOption;

  private static _setUpImageForPromo(promo): string {
    if (promo.thumbnail) {
      return promo.thumbnail;
    }
    if (promo.largeImage) {
      return promo.largeImage[0];
    }
    if (promo.video.thumbnail) {
      return promo.video.thumbnail;
    }
  }

  constructor(
    private _service: OfferService,
    private _eventService: EventsService,
    private _websiteService: WebsiteService,
    private _pollService: PollService,
    private _route: ActivatedRoute,
    private _searchService: SearchService,
    private _router:Router,
    private _createOptionService: CreateOptionDataService
  ) {
  }

  ngOnInit() {
    this.initComp()
    this.setPreviousUrl()
  }

  private initComp() {
    this._route.queryParams.subscribe({
      next: (res) => {
        this.type = res['type']
        if (this.type === 'news') {
          this.selectedId = 1
          this.offerTitle = 'Nyheder'
          this.createNew = 'Opret Nyhed'
          this.getNewsList()
        } else if (this.type === 'arrangement') {
          this.selectedId = 2
          this.createNew = 'Opret Arrangement '
          this.offerTitle = 'Arrangement'
          this.getArrangementList()
        } else if (this.type === 'poll') {
          this.selectedId = 4
          this.offerTitle = 'Afstemninger'
          this.createNew = 'Opret Afstemning'
          this.getPollList()
        } else {
          this.selectedId = 3
          this.offerTitle = 'Website - Bestyrelse'
          this.createNew = 'Opret Website'
          this.getWebsiteList()
        }
      }
    })

    this.selectedOption = {
      mode: 'skab',
      type: 'ja-tak',
      share: {
        coop: true,
        facebook: true,
        sms: false,
        mail: false,
        web: false,
        screen: false,
        dit: false
      },
      //if currentCalendarType is present set step to 3 else 1
      step: 1
    };
  }


  search(value: string) {
    this.searchString = value;
    this.page = 1;
    if (value !== '' && value.trim().length !== 0) {
      this.websiteList = this._searchService.searchByTitle(
        value,
        this.allWebsites,
        'title'
      );
      this.currentSearchString = value;

    }

  }

  closeSearch() {
    this.searchBox.nativeElement.value = null;
    this.currentSearchString = '';
    this.searchString = '';
    this.page = 1;
    this.getWebsiteList()
  }


  private getNewsList() {
    this._service.getNewsList(this.activeTab, this.page, 10).subscribe({
      next: (res) => {
        this.newsList = this.allNews = this._prepareNewsList(res);
      }
    })
  }

  private getArrangementList() {
    this._eventService.getEventsList(this.activeTab, this.page).subscribe({
      next: (res) => {
        this.eventsList = this.allEvents = res
      }
    })
  }

  private getWebsiteList() {
    this._websiteService.getWebsiteListing().subscribe({
      next: (res) => {
        this.websiteList = this.allWebsites = res
      }
    })
  }

  private getPollList() {
    this._pollService.getPolls(this.activeTab, this.page).subscribe({
      next: (res) => {
        this.pollList = this.allPolls = res
      }
    })
  }

  private _prepareNewsList(newsData: Promo[]): NewsList[] {
    const tempNewsDataArray = [];
    let tempNewsData;
    for (const index in newsData) {
      tempNewsData = {};
      const news = newsData[index];
      tempNewsData['id'] = news.id;
      for (const key in this.orderTab) {
        if (news.shared[this.orderTab[key]]) {
          const tab = news[this.orderTab[key]];
          tempNewsData.thumbImageUrl =
            BoardcampaignsComponent._setUpImageForPromo(tab);
          tempNewsData.publishDate = tab.startDate;
          tempNewsData.expirationDate = tab.endDate;
          tempNewsData.title = tab.title;
          tempNewsData.longDescription = FormFunctionsService.stripHTML(
            tab.longDescription
          );
          break;
        }
      }
      tempNewsDataArray.push(tempNewsData);
    }
    return tempNewsDataArray;
  }

  toggleOfferOption() {
    this.showOfferOption = !this.showOfferOption;
  }


  setPreviousUrl() {
    const userType = TokenService.getUserType();
    if (userType === '1') {
      this.parentUrl = '/butikker/indstillinger';
    }
    if (userType === '2') {
      this.parentUrl = '/bestyrelse/indstillinger';
    }
  }

  onSelect(offer) {
    this.selectedId = offer.id
    this.offerTitle = offer.name
    this.getListBySelectedid()
    this.showOfferOption = false
  }

  private getListBySelectedid() {
    if (this.selectedId === 1) {
      this.getNewsList()
      this.createNew = 'Opret Nyhed'
    } else if (this.selectedId === 2) {
      this.getArrangementList()
      this.createNew = 'Opret Arrangement '
    } else if (this.selectedId === 3) {
      this.getWebsiteList()
      this.createNew = 'Opret Website'
    } else {
      this.getPollList()
      this.createNew = 'Opret Afstemning'
    }
  }

  setTabState(state: string) {
    this.activeTab = state
    this.currentSearchString = '';
    // this.searchBox.nativeElement.value = null;
    if (state === '1') {
      this.currentTab = 'active';
    } else if (state === '2') {
      this.currentTab = 'scheduled';
    } else if (state === '0') {
      this.currentTab = 'archived';
    } else {
      this.currentTab = 'cancelled'
    }
    this.page = 1
    // this.getNewsList()
    this.getListBySelectedid()
  }

  formatDate(date: string) {
    return dayjs(date).format('D. M. YYYY')
  }

  onCreateNew() {
    if (this.selectedId === 1) {
      this.selectedOption.isSet = true;
      this._createOptionService.setCreateOption(this.selectedOption);
      this._router.navigate(['/bestyrelse/indlaeg/nyheder/skab']).then()
    }
    else if (this.selectedId === 2) {
      // this.selectedOption.isSet = true;
      // this._createOptionService.setCreateOption(this.selectedOption);
      this._router.navigate(['/bestyrelse/arrangement/skab']).then()
    }
    else if (this.selectedId === 3) {
      this._router.navigate(['/bestyrelse/website/skab']).then()
    }
    else if (this.selectedId === 4) {
      this._router.navigate(['/bestyrelse/afstemninger/skab']).then()
    }

  }

  loadMore(event: any) {
    if (this.page === this.last_page) {
      event.target.complete()
      return;
    }
    this.page++
    if (this.selectedId === 1) {
      this._service.getNewsList(this.activeTab, this.page, 10).subscribe({
        next: (res) => {
        }
      })
    }

    if (this.selectedId === 2) {
      this._eventService.getEventsList(this.activeTab, this.page).subscribe({
        next: (res) => {
          this.eventsList = this.allEvents = this.eventsList.concat(res)
          event.target.complete()
        }
      })
    }


    if (this.selectedId === 4) {
      this._pollService.getPolls(this.activeTab, this.page).subscribe({
        next: (res) => {
          this.pollList = this.allPolls = this.pollList.concat(res)
          event.target.complete()
        }
      })
    }
  }

}
