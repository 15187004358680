import {
  trigger,
  state,
  animate,
  transition,
  style,
} from '@angular/animations';
//created triggerObject 'fadeInOutAnimation'
//set the style of default state
//:enter => transition when element enter in the DOM with the style of backgroundColor then animates it to change that backgroundColor as the element is inserted into the view over .1s transition time and custom defined easing curve. 
//:leave => transition when element leave DOM with .1s transition time and custom defined easing curve changing default state's backgroundColor to void state's backgroundColor.
export const fadeInOutAnimation = trigger('fadeInOutAnimation', [
  state(
    '*',
    style({
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      zIndex: 50,
      height: '100%',
      width: '100%',
    })
  ),
  transition(':enter', [
    style({
      backgroundColor: 'rgba(0, 0, 0, 0)',
    }),
    animate(
      '.1s cubic-bezier(.35,0,.25,1)',
      style({
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      })
    ),
  ]),
  transition(':leave', [
    animate(
      '.1s cubic-bezier(.35,0,.25,1)',
      style({
        backgroundColor: 'rgba(0, 0, 0, 0)',
      })
    ),
  ]),
]);
//created animation variable 'detailOverlayAnimation'
//transition in both direction takes the 600ms and custom defined easing curve changing the element's style to respective state's.
export const detailOverlayAnimation = [
  trigger('slideUpDown', [
    state(
      'inactive',
      style({
        top: '100%',
        opacity: 0,
      })
    ),
    state(
      'active',
      style({
        top: '10%',
        opacity: 1,
      })
    ),
    transition(
      'inactive <=> active',
      animate('600ms cubic-bezier(.35,0,.25,1)')
    ),
  ]),
];
