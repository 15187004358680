import {Component, OnInit, ViewChild} from '@angular/core';
import {
  CreateOptionDataService,
  ErrorhandlerService,
  FormFunctionsService,
  NotificationsService,
  SearchService,
  TokenService
} from "../../../core/services";
import {ActivatedRoute, Router} from "@angular/router";
import {JatakService} from "../../../features/jatak";
import {SetOrderListState} from "../../../features/jatak/states";
import {Store} from "@ngxs/store";
import {mergeMap, take, takeWhile} from "rxjs/operators";
import {OfferService, RecurringPromo} from "../../../features/offer";
import * as dayjs from "dayjs";
import {WebsiteList, WebsiteService} from "../../../features/website";
import {JatakLiveList, JatakLiveService} from "../../../features/jatak-live";
import {CreateOption} from "../../interface";
import { localeDa, MbscDatepicker, MbscLocale } from '@mobiscroll/angular';
@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.scss'],
})
export class CampaignsComponent implements OnInit {
  @ViewChild('searchBox') searchBox;
  @ViewChild('myDatepicker', {static: false}) myDatepicker: any;
  pAnimate: string = 'inactive';
  type: string = ''
  pageTitle = 'Kampagneoversigt'
  parentUrl: string;
  section: string = 'orderlist';
  selectedId = 2;
  offerTitle = 'JA TAK';
  currentTab = 'active';
  showSortOptions = false;
  displayCalendar = false;
  showEditDuplicateBtn = false;
  activeTab: string = '1';
  sortBy = 1;
  showRecent: boolean = true;
  showTurnOver: boolean = false;
  showMostSold: boolean = false;
  itemList = [
    {
      "id": 1,
      "name": 'JA TAK'
    },
    {
      "id": 2,
      "name": 'Ugentligt God Pris'
    },
    {
      "id": 3,
      "name": 'Om din Butik - Website'
    },
    {
      "id": 4,
      "name": 'Facebook Live'
    }
  ]
  page: number = 1;
  limit: number = 10;
  last_page: number;
  showOfferOption = false;
  imageLoaded = false;
  searchString = '';

  allJataks: any;
  inputInSearch: boolean = false;
  currentSearchString: string = '';
  currentUrl: string;
  showMobileScreenInfoById: number | undefined;
  editUrl: string;
  showInfoOptionBYId: number | undefined;

  jatak: any;
  jatakList: any;

  recurringOfferList: RecurringPromo[];
  allRecurringOfferList: RecurringPromo[];

  websiteList: WebsiteList[];
  allWebsites: WebsiteList[];

  jatakLiveList: JatakLiveList[];
  allJatakLive: JatakLiveList[];

  createNew = 'Opret JA TAK'

  selectedOption: CreateOption;
  routeUrl:string;
  constructor(
    private _route: ActivatedRoute,
    private _service: JatakService,
    private _offerService: OfferService,
    private _router: Router,
    private _errorHandlerService: ErrorhandlerService,
    private _store: Store,
    private _websiteService: WebsiteService,
    private _searchService: SearchService,
    private _jatakLiveService: JatakLiveService,
    private _createOptionService: CreateOptionDataService,
  ) {
  }

  ngOnInit() {
    this.initComp()
  }


  private initComp() {
    this.currentUrl = this._router.url;
    this.setPreviousUrl()
    this.selectedOption = {
      mode: 'skab',
      type: 'ja-tak',
      share: {
        coop: true,
        facebook: true,
        sms: false,
        mail: false,
        web: false,
        screen: false,
        dit: false
      },
      //if currentCalendarType is present set step to 3 else 1
      step: 1
    };
    this._route.queryParams.subscribe({
      next: (res) => {
        this.type = res['type']
        if (this.type === 'jatak') {
          this.selectedId = 1
          this.offerTitle = 'JA TAK';
          this.getJatakList()
        } else if (this.type === 'offer') {
          this.selectedId = 2
          this.offerTitle = 'Ugentligt God Pris';
          this.createNew = 'Opret Ugentligt God Pris'
          this.getRecurringPromosList()
        } else if (this.type === 'jatak-live') {
          this.selectedId = 4
          this.offerTitle = 'Facebook Live';
          this.createNew = 'Opret Facebook Live'
          this.getJatakLiveListing()
        } else {
          this.selectedId = 3
          this.offerTitle = 'Om din Butik - Website';
          this.createNew = 'Opret Website'
          this.getWebsiteList()
        }
      }
    })
  }

  private getWebsiteList() {
    this._websiteService.getWebsiteListing().subscribe({
      next: (res) => {
        this.websiteList = this.allWebsites = res
      },
      error: (err) => {
        this._errorHandlerService.handleError(err)
      }
    })
  }

  private getRecurringPromosList() {
    this._offerService.getRecurringPromos(this.page, 10, this.activeTab).subscribe({
      next: (res) => {
        this.recurringOfferList = this.allRecurringOfferList = FormFunctionsService.lowerCaseKeys(res)
      },
      error: (err) => {
        this._errorHandlerService.handleError(err)
      }
    })
  }

  private getJatakLiveListing() {
    this._jatakLiveService.getJatakLiveListing(this.page, 10, this.activeTab).subscribe({
      next: (res) => {
        this.jatakLiveList = this.allJatakLive = res
      }, error: (err) => {
        this._errorHandlerService.handleError(err)

      }
    })
  }

  private getJatakList() {
    this._service.getJatakListing(this.page, 10, this.activeTab, this.sortBy, this.searchString).subscribe({
      next: (res) => {
        this.jatakList = res['data']
        this.last_page = res['meta']['last_page'];
        this.showSortOptions = false
      },
      error: (err) => {
        this._errorHandlerService.handleError(err)
      }
    })
  }

  loadMore(event: any) {
    if (this.page === this.last_page) {
      event.target.complete()
      return;
    }
    this.page++
    if (this.selectedId === 1) {
      this._service.getJatakListing(this.page, 10, this.activeTab, this.sortBy, this.searchString).subscribe({
        next: (res) => {
          this.jatakList = this.jatakList.concat(res['data'])
          this.last_page = res['meta']['last_page'];
          event.target.complete()
        },
        error: (err) => {
          this._errorHandlerService.handleError(err)
        }
      })
    }

    if (this.selectedId === 4) {
      this._jatakLiveService.getJatakLiveListing(this.page, 10, this.activeTab).subscribe({
        next: (res) => {
          this.jatakLiveList = this.allJatakLive = this.jatakLiveList.concat(res)
          event.target.complete()
        }, error: (err) => {
          this._errorHandlerService.handleError(err)

        }
      })
    }
  }


  toggleOfferOption() {
    this.showOfferOption = !this.showOfferOption;

  }

  setPreviousUrl() {
    const userType = TokenService.getUserType();
    if (userType === '1') {
      this.parentUrl = '/butikker/indstillinger';
    }
    if (userType === '2') {
      this.parentUrl = '/bestyrelse/indstillinger';
    }
  }

  setTabState(state: string) {
    this.activeTab = state
    this.currentSearchString = '';
    this.searchBox.nativeElement.value = null;
    this.showSortOptions = false;
    this.searchString = '';
    if (state === '1') {
      this.currentTab = 'active';
    } else if (state === '2') {
      this.currentTab = 'scheduled';
    } else if (state === '0') {
      this.currentTab = 'archived';
    }
    this.page = 1
    this.getListBySelectedId()
  }


  search(value: string) {
    this.searchString = value;
    this.page = 1;
    if (value !== '' && value.trim().length !== 0) {
      if (this.selectedId === 1) {
        this.getJatakList()
      } else if (this.selectedId === 2) {
        this.getRecurringPromosList()
      } else if (this.selectedId === 3) {
        this.websiteList = this._searchService.searchByTitle(
          value,
          this.allWebsites,
          'title'
        );
      } else {

      }
    }
    this.currentSearchString = value;

  }

  onJatakLiveEdit(id) {
    this._router
      .navigate([`/butikker/ja-tak-live/redigere/${id}`], {
        queryParams: {origin: 'list'},
      })
      .then();
  }

  jatakLiveOrderList(id) {
    // this.routeUrl = "/butikker/ja-tak-live/orderlist/"

    this._router.navigate([`/butikker/ja-tak-live/reporting/${id}`]
      )
      .then();
  }

  onDelete(id) {
    NotificationsService.confirmWithCallback('Slette kampagne?', (accept) => {
      if (accept) {
        this._jatakLiveService
          .deleteJatakLive(id)
          .pipe(
            take(1)
          )
          .subscribe({
            next: (res) => {
              this.getJatakLiveListing()
            },
            error: (err) =>
              this._errorHandlerService.handleError(
                err || {code: -400},
                'jatakLive'
              ),
          });
      }
      if (!accept) {
        return;
      }
    });
  }


  closeSearch() {
    this.searchBox.nativeElement.value = null;
    this.currentSearchString = '';
    this.searchString = '';
    this.inputInSearch = false;
    this.page = 1;
    this.getListBySelectedId()
  }

  private getListBySelectedId() {
    if (this.selectedId === 1) {
      this.getJatakList()
      this.createNew = 'Opret JA TAK'
    } else if (this.selectedId === 2) {
      this.getRecurringPromosList()
      this.createNew = 'Opret Ugentligt God Pris'
    } else if (this.selectedId === 3) {
      this.getWebsiteList()
      this.createNew = 'Opret Website'
    } else {
      this.getJatakLiveListing()
      this.createNew = 'Opret Facebook Live'
    }
  }

  onSelect(offer) {
    this.selectedId = offer.id
    this.offerTitle = offer.name
    this.getListBySelectedId()
    this.showOfferOption = false
  }

  handleNextClick(id: number | undefined) {
    this.showMobileScreenInfoById = this.showMobileScreenInfoById === id ? 0 : id
  }

  handleInfo(id: number | undefined) {
    this.showInfoOptionBYId = this.showInfoOptionBYId === id ? 0 : id
  }


  formatDate(date: string) {
    return dayjs(date).format('D. M. YYYY')
  }


  async onSortClicked(id: number) {
    this.sortBy = id
    TokenService.setSortId(this.sortBy);
    this.showSortOptions = true;
    this.page = 1;
    if (id === 1) {
      this.showRecent = true;
      this.showTurnOver = false;
      this.showMostSold = false;
    }
    if (id === 2) {
      this.showRecent = false;
      this.showTurnOver = true;
      this.showMostSold = false;
    }
    if (id === 3) {
      this.showRecent = false;
      this.showTurnOver = false;
      this.showMostSold = true;
    }
    this.getJatakList()
  }

  onSortOptionToggle() {
    this.showSortOptions = !this.showSortOptions
  }

  displayCalendarToggle() {
    console.log('here');
    this.displayCalendar = !this.displayCalendar;
    this.showSortOptions = false;
  }


  isMobileScreen() {
    return window.innerWidth <= 600;
  }

  onImageLoad(event: Event) {
    this.imageLoaded = true;
  }

  onFavClick(id: number | undefined) {

  }

  openEditDuplicate() {
    this.showEditDuplicateBtn = !this.showEditDuplicateBtn;
  }

  duplicate(id: number) {

    NotificationsService.confirmWithCallback(
      'Dette vil kopiere teksten og billedet fra dette indlæg til en ny JA TAK. Denne JA TAK vil ikke blive påvirket. Vil du fortsætte?',
      (accept) => {
        if (accept) {
          let routeToUrl = `butikker/ja-tak/create/`;
          this._router.navigate([routeToUrl + id], {queryParams: {origin: 'duplicate'}})
            .then(() => (this.pAnimate = 'inactive'));
        }
      }
    );
    // this._saveRouteState()
    // this._saveOrderListRouteState()
  }
  orderList(id: number) {
      this.routeUrl = "/butikker/ja-tak/orderlist/"
      this._router.navigate([this.routeUrl + id]);
  }

  edit(id: number) {
    let url = `/butikker/ja-tak/redigere/${id}`
    this.editUrl = `${this.currentUrl}/redigere/`;
    this._router.navigate([url], {queryParams: {origin: 'list'}})
      .then(() => (this.pAnimate = 'inactive'));
    this._saveOrderListState();
    // this._saveRouteState()
  }

  private _saveOrderListState() {
    this._store.dispatch(new SetOrderListState({
      tab: this.activeTab
    }));
  }

  onCreateNew(offerType:string) {

    if (this.selectedId === 1) {
      this.selectedOption.isSet = true;
      this._createOptionService.setCreateOption(this.selectedOption);
      this._router.navigate(['/butikker/ja-tak/skab']).then()
    }
    else if (this.selectedId === 2) {
      this.selectedOption.isSet = true;
      this._createOptionService.setCreateOption(this.selectedOption);
      this._router.navigate(['/butikker/indlaeg/tilbagevendende-tilbud/skab']).then()
    }
    else if (this.selectedId === 3) {
      this._router.navigate(['/butikker/website/skab']).then()
    }
     else if (this.selectedId === 4) {
      this._router.navigate(['butikker/ja-tak-live/skab']).then()
    }

  }
  handleClickOutside(){
    this.showOfferOption = false;
  }
}
