import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserDetail } from 'app/shared/interface';
import { ApiService } from 'app/core/services/api.service';
import { TokenService } from 'app/core/services/token.service';
import { catchError, map } from 'rxjs/operators';
import { NotificationsService } from './notifications.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private _apiService: ApiService
  ) {
  }

  /// *** Returns details of currently logged in store.
  getUserDetail(): Observable<UserDetail> {
    const url = 'setting/detail';
    return this._apiService.get(url).pipe(
      catchError((err) => {
        this.removeUserDetails(true);
        NotificationsService.notify(
          'Noget gik galt. Prøv igen',
          'error',
          'top'
        );
        return err;
      })
    );
  }

  // It represents whether to review or not post before posting to admin's page.
  // Used in a checkmark input in Setting > `Tilsluttet Side` (Facebook)
  // @params [fbStatus]=>alternate 0 and 1 with respect to previous value.
  updateFbConnectionStatus(fbStatus): Observable<any> {
    const url = `update/access/${fbStatus}`;
    return this._apiService.postFacebook(url, {});
  }

  logout(): Observable<any> {
    const url = 'logout';
    return this._apiService.get(url);
  }

  // *** Clears LocalStorage and handles IndexedDB ***
  // @params [removeIdb] => Boolean value that represent whether to delete indexedDB or not
  removeUserDetails(removeIdb: boolean): void {
    TokenService.removeAllTokens();
  }
}
