export interface CreateMenuItem {
  id: number;
  title: string;
  subTitle: string;
  type: string;
  icon: string;
  hasSubMenu: boolean;
}

export interface MenuData {
  create: CreateMenuItem[];
  template: CreateMenuItem[];
}

const storeMenu: CreateMenuItem[] = [
  {
    'id': 1,
    'title': 'God Pris',
    'subTitle': 'Kampagner med rene priser, der ikke er tilbud',
    'type': 'god-pris',
    'icon': 'pricetags-outline',
    'hasSubMenu': true
  },
  {
    'id': 2,
    'title': 'Nyhed',
    'subTitle': 'Må ikke omhandle prisrelaterede kampagner & tilbud',
    'type': 'nyheder',
    'icon': 'megaphone-outline',
    'hasSubMenu': true
  },
  {
    'id': 3,
    'title': 'Arrangement',
    'subTitle': 'Opsæt et event for jeres kunder i Coop App',
    'type': 'arrangement',
    'icon': 'calendar-number-outline',
    'hasSubMenu': false
  },
  {
    'id': 4,
    'title': 'Afstemning',
    'subTitle': 'Indsaml feedback fra jeres kunder i Coop App',
    'type': 'afstemninger',
    'icon': 'stats-chart-outline',
    'hasSubMenu': false
  },
  {
    'id': 5,
    'title': 'JA TAK',
    'subTitle': 'Brug JA TAK robotten til at sælge hurtigere & mere',
    'type': 'ja-tak',
    'icon': 'sparkles-outline',
    'hasSubMenu': true
  },
  {
    'id': 6,
    'title': 'Facebook Live',
    'subTitle': 'Skab videokampagner som bruger JaTak robotten',
    'type': 'ja-tak-live',
    'icon': 'videocam-outline',
    'hasSubMenu': false
  },
  {
    'id': 7,
    'title': 'SMS',
    'subTitle': 'Send en direkte besked til kunderne i jeres SMS-klub',
    'type': 'sms',
    'icon': 'chatbubble-ellipses-outline',
    'hasSubMenu': false
  },
  {
    'id': 8,
    'title': 'Tilbud',
    'subTitle': 'Husk - overholde reglerne for tilbudskommunikation',
    'type': 'tilbud',
    'icon': 'ticket-outline',
    'hasSubMenu': true
  },
  {
    'id': 9,
    'title': 'Ugentligt god pris',
    'subTitle': 'Ugentlige kampagner med priser, der ikke er tilbud',
    'type': 'tilbagevendende-tilbud',
    'icon': 'repeat-outline',
    'hasSubMenu': true
  },
  {
    'id': 10,
    'title': 'Red Maden',
    'subTitle': 'Sælg din overskudsmad i stedet for at smide den ud',
    'type': 'redmad',
    'icon': 'bag-check-outline',
    'hasSubMenu': true
  }
];
const storeTemplateMenu: CreateMenuItem[] = [
  {
    'id': 1,
    'title': 'God Pris',
    'subTitle': 'Kampagner med rene priser, der ikke er tilbud',
    'type': 'god-pris',
    'icon': 'pricetags-outline',
    'hasSubMenu': false
  },
  {
    'id': 2,
    'title': 'Nyhed',
    'subTitle': 'Må ikke omhandle prisrelaterede kampagner & tilbud',
    'type': 'nyheder',
    'icon': 'megaphone-outline',
    'hasSubMenu': false
  },
  {
    'id': 3,
    'title': 'Afstemning',
    'subTitle': 'Indsaml feedback fra jeres kunder i Coop App',
    'type': 'afstemninger',
    'icon': 'stats-chart-outline',
    'hasSubMenu': false
  },
  {
    'id': 4,
    'title': 'JA TAK',
    'subTitle': 'Brug JA TAK robotten til at sælge hurtigere & mere',
    'type': 'ja-tak',
    'icon': 'sparkles-outline',
    'hasSubMenu': false
  },
  {
    'id': 5,
    'title': 'Facebook Live',
    'subTitle': 'Skab videokampagner som bruger JaTak robotten',
    'type': 'ja-tak-live',
    'icon': 'videocam-outline',
    'hasSubMenu': false
  },
  {
    'id': 6,
    'title': 'Tilbud',
    'subTitle': 'Husk - overholde reglerne for tilbudskommunikation',
    'type': 'tilbud',
    'icon': 'ticket-outline',
    'hasSubMenu': false
  }
];
const boardMenu: CreateMenuItem[] = [
  {
    'id': 1,
    'title': 'Nyhed',
    'subTitle': 'Må ikke omhandle prisrelaterede kampagner & tilbud',
    'type': 'nyheder',
    'icon': 'megaphone-outline',
    'hasSubMenu': true
  },
  {
    'id': 2,
    'title': 'Arrangement',
    'subTitle': 'Opsæt et event for jeres kunder i Coop App',
    'type': 'arrangement',
    'icon': 'calendar-number-outline',
    'hasSubMenu': false
  },
  {
    'id': 3,
    'title': 'Afstemning',
    'subTitle': 'Indsaml feedback fra jeres kunder i Coop App',
    'type': 'afstemninger',
    'icon': 'stats-chart-outline',
    'hasSubMenu': false
  }
];
const boardTemplateMenu: CreateMenuItem[] = [

  {
    'id': 1,
    'title': 'Årsmøde',
    'subTitle': 'Fysisk årsmøde, med medlemstilmelding i Coop app, samt deltagerliste i QC.',
    'type': 'arsmode',
    'icon': 'people-outline',
    'hasSubMenu': true
  },

  {
    'id': 2,
    'title': 'Generalforsamling',
    'subTitle': 'Fysisk generalforsamling, med medlemstilmelding i Coop app, samt deltagerliste i QC.',
    'type': 'forsamling-event',
    'icon': 'chatbubbles-outline',
    'hasSubMenu': true
  },
  {
    'id': 3,
    'title': 'Nyhed',
    'subTitle': 'Må ikke omhandle prisrelaterede kampagner & tilbud',
    'type': 'nyheder',
    'icon': 'megaphone-outline',
    'hasSubMenu': false
  },
  {
    'id': 4,
    'title': 'Afstemning',
    'subTitle': 'Indsaml feedback fra jeres kunder i Coop App',
    'type': 'afstemninger',
    'icon': 'stats-chart-outline',
    'hasSubMenu': false
  }

];

export const storeMenuData: MenuData = {
  create: storeMenu,
  template: storeTemplateMenu
};

export const boardMenuData: MenuData = {
  create: boardMenu,
  template: boardTemplateMenu
};
