export const Ok_Screen_Messages = {
  startDate: {
    required: "Udfyld venligst feltet",
    incorrect: "Slutdato skal være efter Startdato",
    used: "Den samme dato findes allerede i brugt Ok-skærm.",
    range: "Der er en aktiv periode et sted mellem dette interval.",
    same: "Samme dato er allerede taget.",
    usedDisplay: "Der er allerede valgt et tidsrum i dette interval.",
    sameDisplay: "Samme dato er allerede valgt.",
    sameSame: "Startdato og slutdato kan ikke være den samme.",
  },
  endDate: {
    required: "Udfyld venligst feltet",
    incorrect: "Slutdato skal være efter Startdato",
    used: "Den samme dato findes allerede i brugt Ok-skærm.",
    range: "Der er en aktiv periode et sted mellem dette interval.",
    same: "Samme dato er allerede taget.",
    usedDisplay: "Der er allerede valgt et tidsrum i dette interval.",
    sameDisplay: "Samme dato er allerede valgt.",
    sameSame: "Startdato og slutdato kan ikke være den samme.",
  },
};
