import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import * as dayjs from "dayjs";

@Component({
  selector: 'app-publish-expiration-date-time',
  templateUrl: './publish-expiration-date-time.component.html',
  styleUrls: ['./publish-expiration-date-time.component.scss'],
})
export class PublishExpirationDateTimeComponent implements OnInit, OnChanges {
  @Input() formData: any
  @Input() editMode: boolean = false
  @Input() duplicateMode: boolean = false
  @Input() templateMode: boolean = false
  @Input() startDateTimeS;
  @Input() endDateTime;
  // @Input()
  defaultStartDate = dayjs().format('YYYY-MM-DD')
  startDate = dayjs().format('YYYY-MM-DD')
  startTime = dayjs().format('HH:mm')
  endDate = dayjs().add(6, 'days').format('YYYY-MM-DD')
  endTime = dayjs().format('HH:mm')
  dateRange = [this.startDate, this.endDate]
  onStartNow: boolean = true
  showCalendar: boolean = false
  @Output() punlishDateTime = new EventEmitter<{ startDateTime: string, endDateTime: string, start_now: boolean }>();
  @Input() dispatchTime:string;
  @Input() closingTime:string;
  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['startDateTimeS'] || changes['endDateTime']) {
      this.setupCalendar();
    }
    if(changes['dispatchTime']){
      this.dispatchTime = changes['dispatchTime'].currentValue
    }
    if(changes['closingTime']){
      this.closingTime = changes['closingTime'].currentValue
    }
  }

  ngOnInit() {
    this.setupCalendar()
    // this.concatDateTime()
  }

  private setupCalendar() {
    this.startDate = dayjs(this.startDateTimeS).format('YYYY-MM-DD')
    this.startTime = dayjs(this.startDateTimeS).format('HH:mm')
    this.endDate = dayjs(this.endDateTime).format('YYYY-MM-DD')
    this.endTime = dayjs(this.endDateTime).format('HH:mm')
    this.dateRange = [this.startDate, this.endDate]
  }

  onStartTimeChange(event: any) {
    this.startTime = event.valueText
    this.concatDateTime()
  }

  onEndTimeChange(event: any) {
    this.endTime = event.valueText
    this.concatDateTime()
  }

  getDateTime(event: any) {
    console.error('this should not run from publish expiration')
    if (this.onStartNow || this.editMode) {
      this.startDate = this.editMode ? this.startDate : dayjs().format('YYYY-MM-DD');
      this.endDate = dayjs(event.value[0]).format('YYYY-MM-DD')
      if (event.value[1]) {
        this.endDate = dayjs(event.value[1]).format('YYYY-MM-DD')
      }
    } else {
      this.startDate = dayjs(event.value[0]).format('YYYY-MM-DD')
      if (event.value[1]) {
        this.endDate = dayjs(event.value[1]).format('YYYY-MM-DD')
      }
    }
    this.dateRange = [this.startDate, this.endDate]
    this.concatDateTime()
  }


  onStartNowToggle(event: any) {
    this.onStartNow = event.target.checked;
    if (!this.onStartNow) {
      this.showCalendar = true
    }
    this.startDate = dayjs().format('YYYY-MM-DD')
    this.startTime = dayjs().format('HH:mm')
    this.dateRange = [this.startDate, this.endDate]
  }

  formatDate(date: string) {
    dayjs.locale('da');
    return dayjs(date).format('D. MMMM');
  }


  concatDateTime() {
    let startDateTime = this.startDate + ' ' + this.startTime
    let endDateTime = this.endDate + ' ' + this.endTime
    let start_now = this.onStartNow
    this.punlishDateTime.emit({startDateTime, endDateTime, start_now});
  }

  toggleCalendar() {
    this.showCalendar = !this.showCalendar
  }
}
