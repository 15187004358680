import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { throwError } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { mergeMap } from 'rxjs/internal/operators/mergeMap';
import { takeWhile } from 'rxjs/operators';

import {
  AppEventsService,
  ErrorhandlerService,
  FormFunctionsService,
  NotificationsService,
  SortService,
  TokenService,
} from 'app/core/services';
import { animateParent, slideAnimation } from 'app/shared/animations';
import { NewsList, Promo, OfferService } from 'app/features/offer';

@Component({
  selector: 'coop-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss'],
  animations: [animateParent, slideAnimation],
})
export class NewsListComponent implements OnInit, OnDestroy {
  orderTab = ['coop', 'facebook', 'sms', 'mail'];
  newsData: Promo[];
  newsList: NewsList[];
  allNews: NewsList[] = [];
  showParent: string = 'visible';
  pageTitle: string = 'Nyheder';
  currentUrl: string;
  parentUrl: string;
  activeTab: string = '1';
  page: number = 1;
  limit: number = 10;
  private _subscriptionState: boolean = true;

  private static _setUpImageForPromo(promo): string {
    if (promo.thumbnail) {
      return promo.thumbnail;
    }
    if (promo.largeImage) {
      return promo.largeImage[0];
    }
    if (promo.video.thumbnail) {
      return promo.video.thumbnail;
    }
  }

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _errorHandlerService: ErrorhandlerService,
    private _offerService: OfferService,
    private _sortService: SortService,
    private _title: Title,
    private _appEvents: AppEventsService
  ) {}

  ngOnInit() {
    this._initComponent();
    this._router.events
      .pipe(
        takeWhile(() => this._subscriptionState),
        mergeMap((routerEvent) => {
          if (
            routerEvent instanceof NavigationEnd &&
            routerEvent.url === this.currentUrl
          ) {
            return this._getAllNews(true, this.activeTab);
          }
          return of([]);
        })
      )
      .subscribe({
        next: (value: [any, NewsList[]]) => {
          // setting newsList
          if (value[1] !== undefined) {
            if (value[1].length !== 0) {
              this.updateList(value[1], this.activeTab);
            }
          }
        },
        error: (err) => this._handleError(err),
      });
    this._appEvents.showLoader(false);
  }

  ngOnDestroy(): void {
    this._subscriptionState = false;
  }

  tab(value: string, event): void {
    if (event && this.activeTab !== value) {
      this._getAllNews(true, value)
        .pipe(takeWhile(() => this._subscriptionState))
        .subscribe({
          next: (posts) => {
            // resetting the page from 1 from other tabs
            this.page = 1;
            this.newsList = this.allNews = [];
            this.updateList(posts, value);
          },
          error: (err) => this._handleError(err),
        });
    }
  }

  loadMore() {
    this.page++;
    this._getAllNews(true, this.activeTab)
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (posts) => {
          if (posts.length === 0) {
            NotificationsService.notify('Ingen flere nyheder', 'error', 'top');
          } else {
            this.updateList(posts, this.activeTab);
          }
        },
        error: (err) => this._handleError(err),
      });
  }

  updateList(posts, value) {
    this.activeTab = value;
    if (this.allNews.length === 0) {
      this.newsList = this.allNews = this._prepareNewsList(posts);
      return;
    }
    this.newsList = this.allNews.concat(this._prepareNewsList(posts));
    this.allNews = this.newsList;
  }

  private _initComponent() {
    this._title.setTitle('QuickCoop');
    this.newsData = this._activatedRoute.snapshot.data['newsList'];
    this.updateList(this.newsData, this.activeTab);
    this._checkChildPage();
    this.currentUrl = this._router.url;
    this._setPreviousUrl();
  }

  private _prepareNewsList(newsData: Promo[]): NewsList[] {
    const tempNewsDataArray = [];
    let tempNewsData;
    for (const index in newsData) {
      tempNewsData = {};
      const news = newsData[index];
      tempNewsData['id'] = news.id;
      for (const key in this.orderTab) {
        if (news.shared[this.orderTab[key]]) {
          const tab = news[this.orderTab[key]];
          tempNewsData.thumbImageUrl =
            NewsListComponent._setUpImageForPromo(tab);
          tempNewsData.publishDate = tab.startDate;
          tempNewsData.expirationDate = tab.endDate;
          tempNewsData.title = tab.title;
          tempNewsData.longDescription = FormFunctionsService.stripHTML(
            tab.longDescription
          );
          break;
        }
      }
      tempNewsDataArray.push(tempNewsData);
    }
    return tempNewsDataArray;
  }

  private _getAllNews(state: boolean, value) {
    if (state) {
      return this._offerService.getNewsList(value, this.page, this.limit);
    }
    return throwError(() => 'Error');
  }

  private _setPreviousUrl() {
    const userType = TokenService.getUserType();
    if (userType === '1') {
      this.parentUrl = '/butikker/indstillinger';
    }
    if (userType === '2') {
      this.parentUrl = '/bestyrelse/indstillinger';
    }
  }

  private _handleError(err) {
    this._errorHandlerService.handleError(err);
  }

  private _checkChildPage(): void {
    this.showParent = this._activatedRoute.children.length
      ? 'invisible'
      : 'visible';
  }
}
