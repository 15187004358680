<div class="link-container">
  <ng-container
    *ngIf="
          (selectedOption.type === 'tilbud' ||
            selectedOption.type === 'god-pris' ||
            selectedOption.type === 'nyheder')
        "
  >
    <a
      class="link-box"
      [class.disabled]="
            userType === '1' && selectedOption.type !== 'nyheder'
          "
      (click)="
            (userType === '2' || selectedOption.type === 'nyheder') &&
              coopSelect()
          "
    >
      <div >
        <ion-icon name="phone-portrait" class="channel-icon default-selected"*ngIf="selectedOption.share.coop && selectedOption.type !== 'nyheder'"></ion-icon>
        <ion-icon name="phone-portrait-outline" class="channel-icon" *ngIf="!selectedOption.share.coop && selectedOption.type === 'nyheder'"></ion-icon>
        <ion-icon name="phone-portrait" class="channel-icon" *ngIf="selectedOption.share.coop && selectedOption.type === 'nyheder'"></ion-icon>
      </div>
      <div class="link-text link-text-padding">
        <div>
          <h3 class="default-selected" *ngIf="selectedOption.share.coop && selectedOption.type !== 'nyheder'">CoopApp</h3>
          <h3 *ngIf="!selectedOption.share.coop && selectedOption.type === 'nyheder'">CoopApp</h3>
          <h5 *ngIf="selectedOption.share.coop && selectedOption.type === 'nyheder'">CoopApp</h5>

        </div>
      </div>
      <div
        class="check icon icon-check-box"
        [ngClass]="selectedOption.share.coop ? 'red' : ''"
      >
        <ion-icon name="square-outline" class="checkbox-icon default-selected" *ngIf="!selectedOption.share.coop && selectedOption.type !== 'nyheder'"></ion-icon>
        <ion-icon name="checkbox" class="checkbox-icon" *ngIf="selectedOption.share.coop && selectedOption.type !== 'nyheder'"></ion-icon>
        <ion-icon name="square-outline" class="checkbox-icon" *ngIf="!selectedOption.share.coop && selectedOption.type === 'nyheder'"></ion-icon>
        <ion-icon name="checkbox" class="checkbox-icon" *ngIf="selectedOption.share.coop && selectedOption.type === 'nyheder'"></ion-icon>
      </div>
    </a>

    <a class="link-box" (click)="checkfbSelect()">
      <div >
        <ion-icon name="logo-facebook" class="channel-icon"></ion-icon>
      </div>
      <div class="link-text link-text-padding">
        <div>
          <h3 *ngIf="!selectedOption.share.facebook">Facebook</h3>
          <h5 *ngIf="selectedOption.share.facebook">Facebook</h5>
        </div>
      </div>
      <div
        class="check icon icon-check-box"
        [ngClass]="selectedOption.share.facebook ? 'red' : ''"
      >
        <ion-icon name="square-outline" class="checkbox-icon" *ngIf="!selectedOption.share.facebook"></ion-icon>
        <ion-icon name="checkbox" class="checkbox-icon" *ngIf="selectedOption.share.facebook"></ion-icon>
      </div>
    </a>


    <a class="link-box" (click)="smsSelect()" *ngIf="userType === '1'">
      <div >
        <ion-icon name="chatbubble-ellipses-outline" class = "channel-icon" *ngIf="!selectedOption.share.sms"></ion-icon>
        <ion-icon name="chatbubble-ellipses" class = "channel-icon" *ngIf="selectedOption.share.sms"></ion-icon>

      </div>
      <div class="link-text link-text-padding">
        <div>
          <h3 *ngIf="!selectedOption.share.sms">SMS</h3>
          <h5 *ngIf="selectedOption.share.sms">SMS</h5>

        </div>
      </div>
      <div
        class="check icon icon-check-box"
        [ngClass]="selectedOption.share.sms ? 'red' : ''"
      >
        <ion-icon name="square-outline" class="checkbox-icon" *ngIf="!selectedOption.share.sms"></ion-icon>
        <ion-icon name="checkbox" class="checkbox-icon" *ngIf="selectedOption.share.sms"></ion-icon>

      </div>
    </a>
    <a class="link-box" (click)="webSelect()">
      <div >
        <ion-icon name="globe-outline" class="channel-icon" *ngIf="!selectedOption.share.web"></ion-icon>
        <ion-icon name="globe" class="channel-icon" *ngIf="selectedOption.share.web"></ion-icon>

      </div>
      <div class="link-text link-text-padding">
        <div>
          <h3 *ngIf="!selectedOption.share.web">Web</h3>
          <h5 *ngIf="selectedOption.share.web">Web</h5>

        </div>
      </div>
      <div
        class="check icon icon-check-box"
        [ngClass]="selectedOption.share.web ? 'red' : ''"
      >
        <ion-icon name="square-outline" class="checkbox-icon" *ngIf="!selectedOption.share.web"></ion-icon>
        <ion-icon name="checkbox" class="checkbox-icon" *ngIf="selectedOption.share.web"></ion-icon>
      </div>
    </a>
    <a
      class=" link-box"
      (click)="ditSelect()"
      *ngIf="userType === '1' && selectedOption.type !== 'tilbud'"
    >
      <div >
        <ion-icon name="location-outline" class="channel-icon" *ngIf="!selectedOption.share.dit"></ion-icon>
        <ion-icon name="location" class="channel-icon" *ngIf="selectedOption.share.dit"></ion-icon>

      </div>
      <div class="link-text link-text-padding">
        <div>
          <h3 *ngIf="!selectedOption.share.dit">Digital avis</h3>
          <h5 *ngIf="selectedOption.share.dit">Digital avis</h5>

        </div>
      </div>
      <div
        class="check icon icon-check-box"
        [ngClass]="selectedOption.share.dit ? 'red' : ''"
      >
        <ion-icon name="square-outline" class="checkbox-icon" *ngIf="!selectedOption.share.dit"></ion-icon>
        <ion-icon name="checkbox" class="checkbox-icon" *ngIf="selectedOption.share.dit"></ion-icon>
      </div>
    </a>
<!--    <a class="link-box" (click)="screenSelect()" *ngIf="showScreenOption">-->
<!--      <div class="link-icon link-icon-padding">-->
<!--        <i class="icon icon-mirroring-screen" *ngIf="!selectedOption.share.screen"></i>-->
<!--        <i class="icon icon-mirroring-screen-filled" *ngIf="selectedOption.share.screen"></i>-->

<!--      </div>-->
<!--      <div class="link-text link-text-padding">-->
<!--        <div>-->
<!--          <h3 *ngIf="!selectedOption.share.screen">Butiksskærme</h3>-->
<!--          <h5 *ngIf="selectedOption.share.screen">Butiksskærme</h5>-->

<!--        </div>-->
<!--      </div>-->
<!--      <div-->
<!--        class="check icon icon-check-box"-->
<!--        [ngClass]="selectedOption.share.screen ? 'red' : ''"-->
<!--      >-->
<!--        <i class="icon icon-stop" *ngIf="!selectedOption.share.screen"></i>-->
<!--        <i class="icon-tick-square" *ngIf="selectedOption.share.screen"></i>-->
<!--      </div>-->
<!--    </a>-->
    <a class="link-box" (click)="emailSelect()">
      <div >
        <ion-icon name="mail-outline" class = "channel-icon"*ngIf="!selectedOption.share.mail"></ion-icon>
        <ion-icon name="mail" class = "channel-icon" *ngIf="selectedOption.share.mail"></ion-icon>

      </div>
      <div class="link-text link-text-padding">
        <div>
          <h3 *ngIf="!selectedOption.share.mail">Email</h3>
          <h5 *ngIf="selectedOption.share.mail">Email</h5>

        </div>
      </div>
      <div
        class="check icon icon-check-box"
        [ngClass]="selectedOption.share.mail ? 'red' : ''"
      >
        <ion-icon name="square-outline" class="checkbox-icon" *ngIf="!selectedOption.share.mail"></ion-icon>
        <ion-icon name="checkbox" class="checkbox-icon" *ngIf="selectedOption.share.mail"></ion-icon>
      </div>
    </a>
  </ng-container>
  <ng-container
    *ngIf="selectedOption.type === 'ja-tak'"
  >
    <a class="link-box" (click)="jatakCoopSelect()">
      <div >
        <ion-icon name="phone-portrait-outline" class="channel-icon" *ngIf="!selectedOption.share.coop"></ion-icon>
        <ion-icon name="phone-portrait" class="channel-icon" *ngIf="selectedOption.share.coop"></ion-icon>
<!--        <i class="icon icon-mobile" *ngIf="!selectedOption.share.coop"></i>-->
<!--        <i class="icon icon-mobile-filled" *ngIf="selectedOption.share.coop"></i>-->
      </div>
      <div class="link-text link-text-padding">
        <div>
          <h3 *ngIf="!selectedOption.share.coop">CoopApp</h3>
          <h5 *ngIf="selectedOption.share.coop">CoopApp</h5>

        </div>
      </div>
      <div
        class="check icon-check-box"
        [ngClass]="selectedOption.share.coop ? 'red' : ''"
      >
        <ion-icon name="square-outline" class="checkbox-icon" *ngIf="!selectedOption.share.coop"></ion-icon>
        <ion-icon name="checkbox" class="checkbox-icon" *ngIf="selectedOption.share.coop"></ion-icon>
<!--        <i class="icon icon-stop " *ngIf="!selectedOption.share.coop"></i>-->
<!--        <i class="icon icon-tick-square-filled " *ngIf="selectedOption.share.coop"></i>-->
      </div>
    </a>
    <a class="link-box" (click)="checkfbSelect()">
      <div>
        <ion-icon name = "logo-facebook" class="channel-icon"></ion-icon>
<!--        <i class="icon icon-facebook" *ngIf="!selectedOption.share.facebook"></i>-->
<!--        <i class="icon icon-facebook-filled" *ngIf="selectedOption.share.facebook"></i>-->
      </div>
      <div class="link-text link-text-padding">
        <div>
          <h3 *ngIf="!selectedOption.share.facebook">Facebook</h3>
          <h5 *ngIf="selectedOption.share.facebook">Facebook</h5>

        </div>
      </div>
      <div
        class="check icon icon-check-box"
        [ngClass]="selectedOption.share.facebook ? 'red' : ''"
      >
        <ion-icon name="square-outline" class="checkbox-icon" *ngIf="!selectedOption.share.facebook"></ion-icon>
        <ion-icon name="checkbox" class="checkbox-icon" *ngIf="selectedOption.share.facebook"></ion-icon>
<!--        <i class="icon icon-stop" *ngIf="!selectedOption.share.facebook"></i>-->
<!--        <i class="icon icon-tick-square-filled" *ngIf="selectedOption.share.facebook"></i>-->
      </div>
    </a>
    <a class="link-box" (click)="jatakSmsSelect()">
      <div >
        <ion-icon name="chatbubble-ellipses-outline" class="channel-icon" *ngIf="!selectedOption.share.sms"></ion-icon>
        <ion-icon name="chatbubble-ellipses" class="channel-icon" *ngIf="selectedOption.share.sms"></ion-icon>
<!--        -->
<!--        <i class="icon icon-message" *ngIf="!selectedOption.share.sms"></i>-->
<!--        <i class="icon icon-message-filled" *ngIf="selectedOption.share.sms"></i>-->
      </div>
      <div class="link-text link-text-padding">
        <div>
          <h3 *ngIf="!selectedOption.share.sms">SMS</h3>
          <h5 *ngIf="selectedOption.share.sms">SMS</h5>

        </div>
      </div>
      <div
        class="check icon icon-check-box"
        [ngClass]="selectedOption.share.sms ? 'red' : ''"
      >
        <ion-icon name="square-outline" class="checkbox-icon" *ngIf="!selectedOption.share.sms"></ion-icon>
        <ion-icon name="checkbox" class="checkbox-icon" *ngIf="selectedOption.share.sms"></ion-icon>
      </div>
    </a>
    <a
      class="link-box"
      (click)="jatakWebSelect()"
      [class.disabled-web]="
            !selectedOption.share.coop && !selectedOption.share.facebook
          "
    >
      <div >
        <ion-icon name="globe-outline" class="channel-icon" *ngIf="!selectedOption.share.web"></ion-icon>
        <ion-icon name="globe" class="channel-icon" *ngIf="selectedOption.share.web"></ion-icon>
<!--        <i class="icon icon-global" *ngIf="!selectedOption.share.web"></i>-->
<!--        <i class="icon icon-global-filled" *ngIf="selectedOption.share.web"></i>-->
      </div>
      <div class="link-text link-text-padding">
        <div>
          <h3 *ngIf="!selectedOption.share.web">Web</h3>
          <h5 *ngIf="selectedOption.share.web">Web</h5>

        </div>
      </div>
      <div
        class="check icon icon-check-box"
        [ngClass]="selectedOption.share.web ? 'red' : ''"
      >
        <ion-icon name="square-outline" class="checkbox-icon" *ngIf="!selectedOption.share.web"></ion-icon>
        <ion-icon name="checkbox" class="checkbox-icon" *ngIf="selectedOption.share.web"></ion-icon>
      </div>
    </a>
  </ng-container>
  <ng-container
    *ngIf="
          selectedOption.type === 'tilbagevendende-tilbud' ||   selectedOption.type === 'redmad'
        "
  >
    <a class="link-box" (click)="coopSelect()">
      <div >
        <ion-icon name="phone-portrait-outline" class="channel-icon" *ngIf="!selectedOption.share.coop"></ion-icon>
        <ion-icon name="phone-portrait" class="channel-icon" *ngIf="selectedOption.share.coop"></ion-icon>

<!--        <i class="icon icon-mobile" *ngIf="!selectedOption.share.coop"></i>-->
<!--        <i class="icon icon-mobile-filled" *ngIf="selectedOption.share.coop"></i>-->

      </div>
      <div class="link-text link-text-padding">
        <div>
          <h3 *ngIf="!selectedOption.share.coop">CoopApp</h3>
          <h5 *ngIf="selectedOption.share.coop">CoopApp</h5>

        </div>
      </div>
      <div
        class="check icon icon-check-box"
        [ngClass]="selectedOption.share.coop ? 'red' : ''"
      >
        <ion-icon name="square-outline" class="checkbox-icon" *ngIf="!selectedOption.share.coop"></ion-icon>
        <ion-icon name="checkbox" class="checkbox-icon" *ngIf="selectedOption.share.coop"></ion-icon>
      </div>
    </a>
    <a class="link-box" (click)="checkfbSelect()">
      <div >
        <ion-icon name="logo-facebook" class="channel-icon"></ion-icon>
<!--        <i class="icon icon-facebook" *ngIf="!selectedOption.share.facebook"></i>-->
<!--        <i class="icon icon-facebook-filled" *ngIf="selectedOption.share.facebook"></i>-->
      </div>
      <div class="link-text link-text-padding">
        <div>
          <h3 *ngIf="!selectedOption.share.facebook">Facebook</h3>
          <h5 *ngIf="selectedOption.share.facebook">Facebook</h5>

        </div>
      </div>
      <div
        class="check icon-check-box"
        [ngClass]="selectedOption.share.facebook ? 'red' : ''"
      >
        <ion-icon name="square-outline" class="checkbox-icon" *ngIf="!selectedOption.share.facebook"></ion-icon>
        <ion-icon name="checkbox" class="checkbox-icon" *ngIf="selectedOption.share.facebook"></ion-icon>

      </div>
    </a>
  </ng-container>
</div>
  <div class="btn-container ">

    <div
      (click)="selectAllChannel()"
      class="login-btn btn-small btn-white-login " >
      Vælg alle
    </div>

    <div
      (click)="next()"
      class="login-btn btn-small btn-black-login" style="margin-left: 10px" >
      Fortsætte
    </div>


<!--    <button-->
<!--      (click)="selectAllChannel()"-->
<!--      class="btn btn-small btn-white btn-adjustment btn-margin-right btn-selectall"-->
<!--      [class.btn-lightRed]="isBtnSwitchColor"-->
<!--      [class.btn-white]="!isBtnSwitchColor"-->
<!--    >-->
<!--      <span class="select-all">Vælg alle</span>-->
<!--&lt;!&ndash;      <i class="icon icon-task-square" *ngIf="!isBtnSwitchColor"></i>&ndash;&gt;-->
<!--&lt;!&ndash;      <i class="icon icon-task-square-filled" *ngIf="isBtnSwitchColor"></i>&ndash;&gt;-->

<!--    </button>-->
<!--    <button-->
<!--      (click)="next()"-->
<!--      class="btn btn-small btn-red btn-adjustment"-->
<!--      *ngIf="-->
<!--        (selectedOption.type === 'tilbud' ||-->
<!--          selectedOption.type === 'nyheder' ||-->
<!--          selectedOption.type === 'god-pris' ||-->
<!--          selectedOption.type === 'ja-tak' ||-->
<!--          selectedOption.type === 'tilbagevendende-tilbud' ||-->
<!--          selectedOption.type === 'redmad')-->
<!--      "-->
<!--    >-->
<!--      Næste-->
<!--    </button>-->

  </div>

<!--<button (click)="toggleParagraph()"  >Click me!</button>-->
<!--<p *ngIf="showParagraph" [@slideUpDown]="showParagraph">Hello world btn is clicked. <br/>hehraere <br/>hehraelr</p>-->
