<div class="app-page product-create-edit page-with-fixed-header-and-tab">
  <div class="fixed-header">
    <coop-pageheader
      [pageTitle]="pageTitle"
      [isChild]="true"
      [routeTo]="previousUrl"
    ></coop-pageheader>
    <div class="fixed-header-content">
      <div class="tab-container">
        <div class="wrapper">
          <ul class="tab-list">
            <li class="tab-option active">CoopApp</li>
          </ul>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="page-container">
    <div class="form-container styled-two">
      <form [formGroup]="productForm" (submit)="save()">
        <div class="wrapper">
          <div class="form-group" controlErrorContainer>
            <coop-image-handler
              [imageUrl]="imageUrl"
              [hasImages]="hasImage"
              [singleCropper]="true"
              [disableUpload]="false"
              [isRectangle]="false"
              [forShop]="true"
              (image)="getImagesUrl($event)"
              (processing)="imageProcessing($event)"
            ></coop-image-handler>
            <input
              formControlName="largeImage"
              [customErrors]="validationMessages['largeImage']"
              hidden
            />
          </div>
          <div class="row form-group" controlErrorContainer>
            <label for="product-category">Kategori</label>
            <div class="category-picker">
              <div class="select-box">
                <input
                  id="product-category"
                  readonly
                  type="text"
                  formControlName="category"
                  [customErrors]="validationMessages['category']"
                  (click)="selectBox()"
                  autocomplete="none"
                />
              </div>
            </div>
          </div>
          <div class="row form-group" controlErrorContainer>
            <label for="product-title">Overskrift</label>
            <input
              id="product-title"
              type="text"
              formControlName="title"
              [customErrors]="validationMessages['title']"
            />
          </div>
          <div class="row form-group" controlErrorContainer>
            <label for="product-text">Tekst</label>
            <textarea
              name=""
              id="product-text"
              formControlName="description"
              [customErrors]="validationMessages['description']"
            ></textarea>
          </div>
          <div class="row form-group price-form-group" controlErrorContainer>
            <label for="product-price"
              >Pris
              <span>per vare/måltid (Angiv salgsprisen inkl. moms)</span></label
            >
            <input
              type="text"
              id="product-price"
              formControlName="price"
              (keydown)="validateNumber($event)"
              [customErrors]="validationMessages['price']"
            />
          </div>
          <div class="push-container">
            <div class="row form-group switches">
              <label><br />Total lagerbeholdning er ubegrænset</label>
              <div>
                <h4 class="stk"></h4>
                <label class="switch">
                  <input
                    type="checkbox"
                    formControlName="totalOfferUnlimited"
                    (click)="updateOfferValidation()"
                  />
                  <span class="toggle round"></span>
                </label>
              </div>
            </div>
          </div>
          <div
            class="row form-group total-offer-form-group"
            controlErrorContainer
          >
            <label for="total-offer"
              >Antal <span>(Total lagerbeholdning)</span></label
            >
            <input
              type="number"
              id="total-offer"
              formControlName="totalOffer"
              [class.disabled-input]="productForm.value['totalOfferUnlimited']"
              [checkParent]="true"
              [customErrors]="validationMessages['totalOffer']"
            />
          </div>
          <div class="push-container">
            <div
              class="row form-group switches infinite-per-day"
              controlErrorContainer
            >
              <label><br />Antal bestillinger per dag er ubegrænset</label>
              <div>
                <h4 class="stk"></h4>
                <label class="switch">
                  <input
                    type="checkbox"
                    formControlName="offerPerDayUnlimited"
                    (click)="updatePerDayValidation($event)"
                  />
                  <span class="toggle round"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="row form-group" controlErrorContainer>
            <label for="offer-per-day">Maks. antal bestillinger per dag</label>
            <input
              type="number"
              id="offer-per-day"
              formControlName="offerPerDay"
              [checkParent]="true"
              [customErrors]="validationMessages['offerPerDay']"
              [class.disabled-input]="productForm.value['offerPerDayUnlimited']"
            />
          </div>
          <div class="row form-group" controlErrorContainer>
            <label for="production-time"
              >Produktionstid
              <span>(Hvor lang tid tager varen at gøre klar)</span></label
            >
            <div class="production-time-picker">
              <div class="select-box">
                <input
                  id="production-time"
                  mbsc-datepicker
                  [mbscOptions]="timePickerSettings"
                  formControlName="productionTime"
                  [customErrors]="validationMessages['productionTime']"
                  type="text"
                  placeholder="Input timer"
                />
              </div>
            </div>
          </div>
          <div class="row form-group" controlErrorContainer>
            <div class="min-order-form-group">
              <div class="min-prod-label">
                <label for="min-order"
                  >Dette produkt kan bestilles ved køb af min. (stk.)</label
                >
              </div>
              <div class="min-order-input">
                <div class="min-order-picker">
                  <a class="flex-item" (click)="subtractItems()">
                    <img src="assets/icons/minus-icon-green.png" alt="" />
                  </a>
                  <div class="hours-after flex-item maxOrderCount">
                    <input
                      type="number"
                      id="min-order"
                      formControlName="minimumOrder"
                      [checkParent]="true"
                      [customErrors]="validationMessages['minimumOrder']"
                      readonly
                    />
                  </div>
                  <a class="flex-item" (click)="addItems()">
                    <img src="assets/icons/plus-icon-green.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="push-container">
            <div class="row form-group switches">
              <label><br />Aktiver produktet i COOP appen</label>
              <div>
                <h4 class="stk"></h4>
                <label class="switch">
                  <input
                    type="checkbox"
                    formControlName="productActive"
                    (click)="changeStatus($event)"
                  />
                  <span class="toggle round"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="push-container">
            <div class="row form-group switches">
              <label
                ><br />Tillad kommentarer til bestillingen af produktet</label
              >
              <div>
                <h4 class="stk"></h4>
                <label class="switch">
                  <input type="checkbox" formControlName="allowComments" />
                  <span class="toggle round"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="push-container">
            <div class="row form-group switches">
              <label
                >Specifik afhentningsperiode
                <span>(off betyder tilgængeligt hele tiden)</span></label
              >
              <div>
                <h4 class="stk"></h4>
                <label class="switch">
                  <input
                    type="checkbox"
                    formControlName="pickUpPeriod"
                    (click)="addDeleteStartEnd($event)"
                  />
                  <span class="toggle round"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="this.productForm.value['pickUpPeriod']">
            <h2 class="form-subtitle">Afhentningsperiode</h2>
            <div
              class="col-two form-group"
              formGroupName="pickUp"
              controlErrorContainer
            >
              <label>Afhent fra</label>
              <div class="date-time-picker">
                <div class="select-box">
                  <input
                    id="start-date"
                    mbsc-datepicker
                    [mbscOptions]="datePickerSettings"
                    formControlName="StartDate"
                    [checkParent]="true"
                    [customErrors]="validationMessages['StartDate']"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div
              class="col-two form-group"
              formGroupName="pickUp"
              controlErrorContainer
            >
              <label>Afhent til</label>
              <div class="date-time-picker">
                <div class="select-box">
                  <input
                    id="end-date"
                    type="text"
                    mbsc-datepicker
                    [mbscOptions]="datePickerSettings"
                    formControlName="EndDate"
                    [checkParent]="true"
                    [customErrors]="validationMessages['EndDate']"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="wrapper">
          <div class="row">
            <button type="submit" class="login-btn btn-black-login">Gem</button>
          </div>
          <div
            class="row"
            *ngIf="
              editMode &&
              !product.ordered &&
              !product.productActive &&
              !product.activeCart
            "
          >
            <a (click)="delete()" class="login-btn btn-white-login ">Slet</a>
          </div>
        </div>
      </form>
    </div>
    <!-- select box -->
    <div class="select-box-modal" *ngIf="showSelectBox">
      <div class="select-box-container">
        <div class="select-box-content">
          <div class="select-box-category-selection">
            <ul>
              <ng-container
                *ngFor="let category of categoryList; let i = index"
              >
                <li
                  class="select-category"
                  [class.category-selected]="tempSelectedCategory == i"
                  (click)="tempSelectedCategory = i"
                >
                  {{ category }}
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
        <div class="wrapper select-btn">
          <div class="row">
            <button
              class="login-btn btn-black-login"
              (click)="categorySelection(tempSelectedCategory)"
            >
              Vælg kategori
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- product inactive popup -->
    <div class="select-box-modal" *ngIf="showDeactivate">
      <div class="select-box-container">
        <div class="close" (click)="showDeactivate = false">
          <i class="icon icon-close-circle"></i>
        </div>
        <div class="select-box-content deactivate-content">
          <div class="select-box-title">
            <h4>Antal stk. inkluderet i de åbne kurve</h4>
          </div>
          <div class="select-box-info">
            <p>
              Dette antal viser hvor mange stk. kunder har tilføjet til åbne
              kurve, men endnu ikke bestilt. En inaktivering af dette produkt
              fjerner det ikke fra de åbne kurve - det fjernet det blot fra
              listen af tilgængelige produkter.
            </p>
            <p class="text-bold">{{ product.reservedQuantity }} stk.</p>
          </div>
        </div>
        <div class="wrapper select-btn">
          <div class="row">
            <button class="login-btn btn-white-login" (click)="showDeactivate = false">
              Tilbage
            </button>
          </div>
          <div class="row">
            <button class="login-btn btn-black-login" (click)="deactivateProduct()">
              Deaktiver produktet i COOP appen
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
