import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeWhile } from 'rxjs/operators';

import { animateParent, slideAnimation } from 'app/shared/animations';
import { ErrorhandlerService } from 'app/core/services';
import {
  PrintHistory,
  OrderService,
} from 'app/features/order-and-pickup/order';

@Component({
  selector: 'coop-print-history',
  templateUrl: './print-history.component.html',
  styleUrls: ['./print-history.component.scss'],
  animations: [animateParent, slideAnimation],
})
export class PrintHistoryComponent implements OnInit {
  pageTitle = 'Print Historik';
  printHistory: PrintHistory[];
  allHistory: PrintHistory[];
  page = 1;
  limit = 10;
  showFilters: string = 'inactive';
  currentCategory = 'Alle';
  zIndexFix = false;
  private _subscriptionState = true;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _orderService: OrderService,
    private _errorHandlerService: ErrorhandlerService
  ) {}

  ngOnInit() {
    this.printHistory = this.allHistory =
      this._activatedRoute.snapshot.data['history']['data'];
  }

  loadMore() {
    this.page++;
    this.getPrintHistory()
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (res) =>
          (this.printHistory = this.allHistory.concat(res['data'])),
        error: (err) => this._errorHandlerService.handleError(err),
      });
  }

  showFilterBtns(): void {
    this.showFilters = 'active';
    setTimeout(() => (this.zIndexFix = !this.zIndexFix), 200);
  }

  hideFilterBtns(): void {
    this.showFilters = 'inactive';
    setTimeout(() => (this.zIndexFix = !this.zIndexFix), 100);
  }

  loadPrintHistory(category: string) {
    this.page = 1;
    this.currentCategory = category;
    this.hideFilterBtns();
    this._loadHistory();
  }

  private _loadHistory() {
    this.getPrintHistory()
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (res) => (this.printHistory = res['data']),
        error: (err) => this._errorHandlerService.handleError(err),
      });
  }

  getPrintHistory() {
    if (this.currentCategory === 'Alle') {
      return this._orderService.getPrintHistory(this.page);
    }
    return this._orderService.getPrintHistory(this.page, this.currentCategory);
  }
}
