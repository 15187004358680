<div class="row form-group" [formGroup]="screenForm">
  <div class="wrapper">
    <ul class="tabs two-col upload-tab-option">
      <li
        class="tab-first"
        (click)="uploadTypeSelect(false)"
        [class.active]="!isVideo"
      >
        Billeder
      </li>
      <li
        class="tab-second"
        (click)="uploadTypeSelect(true)"
        [class.active]="isVideo"
      >
        Video
      </li>
      <hr />
    </ul>
    <div
      class="form-group"
      formArrayName="largeImage"
      *ngIf="currentTab === 'screen'"
    >
      <coop-image-handler
        [imageArray]="imageUrl"
        [hasImages]="hasImage"
        [singleCropper]="true"
        [disableUpload]="false"
        [isRectangle]="false"
        [multipleImage]="true"
        [editMode]="modes.editMode || modes.templateMode"
        [videoUrl]="videoUrl"
        [isVideo]="isVideo"
        [hasVideo]="hasVideo"
        [isGif]="isGif"
        [screen]="true"
        [editorOptions]="screenEditorOptions"
        [offerType]="modes.newsMode ? 'nyhed' : offerType"
        (panelType)="changeUploadType($event)"
        (video)="getVideoUrl($event)"
        (imageArr)="getImagesUrl($event)"
        (processing)="imageProcessing($event)"
      ></coop-image-handler>
      <span
        class="error-message"
        *ngIf="displayMessage['largeImage'] || displayMessage['url']"
      >
        {{ displayMessage['largeImage'] || displayMessage['url'] }}
      </span>
    </div>
    <div class="form-group" controlErrorContainer>
      <label>Overskrift</label>
      <input
        type="text"
        maxlength="47"
        (focusin)="onSelect()"
        (focusout)="ondeselect()"
        formControlName="title"
        [customErrors]="validationMessages['title']" />
      <div *ngIf="visible" class="remaining"> Brugte tegn: {{ screenForm.get("title").value.length }} / 99</div>
    </div>
    <div class="row form-group flex-column" controlErrorContainer>
      <label>Tekst</label>
      <coop-mfl-text
        [offerType]="modes.newsMode ? 'nyhed' : offerType"
      ></coop-mfl-text>
      <textarea name="" formControlName="longDescription"
                [customErrors]="validationMessages['longDescription']"></textarea>
    </div>
    <div class="row form-group" controlErrorContainer *ngIf="!modes.newsMode">
      <label
      >{{
          offerType === 'good-price' ? 'Varens pris' : 'Før pris'
        }}
        <ng-container *ngIf="offerType !== 'good-price'"
        ><br /><span>Vejl. udsalgspris</span></ng-container
        >
      </label
      >
      <input
        type="text"
        formControlName="price"
        placeholder="0.00"
        (keydown)="validateNumber($event)"
        [customErrors]="validationMessages['price']"
        [checkParent]="true"
      />
    </div>
    <div
      class="row form-group"
      controlErrorContainer
      *ngIf="!modes.newsMode && offerType !== 'good-price'"
    >
      <label>Tilbudspris</label>
      <input
        type="text"
        formControlName="offerPrice"
        placeholder="0.00"
        (keydown)="validateNumber($event)"
        [customErrors]="validationMessages['offerPrice']"
        [checkParent]="true"
      />
    </div>
    <div class="row form-group" controlErrorContainer *ngIf="!modes.newsMode">
      <label>Vægt <span>per vare</span> eller antal</label>
      <input
        type="text"
        formControlName="weight"
        min="0"
        [customErrors]="validationMessages['weight']"
      />
    </div>
    <div class="row form-group" controlErrorContainer *ngIf="!modes.newsMode">
      <label>Prisbeskrivelse (Kg-/liter pris beskrivelse)</label>
      <input type="text" formControlName="dutyText" maxlength="50" />
    </div>
    <div class="screen-list">
      <label>Skærme</label>
      <div class="screen-container">
        <div class="chosen-screen-list">
          <div *ngFor="let screen of screenList; let j = index" class="screen">
            <div class="row form-group">
              <div class="screen-single-container">
                <span>{{ screen.name }}</span>
                <i
                  class="icon icon-close-circle remove-screen"
                  *ngIf="screenCount > 1"
                  (click)="removeScreen(j)"
                ></i>
              </div>
            </div>
          </div>
        </div>
        <span
          *ngIf="screenCount < initScreenList.length"
          (click)="openScreenDropDown()"
          class="login-btn btn-white-login add-screen-btn"
        ><i [className]="showScreenDropdown ? 'icon icon-close-circle' : 'icon-plus'"></i
        ></span>
      </div>
      <div class="row form-group" controlErrorContainer style="margin: 0">
        <input formControlName="screens" hidden />
      </div>
      <div
        class="add-screen-container screen-container"
        [@fade]="'in'"
        *ngIf="showScreenDropdown && notSelectedScreenList.length !== 0"
      >
        <a
          *ngFor="let screen of notSelectedScreenList; let j = index"
          class="screen"
          (click)="addScreenTemp(screen)"
        >
          <div class="row form-group">
            <div
              class="screen-single-container"
              [class.selected]="tempSelectedScreenList.indexOf(screen) > -1"
            >
              <i class="icon-plus add-screen"></i>
              <span>{{ screen.name }}</span>
            </div>
          </div>
        </a>
        <button type="button" class="btn btn-black" (click)="addScreen()">
          Vælg
        </button>
      </div>
    </div>
    <div class="row" *ngIf="!modes.newsMode">
      <h2 class="form-subtitle">
        {{
          offerType === 'good-price'
            ? 'God Pris Gyldighedsperiode'
            : 'Tilbudsperiode'
        }}
      </h2>
      <div
        class="form-group"
        [ngClass]="modes.newsMode ? 'central' : 'col-two'"
        controlErrorContainer
      >
        <label>Gyldig fra</label>
        <div class="date-time-picker">
          <input
            id="startscreen"
            mbsc-datepicker
            [mbscOptions]="datePickerSettings"
            formControlName="startDate"
            type="text"
            [customErrors]="validationMessages['startDate']"
            [checkParent]="true"
          />
        </div>
      </div>
      <div
        class="col-two form-group"
        controlErrorContainer
        *ngIf="!modes.newsMode"
      >
        <label>Gyldig til</label>
        <div class="date-time-picker">
          <input
            id="endscreen"
            type="text"
            mbsc-datepicker
            [mbscOptions]="datePickerSettings"
            formControlName="endDate"
            [customErrors]="validationMessages['endDate']"
            [checkParent]="true"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <h2 class="form-subtitle">Hvornår skal beskeden vises</h2>
      <div class="col-two form-group" controlErrorContainer>
        <label>Udsendingstidspunkt</label>
        <div class="date-time-picker">
          <input
            id="publishscreen"
            mbsc-datepicker
            [mbscOptions]="datePickerSettings"
            formControlName="publishDate"
            type="text"
            [customErrors]="validationMessages['publishDate']"
            [checkParent]="true"
          />
        </div>
      </div>
      <div class="col-two form-group" controlErrorContainer>
        <label>Afslutningstidspunkt</label>
        <div class="date-time-picker">
          <input
            id="expirescreen"
            type="text"
            mbsc-datepicker
            [mbscOptions]="datePickerSettings"
            formControlName="expirationDate"
            [customErrors]="validationMessages['expirationDate']"
            [checkParent]="true"
          />
        </div>
      </div>
    </div>
  </div>
</div>
