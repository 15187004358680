import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {FormGroup} from '@angular/forms';

import {DateFormatService} from 'app/core/services';
import * as dayjs from "dayjs";

@Injectable({
  providedIn: 'root',
})
export class OfferDateTimeService {
  constructor() {
  }

  static setDefaultTimeOnCreate(offerForm: FormGroup): void {
    const startDate = dayjs().format('YYYY-MM-DD HH:mm');
    const endDate = dayjs().add(6, 'days').format('YYYY-MM-DD HH:mm');
    offerForm.patchValue({
      publishDate: startDate,
      expirationDate: endDate,
      startDate: startDate,
      endDate: endDate,
    });
  }

  static setDefaultTimeOnCreateSMS(smsForm: FormGroup): void {
    const startDate = DateFormatService.timeIntervalFormat(moment());
    const endDate = DateFormatService.timeIntervalFormat(
      moment().add(7, 'days')
    );
    smsForm.patchValue({
      startDate: startDate,
      endDate: endDate,
      sendDate: startDate,
    });
  }

  static setUpDateTime(offerForm: FormGroup, id: string, channel: string): void {

    // set start date at today's time 8:00
    if (
      id.indexOf(`publish${channel}`) === 0 &&
      !offerForm.value.publishDate
    ) {
      offerForm.patchValue({
        publishDate: moment().hour(8).minute(0).toDate(),
      });
    }
    // end date picker clicked
    // if start date is empty set time to today at 20:00
    if (
      id.indexOf(`expire${channel}`) === 0 &&
      !offerForm.value.publishDate
    ) {
      offerForm.patchValue({
        expirationDate: moment().hour(20).minute(0).toDate(),
      });
    }
    if (
      id.indexOf(`expire${channel}`) === 0 &&
      offerForm.value.publishDate &&
      !offerForm.value.expirationDate
    ) {
      const startDate = moment(offerForm.value.publishDate);
      offerForm.patchValue({
        expirationDate: startDate.hour(20).minute(0).toDate(),
      });
    }
    // set start date at today's time 8:00
    if (id.indexOf(`start${channel}`) === 0 && !offerForm.value.startDate) {
      offerForm.patchValue({
        startDate: moment().hour(8).minute(0).toDate(),
      });
    }
    // end date picker clicked
    // if start date is empty set time to today at 20:00
    if (id.indexOf(`end${channel}`) === 0 && !offerForm.value.startDate) {
      offerForm.patchValue({
        endDate: moment().hour(20).minute(0).toDate(),
      });
    }
    if (
      id.indexOf(`end${channel}`) === 0 &&
      offerForm.value.startDate &&
      !offerForm.value.endDate
    ) {
      const startDate = moment(offerForm.value.startDate);
      offerForm.patchValue({
        endDate: startDate.hour(20).minute(0).toDate(),
      });
    }
    // set send sms date at today's time 8:00
    if (id.indexOf(`send-sms`) === 0 && !offerForm.value.sendDate) {
      offerForm.patchValue({
        sendDate: moment().hour(8).minute(0).toDate(),
      });
    }
  }
}
