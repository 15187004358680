import { Injectable } from '@angular/core';
import { TokenDetail, UserDetail } from 'app/shared/interface';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor() {}

  // *** It checks authenticated State ***
  // @returns [boolean] => true: If all value exists in localStorage
  //                      false: If any value is null or coopExpiration is in past
  static checkAuthenticatedState(): boolean {
    const currentTime = Math.floor(Date.now() / 1000);
    return (
      localStorage['cooptoken'] &&
      localStorage['coopexpiration'] &&
      localStorage['_c_u_t_'] &&
      localStorage.getItem('_c_u_t_') !== '' &&
      localStorage.getItem('cooptoken') !== '' &&
      localStorage.getItem('coopexpiration') !== '' &&
      parseInt(localStorage.getItem('coopexpiration'), 10) - 60 * 60 * 24 >
        currentTime
    );
  }

//** set data to local storage
  //@params[logindata, usercode] => token detail and store id
  //if logindata user_type is true => set logindata user_type(store or board) in local storage
  //else set '1' in local storage
  static setlocalstorage(logindata: TokenDetail, usercode) {
    localStorage.setItem('olddataCleared', 'done');
    localStorage.setItem('cooptoken', logindata.access_token);
    localStorage.setItem('webhooktoken', logindata.webhook_token);
    localStorage.setItem('coopexpiration', logindata.expiration.toString());
    localStorage.setItem('coopcode', usercode);
    if (logindata.user_type) {
      localStorage.setItem('_c_u_t_', logindata.user_type.toString());
    } else {
      localStorage.setItem('_c_u_t_', '1');
    }
  }
//** set member id
  //@params[memberId] memberId of board member
  static setMemberId(memberId: string) {
    localStorage.setItem('coopmember', memberId);
  }

  //set sortId
  static  setSortId(id){
    localStorage.setItem('sortId', id)
  }

  // get sort  ID
  static  geTSortId():any{
    return  localStorage.getItem('sortId')
  }

  static setNotificationStatus(status){
    localStorage.setItem('status', status)
  }

  static getNotificationStatus() : boolean{
    return  JSON.parse(localStorage.getItem('status'))
  }

//** set image token
  //@params[token] image token
  static setImageToken(token: string) {
    localStorage.setItem('imagetoken', token);
  }
//**get token from local storage
  static getToken(): string {
    return localStorage.getItem('cooptoken');
  }
//** get webHookToken from localstorage
  static getWebHookToken(): string {
    return localStorage.getItem('webhooktoken');
  }
//**get image token from local storage
  static getImageToken(): string {
    return localStorage.getItem('imagetoken');
  }
//** get userCode from local storage
  static getUserCode(): string {
    return localStorage.getItem('coopcode');
  }
//** get memberId from local storage
  static getMemberId(): string {
    return localStorage.getItem('coopmember');
  }
//** get userType from local storage
  static getUserType(): string {
    return localStorage.getItem('_c_u_t_');
  }
//**get userDetail from local storage
  static getUserDetail(): UserDetail {
    return JSON.parse(localStorage.getItem('_c_u_d_'));
  }
//** set userDetail in local storage
  static setUserDetail(userDetail: UserDetail): void {
    localStorage.setItem('_c_u_d_', JSON.stringify(userDetail));
  }
//**set defaultLogin
  static usingDefaultLogin() {
    localStorage.setItem('_c_l_t_', 'true');
  }

//** remove token from local storage
  static removeAllTokens() {
    localStorage.removeItem('cooptoken');
    localStorage.removeItem('webhooktoken');
    localStorage.removeItem('coopexpiration');
    localStorage.removeItem('coopcode');
    localStorage.removeItem('_c_u_d_');
    localStorage.removeItem('_c_u_t_');
    localStorage.removeItem('_c_l_t_');
    localStorage.removeItem('_c_a_l_');
    localStorage.removeItem('coopmember');
    sessionStorage.removeItem('checkFbToken');
    localStorage.clear();
  }


}
