import { Injectable } from "@angular/core";
import { EMPTY, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ApiService } from "app/core/services";

@Injectable({
  providedIn: "root"
})
export class SmsService {
  constructor(private _apiService: ApiService) {
  }

  sendSms(params: any): Observable<any> {
    return this._apiService.post("coopsms", ApiService.CreateFormData(params));
  }

  sendSingleSms(params: any, editMode: boolean): Observable<any> {
    if (!editMode) {
      return this._apiService.post(
        "coopsms",
        ApiService.CreateFormData(params)
      );
    }
    if (editMode) {
      //TODO: this is a put
      return this._apiService.put(
        `coopsms/${params["id"]}`,
        ApiService.CreateFormData(params)
      );
    }
    return EMPTY;
  }

  deleteSms(id: string) {
    return this._apiService.del(`coopsms/${id}`);
  }

  getSingleSms(id: string) {
    return this._apiService
      .get(`coopsms/${id}`)
      .pipe(map((res) => res["item"]));
  }

  getSingleSmsLists(
    clubId: string,
    from: string,
    to: string,
    page: number,
    filter: number
  ) {
    return this._apiService
      .get(
        `coopsms?clubId=${clubId}&from=${from}&to=${to}&page=${page}&filter=${filter}`
      )
      .pipe(map((res) => res["items"]));
  }

  saveMemberMobile(params: {}): Observable<any> {
    return this._apiService.post(
      "sms/add-members",
      ApiService.CreateFormData(params)
    );
  }

  getMemberMobile(mobile: any) {
    return this._apiService.get(`sms/search-members?phone=${mobile}`);
  }

  deleteMember(params: any): Observable<any> {
    return this._apiService.post(
      "sms/delete-member",
      ApiService.CreateFormData(params)
    );
  }

  editStandardMessage(params: any) {
    return this._apiService.post(
      "sms/update-standard-text",
      ApiService.CreateFormData(params)
    );
  }
}
