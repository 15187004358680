import { inject, Injectable } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CreateOptionDataService } from 'app/core/services/createoptiondata.service';
// ***   ***
// Checks createOption resolves respective routes if channels are selected
// @returns boolean =>  true:    Route is success
//                      false:   Route is '../'

@Injectable({
  providedIn: 'root'
})
export class CreateOptionGuardService {
  constructor(
    private _router: Router,
    private _createOptionService: CreateOptionDataService
  ) {
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this._createOptionService.currentSetting.pipe(
      map((setting) => {
        if (setting === undefined || !setting.isSet) {
          this._router.navigate(['../']).then();
          return false;
        }
        return true;
      })
    );
  }
}

export const CreateOptionGuard: CanActivateFn = () => {
  return inject(CreateOptionGuardService).canActivate();
};
