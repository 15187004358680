import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { takeWhile } from 'rxjs/operators';

import { animateParent } from 'app/shared/animations';
import { DateWithSeconds } from 'app/shared/pipes';
import { ErrorhandlerService, NotificationsService } from 'app/core/services';
import { OrderService } from 'app/features/order-and-pickup/order';
import { MbscDatepickerOptions } from '@mobiscroll/angular';
import { COMMON_DATEPICKER_SETTINGS } from 'app/shared/data';
import { DatepickerBase } from '@mobiscroll/angular/dist/js/core/components/datepicker/datepicker';

@Component({
  selector: 'coop-print-range-select',
  templateUrl: './print-range-select.component.html',
  styleUrls: ['./print-range-select.component.scss'],
  animations: [animateParent],
  providers: [DateWithSeconds],
})
export class PrintRangeSelectComponent implements OnInit, OnDestroy {
  @Output() rangeSelectEvent = new EventEmitter<boolean>();
  pageTitle: string = 'Print';
  previousUrl: string;
  dateRangeSelectForm: FormGroup;
  isProcessing: boolean = false;
  datePickerSettings: MbscDatepickerOptions = {
    ...COMMON_DATEPICKER_SETTINGS, ...{
      dateWheels: 'YYYY MMMM DD',
      dateFormat: 'YYYY-MM-DD',
      onOpen: (_, inst) => this._setUpDate(inst)
    }
  };

  private _subscriptionState: boolean = true;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _errorHandlerService: ErrorhandlerService,
    private _orderService: OrderService,
    private _formBuilder: FormBuilder,
    private _dateWithSeconds: DateWithSeconds
  ) {}

  ngOnInit() {
    this.previousUrl = '/butikker/order';
    this.dateRangeSelectForm = this._formBuilder.group({
      startDate: [''],
      endDate: [''],
    });
  }

  ngOnDestroy() {
    this._subscriptionState = false;
  }

  hideRangeSelect() {
    this.rangeSelectEvent.emit(false);
  }

  print() {
    if (!this.isProcessing) {
      this.isProcessing = true;
      this.dateRangeSelectForm.patchValue({
        startDate: moment(this.dateRangeSelectForm.value.startDate)
          .hour(0)
          .minute(0)
          .second(0)
          .toDate(),
        endDate: moment(this.dateRangeSelectForm.value.endDate)
          .hour(23)
          .minute(59)
          .second(59)
          .toDate(),
      });
      this.dateRangeSelectForm.value.startDate =
        this._dateWithSeconds.transform(
          this.dateRangeSelectForm.value.startDate
        );
      this.dateRangeSelectForm.value.endDate = this._dateWithSeconds.transform(
        this.dateRangeSelectForm.value.endDate
      );
      this._orderService
        .printOrders(
          this.dateRangeSelectForm.value.startDate,
          this.dateRangeSelectForm.value.endDate
        )
        .pipe(takeWhile(() => this._subscriptionState))
        .subscribe({
          next: () => this._success(),
          error: (err) => this._handleError(err),
        });
    }
  }

  // date time value set for mobiscroll date-time
  private _setUpDate(inst: DatepickerBase) {
    const dateId = inst._el.id;
    // set start date at today's time 00:01
    if (
      (dateId.indexOf('start') === 0 || dateId.indexOf('end') === 0) &&
      !this.dateRangeSelectForm.value.startDate &&
      !this.dateRangeSelectForm.value.endDate
    ) {
      this.dateRangeSelectForm.patchValue({
        startDate: moment().toDate(),
        endDate: moment().add(1, 'day').toDate(),
      });
    }
  }

  private _success(): void {
    this.isProcessing = false;
    NotificationsService.notify('Afsluttet med success', 'success', 'top');
    this.rangeSelectEvent.emit(false);
  }

  private _handleError(error) {
    this._errorHandlerService.handleError(error || { code: -400 }, 'order');
    this.isProcessing = false;
  }
}
