import { NyhedTilbud } from '../interface';

export const INITIAL_FORM_DATA: NyhedTilbud = {
  id: '',
  shared: {
    facebook: false,
    coop: false,
    sms: false,
    mail: false,
    web: false,
    dit: false,
  },
  coop: {
    endDate: '',
    expirationDate: '',
    inAppParameters: { price: null, offerPrice: null, priceDescription: '' ,uom : ''},
    longDescription: '',
    publishDate: '',
    push: null,
    startDate: '',
    title: '',
    largeImage: '',
    thumbnail: '',
    video: { thumbnail: null, url: null },
    isSet: false,
  },
  facebook: {
    endDate: '',
    expirationDate: '',
    inAppParameters: { price: null, offerPrice: null, priceDescription: '',uom : '' },
    longDescription: '',
    publishDate: '',
    startDate: '',
    title: '',
    largeImage: [''],
    video: { thumbnail: null, url: null },
    isSet: false,
  },
  sms: {
    sendDate: '',
    inAppParameters: { price: null, offerPrice: null, priceDescription: '',uom : '' },
    longDescription: '',
    title: '',
    thumbnail: '',
    video: { thumbnail: null, url: null },
    smsText: '',
    solutions: '',
    isSet: false,
  },
  mail: {
    endDate: '',
    expirationDate: '',
    inAppParameters: { price: null, offerPrice: null, priceDescription: '' ,uom : ''},
    shortDescription: '',
    longDescription: '',
    publishDate: '',
    startDate: '',
    title: '',
    thumbnail: '',
    video: { thumbnail: null, url: null },
    mailId: '',
    isSet: false,
  },
  web: {
    endDate: '',
    expirationDate: '',
    inAppParameters: { price: null, offerPrice: null, priceDescription: '' ,uom : '' },
    longDescription: '',
    publishDate: '',
    startDate: '',
    title: '',
    largeImage: [''],
    video: { thumbnail: null, url: null },
    isSet: false,
  },
  screen: {
    endDate: '',
    expirationDate: '',
    longDescription: '',
    publishDate: '',
    startDate: '',
    title: '',
    weight: null,
    dutyText: '',
    price: null,
    offerPrice: null,
    largeImage: [''],
    priceTag: '',
    coop365: null,
    screens: '',
    video: { thumbnail: null, url: null },
    isSet: false,
  },
  dit: {
    endDate: '',
    expirationDate: '',
    longDescription: '',
    publishDate: '',
    startDate: '',
    title: '',
    largeImage: '',
    weight: null,
    dutyText: '',
    uom : '',
    price: null,
    offerPrice: null,
    isSet: false,
  },
};
