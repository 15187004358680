import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConnectComponent } from './components/connect/connect.component';

@NgModule({
  declarations: [ConnectComponent],
  imports: [CommonModule],
  exports: [ConnectComponent],
})
export class FacebookConnectModule {}
