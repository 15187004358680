import {
  animate,
  style,
  transition,
  trigger,
  query,
  stagger,
} from '@angular/animations';
// created animation variable 'commentAnimation'
//transition can go from any to any state
//query looks for an element leaving the page and stagger delay each animation by 300ms
//animate each element by 200ms transition time and style opacity 0 .
// query looks for an element entering DOM 
//each element is initially set to opacity 0 and stagger delay each animation by 300ms 
//animate each entered element to opacity 1 with 200ms transition time
export const commentAnimation = trigger('commentListAnimation', [
  transition('* => *', [
    // each time the binding value changes
    query(':leave', [stagger(300, [animate('200ms', style({ opacity: 0 }))])], {
      optional: true,
    }),
    query(
      ':enter',
      [
        style({ opacity: 0 }),
        stagger(300, [animate('200ms', style({ opacity: 1 }))]),
      ],
      { optional: true }
    ),
  ]),
]);
