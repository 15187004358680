<div class="form-group" [formGroup]="ditForm">
  <div class="wrapper">
    <div class="form-group" controlErrorContainer>
      <coop-image-handler
        [imageUrl]="imageUrl"
        [hasImages]="hasImage"
        [singleCropper]="true"
        [disableUpload]="false"
        [isPostedToFb]="isPostedToFb"
        [editMode]="modes.editMode || modes.templateMode"
        [offerType]="modes.newsMode ? 'nyhed' : offerType"
        (image)="getImagesUrl($event)"
        (processing)="imageProcessing($event)"
      ></coop-image-handler>
      <input
        formControlName="largeImage"
        [customErrors]="validationMessages['largeImage']"
        hidden
      />
    </div>

    <div class="section-wrapper">

      <div class="title">
        Post tekst
      </div>

      <div class="form-group" controlErrorContainer>
        <label class="margin-bottom-8">Overskrift</label>
        <input
          type="text"
          formControlName="title"
          (focusin)="onSelect()"
          (focusout)="ondeselect()"
          [customErrors]="validationMessages['title']"/>
        <div *ngIf="visible" class="remaining"> Brugte tegn: {{ ditForm.get("title").value.length }} / 99</div>
      </div>
      <div class="row form-group flex-column" controlErrorContainer>
        <label class="margin-bottom-8">Tekst</label>
        <coop-mfl-text
          [offerType]="modes.newsMode ? 'nyhed' : offerType"
        ></coop-mfl-text>
        <textarea name="" formControlName="longDescription"
                  [customErrors]="validationMessages['longDescription']"></textarea>
      </div>
    </div>
    <div class="hr"></div>

    <div class="section-wrapper" *ngIf="!modes.newsMode">
      <div class="flex-display">
        <div class="title">
          Prisoplysninger
        </div>
<!--        <div class="tooltip-icon">-->
<!--                    <span-->
<!--                      tooltip="Pris og mængde bliver indsat lige under overskriften."-->
<!--                      placement="top"-->
<!--                      delay="500"><i class="icon-info-circle-filled"></i></span>-->
<!--        </div>-->
      </div>


      <div *ngIf="!modes.newsMode" class="form-group" controlErrorContainer>

        <div *ngIf="offerType !== 'good-price'" class="label-wrapper">
          <div>
            <label class="margin-bottom-8">
              Før pris <span>(vejl. udsalgspris)</span>
            </label>
          </div>
          <div class="tooltip-icon">
                    <span
                      tooltip="Pris og mængde bliver indsat lige under overskriften."
                      placement="top"
                      delay="500"><i class="icon-info-circle-filled"></i></span>
          </div>
        </div>
        <div *ngIf="showPriceSection" class="price-description-wrapper">
          <div class="price-container">
            <div>
              <input

                type="text"
                formControlName="price"
                [customErrors]="validationMessages['price']"
                min="0"
                placeholder="Pris"
                (keydown)="validateNumber($event)"
              />
            </div>
            <div style="margin:10px;min-width: fit-content">
              kr. pr.
            </div>
          </div>

          <div class="price-description-container">
            <div style="margin-right: 10px" class="price-description">
              <input

                type="text"
                formControlName="dutyText"
                [customErrors]="validationMessages['dutyText']"
                min="0"
                placeholder="vægt/mængde"
                (keydown)="validateNumber($event)"
              />
            </div>

            <div #input class="price-limit">
              <select
                formControlName="uom"
                mbsc-select
                [mbscOptions]="priceDescSelectSettings"
                class="form-select">
              </select>
              <div class="dropdown-icon"><i class="icon-arrow-down"></i></div>
            </div>
            <div *ngIf=" offerType === 'good-price' && showPriceSection" style="cursor: pointer;margin-top: 8px"
                 (click)="removePrices()">
              <i class="icon icon-close-circle" style="color: #D10A11;font-size: 24px"></i>
            </div>
          </div>

        </div>

        <div *ngIf="!showPriceSection" class="row addvarient" (click)="addPriceField()">
          <a class="login-btn btn-white-login button-wrapper">
            <div style="margin-right: 10px"><i class="icon-add-circle"></i></div>
            <div>Tilføj pris</div>
          </a>
        </div>
      </div>
    </div>

    <div class="hr"></div>
    <div class="section-wrapper">
      <div class="title">
        Planlægning
      </div>


      <div *ngIf="!modes.newsMode">
        <h2 class="form-subtitle">
          {{
          offerType === 'good-price'
            ? 'God Pris Gyldighedsperiode'
            : 'Tilbudsperiode'
          }}
        </h2>
        <div *ngIf="currentTab === 'dit'" class="form-group">
          <coop-date-time-range-picker
            [datePickerData]="startEndDatePicker"
            (onDateTimeChange)="getStartDateTime($event)"
          ></coop-date-time-range-picker>
        </div>

      </div>
      <div>
        <h2 class="form-subtitle">Hvornår skal beskeden vises</h2>
        <div *ngIf="currentTab === 'dit'" class="form-group" controlErrorContainer>
          <coop-date-time-range-picker
            [datePickerData]="publishStartEndPicker"
            (onDateTimeChange)="getPublishExpirationDateTime($event)"
          ></coop-date-time-range-picker>

        </div>
      </div>

    </div>
  </div>
</div>
