import {Component, Input, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import {
  Summary
} from 'app/features/jatak/interface';
@Component({
  selector: 'coop-order-list-oversight',
  templateUrl: './order-list-oversight.component.html',
  styleUrls: ['./order-list-oversight.component.scss'],
})
export class OrderListOversightComponent implements OnInit , OnChanges {
  @Input() summaryList : Summary ;
  @Input() tab:number
  showOversight:boolean = false
  constructor(){}

   ngOnInit() {
   }

  ngOnChanges(changes:SimpleChanges){
     if(changes['summaryList']){
       this.summaryList = changes['summaryList'].currentValue
     }
  }

  toggleOversight(){
    this.showOversight = !this.showOversight
  }

}

