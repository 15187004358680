<section
  class="app-page list-component order-edit page-with-fixed-header not-print"
>
  <div class="fixed-header">
    <coop-pageheader
      [pageTitle]="pageTitle"
      [isChild]="true"
      [routeTo]="previousUrl"
    ></coop-pageheader>
  </div>
  <div class="page-container">
    <div class="item-list">
      <ng-container *ngFor="let product of order.data.orders; let i = index">
        <div class="item">
          <div class="item-detail">
            <div class="item-order d-flex">
              <div>
                <h3 class="item-title">{{ product.quantity }}x</h3>
                <h3 class="item-title product-title">
                  {{ product.productTitle }}
                </h3>
              </div>
              <h3 class="item-title">
                {{ product.price | numberpad : 'da-DA' }}
              </h3>
            </div>
            <p class="item-order-message">{{ product.message }}</p>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="wrapper order-details-wrapper">
      <div class="order-details">
        <div class="d-flex order-booking-date">
          <span>Bestillingsdato</span>
          <span>{{ order.data.bookingDate | dateformatddmmyy }}</span>
        </div>
        <div class="d-flex order-pickup-date">
          <span>Afhentningsdato</span>
          <span class="pickup-date">{{
              order.data.pickupDate | dateformatddmmyy
            }}</span>
        </div>
        <div
          class="d-flex order-approved-date"
          *ngIf="order.data.orderStatus === 0 || order.data.orderStatus === -1"
        >
          <span>Godkendt den</span>
          <span>{{ order.data.approvedDate | dateformatddmmyy }}</span>
        </div>
        <div class="d-flex order-phone">
          <span>Telefonnummer</span>
          <a class="phone-number" href="tel:+45{{ order.data.phoneNumber }}"
          >Ring til {{ order.data.phoneNumber }}</a
          >
        </div>
        <div class="d-flex order-status" *ngIf="orderFinished">
          <span>Status</span>
          <span>{{ deliveryStatus[order.data.deliveryStatus] }}</span>
        </div>
      </div>
    </div>
    <div class="form-container styled-two">
      <form [formGroup]="orderMessageForm" (submit)="save()" id="message-form">
        <div class="wrapper">
          <div
            class="row form-group"
            *ngIf="
              !orderFinished &&
              order.data.orderStatus !== 2 &&
              order.data.orderStatus !== -1
            "
            controlErrorContainer
          >
            <label for="order-comment"
            >Kommentar til ordren
              <span>(Kunden modtager altid en sms)</span></label
            >
            <textarea
              name=""
              id="order-comment"
              formControlName="comment"
              maxlength="300"
            ></textarea>
          </div>
        </div>
      </form>
      <form
        [formGroup]="notificationMessageForm"
        *ngIf="showSendNotification"
        id="notification-form"
      >
        <div class="wrapper">
          <div formArrayName="notificationMessage" [@list]>
            <div
              *ngFor="
                let notification of notificationMessageForm.get(
                  'notificationMessage'
                )['controls'];
                let i = index
              "
              [@items]
            >
              <ng-container [formGroupName]="i">
                <div class="d-flex">
                  <h3 class="notification-title">Påmindelse {{ i + 1 }}</h3>
                  <span
                  >planlagt til
                    {{ notification.value.sent_date | dateformatddmmyy }}</span
                  >
                </div>
                <div class="row form-group" controlErrorContainer>
                  <label for="send-notification-{{ i + 1 }}"></label>
                  <textarea
                    name=""
                    id="send-notification-{{ i + 1 }}"
                    formControlName="notification"
                    maxlength="300"
                  ></textarea>
                </div>
                <div class="row form-group">
                  <div class="date-time-picker">
                    <div class="date-select-box">
                      <button
                        type="button"
                        class="login-btn btn-white-login"

                        formControlName="sent_date"
                        id="select-notification-{{ i + 1 }}"
                        mbsc-datepicker
                        [mbscOptions]="datePickerSettings"
                      >
                        Sæt påmindelse {{ i + 1 }}
                      </button>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </form>
      <div class="wrapper">
        <div
          class="order-message-list"
          *ngIf="
            order.data.orderStatus === -1 && order.data.notificationMessage
          "
        >
          <ng-container
            *ngFor="
              let notification of order.data.notificationMessage;
              let i = index
            "
          >
            <div class="message">
              <div class="message-detail">
                <div class="message-date-container">
                  <div class="d-flex">
                    <span class="message-date">Påmindelse {{ i + 1 }}</span>
                    <span class="message-date">{{
                        notification.sent_date | dateformatddmmyy
                      }}</span>
                  </div>
                </div>
                <div class="message-container">
                  <span style="white-space: pre-line; word-break: break-all">{{
                      notification.notification
                    }}</span>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div
          class="order-message-list"
          *ngIf="
            order.data.orderStatus === 2 ||
            order.data.orderStatus === 0 ||
            order.data.orderStatus === -1 ||
            orderFinished
          "
          [@list]
        >
          <div *ngFor="let message of order.data.sentComment" [@items]>
            <div class="message">
              <div class="message-detail">
                <div class="message-date-container">
                  <span class="message-date"
                  >Kommentar {{ message.sent_date | dateformatddmmyy }}</span
                  >
                </div>
                <div class="message-container">
                  <span style="white-space: pre-line; word-break: break-all">{{
                      message.comment
                    }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="order-info"
          *ngIf="order.data.orderStatus === 1 && !orderFinished"
        >
          <p>En kvittering vil blive printet når du accepterer ordren.</p>
        </div>
        <div
          class="order-info"
          *ngIf="order.data.orderStatus === 2 && !orderFinished"
        >
          <p>Når Tilberedes vil penge trækkes fra kunden.</p>
        </div>
      </div>
      <div class="wrapper">
        <div class="row" *ngIf="order.data.orderStatus === 1 && !orderFinished">
          <input
            type="submit"
            value="Godkend bestilling"
            class="login-btn btn-black-login"
            form="message-form"
          />
        </div>
        <div class="row" *ngIf="order.data.orderStatus === 2 && !orderFinished">
          <input
            type="submit"
            value="Tilbered"
            class="login-btn btn-black-login"
            form="message-form"
          />
        </div>
        <div class="row" *ngIf="order.data.orderStatus === 0 && !orderFinished">
          <input
            type="submit"
            value="Klar til afhentning"
            class="login-btn btn-black-login"
            form="message-form"
          />
        </div>
        <div
          class="row"
          *ngIf="order.data.orderStatus !== -1 && !orderFinished"
        >
          <a class="login-btn btn-white-login " (click)="cancel()"
          >Annuller</a
          >
        </div>
        <div
          class="row"
          *ngIf="
            order.data.orderStatus === -1 &&
            !showSendNotification &&
            !notificationSent &&
            !orderFinished
          "
        >
          <a class="login-btn btn-white-login " (click)="setNotification()"
          >Planlæg flere påmindelser</a
          >
        </div>
        <div
          class="row"
          *ngIf="
            order.data.orderStatus === -1 &&
            showSendNotification &&
            !orderFinished &&
            notificationCount > 0
          "
        >
          <input
            type="submit"
            class="login-btn btn-white-login "
            (click)="sendNotification()"
            form="notification-form"
            value="Gem"
          />
        </div>
        <div
          class="row"
          *ngIf="order.data.orderStatus === -1 && !orderFinished"
        >
          <a class="login-btn btn-black-login" (click)="smsBox(true)">Afhentet</a>
        </div>
        <div
          class="row"
          *ngIf="order.data.orderStatus === -1 && !orderFinished"
        >
          <a class="login-btn btn-white-login" (click)="smsBox(false)"
          >Ikke afhentet</a
          >
        </div>
        <div
          class="row"
          *ngIf="order.data.deliveryStatus === 1 && orderFinished"
        >
          <a class="login-btn btn-white-login btn-delete" (click)="clearOrder()"
          >Afsluttet uden afhentning</a
          >
        </div>
        <div class="row" *ngIf="order.data.orderStatus === 2 && !orderFinished">
          <button
            type="button"
            class="login-btn btn-white-login print-btn"
            (click)="print(order.data.id)"
          >
            <i class="ti-printer"></i>Print
          </button>
        </div>
      </div>
    </div>
    <!-- sms box -->
    <div class="sms-box-modal" *ngIf="showSmsBox">
      <div class="sms-box-container">
        <div class="close" (click)="showSmsBox = false">
          <i class="icon icon-close-circle"></i>
        </div>
        <div class="sms-box-content">
          <div class="sms-box-title" [class.not-picked-up-title]="!pickedUp">
            <span *ngIf="pickedUp">Ordren er afhentet</span>
            <span *ngIf="!pickedUp">Ordren er ikke afhentet</span>
          </div>
          <div class="sms-box-message">
            <div class="sms-box-info">
              <p class="picked-up" *ngIf="pickedUp">
                Send en besked til kunden som tak for ordren.
              </p>
              <p class="not-picked-up" *ngIf="!pickedUp">
                Send en besked til kunden.
              </p>
              <p class="not-picked-up" *ngIf="!pickedUp">
                Husk at pengene blev trukket da Produktionen startede, så
                refundering skal ske i butikken.
              </p>
            </div>
            <form
              class="sms-box-input"
              [formGroup]="smsMessageForm"
              id="pickup-form"
              (submit)="sendSms()"
            >
              <div class="row form-group" controlErrorContainer>
                <label for="sms-message"></label>
                <textarea
                  name=""
                  id="sms-message"
                  formControlName="sms"
                  maxlength="300"
                ></textarea>
              </div>
            </form>
          </div>
        </div>
        <div class="wrapper send-btn">
          <div class="row">
            <input
              type="submit"
              class="login-btn btn-black-login"
              (click)="sendSms()"
              form="pickup-form"
              value="Send besked og luk ordren"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- cancel order popup -->
    <div class="sms-box-modal" *ngIf="cancelOrder">
      <div class="sms-box-container">
        <div class="close" (click)="cancel()">
          <i class="icon icon-close-cirlce"></i>
        </div>
        <div class="sms-box-content cancel-box-content">
          <div class="sms-box-title cancel-box-title">
            <span>{{ pageTitle }}</span>
          </div>
          <div class="sms-box-message">
            <div
              class="sms-box-info"
              [ngClass]="order.data.orderStatus === 0 ? 'text-center' : ''"
            >
              <p *ngIf="order.data.orderStatus !== 0">
                Send en besked til kunden, der fortæller hvorfor ordren aflyses.
              </p>
              <p *ngIf="order.data.orderStatus !== 0">
                Kunden vil automatisk få pengene tilbage ved dette stadie.
              </p>
              <p *ngIf="order.data.orderStatus === 0">
                Send en besked til kunden, der fortæller hvorfor ordren aflyses.
                Husk at pengene blev trukket da Produktionen startede, så
                refundering skal ske i butikken.
              </p>
            </div>
            <form
              class="sms-box-input"
              [formGroup]="cancelOrderForm"
              id="cancel-order-form"
              (submit)="saveCanceledOrder()"
            >
              <div class="row form-group" controlErrorContainer>
                <label for="cancel-order-comment"></label>
                <textarea
                  name=""
                  id="cancel-order-comment"
                  formControlName="comment"
                  maxlength="300"
                ></textarea>
              </div>
            </form>
          </div>
        </div>
        <div class="wrapper send-btn">
          <div class="row">
            <a class="login-btn btn-white-login" (click)="cancel()"
            >Tilbage til ordren</a
            >
          </div>
          <div class="row">
            <input
              class="login-btn btn-black-login"
              (click)="saveCanceledOrder()"
              type="submit"
              form="cancel-order-form"
              value="Send besked og luk ordren"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- select notification popup -->
    <div class="sms-box-modal" *ngIf="showNotifyPicker">
      <div class="sms-box-container">
        <div class="sms-box-content notification-box-content">
          <div class="sms-box-number">
            <div class="sms-box-info">
              <p>Vil du sætte flere påmindelser?</p>
            </div>
            <div class="sms-box-input">
              <div class="hours-picker">
                <button
                  class="btn-input big-add-btn-left minus-btn"
                  (click)="subtractNumber()"
                  [disabled]="tempSelectedNotify === 1"
                >
                  <i class="ti-minus"></i>
                </button>
                <div class="hours-after">
                  <span>{{ tempSelectedNotify }}</span>
                </div>
                <button
                  class="btn-input big-add-btn add-btn flex-center"
                  (click)="addNumber()"
                  [disabled]="tempSelectedNotify === 2"
                >
                  <i class="icon icon-add"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="wrapper send-btn">
          <div class="row">
            <button class="login-btn btn-black-login" (click)="notificationSelect(true)">
              Bekræft
            </button>
          </div>
          <div class="row">
            <button
              class="login-btn btn-white-login"
              (click)="notificationSelect(false)"
            >
              Nej, ikke flere påmindelser
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- print popup -->
    <div class="sms-box-modal" *ngIf="showPrintModal">
      <div class="sms-box-container">
        <div class="sms-box-content">
          <div class="sms-box-number">
            <div class="sms-box-info">
              <p>Der opstod et problem med udskrivningsservice.</p>
            </div>
          </div>
        </div>
        <div class="wrapper send-btn">
          <div class="row">
            <button class="login-btn btn-black-login" (click)="togglePrintPopup()">Ok</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
