<section
  class="app-page list-component page-with-fixed-header  template-list"
  [@parentAnimate]="showParent"
>
  <div class="fixed-header">
    <coop-pageheader
      [isBackGroundWhite]="true"
      [pageTitle]="pageTitle"
      [isChild]="true"
      [routeTo]="previousUrl"
    ></coop-pageheader>
  </div>
  <div class="channel-selection-overlay-container" *ngIf="showChannelSelection">
    <div class="channel-selection-box fixed-header">
      <coop-pageheader
        [pageTitle]="'Skabelon - Vælg kanaler'"
        [isBackGroundWhite]="true"
        [isChild]="true"
        [routeTo]="''"
        [isTemplate]="true"
        (closeComponent)="closeChannelSelection()"
      ></coop-pageheader>
      <div class="fixed-header-content">
        <coop-channel-select
          [selectedChannel]="sharedChannels"
          [allowedChannels]="sharedChannels"
          [requiredChannel]="requiredChannel"
          [mode]="{ create: false, edit: false, template: true }"
          (channelConfirmed)="channelConfirmed($event)"
        ></coop-channel-select>
      </div>
    </div>
  </div>
  <div class="page-container">

    <div class="searchsection">
      <div class="searchinput">
        <input type="search"
               placeholder="Søg efter skabelon eller mappe"
               #searchBox
               (input)="searchParams.next(searchBox.value)"
               class="search-input"
        />
        <div *ngIf="inputInSearch" (click)="closeSearch()" class="close-search">
          <ion-icon name="close-circle-outline"></ion-icon>
        </div>
      </div>
      <div (click)="searchParams.next(searchBox.value)" class="searchicon">
        <ion-icon name="search-outline"></ion-icon>
      </div>
    </div>

    <div class="item-list">
      <h5 class="message" *ngIf="!templates.length">
        Desværre indeholder denne mappe ingen skabeloner.
      </h5>
      <coop-itemlist
        [itemList]="itemList"
        [url]="currentUrl"
        [template]="templateType"
        [jatakLive]="offerType === 'ja-tak-live'"
        (selectedTemplate)="getSelectedTemplateId($event)"
      ></coop-itemlist>
    </div>
    <div>
      <a
        class="login-btn btn-white-login btn-medium"
        (click)="loadMore(searchBox.value)"
        *ngIf="showLoadMore"
      >Indlæs mere..</a
      >
    </div>
    <!--    <ng-container *ngIf="offerType === 'produkt' || offerType === 'poll'">-->
    <!--      <a-->
    <!--        class="btn btn-border-red btn-medium"-->
    <!--        (click)="loadMore()"-->
    <!--        *ngIf="!(templates.length < pageNumber * limit)"-->
    <!--        >Indlæs mere..</a-->
    <!--      >-->
    <!--    </ng-container>-->
  </div>
</section>
<router-outlet></router-outlet>
