export interface FaceBookPage {
  id: string;
  name: string;
  access_token: string;
  picture: {
    data: {
      url: string;
    };
  };
}

export interface QuickCoopFacebookData {
  connected: number;
  id: string;
  name: string;
  profileImage: string;
  accessToken: string;
}

export interface FacebookLoginUrl {
  fb_login_url: string;
}
