
<!--<div class="accordion-group" #accordionGroup>-->
<!--  <ng-container *ngFor="let item of customerList;  let i = index" >-->
<!--  <div class="accordion" >-->
<!--    <div  (click)="onAccordion(item.userId)"  class="accordion-header" >-->
<!--      <div class="title">-->
<!--        <p> <span>{{i+1}}.</span> &nbsp; {{item.name}}</p>-->
<!--      </div>-->
<!--      <div class="warning" >-->
<!--        <div *ngIf="item.cancelled" class="notification" >-->
<!--          <i-->
<!--            class="icon icon-close-circle"-->
<!--          ></i>-->
<!--        </div>-->
<!--        <div *ngIf="item.collected" class="collected-notification" >-->
<!--          <i-->
<!--            class="icon icon-tick-circle-filled"-->
<!--          ></i>-->
<!--        </div>-->
<!--        <div class="icons">-->
<!--          <i *ngIf="showCustomerDetail !== item.userId" class="icon icon-arrow-down"></i>-->
<!--          <i *ngIf="showCustomerDetail === item.userId" class="icon icon-arrow-up"></i>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <hr  [ngClass]="showCustomerDetail === item.userId ? 'show' : 'hide' "  />-->
<!--    <div [ngClass]="showCustomerDetail === item.userId ? 'show' : 'hide' "  class="accordion-section" >-->
<!--      <div *ngFor="let orders of item.orders" class="order-list" >-->
<!--        <div class="title-wrapper" >-->
<!--          <div class="varaint-title" >-->
<!--            <div>-->
<!--              <p-->
<!--                [class.toapprove]="orders.toApprove"-->
<!--                [class.rejected]="orders.cancelled"-->
<!--                [class.approved]=" !orders.cancelled &&  orders.approved"-->
<!--              >#{{orders.orderId}}</p>-->
<!--            </div>-->
<!--            <div style="margin-left: 10px" >-->
<!--              <p-->
<!--                [class.toapprove]="orders.toApprove"-->
<!--                [class.rejected]="orders.cancelled"-->
<!--                [class.approved]=" !orders.cancelled &&  orders.approved"-->
<!--                *ngIf="orders.variantName" >{{orders.variantName}}</p>-->
<!--              <p *ngIf="orders.toApprove" class="required-text" >Godkendelse nødvendig <br/> for valgt mængde</p>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="call-icon" >-->
<!--            <div style="margin-right: 10px;" >-->
<!--              <a href="tel:{{orders.phone}}" >-->
<!--              <span>{{orders.phone | formatNumber }}</span>-->
<!--              </a>-->
<!--            </div>-->
<!--            <div>-->
<!--              <a href="tel: + {{orders.phone}} " >-->
<!--                <i-->
<!--                  class="icon-call-calling"-->
<!--                ></i>-->
<!--              </a>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="user-details" >-->
<!--          <div class="list antallist" >-->
<!--            <div class="antal" >-->
<!--              <p>Antal</p>-->
<!--            </div>-->
<!--            <div class="actions-wrapper" >-->
<!--              <div class="number" >-->
<!--                <p>{{orders.quantity}}</p>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="statuslist" >-->
<!--            <div class="status" >-->
<!--              <p>Ordre status</p>-->
<!--            </div>-->
<!--            <div class="approved" >-->
<!--              <ng-container *ngIf="orders.toApprove  ;else rejected">-->
<!--                <p  >Afventer</p>-->
<!--              </ng-container>-->
<!--              <ng-template #rejected>-->
<!--                <ng-container  *ngIf="orders.cancelled ; else approved">-->
<!--                  <p >Afvist</p>-->
<!--                </ng-container>-->
<!--              </ng-template>-->
<!--              <ng-template #approved>-->
<!--                <p>Godkendt</p>-->
<!--              </ng-template>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="orderlist" >-->
<!--            <div class="ordered" >-->
<!--              <p>Bestilt den</p>-->
<!--            </div>-->
<!--            <div>-->
<!--              <p>{{orders.orderedAt | dateformat : false }}</p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div *ngIf="item.isActionable"  class="buttons" >-->
<!--                <a-->
<!--                  (click)="onCancelOrder(item.userId)"-->
<!--                  class="btn btn-border-red button-wrapper "-->
<!--                >-->
<!--                  <div class="cancel" > <i-->
<!--                    class="icon icon-close-circle"-->
<!--                  ></i>-->
<!--                  </div>-->
<!--                  <div>Slet</div>-->
<!--                </a-->
<!--                >-->
<!--                <a-->
<!--                  (click)="onCollectOrder(item.userId)"-->
<!--                  class="btn btn-red  button-wrapper"-->
<!--                >-->
<!--                  <div class="approve" ><i-->
<!--                    class="icon-tick-circle"-->
<!--                  ></i></div>-->
<!--                  <div>Afhentet</div>-->
<!--                </a-->
<!--                >-->
<!--              </div>-->
<!--      <div *ngIf="item.cancelled" class="order-deleted" >-->
<!--        <div class="delete-notification" >-->
<!--          <i-->
<!--            class="icon icon-close-circle"-->
<!--          ></i>-->
<!--        </div>-->
<!--        <div class="delete-text" >-->
<!--          <p>Ordren slettet</p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div *ngIf="item.collected" class="order-deleted" >-->
<!--        <div class="collected-notification" >-->
<!--          <i-->
<!--            class="icon-tick-circle-filled"-->
<!--          ></i>-->
<!--        </div>-->
<!--        <div class="collected-text" >-->
<!--          <p>Ordren afhentet</p>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  </ng-container>-->
<!--</div>-->


<div *ngFor="let customer of customerList; let i = index" class="customer">
  <div (click)="onCustomerSelect(customer.userId)" class="customer-info">
    <div class="customer-name">
      <span>{{ i + 1 }}.</span>
      <div>{{customer.name}}</div>
    </div>

    <div style="display: flex;justify-content: center;align-items: center">
<!--      <div *ngIf="customer?.approvalRemaining" class="status">-->
<!--        <ion-icon-->
<!--          [class.to_approval]="customer?.approvalRemaining"-->
<!--          name="alert-circle-outline"></ion-icon>-->
<!--      </div>-->

      <div *ngIf="customer?.collected" class="status">
        <ion-icon
          [class.collected]="customer?.collected"
          name="checkmark-circle"></ion-icon>
      </div>

      <div *ngIf="customer?.cancelled" class="status">
        <ion-icon
          [class.rejected]="customer?.cancelled"
          name="close-circle"></ion-icon>
      </div>

      <div class="dropdown">
        <ion-icon name="chevron-down-outline"></ion-icon>
      </div>
    </div>
  </div>

  <div *ngIf="customerId === customer.userId" class="seperator">
  </div>

  <ng-container *ngIf="customerId === customer.userId">
    <div *ngFor="let order of customer.orders" class="order-list">

      <div class="order-info">
        <div class="orderid">
          <p
            [class.toapprove]="order.toApprove"
            [class.rejected]="order.cancelled"
            [class.approved]=" !order.cancelled &&  order.approved"
          >#{{order.orderId}}</p>

          <div style="margin-left: 10px">
            <p
              [class.toapprove]="order.toApprove"
              [class.rejected]="order.cancelled"
              [class.approved]=" !order.cancelled &&  order.approved"
              *ngIf="order.variantName">{{ order.variantName }}</p>
            <p *ngIf="order.toApprove" class="required-text">Godkendelse nødvendig <br/> for valgt mængde</p>
          </div>
        </div>

        <div class=" call-icon flex-display" >
          <div style="margin-right: 10px;" >
            <a href="tel:{{order.phone}}" >
              <span>{{order.phone | formatNumber }}</span>
            </a>
          </div>
          <div>
            <a href="tel: + {{order.phone}} " >
              <i
                class="icon-call-calling"
              ></i>
            </a>
          </div>
        </div>


      </div>

      <!--    order quantity -->
      <div class="quantity list">
        <div class="order-info-title">
          Quantity
        </div>


<!--        <div *ngIf="order.toApprove" class="quantity-wrapper">-->
<!--          <div class="approve-option-icon">-->

<!--            &lt;!&ndash;            approve order&ndash;&gt;-->

<!--            <ion-icon (click)="approve(order.id,order.quantity)" name="checkmark-circle-outline"-->
<!--            >Approved-->
<!--            </ion-icon>-->


<!--            &lt;!&ndash;            reject order&ndash;&gt;-->
<!--            <ion-icon (click)="reject(order.id)" name="close-circle-outline"-->

<!--            >Reject-->
<!--            </ion-icon>-->
<!--            <div class="border-line"></div>-->
<!--          </div>-->
<!--          <div class="limit">-->
<!--            <mbsc-select display="center" [data]="numbers" [(ngModel)]="order.quantity">-->
<!--            </mbsc-select>-->
<!--          </div>-->
<!--        </div>-->


        <div *ngIf="!order.toApprove" class="amount">
          {{ order.quantity }}
        </div>

      </div>

      <!--    order status-->

      <div class="order-status list">
        <div class="order-info-title">
          Ordre status
        </div>
        <div class="status">
          <ng-container *ngIf="order.toApprove ; else rejected ">
            <span>Afventer</span>
          </ng-container>
          <ng-template #rejected>
            <ng-container *ngIf="order.cancelled ;else approved">
              <span>Afvist</span>
            </ng-container>
          </ng-template>
          <ng-template #approved>
            <span>Godkendt</span>
          </ng-template>
        </div>

      </div>


      <!--    order time-->

      <div class="order-time list">
        <div class="order-info-title">
          Bestilt den
        </div>
        <div class="order-date">
          <span>{{ order.orderedAt | dateformat : false }}</span>
        </div>

      </div>
    </div>


    <div class="action-buttons">
      <ng-container *ngIf="customer.isActionable">

        <div
          style="cursor: pointer"
          (click)="onCancelOrder(customer.userId)"
          class="delete-btn"
        >
          <ion-icon name="close-outline"></ion-icon>
          <div>Slet</div>
        </div>
        <div
          style="cursor: pointer"
          class="pickedup-btn"
          (click)="onCollectOrder(customer)"
        >
          <ion-icon name="checkmark-outline"></ion-icon>
          <div>Afhentet</div>
        </div>
      </ng-container>

      <ng-container *ngIf="customer?.collected">
        <div [class.picked]="customer?.collected" class="actionstatus ">
          <ion-icon name="checkmark-circle"></ion-icon>
          <p>
            Ordren afhentet
          </p>
        </div>
      </ng-container>

      <ng-container *ngIf="customer?.cancelled">
        <div [class.deleted]="customer?.cancelled" class="actionstatus ">
          <ion-icon name="close-circle"></ion-icon>
          <p>
            Ordren slettet
          </p>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>



