import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { EMPTY } from 'rxjs';
import { mergeMap, takeWhile } from 'rxjs/operators';

import {
  DateToString,
  FormatNumberPipe,
  LeftPad,
  NumberPad,
} from 'app/shared/pipes';
import {
  Client,
  DatepickerData,
  JatakDefault,
  MultipleImage,
  SelectedChannels,
  ValidationMessage,
  VideoUrls,
} from 'app/shared/interface';
import {
  ClientService,
  ErrorhandlerService,
  NotificationsService,
  TokenService,
  CreateOptionDataService,
  DateFormatService,
  HelperService,
  ImageService,
} from 'app/core/services';

import {
  checkIfCommentDateIsLessThanStartDate,
  checkWhiteSpace,
  checkPriceRegex,
  uniqueKeyword,
  checkPhoneNumber,
  checkMaxLengthForVataintTitle,
  checkNumberOnly,
  customMinValidator,
  uniquePriceQantity,
} from 'app/core/validators/custom.validator';
import {
  Jatak,
  Jatak_Validation_Messages,
  JatakService,
} from 'app/features/jatak';
import { FacebookEventsService } from 'app/features/facebookconnect';
import { SendsmsComponent } from 'app/features/sms/components';
import { animateParent, slideAnimation } from 'app/shared/animations';
import {
  COMMON_DATETIMEPICKER_SETTINGS,
  COMMON_SELECT_SETTINGS,
} from 'app/shared/data';
import {
  ChannelSelectionModes,
  Shared,
  CreateOption,
} from 'app/shared/interface';
import { DefaultSharedValue } from 'app/core/data';
import { DetectFocusDirective } from 'app/shared/directives';
import { Store } from '@ngxs/store';
import { SetJatakRouteState } from '../../states';
import * as _ from 'lodash';
import { MbscDatepickerOptions, MbscSelectOptions } from '@mobiscroll/angular';
import { DatepickerBase } from '@mobiscroll/angular/dist/js/core/components/datepicker/datepicker';
import * as dayjs from 'dayjs';
import * as moment from 'moment';

@Component({
  selector: 'coop-jatak-form',
  templateUrl: './jatak-form.component.html',
  styleUrls: ['./jatak-form.component.scss'],
  providers: [
    LeftPad,
    NumberPad,
    DateToString,
    DetectFocusDirective,
    FormatNumberPipe,
  ],
  animations: [animateParent, slideAnimation],
})
export class JatakFormComponent implements OnInit, OnDestroy {
  @ViewChild(SendsmsComponent) smsForm: SendsmsComponent;
  @Input() formData?: Jatak;
  @Input() jatakDefault: JatakDefault;
  @Input() editMode = false;
  @Input() previousUrl = '';
  @Input() successUrl = '';
  @Input() offerId = ''; // editId + templateId
  @Input() templateMode = false;
  destroyImageHandler = false;
  jatakForm: FormGroup;
  jatak: Jatak;
  pageTitle: string;
  showParent: string = 'visible';
  formSaved: boolean = false;
  isProcessing: boolean = false;
  formSubmit: boolean = false;
  guid: string = '';
  clients: Client[];
  createSetting: CreateOption;
  allowedChannels: Shared = {
    coop: true,
    facebook: true,
    sms: true,
    mail: false,
    web: true,
    dit: false,
  };
  selectedChannels: SelectedChannels;
  tabName: string;
  hasImage = false;

  replyTextMessage = '';
  customerName: string = '';
  greeting = '';
  infoText: string = 'GÆLDER SÅ LÆNGE LAGER HAVES.';
  defaultTextVariant =
    'Du bestiller ved at skrive JA TAK <Variant> i kommentarfeltet her (ikke under de enkelte billeder), samt hvor mange du vil hente:';
  defaultText =
    'Du bestiller ved at skrive JA TAK i kommentarfeltet her (ikke under de enkelte billeder), samt hvor mange du vil hente, f.eks. JA TAK 1';
  defaultMessage =
    '✅ Din bestilling er bekræftet, når du modtager et svar fra vores robot. 💬 ';
  defaultStaticTextStart = 'Tak for din bestilling i ';
  defaultReplyText = ' ';
  defaultStaticTextEnd: string = '. Vi reserverer <Antal> til dig.';
  defaultStaticTextEndVaraint: string =
    '. Vi reserverer <Antal> <Varaint>. til dig.';
  defaultReplyTextMore =
    '. Der er desværre kun <Antal> tilbage. Vi reserverer <Antal> til dig. ';
  defaultReplyTextMoreVaraint =
    '. Der er desværre kun <Antal> <Varaint>. tilbage. Vi reserverer <Antal> <Varaint>. til dig. ';
  finaltext =
    this.defaultStaticTextStart +
    this.defaultReplyText +
    this.defaultStaticTextEnd;
  finaltextPreview =
    this.defaultStaticTextStart +
    this.defaultReplyText +
    this.defaultReplyTextMore;

  finaltextVaraint =
    this.defaultStaticTextStart +
    this.defaultReplyText +
    this.defaultStaticTextEndVaraint;
  finaltextPreviewVaraint =
    this.defaultStaticTextStart +
    this.defaultReplyText +
    this.defaultReplyTextMoreVaraint;

  textAdded = 'Dit ordrenummer vil snart modtages i en privat besked. ';
  textAddedPrivate = 'Dit ordrenummer er: <ordre ID>';

  showResponse: boolean = false;
  showReply: boolean = false;
  imageUrl;
  defaultImageData = [
    { url: 'assets/images/camera@3x.jpg', hasImage: false },
    { url: 'assets/images/camera@3x.jpg', hasImage: false },
    { url: 'assets/images/camera@3x.jpg', hasImage: false },
    { url: 'assets/images/camera@3x.jpg', hasImage: false },
    { url: 'assets/images/camera@3x.jpg', hasImage: false },
  ];
  defaultVideoData = {
    url: '',
    thumbnail: 'assets/images/video.png',
  };
  videoUrl: VideoUrls = {
    url: '',
    thumbnail: 'assets/images/video.png',
  };
  imageError: boolean = false;
  hasVideo = false;
  isGif = false;
  isVideo = false;
  validateModes = [];
  orderTab = ['coop', 'facebook', 'sms', 'web'];
  tabs = {
    coop: 'COOP App',
    facebook: 'Facebook',
    sms: 'SMS',
    web: 'Web',
  };
  smsText = '';
  shared: Shared = DefaultSharedValue;
  showMFLPopup = false;
  datePickerSettings: MbscDatepickerOptions = {
    ...COMMON_DATETIMEPICKER_SETTINGS,
    ...{
      dateWheels: 'YYYY MMMM DD',
      dateFormat: 'YYYY-MM-DD',
      stepMinute: 15,
      onOpen: (_, inst) => this._setupDateTime(inst),
    },
  };

  emojiError = false;
  showEmojiError = false;
  isValidityChecked = false;
  showChannelSelection = false;
  channelSelectionModes: ChannelSelectionModes;
  tempTitle: string;
  tempTabName: string;
  validationMessage: ValidationMessage = Jatak_Validation_Messages;
  hasBeenPosted: boolean = false;
  tempSms = false;
  limit: number = 1;
  alertQuantitySelectSettings: MbscSelectOptions = {
    ...COMMON_SELECT_SETTINGS,
    ...{
      data: [],
    },
  };
  numbers = [];
  disabled: boolean = false;
  disabledDefaultText: boolean = false;
  disabledPriceandQuantity: boolean = false;
  stringNum = '';
  // for add varient
  showVarient: boolean = false;
  showKeywordWarning: number;
  varaintInfo = '';
  varainInfoShow = false;

  // character count show varaiable delcaration
  visible = {
    title: false,
    description: false,
    robotReply: false,
    soldOutTitle: false,
    soldOutReply: false,
  };
  showVaraintTitle: number;
  orderListUrl = '';
  disabledToggle: boolean = false;
  currentTime: any;
  priceDescSelectSettings = {
    ...COMMON_SELECT_SETTINGS,
    ...{
      data: [
        'stk',
        'kg',
        'g',
        'liter',
        'ml',
        'pakke',
        'pakker',
        'sæt',
        'kasse',
        'kasser',
        'ramme',
        'rammer',
      ],
    },
  };
  origin: string = '';

  showMaxOrder = false;
  showMaualApproval: boolean = false;
  jatakMode: boolean = true;
  private _subscriptionState = true;
  isPostedToFb: boolean = false;
  hideSaveAsTemplateButton: boolean = false;
  emojiCount: any = 0;
  titleCount: any = 0;
  descriptionCount: any = 0;
  soldOutTitleLength: any = 0;
  soldOutReplyLength: any = 0;
  robotReplyValidation: boolean = false;
  titleValidation: boolean = false;
  descriptionValidation: boolean = false;
  soldOutTitleValidation: boolean = false;
  soldOutReplyValidation: boolean = false;
  showReplyInfo: boolean = false;
  datePickerData: DatepickerData;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _jatakService: JatakService,
    private _router: Router,
    private _errorHandlerService: ErrorhandlerService,
    private _dateToString: DateToString,
    private _clientService: ClientService,
    private _facebookEvents: FacebookEventsService,
    private _createOptionService: CreateOptionDataService,
    private _formatNumber: FormatNumberPipe,
    private _store: Store
  ) {}

  // *** It ensures the required jatak form is being loaded ***
  ngOnInit() {

    this._activatedRoute.data.subscribe((res) => {
      this.editMode = res['editMode'];
    });
    this.origin = this._activatedRoute.snapshot.queryParams['origin'];
    this.pageTitle = 'Opret nyt JA TAK';
    this.imageUrl = JSON.parse(JSON.stringify(this.defaultImageData));
    this._setupRouteData();
    this._checkChildPage();
    this._initComp();
    this._setupJatakForm();

    if (!this.jatak) {
      this._setUpFields();
      this.setShareStatus();
      this._setAllowedChannels(true);
      this._setDefaultTimeOnCreate();
    }

    if (this.jatak) {
      if (this.editMode) {
        this.pageTitle = 'Rediger Ja-tak';
      } else {
        this._setDefaultTimeOnCreate();
      }
      this._builtEditForm();
      this.disabledToggle = this.jatak['fbPostId'] && this.editMode;

      // to disable jatak form for archive condition

      if (this.jatak['ended'] && this.editMode) {
        this.pageTitle = 'Arkiveret Ja-tak';
        this.jatakForm.disable(); //web
        this.disabled = true;
        this.disabledPriceandQuantity = true;
        this.disabledDefaultText = true;
        this.hideSaveAsTemplateButton = true;
      } else {
        this.jatakForm.enable();
        this.disabled = false;
        this.disabledDefaultText = false;
        this.disabledPriceandQuantity = false;
        this.hideSaveAsTemplateButton = false;
      }

      // to  disabled jatak form for active condition
      if (this.jatak['started'] && this.editMode) {
        this.jatakForm.controls['startDate'].disable();
        // this.jatakForm.controls['prices'].disable();
        this.disabled = true;
        this.isPostedToFb = true;
      } else {
        this.isPostedToFb = false;
        this.disabled = false;
      }
    }
    if (this.jatakDefault) {
      this.handleJatakSetting();
    }
    this.varientArray.valueChanges
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe((data) => {
        this._handleJatakDynamicOfferText(data);
      });
    this.getCharacterLength();
    this.setPreviousRoute();
    this.datePickerData = {
      titles: {
        start: 'JA TAK Start',
        end: 'JA TAK Slut',
      },
      startEndDateTime: {
        start: this.jatakForm.get('startDate').value,
        end: this.jatakForm.get('endDate').value,
      },
      disable: {
        start: !!(this.editMode && this.jatak && this.jatak.started),
        end: !!(this.editMode && this.jatak && this.jatak.ended),
      },
      modes: {
        isDuplicate: false,
        isEditMode: this.editMode,
        isTemplate: this.templateMode,
      },
    };
  }

  private handleJatakSetting() {
    if (this.jatakDefault.hasCustomerLimit) {
      this.showMaxOrder = true;
    }
    if (this.jatakDefault.hasAlertQuantity) {
      this.showMaualApproval = true;
    }
    this.jatakForm.patchValue({
      soldOutTitle: this.jatak?.soldOutTitle
        ? this.jatak.soldOutTitle
        : this.jatakDefault.soldOutTitle,
      soldOutReply: this.jatak?.soldOutReply
        ? this.jatak.soldOutReply
        : this.jatakDefault.soldOutReply,
      hasCustomerLimit: this.jatak?.hasCustomerLimit
        ? this.jatak.hasCustomerLimit
        : this.jatakDefault.hasCustomerLimit,
      customerLimit: this.jatak?.customerLimit
        ? this.jatak.customerLimit
        : this.jatakDefault.customerLimit,
      hasAlertQuantity: this.jatak?.hasAlertQuantity
        ? this.jatak.hasAlertQuantity
        : this.jatakDefault.hasAlertQuantity,
      alertQuantity: this.jatak?.alertQuantity
        ? this.jatak.alertQuantity
        : this.jatakDefault.alertQuantity,
      phone: this._formatNumber.transform(
        this.jatak?.phone ? this.jatak.phone : this.jatakDefault.phone
      ),
    });
  }

  getCharacterLength() {
    this.jatakForm
      .get('replyText')
      .valueChanges.pipe(takeWhile(() => this._subscriptionState))
      .subscribe((res) => {
        this.replyTextMessage = res;
        this.emojiCount = this.calculateLength(res);
      });

    this.jatakForm
      .get('description')
      .valueChanges.pipe(takeWhile(() => this._subscriptionState))
      .subscribe((res) => {
        this.descriptionCount = this.calculateLength(res);
      });

    this.jatakForm
      .get('title')
      .valueChanges.pipe(takeWhile(() => this._subscriptionState))
      .subscribe((res) => {
        this.titleCount = this.calculateLength(res);
        this.defaultReplyText = res;
        this.finaltext =
          this.defaultStaticTextStart +
          this.defaultReplyText +
          this.defaultStaticTextEnd;
        this.finaltextPreview =
          this.defaultStaticTextStart +
          this.defaultReplyText +
          this.defaultReplyTextMore;
      });

    this.jatakForm
      .get('soldOutTitle')
      .valueChanges.pipe(takeWhile(() => this._subscriptionState))
      .subscribe((res) => {
        this.soldOutTitleLength = this.calculateLength(res);
      });

    if (this.shared.facebook) {
      this.jatakForm
        .get('soldOutReply')
        .valueChanges.pipe(takeWhile(() => this._subscriptionState))
        .subscribe((res) => {
          this.soldOutReplyLength = this.calculateLength(res);
        });
    }
  }

  calculateLength(str: string) {
    return _.size(str);
  }

  ngOnDestroy(): void {
    document.body.classList.remove('overlay');
    this._subscriptionState = false;
    this.destroyImageHandler = true;
  }

  setPreviousRoute() {
    if (this.origin === 'create') {
      this.showChannel();
      this.previousUrl = '/butikker/ja-tak';
    }
    if (this.origin === 'kalender') {
      this.showChannel();
      this.previousUrl = '/';
    }
    if (this.origin === 'duplicate') {
      this.previousUrl = '/butikker/ja-tak';
    }
    if (this.origin === 'list') {
      this.previousUrl = '/butikker/ja-tak';
    }
  }

  // *** It sets channelSelections and sms-validations(if shared has sms) for currently loaded jatak form. ***
  setShareStatus() {
    this.setChannelSelectionModes();
    this.patchToggleToForm();
    this.setTabNameAndValidation();
  }

  // *** It sets current jatakForm mode ***
  setChannelSelectionModes() {
    this.channelSelectionModes = {
      create: !this.editMode && !this.templateMode,
      edit: this.editMode,
      template: this.templateMode,
    };
  }

  // *** Sets tabName field and setup sms if this.shared.sms ***
  setTabNameAndValidation() {
    // setting tab name
    this.tabName = this.getTabName(this.shared);
    this.setTabAndValidation(this.shared);
  }

  // *** patch shared to jatakForm ***
  patchToggleToForm() {
    this.jatakForm.patchValue({
      shared: this.shared,
    });
  }

  // *** It patches shared channels to jatakForm and sets their validations ***
  // used after updating shared channels for validation update
  updateChannelSelection() {
    this.patchToggleToForm();
    this.setTabNameAndValidation();
  }

  // *** It gets current selected channels as a button like title for jatak form ***
  // @params [selectedChannels: Shared] => currently selected channels
  // @returns [tabName:string] => currently selected channels separated by slash(/)
  getTabName(selectedChannels: Shared): string {
    let validTab: any[];
    let tabName: string;
    validTab = this.orderTab
      .filter((tab) => selectedChannels[tab])
      .map((name) => this.tabs[name]);

    tabName = validTab[0];
    for (let i = 1; i < validTab.length; i++) {
      tabName += ' / ' + validTab[i];
    }
    return tabName;
  }

  // TODO: this.validateModes written here is unused
  // *** It set up sms validation if this.shared.sms is true ***
  // @params [shareOptions:Shared]=> selected channels
  setTabAndValidation(shareOptions: any) {
    this.validateModes = [];
    for (const key in this.orderTab) {
      if (shareOptions[this.orderTab[key]]) {
        if (this.orderTab[key] === 'coop') {
          continue;
        }
        this.validateModes.push(this.orderTab[key]);
      }
    }
    if (shareOptions['sms']) {
      this._setUpSms();
    }
  }

  // It updates channel state while choosing before confirmation as tempTabName
  // @params [shareOptions:Shared]=> selected channels
  updateChannelState(channelState: Shared): void {
    this.tempTabName = this.getTabName(channelState);
  }

  // *** It confirms selected shared field ***
  // @params [confirmShares: Shared] => confirmed shared options
  confirmedShareOptions(confirmShares: Shared) {
    if (this.shared !== confirmShares) {
      this.shared = JSON.parse(JSON.stringify(confirmShares));
      this.showChannelSelection = false;
      this.pageTitle = this.tempTitle;
      this.updateChannelSelection();
      this._setUpFields();
    }
  }

  // *** It toggles between channel selection mode and form fillup mode ***
  // It works only when in create mode.
  toggleChannelSelection() {
    // It doesnot allow channel update when jatak is active
    if (this.jatak && this.jatak['started'] && this.editMode) {
      return;
    }
    if (!this.showChannelSelection) {
      this.tempTabName = this.tabName;
      this.tempTitle = this.pageTitle;
      this.pageTitle = 'Ja Tak - Vælg kanaler';
    }
    if (this.showChannelSelection) {
      this.pageTitle = this.tempTitle;
    }
    this.showChannelSelection = !this.showChannelSelection;
  }

  showChannel() {
    this.showChannelSelection = true;
    this.tempTitle = this.pageTitle;
    this.pageTitle = 'Ja Tak - Vælg kanaler';
  }

  // *** When type of media changes, it is evoked ***
  // It envokes clearingMediaOnTabChange according to the boolean value provided
  // @params [ type: boolean] => is Current Tab of video?
  uploadTypeSelect(type: boolean) {
    this.clearingMediaOnTabChange(type);
  }

  // This checks whether previous media tab has a media already selected or not
  // It clears previous media before tab change, if media is found
  // @params [type:boolean] => true: Is  currently changed media type video?
  //                           false: Is currently changed media type image?
  clearingMediaOnTabChange(type: boolean) {
    let isPrompt: boolean;
    if (type) {
      isPrompt = this.hasImage;
    }
    if (!type) {
      isPrompt = this.hasVideo;
    }
    if (isPrompt) {
      this.clearMedia(type);
    }
    if (!isPrompt) {
      this.changeUploadType(type);
    }
  }

  // *** It clears previously uploaded media (photo or video) ***
  // It is envoked only after changing from previous tab to current tab and previous tab should have a media selected
  // @params [isVideo: boolean] => Is changed tab video?
  clearMedia(isVideo: boolean) {
    let clearFunction: (context: any) => void;
    let notificationMsg;
    if (isVideo) {
      notificationMsg =
        'Alle uploadede billeder kasseres ved at skifte faner. Er du sikker?';
      clearFunction = this.clearImageUrl;
    }
    if (!isVideo) {
      notificationMsg =
        'Den uploadede video kasseres ved at skifte faner. Er du sikker?';
      clearFunction = this.clearVideo;
    }
    NotificationsService.confirmWithCallback(notificationMsg, (accept) => {
      if (accept) {
        clearFunction(this);
        this.changeUploadType(isVideo);
        return;
      }
      if (!accept) {
        return;
      }
    });
  }

  // *** When type of media changes, it is evoked ***
  // It envokes changeMediaType according to the boolean value provided
  // @params [ isVideo: boolean] => Is Current Tab of video?
  changeUploadType(isVideo: any) {
    this.isVideo = isVideo;
    this.changeMediaType(isVideo);
  }

  // *** It sets validators for current media type and removes previous media from formData ***
  // When changeUploadType is run, this method is evoked
  // @params [isVideo:boolean] => Is current media video?
  changeMediaType(isVideo: any) {
    if (isVideo && !this.editMode) {
      NotificationsService.notify(
        'Det er vigtigt, at videoen optages med mobiltelefonen holdt vertical/oprejst',
        'info',
        'top'
      );
    }
    this.changeMediaValidators(isVideo);
    this.setMediaToNull(isVideo);
  }

  // *** It sets validator for current media type ***
  // It is evoked in changeMediaType method
  // @params [isVid:boolean] => is current media video?
  changeMediaValidators(isVid: boolean) {
    let videoValidity;
    let largeImageValidity;
    if (isVid) {
      videoValidity = Validators.required;
      largeImageValidity = null;
    }
    if (!isVid) {
      videoValidity = null;
      largeImageValidity = Validators.required;
    }
    this._updateValidators('video.url', videoValidity);
    this._updateValidators('video.thumbnail', videoValidity);
    this._updateValidators('images', largeImageValidity);
    // this._updateValidators('imageUrl.thumbnail', largeImageValidity);
  }

  // *** It  removes previous media from formData ***
  // It is evoked in changeMediaType method
  // @params [isVideo:boolean] => is current media video?
  setMediaToNull(isVideo: boolean) {
    if (isVideo) {
      // this.jatakForm.patchValue({
      //   imageUrl: {
      //     largeImage: null,
      //     thumbnail: null,
      //   },
      // });
    }
    if (!isVideo) {
      this.jatakForm.patchValue({
        video: {
          url: null,
          thumbnail: null,
        },
      });
    }
  }

  // *** It  removes previous imageUrl from formData ***
  // It is evoked only when switching from image to video and previous tab should have an image selected or when video is found in edit form
  // @params [context: JatakFormComponent] => this
  clearImageUrl(context: any) {
    context.imageUrl = JSON.parse(JSON.stringify(context.defaultImageData));
    context.hasImage = false;
    // context.jatakForm.patchValue({
    //   imageUrl: {
    //     largeImage: null,
    //     thumbnail: null,
    //   },
    // });
  }

  // *** It  removes previous videoUrl from formData ***
  // It is evoked only when switching from video to image and previous tab should have a video selected or when image is found in edit form
  // @params [context: JatakFormComponent] => this
  clearVideo(context: any) {
    context.videoUrl = JSON.parse(JSON.stringify(context.defaultVideoData));
    context.hasVideo = false;
    context.jatakForm.patchValue({
      video: {
        url: '',
        thumbnail: '',
      },
    });
  }

  // *** saves coop-sendsms textarea value to this component on ngModelChange
  // patch changes in sms value
  // @params [smsMsg: string] => message box value from textarea in coop-sendsms
  smsInit(smsMsg: string) {
    this.smsText = smsMsg;
  }

  // *** If error message is found then it is displayed below coop-sendsms component ***
  // it is evoked when coop-sendsms alters its validity
  // @params [valid: boolean] => Is form in coop-sendsms valid?
  checkSmsValidity(valid: boolean) {
    this.emojiError = valid;
    if (this.isValidityChecked) {
      this.showEmojiError = this.emojiError;
    }
  }

  // *** prevents invalid characters for phone and price ***
  // @params [event : KeyboardEvent]=> keyDown event
  validateNumber(event: any) {
    HelperService.preventInvalidNumbers(event);
  }

  onInputChange(event: any, index: string | number) {
    const regex = /^[A-Za-z0-9æåøÆÅØ]*$/;
    if (!regex.test(event.target.value)) {
      const sanitizedValue = event.target.value?.replace(
        /[^A-Za-z0-9æåøÆÅØ]/g,
        ''
      );
      event.target.value = sanitizedValue;
      this.jatakForm.controls['variants']['controls'][index].controls[
        'keyword'
      ].patchValue(sanitizedValue);
    }
  }

  formatPhoneNumber(ev: any) {
    if (ev.key === 'Backspace') {
      this.stringNum = ev.target.value;
    } else {
      this.stringNum = ev.target.value
        .replace(/[^\d]/g, '')
        .replace(/(.{2})/g, '$1 ');
    }
    this.jatakForm.patchValue({
      phone: this.stringNum,
    });
  }

  // *** It is evoked when deleting the template or previously active jatak ***
  // @params [id: string] => id of the jatak post to be removed
  // @params [type: string] => 'post' or 'template'
  remove(type: string, id?: string) {
    NotificationsService.confirm(`Er du sikker?`)
      .pipe(
        takeWhile(() => this._subscriptionState),
        mergeMap((state) => {
          if (state) {
            if (type === 'post') {
              return this._deletePost(state, id);
            }
            if (type === 'template') {
              return this._deleteTemplate(state, this.offerId);
            }
          }
          return EMPTY;
        })
      )
      .subscribe({
        next: () => this._success(type),
        error: (err) => this._handleError(err),
      });
  }

  // Todo: Unused
  share(): void {
    const user = TokenService.getUserDetail();
    if (user == null || !user.facebook.connected) {
      this._facebookEvents.connectFacebook();
      return;
    }
    this.jatakForm.patchValue({
      facebook: this.shared.facebook,
    });
  }

  sms() {
    if (!this._isValid()) {
      return;
    }
    // *** SMS TOGGLE ** //
    // Check if SMS is already created , if not get Send SMS Component
    // SMS Toggle to check or uncheck
    if (+this.jatakForm.value.sms === 0) {
      this.isProcessing = true;
      this.shared.sms = true;
      if (this.clients) {
        this.clients = null;
        this.isProcessing = false;
      } else {
        this._clientService
          .getClients()
          .pipe(takeWhile(() => this._subscriptionState))
          .subscribe({
            next: (clients) => {
              this.clients = clients;
              this.isProcessing = false;
            },
            error: (err) => this._handleError(err),
          });
      }
    }
  }

  checkCharacterLength() {
    if (this.emojiCount > 100) {
      this.robotReplyValidation = true;
    }
    if (this.titleCount > 99) {
      this.titleValidation = true;
    }
    if (this.descriptionCount > 2500) {
      this.descriptionValidation = true;
    }
    if (this.soldOutTitleLength > 60) {
      this.soldOutTitleValidation = true;
    }
    if (this.soldOutReplyLength > 100) {
      this.soldOutReplyValidation = true;
    }
    return (
      this.emojiCount > 100 ||
      this.titleCount > 99 ||
      this.descriptionCount > 2500 ||
      this.soldOutTitleLength > 60 ||
      this.soldOutReplyLength > 100
    );
  }

  // *** Saves both create and edit form ***
  save() {
    this.formSubmit = true;
    this.robotReplyValidation = false;
    this.titleValidation = false;
    this.descriptionValidation = false;
    this.soldOutTitleValidation = false;
    this.soldOutReplyValidation = false;
    const fbConnected = TokenService.getUserDetail().facebook.connected;
    if (this.shared['facebook'] && !fbConnected) {
      NotificationsService.notify(
        'Tilslut venligst din Facebook-side',
        'error',
        'top'
      );
      return;
    }
    if (this.shared.facebook && this.jatakForm.value.phone) {
      this._removeSpacing();
    }
    if (this.showVarient) {
      const pricesArray = this.jatakForm.get('prices') as FormArray;
      if (pricesArray.length > 0) {
        pricesArray.at(0).patchValue({
          price: null,
          quantity: null,
          uom: null,
        });
      }
      this.jatakForm.patchValue({
        quantity: null,
      });
      pricesArray.controls.forEach((group: FormGroup) => {
        group.get('price').clearValidators();
        group.get('price').updateValueAndValidity();
        group.get('quantity').clearValidators();
        group.get('quantity').updateValueAndValidity();
      });

      this.jatakForm.get('quantity').clearValidators();
      this.jatakForm.get('quantity').updateValueAndValidity();
    }
    if (!this.showVarient) {
      (this.jatakForm.controls['variants'] as FormArray).clear();
    }
    if (this.checkCharacterLength()) {
      this._handleError('');
      return;
    }
    if (!this._isValid()) {
      return;
    }
    if (!this.isProcessing) {
      this.isProcessing = true;
      this._prepareFormValues();
      this._jatakService
        .saveJatak(this.jatakForm.value, this.editMode)
        .pipe(takeWhile(() => this._subscriptionState))
        .subscribe({
          next: () => this._success('post'),
          error: (err) => this._handleError(err),
        });
    }
  }

  // *** If created from template, it gets template data before saving jatakForm ***
  // @returns [jatakData: Jatak] => values from template
  createTemplateData() {
    const jatakData = {};
    // if(( this.jatak && this.jatak.isVariant) || this.showVarient){
    //   this.jatakForm.removeControl('quantity')
    //   this.jatakForm.removeControl('price')
    // }
    Object.assign(jatakData, this.jatakForm.value);
    // setting board value to 0 for template mode
    jatakData['board'] = '0';
    // changing the date format
    jatakData['startDate'] = this._dateToString.transform(
      this.jatakForm.value.startDate
    );
    jatakData['endDate'] = this._dateToString.transform(
      this.jatakForm.value.endDate
    );
    jatakData['shared']['coop'] = jatakData['shared']['coop'] ? 1 : 0;
    jatakData['shared']['facebook'] = jatakData['shared']['facebook'] ? 1 : 0;
    jatakData['shared']['sms'] = jatakData['shared']['sms'] ? 1 : 0;
    jatakData['shared']['web'] = jatakData['shared']['web'] ? 1 : 0;
    jatakData['shared']['dit'] = 0;
    jatakData['shared']['mail'] = 0;
    if (jatakData['phone']) {
      jatakData['phone'] = jatakData['phone'].replaceAll(' ', '');
    }
    if (this.shared['sms']) {
      jatakData['smsText'] = this.smsForm.getSmsText();
    }
    if (!this.shared['sms']) {
      delete jatakData['smsText'];
    }

    return jatakData;
  }

  // Todo: jatak.own is always false
  // *** Saves Jatak as template ***
  // @params [edit: boolean] => true: if jatak.own is true, Jatak template will be updated
  //                            false: if jatak.own is false, Jatak template will not be updated but created
  saveJatakTemplate(edit: boolean) {
    if (this.shared.facebook && this.jatakForm.value.phone) {
      this._removeSpacing();
    }
    if ((this.jatak && this.jatak.isVariant) || this.showVarient) {
      const pricesArray = this.jatakForm.get('prices') as FormArray;
      if (pricesArray.length > 0) {
        pricesArray.at(0).patchValue({
          price: null,
          quantity: null,
        });
      }
      this.jatakForm.patchValue({
        quantity: null,
      });
      pricesArray.controls.forEach((group: FormGroup) => {
        group.get('price').clearValidators();
        group.get('price').updateValueAndValidity();
        group.get('quantity').clearValidators();
        group.get('quantity').updateValueAndValidity();
      });
      this.jatakForm.get('quantity').clearValidators();
      this.jatakForm.get('quantity').updateValueAndValidity();
    }

    if (!this.showVarient) {
      (this.jatakForm.controls['variants'] as FormArray).clear();
    }

    if (!this._isValid()) {
      return;
    }
    const jatakData = this.createTemplateData();

    if (!edit) {
      this.saveJatakTemplateData(jatakData);
    }
    if (edit) {
      this.updateJatakTemplateData(jatakData);
    }
  }

  // *** It creates new Jatak from Template ***
  // @returns [jatakData: Jatak] => values from template
  saveJatakTemplateData(jatakData: any) {
    this._jatakService
      .saveJatakTemplate(jatakData, false)
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: () =>
          NotificationsService.notify(
            'Skabelon er blevet oprettet',
            'success',
            'top'
          ),
        error: (err) => this._handleError(err),
      });
  }

  // TODO: This is never called
  // *** It updates previous template ***
  // @returns [jatakData: Jatak] => values from template
  updateJatakTemplateData(jatakData: any) {
    this._jatakService
      .saveJatakTemplate(jatakData, true)
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: () => {
          NotificationsService.notify('Med succes afsluttet', 'success', 'top');
          // this.jatakForm.markAsPristine();
          // this._router.navigate([this.previousUrl]).then();
        },
        error: (err) => this._handleError(err),
      });
  }

  // Gets video url from child component coop-image-handler after video is uploaded and patches to jatakForm
  // @params [videoUrl:VideoUrls] => the video url object
  // @returns void
  getVideoUrl(videoUrl: any) {
    this.videoUrl = {
      thumbnail: videoUrl.thumbnail,
      url: videoUrl.url,
    };
    this.hasVideo = true;
    this.jatakForm.patchValue({
      video: {
        url: videoUrl.url,
        thumbnail: videoUrl.thumbnail,
      },
    });
  }

  // Gets image url from child component coop-image-handler after image is uploaded and patches to jatakForm
  // @params [images:ImageUrl] => the image url object
  // @returns void
  getImagesUrl(images: any) {
    this.jatakForm.patchValue({
      images: images,
    });
    Object.assign(this.imageUrl, images);
    this.hasImage = true;
    this._patchImageToMultipleImageSlider(images);
  }

  private _patchImageToMultipleImageSlider(images: MultipleImage[]) {
    const urlArray = JatakFormComponent._convertImageDataToURlArray(images);
    const control = this.jatakForm.get('images') as FormArray;
    // clearing array;
    while (control.length !== 0) {
      control.removeAt(0);
    }
    for (const img of urlArray) {
      control.push(new FormControl(img));
    }
  }

  private static _convertImageDataToURlArray(
    imageSliderArray: MultipleImage[]
  ) {
    const urlArray = [];
    for (const img of imageSliderArray) {
      if (img.hasImage) {
        urlArray.push(img.url);
      }
    }
    return urlArray;
  }

  imageProcessing(e: boolean) {
    this.isProcessing = e;
  }

  // Todo: no use
  toggle(option: string) {
    const patchData = {};
    if (option === 'yes') {
      patchData['replyText'] = this.defaultReplyText + this.textAdded;
      if (this.shared.facebook) {
        patchData['replyTextMore'] = this.defaultReplyTextMore + this.textAdded;
      }
    } else if (this.jatak) {
      patchData['replyText'] = this.jatak.replyText;
      if (this.shared.facebook) {
        patchData['replyTextMore'] = this.jatak.replyTextMore;
      }
    } else {
      patchData['replyText'] = this.defaultReplyText;
      if (this.shared.facebook) {
        patchData['replyTextMore'] = this.defaultReplyTextMore + this.textAdded;
      }
    }
    this.jatakForm.patchValue(patchData);
  }

  // go back button using setPreviousUrl
  back() {
    this._router.navigate([this.previousUrl]).then();
  }

  // *** Show/hide MFL popup ***
  // @params [event: boolean] => true/false emitted from child MFL component
  togglePopup(event: boolean) {
    this.showMFLPopup = event;
  }

  // *** Sets up empty form ****
  private _setupJatakForm(): void {
    this.jatakForm = this._formBuilder.group(
      {
        id: [''],
        kardex: [''],
        title: ['', [Validators.required, checkWhiteSpace]],
        description: ['', [Validators.required, checkWhiteSpace]],
        soldOutTitle: ['', [Validators.required, checkWhiteSpace]],
        isVariant: [false],
        variants: this._formBuilder.array([]),
        images: this._formBuilder.array([], [Validators.required]),
        video: this._formBuilder.group({
          url: [''],
          thumbnail: [''],
        }),
        startDate: ['', Validators.required],
        endDate: ['', Validators.required],
        startNow: [true],
        replyText: ['', [Validators.required, checkWhiteSpace]], // pre-populating the field with default val
        quantity: ['', [Validators.required, Validators.min(0)]],
        prices: this._formBuilder.array([
          this._formBuilder.group({
            id: [''],
            price: [
              '',
              [Validators.required, customMinValidator(1), checkPriceRegex],
            ],
            quantity: [
              '',
              [
                Validators.required,
                checkPriceRegex,
                uniquePriceQantity,
                customMinValidator(1),
              ],
            ],
            uom: ['stk'],
          }),
        ]),
        hasAlertQuantity: [false],
        alertQuantity: ['', Validators.required],
        hasCustomerLimit: [false],
        customerLimit: ['', Validators.required],
        shared: this._formBuilder.group({
          facebook: [false],
          coop: [false],
          sms: [false],
          mail: [false],
          web: [false],
          dit: [false],
        }),
        smsText: [''],
        originId: [''],
      },
      { validators: [checkIfCommentDateIsLessThanStartDate] }
    );
  }

  // Todo: This method has no any more function than calling this._checkChildPage() twice
  private _setupRouteData(): void {
    // to hide when order list is showing
    this._router.events
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe((routerEvent) => {
        if (routerEvent instanceof NavigationEnd) {
          this._checkChildPage();
        }
      });
  }

  get priceInfos(): FormArray {
    return this.jatakForm.get('prices') as FormArray;
  }

  addPriceInfo(): void {
    this.priceInfos.push(
      this._formBuilder.group({
        id: [''],
        price: [
          '',
          [Validators.required, customMinValidator(1), checkPriceRegex],
        ],
        quantity: [
          '',
          [Validators.required, checkPriceRegex, uniquePriceQantity],
        ],
        uom: ['stk'],
      })
    );
  }

  get canAddMorePriceInfos(): boolean {
    return this.priceInfos.length < 5;
  }

  // generates random guid for new jatak post
  // patches to this.jatak if parent provide jatak data
  // passes selected channels to this.selectedChannels from resolver
  private _initComp() {
    this.guid = HelperService.guid();
    this.jatak = this.formData;
    this.createSetting = this._activatedRoute.snapshot.data['createOptions'];
    this.selectedChannels = this._activatedRoute.snapshot.data['channels'];
    if (this.createSetting) {
      this.shared = this.createSetting.share;
    }

    this.customerName = TokenService.getUserDetail().name;
    this.greeting = 'Hej <kundenavn>,';
    if (this._store.selectSnapshot(SetJatakRouteState.getData)) {
      this.previousUrl = this._store.selectSnapshot(
        SetJatakRouteState.getData
      ).routeUrl;
    }
    while (this.limit <= 99) {
      this.alertQuantitySelectSettings.data.push(this.limit);
      this.limit++;
    }
  }

  getStartEndTime(event) {
    this.jatakForm.patchValue({
      startDate: event.startDateTime,
      endDate: event.endDateTime,
      startNow: event.start_now,
    });
  }

  setMaxOrder(event) {
    this.showMaxOrder = event.target.checked;
    if (this.showMaxOrder) {
      this.jatakForm.patchValue({
        customerLimit: this.jatak?.hasCustomerLimit
          ? this.jatak.customerLimit
          : this.jatakDefault.hasCustomerLimit
          ? this.jatakDefault.customerLimit
          : 1,
      });
    }
  }

  onManualApproval(event) {
    this.showMaualApproval = event.target.checked;
    if (this.showMaualApproval) {
      this.jatakForm.patchValue({
        alertQuantity: this.jatak?.hasAlertQuantity
          ? this.jatak.alertQuantity
          : this.jatakDefault.hasAlertQuantity
          ? this.jatakDefault.alertQuantity
          : 1,
        phone: this.jatak?.hasAlertQuantity
          ? this._formatNumber.transform(this.jatak.phone)
          : this.jatakDefault.hasAlertQuantity
          ? this._formatNumber.transform(this.jatakDefault.phone)
          : null,
      });
      this.jatakForm
        .get('phone')
        ?.setValidators([Validators.required, checkPhoneNumber]);
      this.jatakForm.get('phone')?.updateValueAndValidity();
    } else {
      this.jatakForm.get('phone')?.clearValidators();
      this.jatakForm.get('phone')?.updateValueAndValidity();
    }
  }

  // to  hide and show response div
  toggleResponse() {
    this.showResponse = !this.showResponse;
  }

  //to hide and show reply
  toggleReply() {
    this.showReply = !this.showReply;
  }

  // create form group for varient add
  private _createVarientFormGroup(): FormGroup {
    return this._formBuilder.group({
      id: new FormControl(''),
      title: new FormControl('', [
        Validators.required,
        checkMaxLengthForVataintTitle,
      ]),
      keyword: new FormControl('', [
        Validators.required,
        uniqueKeyword,
        checkNumberOnly,
      ]),
      quantity: new FormControl('', [Validators.required, Validators.min(0)]),
      price: new FormControl('', [
        Validators.required,
        customMinValidator(1),
        checkPriceRegex,
      ]),
      priceDescription: new FormControl('', [
        Validators.required,
        customMinValidator(1),
        checkPriceRegex,
      ]),
      uom: ['stk'],
    });
  }

  // to get varient as a array
  get varientArray(): FormArray {
    return this.jatakForm.get('variants') as FormArray;
  }

  // to push new varient in varient form group

  addVarientFormGroup() {
    this.varientArray.push(this._createVarientFormGroup());
  }

  // to delete varaint from array
  removeVarient(i: number) {
    const varients = this.jatakForm.get('variants') as FormArray;
    if (varients.length > 2) {
      varients.removeAt(i);
    } else {
      this.showVarient = false;
      this._handleVaraintToggle(this.showVarient);
    }
  }

  removePrices(i: number) {
    const prices = this.jatakForm.get('prices') as FormArray;
    if (prices.length > 1) {
      prices.removeAt(i);
    }
  }

  // to hide and show varient
  toggleVarient(event: any) {
    this.showVarient = event.target.checked;
    this._handleVaraintToggle(this.showVarient);
  }

  private _handleVaraintToggle(open: boolean) {
    this.jatakForm.patchValue({
      isVariant: open,
    });
    if (open) {
      const varaintArrayLength = this.varientArray.length;
      if (varaintArrayLength < 2) {
        for (let i = varaintArrayLength; i < 2; i++) {
          this.addVarientFormGroup();
        }
      } else {
        this._handleJatakDynamicOfferText(this.varientArray.value);
      }
    } else {
      if (this.jatak) {
        this.jatakForm.patchValue({
          quantity: this.jatak.quantity,
        });
      }

      if (this.jatak && this.jatak.prices.length) {
        this.handlePriceandPriceDescription();
      }

      while (this.varientArray.length !== 0) {
        this.varientArray.removeAt(0);
      }

      // this.jatakForm.controls['price'].setValidators([Validators.required, Validators.min(1), checkPriceRegex])
      this.jatakForm.controls['quantity'].setValidators([
        Validators.required,
        Validators.min(0),
      ]);
      this.varientArray.clearValidators();
      const varaintArrayLength = this.varientArray.length;
      for (let i = 0; i < varaintArrayLength; i++) {
        this.jatakForm.controls['variants']['controls'][i].controls[
          'title'
        ].setValidators(null);
        this.jatakForm.controls['variants']['controls'][i].controls[
          'title'
        ].updateValueAndValidity();
        this.jatakForm.controls['variants']['controls'][i].controls[
          'keyword'
        ].setValidators(null);
        this.jatakForm.controls['variants']['controls'][i].controls[
          'keyword'
        ].updateValueAndValidity();
        this.jatakForm.controls['variants']['controls'][i].controls[
          'quantity'
        ].setValidators(null);
        this.jatakForm.controls['variants']['controls'][i].controls[
          'quantity'
        ].updateValueAndValidity();
        this.jatakForm.controls['variants']['controls'][i].controls[
          'price'
        ].setValidators(null);
        this.jatakForm.controls['variants']['controls'][i].controls[
          'price'
        ].updateValueAndValidity();
        this.jatakForm.controls['variants']['controls'][i].controls[
          'priceDescription'
        ].setValidators(null);
        this.jatakForm.controls['variants']['controls'][i].controls[
          'priceDescription'
        ].updateValueAndValidity();
      }
      this.varientArray.updateValueAndValidity();
    }
  }

  // handle jatak dynamic data
  private _handleJatakDynamicOfferText(data: any[]) {
    let toBePatchValue = '';
    for (let i = 0; i < data.length; i++) {
      if (data[i].title == '' && data[i].keyword == '') {
        break;
      }
      this.varainInfoShow = true;
      const description = data[i].title;
      const jatakStaticText = ' -> skriv ';
      const keywordText = 'JA TAK ' + data[i].keyword;
      const antal = ' &lt;antal&gt;';
      toBePatchValue +=
        description + jatakStaticText + keywordText + antal + '<br/>';
    }
    this.varaintInfo = toBePatchValue;
  }

  // handle all character count div of input field
  onSelect(id: any) {
    if (id === 'title') {
      this.visible['title'] = true;
      this.visible['description'] = false;
      this.visible['robotReply'] = false;
      this.visible['soldOutTitle'] = false;
      this.visible['soldOutReply'] = false;
    }
    if (id === 'description') {
      this.visible['title'] = false;
      this.visible['description'] = true;
      this.visible['robotReply'] = false;
      this.visible['soldOutTitle'] = false;
      this.visible['soldOutReply'] = false;
    }
    if (id === 'robotReply') {
      this.visible['title'] = false;
      this.visible['description'] = false;
      this.visible['robotReply'] = true;
      this.visible['soldOutTitle'] = false;
      this.visible['soldOutReply'] = false;
    }
    if (id === 'soldOutTitle') {
      this.visible['title'] = false;
      this.visible['description'] = false;
      this.visible['robotReply'] = false;
      this.visible['soldOutTitle'] = true;
      this.visible['soldOutReply'] = false;
    }
    if (id === 'soldOutReply') {
      this.visible['title'] = false;
      this.visible['description'] = false;
      this.visible['robotReply'] = false;
      this.visible['soldOutTitle'] = false;
      this.visible['soldOutReply'] = true;
    }
  }

  onTitleSelect(i: number) {
    this.showVaraintTitle = i;
  }

  // to show keyword default warning
  onKeywordSelect(i: number) {
    this.showKeywordWarning = i;
  }

  // *** Sets default time on create form ***
  // @params [eventForm: FormGroup] => the form where default dates are to be patched
  // startDate has current time and endDate has that of 7 days later.
  private _setDefaultTimeOnCreate() {
    if (this.createSetting) {
      const startDate = dayjs().format('YYYY-MM-DD HH:mm');
      const endDate = dayjs().add(6, 'days').format('YYYY-MM-DD HH:mm');
      const currForm = this.jatakForm;
      currForm.patchValue({
        startDate: startDate,
        endDate: endDate,
      });
    }
  }

  // remove spacing between numbers of mobile phone

  _removeSpacing() {
    this.jatakForm.value.phone = this.jatakForm.value.phone.replace(/ /g, '');
  }

  // some JatakForm values are fixed here just before submission
  private _prepareFormValues() {
    this.jatakForm.controls['startDate'].enable();

    // setting template values
    if (this.editMode) {
      if (!this.shared.sms) {
        this.jatakForm.patchValue({
          smsText: '',
        });
      }
    }
    if (!this.editMode) {
      this.jatakForm.removeControl('id');
    }
    if (this.templateMode) {
      this.jatakForm.value.templateId = this.offerId;
      if (!this.shared.sms) {
        this.jatakForm.value.smsText = null;
      }
    }

    // changing the date format
    this.jatakForm.value.startDate = this._dateToString.transform(
      this.jatakForm.value.startDate
    );
    this.jatakForm.value.endDate = this._dateToString.transform(
      this.jatakForm.value.endDate
    );

    this.jatakForm.value.shared = this._convertBoolToNum();

    // add guid to origin id if not already present
    if (!this.jatakForm.value.originId) {
      this.jatakForm.value.originId = this.guid;
    }

    // patch new guid into origin id for template mode
    if (!this.editMode) {
      this.jatakForm.value.originId = this.guid;
    }

    // get the sms text

    if (this.clients) {
      if (this.shared.sms) {
        this.jatakForm.value.smsText = this.smsForm.getSmsText();
      }
    }
    if (this.shared.facebook) {
      this._removeSpacing();
    }
  }

  // *** set up for sms ***
  private _setUpSms() {
    this.isProcessing = true;
    this._clientService
      .getClients()
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (clients) => {
          this.clients = clients;
          this.isProcessing = false;
        },
        error: (err) => this._handleError(err),
      });
    this.isProcessing = false;
  }

  // It set up fields for both create and edit form just after being loaded and after channel selections
  private _setUpFields() {
    /**
     * Show the following fields only when Facebook option is selected
     */
    if (this.shared.facebook) {
      this.jatakForm.addControl(
        'soldOutReply',
        new FormControl('', {
          validators: [Validators.required, checkWhiteSpace],
        })
      );
      this.jatakForm.addControl(
        'phone',
        new FormControl('', [Validators.required, checkPhoneNumber])
      );

      return;
    }
    this.jatakForm.removeControl('soldOutReply');
    this.jatakForm.removeControl('phone');
  }

  // *** Updates formControl's validation ***
  // @params [controlName:string] =>  The control for validation update
  // @params [validators: Array<ValidatorFn> | ValidatorFn] => The validation to apply to the control
  private _updateValidators(formControl: string, validators = []): void {
    this.jatakForm.get(formControl).setValidators(validators);
    this.jatakForm.get(formControl).updateValueAndValidity();
  }

  // *** Used to patch current image or video into edit form ***
  private _patchFormMedias() {
    const jatakValues = this.jatak;
    this.isVideo = this.jatak.images === null;
    this._patchMultipleMedia(this.isVideo, jatakValues);
  }

  // *** It patches either image or video into jatakForm ***
  // @params [ isVideo: boolean] => Is current media video?
  // @params [values: Jatak] => Jatak details (used in case of video in edit form)
  private _patchMultipleMedia(isVideo: boolean, vals: any) {
    const currForm = this.jatakForm.value;
    let imgUrl;
    if (!isVideo) {
      this.imageUrl = ImageService.getPatchableUrl(currForm.images);
      this.hasImage = true;
      imgUrl = this.imageUrl;
      const lastIndexOfDot = imgUrl.lastIndexOf('.');
      const fileFormat = imgUrl.slice(lastIndexOfDot);
      if (fileFormat === '.gif') {
        this.isGif = true;
      }
    }
    if (isVideo) {
      this.videoUrl = vals.video;
      this.hasVideo = true;
      this.changeUploadType(isVideo);
      // we need to clear image in form because while patching , form array forms a formControl and hence largeImage is not empty and valid
      this.clearImageUrl(this);
    }
  }

  private _patchingMultipleImageToForm() {
    this.isVideo = this.jatak.images === null;
    if (!this.isVideo && !this.jatakForm.get('images').value.length) {
      const controlImages = this.jatakForm.get('images') as FormArray;
      for (const url of this.jatak.images) {
        controlImages.push(new FormControl(url));
      }
    }
  }

  // *** Patch the value of event from resolver to the form and removes unused channels for Jatak from templates***

  private _builtEditForm() {
    this.shared = this.jatak.shared;
    this.smsText = this.jatak.smsText
      ? this.jatak.smsText
      : `${this.jatak.title}\n${this.jatak.description}`;
    // if there is a smsTExt already assign it to smsTEXT
    if (this.templateMode) {
      this._setAllowedChannels(false);

      // using data from channel service when isSet is true else use the shared data from API
      this.shared =
        this.selectedChannels && this.selectedChannels.isSet
          ? JSON.parse(JSON.stringify(this.selectedChannels.selected))
          : this.jatak.shared;
    }
    if (this.jatak.isVariant) {
      this.showVarient = this.jatak.isVariant;
      for (let i = 0; i < this.jatak.variants.length; i++) {
        this.addVarientFormGroup();
      }
      this.jatakForm.patchValue(this.jatak);
      this._handleJatakDynamicOfferText(this.jatak.variants);
    }
    this.setShareStatus();
    this._setUpFields();

    this.jatakForm.patchValue(this.jatak);
    if (this.jatak.prices.length) {
      this.handlePriceandPriceDescription();
    } else {
      // this.addPriceInfo()
    }
    // this.patchsSoldOutTitleandalertQuantity()
    if (!this.editMode && !this.templateMode) {
      this.jatakForm.patchValue({
        replyText: '',
      });
      const startDate = DateFormatService.timeIntervalFormatter(dayjs());

      const endDate = DateFormatService.timeIntervalFormatter(
        dayjs().add(7, 'days')
      );
      const currForm = this.jatakForm;
      currForm.patchValue({
        startDate: startDate,
        endDate: endDate,
      });
    }
    if (this.jatak.own) {
      const currentDate = dayjs();
      if (currentDate.isAfter(this.jatak.endDate)) {
        const startDate = DateFormatService.timeIntervalFormatter(dayjs());
        const endDate = DateFormatService.timeIntervalFormatter(
          dayjs().add(7, 'days')
        );
        const currForm = this.jatakForm;
        currForm.patchValue({
          startDate: startDate,
          endDate: endDate,
        });
      } else if (currentDate.isAfter(this.jatak.startDate)) {
        const startDate = DateFormatService.timeIntervalFormatter(dayjs());
        const currForm = this.jatakForm;
        currForm.patchValue({
          startDate: startDate,
          endDate: this.jatak.endDate,
        });
      } else {
        const currForm = this.jatakForm;
        currForm.patchValue({
          startDate: this.jatak.startDate,
          endDate: this.jatak.endDate,
        });
      }
    }

    if (this.shared.facebook) {
      // this._patchSoldOutReplyAndPhone();
    }
    this._buildPreviewMessage();
    if (this.jatak.fbPostId) {
      this.hasBeenPosted = true;
    }
    this._patchingMultipleImageToForm();
    this._patchFormMedias();
    if (this.templateMode) {
      // this._patchDefaultTimeInTemplateMode();
    }
  }

  private handlePriceandPriceDescription() {
    const pricesArray = this.jatakForm.get('prices') as FormArray;
    pricesArray.clear(); // Clear the array before populating with new data

    this.jatak.prices.forEach((priceInfo: any) => {
      pricesArray.push(
        this._formBuilder.group({
          id: [priceInfo.id],
          price: [
            priceInfo.price,
            [Validators.required, customMinValidator(1), checkPriceRegex],
          ],
          quantity: [
            priceInfo.quantity,
            [Validators.required, checkPriceRegex],
          ],
          uom: [priceInfo.uom.toLowerCase()],
        })
      );
    });
  }

  private _buildPreviewMessage() {
    this.finaltext =
      this.defaultStaticTextStart +
      this.jatak.title +
      this.defaultStaticTextEnd;
    this.finaltextPreview =
      this.defaultStaticTextStart +
      this.jatak.title +
      this.defaultReplyTextMore;
    this.replyTextMessage = !this.editMode ? '' : this.jatak.replyText;
  }

  // It sets allowed channel for jatak templates
  private _setAllowedChannels(createMode: boolean) {
    if (!createMode) {
      this.allowedChannels = this.shared;
      return;
    }
    if (this.shared.coop || this.shared.facebook) {
      this.allowedChannels['web'] = true;
    }
  }

  // *** It converts true/false into 1/0 ***
  // @returns [shared:Shared] => with 1/0 instead of true/false
  private _convertBoolToNum(): Shared {
    let shared: Shared = DefaultSharedValue;
    for (let channel in this.shared) {
      shared[channel] = +this.shared[channel];
    }
    return shared;
  }

  // TODO: used in Mobiscroll onShow option
  // *** Sets up dateTime for create mode ***
  // @params [inst: Scroller] => current date-time options and value
  private _setupDateTime(inst: DatepickerBase) {
    const dateId = inst._el.id;
    // ** Create Mode ** //
    if (!this.editMode && !this.templateMode) {
      // set start date at todays time 8:00
      if (dateId === 'startDate' && !this.jatakForm.value.startDate) {
        this.jatakForm.patchValue({
          startDate: dayjs().hour(8).minute(0).toDate(),
        });
      }
      // end date picker clicked
      // if start date is empty set time to today at 20:00
      if (
        dateId === 'jatakEndDate' &&
        !this.jatakForm.value.startDate &&
        !this.jatakForm.value.endDate
      ) {
        this.jatakForm.patchValue({
          endDate: dayjs().hour(20).minute(0).toDate(),
        });
      }
      if (
        dateId === 'jatakEndDate' &&
        this.jatakForm.value.startDate &&
        !this.jatakForm.value.endDate
      ) {
        const startDate = dayjs(this.jatakForm.value.startDate);
        this.jatakForm.patchValue({
          endDate: startDate.hour(20).minute(0).toDate(),
        });
      }
    }
  }

  // ** Prepare Form Data and check Validation ** //
  // @return [boolean] => return false if form is invalid
  private _isValid() {
    if (
      this.jatakForm.status === 'INVALID' ||
      (this.shared.sms && this.emojiError)
    ) {
      this.imageError = this.jatakForm.get('images').errors?.['required'];
      if (this.shared.sms && this.emojiError) {
        this.showEmojiError = true;
        this.isValidityChecked = true;
      }

      this._handleError('');
      return false;
    }
    return true;
  }

  // *** This method is evoked after saving new jatak or jatak from template ***
  // @params [type: string] => 'post' or 'template'
  private _success(type: string): void {
    this.successUrl = '/butikker/campaign';
    this._createOptionService.clearCreateOption();
    this.isProcessing = this.formSubmit = false;
    this.formSaved = true;
    if (type === 'post') {
      NotificationsService.notify('Ja tak er oprettet', 'success', 'top');
      this._router
        .navigate([this.successUrl], { queryParams: { type: 'jatak' } })
        .then();
    }
    if (type === 'template') {
      NotificationsService.notify('Slettet med succes', 'success', 'top');
      this._router.navigate([this.previousUrl]).then();
    }
  }

  // *** Removes jatak from the calendar ***
  // @params [state: boolean] => true or false from notification confirmation
  // @params [id: string] => The jatak id
  private _deletePost(state: boolean, id: string) {
    if (state) {
      return this._jatakService.deleteJatak(id);
    }
    return EMPTY;
  }

  // *** Removes jatak from the template lists ***
  // @params [state: boolean] => true or false from notification confirmation
  // @params [id: string] => The jatak id
  private _deleteTemplate(state: boolean, id: string) {
    if (state) {
      return this._jatakService.deleteJatakTemplate(id);
    }
    return EMPTY;
  }

  onToggleReplyInfo() {
    this.showReplyInfo = !this.showReplyInfo;
  }

  // Check if the route is child route or parent route
  // if child route set isParentPage as false
  private _checkChildPage(): void {
    document.body.classList.add('overlay');
    this.showParent = this._activatedRoute.children.length
      ? 'invisible'
      : 'visible';
  }

  goToOrderList(id: string) {
    this.orderListUrl = `/butikker/ja-tak/orderlist/`;
    this._router.navigate([`${this.orderListUrl}/${id}`]);
    // this._store.dispatch(new SetJatakRouteStates({
    //   routeUrl : this._router.url
    // }))
  }

  duplicate(id: string) {
    this.destroyImageHandler = true;
    NotificationsService.confirmWithCallback(
      'Dette vil kopiere teksten og billedet fra dette indlæg til en ny JA TAK. Denne JA TAK vil ikke blive påvirket. Vil du fortsætte?',
      (accept) => {
        if (accept) {
          let routeToUrl = `/butikker/ja-tak/create/`;
          this._router.navigate([routeToUrl + id]).then();
        }
      }
    );
  }

  private _handleError(error: any) {
    this.isProcessing = false;
    if (error.status === 422 && error.errors.smsText) {
      NotificationsService.notify(
        'Indsæt venligst ingen emoji-tegn (de ikke understøttes).',
        'danger',
        'top'
      );
      return;
    }
    this._errorHandlerService.handleError(error || { code: -400 }, 'ja-tak');
  }
}
