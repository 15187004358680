<section
  class="app-page list-component jatak-list"
  [@parentAnimate]="showParent"
>
  <div class="fixed-header">
    <coop-pageheader
      [pageTitle]="pageTitle"
      [isChild]="true"
      [routeTo]="parentUrl"
      [section]="section"
    ></coop-pageheader>
    <div class="fixed-header-content">
      <ul class="tabs three-col">
        <li
          class="tab-first"
          (click)="tab('1', $event)"
          [class.active]="activeTab === '1'"
        >
          Aktiv
        </li>
        <li
          class="tab-second"
          (click)="tab('2', $event)"
          [class.active]="activeTab === '2'"
        >
          Fremtidige
        </li>
        <li
          class="tab-third"
          (click)="tab('0', $event)"
          [class.active]="activeTab === '0'"
        >
          Arkiv
        </li>
        <hr/>
      </ul>
      <div clickOutside (clickOutside)="onOutsideClick()">
        <div class="searchsortwrapper">
          <div [class.schedule-search-box]="activeTab === '2'" class="search-containers">
            <div [class.searchclose]="inputInSearch" class="search-box">
              <input
                type="text"
                placeholder="Søg"
                #searchBox
                (keyup.enter)="search(searchBox.value)"
              />
              <i class="search-button right-search icon icon-search-normal"
                 *ngIf="!searchString"
                 (click)="search(searchBox.value)"></i>
              <i
                class="icon icon-close-circle search-button left-close"
                (click)="closeSearch()"
                *ngIf="searchString"
              ></i>
            </div>
          </div>
          <div style="cursor: pointer" *ngIf="activeTab !== '2'" (click)="toggleSortOptions()"
               [ngClass]="showSortOptions? 'sort shortactive' : 'sort' ">
            <i
              class="icon icon-arrow-up-down"
            ></i>
          </div>
        </div>

        <div *ngIf="showSortOptions && activeTab !== '2' " class="sortby">
          <div (click)="onSortClicked(1)" style="cursor: pointer"
               id="recent" class="sort-options">
            <div>
              <p>Seneste</p>
            </div>
            <div class="check-box" [class.activesort]="sortBy === 1">
              <i *ngIf="sortBy != 1" class="icon icon-stop"></i>
              <i *ngIf="sortBy == 1" class="icon icon-tick-square"></i>
            </div>
          </div>
          <div (click)="onSortClicked(2)" id="trunover" class="sort-options">
            <div style="cursor: pointer">
              <p>Højeste omsætning</p>
            </div>
            <div class="check-box" [class.activesort]="sortBy === 2">
              <i *ngIf="sortBy != 2" class="icon icon-stop"></i>
              <i *ngIf="sortBy == 2" class="icon icon-tick-square"></i>
            </div>
          </div>
          <div (click)="onSortClicked(3)" class="sort-options">
            <div style="cursor: pointer">
              <p>Mest solgte</p>
            </div>
            <div class="check-box" [class.activesort]="sortBy === 3">
              <i *ngIf="sortBy != 3" class="icon icon-stop"></i>
              <i *ngIf="sortBy == 3" class="icon icon-tick-square"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div [ngClass]="showSortOptions  ? 'page-container-active' : 'page-container'">
    <div *ngFor="let item of jatakList ; let i = index" class="item-lists">
      <div (click)="edit(item.id)" class="item-title">
        <p>{{item.title}}</p>
      </div>
      <div class="item-details swiper-container">
        <swiper-container #swiper init="false">
          <swiper-slide>
            <div class="fb-info">
              <div><i class="icon icon-moneys"></i></div>
              <div style="margin-left: 10px"><span class="infos">{{item.turnover}} </span>kr.</div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="fb-info ">
              <div><i class="icon icon-profile-users"></i></div>
              <div style="margin-left: 10px"><span class="infos">{{item.customer}} </span>kunder</div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="fb-info  sold-info">
              <div><i class="icon icon-card-tick"></i></div>
              <div style="margin-left: 10px"><span class="infos">{{item.sold}} </span>solgt</div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="fb-info">
              <div><i class="icon icon-graph"></i></div>
              <div style="margin-left: 10px;"><span class="infos">{{item.stockLeft}} </span>tilbage</div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="fb-info">
              <div><i class="icon icon-bag"></i></div>
              <div style="margin-left: 10px"><span class="infos">{{item.orders}} </span>ordre</div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="fb-info swiper-slide">
              <div><i class="icon icon-bag-tick"></i></div>
              <div style="margin-left: 10px"><span class="infos">{{item.pickedUp}} </span>afhentet</div>
            </div>
          </swiper-slide>
        </swiper-container>
        <div (click)="handleNextClick(swiper)" class="swiper-button-next1 arrow-right">
          <i class="icon icon-arrow-right"></i>
        </div>
        <div class="swiper-button-prev arrow-right">
          <i class="icon icon-arrow-right"></i>
        </div>
      </div>
      <div class="details">
        <div>
          <div (click)="edit(item.id)" class="item-image">
            <img src="{{item.image}}">
            <div *ngIf="item.isVariant" class="info">
              <div>
                <i class="icon icon-data"></i>
              </div>
              <div [ngClass]="item.totalSoldVariant == item.variantCounts ? 'soldout' : ''  " style="margin-left: 10px">
                <p> {{item.totalSoldVariant}} / {{item.variantCounts}} </p>
                <span *ngIf="item.totalSoldVariant !== item.variantCounts">udsolgte varianter </span>
                <span *ngIf="item.totalSoldVariant == item.variantCounts">Alle varianter er udsolgt </span>
              </div>
            </div>
          </div>
        </div>
        <div class="item-info">
          <div>
            <div class="time-info">
              <div>
                <i class="icon icon-clock"></i>
              </div>
              <div style="margin-left: 5px;">
                <p>{{ item.startDate | dateformat : false }}</p>
              </div>
            </div>
            <div class="time-info">
              <div>
                <i class="icon icon-clock-filled"></i>
              </div>
              <div style="margin-left: 5px;">
                <p>{{ item.endDate | dateformat : false }}</p>
              </div>
            </div>
          </div>
          <div>
            <div *ngIf="item.actionRequired" class="action">
              <div><i class="icon icon-info-circle"></i></div>
              <div class="warning-text"><p>Handling påkrævet</p></div>
            </div>
            <div class="order-list-button">
              <div (click)="orderList(item.id)" class="order-button">
                <div
                  class="login-btn btn-black-login "
                  [routerLink]="['orderlist', item.id]"
                >Ordreliste
                </div
                >
              </div>
              <div (click)="toggleButtonOptions(item.id)" class="btn-options">
                <i class="icon icon-more"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="showButtonOptionsById === item.id ">
        <hr class="seperater"/>
        <div class="button-options">
          <ng-container *ngIf="this.activeTab === '0'">
            <a
              (click)="edit(item.id)"
              class="login-btn btn-white-login button-wrapper"
            >
              <div style="margin-right: 5px"><i class="icon icon-more-triangle"></i></div>
              <div><p>Åben</p></div>
              <!--              Åben-->
            </a>
          </ng-container>
          <ng-container *ngIf="this.activeTab !== '0'">
            <a
              (click)="edit(item.id)"
              class="login-btn btn-white-login  button-wrapper"
            >
              <div style="margin-right: 5px"><i class="icon icon-edit"></i></div>
              <div><p>Redigere</p></div>
            </a>
          </ng-container>
          <a
            (click)="duplicate(item.id)"
            class="login-btn btn-white-login button-wrapper duplicate"
          >
            <div style="margin-right: 5px"><i class="icon icon-document-copy"></i></div>
            <div><p>Duplikere</p></div>
          </a>
        </div>
        <div *ngIf="item.canSaveAsTemplate">
          <a
            (click)="saveJatakTemplate(item.id)"
            class="login-btn btn-white-login template button-wrapper "
          >
            <div style="margin-right: 5px"><i class="icon icon-archive-add"></i></div>
            <div><p>Gem som skabelon</p></div>
          </a>
        </div>
      </div>
    </div>
    <h5 class="empty-message" *ngIf="jatakList.length === 0">
      Beklager, ingen varer tilgængelige
    </h5>

    <div>
      <a
        class="login-btn btn-white-login btn-medium"
        (click)="loadMore()"
        *ngIf="last_page !== page  && jatakList.length !== 0 "
      >Indlæs mere..
      </a>
    </div>
    <div>
      <a
        (click)="createJatak()"
        class="big-add-btn add-btn show-hide-btn add-jatak flex-center"
      >
        <i class="icon icon-add"></i>
      </a>
    </div>
  </div>
</section>
<router-outlet></router-outlet>

<!--<app-campaigns></app-campaigns>-->
