<section
  class="app-page list-component order-list"
  [@parentAnimate]="showParent"
  [class.anim-fix-enter]="showParent === 'visible'"
>
  <div class="fixed-header">
    <coop-pageheader
      [pageTitle]="pageTitle"
      [isChild]="true"
      [routeTo]="previousUrl"
    ></coop-pageheader>
  </div>
  <div class="page-container">
    <div class="item-list">
      <ng-container *ngFor="let order of finishedOrderList">
        <a class="item" routerLink="{{ currentUrl }}/redigere/{{ order.id }}">
          <span
            [className]="'dot' + ' ' + deliveryStatus[order.deliveryStatus]"
          ></span>
          <div class="item-detail">
            <div class="item-order d-flex">
              <div>
                <h3 class="item-title">#{{ order.orderNumber }}</h3>
                <span> {{ order.category }}</span>
              </div>
              <h3 class="item-title">
                {{ order.totalPrice | numberpad : 'da-DA' }}
              </h3>
            </div>
            <div class="item-date d-flex">
              <span>{{ order.bookingDate | dateformatddmmyy }}</span>
              <span>{{ deliveryStatus[order.deliveryStatus] }}</span>
            </div>
          </div>
        </a>
      </ng-container>
      <h5 class="empty-message" *ngIf="finishedOrderList.length === 0">
        Beklager, ingen ordrer tilgængelige
      </h5>
    </div>
    <a
      class="login-btn btn-white-login btn-medium"
      (click)="loadMore()"
      *ngIf="!(finishedOrderList.length < page * limit)"
      >Indlæs mere..</a
    >
  </div>
</section>
<router-outlet></router-outlet>
