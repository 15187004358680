import { inject, Injectable } from '@angular/core';
import { CanActivateFn, CanDeactivateFn, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { NotificationsService, TokenService } from 'app/core/services';
import { SpecialPollCreateEditComponent } from 'app/features/poll';

@Injectable({
  providedIn: 'root'
})
export class SpecialPollGuard {
  constructor(private _router: Router) {
  }

  canDeactivate(
    component: SpecialPollCreateEditComponent
  ): Observable<boolean> | boolean {
    if (component.specialPollForm.dirty && !component.formSaved) {
      const pollTitle =
        component.specialPollForm.get('title').value || 'Ny afstemning';
      return NotificationsService.confirm(
        `Dine ændringer til ${pollTitle} bliver ikke gemt.`
      );
    }
    return true;
  }

  canActivate(): boolean {
    if (TokenService.getUserType() === '1') {
      this._router.navigate(['']).then();
      return false;
    }
    return true;
  }
}

export const SpecialPollGuardCanDeactivate: CanDeactivateFn<SpecialPollCreateEditComponent> = (component) => {
  return inject(SpecialPollGuard).canDeactivate(component);
};

export const SpecialPollGuardCanActivate: CanActivateFn = () => {
  return inject(SpecialPollGuard).canActivate();
};
