import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {FormFunctionsService, HelperService} from 'app/core/services';
import {
  checkIfEndDateIsLessThanStartDate,
  checkIfExpDateIsLessThanPublishDate,
  checkIfExpireEndMismatch, checkMaxLength,
  checkPriceMinimum,
  checkPriceRegex,
  checkWhiteSpace, customMinValidator,
  maxLengthOfEight
} from 'app/core/validators';
import {Modes, ValidationMessage} from 'app/shared/interface';
import {
  COMMON_DATETIMEPICKER_SETTINGS,
  COMMON_SELECT_SETTINGS
} from 'app/shared/data';
import {
  INITIAL_FORM_DATA,
  NyhedTilbud, OfferDataService,
  OfferDateTimeService,
  OfferStateService
} from 'app/features/offer';
import * as moment from 'moment/moment';
import * as _ from 'lodash';
import {MbscDatepickerOptions} from '@mobiscroll/angular';
import {DatepickerBase} from '@mobiscroll/angular/dist/js/core/components/datepicker/datepicker';

@Component({
  selector: 'coop-dit-form',
  templateUrl: './dit-form.component.html',
  styleUrls: ['./dit-form.component.scss']
})
export class DitFormComponent implements OnInit, OnChanges {
  @Input() modes: Modes;
  @Input() validationMessages: ValidationMessage;
  @Input() validateModes = [];
  @Input() currentTab = 'coop';
  @Input() offerType: string = 'tilbud';
  @Output() formReady = new EventEmitter<FormGroup>();
  formData?: NyhedTilbud = INITIAL_FORM_DATA;
  ditForm: FormGroup;
  imageUrl = {
    largeImage: 'assets/images/camera@3x.png',
    thumbnail: ''
  };
  hasImage = false;
  isProcessing = false;
  isPostedToFb: boolean = false;
  currentDateTime: string;
  visible: boolean = false;
  numbers = [];
  priceDescSelectSettings = {
    ...COMMON_SELECT_SETTINGS, ...{
      data: [
        'stk',
        'kg',
        'g',
        'liter',
        'ml',
        'pakke',
        'pakker',
        'sæt',
        'kasse',
        'kasser',
        'ramme',
        'rammer'
      ]
    }
  };
  datePickerSettings: MbscDatepickerOptions = {
    ...COMMON_DATETIMEPICKER_SETTINGS, ...{
      dateWheels: 'YYYY MMMM DD',
      dateFormat: 'YYYY-MM-DD',
      stepMinute: 15,
      onOpen: (_, inst) => this._setUpDateTime(inst),
      onClose: (_, inst) => this._closeDatePicker(inst)
    }
  };
  titleCount: any = 0;

  showPriceSection: boolean = false;
  validFrom:string = 'Gyldig fra - nu';
  validTill="Gyldig til"
  dispatchTime = "Udsendingstidspunkt - nu";
  closingTime = "Afslutningstidspunkt"
  constructor(
    private _formBuilder: FormBuilder,
    private _formStateService: OfferStateService
  ) {
    this.currentDateTime = moment().format('YYYY-MM-DD HH:mm');

  }

  ngOnInit() {
    this.initializeForms();
    this.ditForm.get('title').valueChanges.subscribe((res) => {
      this.titleCount = this.calculateLength(res);
    });

  }

  calculateLength(str: string) {
    return _.size(str);
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['offerType']) {
      this.offerType = changes['offerType'].currentValue;
    }
    if (changes['modes']) {
      this.modes = changes['modes'].currentValue;
    }
    if (changes['currentTab']) {
      if (changes['currentTab'].currentValue === 'dit') {
        this.initializeForms();
      }
    }
  }

  initializeForms() {
    if (!this.ditForm) {
      this._setupDITForm();
    }
    if (!this.modes.newsMode && (this.offerType === 'tilbud')) {
      this._setPriceValidators();
    }
    this.formData = this._formStateService.formState.value;
    if (
      this.formData.dit?.isSet ||
      this.modes.editMode ||
      this.modes.templateMode
    ) {
      this.getForm.patchValue(this.formData.dit);
      this.getForm.patchValue({
        uom: this.formData.dit.uom.toLowerCase()
      });
      if (!this.modes.newsMode && this.offerType === 'good-price' && this.formData.dit.price !== '') {
        this.showPriceSection = true;
        this.updateValidation();
      }
      this.imageUrl = {
        largeImage: this.formData.dit.largeImage,
        thumbnail: this.formData.dit.largeImage
      };
      this.hasImage = true;
    }

    if (this.modes.editMode) {
      if (this.formData.dit && this.formData.dit.startDate) {
        if (this.currentDateTime > this.formData.dit.startDate) {
          this.isPostedToFb = true;
        }
      }
    }
    if (!this.modes.editMode && !this.modes.templateMode) {
      this.createUpDitForm();
    }
    if (!this.modes.newsMode && this.offerType === 'tilbud') {
      this.showPriceSection = true;
    }
    this.formReady.emit(this.ditForm);
  }

  public get getForm() {
    return this.ditForm;
  }

  createUpDitForm() {
    const ditForm = this.getForm;
    const firstValidOption = this.validateModes[0];
    const parentForm = this.formData[firstValidOption];
    const ditImageUrl = this.formData.dit?.largeImage ? this.formData.dit?.largeImage : this.hasImage = false;
    if (firstValidOption !== 'dit') {
      if (FormFunctionsService.checkparentFormEmpty(parentForm)) {
        return;
      }
      console.log(this.ditForm.value, 'dit form value')
      if (FormFunctionsService.checkFormEmpty(ditForm.value)) {
        const parentImgUrl = this.getImageForDIT(firstValidOption);
        const imageUrl = ditImageUrl ? ditImageUrl : parentImgUrl;
        ditForm.patchValue({
          title: parentForm.title,
          longDescription: OfferDataService.descriptionChanges(firstValidOption, this.modes.newsMode, parentForm.longDescription),
          publishDate: parentForm.publishDate,
          expirationDate: parentForm.expirationDate,
          price: parentForm.inAppParameters.price,
          dutyText: parentForm.inAppParameters.priceDescription,
          uom: parentForm.inAppParameters.uom,
          largeImage: imageUrl,
          startDate: parentForm.startDate,
          endDate: parentForm.endDate
        });

        if (!ditImageUrl && parentImgUrl) {
          this.imageUrl.largeImage = parentImgUrl;
          this.hasImage = true;
        }
        if (firstValidOption === 'sms') {
          // sms doesnt have other dates, so we set to default
          OfferDateTimeService.setDefaultTimeOnCreate(ditForm);
        }
      }

      if (!this.modes.newsMode && this.offerType === 'good-price') {
        this.showPriceSection = this.checkInAppParametersValues(parentForm)
        ditForm.patchValue({
          price: parentForm.inAppParameters.price,
          dutyText: parentForm.inAppParameters.priceDescription,
          uom: parentForm.inAppParameters.uom,
        })
      }
    }
    // set default date to now on create
    if (FormFunctionsService.checkFormEmpty(ditForm.value)) {
      OfferDateTimeService.setDefaultTimeOnCreate(ditForm);
    }
    if (ditImageUrl && !this.getForm.value.largeImage) {
      this.getForm.patchValue({
        largeImage: ditImageUrl
      });
    }
  }

  getImageForDIT(firstChannel): string {
    let parentForm = this.formData;
    let parentImgUrl = Array.isArray(parentForm[firstChannel].largeImage) ? parentForm[firstChannel].largeImage[0] : parentForm[firstChannel].largeImage;
    return parentImgUrl;
  }

  getImagesUrl(images: any) {
    this.ditForm.patchValue({
      largeImage: images.largeImage
    });
    Object.assign(this.imageUrl, images);
    this.hasImage = true;
  }

  imageProcessing(e: boolean) {
    this.isProcessing = e;
  }

  validateNumber(event) {
    HelperService.preventInvalidNumbers(event);
  }

  private _setupDITForm() {
    const validators = [checkIfExpDateIsLessThanPublishDate];
    if (!this.modes.newsMode) {
      validators.push(checkIfExpireEndMismatch);
      validators.push(checkIfEndDateIsLessThanStartDate);
    }
    this.ditForm = this._formBuilder.group(
      {
        title: ['', [Validators.required, checkWhiteSpace, checkMaxLength]],
        longDescription: ['', [Validators.required, checkWhiteSpace]],
        largeImage: ['', Validators.required],
        publishDate: ['', Validators.required],
        expirationDate: ['', Validators.required],
        startDate: ['', Validators.required],
        endDate: ['', Validators.required],
        startNow: [],
        publishNow: [],
        dutyText: [''],
        price: [''],
        uom: [''],
        weight: ['']
      },
      {
        validators: validators
      }
    );
  }

  checkInAppParametersValues(parentForm): boolean {
    if (parentForm) {
      const price = parentForm.inAppParameters.price
      const priceDescription = parentForm.inAppParameters.priceDescription;
      const uom = parentForm.inAppParameters.uom;
      return price !== '' && priceDescription !== '' && uom !== '';
    }
    return false;
  }

  private updateValidation() {
    const validators = [checkPriceMinimum, checkPriceRegex];
    validators.push(Validators.required);
    this._updateValidators("dutyText", [
      Validators.required,
      checkWhiteSpace,
      checkPriceRegex
    ]);
    this._updateValidators("price", [
      Validators.required,
      checkWhiteSpace,
      checkPriceRegex
    ]);
  }


  addPriceField() {
    this.showPriceSection = true
    this.updateValidation()
    this.ditForm.patchValue({
      uom: 'stk'
    })
  }

  removePrices() {
    this.showPriceSection = false
    this.removePriceValidators()
  }

  removePriceValidators(): void {
    this._updateValidators("price", null);
    this._updateValidators("dutyText", null);
    this.ditForm.patchValue({
      price: '',
      dutyText: '',
      uom: ''
    })
  }

  private _setPriceValidators() {
    this._updateValidators('price', [checkPriceMinimum, checkPriceRegex, Validators.required, customMinValidator(1)]);
    this._updateValidators('dutyText', [checkPriceMinimum, checkPriceRegex, Validators.required, customMinValidator(1)]);
    this._updateValidators('weight', [maxLengthOfEight]);
  }

  private _updateValidators(formControl: string, validators = []): void {
    this.ditForm.get(formControl).setValidators(validators);
    this.ditForm.get(formControl).updateValueAndValidity();
  }

  private _editModeSetup(promo: NyhedTilbud) {
    this.getForm.patchValue(promo.dit);
    this._patchFormMedias();
  }

  private _patchFormMedias() {
    this.imageUrl.largeImage = this.getForm.value.largeImage;
    this.hasImage = true;
  }

  // date time value set for mobiscroll date-time
  private _setUpDateTime(inst: DatepickerBase) {
    if (!this.formData.dit) {
      OfferDateTimeService.setUpDateTime(this.ditForm, inst._el.id, 'dit');
    }
  }

  // on close of datetime
  private _closeDatePicker(inst: DatepickerBase) {
    const dateId = inst._el.id;
    if (this.modes.newsMode) {
      this._patchStartEndDatesForNewsMode(dateId);
    }
  }

  private _patchStartEndDatesForNewsMode(dateId) {
    const promoForm = this.getForm;
    if (dateId.indexOf('publishdit') === 0) {
      promoForm.patchValue({
        startDate: promoForm.value.publishDate
      });
    }
    if (dateId.indexOf('expiredit') === 0) {
      promoForm.patchValue({
        endDate: promoForm.value.expirationDate
      });
    }
  }

  onSelect() {
    this.visible = true;
  }

  ondeselect() {
    this.visible = false;
  }

  getStartDateTime(event: any) {
    this.ditForm.patchValue({
      startDate: event.startDateTime,
      endDate: event.endDateTime,
      startNow: event.start_now
    }, {
      onlySelf : true
    })
  }

  getPublishExpirationDateTime(event: any) {
    this.ditForm.patchValue({
      publishDate: event.startDateTime,
      expirationDate: event.endDateTime,
      publishNow: event.start_now
    }, {
      onlySelf : true
    })
  }

}
