import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { TokenService } from './token.service';
import { TokenDetail } from 'app/shared/interface';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private _http: HttpClient) {
  }

// *** Converts any object to HttpParams ***
// Used for changing data headers[content-type] as application/x-www-form-urlencoded
// @params [params] Array or Object
// @returns [HttpParams]
  static CreateFormData(params: any): HttpParams {
    let httpParams = new HttpParams();

    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        if (params[key] instanceof Array) {
          const newArray = params[key];
          for (let i = 0; i < newArray.length; i++) {
            if (typeof newArray[i] === 'object') {
              objectParse(newArray[i], key + '[' + i + ']');
            }
            if (typeof newArray[i] !== 'object') {
              httpParams = httpParams.set(key + '[' + i + ']', newArray[i]);
            }
          }
        } else if (typeof params[key] === 'object') {
          objectParse(params[key], key);
        } else {
          httpParams = httpParams.set(key, params[key]);
        }
      }
    }

    function objectParse(param: any, firstkey: any) {
      for (const key in param) {
        if (param.hasOwnProperty(key)) {
          if (typeof param[key] === 'object') {
            const newObject = param[key];
            for (const newKey in newObject) {
              if (newObject.hasOwnProperty(newKey)) {
                httpParams = httpParams.set(
                  firstkey + '[' + key + ']' + '[' + newKey + ']',
                  newObject[newKey]
                );
              }
            }
          } else {
            httpParams = httpParams.set(firstkey + '[' + key + ']', param[key]);
          }
        }
      }
    }

    return httpParams;
  }

  /*  get(url: string): Observable<any> {
     return this._http.get(`${environment.apiurl}${url}`)
       .pipe(
         catchError(this._handlerError())
       );
   } */
//** gets data based on the respective api url from backend
  //@params[url , options] => url to be navigated and HTTP options to send with the request
  //returns an Observable of the response, with the response body as an ArrayBuffer.
  get(url: string, option?: any): Observable<any> {
    return this._http
      .get(`${environment.apiurl}${url}`, option)
      .pipe(catchError(this._handlerError()));
  }

//** post data to the respective api url in the backend
  //@params[url , params] => url to be navigated and http options
  //returns an Observable of the response, with the response body as an object parsed from JSON.
  post(url: string, params: object) {
    return this._http
      .post(`${environment.apiurl}${url}`, params)
      .pipe(catchError(this._handlerError()));
  }

  quicktogopost(url: string, params: object) {
    return this._http
      .post(`${environment.apiurl}${url}`, params)
      .pipe(catchError(this._handlerError()));
  }

//** update the data in respective api url
  //@params[url , params] => url to be navigated and http options
  //returns an Observable of the response as a JavaScript object.
  put(url: string, params: object) {
    return this._http
      .put(`${environment.apiurl}${url}`, params)
      .pipe(catchError(this._handlerError()));
  }

//** delete the data in respective api url
  //@params[url] url to be navigated
  // returns an Observable of the response, with the response body of type Object
  del(url: string) {
    return this._http
      .delete(`${environment.apiurl}${url}`)
      .pipe(catchError(this._handlerError()));
  }

  // @ts-ignore
// *** Converts image from Blob to FormData and Uploads  ***
// Used for uploading single or multiple images
// @params [singleUpload: boolean, squareImage: any, rectangleImage: any, imageFormat: string]
// @returns [Response after POST]
  uploadImage(
    singleUpload: boolean,
    squareImage: any,
    rectangleImage: any,
    imageFormat: string
  ): Observable<any> {
    if (!singleUpload) {
      const formData = new FormData();
      formData.append('thumb', squareImage, imageFormat);
      formData.append('img', rectangleImage, imageFormat);
      return this._http.post(`${environment.apiurl}upload-image`, formData);
    }
    if (singleUpload) {
      const formData = new FormData();
      if (rectangleImage === null) {
        formData.append('thumb', squareImage, imageFormat);
        formData.append('img', squareImage, imageFormat);
      } else {
        formData.append('thumb', rectangleImage, imageFormat);
        formData.append('img', rectangleImage, imageFormat);
      }

      return this._http.post(`${environment.apiurl}upload-image`, formData);
    }
  }

  //** video upload
  //@params[videoFile, videoFormat] => uploaded file content and format of file
  //name:'video', videoFile, videoFormat are added to the formData
  //returns an Observable of all HttpEvents for the request, with a response body of type Object.
  uploadVideo(videoFile: Blob, videoFormat: string) {
    const formData = new FormData();
    formData.append('video', videoFile, videoFormat);
    return this._http.post(`${environment.apiurl}upload/video`, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  // *** Facebook API CALLS ***
// returns an observable of the response body as a JavaScript object.
  getFacebookLoginUrl(): Observable<any> {
    const url = `${
      environment.fburl
    }login-url?x-user-id=${TokenService.getUserCode()}&redirect_url=${
      environment.fburl
    }callback`;
    return this._http.get(url).pipe(catchError(this._handlerError()));
  }

//** gets facebook list
  //@params[token] token received when facebook is connected
  //returns an Observable of the response body as a JavaScript object.
  getFacebookPageList(token: string): Observable<any> {
    return this._http
      .get(
        'https://graph.facebook.com/me/accounts?fields=name,picture.width(200),access_token&limit=50&access_token=' +
        token
      )
      .pipe(catchError(this._handlerError()));
  }

//** get facebook pages through pagination list
  // returns an Observable of the response body as a JavaScript object.
  getFacebookPagesPaginationList(url: string): Observable<any> {
    return this._http.get(url).pipe(catchError(this._handlerError()));
  }

  //**get Facebook status
// returns an observable of the response body as a JavaScript object.
  getFacebook(url: string): Observable<any> {
    return this._http
      .get(`${environment.fburl}${url}`)
      .pipe(catchError(this._handlerError()));
  }

//**post Facebook
  //@params[url, params] url to be navigated and object
  //returns an observable of the response, with the response body as an object parsed from JSON.
  postFacebook(url: string, params: object) {
    return this._http
      .post(`${environment.fburl}${url}`, params)
      .pipe(catchError(this._handlerError()));
  }

  // *** Oauth API Calls ***
  //returns an observable of the response body as a JavaScript object.
  getOauthLoginUrl(isMobileApp: boolean): Observable<string> {
    const url = isMobileApp ? `${environment.oauthurl}login?web=false` : `${environment.oauthurl}login?web=true`;
    return this._http.get(url).pipe(
      // @ts-ignore
      map((res) => res['coop_oauth_login_url']),
      catchError(this._handlerError())
    );
  }

//** outh login of board**
  //@params[code] passed to verify code and for that returns token after being verified
  //returns an observable of the response body as a JavaScript object.
  boardOauthLogin(code: string): Observable<TokenDetail> {
    return this._http
      .get(`${environment.apiurl}exchange/token?code=${code}`)
      .pipe(catchError(this._handlerError()));
  }

  //@params[code,type] passed to verify code and for that returns token after being verified
  // returns an Observable of the response body as a JavaScript object.
  autoLogin(code: string, type: string): Observable<TokenDetail> {
    return this._http
      .get(
        `${environment.apiurl}login/autologin-admin?code=${code}&for=${type}`
      )
      .pipe(catchError(this._handlerError()));
  }

//**handles error
  private _handlerError() {
    return (error: HttpErrorResponse): Observable<any> => {
      return throwError(() => error);
    };
  }

//**upload image
  //@params[singleUpload,squareImage,rectangleImage,imageFormat] boolean value,square image, rectangle image and format of image
  //if rectangleImage is null => append name, squareImage and imageFormat to formData
  //else append image ,rectangleImage and imageFormat to formData
  //return an observable of the response, with the response body as an object parsed from JSON.
  uploadImageShop(
    squareImage: Blob,
    rectangleImage: Blob,
    imageFormat: string
  ): Observable<any> {
    const formData = new FormData();
    if (rectangleImage === null) {
      formData.append('image', squareImage, imageFormat);
    } else {
      formData.append('image', rectangleImage, imageFormat);
    }
    return this._http.post(`${environment.apiurl}image`, formData);
  }

//** post webhook
  //@params[url, params] api url to be navigated and object
  //returns and observable of the response, with the response body as an object parsed from JSON.
  postWebHook(url: string, params: object) {
    return this._http
      .post(`${environment.webhookurl}${url}`, params)
      .pipe(catchError(this._handlerError()));
  }

//** get webhook
  //@params[url] api url to be navigated
  //returns an observable of the response body as a JavaScript object.
  getWebHook(url: string) {
    return this._http
      .get(`${environment.webhookurl}${url}`)
      .pipe(catchError(this._handlerError()));
  }

//** put webhook
  //@params[url, params] api url to be navigated
  //returns an observable of the response as a JavaScript object.
  putWebHook(url: string, params: object) {
    return this._http
      .put(`${environment.webhookurl}${url}`, params)
      .pipe(catchError(this._handlerError()));
  }

//** delete webhook
  //@params[url] api url to be navigated
  //returns an observable of the response, with the response body of type Object
  delWebHook(url: string) {
    return this._http
      .delete(`${environment.webhookurl}${url}`)
      .pipe(catchError(this._handlerError()));
  }
}
