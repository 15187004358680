import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {takeWhile} from 'rxjs/operators';

import {
  ClientService,
  DateFormatService,
  ErrorhandlerService,
  FormFunctionsService,
  HelperService,
  NotificationsService
} from 'app/core/services';
import {
  checkEmojiCharacters,
  checkIfEndDateIsLessThanStartDate,
  checkIfOfferPriceLessThanReal,
  checkIfSmsDateIsInRange, checkMaxLength,
  checkPriceMinimum,
  checkPriceRegex,
  checkWhiteSpace, customMinValidator
} from 'app/core/validators';
import {
  COMMON_DATETIMEPICKER_SETTINGS,
  COMMON_SELECT_SETTINGS
} from 'app/shared/data';
import {
  Client,
  Modes,
  ValidationMessage,
  VideoUrls
} from 'app/shared/interface';
import {SendsmsComponent} from 'app/features/sms/components';
import {
  NyhedTilbud,
  SMS,
  OfferDateTimeService,
  OfferStateService, OfferDataService
} from 'app/features/offer';
import * as moment from 'moment/moment';
import * as _ from 'lodash';
import {localeTh, MbscDatepickerOptions} from '@mobiscroll/angular';
import {DatepickerBase} from '@mobiscroll/angular/dist/js/core/components/datepicker/datepicker';
import * as dayjs from "dayjs";

@Component({
  selector: 'coop-sms-form',
  templateUrl: './sms-form.component.html',
  styleUrls: ['./sms-form.component.scss']
})
export class SmsFormComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(SendsmsComponent) sendSmsForm: SendsmsComponent;
  @Input() modes: Modes;
  @Input() tab: string = ''
  @Input() validationMessages: ValidationMessage;
  @Input() validateModes = [];
  @Input() currentTab = 'coop';
  @Input() offerType: string = 'tilbud';
  @Output() formReady = new EventEmitter<FormGroup>();
  formData?: NyhedTilbud;
  smsForm: FormGroup;
  clients: Client[];
  tempSms = false;
  smsText = '';
  showSmsTextSection = true;
  showEmojiError = false;
  imageUrl = {
    largeImage: 'assets/images/camera@3x.png',
    thumbnail: ''
  };
  defaultImageData = {
    largeImage: 'assets/images/camera@3x.png',
    thumbnail: ''
  };
  defaultVideoData = {
    url: '',
    thumbnail: 'assets/images/video.png'
  };
  videoUrl: VideoUrls = {
    url: '',
    thumbnail: 'assets/images/video.png'
  };
  hasImage = false;
  hasVideo = false;
  isGif = false;
  isVideo = false;
  isProcessing = false;
  firstSmsText: string;
  offerGroup: string;
  smsSendDate;
  isPostedToFb: boolean = false;
  currentDateTime: string;
  visible: boolean = false;
  numbers = [];
  priceDescSelectSettings = {
    ...COMMON_SELECT_SETTINGS, ...{
      data: [
        'stk',
        'kg',
        'g',
        'liter',
        'ml',
        'pakke',
        'pakker',
        'sæt',
        'kasse',
        'kasser',
        'ramme',
        'rammer'
      ]
    }
  };
  datePickerSettings: MbscDatepickerOptions = {
    ...COMMON_DATETIMEPICKER_SETTINGS, ...{
      dateWheels: 'YYYY MMMM DD',
      dateFormat: 'YYYY-MM-DD',
      stepMinute: 15,
      onOpen: (_, inst) => this._setUpDateTime(inst),
      onClose: (_, inst) => this._closeDatePicker(inst)
    }
  };
  @Output() emojiErrorcheck: EventEmitter<boolean> = new EventEmitter<boolean>();
  private _subscriptionState = true;
  titleCount: any = 0;
  showPriceSection: boolean = false;
  startDate = dayjs().format('YYYY-MM-DD')
  startTime = dayjs().format('HH:mm')
  onStartNow: boolean = true
  validFrom:string = 'Gyldig fra - nu';
  validTill="Gyldig til"
  constructor(
    private _formBuilder: FormBuilder,
    private _formStateService: OfferStateService,
    private _clientService: ClientService,
    private _errorHandlerService: ErrorhandlerService
  ) {
    this.currentDateTime = moment().format('YYYY-MM-DD HH:mm');
  }

  ngOnInit() {
    this.initializeForms();
    this.smsForm.get('title').valueChanges.subscribe((res) => {
      this.titleCount = this.calculateLength(res);
    });
  }

  calculateLength(str: string) {
    return _.size(str);
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes['offerType']) {
      this.offerType = changes['offerType'].currentValue;
    }
    if (changes['modes']) {
      this.modes = changes['modes'].currentValue;
    }
    if (changes['currentTab']) {
      if (changes['currentTab'].currentValue === 'sms') {
        this.initializeForms();
      }
    }
  }

  initializeForms() {
    if (!this.smsForm) {
      this._setupSMSForm();
    }
    if (!this.modes.newsMode && (this.offerType === 'tilbud')) {
      this._setPriceValidators();
    }
    this.formData = this._formStateService.formState.value;
    if (this.formData.sms?.isSet || this.modes.editMode || this.modes.templateMode) {
      this._editModeSetup(this.formData);
    }
    if (this.modes.editMode || this.modes.templateMode) {
      this.setupCalendarForSendDate()
    }
    if (!this.modes.editMode && !this.modes.templateMode) {
      this.createSmsForm();
    }
    if (!this.clients) {
      this._setUpSms();
    }
    if (!this.modes.newsMode && this.offerType === 'tilbud') {
      this.showPriceSection = true;
    }
    this.formReady.emit(this.smsForm);
  }

  ngOnDestroy(): void {
    this._subscriptionState = false;
  }

  public get getForm() {
    return this.smsForm;
  }

  createSmsForm() {
    const firstValidOption = this.validateModes[0];
    const formValue = JSON.parse(JSON.stringify(this.getForm.value));
    const smsImageUrl = this.formData.sms?.thumbnail;

    if (firstValidOption !== 'sms') {
      const parentForm = this.formData[firstValidOption];
      if (FormFunctionsService.checkparentFormEmpty(parentForm)) {
        return;
      }
      console.log(formValue)
      if (FormFunctionsService.checkFormEmpty(formValue)) {
        this.hasImage = false;
        //check for parentImage, can be an array if its facebook so assign the first item of the arry. Can be just a single image url as well
        const parentImgUrl = Array.isArray(this.getImageForSms()) ? this.getImageForSms()[0] : this.getImageForSms();

        const imageUrl = smsImageUrl ? smsImageUrl : parentImgUrl;
        this.getForm.patchValue({
          title: parentForm.title,
          longDescription: OfferDataService.descriptionChanges(firstValidOption, this.modes.newsMode, parentForm.longDescription),
          thumbnail: imageUrl,
          inAppParameters: {
            price: parentForm.inAppParameters.price,
            offerPrice: parentForm.inAppParameters.offerPrice,
            priceDescription: parentForm.inAppParameters.priceDescription,
            uom: parentForm.inAppParameters.uom
          },
          sendDate: parentForm.publishDate,
          startDate: parentForm.startDate,
          endDate: parentForm.endDate,
          video: {
            url: parentForm.video.url,
            thumbnail: parentForm.video.thumbnail
          }
        });

        if (smsImageUrl && !this.getForm.value.thumbnail) {
          this.getForm.patchValue({
            thumbnail: smsImageUrl
          });
        }
        this._patchFormMedias();
        if (!smsImageUrl && parentImgUrl) {
          this.imageUrl.largeImage = parentImgUrl;
          this.hasImage = true;
        } else {
          this.hasImage = false;
          return;
        }
      }

      if (!this.modes.newsMode && this.offerType === 'good-price') {
        this.showPriceSection = this.checkInAppParametersValues(parentForm);
        this.getForm.get('inAppParameters').patchValue({
          price: parentForm.inAppParameters.price,
          priceDescription: parentForm.inAppParameters.priceDescription,
          uom: parentForm.inAppParameters.uom
        });
      }
    }
    // sms doesn't have other dates, so we set to default
    if (FormFunctionsService.checkFormEmpty(this.getForm.value)) {
      OfferDateTimeService.setDefaultTimeOnCreateSMS(this.smsForm);
    }
  }

  getImageForSms() {
    const parentForm = this.formData;
    let parentImgUrl = '';
    let firstSharedChannel = this.validateModes[0];
    if (this.validateModes && (parentForm.facebook && !parentForm.facebook.video?.thumbnail)) {
      parentImgUrl = parentForm.facebook.largeImage[0];
      return parentImgUrl;
    }
    // if (
    //   this.validateModes && !parentForm[firstSharedChannel].video?.thumbnail && !this.modes.newsMode && this.offerType === "tilbud"
    // ) {
    //   parentImgUrl = parentForm[firstSharedChannel].thumbnail || parentForm[firstSharedChannel].largeImage;
    //   return parentImgUrl;
    // }
    // if (
    //   this.validateModes && !parentForm[firstSharedChannel].video?.thumbnail && this.modes.newsMode && this.offerType === "tilbud"
    // ) {
    //   parentImgUrl = parentForm[firstSharedChannel].thumbnail || parentForm[firstSharedChannel].largeImage;
    //   return parentImgUrl;
    // }
    if (
      this.validateModes && !parentForm[firstSharedChannel].video?.thumbnail && !this.modes.newsMode && this.offerType !== 'tilbud'
    ) {
      parentImgUrl = parentForm[firstSharedChannel].thumbnail || parentForm[firstSharedChannel].largeImage;
      return parentImgUrl;
    }
    if (this.validateModes && (parentForm.facebook && !parentForm.facebook.video?.thumbnail)) {
      parentImgUrl = parentForm.facebook.largeImage[0];
      return parentImgUrl;
    }
    return parentImgUrl;
  }

  getStartDateTime(event: any) {
    this.smsForm.patchValue({
      startDate: event.startDateTime,
      endDate: event.endDateTime
    }, {
      onlySelf: true
    })
  }

  getVideoUrl(videoUrl: any) {
    this.videoUrl = {
      thumbnail: videoUrl.thumbnail,
      url: videoUrl.url
    };
    this.hasVideo = true;
    this.smsForm.patchValue({
      video: {
        url: videoUrl.url,
        thumbnail: videoUrl.thumbnail
      }
    });
  }

  getImagesUrl(images: any) {
    this.smsForm.patchValue({
      thumbnail: images.thumbnail
    });
    Object.assign(this.imageUrl, images);
    this.hasImage = true;
  }

  imageProcessing(e: boolean) {
    this.isProcessing = e;
  }

  uploadTypeSelect(type: boolean) {
    this.clearingMediaOnTabChange(type);
  }

  clearingMediaOnTabChange(type: boolean) {
    let isPrompt;
    if (type) {
      isPrompt = this.hasImage;
    }
    if (!type) {
      isPrompt = this.hasVideo;
    }
    if (isPrompt) {
      this.clearMedia(type);
    }
    if (!isPrompt) {
      this.changeUploadType(type);
    }
  }

  clearMedia(isVideo: boolean) {
    let clearFunction;
    let notificationMsg;
    if (isVideo) {
      notificationMsg =
        'Alle uploadede billeder kasseres ved at skifte faner. Er du sikker?';
      clearFunction = this.clearImageUrl;
    }
    if (!isVideo) {
      notificationMsg =
        'Den uploadede video kasseres ved at skifte faner. Er du sikker?';
      clearFunction = this.clearVideo;
    }
    NotificationsService.confirmWithCallback(notificationMsg, (accept) => {
      if (accept) {
        clearFunction(this);
        this.changeUploadType(isVideo);
        return;
      }
      if (!accept) {
        return;
      }
    });
  }

  changeUploadType(isVideo: any) {
    this.isVideo = isVideo;
    this.changeMediaType(isVideo);
  }

  changeMediaType(isVideo: any) {
    if (isVideo && !!!this.getForm.value.video.url) {
      NotificationsService.notify(
        'Det er vigtigt, at videoen optages med mobiltelefonen holdt vertical/oprejst',
        'info',
        'top'
      );
    }
    this.changeMediaValidators(isVideo);
    this.setMediaToNull(isVideo);
  }

  changeMediaValidators(isVid: boolean) {
    let videoValidity;
    let largeImageValidity;
    if (isVid) {
      videoValidity = Validators.required;
      largeImageValidity = null;
    }
    if (!isVid) {
      videoValidity = null;
      largeImageValidity = Validators.required;
    }
    this._updateValidators('video.url', videoValidity);
    this._updateValidators('video.thumbnail', videoValidity);
    this._updateValidators('thumbnail', largeImageValidity);
  }

  setMediaToNull(isVideo: boolean) {
    if (isVideo) {
      this.smsForm.patchValue({
        thumbnail: null
      });
    }
    if (!isVideo) {
      this.smsForm.patchValue({
        video: {
          url: null,
          thumbnail: null
        }
      });
    }
  }

  clearImageUrl(context: any) {
    context.imageUrl = JSON.parse(JSON.stringify(context.imageUrl));
    context.hasImage = false;
    context.smsForm.patchValue({
      thumbnail: null
    });
  }

  clearVideo(context: any) {
    context.videoUrl = JSON.parse(JSON.stringify(context.defaultVideoData));
    context.hasVideo = false;
    context.smsForm.patchValue({
      video: {
        url: '',
        thumbnail: ''
      }
    });
  }

  validateNumber(event) {
    HelperService.preventInvalidNumbers(event);
  }

  smsInit(smsMsg) {
    this.smsText = smsMsg;
    this.firstSmsText = this.sendSmsForm ? this.sendSmsForm.getSmsText() : '';
  }

  checkSmsMessageValidity(error) {
    setTimeout(() => {
      this.showEmojiError = error;
      this.emojiErrorcheck.emit(this.showEmojiError);
    });
  }

  private _setupSMSForm() {
    const validators = [];
    if (!this.modes.newsMode) {
      validators.push(checkIfEndDateIsLessThanStartDate);
      validators.push(checkIfSmsDateIsInRange);
    }
    this.smsForm = this._formBuilder.group(
      {
        title: ['', [Validators.required, checkWhiteSpace, checkEmojiCharacters, checkMaxLength]],
        thumbnail: ['', Validators.required],
        video: this._formBuilder.group({
          url: [''],
          thumbnail: ['']
        }),
        startNow: [],
        publishNow: [],
        sendDate: ['', Validators.required],
        startDate: ['', Validators.required],
        endDate: ['', Validators.required],
        longDescription: ['', [Validators.required, checkWhiteSpace]],
        inAppParameters: this._formBuilder.group(
          {
            price: [''],
            offerPrice: [''],
            priceDescription: [''],
            uom: ['']
          },
          {validators: checkIfOfferPriceLessThanReal}
        ),
        smsText: ['', checkEmojiCharacters],
        solutions: ['']
      },
      {
        validators: validators
      }
    );
  }

  checkInAppParametersValues(parentForm): boolean {
    if (parentForm) {
      const price = parentForm.inAppParameters.price
      const priceDescription = parentForm.inAppParameters.priceDescription;
      const uom = parentForm.inAppParameters.uom;
      return price !== '' && priceDescription !== '' && uom !== '';
    }
    return false;
  }

  private updateValidation() {
    const validators = [checkPriceMinimum, checkPriceRegex];
    validators.push(Validators.required);
    this._updateValidators("inAppParameters.priceDescription", [
      Validators.required,
      checkWhiteSpace,
      checkPriceRegex
    ]);
    this._updateValidators("inAppParameters.price", [
      Validators.required,
      checkWhiteSpace,
      checkPriceRegex
    ]);
  }

  addPriceField() {
    this.showPriceSection = true
    this.updateValidation()
    this.smsForm.get('inAppParameters').patchValue({
      uom: 'stk'
    })
  }

  removePrices() {
    this.showPriceSection = false
    this.removePriceValidators()
  }

  removePriceValidators(): void {
    this._updateValidators("inAppParameters.priceDescription", null);
    this._updateValidators("inAppParameters.price", null);
    this.smsForm.get('inAppParameters').patchValue({
      price: '',
      priceDescription: '',
      uom: ''
    })
  }

  private _updateValidators(formControl: string, validators = []): void {
    this.smsForm.get(formControl).setValidators(validators);
    this.smsForm.get(formControl).updateValueAndValidity();
  }

  private _setPriceValidators() {
    const validators = [checkPriceMinimum, checkPriceRegex];
    if (this.offerType === 'tilbud') {
      validators.push(Validators.required);
      this._updateValidators('inAppParameters.priceDescription', [
        Validators.required,
        checkWhiteSpace,
        checkPriceRegex,
        customMinValidator(1),
        Validators.maxLength(50)
      ]);
      this._updateValidators('inAppParameters.price', [
        customMinValidator(1),
        Validators.required,
        checkWhiteSpace,
        checkPriceRegex
      ]);
    }
    if (this.offerType === 'tilbud') {
      this._updateValidators('inAppParameters.offerPrice', validators);
    }
  }

  // ** set up for sms
  private _setUpSms() {
    this.isProcessing = true;
    this._clientService
      .getClients()
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (clients) => {
          this.clients = clients;
          this.isProcessing = false;
        },
        error: (err) => this._handleError(err)
      });
  }

  private _editModeSetup(promo: NyhedTilbud) {
    this._patchFormValues(promo);
    this.smsForm.get('inAppParameters').patchValue({
      uom: promo.sms.inAppParameters.uom.toLowerCase()
    });
    this._patchFormMedias();
    this.smsText = promo.sms.smsText;
    if (!this.modes.newsMode && this.offerType === 'good-price' && promo.sms.inAppParameters.price !== '') {
      this.showPriceSection = true;
      this.updateValidation();
    }
    if (promo.sms && promo.sms.sendDate) {
      if (this.currentDateTime > promo.sms.sendDate) {
        this.isPostedToFb = true;
      }
    }

  }

  private setupCalendarForSendDate() {
    this.startDate = dayjs(this.formData.sms.sendDate).format('YYYY-MM-DD')
    this.startTime = dayjs(this.formData.sms.sendDate).format('HH:mm')
    console.log(this.startDate, this.startTime)
  }

  private _patchFormValues(promo: NyhedTilbud) {
    this.smsForm.patchValue(promo.sms);
    this.offerGroup = promo.offerGroup;
  }

  private _patchFormMedias() {
    this.isVideo = !!this.getForm.value.video.url;
    this._patchMultipleMedia(this.isVideo, this.getForm.value);

  }

  private _patchMultipleMedia(isVideo, values: SMS) {
    const currForm = this.smsForm.value;
    let imgUrl;
    if (!isVideo) {
      this.imageUrl.largeImage = currForm.thumbnail;
      this.hasImage = true;
      imgUrl = this.imageUrl.largeImage;
      const lastIndexOfDot = imgUrl.lastIndexOf('.');
      const fileFormat = imgUrl.slice(lastIndexOfDot);
      if (fileFormat === '.gif') {
        this.isGif = true;
      }
    }
    if (isVideo) {
      this.videoUrl = values.video;
      this.hasVideo = true;
      this.changeUploadType(isVideo);
      // we need to clear image in form because while patching , form array forms a formControl and hence largeImage is not empty and valid
      this.clearImageUrl(this);
    }
  }


  // date time value set for mobiscroll date-time
  private _setUpDateTime(inst: DatepickerBase) {
    if (!this.modes.editMode) {
      OfferDateTimeService.setUpDateTime(this.smsForm, inst._el.id, 'sms');
    }
  }

  // on close of datetime
  private _closeDatePicker(inst: DatepickerBase) {
    const dateId = inst._el.id;
    if (dateId === 'send-sms') {
      this.smsSendDate = inst.getVal();
      this.sendSmsForm.setUpSendDate(this.smsSendDate);
    }
  }


  onStartTimeChange(event) {
    this.startTime = event.valueText
    this.smsForm.patchValue({
      sendDate: this.startDate + ' ' + this.startTime
    })
  }

  getDateTime(event: any) {
    this.startDate = dayjs(event.value).format('YYYY-MM-DD')
    this.smsForm.patchValue({
      sendDate: this.startDate + ' ' + this.startTime
    })
  }

  formatDate(date: string) {
    dayjs.locale('da');
    return dayjs(date).format('D. MMMM');
  }


  onSelect() {
    this.visible = true;
  }

  ondeselect() {
    this.visible = false;
  }


  private _handleError(error): void {
    this._errorHandlerService.handleError(error || {code: -400}, 'promo');
    this.isProcessing = false;
  }
}
