import { inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ProductCreateEditComponent } from 'app/features/order-and-pickup/product';
import { NotificationsService } from 'app/core/services';
import { CanDeactivateFn } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ProductGuardService  {
  constructor() {}

  canDeactivate(
    component: ProductCreateEditComponent
  ): Observable<boolean> | boolean {
    if (component.productForm.dirty && !component.formSaved) {
      return NotificationsService.confirm(
        'Dine ændringer vil ikke blive gemt.'
      );
    }
    return true;
  }
}

export const ProductGuard: CanDeactivateFn<ProductCreateEditComponent> =(component) =>{
  return inject(ProductGuardService).canDeactivate(component)
}
