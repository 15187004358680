import { Component, OnInit, ViewChild } from '@angular/core';

import {
  INITIAL_FORM_DATA,
  NyhedTilbud,
  GoodPriceFormComponent,
} from 'app/features/offer';

@Component({
  selector: 'coop-good-price-create',
  templateUrl: './good-price-create.component.html',
  styleUrls: ['./good-price-create.component.scss'],
})
export class GoodPriceCreateComponent implements OnInit {
  @ViewChild(GoodPriceFormComponent, { static: true })
  offerFormComp: GoodPriceFormComponent;
  formData?: NyhedTilbud = INITIAL_FORM_DATA;
  constructor() {}

  ngOnInit(): void {}
}
