import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {
  DashboardService, DateFormatService,
  ErrorhandlerService,
  TokenService
} from 'app/core/services';
import {
  AdminReport, DashboardLatestYearMonth,
  GeneralReport,
  JatakReport,
  PromoReport
} from 'app/shared/interface';
import * as dayjs from 'dayjs';
import * as isoWeeksInYear from 'dayjs/plugin/isoWeeksInYear';
import * as isLeapYear from 'dayjs/plugin/isLeapYear';
import 'dayjs/locale/da';
import { takeWhile } from 'rxjs/operators';
import { CreateOptionDataService } from 'app/core/services/createoptiondata.service';
import { Title } from '@angular/platform-browser';
import { MbscDatepickerOptions, MbscSelectCloseEvent, MbscSelectOptions } from '@mobiscroll/angular';
import { DatepickerBase } from '@mobiscroll/angular/dist/js/core/components/datepicker/datepicker';
import { SelectBase } from '@mobiscroll/angular/dist/js/core/components/select/select';
import { COMMON_DATEPICKER_SETTINGS, COMMON_SELECT_SETTINGS } from '../../data';

@Component({
  selector: 'coop-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  isProcessing = false;
  pageTitle: string = 'Statistik';
  generalReport: GeneralReport;
  jatakReport: JatakReport;
  promoReport: PromoReport;
  adminReport: AdminReport;
  date: any;
  latestReportDate: DashboardLatestYearMonth;
  memberStats = {
    year: dayjs().format('YYYY'),
    month: dayjs().format('M')
  };
  lookUpStats = {
    week: '0',
    year: dayjs().format('YYYY')
  };
  memberStatsDatePicker: {
    month: MbscDatepickerOptions,
    year: MbscDatepickerOptions
  };
  lookupStatsDatePicker: {
    week: MbscSelectOptions,
    year: MbscDatepickerOptions
  };
  userType: string;
  number: number;
  isRedmadScreenAvailable: boolean;
  colors = [
    'shade1',
    'shade2',
    'shade3',
    'shade4',
    'shade5',
    'shade6',
    'shade7'
  ];
  itemList = [
    {
      "id": 1,
      "name": "JA TAK"
    },
    {
      "id": 2,
      "name": "Red Maden"
    }
  ]
  selectedItemId: number = 1
  selectedCategoryId : number = 1
  selectedItemName: string = ''
  showList: boolean = false
  private _subscriptionState = true;
  activeTab: string = 'jatak';

  constructor(
    private _route: ActivatedRoute,
    private _title: Title,
    private _errorHandlerService: ErrorhandlerService,
    private _dashboardService: DashboardService,
    private _createOptionService: CreateOptionDataService
  ) {
  }

//** initialize component /
  //userType is set with the value returned by getuserType()
  //set the title of html document to 'Statistik - QuickCoop'
  //pageTitle is set with the provided value
  //set generalReport with the value fetched from .data 'generalReport'
  //set jatakReport with the value fetched from .data 'jatakReport'
  //set promoReport with the value fetched from .data 'promoReport'
  //set adminReport with the value fetched from .data 'adminReport'
  //setDatTimePickers() =>set date and time
  //_buildAgeDistributionResults() => provides data of member according to age group
  ngOnInit() {
    dayjs.extend(isoWeeksInYear);
    dayjs.extend(isLeapYear)
    dayjs().locale('da');//set local time
    this.userType = TokenService.getUserType();
    this.isRedmadScreenAvailable = TokenService.getUserDetail().scopes.quick2Go;
    this._title.setTitle('Statistik - QuickCoop');
    this.generalReport = this._route.snapshot.data['generalReport'];
    this.jatakReport = this._route.snapshot.data['jatakReport'];
    this.promoReport = this._route.snapshot.data['promoReport'];
    this.adminReport = this._route.snapshot.data['adminReport'];
    this.latestReportDate = this._route.snapshot.data['latestGeneraReportDate'];
    this._setDateTimePickers();
    this._buildAgeDistributionResults();
    this.itemList.forEach((item) => {
      if (item.id === this.selectedItemId) {
        this.selectedItemName = item.name
      }
    })
  }

//** called after the completion of initialization of component's view
  //_setPaddingTop() to provide top padding
  ngAfterViewInit() {
    // DashboardComponent._setPaddingTop();
  }

//** destroy component
  //clearCreateOption() => Sets the latest value of currentSetting to undefined
  //set Title 'QuickCoop'
  ngOnDestroy(): void {
    this._createOptionService.clearCreateOption();
    this._title.setTitle('QuickCoop');
  }

//** provide general report of store
  //subscribe to getGeneralReport => set generalReport with res
  //                                 _buildAgeDistributionResults() provides data of member according to age group
  getGeneralReport() {
    this._dashboardService
      .getGeneralReport(this.memberStats.year, this.memberStats.month)
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (res) => {
          this.generalReport = res;
          this._buildAgeDistributionResults();
        },
        error: (err) => this._handleError(err)
      });
  }

//** provide admin report
  //@params[lookupStatsYear, lookupStatsWeek] => selected year and week
  //subscribe to getAdminReport() =>set adminReport with res
  getAdminReport() {
    this._dashboardService
      .getAdminReport(this.lookUpStats.year, this.lookUpStats.week)
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (res) => (this.adminReport = res),
        error: (err) => this._handleError(err)
      });
  }

//** provide report of jatak
  //@params[lookupStatsYear, lookupStatsWeek] => selected year and week
  //subscribe to getJatakReport => set jatakReport with res
  getJatakReport() {
    this._dashboardService
      .getJatakReport(this.lookUpStats.year, this.lookUpStats.week)
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (res) => (this.jatakReport = res),
        error: (err) => this._handleError(err)
      });
  }

//** provides promo report
  //@params[lookupStatsYear, lookupStatsWeek] => selected year and week
  //subscribe to getPromoReport() => set promoReport with res
  getPromoReport() {
    this._dashboardService
      .getPromoReport(this.lookUpStats.year, this.lookUpStats.week)
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (res) => (this.promoReport = res),
        error: (err) => this._handleError(err)
      });
  }

//** provides reports
  //if userType is 1 i.e store => getJatakReport() provides jatak report
  //                              getPromoReport() provides promo report
  //if userType is 2 i.e board => getAdminReport() provide report for admin
  getLookupReports() {
    if (this.userType === '1') {
      this.getJatakReport();
      this.getPromoReport();
    }
    if (this.userType === '2') {
      this.getAdminReport();
    }
  }

  showItemsList() {
    this.showList = !this.showList
  }

  selectItem(data) {
    this.selectedItemId = data.id
    this.selectedItemName = data.name
    this.showList = false
  }

  selectCategory(id) {
    this.selectedCategoryId = id
  }

//** set date and time
  //object are created with respective properties for week, month and year
  private _setDateTimePickers() {
    //setup a the date from api
    const date = new Date(this.latestReportDate.year, this.latestReportDate.month - 1, 1);

    this.memberStatsDatePicker = {
      month: {
        ...COMMON_DATEPICKER_SETTINGS, ...{
          dateFormat: 'MMMM',
          dateWheels: 'MMMM',
          defaultSelection: date,
          defaultValue: date,
          onClose: (event, inst) => this._selectMonth(inst)
        }
      },
      year: {
        ...COMMON_DATEPICKER_SETTINGS, ...{
          dateFormat: 'YYYY',
          dateWheels: 'YYYY',
          min: '2010',
          max: new Date(),
          defaultSelection: date,
          defaultValue: date,
          onClose: (event, inst) => this._selectYear(inst)
        }
      }
    };


    this.lookupStatsDatePicker = {
      year: {
        ...COMMON_DATEPICKER_SETTINGS, ...{
          dateFormat: 'YYYY',
          dateWheels: 'YYYY',
          min: '2010',
          max: new Date(),
          defaultValue: new Date(),
          onClose: (event, inst) => this._selectYear(inst)
        }
      },
      week: {
        ...COMMON_SELECT_SETTINGS, ...{
          data: DateFormatService.getWeeksInaYearinArray(new Date().getUTCFullYear()),
          defaultValue: DateFormatService.getWeekNumber(new Date()),
          onChange: (args: MbscSelectCloseEvent, inst) => this._selectWeek(inst)
        }
      }
    };
  }

//** data of age group member
  //calculates the percentage of age group
  private _buildAgeDistributionResults() {
    const distribution = this.generalReport.ageDistribution;
    for (let i = 0; i < distribution.length; i++) { // loop through each distribution to get age group percentage
      distribution[i].Percentage = parseFloat(
        (
          (parseInt(distribution[i].members, 10) /
            this.generalReport.ageDistributionCount) *
          100
        ).toString()
      ).toFixed(2) as any;

      distribution[i].Width = distribution[i].Percentage * 1.2;// width of shade of age group in UI
      distribution[i].Shade = this.colors[i];// color according to their age group in UI
    }

  }

//** selects week
  //@param[inst] => data provided through mobiscroll library
  //if inst element id is equal to 'lookup-stats-week' => set lookupStatsWeek to value of inst getVal()
  //                                                      getLookupReports() to get reports
  private _selectWeek(inst: SelectBase) {
    this.lookUpStats.week = inst.value;
    this.getLookupReports();
  }

//** selects month
  //@params[inst] =>data provided through mobiscroll library
  //if inst element id is equal to 'member-stats-month' => set memberStatsMonth with value for inst getVal()
  //                                                        getGeneralReport() to get reports

  private _selectMonth(inst: DatepickerBase) {
    if (inst._el.id === 'member-stats-month') {
      this.memberStats.month = dayjs(inst.getVal().toString()).format('M');
      this.getGeneralReport();
    }
  }

//** select year
  //@params[inst] =>data provided through mobiscroll library
  //if inst element id is equal to 'member-stats-year' => set memberStatsYear with the value of inst getVal()
  //                                                    getGeneralReport() to get reports
  //if inst element id is equal to 'lookup-stats-year' => set lookupStatsYear with the value of inst getVal()
  //                                                      getGeneralReport() to get reports
  private _selectYear(inst: DatepickerBase) {
    if (inst._el.id === 'member-stats-year') {
      this.memberStats.year = dayjs(inst.getVal().toString()).format('YYYY');
      this.getGeneralReport();
    }
    if (inst._el.id === 'lookup-stats-year') {
      this.lookUpStats.year = dayjs(inst.getVal().toString()).format('YYYY');
      this.lookupStatsDatePicker.week.data = this.getWeeksInaYear(parseInt(this.lookUpStats.year));
      this.getLookupReports();
    }
  }


  private getWeeksInaYear(year: number) {
    const weeksInTheyear = dayjs(`${year}-01-01`).isoWeeksInYear();
    return Array.from({ length: weeksInTheyear }, (_, i) => ({
      text: `${i + 1}`,
      value: i + 1
    }));

  }

//**set top padding
  //const statHeader is set with the value of element 'stat-header'
  //const pageContainer is set with the value of element 'stat-container'
  //pageContainer is styled with paddingTop with the offsetHeight of statHeader i.e height of statHeader
  private static _setPaddingTop(): void {
    const statHeader = document.getElementById('stat-header');
    const pageContainer = document.getElementById('stat-container');
    pageContainer.style.paddingTop = statHeader.offsetHeight + 'px';
  }

  selectTab(tab: string) {
    this.activeTab = tab;
  }


//** handle errors
  //@params[error] =>error object
  //handleError pass either error object or code:-400
  //set isProcessing to false
  private _handleError(error: any): void {
    this._errorHandlerService.handleError(error || {code: -400}, 'promo');
    this.isProcessing = false;
  }
}
