import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { takeWhile } from 'rxjs/operators';

import {
  ErrorhandlerService, HelperService,
  NotificationsService,
  TokenService, UserService
} from 'app/core/services';
import {
  checkEmojiCharacters,
  checkWhiteSpace,
  validatePhone
} from 'app/core/validators/custom.validator';
import { Client, UserDetail, ValidationMessage } from 'app/shared/interface';
import { DateToString, NumberPad } from 'app/shared/pipes';
import {
  MOBILE_MEDLEM_VALIDATION_MESSAGES,
  SmsService
} from 'app/features/sms';

@Component({
  selector: 'coop-sms-club',
  templateUrl: './sms-club.component.html',
  styleUrls: ['./sms-club.component.scss'],
  providers: [NumberPad, DateToString]
})
export class SmsClubComponent implements OnInit, OnDestroy {
  clients: Client[];
  client: Client;
  isChild = true;
  pageTitle = 'SMS Klub';
  isProcessing = false;
  smsForm: FormGroup;
  mobileAddForm: FormGroup;
  mobileSearchForm: FormGroup;
  formError = false;
  messageText: string = '';
  staticMessage: string = '';
  subscribeText: Array<string> = [];
  unsubscribeText: Array<string> = [];
  charCount: number;
  tempValHolder: any;
  mobileTable = false;
  parentUrl: string;
  validationMessages: ValidationMessage = MOBILE_MEDLEM_VALIDATION_MESSAGES;
  userDetail: UserDetail;
  private _subscriptionState: boolean = true;
  isSearchMode = true;
  number: string = '';
  formSubmitted = false; // Flag to track form submission
  isMaxlength: boolean = false;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _smsService: SmsService,
    private _errorHandlerService: ErrorhandlerService
  ) {
  }

  ngOnInit() {
    this.client = this._activatedRoute.snapshot.data['clients'][0];
    this.userDetail = TokenService.getUserDetail();
    // setting of static msgs, unsubscribe msgs etc
    this.settingSmsVal();
    this.setPreviousUrl();

    // building sms forms for adding and search
    this.smsForm = this._formBuilder.group(
      {
        message: [this.messageText, [Validators.required, checkWhiteSpace, checkEmojiCharacters]]
      }
    );
    this._buildAddForm();
    this._buildSearchForm();


  }

  ngOnDestroy(): void {
    this._subscriptionState = false;
  }

  setPreviousUrl() {
    const userType = TokenService.getUserType();
    if (userType === '1') {
      this.parentUrl = '/butikker/indstillinger';
    }
    if (userType === '2') {
      this.parentUrl = '/bestyrelse/indstillinger';
    }
  }


  smsConvert(ev) {
    const userInput = ev.target.value;
    const { text: converted } = HelperService.smsParser(userInput);
    this.messageText = converted;
    this.smsForm.patchValue({
      message: this.messageText
    });
    this.charCount = this.messageText.length + this.staticMessage.length;
  }


  settingSmsVal() {
    if (this.client.standardText) {
      this.messageText = this.client.standardText.replace(/^\s+|\s+$/g, '');
      const { text: convertedProcessedMessage } = HelperService.smsParser(this.messageText);
      // this.smsForm.patchValue({
      //   message : convertedProcessedMessage
      // })
      this.messageText = convertedProcessedMessage;
    }
    this.staticMessage = this.client.afmeldingText.replace(/^\s+|\s+$/g, '');
    if (this.client.tilmeldingText) {
      this.subscribeText = SmsClubComponent._splitText(
        this.client.tilmeldingText
      );
    }
    this.unsubscribeText = SmsClubComponent._splitText(
      this.client.afmeldingText
    );

    this.charCount = this.messageText.length + this.staticMessage.length;
  }

  private static _splitText(text: string): Array<string> {
    const firstSplit = text.split(':');
    return firstSplit[1].split('til');
  }

  private static _split(text: string): Array<string> {
    return text.split(',');
  }

  formatText(text: string, type: number) {

    if (type === 1) {
      return text.slice(0, 2).toUpperCase();
    }

    if (type === 2) {
      let firstTwoLetters = text.substring(0, 2).toUpperCase();
      let restOfString = text.substring(2);
      return firstTwoLetters + restOfString;
    }
  }

  send(): void {
    if (this.charCount > 100) {
      this.isMaxlength = true;
      return;
    }
    this.isMaxlength = false;
    if (!this._isValid(this.smsForm)) {
      return;
    }
    this._smsService
      .editStandardMessage({
        solutionId: this.client.id,
        standardText: this.smsForm.getRawValue().message
      })
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: () => {
          NotificationsService.notify('Den personlige hilsen blev gemt.', 'success', 'top');
          // this._router.navigate([this.parentUrl]).then();
        },
        error: (error) => {
          this._handleError(error);
        }
      });
  }

  onSearchNumber() {
    this.isSearchMode = true;
    if (!this._isValid(this.mobileSearchForm)) {
      return;
    }
    this._smsService
      .getMemberMobile(this.mobileSearchForm.value.numberSearch)
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (res) => {
          if (res['success'] === true) {
            NotificationsService.notify(
              'Mobilnummer fundet!',
              'success',
              'top'
            );
            this.tempValHolder = res['members'];
            this.mobileTable = true;
          } else {
            NotificationsService.notify(
              'Mobilnummeret eksisterer ikke',
              'error',
              'top'
            );
            this.mobileTable = false;
            this.resetForm('mobileSearchForm');
          }
        },
        error: (err) => this._handleError(err)
      });
  }

  onInputChange(event) {
    const inputElement = event.target as HTMLInputElement;

    // Access the value property of the input element
    const inputValue = inputElement.value;

    this.mobileAddForm.patchValue({
      number: inputValue
    });

    this.mobileSearchForm.patchValue({
      numberSearch: inputValue
    });
  }

  onAddNumber() {
    this.isSearchMode = false;
    this.formSubmitted = true;
    if (!this._isValid(this.mobileAddForm)) {
      return;
    }
    const numObj = {};
    const numArray = SmsClubComponent._split(this.mobileAddForm.value.number);
    for (let i = 0; i < numArray.length; i++) {
      // @ts-ignore
      numObj[`members[${i}]`] = numArray[i];
    }
    this._smsService
      .saveMemberMobile(numObj)
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (res) => {
          if (res['success'] === true) {
            NotificationsService.notify(res['description'], 'success', 'top');
          }
          this.resetForm('mobileAddForm');
        },
        error: (err) => {
          // NotificationsService.notify('Noget gik galt / prøv igen', 'error', 'top');
          this._handleError(err);
          this.resetForm('mobileAddForm');
        }
      });
  }

  onMemberMobileDelete(uId: any, index: any) {
    this._smsService
      .deleteMember({ id: uId })
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (res) => {
          if (res['success'] == true) {
            NotificationsService.notify(
              'Slettet succesfuldt',
              'success',
              'top'
            );
            this.tempValHolder.splice(index, 1);
            if (this.tempValHolder.length === 0) {
              this.mobileTable = false;
              this.resetForm('mobileSearchForm');
            }
          }
        },
        error: (error) => {
          this._handleError(error);
        }
      });
  }

  private _isValid(form: FormGroup) {
    if (form.status === 'INVALID') {
      this.formError = true;
      this._handleError('');
      return false;
    }
    return true;
  }

  resetForm(form: string) {
    if (form === 'mobileSearchForm') {
      this._buildSearchForm();
    } else {
      this._buildAddForm();
    }
  }

  private _buildAddForm() {
    this.mobileAddForm = this._formBuilder.group({
      number: ['', [Validators.required, validatePhone]]
    });
  }

  private _buildSearchForm() {
    this.mobileSearchForm = this._formBuilder.group({
      numberSearch: ['', Validators.required]
    });
  }

  private _handleError(error: any): void {
    this.isProcessing = this.formSubmitted = false;
    if (error.status === 422 && error.errors?.standardText) {
      const errorMessage = 'Indsæt venligst ingen emoji-tegn (de ikke understøttes).';
      NotificationsService.notify(errorMessage, 'danger', 'top');
      return;
    }
    this._errorHandlerService.handleError(error || { code: -400 }, 'sms');
  }
}
