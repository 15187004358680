import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private tabSource = new BehaviorSubject(1);
  currentTab = this.tabSource.asObservable();

  constructor() {}

  changeTab(tab: number) {
    this.tabSource.next(tab);
  }
}
