<section
  class="app-page promo-recurring-offer page-with-fixed-header-and-tab"
  [@parentAnimate]="showParent"
>
  <div class="fixed-header-bug-fix" id="fixed">
    <coop-pageheader
      [pageTitle]="pageTitle"
      [isBackGroundWhite]="true"
      [isChild]="true"
      [routeTo]="previousUrl"
    ></coop-pageheader>
    <div class="fixed-header-content">
      <div class="tab-container">
        <div class="flex-display tab-container-wrap">
          <ul class="tab-list">
            <li class="tab-option active">{{ tabName }}</li>
          </ul>
          <div
            class="channel-selection-btn"
            (click)="toggleChannelSelection()"
            *ngIf="!editMode"
          >
            <span class="channel-margin-5">Kanal</span>
            <ion-icon name="chevron-down"  [class.show-channel-selection]="showChannelSelection" class="font-18"></ion-icon>
<!--            <i-->
<!--              class="icon icon-arrow-down"-->
<!--              [class.show-channel-selection]="showChannelSelection"-->
<!--            ></i>-->
          </div>
        </div>
        <coop-channel-select
          *ngIf="showChannelSelection"
          class="channel-selection-box"
          [selectedChannel]="shared"
          [mode]="channelSelectionModes"
          [allowedChannels]="allowedChannels"
          (channelConfirmed)="confirmedShareOptions($event)"
          (channelState)="updateChannelState($event)"
        ></coop-channel-select>
      </div>
    </div>
  </div>
  <div class="page-container" id="page-content">
    <div class="form-container styled-two">
      <form [formGroup]="promoForm" (submit)="save()">
        <div class="form-group">
          <div class="wrapper">
            <ng-container *ngIf="!archiveTab">
              <div class="form-group" controlErrorContainer>
                <coop-image-handler
                  [imageUrl]="imageUrl"
                  [hasImages]="hasImage"
                  [singleCropper]="true"
                  [disableUpload]="false"
                  [isRectangle]="false"
                  [editMode]="editMode"
                  (image)="getImagesUrl($event)"
                  (processing)="imageProcessing($event)"
                ></coop-image-handler>
                <input
                  formControlName="largeImage"
                  [customErrors]="validationMessages['largeImage']"
                  hidden
                />
              </div>
            </ng-container>
            <div *ngIf="archiveTab" class="form-group ">
              <div class="large-img">
                <img [src]="imageUrl.largeImage" alt=""/>
              </div>
            </div>
            <div class="form-group" controlErrorContainer>
              <label class="margin-bottom-8">Overskrift*</label>
              <input
                type="text"
                (focusin)="showCounter()"
                placeholder="Indsæt overskrift"
                (focusout)="showCounter()"
                formControlName="title"
                [customErrors]="validationMessages['title']"/>

              <div *ngIf="showLengthCounter" class="remaining"> Brugte
                tegn: {{ promoForm.get('title').value.length }} / 99
              </div>
            </div>
            <div class="row form-group" controlErrorContainer>
              <label class="margin-bottom-8">Tekst</label>
              <textarea
                placeholder="Indsæt tekst"
                name=""
                formControlName="longDescription"
                [customErrors]="validationMessages['largeDescription']"
              ></textarea>
            </div>


            <div class="form-group" controlErrorContainer>

              <div class="label-wrapper margin-bottom-20">
                <div>
                  <label>
                    Prisoplysninger:
                  </label>
                </div>
                <div class="tooltip-icon">
                    <span
                      tooltip="På Facebook vil prisen og mængden blive indsat sidst i opslaget."
                      placement="top"
                      delay="500"><ion-icon name="information-circle" class="tooltip-info-icon"></ion-icon></span>
                </div>
              </div>
              <div *ngIf="showPriceSection" class="price-description-wrapper">
                <div class="price-container">
                  <div formGroupName="inAppParameters">
                    <input
                      id="fb-price"
                      type="text"
                      formControlName="price"
                      [customErrors]="validationMessages['price']"
                      min="0"
                      placeholder="Pris"
                      (keydown)="validateNumber($event)"
                    />
                  </div>
                  <div style="margin:10px;min-width: fit-content">
                    kr. pr.
                  </div>
                </div>

                <div class="price-description-container">
                  <div style="margin-right: 10px" class="price-description" formGroupName="inAppParameters">
                    <input

                      type="text"
                      formControlName="priceDescription"
                      [customErrors]="validationMessages['priceDescription']"
                      min="0"
                      placeholder="vægt/mængde"
                      (keydown)="validateNumber($event)"
                    />
                  </div>

                  <div class="price-limit" formGroupName="inAppParameters">
                    <select
                      formControlName="uom"
                      mbsc-select
                      [mbscOptions]="priceDescSelectSettings"
                      class="form-select">
                    </select>
                    <div class="dropdown-icon"><ion-icon name="chevron-down"></ion-icon></div>
                  </div>
                  <div *ngIf="showPriceSection" style="cursor: pointer;margin-top: 8px" (click)="removePrices()">
                    <ion-icon name="close-circle" style="color: #D10A11;font-size: 24px"></ion-icon>
                  </div>
                </div>

              </div>

              <div *ngIf="!showPriceSection" class="row addvarient" (click)="addPriceField()">
                <a class="login-btn btn-white-login button-wrapper">
                  <div style="margin-right: 10px"><ion-icon name="add" class="font-18"></ion-icon></div>
                  <div><p>Tilføj pris</p></div>
                </a>
              </div>
            </div>

            <div style="margin-bottom: 10px">
              <h2 class="form-subtitle">Gyldighedsperiode (fra - til)</h2>
              <div class="flex-display">
                <div
                  formGroupName="recurringData"
                  controlErrorContainer
                  style="flex: 1"
                >

                  <div
                    class="start-end-day-box flex-display"
                    (click)="selectBox('start')"
                    [class.disabled]="archiveTab">
                    <div >
                      {{
                      recurringDateList[selectedRecurringDates.start][
                        'dayName'
                        ]
                      }}
                    </div>
                    <div>
                      <ion-icon name="chevron-down-outline"></ion-icon>
                    </div>
                  </div>

                  <input formControlName="startDay" hidden/>
                </div>
                <div
                  formGroupName="recurringData"
                  controlErrorContainer
                  style="flex: 1;margin-left: 5px"
                >
                  <div
                    class="start-end-day-box flex-display"
                    (click)="selectBox('end')"
                    [class.disabled]="archiveTab">
                    <div >
                      {{
                      recurringDateList[selectedRecurringDates.end][
                        'dayName'
                        ]
                      }}
                    </div>
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </div>
                  <input formControlName="endDay" hidden/>
                </div>
              </div>
            </div>
            <div style="margin-top: 14px;">
              <h2 class="form-subtitle">Periode</h2>

              <div class="border-15">
                <div class="date-container flex-display" style="flex-wrap: wrap">
                  <div class="datetime-title">
                    Udsendingstidspunkt
                  </div>
                  <div class="flex-display date-wrapper">
                    <div (click)="toggleCalendar()" class="padding date flex-display">
                      <div>
                        {{  formatDate(startDate) }}
                      </div>
                      <div>
                        <ion-icon name="chevron-down-outline"></ion-icon>
                      </div>
                    </div>
                    <div (click)="starttime.click()" class="time">
                      {{ startTime }}
                      <input
                        #starttime
                        (onChange)="onStartTimeChange($event)"
                        mbsc-datepicker
                        [controls]="['time']"
                        readonly
                        formControlName="startDate"
                        display="center"
                        timeFormat="HH:mm"
                        [touchUi]="false" dateFormat="YYYY-MM-DD"
                        class="date-input" hidden/>
                    </div>

                  </div>
                </div>

                <div class="hr"></div>

                <div class="date-container flex-display" style="flex-wrap: wrap">
                  <div class="datetime-title">
                    Afslutningstidspunkt
                  </div>
                  <div class="flex-display date-wrapper">
                    <div (click)="toggleCalendar()" class="padding date flex-display">
                      <div>
                        {{ formatDate(endDate) }}
                      </div>
                      <div>
                        <ion-icon name="chevron-down-outline"></ion-icon>
                      </div>
                    </div>
                    <div (click)="endtime.click()" class="time">
                      {{ endTime }}
                      <input
                        (onChange)="onEndTimeChange($event)"
                        mbsc-datepicker
                        #endtime
                        [controls]="['time']"
                        readonly
                        formControlName="endDate"
                        display="center"
                        timeFormat="HH:mm"
                        hidden
                        [touchUi]="false" dateFormat="YYYY-MM-DD"
                        class="date-input"/>
                    </div>

                  </div>
                </div>

                <div *ngIf="showCalendar" class="calendar-wrapper">
                  <mbsc-datepicker
                    #myDatepicker
                    (valueChange)="getDateTime($event)"
                    [controls]="['calendar']"
                    formControlName="dateRange"
                    [showRangeLabels]="false"
                    [min]="defaultStartDate"
                    select="range"
                    display="inline"></mbsc-datepicker>
                </div>

              </div>
            </div>
            <div class="row form-group">
              <div class="recurring-date-container">
                <div class="recurring-date-content">
                  <div class="recurring-date-list">
                    <ul>
                      <ng-container
                        *ngFor="
                          let recurringDate of recurringDateList;
                          let i = index
                        "
                      >
                        <li
                          class="recurring-dates"
                          [class.start-day]="offerStartDay === i"
                          [class.active-range]="recurringDate['inRange']"
                        >
                          {{ recurringDate['dayShort'] }}
                          <span class="start-text" *ngIf="offerStartDay === i"
                          >Udsend</span
                          >
                        </li>

                        <span class="separator">|</span>
                      </ng-container>
                    </ul>
                  </div>
                </div>
                <div class="recurring-date-description">
                  <p>
                    Din God Pris kampagne vil være aktivt fra den
                    <span class="red-text">{{
                      recurringDateDescription.startDateDescription
                      }}</span
                    >.
                  </p>
                  <p>
                    Det vil blive udsendt
                    <span class="red-text">{{
                      recurringDateDescription.startDayDescription
                      }}</span
                    >.
                  </p>
                  <p>
                    Det vil være gyldigt
                    <span
                      *ngIf="
                        selectedRecurringDates.start ===
                        selectedRecurringDates.end
                      "
                    >hver</span
                    >
                    <span class="red-text">{{
                      recurringDateDescription.validRangeDescription
                      }}</span
                    >.
                  </p>
                  <p>
                    Det ugentlig God Pris vil være inaktivt fra
                    <span class="red-text">{{
                      recurringDateDescription.endDateDescription
                      }}</span
                    >.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- recurring date -->
        </div>
        <div class="wrapper" style="margin-top:4px">
          <div class="row form-submit-field" *ngIf="!archiveTab">
            <input type="submit" value="Aktivér" class="login-btn btn-black-login"/>
          </div>
          <div class="row" *ngIf="editMode && archiveTab">
            <a (click)="useAsTemplate()" class="login-btn btn-black-login"
            >Brug som skabelon</a
            >
          </div>
          <div class="row" *ngIf="editMode">
            <a (click)="deletePromo()" class="login-btn btn-white-login"
            >Slet</a
            >
          </div>
          <!--          <div class="row">-->
          <!--            <a (click)="back()" class="btn btn-border-red btn-delete"-->
          <!--              >Tilbage</a-->
          <!--            >-->
          <!--          </div>-->
        </div>
      </form>
    </div>
    <!-- select box -->
    <div class="select-box-modal" *ngIf="showSelectBox">
      <div class="select-box-container">
        <div class="select-box-content">
          <div class="select-box-title">
            <span *ngIf="selectBoxType === 'start'">Gyldig fra:</span>
            <span *ngIf="selectBoxType === 'end'">Gyldig til:</span>
          </div>
          <div class="select-box-day-selection">
            <ul>
              <ng-container
                *ngFor="let recurringDate of recurringDateList; let i = index"
              >
                <li
                  class="recurring-dates"
                  [class.start-day-selected]="tempSelectedDay === i"
                  (click)="tempSelectedDay = i"
                >
                  {{ recurringDate['dayShort'] }}
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
        <div class="select-box-buttons">
          <div class="cancel-btn" (click)="closeSelectBox()">Annuller</div>
          <div
            class="select-btn"
            (click)="recurringDateSelection(tempSelectedDay)"
          >
            Saet
          </div>
          <div class="clear"></div>
        </div>
      </div>
    </div>
  </div>
</section>

<coop-no-price-popup
  *ngIf="showPricePopup"
  [type]="'recurring'"
  (showPopup)="togglePricePopup($event)"
  (saveWithoutPrice)="saveWithoutPrice($event)"
></coop-no-price-popup>

