import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
// created animation varible 'fadeInOutAnimationMFL'
// default state is provided with its style
//:enter=> transition when element enter in the DOM with style backgroundColor: 'rgba(0, 0, 0, 0)' 
// :enter transition then animates element to change backgroundColor: 'rgba(0, 0, 0, 0.6)' & opacity: 1 as the element is inserted into the view over .25s transition time with custom defined easing curve. 
//:leave => transition when element leave DOM with opacity 0.
//:leave transitiion then animates element to change backgroundColor over .25s with custom defined easing curve.
export const fadeInOutAnimationMFL = trigger('fadeInOutAnimationMFL', [
  state(
    '*',
    style({
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      zIndex: 50,
      height: '100%',
      width: '100%',
      overflow: 'hidden',
    })
  ),
  transition(':enter', [
    style({
      backgroundColor: 'rgba(0, 0, 0, 0)',
    }),
    animate(
      '.25s cubic-bezier(.35,0,.25,1)',
      style({
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        opacity: 1,
      })
    ),
  ]),
  transition(':leave', [
    style({
      opacity: 0,
    }),
    animate(
      '.25s cubic-bezier(.35,0,.25,1)',
      style({
        backgroundColor: 'rgba(0, 0, 0, 0)',
      })
    ),
  ]),
]);

//created animation variable 'mflPopupAnimation'
//transition flows in direction of inactive to active state with 250ms transition time and custom defined easing curve .

export const mflPopupAnimation = [
  trigger('slideUpDown', [
    state(
      'inactive',
      style({
        top: '-50%',
      })
    ),
    state(
      'active',
      style({
        top: '20px',
      })
    ),
    transition(
      'inactive => active',
      animate('250ms cubic-bezier(.35,0,.25,1)')
    ),
  ]),
];
// created animation variable 'mflPopoutAnimation'
// transition flows in direction of active to inactive state with 250ms transition time and custom define easing curve changing element's style.
export const mflPopoutAnimation = [
  trigger('slideDownUp', [
    state(
      'active',
      style({
        top: '20px',
      })
    ),
    state(
      'inactive',
      style({
        top: '-50%',
      })
    ),
    transition(
      'active => inactive',
      animate('250ms cubic-bezier(.35,0,.25,1)')
    ),
  ]),
];
