import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { register } from 'swiper/element/bundle';
import { RequestInterceptorService } from 'app/core/services/http.interceptor.service';
import { localeDa, MbscModule , setOptions } from '@mobiscroll/angular';

register();
setOptions({
  locale: localeDa,
  theme: 'ios',
  themeVariant: 'light',
});
@NgModule({ declarations: [], imports: [CommonModule, MbscModule], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptorService,
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only'
      );
    }
  }
}
