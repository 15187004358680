import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable } from "rxjs";
import { TokenDetail } from "app/shared/interface";

@Injectable({
  providedIn: "root"
})
//** verifyToken for the given user
///@params[code, type] => passed to verify code and type that returns token after being verified
//if type is present => returns an Observable of the response body as a JavaScript object from autoLogin()
//else returns an observable of the response body as a JavaScript object from boardOauthLogin()
export class AutologinService {

  constructor(private _apiService: ApiService) {
  }

  verifyToken(code: string, type?: string): Observable<TokenDetail> {
    if (type) {
      return this._apiService.autoLogin(code, type);
    }
    return this._apiService.boardOauthLogin(code);
  }
}
