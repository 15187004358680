<ion-content>
  <section class="app-page page-with-fixed-header">
    <div class="fixed-header">
      <coop-pageheader
        [isBackGroundWhite]="true"
        [pageTitle]="pageTitle"
        [isChild]="true"
        [routeTo]="parentUrl"
        [section]="section"
      ></coop-pageheader>
    </div>

    <div class="page-container">
      <div class="campaign-overview">
        <div class="offertype">
          <div (click)="toggleOfferOption()" class="offerlist">
            <div class="offer-title">
              {{ offerTitle }}
            </div>
            <div>
              <ion-icon *ngIf="!showOfferOption" name="chevron-down-outline"></ion-icon>
              <ion-icon *ngIf="showOfferOption" name="chevron-up-outline"></ion-icon>
            </div>
          </div>
          <div *ngIf="showOfferOption" class="listsection">
            <div *ngFor="let offer of itemList;let last= last" (click)="onSelect(offer)">
              <div class="list" [class.noborder]="last">
                <div class="offer-name">
                  {{ offer.name }}
                </div>
                <div>
                  <ion-icon *ngIf="selectedId === offer.id" name="radio-button-on-outline"></ion-icon>
                  <ion-icon *ngIf="selectedId !== offer.id" name="radio-button-off-outline"></ion-icon>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          *ngIf="selectedId === 1
          || selectedId === 2
          || selectedId === 4"
          class="tab-options">
          <ion-segment [class.flex-layout]="selectedId === 2" class="tab-options-wrapper" mode="md">
            <ion-segment-button class="tab-options-title"
                                value="1"
                                [ngClass]="currentTab=='active' ? 'segment-button-checked' : 'segement-button-unchecked'"
                                (click)="setTabState('1')">
              <ion-label class="text-label"
                         [class]="currentTab == 'active' ? 'tab-sub-label-bold': 'tab-sub-label'">Aktive
              </ion-label>
            </ion-segment-button>

            <ion-segment-button class="tab-options-title"
                                value="2"
                                [ngClass]="currentTab=='scheduled' ? 'segment-button-checked' : 'segement-button-unchecked'"
                                (click)="setTabState('2')">
              <ion-label class="text-label"
                         [class]="currentTab == 'scheduled' ? 'tab-sub-label-bold': 'tab-sub-label'">Fremtidige
              </ion-label>
            </ion-segment-button>
            <ion-segment-button
              class="tab-options-title"
              value="0"
              [ngClass]="currentTab=='archived' ? 'segment-button-checked' : 'segement-button-unchecked'"
              (click)="setTabState('0')">
              <ion-label class="text-label"
                         [class]="currentTab == 'archived' ? 'tab-sub-label-bold': 'tab-sub-label'">Arkiv
              </ion-label>
            </ion-segment-button>

            <ion-segment-button
              *ngIf="selectedId === 2"
              class="tab-options-title"
              value="3"
              [ngClass]="currentTab=='cancelled' ? 'segment-button-checked' : 'segement-button-unchecked'"
              (click)="setTabState('3')">
              <ion-label class="text-label"
                         [class]="currentTab == 'cancelled' ? 'tab-sub-label-bold': 'tab-sub-label'">Aflyst
              </ion-label>
            </ion-segment-button>
          </ion-segment>
        </div>


        <div *ngIf="selectedId === 3" class="searchsection">
          <div class="searchinput">
            <input type="search"
                   #searchBox
                   placeholder="Søg"
                   class="search-input"
                   (keyup.enter)="search(searchBox.value)"
            />
            <div *ngIf="searchString" class="close-search" (click)="closeSearch()">
              <ion-icon name="close-outline"></ion-icon>
            </div>
          </div>
          <div class="searchicon">
            <ion-icon name="search-outline"></ion-icon>
          </div>
        </div>


        <ng-container *ngIf="selectedId === 1">
          <div *ngFor="let item of newsList"
               routerLink="/bestyrelse/indlaeg/nyheder/redigere/{{
            item.id
          }}"
               class="item-list-wrapper">
            <div class="flex-display">
              <div class="flex-display">
                <div>
                  <img
                    class="item-img"
                    src="{{ item.thumbImageUrl }}">
                </div>

                <div style="margin-left: 10px">
                  <span>{{ formatDate(item.publishDate) }} - {{ formatDate(item.expirationDate) }}</span>
                  <div class="item-title">
                    {{ item.title }}
                  </div>
                </div>

              </div>

              <div>
                <ion-icon name="chevron-forward-outline"></ion-icon>
              </div>

            </div>
          </div>

          <div class="no-data-available" *ngIf="newsList?.length === 0">
            No news list available
          </div>
        </ng-container>


        <ng-container *ngIf="selectedId === 2">
          <div *ngFor="let item of eventsList"
               routerLink="/bestyrelse/indlaeg/arrangement/redigere/{{
            item.id
          }}"
               class="item-list-wrapper">
            <div class="flex-display">
              <div class="flex-display">
                <div>
                  <img
                    class="item-img"
                    src="{{ item.thumbImageUrl }}">
                </div>

                <div style="margin-left: 10px">
                  <span>{{ formatDate(item.startDate) }} - {{ formatDate(item.endDate) }}</span>
                  <div class="item-title">
                    {{ item.title }}
                  </div>
                </div>

              </div>

              <div>
                <ion-icon name="chevron-forward-outline"></ion-icon>
              </div>

            </div>
          </div>


          <div class="no-data-available" *ngIf="eventsList?.length === 0">
            No event list available
          </div>
        </ng-container>

        <ng-container *ngIf="selectedId === 3">
          <div *ngFor="let item of websiteList" class="item-list-wrapper"
               routerLink="/bestyrelse/website/redigere/{{
            item.id
          }}"
          >
            <div class="flex-display">
              <div class="flex-display">
                <div>
                  <img
                    class="item-img"
                    src="{{ item.image }}">
                </div>

                <div style="margin-left: 10px">
                  <div class="item-title">
                    {{ item.title }}
                  </div>
                  <span>{{ item.description  }}</span>
                </div>

              </div>

              <div>
                <ion-icon name="chevron-forward-outline"></ion-icon>
              </div>

            </div>
          </div>

          <div class="no-data-available" *ngIf="websiteList?.length === 0">
            No website list available
          </div>

        </ng-container>

        <ng-container *ngIf="selectedId === 4">
          <div *ngFor="let item of pollList"
               class="item-list-wrapper">
            <div class="flex-display">
              <div class="flex-display">
                <div>
                  <img
                    class="item-img"
                    src="{{ item.imageUrl }}">
                </div>

                <div style="margin-left: 10px">
                  <span>{{ formatDate(item.startDate) }} - {{ formatDate(item.endDate) }}</span>
                  <div class="item-title">
                    {{ item.title }}
                  </div>
                </div>

              </div>

              <div>
                <ion-icon name="chevron-forward-outline"></ion-icon>
              </div>

            </div>
          </div>


          <div class="no-data-available" *ngIf="eventsList?.length === 0">
            No event list available
          </div>
        </ng-container>

      </div>
    </div>


    <div (click)="onCreateNew()" class="create">
      <ion-icon name="add-outline"></ion-icon>
      <p>
        {{ createNew }}
      </p>
    </div>

    <ion-infinite-scroll (ionInfinite)="loadMore($event)" class="ion-margin-top">
      <ion-infinite-scroll-content loadingText="...">
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>

  </section>
</ion-content>
