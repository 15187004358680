import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

import { Shared } from "app/features/offer";
import { FormFunctionsService } from "../../../core/services";

@Injectable({
  providedIn: "root"
})
export class OfferDataService {
  readonly defaultChannels: Shared = {
    coop: true,
    facebook: false,
    sms: false,
    mail: false,
    web: false,
    dit: false
  };
  private channelSelection = new BehaviorSubject(this.defaultChannels);
  selectedChannel = this.channelSelection.asObservable();

  setChannel(channels: Shared) {
    this.channelSelection.next(channels);
  }

  clearChannel() {
    this.channelSelection.next(undefined);
  }

  static descriptionChanges(optionName: string, isNewsMode, description): string {
    if (!isNewsMode || (isNewsMode && optionName !== "coop")) {
      return FormFunctionsService.stripHTML(description);
    }
    if (isNewsMode && optionName === "coop") {
      return FormFunctionsService.stripHTMLMaintainLineBreak(description);
    }
  }
}
