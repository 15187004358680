import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { mergeMap, take } from 'rxjs/operators';

import {
  AppEventsService,
  ErrorhandlerService,
  WindowService,
  FileReaderService,
} from 'app/core/services';
import {
  CommentDetail,
  FbInsights,
  JatakLiveComments,
  JatakLiveCommentSeparator,
  ProductReportData,
  JatakLiveService,
} from 'app/features/jatak-live';

@Component({
  selector: 'coop-jatak-live-reporting',
  templateUrl: './jatak-live-reporting.component.html',
  styleUrls: ['./jatak-live-reporting.component.scss'],
})
export class JatakLiveReportingComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  infiniteCtn;
  pageTitle = 'Rapportering';
  previousUrl = '/';
  showParent = 'invisible';
  productData: ProductReportData;
  fbInsights: FbInsights;
  routeId;
  commentIntervalId;
  reportIntervalId;
  lastId = undefined;
  lastHeadId = undefined;
  commentList: CommentDetail[] = [];
  isLive = false;
  hasGoneLive = false;
  isProcessing = false;
  apiCallonScrollUp = true;
  separatorComment = {};
  scrollToBottom = false;
  initInfiniteScroll;
  downloadFile = {
    name: '',
    file: '',
  };
  replyStatusNull = [];
  fetchCommentsForId = '';

  private _subscriptionState = true;

  constructor(
    private _jatakLiveService: JatakLiveService,
    private _activatedRoute: ActivatedRoute,
    private _errorHandlerService: ErrorhandlerService,
    private _appEventService: AppEventsService,
    private _windowService: WindowService
  ) {}
// sets resolver data to its fields
  // subscribes to JatakLiveComments every 3 seconds
  ngOnInit() {
    this._appEventService.showLoader(false);
    document.body.classList.add('overlay');
    this.routeId = this._activatedRoute.snapshot.parent.params['id']
      ? this._activatedRoute.snapshot.parent.params['id']
      : this._activatedRoute.snapshot.params['id'];
    this.productData = this._activatedRoute.snapshot.data['productData'];
    this.fbInsights = this._activatedRoute.snapshot.data['fbInsights'];
    this.commentList =
      this._activatedRoute.snapshot.data['commentData']['data'];
    this.lastId = this._activatedRoute.snapshot.data['commentData']['last_id'];
    this.lastHeadId =
      this._activatedRoute.snapshot.data['commentData']['last_head_id'];
    this.isLive = this._activatedRoute.snapshot.data['commentData']['IsLive'];
    this.hasGoneLive =
      this._activatedRoute.snapshot.data['commentData']['hasGoneLive'];
    this._formatSeparatorData(
      this._activatedRoute.snapshot.data['commentData']['separator']
    );
    //    this.downloadUrl = `${environment.webhookurl}/live_event/${this.routeId}/orderSheet`;
    if (this.isLive) {
      this.commentIntervalId = setInterval(
        () => this._getJatakComments(),
        3000
      );
    }
  }

  ngOnDestroy(): void {
    this._subscriptionState = false;
    document.body.classList.remove('overlay');
    clearInterval(this.commentIntervalId);
    clearInterval(this.reportIntervalId);
  }

  ngAfterViewInit(): void {
    if (this.hasGoneLive) {
      this.infiniteCtn = document.getElementById('infinite');
      this.infiniteCtn.scrollTop = 40;
    }
    if (this.hasGoneLive && this.commentList.length > 0) {
      setTimeout(() => {
        this.infiniteCtn.scrollTop = this.infiniteCtn.scrollHeight;
        this.initInfiniteScroll = true;
      }, 100);
    }
  }

  onScrollTop(): void {
    this._getPreviousJatakComments();
  }

  onScrollBottom(atBottom: boolean): void {
    this.scrollToBottom = atBottom;
  }
// Downloads the order list from fb live
  onDownload(): void {
    if (!this.isProcessing) {
      this.isProcessing = true;
      this._jatakLiveService
        .getJatakReport(this.routeId)
        .pipe(
          take(1),
          mergeMap((file) => this._loadFile(file))
        )
        .subscribe({
          next: () => {
            this._download();
            this.isProcessing = false;
          },
          error: (err) => this._handleError(err),
        });
    }
  }

  private _loadFile(file) {
    this.downloadFile.file = file;
    return FileReaderService.readFile(file, file['type']);
  }

  private _download() {
    FileReaderService.downloadFile(
      this.downloadFile,
      'Rapport' + new Date().getTime()
    );
  }

  private _getJatakComments() {
    if (!this.isProcessing) {
      this._jatakLiveService
        .getJatakComments(
          this.routeId,
          this.lastId,
          false,
          this.fetchCommentsForId
        )
        .pipe(take(1))
        .subscribe({
          next: (res: JatakLiveComments) => {
            if (res.last_id !== null && this.lastId !== res.last_id) {
              this.lastId = res.last_id;
              if (!this.initInfiniteScroll) {
                this.initInfiniteScroll = true;
              }
              res.data.forEach((data) => {
                // fetch again for comments with reply_status null
                if (data.reply_comment && data.reply_status === null) {
                  this.replyStatusNull.push(data.id);
                  this.fetchCommentsForId = this.replyStatusNull.toString();
                }
                const currentIndex = this.replyStatusNull.indexOf(data.id);
                if (data.reply_status !== null && currentIndex !== -1) {
                  this.replyStatusNull.splice(currentIndex, 1);
                  this.fetchCommentsForId = this.replyStatusNull.toString();
                }
                const index = this.commentList.findIndex(
                  (prod) => prod.id === data.id
                );
                if (index !== -1) {
                  this.commentList[index] = data;
                }
                if (index === -1) {
                  this.commentList.push(data);
                }
                // comment section not scrolling after live end issue fix
                // if (this.scrollToBottom) {
                //     setTimeout(() => {
                //         this.infiniteCtn.scrollTop = this.infiniteCtn.scrollHeight;
                //     }, 100);
                // }
                setTimeout(() => {
                  this.infiniteCtn.scrollTop = this.infiniteCtn.scrollHeight;
                }, 100);
              });
            }
            if (this.lastId === res.last_id) {
              this._formatSeparatorData(res.separator);
            }
            if (!res.IsLive) {
              clearInterval(this.commentIntervalId);
            }
            this.hasGoneLive = res.hasGoneLive;
            this.isProcessing = false;
          },
          error: (err) => this._handleError(err),
        });
    }
  }

  private _getPreviousJatakComments() {
    if (!this.isProcessing && this.apiCallonScrollUp) {
      this.isProcessing = true;
      this._jatakLiveService
        .getJatakComments(this.routeId, this.lastHeadId, true)
        .pipe(take(1))
        .subscribe((res: JatakLiveComments) => {
          if (
            res.last_head_id !== null &&
            this.lastHeadId !== res.last_head_id
          ) {
            this.lastHeadId = res.last_head_id;
            this.commentList = [...res.data].concat(this.commentList);
          }
          if (res.last_head_id === null) {
            this.apiCallonScrollUp = false;
          }
          this.isProcessing = false;
        });
    }
  }

  private _formatSeparatorData(
    separatorArray: JatakLiveCommentSeparator[]
  ): void {
    this.separatorComment = separatorArray.reduce((acc, separator) => {
      return { ...acc, [separator.id]: separator.date };
    }, {});
  }

  private _handleError(error) {
    this._errorHandlerService.handleError(error || { code: -400 }, 'jatakLive');
    this.isProcessing = false;
  }
}
