import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { InfiniteScrollOptions } from 'app/shared/interface/infinite-scroll.interface';

@Component({
  selector: 'coop-live-infinite-scroll',
  templateUrl: './live-infinite-scroll.component.html',
  styleUrls: ['./live-infinite-scroll.component.scss'],
})
export class LiveInfiniteScrollComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() direction: 'up' | 'down' = 'down';
  @Input() ready = false;
  @Input() isLoading = false;
  @Input() options: InfiniteScrollOptions = { threshold: 1 };
  @Output() scrollTop = new EventEmitter();
  @Output() scrollBottom = new EventEmitter<boolean>();
  @ViewChild('anchor_head') anchorHead: ElementRef<HTMLElement>;
  @ViewChild('anchor_tail') anchorTail: ElementRef<HTMLElement>;

  private observerHead: IntersectionObserver;
  private observerTail: IntersectionObserver;

  constructor(private host: ElementRef) {}

  ngOnInit() {}

  ngAfterViewInit() {
    const options = {
      root: this.isHostScrollable() ? this.host.nativeElement : null,
      ...this.options,
    };
    this.observerHead = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        this.scrollTop.emit();
        if (this.direction === 'up') {
          this.host.nativeElement.scrollTop = 40;
        }
      }
    }, options);
    this.observerTail = new IntersectionObserver(([entry]) => {
      this.scrollBottom.emit(entry.isIntersecting);
    });
    this.observerHead.observe(this.anchorHead.nativeElement);
    this.observerTail.observe(this.anchorTail.nativeElement);
  }

  ngOnDestroy() {
    this.observerHead.disconnect();
  }

  get element() {
    return this.host.nativeElement;
  }

  private isHostScrollable() {
    const style = window.getComputedStyle(this.element);
    return (
      style.getPropertyValue('overflow') === 'auto' ||
      style.getPropertyValue('overflow-y') === 'scroll'
    );
  }
}
