<section
  class="app-page list-component jatak-live-list"
  [@parentAnimate]="showParent"
>
  <div class="fixed-header">
    <coop-pageheader
      [pageTitle]="pageTitle"
      [isChild]="true"
      [routeTo]="parentUrl"
    ></coop-pageheader>
    <div class="fixed-header-content">
      <ul class="tabs three-col">
        <li
          class="tab-first"
          (click)="tab('1', $event)"
          [class.active]="activeTab == '1'"
        >
          Aktiv
        </li>
        <li
          class="tab-second"
          (click)="tab('2', $event)"
          [class.active]="activeTab == '2'"
        >
          Fremtidige
        </li>
        <li
          class="tab-third"
          (click)="tab('0', $event)"
          [class.active]="activeTab == '0'"
        >
          Arkiv
        </li>
        <hr />
      </ul>
    </div>
  </div>
  <div class="page-container">
    <div class="item-list">
      <ng-container *ngFor="let fbOffer of jatakLiveList">
        <a class="item" (click)="navigateTo('redigere', fbOffer.id, $event)">
          <div class="item-detail">
            <p class="item-date">
              {{ fbOffer.start_date | dateformatddmmyy : true }}
              <span style="font-weight: 700"><-></span>
              {{ fbOffer.end_date | dateformatddmmyy : true }}
            </p>
            <h3 class="item-title">{{ fbOffer.title }}</h3>
            <h5 class="item-comment-order">
              Lagerbeholdning: {{ fbOffer.max_items || 0 }} stk.
            </h5>
            <h5 class="item-comment-order">
              Antal bestilte produkter: {{ fbOffer.order || 0 }} stk.
            </h5>
            <h5 class="item-comment-order">
              Omsætning: {{ fbOffer.turnover || 0 }} kr.
            </h5>
          </div>
          <div class="link-icons">
            <a
              class="link-icon"
              (click)="navigateTo('redigere', fbOffer.id, $event)"
            >
              <span class="icon-container">
                <i class="ti-pencil"></i>
              </span>
              <span class="link-text">Rediger</span>
            </a>
            <a class="link-icon" (click)="onDelete(fbOffer.id, $event)">
              <span class="icon-container">
                <i class="ti-close"></i>
              </span>
              <span class="link-text">Slet</span>
            </a>
            <a
              class="link-icon"
              (click)="navigateTo('reporting', fbOffer.id, $event)"
            >
              <span class="icon-container">
                <i class="ti-bar-chart"></i>
              </span>
              <span class="link-text">Rapport</span>
            </a>
          </div>
        </a>
      </ng-container>
      <h5 class="empty-message" *ngIf="page == 1 && jatakLiveList.length == 0">
        Beklager, ingen kampagner tilgængelige
      </h5>
    </div>
    <a
      class="login-btn btn-white-login btn-medium"
      (click)="loadMore()"
      *ngIf="!(jatakLiveList.length < page * limit)"
      >Indlæs mere..</a
    >
  </div>
  <a (click)="navigateToCreate()" class="big-add-btn add-btn show-hide-btn flex-center">
    <i class="icon icon-add"></i>
  </a>
</section>
<router-outlet></router-outlet>
