<header class="app-header clearfix not-print" [class.whitecolor]="isBackGroundWhite">
  <div class="wrapper">
    <span class="back-btn not-print" (click)="back()" *ngIf="!create && isChild">
             <ion-icon name="arrow-back-outline"></ion-icon>
         </span>
    <div class="page-titles">
      <h1 *ngIf="pageTitle" class="page-title">{{ pageTitle || 'Coop' }}</h1>
      <h2 class="page-subtitle" *ngIf="pageSubTitle">{{ pageSubTitle }}</h2>
    </div>

    <div
      *ngIf="showPrinter" (click)="onPrint()"
      class="print-btn flex-display">
      <div>
        Print
      </div>
      <div style="margin-left: 5px">
        <ion-icon name="print"></ion-icon>
      </div>
    </div>

  </div>
</header>
