import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { InfiniteScrollOptions } from 'app/shared/interface';

@Component({
  selector: 'coop-infinite-scroll',
  templateUrl: './infinite-scroll.component.html',
  styleUrls: ['./infinite-scroll.component.scss'],
})
export class InfiniteScrollComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() direction: 'up' | 'down' = 'down';
  @Input() ready = false;
  @Input() isLoading = false;
  @Input() options: InfiniteScrollOptions = { threshold: 1 };
  @Output() scrolled = new EventEmitter();
  @ViewChild('anchor') anchor: ElementRef<HTMLElement>;

  private observer: IntersectionObserver;

  constructor(private host: ElementRef) {}

  ngOnInit() {}

//**called after Angular has fully initialized a component's view
  //set options with the root
  //create a IntersectionObserver variable observer => if entry isIntersecting is true
  //                                                        =>if direction is 'up' => set host nativeElement scrollTop 40
  ngAfterViewInit() {
    const options = {
      root: this.isHostScrollable() ? this.host.nativeElement : null,// if isHostScrollable() is true assign root with host nativeElement and add options else set with null
      ...this.options,
    };
    this.observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        this.scrolled.emit(); // emit data for parent component
        if (this.direction === 'up') {
          this.host.nativeElement.scrollTop = 40;
        }
      }
    }, options);
    this.observer.observe(this.anchor.nativeElement); //observe anchor nativeElement
  }
//** destroy component
  ngOnDestroy() {
    this.observer.disconnect();
  }
//**returns the host nativeElement
  get element() {
    return this.host.nativeElement;
  }
//** checks if host is scrollable
  //const style is set with the value returned by getComputedStyle()
  //returns the respective condition that is fulfilled
  private isHostScrollable() {
    const style = window.getComputedStyle(this.element);
    return (
      style.getPropertyValue('overflow') === 'auto' ||
      style.getPropertyValue('overflow-y') === 'scroll'
    );
  }
}

