import { FormGroup, FormArray } from '@angular/forms';

export class GenericValidator {
  constructor(
    private _validationMessages: {
      [key: string]: {
        [key: string]: string;
      };
    }
  ) {}
// @params [container] => formGroup or formArray
// It maps validation keys to its validation messages
// @returns [messages] => object of error message with keys
  processMessages(container: FormGroup | FormArray) {
    let messages = {};
    if (container.errors) {
      for (let errorKey in container.errors) {
        if (container.errors.hasOwnProperty(errorKey)) {
          let m = container.errors[errorKey];
          Object.keys(m).map((key) => {
            messages[errorKey] = this._validationMessages[errorKey][key];
          });
        }
      }
    }
    for (let controlKey in container.controls) {
      if (container.controls.hasOwnProperty(controlKey)) {
        let c = container.controls[controlKey];
        if (c instanceof FormArray) {
          let formArrayControls = c.controls;
          for (let i = 0; i < formArrayControls.length; i++) {
            let childMessages = this.processMessages(
              <FormGroup>formArrayControls[i]
            );
            Object.assign(messages, childMessages);
          }
        }
        if (c instanceof FormGroup) {
          let childMessages = this.processMessages(c);
          Object.assign(messages, childMessages);
        } else {
          if (this._validationMessages[controlKey]) {
            if (c.errors) {
              Object.keys(c.errors).map((messageKey) => {
                if (this._validationMessages[controlKey][messageKey]) {
                  messages[controlKey] =
                    this._validationMessages[controlKey][messageKey];
                }
              });
            }
          }
        }
      }
    }
    return messages;
  }

// @params [container] => formGroup or formArray
// It maps validation keys to its validation messages
// @returns [messages] => object of error message with keys
  processArrayMessages(container: FormGroup | FormArray) {
    let messages = {};
    if (container.errors) {
      for (const errorKey in container.errors) {
        if (container.errors.hasOwnProperty(errorKey)) {
          const m = container.errors[errorKey];
          Object.keys(m).map((key) => {
            messages[errorKey] = this._validationMessages[errorKey][key];
          });
        }
      }
    }
    for (const controlKey in container.controls) {
      if (container.controls.hasOwnProperty(controlKey)) {
        const c = container.controls[controlKey];
        if (c instanceof FormArray) {
          const formArrayControls = c.controls;
          for (let i = 0; i < formArrayControls.length; i++) {
            messages[i] = {};
            messages[i] = this.processArrayMessages(
              formArrayControls[i] as FormGroup
            );
          }
        }
        if (c instanceof FormGroup) {
          const childMessages = this.processArrayMessages(c);
          Object.assign(messages, childMessages);
        } else {
          if (this._validationMessages[controlKey]) {
            messages[controlKey] = '';
            if (c.errors) {
              Object.keys(c.errors).map((messageKey) => {
                if (this._validationMessages[controlKey][messageKey]) {
                  messages[controlKey] += this._validationMessages[controlKey][messageKey] + '';
                }
              });
            }
          }
        }
      }
    }
    return messages;
  }
}
