import {
  animate,
  style,
  transition,
  trigger,
  query,
  stagger,
  animateChild,
} from '@angular/animations';
//create triggerObject 'listItemFade'
//:enter=> transition when element enter in the DOM with opacity 0 and scale(0.8)
// :enter transition then animates it to change opacity to 1 and transform scale 1 as the element is inserted into the view over 300ms transition time.
//:leave =>  transition when element leave the DOM with opacity 1 and scale 1 and height default.
//:leave transiton then animates it to change  opacity,height,and margin to 0 and transform scale 0.8 as element leaves DOM over 300ms transition time.
export const listItemFade = trigger('items', [
  transition(':enter', [
    style({ transform: 'scale(0.8)', opacity: 0 }),
    animate(
      '300ms cubic-bezier(.8, -0.6, 0.2, 1.5)',
      style({ transform: 'scale(1)', opacity: 1 })
    ),
  ]),
  transition(':leave', [
    style({ transform: 'scale(1)', opacity: 1, height: '*' }),
    animate(
      '300ms cubic-bezier(.8, -0.6, 0.2, 1.5)',
      style({
        transform: 'scale(0.8)',
        opacity: 0,
        height: '0px',
        margin: '0px',
      })
    ),
  ]),
]);
// created animation variable 'listAnimate' 
//query looks for child element having their own animation(@items trigger) when entering DOM
//stagger delay each animation by 200 
//animateChild() control animation styling and timing for a child animation.
export const listAnimate = trigger('list', [
  transition(':enter', [
    query('@items', stagger(200, animateChild()), { optional: true }),
  ]),
]);
