import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'coop-order-list-header',
  templateUrl: './order-list-header.component.html',
  styleUrls: ['./order-list-header.component.scss'],
})
export class OrderListHeaderComponent implements OnInit {
  @Input() title: string;
  @Input() startDate: string;
  @Input() endDate: string;

  @Output() printOrder = new EventEmitter<null>();
  constructor() {}

  ngOnInit() {
  }
// emits printOrder event to its parent component

}
