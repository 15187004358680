import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';

import { Notifications } from '@mobiscroll/angular';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  private static mbscNotifications: Notifications;

  constructor(notify: Notifications) {
    NotificationsService.mbscNotifications = notify;
  }

  init() {

  }

  // *** Show Confirmation Message ***
  // @params [confirmationMessage] => Message to be displayed
  // @params [okText] => Text for the ok button: Defaults to OK
  // @params [cancelText] => Text for the cancel button: Defaults to Anuller
  // @returns [Promise] => Boolean accordingly
  static confirm(confirmationMessage: string, okText: string = 'Ok', cancelText: string = 'Annuller'): Observable<boolean> {
    return from(
      NotificationsService.mbscNotifications.confirm({
        title: '',
        message: confirmationMessage,
        okText: okText,
        cancelText: cancelText
      })
    );
  }

  // *** Show Confirmation Message ***
  // @params [confirmationMessage] => Message to be displayed
  static confirmWithCallback(confirmationMessage: string, callbackFn: (result: boolean) => void, okTxt = 'Ok', cancelTxt = 'Annuller'): Observable<boolean> {
    return from(
      NotificationsService.mbscNotifications.confirm({
        message: confirmationMessage,
        title: '',
        okText: okTxt,
        cancelText: cancelTxt,
        callback: callbackFn
      })
    );
  }

  // *** Show Notifications ***
  // @params [notificationMessage] => Message to be displayed
  static notify(notificationMessage: string, type: any, position: 'bottom' | 'center' | 'top', duration = 7000) {
    NotificationsService.mbscNotifications
      .snackbar({
        message: notificationMessage,
        color: type === 'error' ? 'danger' : type,
        display: position,
        duration: duration
      })
      .then();
  }
}
