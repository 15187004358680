import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { JatakFormComponent } from 'app/features/jatak';
import {JatakDefault} from "../../../../shared/interface";
import {co} from "@fullcalendar/core/internal-common";

@Component({
  selector: 'app-jatak-create',
  templateUrl: './jatak-create.component.html',
  styleUrls: ['./jatak-create.component.scss'],
})
export class JatakCreateComponent implements OnInit {
  // *** JatakFormComponent required in canDeactivate guard ***
  @ViewChild(JatakFormComponent, { static: true })
  jatakFormComponent: JatakFormComponent;
  previousUrl: string;
  currentUrl: string;
  successUrl: string;
  parentUrl: string;
  jatakDefault :JatakDefault
  jatakDuplicateData:any
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router
  ) {
    // this._router.events.subscribe(event => {
    //   console.log(event)
    // } )
  }

// *** It sets previousUrl and successUrl for coop-jatak-form ***
  ngOnInit() {
    this.parentUrl = this._activatedRoute.snapshot.parent.data['parentUrl'];
    this.currentUrl = this._router.url;
    this.previousUrl = this.currentUrl.replace('ja-tak/skab', '');
    this.successUrl = this.parentUrl + '/kalendar/ja-tak';
    this.jatakDefault = this._activatedRoute.snapshot.data["data"]
  }
}
